import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiResponse } from '@shared/models/global/apiResponse';
import { PageInfo } from '@shared/models/global/response';
import { PaginationHelper } from '@app/shared/helpers/pagination-helper';
import { CarWashAddOn } from "@shared/models/carwash/car-wash-add-on.model";
import { CarWashAddOnHttpService } from "@shared/service/car-wash-add-ons/car-wash-add-on-http.service";

@Injectable({ providedIn: 'root' })
export class CarWashAddonService {
  readonly pagination$: Subject<PageInfo> = new Subject<PageInfo>();

  constructor(
    private readonly carWashPackageHttpService: CarWashAddOnHttpService,
  ) {}

  getAddons(filters?: Record<string, string>): Observable<CarWashAddOn[]> {
    const toData = (res: ApiResponse<CarWashAddOn[]>) => res.data;
    const toPagination = (res: ApiResponse<CarWashAddOn[]>) =>
      this.pagination$.next(PaginationHelper.camelToSnakeCase(res.pageInfo));

    return this.carWashPackageHttpService
      .getAddons(filters)
      .pipe(tap(toPagination), map(toData));
  }

  createAddon(data: Partial<CarWashAddOn>): Observable<CarWashAddOn> {
    const addon = (res: ApiResponse<CarWashAddOn>) => res.data;

    return this.carWashPackageHttpService
      .createAddon(data)
      .pipe(map(addon));
  }

  updateAddon(
    id: number,
    data: Partial<CarWashAddOn>,
  ): Observable<CarWashAddOn> {
    const addon = (res: ApiResponse<CarWashAddOn>) => res.data;

    return this.carWashPackageHttpService
      .updateAddon(id, data)
      .pipe(map(addon));
  }

  removeAddon(id: number): Observable<void> {
    return this.carWashPackageHttpService.removeAddon(id);
  }
}
