import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { DriversBookingLogs } from '@app/modules/drivers/shared/models';
import { DriverQuestions } from '@app/modules/drivers/shared/models/driver-questions.model';

@Injectable({
  providedIn: 'root',
})
export class DriversBookingLogsHttpService {
  private readonly url: string = `${environment.jcDeliveryServer}orders/log-driver-answers`;
  private readonly urlDriverQuestions: string = `${environment.jcDeliveryServer}driver-questions`;
  constructor(private readonly http: HttpClient) {}

  getBookingLogs(
    params: Record<string, string | number>,
  ): Observable<ApiResponse<DriversBookingLogs[]>> {
    return this.http.get<ApiResponse<DriversBookingLogs[]>>(this.url, {
      params,
    });
  }

  getDriverQuestions(): Observable<ApiResponse<DriverQuestions[]>> {
    return this.http.get<ApiResponse<DriverQuestions[]>>(
      this.urlDriverQuestions,
    );
  }

  getDriverQuestionsById(id: number): Observable<ApiResponse<DriverQuestions>> {
    return this.http.get<ApiResponse<DriverQuestions>>(
      `${this.urlDriverQuestions}/${id}`,
    );
  }
}
