import { Name } from '@shared/models';
import { Status } from '@app/shared/enums/status.enum';
export interface UserSegment {
  id: number;
  name: Name;
  orderStartRange: number;
  orderEndRange: number;
  status: Status;
  cashbackPercentage: number;
}
