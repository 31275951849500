import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { TranslateModule } from '@ngx-translate/core';

import { CarwashOrderDetailsComponent } from '@app/modules/carwash/modules/carwash-order/components/carwash-order-details/carwash-order-details.component';
import { CarwashOrderDetailsCardComponent } from './carwash-order-details-card/carwash-order-details-card.component';
import { CarwashOrderDetailsHeaderComponent } from './carwash-order-details-header/carwash-order-details-header.component';
import { CarwashOrderDetailsItemComponent } from './carwash-order-details-item/carwash-order-details-item.component';
import { SharedModule } from '@app/modules/homeclean/shared/shared.module';

@NgModule({
  declarations: [
    CarwashOrderDetailsComponent,
    CarwashOrderDetailsCardComponent,
    CarwashOrderDetailsHeaderComponent,
    CarwashOrderDetailsItemComponent,
  ],
  imports: [
    CommonModule,
    MDBBootstrapModulesPro,
    TranslateModule,
    SharedModule,
  ],
})
export class CarwashOrderDetailsModule {}
