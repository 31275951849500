import { environment } from 'src/environments/environment';
import { GroupService } from '@app/shared/service/group';
import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { DialogService } from '@shared/components/dialog/services/dialog.service';
import { CarwashBranch } from '@shared/models';
import { ActivatedRoute, Router } from '@angular/router';
import { CarwashBranchEditDialogComponent } from '@app/modules/carwash/modules/carwash-branch/components/carwash-branch-edit-dialog/carwash-branch-edit-dialog.component';
import { filter, tap, takeUntil, finalize } from 'rxjs/operators';
import { Status } from '@shared/enums/status.enum';
import { CarwashBranchService } from '@shared/service/carwash-branch';
import { DomSanitizer } from '@angular/platform-browser';
import { AreaCoverageDialogComponent } from '../area-coverage-dialog/area-coverage-dialog.component';
import { OnlineProfileComponent } from '../online-profile/online-profile.component';
import { PageInfo } from '@app/shared/models/global/response';

@Component({
  selector: 'app-carwash-branch-list',
  templateUrl: './carwash-branch-list.component.html',
  styles: [
    `
      .update-branch-loader {
        min-width: 72px;
      }
    `,
  ],
})
export class CarwashBranchListComponent implements OnInit {
  isMobileType: boolean;
  groupId: number;
  companyId: number;
  Status = Status;
  pagination$: Observable<PageInfo>;
  branches$: Observable<CarwashBranch[]>;
  isProduction: boolean;
  readonly createBranch = 'CARWASH.BRANCH.CREATE_BRANCH';
  readonly noBranches = {
    text: 'CARWASH.BRANCH.NO_BRANCHES.TEXT',
  };
  readonly table = {
    columns: [
      {
        title: '#',
      },
      {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.COLUMNS.BRANCH_ID',
      },
      {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.COLUMNS.BRANCH_NAME',
      },
      {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.COLUMNS.OPERATING',
      },
      {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.COLUMNS.BRANCH_MANAGER_NAME',
      },
      {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.COLUMNS.BRANCH_MANAGER_NUMBER',
      },
      {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.COLUMNS.LOWEST_PRICE',
      },
      {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.COLUMNS.HIGHEST_PRICE',
      },
      {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.COLUMNS.GOOGLE_LINK',
      },
      {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.COLUMNS.OPEN_TIME',
      },
      {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.COLUMNS.CLOSE_TIME',
      },
      { title: 'Status' },
      { title: 'CARWASH.BRANCH.TABLES.BRANCHES.COLUMNS.HASPOS' },
      {
        title: 'ACTIONS',
      },
    ],
    options: {
      stations: 'CARWASH.BRANCH.STATIONS',
      vehicles: 'CARWASH.BRANCH.VEHICLES',
      update: {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.OPTIONS.UPDATE.TITLE',
      },
      createTicket: {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.OPTIONS.PACKAGES.TITLE',
      },
      pricing: {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.OPTIONS.PRICING.TITLE',
      },
      addOnsPricing: {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.OPTIONS.ADD_ON_PRICING.TITLE',
      },
      start_time_service: {
        title:
          'CARWASH.BRANCH.TABLES.BRANCHES.OPTIONS.START_TIME_SERVICE.TITLE',
      },
      area_coverage: {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.OPTIONS.AREA_COVERAGE.TITLE',
      },
      area_charges: {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.OPTIONS.AREA_CHARGES.TITLE',
      },
      online_profile: {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.OPTIONS.ONLINE_PROFILE.TITLE',
      },
      manage_pos_contract: {
        title:
          'CARWASH.BRANCH.TABLES.BRANCHES.OPTIONS.MANAGE_POS_CONTRACT.TITLE',
      },
    },
  };
  branchesUpdating: { [branchId: number]: 'pos' | 'status' } = {};
  destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    private readonly carwashBranchService: CarwashBranchService,
    private readonly dialog: DialogService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly groupService: GroupService,
    private sanitizer: DomSanitizer,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.initVariables();
  }
  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  openBranchEditDialog(branch: CarwashBranch = null): void {
    this.dialog
      .open(CarwashBranchEditDialogComponent, {
        ...(branch && { branch }),
        companyId: this.companyId,
      })
      .afterClosed$.pipe(
        filter(Boolean),
        tap(() => this.loadBranches()),
      )
      .subscribe();
  }

  changeBranchStatus(branch: CarwashBranch, status: Status): void {
    const newStatus =
      status === Status.Inactive ? Status.Active : Status.Inactive;
    const body = {
      status: newStatus,
    };
    this.updateBranch(
      branch,
      body,
      () => (branch.status = newStatus),
      'status',
    );
  }

  changePosStatus(branch: CarwashBranch, posStatus: boolean) {
    const body = {
      hasPosStatus: posStatus,
    };
    this.updateBranch(
      branch,
      body,
      () => (branch.hasPosStatus = posStatus),
      'pos',
    );
  }

  updateBranch(
    branch: CarwashBranch,
    body: Record<string, string | number | boolean>,
    cd: Function,
    type: 'pos' | 'status',
  ) {
    const { id } = branch;
    this.branchesUpdating[id] = type;
    this.carwashBranchService
      .updateBranch(id, body)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => delete this.branchesUpdating[id]),
      )
      .subscribe(() => {
        cd();
      });
  }

  openAreaCoverageDialog(branch: CarwashBranch = null): void {
    this.dialog
      .open(AreaCoverageDialogComponent, branch.id)
      .afterClosed$.pipe(filter(Boolean))
      .subscribe();
  }

  openOnlineProfileDialog(branch: CarwashBranch = null): void {
    this.dialog
      .open(OnlineProfileComponent, branch.id)
      .afterClosed$.pipe(filter(Boolean))
      .subscribe();
  }

  getPage(pageNumber: number) {
    this.loadBranches(pageNumber);
  }

  private initVariables(): void {
    this.isProduction = environment.production;
    this.companyId = Number(this.activatedRoute.snapshot.params.id);
    this.isMobileType =
      this.activatedRoute.snapshot.queryParams.isMobileType === 'true';
    this.groupId = +this.activatedRoute.snapshot.queryParams.groupId;
    this.checkCompanySelectedGroup();
    this.setPagination();
    this.loadBranches();
  }

  private checkCompanySelectedGroup(): void {
    const companySelectedGroup = localStorage.getItem('companySelectedGroup');
    if (!this.groupService.selectedGroup && companySelectedGroup) {
      this.groupService.selectedGroup = JSON.parse(companySelectedGroup);
    }
  }

  private setPagination(): void {
    this.pagination$ = this.carwashBranchService.pagination$;
  }

  private loadBranches(page?: number): void {
    const groupId = this.groupService.selectedGroup?.id;
    if (!groupId) {
      this.router.navigate(['/dashboard/carwash/companies/list']);
    } else {
      this.branches$ = this.carwashBranchService.getBranches({
        cwGarageId: this.companyId.toString(),
        groupId: groupId?.toString(),
        ...(page ? { page: page.toString() } : {}),
      });
    }
  }
}
