import { Router } from '@angular/router';
import { AppHeaders } from '../models/user/generic-backend';
import { GlobalService } from '../service/global.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { GlobalConfig, ToastService } from 'ng-uikit-pro-standard';
import { catchError } from 'rxjs/operators';
import { ERROR_HANDLERS } from './error-codes';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(
    private globalService: GlobalService,
    private toast: ToastService,
    private router: Router,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    let headers: AppHeaders = this.globalService.headers;

    // add all the extra keys to the header
    request.headers.keys().map(key=> {
      headers[key] = request.headers.get(key)
      return key}
    )

    if (!request.headers.get('group_id')) {
      headers = { ...headers, group_id: '1' };
    }

    if (request.headers.get('image_type')) {
      headers = { ...headers, ...this.globalService.headersImage };
    }

    const authReq = request.clone({
      headers: new HttpHeaders(JSON.parse(JSON.stringify(headers))),
    });

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage: string =
          error?.error?.message || error?.error?.exception || error.message;
        const errorCode: number = error.status;

        if (ERROR_HANDLERS[errorCode]) {
          const lang = localStorage.getItem('language') || 'en';
          errorMessage = ERROR_HANDLERS[errorCode]?.messageTranslation[lang];
          this.showErrorToast(errorMessage);
          if (ERROR_HANDLERS[errorCode].handlerName) {
            const result = this[ERROR_HANDLERS[errorCode].handlerName]();
            if (result) {
              return result as Observable<any>;
            }
          }
        } else {
          this.showErrorToast(errorMessage);
        }
        return throwError(error);
      }),
    );
  }

  private showErrorToast(message: string): void {
    if (!this.globalService.stopShowingErrors) {
      this.toast.error(message, 'Sorry!', this.getCommonSetting());
    }
  }

  private getCommonSetting(): GlobalConfig {
    const options: GlobalConfig = this.toast.toastConfig;
    options.preventDuplicates = true;
    options.closeButton = true;
    return options;
  }

  //#region Error Handlers (same name as in error-codes.ts file)

  logout(): void {
    localStorage.clear();
    setTimeout(() => this.router.navigate(['/login']), 2000);
  }

  goHome(): void {
    setTimeout(
      () => this.router.navigate(['/dashboard/orders/orders-list']),
      1000,
    );
  }

  //#endregion
}
