import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BackEndResponse } from '../models/global/response';
import { tap } from 'rxjs/operators';
import {
  SupplierToBeSaved,
  SupplierAreaToBeSaved
} from '../models/laundries/suppliers';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {
  readonly URL_SUPPLIERS = environment.serverUrl + 'suppliers';
  readonly URL_CHANGE_STATUS =
    environment.serverUrl + 'suppliers/activate-supplier';
  readonly URL_SAVE_AREA = environment.serverUrl + 'suppliers/areas';
  readonly URL_AREA = environment.serverUrl + 'suppliers/areas';
  readonly URL_SUPPLIER_PAYMENT = environment.serverUrl + 'suppliers/payments';
  constructor(private http: HttpClient) {}

  getSuppliers(headers, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_SUPPLIERS, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params })
      })
      .pipe(tap(data => console.log(data)));
  }

  saveUpdate(headers, body: SupplierToBeSaved) {
    return this.http
      .post<BackEndResponse>(this.URL_SUPPLIERS, body, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  deleteSupplier(headers, id: number) {
    return this.http
      .delete<BackEndResponse>(this.URL_SUPPLIERS + '/' + id, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  changeStatus(headers, body: { id: number; status: string }) {
    return this.http
      .post<BackEndResponse>(this.URL_CHANGE_STATUS, body, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  getSupplierAreas(headers, supplierId: number) {
    return this.http
      .get<BackEndResponse>(this.URL_SAVE_AREA + '/' + supplierId, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  saveSupplierAreas(headers, body: SupplierAreaToBeSaved) {
    return this.http
      .post<BackEndResponse>(this.URL_SAVE_AREA, body, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  paySupplier(headers, body: { supplier_id: number, amount: number }) {
    return this.http
      .post<BackEndResponse>(this.URL_SUPPLIER_PAYMENT, body, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }
}
