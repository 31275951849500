// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body {
  min-height: 20rem;
  max-height: 40rem;
  min-width: 800px;
}

.working-hours-service-times {
  margin-bottom: 15px;
}

.column {
  column-count: 2;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/crud/shared/action-components/service-payment-methods/service-payment-methods.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,iBAAA;EACA,gBAAA;AACJ;;AAEE;EACE,mBAAA;AACJ;;AAEE;EACE,eAAA;AACJ","sourcesContent":[".modal-body {\n    min-height: 20rem;\n    max-height: 40rem;\n    min-width: 800px;\n  }\n  \n  .working-hours-service-times {\n    margin-bottom: 15px;\n  }\n  \n  .column {\n    column-count: 2;\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
