// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uploaded-file--height {
  height: 50px;
}
.uploaded-file--image {
  width: 50px;
  height: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/sales/components/kpi-achievement/kpi-achievement.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".uploaded-file--height {\n  height: 50px;\n}\n.uploaded-file--image {\n  width: 50px;\n  height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
