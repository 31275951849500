// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      .update-branch-loader {
        min-width: 72px;
      }
    `, "",{"version":3,"sources":["webpack://./src/app/modules/carwash/modules/carwash-branch/components/carwash-branch-list/carwash-branch-list.component.ts"],"names":[],"mappings":";MACM;QACE,eAAe;MACjB","sourcesContent":["\n      .update-branch-loader {\n        min-width: 72px;\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
