export interface BranchBillingList {
  id: number;
  invoice_date: Date;
  description: string;
  invoice_cost: number;
  laundry_branch_id: number;
  compensations: any[];
  bill_type: string;
  discount_by_justclean: number;
  discount_by_laundry: number;
}

export interface Document {
  name: string;
  file: string;
}

export class BillToBeSaved {
  invoice_type_id: number;
  laundry_branch_id: number;
  invoice_date: string;
  document: Document;
  discount_by_justclean: number;
  discount_by_laundry: number;
  description: string;
  invoice_cost: number;
}

export interface BillListQueryParams {
  laundry_branch_id?: number;
  page?: number;
  startdate?: string;
  enddate?: string;
}
