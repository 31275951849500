// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.permissions-modal-container {
  max-width: inherit;
  width: 90vw;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/crud/components/manage-permissions/manage-permissions.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;AACJ","sourcesContent":[".permissions-modal-container {\n    max-width: inherit;\n    width: 90vw;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
