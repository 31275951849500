// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  margin: 15px 0;
}

.title-row {
  font-weight: bold;
}

.file-field {
  margin-bottom: 25px;
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/carwash/modules/carwash-branch/shared/components/carwash-time-range/carwash-time-range.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[":host {\n  display: block;\n  margin: 15px 0;\n}\n\n.title-row {\n  font-weight: bold;\n}\n\n.file-field {\n  margin-bottom: 25px;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
