import { GlobalService } from 'src/app/shared/service/global.service';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import moment from 'moment/moment';

import { OpenHours } from '@shared/models';
import { timePattern } from '@shared/helpers/time-pattern';
import { timeRangeOpenHours } from '@app/modules/carwash/modules/carwash-branch/shared/components/carwash-time-range/carwash-time-range.data';

export interface TimeRangeOpenHour extends OpenHours {
  dayName: string;
}

@Component({
  selector: 'app-carwash-time-range',
  templateUrl: './carwash-time-range.component.html',
  styleUrls: ['./carwash-time-range.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarwashTimeRangeComponent implements OnInit, OnDestroy {
  @Input() openHours: OpenHours[] | undefined;
  @Output() hoursChange: EventEmitter<OpenHours[]> = new EventEmitter<
    OpenHours[]
  >();

  form: UntypedFormArray;
  readonly timeRangeOpenHours: TimeRangeOpenHour[] = timeRangeOpenHours;

  private destroy$: Subject<void> = new Subject<void>();
  minOpeningTime: string;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly global: GlobalService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.handleFormChange();
    this.minOpeningTime = `0${this.global.getTimezoneDifference()}:00`;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addMinute(value: string): string {
    return moment(value, 'HH:mm').add(1, 'minutes').format('HH:mm');
  }

  private initForm(): void {
    this.form = this.fb.array([
      ...this.timeRangeOpenHours.map((timeRangeOpenHour: TimeRangeOpenHour) =>
        this.fb.group({
          dayId: timeRangeOpenHour.dayId,
          openTime: [
            timeRangeOpenHour.openTime,
            [Validators.pattern(timePattern), Validators.required],
          ],
          closeTime: [
            timeRangeOpenHour.closeTime,
            [Validators.pattern(timePattern), Validators.required],
          ],
          isOpen: false,
        }),
      ),
    ]);

    this.setForm();
  }
  private setForm(): void {
    if (!this.openHours) {
      return;
    }

    this.form.patchValue(this.openHours, { emitEvent: false });
  }

  private handleFormChange(): void {
    this.form.valueChanges
      .pipe(debounceTime(500), takeUntil(this.destroy$))
      .subscribe((form: OpenHours[]) => {
        this.hoursChange.emit(form);
      });
  }
}
