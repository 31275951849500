import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { CarwashStation } from '@app/modules/carwash/modules/carwash-station/shared/model/carwash-station.model';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { Nullable } from '@shared/models/nullable.model';
import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { CarwashStationService } from '@app/modules/carwash/modules/carwash-station/shared/services/carwash-station.service';

@Component({
  selector: 'app-carwash-station-edit-dialog',
  templateUrl: './carwash-station-edit-dialog.component.html',
  styleUrls: ['./carwash-station-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarwashStationEditDialogComponent implements OnInit, OnDestroy {
  isEditMode: boolean;
  isLoading: boolean;
  form: UntypedFormGroup;
  translationKey = 'STATION';

  private readonly destroy$: Subject<void> = new Subject<void>();

  get isDisableButton(): boolean {
    return this.isLoading || this.form.invalid;
  }
  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: {
      station: Nullable<CarwashStation>;
      branchId: number;
      isMobileType: boolean;
    } | any,
    private readonly dialogRef: DialogRef<CarwashStationEditDialogComponent>,
    private readonly fb: UntypedFormBuilder,
    private readonly carwashStationService: CarwashStationService,
  ) {}

  ngOnInit(): void {
    this.isEditMode = !!this.data.station;
    if (this.data.isMobileType) {
      this.translationKey = 'VEHICLE';
    }
    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(): void {
    this.dialogRef.close();
  }

  editStation(): void {
    const branchId: number = this.data.branchId;
    const data: Partial<CarwashStation> = this.form.value;
    const action$ = this.isEditMode
      ? this.carwashStationService.updateStations(this.data.station.id, data)
      : this.carwashStationService.createStations(branchId, data);

    this.isLoading = true;

    action$
      .pipe(takeUntil(this.destroy$))
      .subscribe((station: CarwashStation) => {
        this.dialogRef.close(station);
      });
  }

  private initForm(): void {
    this.form = this.fb.group({
      name: this.fb.group({
        en: ['', [Validators.required]],
        ar: ['', [Validators.required]],
      }),
    });

    this.patchForm();
  }

  private patchForm(): void {
    if (!this.isEditMode) {
      return;
    }

    this.form.patchValue(this.data.station);
  }
}
