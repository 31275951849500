import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from 'src/app/shared/service/global.service';
import {
  AdvertisementToBeSaved,
  SplashAreas,
} from 'src/app/shared/models/laundries/branch-ads';
import { IMyOptions, ToastService } from 'ng-uikit-pro-standard';
import { CategoriesService } from 'src/app/shared/service/categories.service';
import { UploaderOptions } from 'ng-uikit-pro-standard/lib/pro/file-input/classes/mdb-uploader.class';
import { BranchesService } from 'src/app/shared/service/branches.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BrArea } from 'src/app/shared/models/laundries/branch';
import { AdsService } from 'src/app/shared/service/ads.service';
import { NgForm, UntypedFormControl, Validators } from '@angular/forms';
import { SelectedReason, Reason } from 'src/app/shared/models/orders/order';
import { ArrayFunctions } from 'src/app/shared/helpers/array-functions';
import { ApiLibrary } from 'src/app/shared/helpers/http-params';

@Component({
  selector: 'app-splash-banner',
  templateUrl: './splash-banner.component.html',
  styleUrls: ['./splash-banner.component.css'],
})
export class SplashBannerComponent implements OnInit {
  branchId: number;
  laundryId: number;
  branchName: string;
  isUpdate: boolean;
  contractData: AdvertisementToBeSaved;
  expiryDateOption: IMyOptions;
  billToOption = [
    {
      label: 'Laundry',
      value: 'laundry',
    },
    {
      label: 'JustClean',
      value: 'justclean',
    },
  ];
  splashEnglishFile: File;
  splashArabicFile: File;
  contractFile: File;
  splashEnglishError: {
    error: string;
    success: string;
  };
  splashArabicError: {
    error: string;
    success: string;
  };
  contractFileError: {
    error: string;
    success: string;
  };
  loader = {
    en: false,
    ar: false,
    contract: false,
    saveLoader: false,
    cancel: false,
    openReason: false,
  };
  splashFileOptions: UploaderOptions;
  contractFileOptions: UploaderOptions;
  currency: string;
  filteredAreas: SplashAreas[];
  searchText: string;
  decimal: number;
  selectedReason: SelectedReason;
  isReasonModalOpened = false;
  isConfirmationModalOpened = false;
  reasons: Reason[];
  commentControl: UntypedFormControl;

  @ViewChild('form', { static: false }) form: NgForm;
  constructor(
    private globalService: GlobalService,
    private catservice: CategoriesService,
    private route: ActivatedRoute,
    private router: Router,
    private branchService: BranchesService,
    private toast: ToastService,
    private adsService: AdsService,
  ) {}

  ngOnInit() {
    this.branchId = this.route.snapshot.params.branchId;
    this.laundryId = this.route.parent.snapshot.params.id;
    this.branchName = this.route.snapshot.queryParams.branch;
    this.currency = this.globalService.currency;
    this.decimal = parseInt(localStorage.getItem('decimal'), 10);
    this.commentControl = new UntypedFormControl(null, [Validators.required]);
    this.getAdsData();
  }

  showDate(date: string, isStart: boolean) {
    return date
      ? this.globalService.formatDate(date, 'DD-MM-YYYY')
      : isStart
      ? 'Select Start Date'
      : 'Select Expiry Date';
  }

  startDateChanged(event) {
    const date = this.globalService.formatDate(
      this.globalService.datePlusDays(event.actualDateFormatted, 0).toString(),
      'YYYY-MM-DD',
    );
    this.contractData.start_date = date;
    this.expiryDateOption = {
      disableUntil: {
        year: parseInt(date.split('-')[0], 10),
        month: parseInt(date.split('-')[1], 10),
        day: parseInt(date.split('-')[2], 10),
      },
    };
    console.log(this.contractData.start_date);

    this.calculateCPD();
  }

  endDateChanged(event) {
    const date = this.globalService.formatDate(
      this.globalService.datePlusDays(event.actualDateFormatted, 0).toString(),
      'YYYY-MM-DD',
    );
    this.contractData.end_date = date;
    this.calculateCPD();
  }

  loadSplashEnglish(event): void {
    this.splashEnglishError = {
      error: null,
      success: null,
    };
    this.splashEnglishFile = event.target.files[0];
    if (this.splashEnglishFile.size / 1024 <= 200) {
      this.loader.en = true;
      this.catservice
        .imageUpload(this.splashEnglishFile, 'advertisements/splash/en')
        .subscribe(
          (response) => {
            if (response.code === 200) {
              this.splashEnglishError.error = null;
              this.splashEnglishError.success = response.message;
              this.contractData.advertisements[0] = {
                name: this.splashEnglishFile.name,
                type: 'splash',
                image: response.data,
                language_id: 1,
              };
              this.loader.en = false;
            } else {
              this.splashEnglishError = {
                error: response.message,
                success: null,
              };
              this.loader.en = false;
            }
          },
          (error) => {
            this.splashEnglishError = {
              error: error.error.message,
              success: null,
            };
            this.loader.en = false;
          },
        );
    } else {
      this.splashEnglishError = {
        error: 'file size must be less or equals 200 kb',
        success: null,
      };
    }
  }

  loadSplashArabic(event): void {
    this.splashArabicError = {
      error: null,
      success: null,
    };
    this.splashArabicFile = event.target.files[0];
    if (this.splashArabicFile.size / 1024 <= 200) {
      this.loader.ar = true;
      this.catservice
        .imageUpload(this.splashArabicFile, 'advertisements/splash/ar')
        .subscribe(
          (response) => {
            if (response.code === 200) {
              this.splashArabicError.error = null;
              this.splashArabicError.success = response.message;
              this.contractData.advertisements[1] = {
                name: this.splashArabicFile.name,
                type: 'splash',
                image: response.data,
                language_id: 2,
              };
              this.loader.ar = false;
            } else {
              this.splashArabicError = {
                error: response.message,
                success: null,
              };
              this.loader.ar = false;
            }
          },
          (error) => {
            this.splashArabicError = {
              error: error.error.message,
              success: null,
            };
            this.loader.ar = false;
          },
        );
    } else {
      this.splashArabicError = {
        error: 'file size must be less or equals 200 kb',
        success: null,
      };
    }
  }

  loadContractDocument(event): void {
    this.contractFileError = {
      error: null,
      success: null,
    };
    this.contractFile = event.target.files[0];
    if (this.contractFile.size / 1024000 <= 2) {
      this.loader.contract = true;
      this.catservice
        .imageUpload(this.contractFile, 'advertisements/contract')
        .subscribe(
          (response) => {
            if (response.code === 200) {
              this.contractFileError.error = null;
              this.contractFileError.success = response.message;
              this.contractData.contract = {
                image: response.data,
                name: this.contractFile.name,
              };
              this.loader.contract = false;
            } else {
              this.contractFileError = {
                error: response.message,
                success: null,
              };
              this.loader.contract = false;
            }
          },
          (error) => {
            this.contractFileError = {
              error: error.error.message,
              success: null,
            };
            this.loader.contract = false;
          },
        );
    } else {
      this.contractFileError = {
        error: 'The contract file must be less or equals 2mb',
        success: null,
      };
    }
  }

  removeEnglishSplash() {
    this.splashEnglishFile = new File([''], 'Upload The English Splash(*)');
    this.contractData.advertisements[0] = {
      name: null,
      type: null,
      image: null,
      language_id: 1,
    };
    this.splashEnglishError = null;
  }

  removeArabicSplash() {
    this.splashArabicFile = new File([''], 'Upload The Arabic Splash(*)');
    this.contractData.advertisements[1] = {
      name: null,
      type: null,
      image: null,
      language_id: 2,
    };
    this.splashArabicError = null;
  }

  removeContractFile() {
    this.contractFile = new File([''], 'Upload the Contract(*)');
    this.contractData.contract = null;
    this.contractFileError = null;
  }

  filterAreas() {
    this.filteredAreas = JSON.parse(
      JSON.stringify(this.contractData.areas),
    ).filter((e) =>
      e.name.toLowerCase().includes(this.searchText.toLowerCase()),
    );
  }

  getAreaById(id: number) {
    return this.contractData.areas.find((e) => e.area_id === id);
  }

  toggleCpdAndroid(id: number) {
    const area = this.getAreaById(id);
    area.cpd_android = 0;
    area.has_android = !area.has_android;
    this.calculateCPD();
  }

  toggleCpdIos(id: number) {
    const area = this.getAreaById(id);
    area.cpd_ios = 0;
    area.has_ios = !area.has_ios;
    this.calculateCPD();
  }

  calculateCPD() {
    const areas = JSON.parse(JSON.stringify(this.contractData.areas));
    this.contractData.total_cpd_android = areas
      .filter((e) => e.has_android)
      .reduce((sum, current) => {
        return sum + current.cpd_android;
      }, 0);
    this.contractData.total_cpd_ios = areas
      .filter((e) => e.has_ios)
      .reduce((sum, current) => {
        return sum + current.cpd_ios;
      }, 0);
    this.contractData.total_cpd =
      this.contractData.total_cpd_android + this.contractData.total_cpd_ios;
    this.contractData.total_contract_cost =
      this.contractData.total_cpd * this.contractDays();
    this.validate();
  }

  contractDays(): number {
    return this.contractData &&
      this.contractData.start_date &&
      this.contractData.end_date
      ? this.globalService.timeDiff(
          this.contractData.start_date.toString(),
          this.contractData.end_date.toString(),
          'days',
        )
      : 0;
  }

  saveContract() {
    this.loader.saveLoader = true;
    this.contractData.laundry_branch_id = this.branchId;
    const body = this.contractData;
    body.areas = JSON.parse(JSON.stringify(this.contractData.areas)).filter(
      (e) => e.has_android || e.has_ios,
    );
    this.adsService.createBranchAds(this.globalService.headers, body).subscribe(
      (response) => {
        if (response.code === 200) {
          this.toast.success(response.message);
          window.location.reload();
        } else {
          this.toast.error(response.message);
        }
        this.loader.saveLoader = false;
      },
      (error) => {
        this.loader.saveLoader = false;
      },
    );
  }

  validate() {
    return (
      !!this.contractData &&
      !!this.contractData.advertisements &&
      !!this.contractData.contract &&
      !!this.contractData.bill_to &&
      !!this.contractData.start_date &&
      !!this.contractData.end_date &&
      !!this.contractData.advertisements[0].image &&
      !!this.contractData.advertisements[1].image &&
      !!this.contractData.contract.image &&
      !!this.form &&
      this.form.valid
    );
  }

  openReasonModal(event) {
    this.getReasons({ type: 'cancel' });
  }

  openConfirmationModal(event) {
    this.isConfirmationModalOpened = true;
  }

  closeReasonModal(event) {
    this.isReasonModalOpened = false;
    this.selectedReason = null;
    this.commentControl.setValue(null);
  }

  closeConfirmationModal(event) {
    this.isConfirmationModalOpened = false;
  }

  isReasonButtonDisabled() {
    if (this.selectedReason) {
      return (
        (this.selectedReason && this.reasonHasSubs(this.selectedReason.reason)
          ? this.selectedReason.reason > 0 && this.selectedReason.sub > 0
          : this.selectedReason.reason > 0) &&
        this.commentControl.valid &&
        this.commentControl.value
      );
    } else {
      return false;
    }
  }

  selectedReasonAndSub(event) {
    this.selectedReason = event;
  }

  cancelContract() {
    this.loader.cancel = true;
    this.adsService
      .stopBranchAds(
        this.globalService.headers,
        new ApiLibrary().cleanObjectFromComponentWithMinusValue({
          advertisement_id: this.contractData.id,
          advertisement_reason_id: this.selectedReason.reason,
          advertisement_sub_reason_id: this.selectedReason.sub,
          comment: this.commentControl.value,
        }),
      )
      .subscribe((response) => {
        if (response.code === 200) {
          this.getAdsData();
          this.toast.success(response.message);
          this.closeConfirmationModal(false);
          this.closeReasonModal(false);
        } else {
          this.toast.error(response.message);
        }
      });
  }

  goBack() {
    this.router.navigate([
      '/dashboard/' +
        window.localStorage.getItem('module') +
        '/branch-list/' +
        this.laundryId,
    ]);
  }

  private initData() {
    this.splashEnglishFile = new File([''], 'Upload The English Splash(*)');
    this.splashArabicFile = new File([''], 'Upload the Arabic  Splash(*)');
    this.contractFile = new File([''], 'Upload the Contract(*)');
    this.splashFileOptions = {
      allowedContentTypes: ['jpg', 'jpeg', 'png'],
      concurrency: 1,
      maxUploads: 1,
    };
    this.contractFileOptions = {
      allowedContentTypes: ['pdf'],
      concurrency: 1,
      maxUploads: 1,
    };
  }

  private initAds() {
    this.contractData.advertisements = [
      {
        name: null,
        type: 'splash',
        image: null,
        language_id: 1,
      },
      {
        name: null,
        type: 'splash',
        image: null,
        language_id: 2,
      },
    ];
  }

  private getBranchAreas() {
    this.branchService
      .getBranchAr(this.globalService.headers, this.branchId, {
        is_selected: true,
      })
      .subscribe((response) => {
        if (response.code === 200) {
          const areas = response.data as BrArea[];
          this.contractData.areas = areas.map((e) => {
            return {
              area_id: e.id,
              cpd_android: 0,
              cpd_ios: 0,
              has_android: false,
              has_ios: false,
              name: e.name,
            } as SplashAreas;
          });
          this.filteredAreas = this.contractData.areas;
        } else {
          this.toast.error(response.message);
        }
      });
  }

  private getAdsData() {
    this.adsService
      .getBranchAds(this.globalService.headers, this.branchId)
      .subscribe((response) => {
        if (response.code === 200) {
          if (response.data) {
            this.contractData = response.data;
            this.filteredAreas = this.contractData.areas;
            this.splashEnglishFile = new File(
              ['<img src=' + this.contractData.advertisements[0].image],
              'english-splash',
            );
            this.splashArabicFile = new File(
              ['<img src=' + this.contractData.advertisements[1].image],
              'arabic-splash',
            );
            this.contractFile = new File(
              ['<img src=' + this.contractData.contract.image],
              'contract-splash',
            );
            this.isUpdate = true;
          } else {
            this.contractData = new AdvertisementToBeSaved();
            this.initData();
            this.initAds();
            this.getBranchAreas();
            this.isUpdate = false;
          }
        } else {
          this.toast.error(response.message);
        }
      });
  }

  private getReasons(params?: any) {
    this.loader.openReason = true;
    this.adsService
      .getStopBranchAdsReasons(this.globalService.headers, params)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.reasons = response.data;
            this.isReasonModalOpened = true;
          } else {
            this.toast.error(response.message);
          }
          this.loader.openReason = false;
        },
        (error) => {
          this.loader.openReason = false;
        },
      );
  }

  private reasonHasSubs(id: number) {
    const reasonIndex = ArrayFunctions.findElementInArray<Reason>(
      this.reasons,
      id,
      true,
      'id',
    );
    const reason = this.reasons[reasonIndex];
    return reason.sub_reasons && reason.sub_reasons.length > 0;
  }
}
