import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CrudHttpService } from '@shared/service/crud/crud-http.service';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { APIConfig, Crud, CrudResponse, Parameter } from '@shared/models';
import { SelectOptions } from '@app/shared/models/global/response';
import { PaginationHelper } from '@app/shared/helpers/pagination-helper';
import { PageInfo } from '@shared/models/global/response';
import { plural } from 'pluralize';
import { LoggedinUser } from '@app/shared/models/user/user';

@Injectable({ providedIn: 'root' })
export class CrudService {
  readonly pagination$: Subject<PageInfo> = new Subject<PageInfo>();
  readonly genericPagination$: Subject<PageInfo> = new Subject<PageInfo>();
  readonly loadCrudSubject:Subject<void>=new Subject();
  allPages: Array<Crud>;
  selectedCrud: Crud;
  isSuperAdmin: boolean;
  user: LoggedinUser;

  constructor(private readonly crudHttpService: CrudHttpService) {}

  getCruds(): Observable<Crud[]> {
    return this.crudHttpService.getCruds();
  }

  getActivePages(): Observable<Crud[]> {
    return this.crudHttpService.getActivePages().pipe(
      map((res) => {
        this.allPages = res;
        this.loadCrudSubject.next();
        return res.filter((page) => page.isActive);
      }),
    );
  }

  createCrud(data: Partial<Crud>): Observable<Crud> {
    const toData = (res: ApiResponse<CrudResponse>) => {
      res.data.configuration.id = res.data.id;
      return res.data.configuration;
    };

    return this.crudHttpService.createCrud(data).pipe(map(toData));
  }

  updateCrud(id: number, data: Partial<Crud>): Observable<Crud> {
    const toData = (res: ApiResponse<CrudResponse>) => {
      res.data.configuration.id = res.data.id;
      return res.data.configuration;
    };

    return this.crudHttpService.updateCrud(id, data).pipe(map(toData));
  }

  getMappedParameters(params: Array<any>): Parameter {
    const extraParameters: Parameter = {};
    params.forEach((param) => {
      extraParameters[Object.keys(param)[0]] = param[Object.keys(param)[0]];
    });
    return extraParameters;
  }

  getReversedParameters(params: Parameter = {}): Array<Parameter> {
    const extraParameters: Array<any> = [];
    Object.keys(params).forEach((key) => {
      extraParameters.push({ [key]: params[key] });
    });
    return extraParameters;
  }

  getDropdownData(
    apiConfig: APIConfig,
  ): Observable<Array<SelectOptions<number>>> {
    return this.crudHttpService.getDropdownData(apiConfig);
  }

  /** Method for generic loading data of the page */
  getPageData(foreignKeyParam?: any, page?: number): Observable<Crud[]> {
    const toData = (res: ApiResponse<Crud[]>) => res.data;

    const toPagination = (res: ApiResponse<Crud[]>) =>
      this.genericPagination$.next(
        PaginationHelper.camelToSnakeCase(res.pageInfo),
      );

    return this.crudHttpService
      .getPageData(this.selectedCrud.apiConfig, foreignKeyParam, page)
      .pipe(tap(toPagination), map(toData));
  }

  /** Method for generic loading data of the page */
  getSingleRow(id: number, apiConfig: APIConfig): Observable<any> {
    const toData = (res: ApiResponse<Crud[]>) =>
      res.data?.length ? res.data[0] : res.data;

    return this.crudHttpService.getSingleRow(id, apiConfig).pipe(map(toData));
  }

  /** Method for generic createing data */
  create(body: any): Observable<any> {
    return this.crudHttpService.create(
      (this.selectedCrud.customAPIConfigs?.POST as APIConfig).path
        ? (this.selectedCrud.customAPIConfigs?.POST as APIConfig)
        : this.selectedCrud.apiConfig,
      body,
    );
  }

  /** Method for generic updating data */
  update(id: number, body: any): Observable<any> {
    return this.crudHttpService.update(
      (this.selectedCrud.customAPIConfigs?.PATCH as APIConfig)?.path
        ? (this.selectedCrud.customAPIConfigs?.PATCH as APIConfig)
        : this.selectedCrud.apiConfig,
      id,
      body,
    );
  }

  /** Method for generic deleting data */
  delete(id: number): Observable<any> {
    return this.crudHttpService.delete(
      (this.selectedCrud.customAPIConfigs?.DELETE as APIConfig)?.path
        ? (this.selectedCrud.customAPIConfigs?.DELETE as APIConfig)
        : this.selectedCrud.apiConfig,
      id,
    );
  }

  /** Method for set the next page configuration using its id (linkedPageId) */
  setPage(linkedPageId: number) {
    this.selectedCrud = this.allPages.find((page) => page.id === +linkedPageId);
  }

  /** Method for get plural name of the page */
  getPluralPageName(pageName: string): string {
    return plural(pageName);
  }

  getMenuItemPageName(page?: Crud, PageNamePrefix?: string): string {
    const pluralName = this.getPluralPageName(
      (page || this.selectedCrud)?.pageName,
    );
    return pluralName.toLowerCase() === PageNamePrefix?.toLowerCase()
      ? PageNamePrefix
      : `${
          PageNamePrefix ||
          (page || this.selectedCrud)?.menuNamePrefix ||
          'List of'
        } ${pluralName}`;
  }

  setSelectedCrud(pageName: string) {
    this.selectedCrud = this.allPages?.find(
      (page) => page.pageName === pageName,
    );
  }

  adjustNumericParameters(parameters: Parameter): void {
    this.crudHttpService.adjustNumericParameters(parameters);
  }
}
