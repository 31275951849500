import { Injectable } from '@angular/core';
import { PaymentToBeCreated } from '../models/payment/payment';
import { BackEndResponse } from '../models/global/response';
import { tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  readonly URL_CREATE_PAYMENT =
    environment.serverUrl + 'setting/add-payment-method';
  readonly URL_GET_PAYMENT =
    environment.serverUrl + 'setting/get-payment-method';

  constructor(protected http: HttpClient) {}

  createPaymentType(headers, payment: PaymentToBeCreated) {
    return this.http
      .post<BackEndResponse>(this.URL_CREATE_PAYMENT, payment, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  getPaymentMethod(headers) {
    return this.http
      .get<BackEndResponse>(this.URL_GET_PAYMENT, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }
}
