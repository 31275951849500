import { Injectable } from '@angular/core';
import { HomecleanCompanyHttpService } from './homeclean-company-http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { HomecleanCompany } from '@app/modules/homeclean/modules/homeclean-company/shared/models';

@Injectable({ providedIn: 'root' })
export class HomecleanCompanyService {
  constructor(
    private readonly homecleanCompanyHttpService: HomecleanCompanyHttpService,
  ) {}

  getCompanies(params: any = {}): Observable<HomecleanCompany[]> {
    return this.homecleanCompanyHttpService.getCompanies(params);
  }

  getCompaniesWithPagination(
    params: any = {},
  ): Observable<ApiResponse<HomecleanCompany[]>> {
    return this.homecleanCompanyHttpService.getCompaniesWithPagination(params);
  }

  createCompany(data: Partial<any>): Observable<HomecleanCompany> {
    const toCompany = (res: ApiResponse<HomecleanCompany>) => res.data;

    return this.homecleanCompanyHttpService
      .createCompany(data)
      .pipe(map(toCompany));
  }

  updateCompany(
    id: number,
    data: Partial<HomecleanCompany>,
  ): Observable<HomecleanCompany> {
    const toCompany = (res: ApiResponse<HomecleanCompany>) => res.data;

    return this.homecleanCompanyHttpService
      .updateCompany(id, data)
      .pipe(map(toCompany));
  }

  removeCompany(id: number): Observable<void> {
    return this.homecleanCompanyHttpService.removeCompany(id);
  }
}
