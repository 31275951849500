import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-driver-management',
  templateUrl: './driver-management.component.html',
  styleUrls: ['./driver-management.component.css']
})
export class DriverManagementComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
