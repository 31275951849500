import { Router, ActivatedRoute } from '@angular/router';
import { GroupService } from '@shared/service/group';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { DialogService } from '@shared/components/dialog/services/dialog.service';
import { Group, Variant } from "@shared/models";
import { Status } from '@shared/enums/status.enum';
import { PageInfo } from '@shared/models/global/response';
import { filter, finalize, map } from 'rxjs/operators';
import { CarWashPackage } from "@shared/models/carwash/car-wash-package.model";
import { CarWashPackageService } from "@shared/service/car-wash-packages";
import { VariantService } from "@shared/service/variant";
import { GlobalService } from "@shared/service/global.service";
import {
  CarWashPackagePricingEditDialogComponent
} from "@app/modules/carwash/modules/car-wash-package-pricing/components/car-wash-package-pricing-edit-dialog/car-wash-package-pricing-edit-dialog.component";

@Component({
  selector: 'app-car-wash-package-list',
  templateUrl: './car-wash-package-pricing-list.component.html',
  styleUrls: ['./car-wash-package-pricing-list.component.scss'],
})
export class CarWashPackagePricingListComponent implements OnInit, OnDestroy {
  readonly Status = Status;
  private subscriber$: Subscription;
  pagination$: Observable<PageInfo>;
  packages: CarWashPackage[];
  isLoading: boolean;
  groups$: Observable<Array<Group>>;
  variants$: Observable<Array<Variant>>;
  currentPage = 1;
  companyTypeMap = {
    inCall: 'Station',
    outCall: 'Mobile',
  };
  selectedGroup: Group;
  cwGarageBranchId: number;
  isMobileType: boolean;
  isLaundryDesign: boolean;
  currency: string = 'KWD';

  constructor(
    private readonly carWashPackageService: CarWashPackageService,
    private readonly groupService: GroupService,
    private readonly globalService: GlobalService,
    private readonly dialog: DialogService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly variantService: VariantService,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.cwGarageBranchId = Number(this.activatedRoute.snapshot.params.id);
    this.checkCompanySelectedGroup();
    this.currency = this.globalService.getCurrency();
    this.pagination$ = this.carWashPackageService.pagination$;
    this.groups$ = this.groupService.getGroups().pipe(
      map((groups) => {
        if (this.selectedGroup) {
          /** This to bind with groups dropdown */
          this.selectedGroup = groups.find(
            ({ id }) => id === this.selectedGroup.id,
          );
        }
        return groups.filter(
          (group) => !this.groupService.checkHomeCleaningDesign(group),
        );
      }),
    );

    if (this.groupService.selectedGroup) {
      this.selectedGroup = this.groupService.selectedGroup;
      this.loadGroupCompanies();
    }
    this.loadVariants()
  }

  loadGroupCompanies(): void {
    localStorage.setItem(
      'companySelectedGroup',
      JSON.stringify(this.selectedGroup),
    );
    this.currentPage = 1;
    this.isMobileType = this.groupService.checkOutCall(this.selectedGroup);
    this.isLaundryDesign = this.groupService.checkLaundryDesign(
      this.selectedGroup,
    );

    if (!this.isLaundryDesign) {
      this.loadData();
    }

    this.router.navigate([`.`], {
      relativeTo: this.route,
      queryParams: { groupId: this.selectedGroup.id },
    });

    this.changeDetectorRef.detectChanges();
  }

  openDialog(carWashPackage: CarWashPackage = null): void {
    this.dialog
      .open(CarWashPackagePricingEditDialogComponent,{
        carWashPackage,
        variants$: this.variants$,
        groupId: this.selectedGroup?.id,
        cwGarageBranchId: this.cwGarageBranchId,
      })
      .afterClosed$.pipe(filter(Boolean))
      .subscribe(() => this.loadData());
  }

  removePackage(id: number): void {
    this.carWashPackageService
      .removePackage(id)
      .subscribe(() => this.loadData());
  }

  getPage(pageNumber: number) {
    this.currentPage = pageNumber;
    this.loadData();
  }

  private checkCompanySelectedGroup(): void {
    const companySelectedGroup = localStorage.getItem('companySelectedGroup');
    if (companySelectedGroup) {
      this.selectedGroup = this.groupService.selectedGroup =
        JSON.parse(companySelectedGroup);
    }
  }

  private loadData(): void {
    this.isLoading = true;
    this.packages = null;
    this.unsubscribe();

    this.subscriber$ = this.carWashPackageService
      .getPackages(this.getParams())
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((data) => (this.packages = data));
  }

  private getParams(): any {
    const params: any = {
      page: this.currentPage,
    };
    if (this.selectedGroup) {
      params.groupId = this.selectedGroup.id.toString();
    }


    return params;
  }

  private unsubscribe(): void {
    if (this.subscriber$) {
      this.subscriber$.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  private loadVariants(): void {
    this.variants$ = this.variantService.getVariants({
      countryId: this.globalService.countryId.toString() ,
      filterGlobalPackages: 'true',
      groupId: this.selectedGroup.id.toString()
    }, this.currentPage);
  }

  mapSubVariantPrice(cwPackageId: number, subVariantId: number): number {
    const selectedPackage = this.packages.find((cwPackage) => { return cwPackage.id === cwPackageId});

    const cwPackagesCwGarageBranch = selectedPackage.cwPackagesCwGarageBranches.find((cwPackagesCwGarageBranch) => cwPackagesCwGarageBranch.subVariantId === subVariantId && cwPackagesCwGarageBranch.cwGarageBranchId === this.cwGarageBranchId);

    return selectedPackage && cwPackagesCwGarageBranch ? cwPackagesCwGarageBranch.price : 0;
  }

}
