import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import {
  CarwashDetail,
  CarwashTicket,
} from '@app/modules/carwash/modules/carwash-packages/shared/models/carwash-ticket-model';
import { Status } from '@app/shared/enums/status.enum';
import { Nullable } from '@shared/models';

@Component({
  selector: 'app-carwash-ticket-form',
  templateUrl: './carwash-package-form.component.html',
  styleUrls: ['./carwash-package-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarwashPackageFormComponent {
  ticketForm: UntypedFormGroup;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  get includeDetails(): UntypedFormArray {
    return this.ticketForm.get('includeDetails') as UntypedFormArray;
  }

  get notIncludeDetails(): UntypedFormArray {
    return this.ticketForm.get('notIncludeDetails') as UntypedFormArray;
  }

  createForm(ticket: Nullable<CarwashTicket>): void {
    this.ticketForm = this.fb.group({
      name: this.fb.group({
        en: ['', [Validators.required, Validators.maxLength(25)]],
        ar: ['', [Validators.required, Validators.maxLength(25)]],
      }),
      operatingName: this.fb.group({
        en: [''],
        ar: [''],
      }),
      includeDetails: this.fb.array(this.setIncludeDetailsInitData(ticket)),
      notIncludeDetails: this.fb.array(
        this.setNotIncludeDetailsInitData(ticket),
      ),
      price: ['', [Validators.required, Validators.min(1)]],
      status: [ticket?.status && this.isActiveStatus(ticket.status)],
      timeToComplete: ['', [Validators.required]],
    });

    this.patchForm(ticket);
  }
  setIncludeDetailsInitData(ticket: Nullable<CarwashTicket>) {
    if (ticket && ticket.details.length) {
      return ticket.details
        .filter((detail) => detail.include)
        .map(() => this.createFormDetail(true));
    } else {
      return [this.createFormDetail(true)];
    }
  }
  setNotIncludeDetailsInitData(ticket: Nullable<CarwashTicket>) {
    if (ticket && ticket.details.length) {
      return ticket.details
        .filter((detail) => !detail.include)
        .map(() => this.createFormDetail());
    } else {
      return [this.createFormDetail()];
    }
  }

  changedTime(time: number): void {
    this.ticketForm.controls.timeToComplete.patchValue(time);
    this.cdr.markForCheck();
  }

  addDetail(isIncludedDetails: boolean = false): void {
    const details: UntypedFormArray = isIncludedDetails
      ? this.includeDetails
      : this.notIncludeDetails;

    details.push(this.createFormDetail(isIncludedDetails));
  }

  removeDetail(index: number, isIncludedDetails: boolean = false): void {
    const details: UntypedFormArray = isIncludedDetails
      ? this.includeDetails
      : this.notIncludeDetails;

    details.removeAt(index);
  }

  private createFormDetail(isInclude: boolean = false): UntypedFormGroup {
    return this.fb.group({
      en: ['', [Validators.maxLength(250)]],
      ar: ['', [Validators.maxLength(250)]],
      include: [isInclude],
    });
  }

  private patchForm(ticket: Nullable<CarwashTicket>): void {
    if (!ticket) {
      return;
    }

    const isIncludeDetail = (detail: CarwashDetail): boolean => detail.include;
    const isNotIncludeDetail = (detail: CarwashDetail): boolean =>
      !detail.include;
    const includeDetails = ticket.details.filter(isIncludeDetail);
    const notIncludeDetails = ticket.details.filter(isNotIncludeDetail);
    const status = this.isActiveStatus(ticket.status);
    const data = {
      ...ticket,
      includeDetails,
      notIncludeDetails,
      status
    };

    this.ticketForm.patchValue(data);
  }

  private isActiveStatus(status: Status): boolean {
    return status === Status.Active;
  }
}
