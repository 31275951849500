// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-item-list {
  width: 400px;
  margin: auto;
}
.create-item-list b, .create-item-list strong {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/create-item-list/create-item-list.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;AACF;AACE;EACE,iBAAA;AACJ","sourcesContent":[".create-item-list {\n  width: 400px;\n  margin: auto;\n\n  b, strong {\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
