export interface BranchArea {
  id: number;
  minimumOrderAmount: number;
  deliveryCharge: number;
  serviceCharge: number;
  sortOrder: number;
  areaId: number;
  laundryBranchId?: number;
  cwGarageBranchId?: number;
  createdAt: string;
  updatedAt: string;
}
