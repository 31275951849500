import { GlobalService } from 'src/app/shared/service/global.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { DialogService } from '@shared/components/dialog/services/dialog.service';
import { ReferralCashoutRequest } from '@shared/models';
import { PageInfo } from '@shared/models/global/response';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { ReferralCashoutRequestService } from '@shared/service/referral-cashout-requests';
import { ReferralCashoutDialogComponent } from '../referral-cashout-requests-edit-dialog/referral-cashout-requests-dialog.component';

@Component({
  selector: 'app-referral-cashout-requests-list',
  templateUrl: './referral-cashout-requests-list.component.html',
  styleUrls: ['./referral-cashout-requests-list.component.scss'],
})
export class ReferralCashoutRequestListComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>() ;
  pagination$: Observable<PageInfo>;
  referralCashoutRequests: Array<ReferralCashoutRequest>;
  currency: string;
  currentPage: number = 1;
  selectedIds: number[] = [];
  searchInputValue: string;
  selectedStatus: string = 'pending';
  statuses = [
    { value: 'approved', label: 'Approved' },
    { value: 'pending', label: 'Pending' },
    { value: 'rejected', label: 'Rejected' },
    { value: '', label: 'All' },
  ];
  searchTextChanged: Subject<string> = new Subject<string>();

  private get countryId(): number {
    return +localStorage.getItem('country');
  }

  constructor(
    private readonly referralCashoutRequestService: ReferralCashoutRequestService,
    private readonly globalService: GlobalService,
    private readonly dialog: DialogService,
  ) {}

  ngOnInit(): void {
    this.loadReferralCashoutRequests();
    this.currency = this.globalService.currency;
    this.pagination$ = this.referralCashoutRequestService.pagination$;
    this.handleSearchChange();
  }

  toggleRow(id: number): void {
    const index = this.selectedIds.indexOf(id);
    if (index === -1) {
      this.selectedIds.push(id);
    } else {
      this.selectedIds.splice(index, 1);
    }
  }

  openDialog(type: 'details' | 'approve' | 'decline', id?: number): void {
    this.dialog
      .open(ReferralCashoutDialogComponent, {
        type,
        selected: id
          ? [
              this.referralCashoutRequests.find(
                ({ id: referralCashoutRequestId }) =>
                  referralCashoutRequestId === id,
              ),
            ]
          : this.referralCashoutRequests.filter(({ id }) =>
              this.selectedIds.includes(id),
            ),
      })
      .afterClosed$.pipe(takeUntil(this.destroy$), filter(Boolean))
      .subscribe(() => this.loadReferralCashoutRequests());
  }

  openDetailsDialog(id: number): void {
    this.openDialog('details', id);
  }

  openApproveDialog(id?: number): void {
    this.openDialog('approve', id);
  }

  openDeclineDialog(id?: number): void {
    this.openDialog('decline', id);
  }

  onInput(event: Event): void {
    if (event.target['value'].trim().length > 2) {
      this.searchTextChanged.next(event.target['value'].trim());
    }
  }

  private handleSearchChange(): void {
    this.searchTextChanged.pipe(debounceTime(1000)).subscribe((value) => {
      this.searchInputValue = value || '';
      this.loadReferralCashoutRequests(1);
    });
  }

  loadReferralCashoutRequests(page?: number): void {
    this.referralCashoutRequests = null;
    this.selectedIds = [];
    this.currentPage = page || this.currentPage;
    this.referralCashoutRequestService
      .getReferralCashoutRequests({
        page: this.currentPage.toString(),
        countryId: this.countryId.toString(),
        ...(this.searchInputValue && {
          search: this.searchInputValue,
        }),
        ...(this.selectedStatus && {
          status: this.selectedStatus,
        }),
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.referralCashoutRequests = data;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
