import { NgModule } from '@angular/core';

import { CarwashCompanyRoutingModule } from './carwash-company-routing.module';
import { CarwashCompanyFormComponent } from '@app/modules/carwash/modules/carwash-company/components/carwash-company-edit-dialog/carwash-company-form/carwash-company-form.component';
import { SharedModule } from '@app/modules/carwash/shared/shared.module';
import { CarwashCompanyEditDialogComponent } from '@app/modules/carwash/modules/carwash-company/components/carwash-company-edit-dialog/carwash-company-edit-dialog.component';
import { CarwashCompanyListComponent } from '@app/modules/carwash/modules/carwash-company/components/carwash-company-list/carwash-company-list.component';
@NgModule({
  imports: [CarwashCompanyRoutingModule, SharedModule],
  declarations: [
    CarwashCompanyListComponent,
    CarwashCompanyFormComponent,
    CarwashCompanyEditDialogComponent,
  ],
})
export class CarwashCompanyModule {}
