import { Status } from '@app/shared/enums/status.enum';
import { HcServiceArea } from '.';

export interface HcArea {
  id: number;
  HcServiceArea: Array<HcServiceArea>;
  cityId: number;
  latitude: number;
  longitude: number;
  name: string;
  status: Status;
}
