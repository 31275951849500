import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  Reason,
  SelectedReason,
  OrderDetails
} from 'src/app/shared/models/orders/order';
import { GlobalService } from 'src/app/shared/service/global.service';
import { OrderService } from 'src/app/shared/service/order.service';
import { ArrayFunctions } from 'src/app/shared/helpers/array-functions';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ApiLibrary } from 'src/app/shared/helpers/http-params';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-waived-order',
  templateUrl: './waived-order.component.html',
  styleUrls: ['./waived-order.component.css']
})
export class WaivedOrderComponent implements OnInit {
  @Input() isWaived: boolean;
  @Input() order: OrderDetails;
  @Input() disabled: boolean;
  @Input() currency: string;
  @Input() decimal: number;
  @Output() waiveEnded: EventEmitter<boolean> = new EventEmitter();
  isOpenReasonModal = false;
  reasons: Reason[];
  selectedReason: SelectedReason;
  valid: boolean;
  // confirm modal data
  isConfirmModalOpen = false;
  commentControl: UntypedFormControl;
  loader = false;
  constructor(
    private global: GlobalService,
    private orderService: OrderService,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.commentControl = new UntypedFormControl(null, [Validators.required]);
  }

  openModal() {
    this.isOpenReasonModal = true;
    if (!this.reasons || this.reasons.length <= 0) {
      this.getReasons({
        type: 'waive'
      });
    }
  }

  confirmSelectReasons() {
    this.isConfirmModalOpen = true;
  }

  cancelReasons() {
    this.isOpenReasonModal = false;
    this.selectedReason = null;
    this.commentControl.reset();
  }

  selectedReasonAndSub(event: SelectedReason) {
    this.selectedReason = event;
    this.validateSubmit();
  }

  validateSubmit() {
    this.valid =
      (this.selectedReason && this.reasonHasSubs(this.selectedReason.reason)
        ? this.selectedReason.reason > 0 && this.selectedReason.sub > 0
        : this.selectedReason.reason > 0) && this.commentControl.valid;
  }

  // confirmation modal functions
  cancelSubmitData() {
    this.isConfirmModalOpen = false;
  }

  isOpsLeaders() {
    const email = localStorage.getItem('email');
    return (
      (email.includes('rcherchali') ||
        email.includes('balqatari') ||
        email.includes('superadmin')
    ));
  }

  submitData() {
    this.loader = true;
    this.orderService
      .waiveOrder(
        this.global.headers,
        new ApiLibrary().cleanObjectFromComponentWithMinusValue({
          order_id: this.order.order_result.id,
          reason_id: this.selectedReason.reason,
          sub_reason_id: this.selectedReason.sub,
          comment: this.commentControl.value
        })
      )
      .subscribe(
        response => {
          if (response.code === 200) {
            this.loader = false;
            this.isConfirmModalOpen = false;
            this.isOpenReasonModal = false;
            this.toast.success(response.message);
            this.waiveEnded.emit(true);
          } else {
            this.toast.error(response.message);
            this.loader = false;
          }
        },
        error => {
          this.loader = false;
        }
      );
  }

  private reasonHasSubs(id: number) {
    const reasonIndex = ArrayFunctions.findElementInArray<Reason>(
      this.reasons,
      id,
      true,
      'id'
    );
    const reason = this.reasons[reasonIndex];
    return reason.sub_reasons && reason.sub_reasons.length > 0;
  }

  private getReasons(params?: any) {
    this.orderService
      .getAllReasons(this.global.headers, params)
      .subscribe(response => {
        if (response.code === 200) {
          this.reasons = response.data;
        }
      });
  }
}
