import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Reviews } from 'src/app/shared/models/customer/reviews';
import { ModalDirective } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-update-review',
  templateUrl: './update-review.component.html',
  styleUrls: ['./update-review.component.css']
})
export class UpdateReviewComponent implements OnChanges {
  @Input()
  review: Reviews.ReviewsFromList;
  @Input()
  isOpen: boolean;
  @Input()
  loaderApprove: boolean;
  @Input()
  loaderReject: boolean;
  @Output()
  updateReview: EventEmitter<any> = new EventEmitter();
  @Output()
  closeModal: EventEmitter<any> = new EventEmitter();
  @ViewChild('updateReview', { static: true })
  modal: ModalDirective;
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if (changes.isOpen && changes.isOpen.currentValue === true) {
      this.modal.show();
    } else {
      this.modal.hide();
    }
  }

  close() {
    this.closeModal.emit(true);
  }

  checkStatus() {}

  triggerUpdate(isApproved: boolean) {
    if (isApproved !== null) {
      this.review.status = isApproved === true ? 'approved' : 'rejected';
    }
    this.updateReview.emit({
      review: this.review,
      isApproved: isApproved
    });
  }
}
