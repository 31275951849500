import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { ReferralCashoutRequest } from '@shared/models';

@Injectable({ providedIn: 'root' })
export class ReferralCashoutRequestHttpService {
  private readonly url = `${environment.jcDeliveryServer}referral-cash-out-requests`;

  constructor(private readonly http: HttpClient) {}

  getReferralCashoutRequests(
    params?: Record<string, string>,
  ): Observable<ApiResponse<ReferralCashoutRequest[]>> {
    return this.http.get<ApiResponse<ReferralCashoutRequest[]>>(this.url, {
      params,
    });
  }

  getReferralCashoutRequest(
    id: number,
  ): Observable<ApiResponse<ReferralCashoutRequest>> {
    return this.http.get<ApiResponse<ReferralCashoutRequest>>(
      `${this.url}/${id}`,
    );
  }

  updateReferralCashoutRequest(
    id: number,
    data: Partial<ReferralCashoutRequest>,
  ): Observable<ApiResponse<ReferralCashoutRequest>> {
    return this.http.patch<ApiResponse<ReferralCashoutRequest>>(
      `${this.url}/${id}`,
      data,
    );
  }
}
