// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: white;
  opacity: 0.6;
  color: #0ba5de;
  z-index: 1;
}

.spinner-border {
  width: 15rem;
  height: 15rem;
  font-size: 5rem;
}

.btn-download {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  width: 5rem;
  height: 5rem;
  padding: 15px 30px;
  border-radius: 100%;
  box-shadow: 0 4px 16px 0 rgba(100, 27, 180, 0.24);
  background-color: #641bb4;
  z-index: 1;
}
.btn-download img {
  width: 3rem;
}

@page {
  size: 7in 9.25in;
  margin: 27mm 16mm 27mm 16mm;
}
.btn-primary {
  background-color: #0ba5de;
  border-color: #0ba5de;
}

.display-qr-code {
  position: fixed;
  top: 6rem;
  right: 1rem;
  z-index: 2;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/finance/components/export-invoices/export-invoices.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;EACA,cAAA;EACA,UAAA;AACJ;;AACA;EACI,YAAA;EACA,aAAA;EACA,eAAA;AAEJ;;AAAA;EACI,eAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,eAAA;EAEA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,iDAAA;EACA,yBAAA;EACA,UAAA;AAEJ;AAAI;EACI,WAAA;AAER;;AAEA;EACI,gBAAA;EACA,2BAAA;AACJ;AAEA;EACI,yBAAA;EACA,qBAAA;AAAJ;;AAGA;EACE,eAAA;EACA,SAAA;EACA,WAAA;EACA,UAAA;AAAF","sourcesContent":[".loading-container {\n    height: 100vh;\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: absolute;\n    background: white;\n    opacity: 0.6;\n    color: #0ba5de;\n    z-index: 1;\n}\n.spinner-border {\n    width: 15rem;\n    height: 15rem;\n    font-size: 5rem;\n}\n.btn-download {\n    position: fixed;\n    bottom: 3rem;\n    right: 3rem;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: white;\n    cursor: pointer;\n\n    width: 5rem;\n    height: 5rem;\n    padding: 15px 30px;\n    border-radius: 100%;\n    box-shadow: 0 4px 16px 0 rgba(100, 27, 180, 0.24);\n    background-color: #641bb4;\n    z-index: 1;\n\n    img {\n        width: 3rem;\n    }\n}\n\n@page {\n    size: 7in 9.25in;\n    margin: 27mm 16mm 27mm 16mm;\n}\n\n.btn-primary {\n    background-color: #0ba5de;\n    border-color: #0ba5de;\n}\n\n.display-qr-code {\n  position: fixed;\n  top: 6rem;\n  right: 1rem;\n  z-index: 2;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
