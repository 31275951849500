import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { CarwashOrder } from '@app/modules/carwash/modules/carwash-order/components/shared/models/carwash-order.model';
import { CarwashOrderService } from '@app/modules/carwash/modules/carwash-order/components/shared/services/carwash-order.service';
import { PageInfo, SelectOptions } from '@shared/models/global/response';
import { CARWASH_ORDER_STATUSES } from '@app/shared/models';

@Component({
  selector: 'app-carwash-order-list',
  templateUrl: './carwash-order-list.component.html',
  styleUrls: ['./carwash-order-list.component.css'],
})
export class CarwashOrderListComponent implements OnInit {
  orders$: Observable<CarwashOrder[]>;
  pagination$: Observable<PageInfo>;
  private params: any = { sortBy: 'id', sortValue: 'DESC' };
  statuses: SelectOptions<string>[];
  selectedStatus: number;
  searchInputValue: string;
  currentPage: number = 1;

  constructor(private readonly carwashOrderService: CarwashOrderService) {}

  ngOnInit(): void {
    this.setStatuses();
    this.orders$ = this.carwashOrderService.getOrders(this.params);
    this.pagination$ = this.carwashOrderService.getPagination();
  }

  getPage(page: number): void {
    if (page !== undefined) {
      this.currentPage = page;
      this.setParams();
      this.orders$ = this.carwashOrderService.getOrders(this.params);
    }
  }

  private setParams() {
    this.params.page = this.currentPage;
    this.params.status = this.selectedStatus;
    this.params.search = this.searchInputValue;

    if (!this.params.search) {
      delete this.params.search;
    }
    if (!this.params.status) {
      delete this.params.status;
    }
  }

  private setStatuses() {
    this.statuses = CARWASH_ORDER_STATUSES;
    this.params.status = this.statuses[0].value;
  }

}
