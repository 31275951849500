import { NgModule } from '@angular/core';
import { DriversListComponent } from './components/drivers-list/drivers-list.component';
import { SharedModule } from '../shared/shared.module';
import { DriversRoutingModule } from './drivers-routing.module';
import { DriversCreateComponent } from './components/drivers-create/drivers-create.component';
import { DriversBookingsLogsComponent } from './components/drivers-bookings-logs/drivers-bookings-logs.component';
import { DriversBookingLogsAdviceComponent } from './components/drivers-bookings-logs/drivers-booking-logs-advice/drivers-booking-logs-advice.component';

@NgModule({
  imports: [SharedModule.forRoot(), DriversRoutingModule],
  declarations: [DriversListComponent, DriversCreateComponent, DriversBookingsLogsComponent, DriversBookingLogsAdviceComponent]
})
export class DriversModule {}
