import { NgModule } from '@angular/core';
import { SharedModule } from '@app/modules/carwash/shared/shared.module';
import { CarWashAddOnsRoutingModule } from "@app/modules/carwash/modules/car-wash-add-ons/car-wash-add-ons-routing.module";

import {
  CarWashAddOnsListComponent
} from "@app/modules/carwash/modules/car-wash-add-ons/components/car-wash-add-ons-list/car-wash-add-ons-list.component";
import {
  CarWashAddOnEditDialogComponent
} from "@app/modules/carwash/modules/car-wash-add-ons/components/car-wash-add-ons-edit-dialog/car-wash-add-on-edit-dialog.component";
import {
  CarWashAddOnFormComponent
} from "@app/modules/carwash/modules/car-wash-add-ons/components/car-wash-add-ons-edit-dialog/carwash-add-on-form/car-wash-add-on-form.component";
@NgModule({
  imports: [CarWashAddOnsRoutingModule, SharedModule],
  declarations: [
    CarWashAddOnsListComponent,
    CarWashAddOnFormComponent,
    CarWashAddOnEditDialogComponent,
  ],
})
export class CarWashAddonsModule {}
