export class Car {
  id: number;
  name: string;
  plate_no: string;
  device_id: string;
  device_token: string;
  device_type: string;
  status: string;
  app_version: string;
  laundry_branch_id: number;
  area_id: number;
}

export class CarSaved {
  id: number;
  name: string;
  plate_no: string;
  device_id: string;
  device_token: string;
  device_type: string;
  status: string;
  app_version: string;
  laundry_branch_id: number;
  area_id: number;
}

export class CarToBeSaved {
  name: string;
  plate_no: string;
  device_id: string;
  laundry_branch_id: number;
  area_id: number;
  car_id?: number;
}

export class IdName {
  id: number;
  name: string;
  status?: boolean;
}

export class NameForCarsList {
  name: string;
}

export class LaundryBranch {
  name: string;
  laundry: NameForCarsList;
}

export class CarList {
  id: number;
  name: string;
  plate_no: string;
  device_id: string;
  device_token: string;
  device_imei: string;
  device_type: string;
  status: string;
  app_version: string;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  laundry_branch_id: number;
  area_id?: any;
  laundry_branch: LaundryBranch;
  area?: NameForCarsList;
}

export class CarDetails {
  id: number;
  name: string;
  plate_no: string;
  device_id: string;
  device_token: string;
  device_imei: string;
  device_type: string;
  status: string;
  app_version: string;
  is_logistics: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  laundry_branch_id: number;
  area_id: number;
  laundry_id: number;
}
