import { UserHomeCleaningFormComponent } from './../user-home-cleaning-form/user-home-cleaning-form.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RolesService } from '@shared/service/roles.service';
import { AppHeaders, GenericBackend } from "@shared/models/user/generic-backend";
import { LaundryService } from '@shared/service/laundry.service';
import { CorporateService } from '@shared/service/corporate.service';

import {
  RolesList,
  CorporateList,
  UserToBeSaved,
  UserToBeUpated,
} from '@shared/models/user/user';
import { GlobalService } from '@shared/service/global.service';
import { BranchList } from '@shared/models/laundries/branch';
import { BranchesService } from '@shared/service/branches.service';
import { UsersService } from '@shared/service/users.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { CountryService } from '@shared/service/country.service';
import { SelectOptions } from '@shared/models/global/response';
import { CountryList } from '@shared/models/countries/country';
import { Department } from '@app/modules/users/shared/enums';
import { UserCarwashFormComponent } from '@app/modules/users/components/user-carwash-form/user-carwash-form.component';
import { map } from "rxjs/operators";
import { Group } from "@shared/models";
import { Observable } from "rxjs";
import { GroupService } from "@shared/service/group";

@Component({
  selector: 'app-create-users',
  templateUrl: './create-users.component.html',
  styleUrls: ['./create-users.component.scss'],
})
export class CreateUsersComponent implements OnInit {
  @ViewChild(UserCarwashFormComponent, { static: false })
  readonly userCarwashFormComponent: UserCarwashFormComponent;
  @ViewChild(UserHomeCleaningFormComponent, { static: false })
  readonly userHomeCleaningFormComponent: UserHomeCleaningFormComponent;

  createUserForm: UntypedFormGroup;
  loader: boolean;
  isLoadingLaundries: boolean = false;
  deps: any[];
  selectedDep: number;
  roles: any[];
  selectedRole: number;
  laundries: any[];
  selectedLaundry: number;
  branches: any[];
  selectedBranch: number;
  corporates: any[];
  selectedCorporate: number;
  showLaundries: boolean;
  showBranches: boolean;
  showCorporates: boolean;
  saveError: string;
  isUpdate: boolean;
  userIdToBeUpdated: UserToBeUpated = new UserToBeUpated();
  countries: SelectOptions<number>[];
  selectedCountry: number;
  selectedCountries: number[] = [];
  multiCountries: SelectOptions<number>[];
  voip: string;
  readonly Department = Department;
  selectedGroup: Group;
  groups$: Observable<Array<Group>>;
  headers: AppHeaders = {};
  isLoadingGroups: boolean;

  get isButtonDisable(): boolean {
    return (
      this.createUserForm.invalid ||
      this.userCarwashFormComponent?.form?.invalid ||
      this.userHomeCleaningFormComponent?.form?.invalid
    );
  }

  constructor(
    public translate: TranslateService,
    private roleService: RolesService,
    private laundryService: LaundryService,
    private countryService: CountryService,
    private corpService: CorporateService,
    private branchService: BranchesService,
    private userService: UsersService,
    private router: Router,
    private route: ActivatedRoute,
    protected globalService: GlobalService,
    private toast: ToastService,
    private readonly formBuilder: UntypedFormBuilder,
    private groupsService: GroupService,
  ) {}

  ngOnInit() {
    this.headers = this.globalService.headers
    this.initVariables();
    this.translate.use(this.translate.defaultLang);
    this.initForm();
    this.handleFormChanges();
    this.getDepartments();
    this.getCountries();
    this.userIdToBeUpdated.id = this.route.snapshot.params.id;
    if (this.userIdToBeUpdated.id > 0) {
      this.isUpdate = true;
      this.getUserForUpdate(this.userIdToBeUpdated.id);
    } else {
      this.isUpdate = false;
    }
    this.loadLaundryGroups();
  }



  getRoles(id?: number) {
    this.roleService
      .getRoles(this.headers, { department_id: id })
      .subscribe((response) => {
        this.roles = [];
        const roles = response.data as RolesList[];
        roles.forEach((element) => {
          this.roles.push({ value: element.id, label: element.name });
        });
      });
  }


  getLaundries() {
    this.isLoadingLaundries = true;
    this.laundryService
      .getLaundriesWithParams(this.headers, { page: 0 })
      .subscribe((response) => {
        this.laundries = [];
        const laun = response.data as GenericBackend[];
        this.showLaundries = true;
        this.showCorporates = false;
        laun.forEach((element) => {
          this.laundries.push({ value: element.id, label: element.name });
        });
        this.isLoadingLaundries = false;
      }, (error) => {
        this.isLoadingLaundries = false;
        // Handle error here
      });
  }


  getCorporates() {
    this.corpService
      .getCorporates(this.headers)
      .subscribe((response) => {
        this.corporates = [];
        const corporates = response.data as CorporateList[];

        corporates.forEach((element) => {
          this.corporates.push({ value: element.id, label: element.name });
        });
        this.showCorporates = true;
        this.showLaundries = false;
      });
  }

  getBranches() {
    this.branchService
      .getBranchList(this.headers, this.selectedLaundry)
      .subscribe((response) => {
        this.branches = [];
        const branches = response.data as BranchList[];
        branches.forEach((ele) => {
          this.branches.push({
            value: ele.id,
            label: ele.name,
          });
        });
      });
  }

  getCountries() {
    this.countryService
      .getCountries(this.headers)
      .subscribe((response) => {
        this.countries = [];
        const countries = response.data as CountryList[];
        countries.forEach((country) =>
          this.countries.push({
            value: country.id,
            label: country.name.en,
          }),
        );
      });
  }

  depChanges(event) {
    if (event) {
      this.getRoles(event);
    }
  }

  roleChanges(event) {
    console.log(event);

    if (event === 3 || event === 6) {
      this.getLaundries();
    }
    if (event === 4) {
      this.getCorporates();
    }
    if (!event) {
      this.createUserForm.setErrors({ role: true });
    }
    if (event) {
      this.createUserForm.setErrors(null);
    }
    if (event !== 3 && event !== 4) {
      this.showCorporates = false;
      this.showLaundries = false;
    }

    if (this.selectedDep === 6) {
      this.getLaundries();
      this.createUserForm.setErrors({ laundry: true });
      this.showLaundries = true;
      if (event === 1) {
        this.branches = null;
      }
    }
    if (
      this.selectedDep === 6 &&
      (this.selectedRole === 2 ||
        this.selectedRole === 4 ||
        this.selectedRole === 5 ||
        this.selectedRole === 6)
    ) {
      this.createUserForm.controls.pin.setValidators([
        Validators.pattern(/^[0-9]{4}$/),
        Validators.required,
      ]);
    }
    if (this.showVoip()) {
      this.createUserForm.controls.voip.setValidators([Validators.required]);
    }
    this.validateForm();
  }

  validateForm() {
    if (this.selectedRole === 3 && !this.selectedLaundry) {
      this.createUserForm.setErrors({ laundry: true });
    }
    if (
      this.selectedRole === 3 &&
      this.selectedLaundry &&
      this.selectedBranch
    ) {
      this.createUserForm.setErrors(null);
    }
    if (this.selectedRole === 4 && !this.selectedCorporate) {
      this.createUserForm.setErrors({ corporate: true });
    }
    if (this.selectedRole === 4 && this.selectedCorporate) {
      this.createUserForm.setErrors(null);
    }

    if (this.selectedRole && this.selectedRole < 3 && this.selectedRole > 4) {
      this.createUserForm.setErrors(null);
    }

    if (this.selectedLaundry) {
      this.createUserForm.setErrors(null);
      if (this.selectedRole !== 1 && this.selectedDep === 6) {
        this.getBranches();
      }
    }

    if (!this.selectedCountry) {
      this.createUserForm.setErrors({ country: true });
    }

    if (this.selectedCountry) {
      this.createUserForm.setErrors(null);
    }
  }

  prefferedCountrySelected() {
    this.multiCountries = this.countries.filter(
      (e) => e.value !== this.selectedCountry,
    );
    this.validateForm();
  }

  saveUser() {
    const form =
      this.userCarwashFormComponent?.form?.value ||
      this.userHomeCleaningFormComponent?.form?.value;
    this.loader = true;
    const userToBeSaved: UserToBeSaved = {
      name:
        this.createUserForm.controls.firstName.value +
        ' ' +
        this.createUserForm.controls.lastName.value,
      first_name: this.createUserForm.controls.firstName.value,
      last_name: this.createUserForm.controls.lastName.value,
      translations: [
        {
          first_name: this.createUserForm.controls.firstName.value,
          last_name: this.createUserForm.controls.lastName.value,
          language_id: 1,
        },
        {
          first_name: this.createUserForm.controls.firstNameAr.value,
          last_name: this.createUserForm.controls.lastNameAr.value,
          language_id: 2,
        },
      ],
      id: this.userIdToBeUpdated ? this.userIdToBeUpdated.id : null,
      email: this.createUserForm.controls.userName.value,
      password: this.createUserForm.controls.password.value,
      department_id: this.selectedDep,
      role_admin_id: this.selectedRole,
      corporate_id: this.selectedCorporate ? this.selectedCorporate : null,
      laundry_id: this.selectedLaundry ? this.selectedLaundry : null,
      laundry_branch_id: this.selectedBranch ? this.selectedBranch : null,
      pin: this.showPin() ? this.createUserForm.controls.pin.value : null,
      voip_id: this.showVoip() ? this.createUserForm.controls.voip.value : null,
      country_id: this.selectedCountry,
      assigned_country: this.selectedCountries,
      ...(this.userCarwashFormComponent?.form && {
        cw_garage_id: form.company,
        cw_garage_branch_id: form.branch,
      }),
      ...(this.userHomeCleaningFormComponent?.form && {
        hc_company_id: form.company,
        hc_company_branch_id: form.branch,
      }),
    };

    if (!this.isUpdate) {
      delete userToBeSaved.id;
      this.userService
        .saveUser(this.headers, userToBeSaved)
        .subscribe(
          (data) => {
            if (data.code === 200) {
              this.toast.success(data.message);
              this.router.navigate(['dashboard/users/list-users']);
            } else {
              this.saveError = data.message;
              this.toast.error(data.message);
            }
            this.loader = false;
          },
          (error) => {
            this.loader = false;
          },
        );
    } else {
      this.userService
        .updateUser(this.headers, userToBeSaved)
        .subscribe((data) => {
          if (data.code === 200) {
            this.router.navigate(['dashboard/users/list-users']);
          } else {
            this.saveError = 'sorry';
          }
          this.loader = false;
        });
    }
  }

  getUserForUpdate(id: number) {
    this.userService
      .getUserByID(this.headers, this.userIdToBeUpdated.id)
      .subscribe((data) => {
        console.log(data);

        this.userIdToBeUpdated = data.data as UserToBeUpated;
        this.selectedRole = this.userIdToBeUpdated.role_admin.id;
        this.selectedDep = this.userIdToBeUpdated.department.id;
        this.selectedLaundry = this.userIdToBeUpdated.laundry_id
          ? this.userIdToBeUpdated.laundry_id
          : undefined;
        this.selectedCorporate = this.userIdToBeUpdated.corporate_id
          ? this.userIdToBeUpdated.corporate_id
          : undefined;
        this.showCorporates = this.userIdToBeUpdated.laundry_id ? true : false;
        this.showLaundries = this.userIdToBeUpdated.laundry_id ? true : false;
        this.createUserForm.controls.userName.setValue(
          this.userIdToBeUpdated.email,
        );
        this.createUserForm.controls.firstName.setValue(
          this.userIdToBeUpdated.name.split(' ')
            ? this.userIdToBeUpdated.name.split(' ')[0]
            : '',
        );
        this.createUserForm.controls.lastName.setValue(
          this.userIdToBeUpdated.name.split(' ')
            ? this.userIdToBeUpdated.name.split(' ')[1]
            : '',
        );
        this.createUserForm.controls.password.setValue('yourpassword');
        this.getRoles();
      });
  }

  showPin() {
    return (
      this.selectedDep === Department.Laundries &&
      (this.selectedRole === 2 ||
        this.selectedRole === 4 ||
        this.selectedRole === 5 ||
        this.selectedRole === 6 ||
        this.selectedRole === 1)
    );
  }

  showVoip() {
    return (
      this.selectedDep === Department.Operations && this.selectedRole === 5
    );
  }

  private initVariables(): void {
    this.loader = false;
    this.showCorporates = false;
    this.showLaundries = false;
  }

  private initForm(): void {
    this.createUserForm = this.formBuilder.group({
      userName: [
        null,
        [
          Validators.required,
          Validators.pattern(
            /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
          ),
        ],
      ],
      password: [null, Validators.required],
      firstName: [null, Validators.required],
      firstNameAr: [null, Validators.required],
      lastName: [null, Validators.required],
      lastNameAr: [null, Validators.required],
      pin: [null, [Validators.pattern(/^[0-9]{4}$/)]],
      voip: null,
    });
  }

  private handleFormChanges(): void {
    this.createUserForm.valueChanges.subscribe((data) => {
      const error = this.selectedRole ? null : { role: true };
      this.createUserForm.setErrors(error);
    });
  }


  setGroup(): void {
    this.headers.group_id = this.selectedGroup?.id ? this.selectedGroup.id.toString() : '1';
    this.laundries = [];
    this.getLaundries();
  }

  getDepartments() {
    this.roleService
      .getDep(this.headers)
      .subscribe((response) => {
        this.deps = [];
        const d = response.data as any[];
        d.forEach((element) => {
          this.deps.push({ value: element.id, label: element.name });
        });
      });
  }

  loadLaundryGroups(): void {
    this.isLoadingGroups = true;
    this.groups$ = this.groupsService.getGroups().pipe(
      map((groups) => {
        if (this.selectedGroup) {
          /** This to bind with groups dropdown */
          this.selectedGroup = groups.find(
            ({ id }) => id === this.selectedGroup.id,
          );
        } else {
          // assign the first group by default
          this.selectedGroup = groups.find(() => true);
        }

        this.isLoadingGroups = true;
        return groups.filter((group) =>
          this.groupsService.checkLaundryDesign(group),
        );
      }),
    );
  }
}
