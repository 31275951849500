import { Component, OnInit } from '@angular/core';
import { SelectOptions, PageInfo } from 'src/app/shared/models/global/response';
import { GlobalService } from 'src/app/shared/service/global.service';
import { FinanceService } from '../../services/finance.service';
import { MonthlyOpenOrder, MonthlyOrder, CloseOrderBody } from '../../models/finance';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-close-monthly-orders',
  templateUrl: './close-monthly-orders.component.html',
  styleUrls: ['./close-monthly-orders.component.scss'],
})
export class CloseMonthlyOrdersComponent implements OnInit {
  selectedYear: string;
  selectedMonth: string;
  years: SelectOptions<string>[] = [];
  months: SelectOptions<string>[] = [];
  orders: MonthlyOpenOrder[];
  monthlyOrders: MonthlyOrder;
  pagination: PageInfo;
  dataReady = false;
  isConfirmModalOpen = false;
  loader: {
    submit: boolean,
    close: boolean
  } = {
    submit: false,
    close:  false
  };
  comment: string;
  constructor(
    private financeService: FinanceService,
    private globalService: GlobalService,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.initData();
  }

  getData() {
    this.dataReady = false;
    this.getAllOrders(this.pagination ? this.pagination.current_page : 1);
  }

  closeOrder() {
    this.loader.close = true;
    const body = {
      year: parseInt(this.selectedYear, 10),
      month: parseInt(this.selectedMonth, 10),
      comment: this.comment
    } as CloseOrderBody;
    this.financeService
      .closeMonthlyOrders(
        this.globalService.headers,
        body
      )
      .subscribe(
        response => {
          if (response.code === 200) {
            this.getAllOrders(this.pagination ? this.pagination.current_page : 1);
            this.toast.success(response.message);
            this.closeModal();
          } else {
            this.toast.error(response.message);
          }
          this.loader.close  = false;
        },
        error => {
          this.loader.close  = false;
        }
      );
  }

  openModal() {
    this.isConfirmModalOpen = true;
  }

  closeModal() {
    this.isConfirmModalOpen = false;
  }
  private initData() {
    const currentYear = new Date().getFullYear();
    const startYear = 2018;
    for (let i = startYear; i <= currentYear; i++) {
      this.years.push({
        label: i + '',
        value: i + '',
      });
    }
    const months = this.globalService.getMonthList();
    const keys = Object.keys(months);
    for (const key of keys) {
      this.months.push({
        label: months[key],
        value: parseInt(key, 10) + 1 +  '',
      });
    }
  }

  private getAllOrders(page: number) {
    this.loader.submit = true;
    this.financeService
      .getMonthlyOrders(
        this.globalService.headers,
        this.selectedYear,
        this.selectedMonth,
        { page }
      )
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.monthlyOrders = response.data as MonthlyOrder;
            this.orders = this.monthlyOrders.orders;
            this.pagination = response.page_info;
            this.dataReady = true;
          } else {
            this.toast.error(response.message);
          }
          this.loader.submit = false;
        },
        (error) => {
          this.loader.submit = false;
        }
      );
  }

  getDateTime(date: string) {
    return this.globalService.getDateTimeFromString(date);
  }

  getPage(event) {
    this.getAllOrders(event);
  }
}
