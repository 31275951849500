import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { CountryService } from '../../../../../shared/service/country.service';
import { GlobalService } from '../../../../../shared/service/global.service';
import { AreaList } from '../../../../../shared/models/countries/country';
import {
  LaundryToBeSaved,
  LaundryInfo
} from '../../../../../shared/models/laundries/laundry';
import { LaundryService } from '../../../../../shared/service/laundry.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { map } from "rxjs/operators";
import { Group } from "@shared/models";
import { Observable } from "rxjs";
import { GroupService } from "@shared/service/group";

@Component({
  selector: 'app-step-creation',
  templateUrl: './step-creation.component.html',
  styleUrls: ['./step-creation.component.css']
})
export class StepCreationComponent implements OnInit {
  createLaundry: UntypedFormGroup;
  page: number;
  constructor(
    private countryService: CountryService,
    private globalService: GlobalService,
    private laundryService: LaundryService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService,
    private groupsService: GroupService,
  ) {}
  areas;
  areaModel: number;
  loader: boolean;
  isUpdate: boolean;
  laundryId: number;
  selectedGroup: Group;
  groups$: Observable<Array<Group>>;
  ngOnInit() {
    this.isUpdate =
      this.route.snapshot.queryParams.isUpdate === 'true' ? true : false;
    this.laundryId = this.route.snapshot.queryParams.id;
    this.page = this.route.snapshot.queryParams.page;
    this.loadDateOnUpdate(this.isUpdate);
    this.loader = false;

    this.loadLaundryGroups();
    if (this.groupsService.selectedGroup) {
      this.selectedGroup = this.groupsService.selectedGroup;
    }
  }

  initForm(isUpdate: boolean, laundryInfo?: LaundryInfo) {
    this.createLaundry = new UntypedFormGroup({
      nameEn: new UntypedFormControl(
        isUpdate ? laundryInfo.name_translations.en : null,
        Validators.required
      ),
      nameAr: new UntypedFormControl(
        isUpdate ? laundryInfo.name_translations.ar : null,
        Validators.required
      ),
      address: new UntypedFormControl(
        isUpdate ? laundryInfo.address : null,
        Validators.required
      ),
      ownerName: new UntypedFormControl(
        isUpdate ? laundryInfo.owner_name : null,
        Validators.required
      ),
      mobile: new UntypedFormControl(
        isUpdate ? laundryInfo.mobile : null,
        Validators.required
      ),
      mobileAlt: new UntypedFormControl(
        isUpdate ? laundryInfo.mobile_alt : null,
        Validators.required
      ),
      area: new UntypedFormControl(isUpdate ? laundryInfo.area_id : null),
      trnName: new UntypedFormControl(isUpdate ? laundryInfo.trn_name : null),
      trnNumber: new UntypedFormControl(isUpdate ? laundryInfo.trn_number : null),
      actualName: new UntypedFormControl(isUpdate ? laundryInfo.name_actual : null),
      email: new UntypedFormControl(isUpdate ? laundryInfo.email : null, [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)
      ])
    });

    this.areaModel = isUpdate ? laundryInfo.area_id : null;

    this.countryService
      .getListAreas(this.globalService.headers, { page: 0 })
      .subscribe(response => {
        const areas = (response.data as AreaList[]).filter(
          area => area.country_id === this.globalService.countryId
        );
        this.areas = [];
        areas.forEach(element => {
          this.areas.push({
            value: element.id,
            label: element.name.en
          });
        });
      });
  }

  saveLaundry() {
    this.loader = true;
    const laundry = new LaundryToBeSaved();
    laundry.laundry_translations = [];
    laundry.address = this.getValue('address');
    laundry.area_id = this.areaModel;
    laundry.email = this.getValue('email');
    laundry.mobile = this.getValue('mobile');
    laundry.mobile_alt = this.getValue('mobileAlt');
    laundry.owner_name = this.getValue('ownerName');
    laundry.trn_name = this.getValue('trnName');
    laundry.trn_number = this.getValue('trnNumber');
    laundry.name_actual = this.getValue('actualName');
    laundry.laundry_translations[0] = {
      language_id: 1,
      name: this.getValue('nameEn')
    };
    laundry.laundry_translations[1] = {
      language_id: 2,
      name: this.getValue('nameAr')
    };
    if (this.isUpdate) {
      this.laundryService
        .updateLaundry(this.globalService.headers, laundry, this.laundryId)
        .subscribe(
          response => {
            if (response.code === 200) {
              this.router.navigate(
                [ '/','dashboard','laundries','laundry-create', 'step3', this.laundryId],
                { queryParams: { isUpdate: this.isUpdate, page: this.page } }
              );
            } else {
              this.toast.error(response.message);
            }
          }
        );
    } else {
      this.laundryService
        .saveLaundry(this.globalService.headers, laundry)
        .subscribe(
          response => {
            if (response.code === 200) {
              const id = response.data.id;
              this.router.navigate(
                [ '/','dashboard','laundries','laundry-create', 'step3', + id],
                { queryParams: { isUpdate: this.isUpdate, page: this.page } }
              );
            } else {
              this.isUpdate = false;
              this.toast.error(response.message);
            }
          }
        );
    }
  }

  private getValue(control: string) {
    return this.createLaundry.controls[control].value;
  }

  private loadDateOnUpdate(isUpdate) {
    if (isUpdate) {
      this.laundryService
        .getLaundryInfo(this.globalService.headers, this.laundryId)
        .subscribe(
          response => {
            if (response.code === 200) {
              this.initForm(isUpdate, response.data);
            } else {
              this.toast.error(response.message);
            }
          }
        );
    } else {
      this.initForm(isUpdate);
    }
  }

  loadLaundryGroups(): void {
    this.groups$ = this.groupsService.getGroups().pipe(
      map((groups) => {
        if (this.selectedGroup) {
          /** This to bind with groups dropdown */
          this.selectedGroup = groups.find(
            ({ id }) => id === this.selectedGroup.id,
          );
        } else {
          // assign the first group by default
          this.selectedGroup = groups.find(()=> true);
        }
        return groups.filter(
          (group) => this.groupsService.checkLaundryDesign(group),
        );
      }),
    );
  }
}
