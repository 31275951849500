import { Country } from '@app/shared/models/user/user';
import { Area } from '@app/shared/models';
import { AdvertisementArea, BranchArea } from '@app/shared/models';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subject, Observable, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { FormStatus } from '@shared/enums/form-status.enum';
import { Ad } from '@shared/models';
import { Gender, PetPreference } from '@app/shared/enums';
import { AreaService } from '@app/shared/service/area';
import moment from "moment";

@Component({
  selector: 'app-sales-ad-form',
  templateUrl: './sales-ad-form.component.html',
  styleUrls: ['./sales-ad-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SalesAdFormComponent implements OnInit, OnDestroy {
  @Input() ad: Ad;
  @Input() adImageAr: string;
  @Input() adImageEn: string;
  @Output() formChanged: EventEmitter<Partial<Ad>> = new EventEmitter<
    Partial<Ad>
  >();
  @Output() formStatusChanged: EventEmitter<FormStatus> =
    new EventEmitter<FormStatus>();
  @Output() changeAdImageEn: EventEmitter<File> = new EventEmitter<File>();
  @Output() changeAdImageAr: EventEmitter<File> = new EventEmitter<File>();

  form: UntypedFormGroup;
  GENDER = Gender;
  PET_PREFERENCE = PetPreference;

  private readonly destroy$: Subject<void> = new Subject<void>() ;
  areas$: Observable<BranchArea[] | Area[]>;
  allAreas: Area[] | BranchArea[];
  selectedAdvertisementAreas: { [areaId: number]: AdvertisementArea } = {};
  isAllSelected: boolean;

  //#region isMarketing variables
  @Input() isMarketing: boolean;
  @Input() countries: Country[];
  @Input() selecteCountryId: number;
  searchTextChanged: Subject<string> = new Subject<string>();
  subscriber$ = new Subscription();
  isLoading: boolean;
  //#endregion

  types: Array<{ name: string, value: string }> = [{ name: 'Scrollable Ads', value: 'home' }, { name: 'Banner 1', value: 'homeSet2' }, { name: 'Banner 2', value: 'homeSet3' }];
  adType: string = this.types[0].value;
  startDate: string = moment().format();
  endDate: string = moment().add(1, 'year').format();


  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly areaService: AreaService,
  ) {}

  ngOnInit(): void {
    this.initVariables();
    this.initForm();
    this.handleFormChanges();
    this.handleFormStatusChanges();
  }

  upload(file: File, isAr?: boolean): void {
    if (isAr) {
      this.changeAdImageAr.next(file);
    } else {
      this.changeAdImageEn.next(file);
    }
  }

  changePetStatus(): void {
    this.form.get('petPreference').setValue(PetPreference.hasPets);
    this.form.get('genderId').setValue(undefined);
  }

  changeGender(gender: Gender): void {
    this.form.get('genderId').setValue(gender);
    this.form.get('petPreference').setValue(undefined);
  }

  getEvenIndexes(areas: BranchArea[]): BranchArea[] {
    const isEven = (area, index) => !(index % 2);
    return areas?.filter(isEven);
  }

  getOddIndexes(areas: BranchArea[]): BranchArea[] {
    const isEven = (area, index) => index % 2;
    return areas?.filter(isEven);
  }

  toggleAll(isSelectAll: boolean): void {
    if (isSelectAll) {
      this.allAreas.forEach((area) => {
        const areaId = area.areaId || area.id;
        this.selectedAdvertisementAreas[areaId] = {
          areaId,
          hasAndroid: true,
          hasIos: true,
        };
      });
    } else {
      this.selectedAdvertisementAreas = {};
    }
    this.checkAllSelected();
  }

  toggleArea(areaId: number, checked: boolean): void {
    if (checked) {
      this.selectedAdvertisementAreas[areaId] = {
        areaId,
        hasAndroid: true,
        hasIos: true,
      };
    } else {
      delete this.selectedAdvertisementAreas[areaId];
    }
    this.checkAllSelected();
  }

  private checkAllSelected(): void {
    this.isAllSelected =
      Object.keys(this.selectedAdvertisementAreas)?.length ===
      this.allAreas?.length;
  }

  fillAreas(formData: Partial<Ad>): void {
    formData.advertisementAreas = Object.keys(
      this.selectedAdvertisementAreas,
    ).map((key) => this.selectedAdvertisementAreas[key]);
  }

  onInput(event: Event): void {
    if (event.target['value'].trim().length > 2) {
      this.searchTextChanged.next(event.target['value'].trim());
    }
  }



  private initVariables() {
    this.loadCountryAreas();
  }

  private initForm(): void {
    this.form = this.fb.group({
      name: this.fb.group({
        en: [this.titleEn, [Validators.required]],
        ar: [this.titleAr, [Validators.required]],
      }),
      image: this.fb.group({
        en: [''],
        ar: [''],
      }),
      link: this.fb.group({
        en: [this.linkEn],
        ar: [this.linkAr],
      }),
      startDate: [this.formattedStartDate, [Validators.required]],
      endDate: [this.formattedEndDate, [Validators.required]],
      maxViews: [
        100,
        [Validators.required, Validators.min(100), Validators.max(5000)],
      ],
      petPreference: [PetPreference.notSet],
      /** null means all users */
      genderId: [null],
      hcServiceId: [null,],
      groupId: [null],
      type: [this.adType],
      advertisementAreas: new UntypedFormArray([]),
    });
    this.patchForm();
  }

  private get titleEn(): string {
    return ``;
  }
  private get titleAr(): string {
    return ``;
  }

  private get linkEn(): string {
    return ``;
  }
  private get linkAr(): string {
    return ``;
  }
  private get formattedStartDate(): string {
    return moment(this.startDate).toISOString();
  }
  private get formattedEndDate(): string {
    return moment(this.endDate).toISOString();
  }

  private patchForm(): void {
    if (this.ad) {
      this.form.patchValue(this.ad);

      this.ad.advertisementAreas.forEach((area) => {
        this.selectedAdvertisementAreas[area.areaId] = {
          areaId: area.areaId,
          hasAndroid: area.hasAndroid,
          hasIos: area.hasIos,
        };
      });
      if (this.ad.petPreference !== PetPreference.notSet) {
        this.form.get('genderId').setValue(this.ad.genderId || undefined);
      }

    }
    this.formChanged.emit(this.form.value);
  }

  private handleFormChanges(): void {
    const emitValue = (value: Partial<Ad>) => this.formChanged.emit(value);

    this.form.valueChanges
      .pipe(debounceTime(500), tap(emitValue), takeUntil(this.destroy$))
      .subscribe();
  }

  private handleFormStatusChanges(): void {
    const emitStatus = (value: FormStatus) =>
      this.formStatusChanged.emit(value);

    this.form.statusChanges
      .pipe(
        startWith(this.form.status),
        distinctUntilChanged(),
        tap(emitStatus),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private loadCountryAreas() {
    this.loadAreas({
      countryId: this.selecteCountryId || this.countryId,
    });
  }

  private loadAreas(params: Record<string, string | number>): void {
    this.areas$ = this.areaService.getAllAreas(params).pipe(
      map((areas) => {
        this.allAreas = areas;
        if (!this.ad) {
          return areas;
        }

        const isSelected = (area) =>
          this.ad ? this.selectedAdvertisementAreas[area.id] : true;
        return areas?.filter(isSelected);
      }),
    );
  }

  private get countryId(): number {
    return +localStorage.getItem('country');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.subscriber$) {
      this.subscriber$.unsubscribe();
    }
  }

  getSelectedAdType($event: any) {
    this.adType = $event;
    this.form.get('type').setValue($event);
    return this.adType
  }

}
