import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { BranchList } from '../../../../shared/models/laundries/branch';
import { GlobalService } from '../../../../shared/service/global.service';
import { BranchesService } from '../../../../shared/service/branches.service';
import { ModalDirective, ToastService } from "ng-uikit-pro-standard";
import { Subject } from 'rxjs';
import { CarwashBranch } from '@shared/models';
import { DialogService } from '@shared/components/dialog/services/dialog.service';
import {
  LaundryBranchAreaCoverageDialogComponent
} from '@app/modules/branch/components/create-branch/area-coverage-dialog/area-coverage-dialog.component';

@Component({
  selector: 'app-branch-list',
  templateUrl: './branch-list.component.html',
  styleUrls: ['./branch-list.component.scss'],
})
export class BranchListComponent implements OnInit {
  branches: BranchList;
  laundryid: number;
  isLogistics: boolean;
  groupId: number;
  openAssign = false;
  selectedBranch: number;
  selectedLaundryId: number;
  selectedBranchIsLogistics: boolean;
  private destroy$: Subject<void> = new Subject<void>() ;

  @ViewChild('success', { static: true }) succesModal: ModalDirective;

  constructor(
    private route: ActivatedRoute,
    protected globalService: GlobalService,
    protected branchService: BranchesService,
    private readonly dialog: DialogService,
    private toast: ToastService,
  ) {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        this.groupId = params.groupId
      });
  }

  ngOnInit() {
    this.laundryid = this.route.snapshot.params.id;
    this.isLogistics =
      window.localStorage.getItem('isLog') === 'true' ? true : false;
    this.getBranches(this.laundryid);
  }

  getBranches(laundryId: number) {
    this.branchService
      .getBranchList(this.globalService.headers, laundryId)
      .subscribe((data) => {
        if (data.code === 200) {
          this.branches = data.data;
        }
      });
  }

  changeStatus(branch: BranchList, isLogistics?: boolean) {
    const status = {
      branch_id: branch.id,
      status: branch.status === 'active' ? 'inactive' : 'active',
      is_logistics: branch.is_logistics ? false : true,
    };
    if (isLogistics) {
      delete status.status;
    } else {
      delete status.is_logistics;
    }
    this.branchService
      .changeBranchStatus(this.globalService.headers, status)
      .subscribe(
        (response) => {
          this.getBranches(this.laundryid);
        }
      );
  }

  changePosStatus(branch: BranchList) {
    const status = {
      laundry_branch_id: branch.id,
      status: branch.has_pos_status ? false : true,
    };
    this.branchService
      .changeBranchPosStatus(this.globalService.headers, status)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.getBranches(this.laundryid);
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }

  changeCorporateStatus(branch: BranchList) {
    const status = {
      laundry_branch_id: branch.id,
      is_corporate: branch.is_corporate ? false : true,
    };
    this.branchService
      .changeBranchCorporateStatus(this.globalService.headers, status)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.getBranches(this.laundryid);
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }


  openDialog(branchId: number, isLogistics: boolean, laundryId: number) {
    this.selectedBranch = branchId;
    this.selectedBranchIsLogistics = isLogistics;
    this.selectedLaundryId = laundryId;
    this.openAssign = true;
  }



  closeAssign(event) {
    console.log(event);

    this.openAssign = false;
    if (event.showSuccess) {
      this.succesModal.show();
    }
  }


  openAreaCoverageDialog(branch: CarwashBranch = null): void {
    this.dialog
      .open(LaundryBranchAreaCoverageDialogComponent, branch.id)
      .afterClosed$.pipe(filter(Boolean))
      .subscribe();
  }
}
