import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'jcUtcToLocalDate'
})
export class UtcToLocalDatePipe implements PipeTransform {

  transform(date: string | Date, format?: string): string {
    if (!date) {
      return null;
    }
    const timeZone = localStorage.getItem('timeZone');
    const result = moment.utc(date).tz(timeZone).format(format);
    return result;
  }
}
