import { NgModule } from '@angular/core';

import { CarwashBranchRoutingModule } from '@app/modules/carwash/modules/carwash-branch/carwash-branch-routing.module';
import { CarwashBranchFormComponent } from '@app/modules/carwash/modules/carwash-branch/components/carwash-branch-edit-dialog/carwash-branch-form/carwash-branch-form.component';
import { CarwashBranchEditDialogComponent } from '@app/modules/carwash/modules/carwash-branch/components/carwash-branch-edit-dialog/carwash-branch-edit-dialog.component';
import { CarwashBranchListComponent } from '@app/modules/carwash/modules/carwash-branch/components/carwash-branch-list/carwash-branch-list.component';
import { SharedModule } from '@app/modules/carwash/shared/shared.module';
import { CarwashTimeRangeComponent } from '@app/modules/carwash/modules/carwash-branch/shared/components/carwash-time-range/carwash-time-range.component';
import { CarwashBranchReservationOptionsComponent } from './shared/components/carwash-branch-reservation-options/carwash-branch-reservation-options.component';
import { CarwashBranchTimeServiceComponent } from './components/carwash-branch-time-service/carwash-branch-time-service.component';
import { AreaCoverageDialogComponent } from './components/area-coverage-dialog/area-coverage-dialog.component';
import { OnlineProfileComponent } from './components/online-profile/online-profile.component';
import { LightgalleryModule } from 'lightgallery/angular/11';

@NgModule({
  imports: [CarwashBranchRoutingModule, SharedModule, LightgalleryModule],
  declarations: [
    CarwashBranchListComponent,
    CarwashBranchFormComponent,
    CarwashBranchEditDialogComponent,
    CarwashBranchReservationOptionsComponent,
    CarwashTimeRangeComponent,
    CarwashBranchTimeServiceComponent,
    AreaCoverageDialogComponent,
    OnlineProfileComponent,
  ]
})
export class CarwashBranchModule {}
