import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersNotificationsMainComponent } from 'src/app/modules/notifications/components/users-notifications-main/users-notifications-main.component';


const routes: Routes = [
  {
    path: 'notification-wizard',
    component: UsersNotificationsMainComponent,
    data: {
      title: 'Notifications Wizard'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotificationsRoutingModule { }
