// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-type{
  width: 70%;
  padding-top: 9px;
  display: inline-block;
}

.custom-amount{
    border: solid 1px #e1e1e1;
    border-radius: 5px;
    margin: 10px;
    padding: 24px 10px;
}
.custom-amount .title{
  font-weight: bold;
  font-size: 20px;
}
.custom-amount .switch{
  width: 50px;
  display: inline-block;
}
.action_buttons{
  /* max-width: 143px;
  padding: 11px 36px; */

  max-width: 271px;
  padding: 11px 36px;

}
.custom-details{
  flex-flow: wrap-reverse;
}

.width-80{
  max-width: 80% !important;
}

.deavctivate{
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/branch/components/create-branch/pos-contract/pos-contract.component.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;AACtB;AACA;EACE,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,WAAW;EACX,qBAAqB;AACvB;AACA;EACE;uBACqB;;EAErB,gBAAgB;EAChB,kBAAkB;;AAEpB;AACA;EACE,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".select-type{\n  width: 70%;\n  padding-top: 9px;\n  display: inline-block;\n}\n\n.custom-amount{\n    border: solid 1px #e1e1e1;\n    border-radius: 5px;\n    margin: 10px;\n    padding: 24px 10px;\n}\n.custom-amount .title{\n  font-weight: bold;\n  font-size: 20px;\n}\n.custom-amount .switch{\n  width: 50px;\n  display: inline-block;\n}\n.action_buttons{\n  /* max-width: 143px;\n  padding: 11px 36px; */\n\n  max-width: 271px;\n  padding: 11px 36px;\n\n}\n.custom-details{\n  flex-flow: wrap-reverse;\n}\n\n.width-80{\n  max-width: 80% !important;\n}\n\n.deavctivate{\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
