import { Component, OnInit } from '@angular/core';
import {
  ContractFromList,
  ContractTypePrettyName,
  CorporateStatus,
  ContractHasOrder,
  ContractOrders
} from './../../../../shared/models/laundries/corporate';
import { CorporateService } from './../../../../shared/service/corporate.service';
import { GlobalService } from 'src/app/shared/service/global.service';
import { PageInfo } from 'src/app/shared/models/global/response';
import { ToastService } from 'ng-uikit-pro-standard';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.css']
})
export class ContractListComponent implements OnInit {
  contracts: ContractFromList[];
  pagination: PageInfo;
  searchTerm: string;
  corpCustomerId: number;
  contractOrder: ContractHasOrder[] = [];
  constructor(
    private globalService: GlobalService,
    protected corporateService: CorporateService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected toast: ToastService
  ) {}

  ngOnInit() {
    this.corpCustomerId = this.route.snapshot.queryParams.id;
    this.getContracts({ page: 1 });
  }

  getContracts(params?: any) {
    this.corporateService
      .getContracts(this.globalService.headers, this.corpCustomerId, params)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.contracts = response.data;
            this.pagination = response.page_info;
            this.contracts.forEach(contract => {
              this.corporateService
                .getScheduledOrderForContract(
                  this.globalService.headers,
                  contract.id
                )
                .subscribe(orders => {
                  if (orders.code === 200) {
                    const order: ContractOrders = orders.data;
                    this.contractOrder.push({
                      id: contract.id,
                      hasOrder:
                        order[0].corporate_order_schedules.length > 0
                          ? true
                          : false
                    });
                  }
                });
            });
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }

  getPage(number: number) {
    this.getContracts({ page: number, search: this.searchTerm });
  }

  showContractType(contractType: string) {
    return ContractTypePrettyName[contractType];
  }

  fixNull(data) {
    return this.globalService.fixNullValues(data);
  }

  search() {
    this.getContracts({
      search: this.searchTerm,
      page: this.pagination.current_page
    });
  }

  toggleStatus(contract: ContractFromList) {
    this.corporateService
      .toggleStatus(this.globalService.headers, {
        contract_id: contract.id,
        status:
          contract.status === CorporateStatus.ACTIVE
            ? CorporateStatus.INACTIVE
            : CorporateStatus.ACTIVE
      })
      .subscribe(
        response => {
          if (response.code === 200) {
            this.getContracts({
              page: this.pagination.current_page,
              search: this.searchTerm
            });
          } else {
            this.toast.error(response.message);
            this.getContracts({
              page: this.pagination.current_page,
              search: this.searchTerm
            });
          }
        },
        error => {
          this.getContracts({
            page: this.pagination.current_page,
            search: this.searchTerm
          });
        }
      );
  }

  hasOrder(contractId: number) {
    const contract = this.contractOrder.find(con => con.id === contractId);
    return contract && contract.hasOrder;
  }
}
