import { map } from 'rxjs/operators';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CancelReason, HcCancelReasonResponse } from '@app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class CancelReasonsService {
  private readonly branchesUrl = `${environment.jcDeliveryServer}order-reasons`;

  constructor(private readonly http: HttpClient) {}

  getCancelReasons(params: any = {}): Observable<Array<CancelReason>> {
    return this.http
      .get<ApiResponse<Array<HcCancelReasonResponse>>>(this.branchesUrl, {
        params,
      })
      .pipe(map((res) => this.mapCancelReasons(res.data)));
  }

  private mapCancelReasons(items: HcCancelReasonResponse[]): CancelReason[] {
    return items
      ?.filter((item) => item?.reason?.en)
      ?.map(({ id, reason, hasNote }) => ({
        id,
        hasNote,
        name: {
          en: reason.en,
          ar: reason.ar,
        },
      }));
  }
}
