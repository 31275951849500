import { Component, OnInit, HostListener } from '@angular/core';
import {
  NewPageInfo,
  SelectOptions,
} from 'src/app/shared/models/global/response';
import { GlobalService } from 'src/app/shared/service/global.service';
import { JcDeliverService } from '../../jc-deliver.service';
import {
  JcDeliverOrders,
  JCDeliverStatuses,
  OrderListParams,
  SORT_VALUE,
  JcDelDriver,
  JcDelPartner,
  Statuses,
  OrderTrackingToBeSaved,
} from '../../jc-deliver.model';
import { ApiLibrary } from 'src/app/shared/helpers/http-params';
import { zip } from 'rxjs';

@Component({
  selector: 'app-delivery-orders',
  templateUrl: './delivery-orders.component.html',
  styleUrls: ['./delivery-orders.component.css'],
})
export class DeliveryOrdersComponent implements OnInit {
  statusFilter = [];
  statusList = Statuses;
  pageInfoCurrent: NewPageInfo;
  pageInfoHistory: NewPageInfo;
  orders: JcDeliverOrders[];
  historyOrders: JcDeliverOrders[];
  statuses = JCDeliverStatuses;
  isAssignDriverOpened = false;
  loader = false;
  params: OrderListParams;
  historyParams: OrderListParams;
  availableDrivers: SelectOptions<any>[];
  selectedDriver: number;
  selectedOrder: JcDeliverOrders;
  isConfirmCancelOrderOpened = false;
  availableDriversFromBackend: JcDelDriver[];
  availablePartnersFromBackend: JcDelPartner[];
  constructor(
    private global: GlobalService,
    private jsCelService: JcDeliverService,
  ) {}

  ngOnInit() {
    this.init();
  }

  getPageCurrent(event) {
    this.params.page = event;
    this.getOrders(this.params);
  }

  getPageHistory(event) {
    this.historyParams.page = event;
    this.getOrdersHistory();
  }

  closeAssignDriverModal() {
    this.isAssignDriverOpened = false;
    this.selectedDriver = 0;
  }

  openAssignDriverModal(order: JcDeliverOrders) {
    this.selectedOrder = order;
    this.isAssignDriverOpened = true;
    if (
      !this.availableDrivers ||
      (this.availableDrivers && this.availableDrivers.length <= 0)
    ) {
      this.getDrivers();
    }
  }

  DriverChanges(event) {
    this.selectedDriver = event;
  }

  assignDriver() {
    this.loader = true;
    const isDriver = !!this.availableDriversFromBackend.find(
      (e) => e.id === this.selectedDriver
    );
    const orderTrackingBody: OrderTrackingToBeSaved = {
      driverId: isDriver ? this.selectedDriver : null,
      partnerId: !isDriver ? this.selectedDriver : null,
      orderDeliveryId: this.selectedOrder.id,
      type: this.selectedOrder.type,
      status: 'assigned',
      countryId: this.global.countryId
    };
    this.jsCelService
      .saveOrderTracking(this.global.headers, orderTrackingBody)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.filterOrders();
            this.closeAssignDriverModal();
          }
          this.loader = false;
        },
        (error) => {
          this.loader = false;
        }
      );
  }

  init() {
    this.params = {
      page: 1,
      sortBy: 'updatedAt',
      sortValue: SORT_VALUE.up,
      statusDeliveryIds: '',
    };
    this.historyParams = JSON.parse(JSON.stringify(this.params));
    this.getOrders(this.params);
    this.getOrdersHistory();
  }

  filterOrders() {
    this.historyParams = JSON.parse(JSON.stringify(this.params));
    console.log(this.params, this.historyParams);
    this.getOrders(this.params);
    this.getOrdersHistory();
  }

  toggleStatusFilter(event) {
    this.params.statusDeliveryIds =
      this.statusFilter && this.statusFilter.length > 0
        ? JSON.stringify(this.statusFilter)
        : '';
  }

  @HostListener('keyup', ['$event'])
  keyPressed(event: KeyboardEvent) {
    console.log(event.code);

    if (event.code === 'Enter') {
      this.filterOrders();
    }
    if (event.code === 'Escape') {
      this.init();
    }
  }

  changeStatus(statusId: number) {
    this.loader = true;
    this.jsCelService
      .changeStatus(this.global.headers, this.selectedOrder.id, statusId)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.filterOrders();
            this.closeConfirmCancel(false);
          }
          this.loader = false;
        },
        (error) => {
          this.loader = false;
        }
      );
  }

  getDriverFromTracking(order: JcDeliverOrders) {
    let driverData: JcDelDriver | JcDelPartner;
    if (
      order &&
      order.ordersDeliveryTracking &&
      order.ordersDeliveryTracking.length > 0
    ) {
      const drivers = order.ordersDeliveryTracking.filter(
        (e) => e.type === order.type
      );
      const currentDriver = drivers[drivers.length - 1];
      if (currentDriver) {
        driverData = currentDriver.driver
          ? currentDriver.driver
          : currentDriver.partner;
      }
    }
    return driverData;
  }

  openConfirmCancel(order: JcDeliverOrders) {
    this.selectedOrder = order;
    this.isConfirmCancelOrderOpened = true;
  }

  closeConfirmCancel($event) {
    this.isConfirmCancelOrderOpened = false;
  }

  private getOrders(params?: any) {
    this.jsCelService
      .getAllOrders(
        this.global.headers,
        new ApiLibrary().cleanObjectFromComponent(params)
      )
      .subscribe(
        (response) => {
          if (response.code === 200) {
            const allOrders = response.data as JcDeliverOrders[];
            this.orders = allOrders.filter(
              (e) =>
                (e.statusDeliveryId < 8)
            );
            this.pageInfoCurrent = response.pageInfo;
          }
        },
      );
  }
  private getOrdersHistory() {
    this.historyParams.statusDeliveryIds = '[8, 9, 10]';
    this.jsCelService
      .getAllOrders(
        this.global.headers,
        new ApiLibrary().cleanObjectFromComponent(this.historyParams)
      )
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.historyOrders = response.data as JcDeliverOrders[];
            this.pageInfoHistory = response.pageInfo;
          }
        },
      );
  }

  private getDrivers(params?: any) {
    zip(
      this.jsCelService.getDrivers(this.global.headers, {
        isLogistics: true,
        page: 'all',
      }),
      this.jsCelService.getPartners(this.global.headers, { page: 'all' })
    ).subscribe(
      (response) => {
        if (response[0].code === 200 && response[1].code === 200) {
          this.availableDriversFromBackend = response[0].data as JcDelDriver[];
          this.availablePartnersFromBackend = response[1]
            .data as JcDelPartner[];
          this.availableDrivers = [];
          this.availableDrivers.push({
            value: '',
            label: 'Justclean Drivers',
            group: true,
          });
          this.availableDriversFromBackend.forEach((e) => {
            this.availableDrivers.push({
              value: e.id,
              label: e.firstName + '' + e.lastName + '-' + e.mobileNo,
            });
          });
          this.availableDrivers.push({
            value: '',
            label: 'Partners',
            group: true,
          });
          this.availablePartnersFromBackend.forEach((e) => {
            this.availableDrivers.push({
              value: e.id,
              label: e.firstName + '' + e.lastName + '-' + e.mobileNo,
            });
          });

          console.log(this.availableDrivers);
        } else {
        }
      },
    );
  }
}
