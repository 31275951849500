import { HcBranch } from '@app/shared/models';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { IOption } from 'ng-uikit-pro-standard/lib/pro/material-select/option-interface';
import { finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HomecleanCompanyService } from '@app/modules/homeclean/modules/homeclean-company/shared/services/homeclean-company.service';
import { HomecleanCompany } from '@app/modules/homeclean/modules/homeclean-company/shared/models';
import { HomeCleaningBranchesService } from '@app/shared/service/home-cleaning/home-cleaning-branches.service';

@Component({
  selector: 'app-user-home-cleaning-form',
  templateUrl: './user-home-cleaning-form.component.html',
  styleUrls: ['./user-home-cleaning-form.component.css'],
})
export class UserHomeCleaningFormComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  companiesOptions: Pick<IOption, 'label' | 'value'>[] = [];
  companies: Array<HomecleanCompany>;
  branches: Pick<IOption, 'label' | 'value'>[] = [];
  isLoadingData: boolean;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly homeCleaningCompanyService: HomecleanCompanyService,
    private readonly homeCleaningBranchService: HomeCleaningBranchesService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadCompanies();
    this.handleCompanyChange();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      company: ['', Validators.required],
      branch: ['', Validators.required],
    });
  }

  private loadCompanies(): void {
    const toOptions = (
      company: HomecleanCompany,
    ): Pick<IOption, 'label' | 'value'> => ({
      label: company.name.en,
      value: company.id.toString(),
    });

    this.isLoadingData = true;

    const countryId = localStorage.getItem('country');

    this.homeCleaningCompanyService
      .getCompanies({ countryId })
      .pipe(
        finalize(() => (this.isLoadingData = false)),
        takeUntil(this.destroy$),
      )
      .subscribe((res: HomecleanCompany[]) => {
        this.companies = res;
        this.companiesOptions = res.map(toOptions);
      });
  }

  private handleCompanyChange(): void {
    const toOptions = (branch: HcBranch): Pick<IOption, 'label' | 'value'> => ({
      label: branch.companyName.en,
      value: branch.id.toString(),
    });

    this.form.controls.company.valueChanges
      .pipe(
        tap(() => {
          this.isLoadingData = true;
          this.form.controls.branch.reset('');
        }),
        switchMap((branchId: number) => this.getBranches$(branchId)),
        takeUntil(this.destroy$),
      )
      .subscribe((branches: HcBranch[]) => {
        this.branches = branches.map(toOptions);
        this.isLoadingData = false;
      });
  }

  private getBranches$(hcCompanyId: number): Observable<HcBranch[]> {
    return this.homeCleaningBranchService.getHcBranches({ hcCompanyId });
  }
}
