// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-height-fix {
  height: 530px;
}

.area-card {
  height: 500px;
  overflow: scroll;
}

.uploaded-file--height {
  height: 50px;
}
.uploaded-file--image {
  width: 50px;
  height: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/branch/components/create-branch/branch-billing/branch-billing.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".card-height-fix {\n  height: 530px;\n}\n\n.area-card {\n  height: 500px;\n  overflow: scroll;\n}\n\n.uploaded-file--height {\n  height: 50px;\n}\n.uploaded-file--image {\n  width: 50px;\n  height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
