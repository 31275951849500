import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DialogComponent } from './components/dialog.component';
import { DialogService } from '@shared/components/dialog/services/dialog.service';
import { ModalModule } from 'ng-uikit-pro-standard';

@NgModule({
  declarations: [DialogComponent],
  imports: [CommonModule, ModalModule],
  providers: [DialogService],
})
export class DialogModule {}
