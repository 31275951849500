import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { FormStatus } from '@shared/enums/form-status.enum';
import { ImageUploaderHttpService } from '@shared/service/image-uploader-http.service';
import { CarWashAddOn } from "@shared/models/carwash/car-wash-add-on.model";
import { CarWashAddonService } from "@shared/service/car-wash-add-ons";

@Component({
  templateUrl: './car-wash-add-on-edit-dialog.component.html',
})
export class CarWashAddOnEditDialogComponent implements OnInit {
  isEditMode: boolean;
  isDisableButton: boolean;
  formData: Partial<CarWashAddOn>;
  imageEn: string;
  imageAr: string;
  isLoading: boolean;

  private readonly destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: { carWashAddOn: CarWashAddOn | null; groupId: number } | any,
    private readonly dialogRef: DialogRef<CarWashAddOnEditDialogComponent>,
    private readonly carWashAddonService: CarWashAddonService,
    private readonly imageUploaderHttpService: ImageUploaderHttpService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.isEditMode = !!this.data.carWashAddOn;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(): void {
    this.dialogRef.close();
  }

  formChanged(carWashPackage: Partial<CarWashAddOn>): void {
    this.formData = {
      ...carWashPackage,
    };
  }

  formStatusChanged(status: FormStatus): void {
    this.isDisableButton = status === FormStatus.Invalid;
  }

  createPackage(): void {
    const data: Partial<CarWashAddOn> = {
      ...this.formData,
      ...(this.imageEn && {
        image: {
          en: this.imageEn,
          ar: this.imageAr,
        },
      }),
      ...(this.data.groupId ? { groupId: this.data.groupId } : {}),
    };
    const action$ = this.isEditMode
      ? this.carWashAddonService.updateAddon(this.data.carWashAddOn.id, data)
      : this.carWashAddonService.createAddon(data);

    this.isLoading = true;
    action$
      .pipe(
        tap(() => {
          this.isLoading = false;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (company: CarWashAddOn) => {
          this.dialogRef.close(company);
        },
        (error) => {
          this.isLoading = false;
          this.cdr.markForCheck();
        },
      );
  }

  uploadImageEnFile(file: File): void {
    this.uploadImage(file)
      .pipe(takeUntil(this.destroy$))
      .subscribe((link: string) => {
        this.imageEn = link;
      });
  }

  uploadImageArFile(file: File): void {
    this.uploadImage(file)
      .pipe(takeUntil(this.destroy$))
      .subscribe((link: string) => {
        this.imageAr = link;
      });
  }

  private uploadImage(file): Observable<string> {
    this.isLoading = true;

    return this.imageUploaderHttpService.imageUpload(file).pipe(
      tap(() => {
        this.cdr.markForCheck();
        this.isLoading = false;
      }),
    );
  }
}
