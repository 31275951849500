import { TranslateService } from '@ngx-translate/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { FormStatus } from '../../enums/form-status.enum';

@Component({
  selector: 'app-form-field-error',
  templateUrl: './form-field-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldErrorComponent implements OnInit {
  @Input() control: AbstractControl;
  @Output() setErrors = new EventEmitter<boolean>();

  message$: Observable<{ status: FormStatus; text: string }>;
  readonly FormStatus = FormStatus;

  constructor(private readonly translate: TranslateService) {}

  ngOnInit(): void {
    this.initMessage();
  }

  private initMessage(): void {
    this.message$ = this.control.statusChanges.pipe(
      startWith(() => null),
      debounceTime(200),
      map((status: FormStatus) => {
        if (status === FormStatus.Invalid) {
          this.setErrors.emit(true);
        }
        return { text: this.getTextError(), status };
      })
    );
  }

  private getTextError(): string {
    if (!this.control.errors) {
      this.setErrors.emit(false);
      return;
    }

    const firstKey: string = Object.keys(this.control.errors)[0];
    const error: any = this.control.getError(firstKey);
    const customMessages: { [key: string]: string } = {
      minlength: this.getTranslation(firstKey, error.requiredLength),
      maxlength: this.getTranslation(firstKey, error.requiredLength),
      min: this.getTranslation(firstKey, error.min),
      max: this.getTranslation(firstKey, error.max),
    };

    return (
      customMessages[firstKey] ||
      this.getTranslation(firstKey) ||
      this.getTranslation('UNKNOWN')
    );
  }

  private getTranslation(key: string, length?: number): any {
    return this.translate.instant(`ERRORS.${key}`, length ? { length } : {});
  }
}
