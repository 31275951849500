import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { DialogService } from '@shared/components/dialog/services/dialog.service';
import { Crud } from '@shared/models';
import { PageInfo } from '@shared/models/global/response';
import { filter, finalize } from 'rxjs/operators';
import { CrudService } from '@app/shared/service/crud';
import { CrudEditDialogComponent } from '../crud-edit-dialog/crud-edit-dialog.component';
import { ManagePermissionsComponent } from '../manage-permissions/manage-permissions.component';

@Component({
  selector: 'app-crud-list',
  templateUrl: './crud-list.component.html',
})
export class CrudListComponent implements OnInit, OnDestroy {
  private subscriber: Subscription;
  pagination$: Observable<PageInfo>;
  cruds: Array<Crud>;
  isLoading: boolean;
  getPluralPageNameBounded: Function;

  constructor(
    private readonly crudService: CrudService,
    private readonly dialog: DialogService,
  ) {}

  ngOnInit(): void {
    this.loadCruds();
    this.pagination$ = this.crudService.pagination$;
    this.getPluralPageNameBounded =
      this.crudService.getPluralPageName.bind(this);
  }

  openDialog(crud: Crud = null): void {
    this.dialog
      .open(CrudEditDialogComponent, {
        selected: crud,
        allCruds: this.cruds.filter((page) => page.id !== crud?.id),
      })
      .afterClosed$.pipe(filter(Boolean))
      .subscribe(() => this.loadCruds());
  }

  openPermissionsDialog(firstPage: Crud = null): void {
    this.dialog
      .open(ManagePermissionsComponent, {
        firstPage,
      })
      .afterClosed$.pipe(filter(Boolean))
      .subscribe(() => this.loadCruds());
  }

  private loadCruds(): void {
    this.isLoading = true;
    this.unsubscribe();
    this.subscriber = this.crudService
      .getCruds()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((cruds) => (this.cruds = cruds));
  }

  private unsubscribe(): void {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
