import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiResponse } from '@shared/models/global/apiResponse';
import { PageInfo, SelectOptions } from '@shared/models/global/response';
import { PaginationHelper } from '@app/shared/helpers/pagination-helper';
import { CarWashPackageHttpService } from "@shared/service/car-wash-packages/car-wash-package-http.service";
import { CarWashPackage } from "@shared/models/carwash/car-wash-package.model";

@Injectable({ providedIn: 'root' })
export class CarWashPackageService {
  readonly pagination$: Subject<PageInfo> = new Subject<PageInfo>();

  constructor(
    private readonly carWashPackageHttpService: CarWashPackageHttpService,
  ) {}

  getPackages(filters?: Record<string, string>): Observable<CarWashPackage[]> {
    const toData = (res: ApiResponse<CarWashPackage[]>) => res.data;
    const toPagination = (res: ApiResponse<CarWashPackage[]>) =>
      this.pagination$.next(PaginationHelper.camelToSnakeCase(res.pageInfo));

    return this.carWashPackageHttpService
      .getPackages(filters)
      .pipe(tap(toPagination), map(toData));
  }

  createPackage(data: Partial<CarWashPackage>): Observable<CarWashPackage> {
    const toCompany = (res: ApiResponse<CarWashPackage>) => res.data;

    return this.carWashPackageHttpService
      .createPackage(data)
      .pipe(map(toCompany));
  }

  updatePackage(
    id: number,
    data: Partial<CarWashPackage>,
  ): Observable<CarWashPackage> {
    const toCompany = (res: ApiResponse<CarWashPackage>) => res.data;

    return this.carWashPackageHttpService
      .updatePackage(id, data)
      .pipe(map(toCompany));
  }

  removePackage(id: number): Observable<void> {
    return this.carWashPackageHttpService.removePackage(id);
  }

  getCarWashPackageTypes(): Observable<Array<SelectOptions<string>>> {
    const callTypes: SelectOptions<string>[] = [
      { label: 'One time', value: 'oneTime' },
      { label: 'subscription', value: 'subscription' },
    ];
    return of(callTypes);
  }
}
