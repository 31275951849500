import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CustomerRoutingModule } from './customer-routing.module';
import { CustomerListComponent } from './components/customer-list/customer-list.component';
import { CustomerDetailsComponent } from './components/customer-details/customer-details.component';
import { CreateAddressComponent } from './components/create-address/create-address.component';
import { RedirectFromNumberComponent } from './components/redirect-from-number/redirect-from-number.component';
import { CustomerSchedulesComponent } from './components/customer-schedules/customer-schedules.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';

@NgModule({
  imports: [SharedModule.forRoot(), CustomerRoutingModule],
  declarations: [
    CustomerListComponent,
    CustomerDetailsComponent,
    CreateAddressComponent,
    RedirectFromNumberComponent,
    ChangePasswordComponent,
    CustomerSchedulesComponent,
    ChangePasswordComponent
  ]
})
export class CustomerModule {}
