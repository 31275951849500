import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  OrderDetails,
  OrderCashPayment,
} from 'src/app/shared/models/orders/order';
import { SelectOptions } from 'src/app/shared/models/global/response';
import { GlobalService } from 'src/app/shared/service/global.service';
import { LogDriverService } from 'src/app/shared/service/log-driver.service';
import { DriversService } from 'src/app/shared/service/drivers.service';
import { DriverList } from 'src/app/shared/models/cars/driver';
import { LogDriverFromList } from 'src/app/shared/models/cars/log-driver';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-pay-cash',
  templateUrl: './pay-cash.component.html',
  styleUrls: ['./pay-cash.component.css'],
})
export class PayCashComponent implements OnChanges {
  constructor(
    private globalService: GlobalService,
    private driversService: DriversService,
    private logisticsDriver: LogDriverService
  ) {}
  @Input() order: OrderDetails;
  @Input() init: boolean;
  @Output() selected: EventEmitter<OrderCashPayment> = new EventEmitter();
  pickedUpByJustclean: string;
  laundryDrivers: SelectOptions<number>[];
  logDrivers: SelectOptions<number>[];
  orderPayCashBody: OrderCashPayment;
  @ViewChild('description', {static: false}) description: NgModel;
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.init && changes.init.currentValue) {
      console.log(changes);

      this.initData();
    }
  }

  driverSelected(event) {
    this.orderPayCashBody.driver_id = event.value;
    this.selected.emit(this.orderPayCashBody);
  }

  descriptionTyped(event) {
    this.selected.emit(this.orderPayCashBody);
  }

  getLaundryDrivers() {
    this.driversService
      .getDriver(
        this.globalService.headers,
        this.order.order_result.laundry_branch.id,
        { page: 0 }
      )
      .subscribe(
        (response) => {
          if (response.code === 200) {
            const drivers = response.data as DriverList[];
            this.laundryDrivers = drivers.map((e) => {
              return {
                label: e.first_name + ' ' + e.last_name,
                value: e.id,
              } as SelectOptions<number>;
            });
          }
        },
      );
  }

  getLogisticDrivers() {
    this.logisticsDriver
      .getAllDriverForLogistics(this.globalService.headers, { page: 0 })
      .subscribe(
        (response) => {
          if (response.code === 200) {
            const drivers = response.data as LogDriverFromList[];
            this.logDrivers = drivers.map((e) => {
              return {
                label: e.first_name + ' ' + e.last_name,
                value: e.id,
              } as SelectOptions<number>;
            });
          }
        },
      );
  }

  private initData() {
    this.orderPayCashBody = {
      description: '',
      driver_id: null,
      order_id: this.order.order_result.id,
    };
    this.pickedUpByJustclean = null;
    if (this.description) {
      this.description.reset();
    }
    this.laundryDrivers = [];
    this.logDrivers = [];
    this.getLaundryDrivers();
    this.getLogisticDrivers();
  }
}
