import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SalesRoutingModule } from './sales-routing.module';
import { KpiSetupComponent } from './components/kpi-setup/kpi-setup.component';
import { SharedModule } from '../shared/shared.module';
import { KpiAchievementComponent } from './components/kpi-achievement/kpi-achievement.component';


@NgModule({
  declarations: [KpiSetupComponent, KpiAchievementComponent],
  imports: [
    CommonModule,
    SalesRoutingModule,
    SharedModule.forRoot()
  ]
})
export class SalesModule { }
