import { GlobalService } from 'src/app/shared/service/global.service';
import { HcService, HcServiceTimeSlot } from '@app/shared/models';
import { HomeCleaningServicesService } from '@app/shared/service/home-cleaning/home-cleaning-services.service';
import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Inject,
  HostListener,
} from '@angular/core';
import { TabsetComponent } from 'ng-uikit-pro-standard';

import { Days } from '@shared/enums';
import { TimeSlotsConfig } from '@app/modules/carwash/modules/carwash-branch/shared/models/time-slots-config.model';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { Status } from '@shared/enums/status.enum';
import { concat, Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { CarwashBranchTimeServiceService } from '@app/modules/carwash/modules/carwash-branch/shared/services/carwash-branch-time-service/carwash-branch-time-service.service';
import { LocalStoreService } from '../../../../../../../libs/justclean-services/src/lib/local-store/local-store.service';
import { CountryService } from '@shared/service/country.service';
import { CountryV4 } from '@shared/models/countries/country-v4';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-working-hours',
  templateUrl: './working-hours.component.html',
  styleUrls: ['./working-hours.component.scss'],
})
export class WorkingHoursComponent implements OnInit {
  isLoading: boolean;
  isLoadingData: boolean;
  timeSlotTimesCountries: Record<string, string | number | null>[] = [];

  isMobileResolution: boolean;
  private window: Window;
  private hcServiceTimeSlots: number[];
  private readonly countryId: number =
    +LocalStoreService.getItems<string>('country');
  private readonly destroy$: Subject<void> = new Subject<void>();
  @ViewChild('staticTabs', { static: true }) staticTabs: TabsetComponent;

  tabs: { title: string; times: TimeSlotsConfig[] }[] = [];
  form: UntypedFormArray;
  constructor(
    @Inject(DIALOG_DATA)
    public readonly data:
      | {
          dataRow: Array<HcService>;
          PageNamePrefix: string;
          foreignKeyParam: Object;
        }
      | any,
    private readonly dialogRef: DialogRef<WorkingHoursComponent>,
    private readonly cd: ChangeDetectorRef,
    private readonly fb: UntypedFormBuilder,
    private readonly globalService: GlobalService,
    private readonly homeCleaningServicesService: HomeCleaningServicesService,
    private readonly carwashBranchTimeServiceService: CarwashBranchTimeServiceService,
    private readonly countryService: CountryService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  private get firstService(): HcService {
    return this.data.dataRow[0] || this.data.dataRow;
  }

  ngOnInit(): void {
    this.isLoadingData = true;
    this.initializeWindowObject();
    this.countryService
      .getCountry(this.countryId)
      .pipe(
        map(({ timeSlotTimesCountries }) => timeSlotTimesCountries),
        takeUntil(this.destroy$),
      )
      .subscribe((res: Record<string, string | number | null>[]) => {
        this.timeSlotTimesCountries = res;
        this.loadData();
      });
  }

  saveTimes(): void {
    this.isLoading = true;

    const toTime = ({ id, status, cost }): any => ({
      timeSlotTimeId: id,
      status: status ? Status.Active : Status.Inactive,
      cost,
    });
    const toTimes = (
      acc,
      cur: { title: string; times: TimeSlotsConfig[] },
    ): TimeSlotsConfig[] => [...acc, ...cur.times];

    const times: any[] = this.form.value?.reduce(toTimes, [])?.map(toTime);

    const subscribers$: Array<Observable<any>> = this.data.dataRow[0]
      ? this.data.dataRow.map((service) => {
          return this.homeCleaningServicesService.updateHcService(service.id, {
            hcServiceSSTDs: times,
          });
        })
      : [
          this.homeCleaningServicesService.updateHcService(
            this.firstService?.id,
            {
              hcServiceSSTDs: times,
            },
          ),
        ];

    this.updateCosts(times);

    if (subscribers$.length) {
      /** calling observables sequentially */
      concat(...subscribers$)
        .pipe(
          toArray(),
          tap(() => {
            this.isLoading = false;
          }),
          takeUntil(this.destroy$),
        )
        .subscribe(
          () => this.dialogRef.close({}),
          (error) => {
            this.isLoading = false;
            this.cd.markForCheck();
          },
        );
    } else {
      this.dialogRef.close();
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  private loadData(): void {
    this.carwashBranchTimeServiceService
      .getAllTimesConfig({
        hcServiceId: this.firstService?.id,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((timeSlotTimes: TimeSlotsConfig[]) => {
        this.hcServiceTimeSlots = timeSlotTimes.map(
          (time: TimeSlotsConfig) => +time.id,
        );
        this.loadAllTimes();
      });
  }

  private initForm(timeSlotsConfigs: HcServiceTimeSlot[]): void {
    const toSortedHcServiceTimeSlot = (
      acc,
      timeSlotConfig: HcServiceTimeSlot | TimeSlotsConfig,
    ) => {
      const timeSlotCost = this.timeSlotTimesCountries.find(
        (ts) => timeSlotConfig.id === ts.timeSlotTimeId,
      );
      const slots = acc[timeSlotConfig.timeSlotDayId]
        ? acc[timeSlotConfig.timeSlotDayId]
        : [];
      acc[timeSlotConfig.timeSlotDayId] = [
        ...slots,
        { ...timeSlotConfig, cost: timeSlotCost?.cost || 0 },
      ];

      return acc;
    };

    const sortedTimeSlotsConfig: Record<number, HcServiceTimeSlot[]> =
      timeSlotsConfigs.reduce(toSortedHcServiceTimeSlot, {});

    const timeZoneDifference = this.globalService.getTimezoneDifference();
    Object.keys(sortedTimeSlotsConfig).forEach((key) => {
      const arr = sortedTimeSlotsConfig[key] as HcServiceTimeSlot[];
      const lastTimesAfterConversion = arr.splice(
        /** 4 is the parts count of each hour (03:00, 03:15, 03:30, 03:45)...etc */
        arr.length - 4 * timeZoneDifference,
      );
      arr.splice(0, 0, ...lastTimesAfterConversion);
    });

    this.form = this.fb.array([
      this.fb.group({
        title: Days[Days.Monday],
        times:
          this.fb.array(
            sortedTimeSlotsConfig?.[Days.Monday]?.map(
              (config: HcServiceTimeSlot | TimeSlotsConfig) =>
                this.fb.group(this.mapActiveStatus(config)),
            ),
          ) || [],
      }),
      this.fb.group({
        title: Days[Days.Tuesday],
        times:
          this.fb.array(
            sortedTimeSlotsConfig?.[Days.Tuesday]?.map(
              (config: HcServiceTimeSlot | TimeSlotsConfig) =>
                this.fb.group(this.mapActiveStatus(config)),
            ),
          ) || [],
      }),
      this.fb.group({
        title: Days[Days.Wednesday],
        times:
          this.fb.array(
            sortedTimeSlotsConfig?.[Days.Wednesday]?.map(
              (config: HcServiceTimeSlot | TimeSlotsConfig) =>
                this.fb.group(this.mapActiveStatus(config)),
            ),
          ) || [],
      }),
      this.fb.group({
        title: Days[Days.Thursday],
        times:
          this.fb.array(
            sortedTimeSlotsConfig?.[Days.Thursday]?.map(
              (config: HcServiceTimeSlot | TimeSlotsConfig) =>
                this.fb.group(this.mapActiveStatus(config)),
            ),
          ) || [],
      }),
      this.fb.group({
        title: Days[Days.Friday],
        times:
          this.fb.array(
            sortedTimeSlotsConfig?.[Days.Friday]?.map(
              (config: HcServiceTimeSlot | TimeSlotsConfig) =>
                this.fb.group(this.mapActiveStatus(config)),
            ),
          ) || [],
      }),
      this.fb.group({
        title: Days[Days.Saturday],
        times:
          this.fb.array(
            sortedTimeSlotsConfig?.[Days.Saturday]?.map(
              (config: HcServiceTimeSlot | TimeSlotsConfig) =>
                this.fb.group(this.mapActiveStatus(config)),
            ),
          ) || [],
      }),
      this.fb.group({
        title: Days[Days.Sunday],
        times:
          this.fb.array(
            sortedTimeSlotsConfig?.[Days.Sunday]?.map(
              (config: HcServiceTimeSlot | TimeSlotsConfig) =>
                this.fb.group(this.mapActiveStatus(config)),
            ),
          ) || [],
      }),
    ]);
  }

  private mapActiveStatus(config: HcServiceTimeSlot | TimeSlotsConfig) {
    return {
      ...config,
      status: !!this.hcServiceTimeSlots?.includes(config.id),
    };
  }

  private loadAllTimes(): void {
    this.carwashBranchTimeServiceService
      .getAllTimesConfig()
      .subscribe((timeSlotsConfig: TimeSlotsConfig[]) => {
        this.initForm(timeSlotsConfig as any);
        this.isLoadingData = false;
        this.cd.detectChanges();
      });
  }

  private updateCosts(times: any[]): void {
    this.countryService
      .getCountry(this.countryId)
      .pipe(
        switchMap((country: CountryV4) =>
          this.countryService.updateCountry(this.countryId, {
            isoCode: country.isoCode,
            status: Status.Active,
            flagUrl: country.flagUrl,
            name: country.name,
            timeSlotTimesCountries: times.map((time) => ({
              cost: time.cost,
              timeSlotTimeId: time.timeSlotTimeId,
            })),
          }),
        ),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  private initializeWindowObject() {
    this.window = this.document.defaultView;
    this.checkMobileResolution();
  }

  @HostListener('window:resize', ['$event'])
  checkMobileResolution(): void {
    this.isMobileResolution = this.window.innerWidth < 1200;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
