import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../../../../shared/service/global.service';
import { LaundryService } from '../../../../../shared/service/laundry.service';
import { CategoriesService } from '../../../../../shared/service/categories.service';
import {
  LaundryMarketing,
  LaundryMArketingInfo,
} from '../../../../../shared/models/laundries/laundry';
import { zip } from 'rxjs';

@Component({
  selector: 'app-step-marketing',
  templateUrl: './step-marketing.component.html',
  styleUrls: ['./step-marketing.component.css'],
})
export class StepMarketingComponent implements OnInit {
  files: File;
  fileMain: File;
  descEnText: any;
  descArText: any;
  loader = false;
  laundryId: number;
  page: number;
  isUpdate: boolean;
  laundryMarketingInfo: LaundryMArketingInfo;
  constructor(
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private laundryService: LaundryService,
    private router: Router,
    private catService: CategoriesService,
  ) {}

  ngOnInit() {
    this.laundryId = this.route.snapshot.params.id;
    this.isUpdate =
      this.route.snapshot.queryParams.isUpdate === 'true' ? true : false;
    this.page = this.route.snapshot.queryParams.page;
    this.initData();
  }

  showFiles() {
    if (this.files) {
      return this.files.name;
    }
  }
  showFilesMain() {
    if (this.fileMain) {
      return this.fileMain.name;
    }
  }

  loadFile(event): void {
    this.files = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function () {
      const output = document.getElementById('preview') as HTMLImageElement;
      output.src = reader.result.toString();
    };
    reader.readAsDataURL(event.target.files[0]);
    this.showFiles();
  }

  loadFileMain(event): void {
    this.fileMain = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function () {
      const output = document.getElementById('previewMain') as HTMLImageElement;
      output.src = reader.result.toString();
    };
    reader.readAsDataURL(event.target.files[0]);
    this.showFilesMain();
  }

  isFileEmpty() {
    return (
      this.files && this.files.size === 0 && !this.files.name.includes('http')
    );
  }

  isFileMainEmpty() {
    return (
      this.files &&
      this.fileMain.size === 0 &&
      !this.fileMain.name.includes('http')
    );
  }

  initData() {
    if (this.isUpdate) {
      this.laundryService
        .getMarketing(this.globalService.headers, this.laundryId)
        .subscribe((response) => {
          this.laundryMarketingInfo = response.data;
          this.files = new File([''], this.laundryMarketingInfo.image_logo, {
            type: 'image/jpeg',
          });
          this.fileMain = new File(
            [''],
            this.laundryMarketingInfo.image_backdrop,
            { type: 'image/jpeg' },
          );
          const output = document.getElementById('preview') as HTMLImageElement;
          output.src = this.laundryMarketingInfo.image_logo;
          const outputBackDrop = document.getElementById(
            'previewMain',
          ) as HTMLImageElement;
          outputBackDrop.src = this.laundryMarketingInfo.image_backdrop;
          this.showFiles();
          this.showFilesMain();
          this.descArText =
            this.laundryMarketingInfo.translations.ar.description;
          this.descEnText =
            this.laundryMarketingInfo.translations.en.description;
        });
    } else {
      this.files = new File([''], 'Upload your icon(*)');
      this.fileMain = new File([''], 'Upload the main picture(*)');
    }
  }

  saveLogo() {
    this.loader = true;
    const marketing = new LaundryMarketing();
    if (
      !this.files.name.includes('http') &&
      this.fileMain.name.includes('http')
    ) {
      this.catService
        .imageUpload(this.files, 'LaundryLogo')
        .subscribe((response) => {
          if (response.code === 200) {
            marketing.laundry_id = this.laundryId;
            marketing.translations = [];
            marketing.translations[0] = {
              description: this.descEnText,
              logo: response.data,
              language_id: 1,
              main_image: this.laundryMarketingInfo.image_backdrop,
            };
            marketing.translations[1] = {
              description: this.descArText,
              logo: response.data,
              language_id: 2,
              main_image: this.laundryMarketingInfo.image_backdrop,
            };
            marketing.laundry_images = [];
            marketing.laundry_images[0] = {
              file_type: this.files.type,
              name: this.files.name,
              url: response.data,
            };
            marketing.laundry_images[1] = {
              file_type: this.fileMain.type,
              name: this.fileMain.name,
              url: this.laundryMarketingInfo.image_backdrop,
            };
          }
          this.laundryService
            .addMarkting(this.globalService.headers, marketing)
            .subscribe((data) => {
              this.loader = false;
              this.router.navigate(['dashboard/laundries/laundry-list'], {
                queryParams: { page: this.page },
              });
            });
        });
    }
    if (
      this.files.name.includes('http') &&
      !this.fileMain.name.includes('http')
    ) {
      this.catService
        .imageUpload(this.fileMain, 'LaundryBackdrop')
        .subscribe((response) => {
          if (response.code === 200) {
            marketing.laundry_id = this.laundryId;
            marketing.translations = [];
            marketing.translations[0] = {
              description: this.descEnText,
              logo: this.laundryMarketingInfo.image_logo,
              language_id: 1,
              main_image: response.data,
            };
            marketing.translations[1] = {
              description: this.descArText,
              logo: this.laundryMarketingInfo.image_logo,
              language_id: 2,
              main_image: response.data,
            };
            marketing.laundry_images = [];
            marketing.laundry_images[0] = {
              file_type: this.files.type,
              name: this.files.name,
              url: this.laundryMarketingInfo.image_logo,
            };
            marketing.laundry_images[1] = {
              file_type: this.fileMain.type,
              name: this.fileMain.name,
              url: response.data,
            };
          }
          this.laundryService
            .addMarkting(this.globalService.headers, marketing)
            .subscribe((data) => {
              this.loader = false;
              this.router.navigate(['dashboard/laundries/laundry-list'], {
                queryParams: { page: this.page },
              });
            });
        });
    }
    if (
      !this.files.name.includes('http') &&
      !this.fileMain.name.includes('http')
    ) {
      const request = zip(
        this.catService.imageUpload(this.files, 'LaundryLogo'),
        this.catService.imageUpload(this.fileMain, 'LaundryBackdrop'),
      );

      request.subscribe(([responseOne, responseTwo]) => {
        if (responseOne.code === 200) {
          marketing.laundry_id = this.laundryId;
          marketing.translations = [];
          marketing.translations[0] = {
            description: this.descEnText,
            logo: this.files.name.includes('http')
              ? this.laundryMarketingInfo.image_logo
              : responseOne.data,
            language_id: 1,
            main_image: this.fileMain.name.includes('http')
              ? this.laundryMarketingInfo.image_backdrop
              : responseTwo.data,
          };
          marketing.translations[1] = {
            description: this.descArText,
            logo: this.files.name.includes('http')
              ? this.laundryMarketingInfo.image_logo
              : responseOne.data,
            language_id: 2,
            main_image: this.fileMain.name.includes('http')
              ? this.laundryMarketingInfo.image_backdrop
              : responseTwo.data,
          };
          marketing.laundry_images = [];
          marketing.laundry_images[0] = {
            file_type: this.files.type,
            name: this.files.name,
            url: this.files.name.includes('http')
              ? this.laundryMarketingInfo.image_logo
              : responseOne.data,
          };
          marketing.laundry_images[1] = {
            file_type: this.fileMain.type,
            name: this.fileMain.name,
            url: this.fileMain.name.includes('http')
              ? this.laundryMarketingInfo.image_backdrop
              : responseTwo.data,
          };
        }
        this.laundryService
          .addMarkting(this.globalService.headers, marketing)
          .subscribe((data) => {
            this.loader = false;
            this.router.navigate(['dashboard/laundries/laundry-list'], {
              queryParams: { page: this.page },
            });
          });
      });
    }
    if (
      this.files.name.includes('http') &&
      this.fileMain.name.includes('http')
    ) {
      marketing.laundry_id = this.laundryId;
      marketing.translations = [];
      marketing.translations[0] = {
        description: this.descEnText,
        logo: this.laundryMarketingInfo.image_logo,
        language_id: 1,
        main_image: this.laundryMarketingInfo.image_backdrop,
      };
      marketing.translations[1] = {
        description: this.descArText,
        logo: this.laundryMarketingInfo.image_logo,
        language_id: 2,
        main_image: this.laundryMarketingInfo.image_backdrop,
      };
      marketing.laundry_images = [];
      marketing.laundry_images[0] = {
        file_type: this.files.type,
        name: this.files.name,
        url: this.laundryMarketingInfo.image_logo,
      };
      marketing.laundry_images[1] = {
        file_type: this.fileMain.type,
        name: this.fileMain.name,
        url: this.laundryMarketingInfo.image_backdrop,
      };
      this.laundryService
        .addMarkting(this.globalService.headers, marketing)
        .subscribe((data) => {
          this.loader = false;
          this.router.navigate(['dashboard/laundries/laundry-list'], {
            queryParams: { page: this.page },
          });
        });
    }
  }
}
