// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gold {
    background: #D7B740;
}
.gold-color {
    color: #D7B740;
}

.text-center {
  text-align: center;
}

.search-box {
  height: 3rem;
  line-height: 3.0;
}

.table {
  margin-top: 1rem; /* Adjust this to your liking */
}

.md-tabs {
  margin-bottom: 1rem; /* Adjust this to your liking */
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/laundries/components/laundry-position/laundry-position.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,cAAc;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,gBAAgB,EAAE,+BAA+B;AACnD;;AAEA;EACE,mBAAmB,EAAE,+BAA+B;AACtD","sourcesContent":[".gold {\n    background: #D7B740;\n}\n.gold-color {\n    color: #D7B740;\n}\n\n.text-center {\n  text-align: center;\n}\n\n.search-box {\n  height: 3rem;\n  line-height: 3.0;\n}\n\n.table {\n  margin-top: 1rem; /* Adjust this to your liking */\n}\n\n.md-tabs {\n  margin-bottom: 1rem; /* Adjust this to your liking */\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
