/** @format */

import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  CouponsFromList,
  CouponDiscountType,
  CouponCustomerType,
} from 'src/app/shared/models/offers/offers';
import { finalize } from 'rxjs/operators';
import { Status } from './../../../../shared/enums/status.enum';
import { GlobalService } from 'src/app/shared/service/global.service';
import { CouponsService } from 'src/app/shared/service/coupons.service';
import { PageInfo } from 'src/app/shared/models/global/response';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-coupon-list',
  templateUrl: './coupon-list.component.html',
  styleUrls: ['./coupon-list.component.css'],
})
export class CouponListComponent implements OnInit, OnDestroy {
  openDialog: boolean;
  coupons: CouponsFromList[];
  pagination: PageInfo;
  selectedStatus: Status;
  statuses = Object.keys(Status);
  search: string = '';
  STATUSES = Status;
  isLoading: boolean;
  subscriber$: any;

  constructor(
    private globalService: GlobalService,
    private couponService: CouponsService,
    private toast: ToastService,
  ) {}

  ngOnInit() {
    this.openDialog = false;
    this.getPage(1);
  }

  hideDialog(isReload: boolean) {
    this.openDialog = false;
    if (isReload) {
      this.getPage(1);
    }
  }

  getPage(pageNumber: number) {
    this.loadCoupons(pageNumber);
  }

  resetFilters(): void {
    this.selectedStatus = null;
    this.search = '';
    this.getPage(1);
  }

  getDateFromString(date: string): string {
    return this.globalService.getDateFromString(date);
  }

  changeStatus(coupon: CouponsFromList) {
    this.couponService
      .changeStatus(this.globalService.headers, {
        coupon_id: coupon.id,
        status: coupon.coupon_remaining > 0 ? 'Deactivated' : 'Created',
      })
      .subscribe((response) => {
        if (response.code === 200) {
          this.getPage(this.pagination.current_page);
        } else {
          this.toast.error(response.message);
        }
      });
  }

  showStatus(coupon: CouponsFromList) {
    return coupon.coupon_remaining > 0 && !coupon.expire
      ? 'Active'
      : 'Inactive';
  }

  showAmount(coupon: CouponsFromList) {
    if (coupon.discount_type) {
      return coupon.discount_type === CouponDiscountType.percentage
        ? coupon.discount_percentage + ' %'
        : coupon.discount_amount + ' ' + this.globalService.getCurrency();
    } else {
      return coupon.amount + ' ' + this.globalService.getCurrency();
    }
  }

  pretyTextForTypeCustomer(type: string) {
    switch (type) {
      case CouponCustomerType.all:
        return 'All';
      case CouponCustomerType.firstTime:
        return 'First Order';
      case CouponCustomerType.reorder:
        return 'Reorder';
    }
  }

  getLaunderies(coupon: CouponsFromList) {
    if (coupon.laundries) {
      let html = '';
      coupon.laundries.forEach((e, i) => {
        html +=
          '<span class="green-blue-color">' +
          (i + 1) +
          '- ' +
          e +
          '</span><br>';
      });
      return coupon.laundries.length > 0
        ? html
        : coupon.laundries === 0
        ? '<span class="error-text"> All Laundries</span>'
        : '<span class="error-text"> No Laundry</span>';
    } else {
      return '<span class="error-text"> No Laundry</span>';
    }
  }

  private unsubscribe(): void {
    if (this.subscriber$) {
      this.subscriber$.unsubscribe();
    }
  }

  private loadCoupons(pageNumber: number) {
    const couponParams: {page: number, status?: Status, search?: string} = {
      page: pageNumber,
    }
    if (this.search.length) {
      couponParams.search = this.search;
    }
    if (this.selectedStatus) {
      couponParams.status = this.selectedStatus;
    }
    this.isLoading = true;
    this.unsubscribe();
    this.subscriber$ = this.couponService
      .getCoupons(this.globalService.headers, couponParams)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((response) => {
        if (response.code === 200) {
          this.coupons = response.data as CouponsFromList[];
          this.pagination = response.page_info;
        }
      });
  }

  filterCoupons() {
    this.loadCoupons(1)
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
