import { Status } from '@app/shared/enums/status.enum';
import { UserSegment, Name } from '@shared/models';

export interface CashbackCampaign {
  name: Name;
  id: number;
  cashbackPercentage: number;
  maxCashbackAmount: number;
  startDate: string;
  endDate: string;
  status: Status;
  countryId: number;
  userSegmentId: number;
  validityInMinutes: number;
  cashbackReceivableGroups: { groupId: number }[];
  cashbackDeductibleGroups: { groupId: number }[];
  cashbackSegments: Partial<UserSegment>[];
}
