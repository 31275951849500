import { GlobalService } from 'src/app/shared/service/global.service';
import { Translation } from 'libs/justclean-models/src/lib/justclean-models';
import { GroupService } from '@app/shared/service/group';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { DialogService } from '@shared/components/dialog/services/dialog.service';
import { Ad } from '@shared/models';
import { Status } from '@shared/enums/status.enum';
import { filter, takeUntil } from 'rxjs/operators';
import { AdService } from '@shared/service/ads';
import { AdEditDialogComponent } from '../ad-edit-dialog/ad-edit-dialog.component';
import { PageInfo } from '@app/shared/models/global/response';

@Component({
  selector: 'app-ad-list',
  templateUrl: './ad-list.component.html',
})
export class AdListComponent implements OnInit, OnDestroy {
  readonly Status = Status;
  pagination$: Observable<PageInfo>;
  ads$: Observable<Array<Ad>>;

  checkIsActiveBounded: Function;

  branchName: string;
  /** DO NOT CHANGE THIS PROPERTY NAME AS IT'S RELATED TO PAGE GENERATOR TOO */
  branchNameAR: string;
  groupName: Translation;
  private branchKey: string;
  private branchId: number;
  private groupId: number;

  private destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    private readonly adService: AdService,
    private readonly groupService: GroupService,
    private readonly globalService: GlobalService,
    private readonly dialog: DialogService,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.initVariables();
    this.loadAds();
  }

  openDialog(ad: Ad = null): void {
    this.dialog
      .open(AdEditDialogComponent, { ad })
      .afterClosed$.pipe(filter(Boolean))
      .subscribe(() => {
        this.ads$ = null;
        this.loadAds();
      });
  }

  getPage(pageNumber: number) {
    this.loadAds(pageNumber);
  }

  private checkIsActive(endDate: string): boolean {
    const diff = this.globalService.checkFromNow(endDate);
    return !diff.includes('ago');
  }

  private loadGroup(): void {
    this.groupService
      .getGroupById(this.groupId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((group) => {
        this.groupName = group.name;
        this.adService.group = group;
      });
  }

  private initVariables(): void {
    this.setParamsValues();
    this.setServiceGlobalVariables();
    this.loadGroup();
    this.checkIsActiveBounded = this.checkIsActive.bind(this);
    this.pagination$ = this.adService.pagination$;
  }

  private setParamsValues(): void {
    this.branchName = this.route.snapshot.queryParams.branchName;
    this.branchNameAR =
      this.route.snapshot.queryParams.branchNameAR || this.branchName;
    this.branchKey =
      this.route.snapshot.queryParams.branchKey || 'hcCompanyBranchId';
    /** 5: Home cleaning coming from page generator */
    this.groupId = +this.route.snapshot.queryParams.groupId || 5;
    this.branchId = +this.route.snapshot.params.branchId;
  }

  private setServiceGlobalVariables(): void {
    this.adService.branchId = this.branchId;
    this.adService.branchKey = this.branchKey;
    this.adService.branchName = { en: this.branchName, ar: this.branchNameAR };
  }

  private loadAds(page: number = 1): void {
    const params = {
      [this.branchKey]: this.branchId,
      includeExpired: true,
      page,
      sortName: 'endDate',
      sortOrder: 'DESC',
      hasBlankCodes: true,
      type: 'deals',
    };
    this.ads$ = this.adService.getAds(params);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeStatus(ad: Ad) {
    let status = 'active'
    if (ad.status === 'active') {
      status = 'inactive';
    }
    this.adService.updateAd(ad.id, { status })
      .subscribe(() => {
      this.loadAds();
    });
  }
}
