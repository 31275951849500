import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  CarwashOrder,
  CwGarageBranchTicket, CwgBranchTicketAddon
} from "@app/modules/carwash/modules/carwash-order/components/shared/models/carwash-order.model";
import { CarwashOrderHttpService } from '@app/modules/carwash/modules/carwash-order/components/shared/services/carwash-order-http.service';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { map, tap } from 'rxjs/operators';
import { PageInfo } from '@shared/models/global/response';

@Injectable({ providedIn: 'root' })
export class CarwashOrderService {
  private pagination$: BehaviorSubject<PageInfo> =
    new BehaviorSubject<PageInfo>(null);

  constructor(
    private readonly carwashOrderHttpService: CarwashOrderHttpService,
  ) {}

  getOrderById(id: number): Observable<CarwashOrder> {
    return this.carwashOrderHttpService
      .getOrderById(id)
      .pipe(map((res: ApiResponse<CarwashOrder>) => res.data));
  }

  updateOrderStatus(
    ticketId: number,
    statusCwGarageBranchTicketId: number,
    userAdminId: number,
    reasonId?: number,
    reason?: string,
  ): Observable<CwGarageBranchTicket> {
    return this.carwashOrderHttpService
      .updateOrderStatus(
        ticketId,
        statusCwGarageBranchTicketId,
        userAdminId,
        reasonId,
        reason,
      )
      .pipe(map((res) => res.data));
  }

  getOrders(params: Record<string, any> = null): Observable<CarwashOrder[]> {
    const countryId = Number(localStorage.getItem('country'));

    return this.carwashOrderHttpService
      .getOrders({ ...params, countryId })
      .pipe(
        tap((res: ApiResponse<CarwashOrder[]>) =>
          this.pagination$.next({
            page_count: res.pageInfo.pageCount,
            current_page: res.pageInfo.currentPage,
            next_page: res.pageInfo.nextPage,
            item_count: res.pageInfo.itemCount,
          }),
        ),
        map((res: ApiResponse<CarwashOrder[]>) => res.data),
      );
  }


  getCwgBranchTicketAddons(params: Record<string, any> = null): Observable<CwgBranchTicketAddon[]> {
    const countryId = Number(localStorage.getItem('country'));

    return this.carwashOrderHttpService
      .getCwgBranchTicketAddons({ ...params, countryId })
      .pipe(
        tap((res: ApiResponse<CwgBranchTicketAddon[]>) =>
          this.pagination$.next({
            page_count: res.pageInfo.pageCount,
            current_page: res.pageInfo.currentPage,
            next_page: res.pageInfo.nextPage,
            item_count: res.pageInfo.itemCount,
          }),
        ),
        map((res: ApiResponse<CwgBranchTicketAddon[]>) => res.data),
      );
  }

  getPagination(): Observable<PageInfo> {
    return this.pagination$;
  }
}
