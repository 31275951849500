import { Component, OnInit } from '@angular/core';
import { NewPageInfo } from 'src/app/shared/models/global/response';
import { GlobalService } from 'src/app/shared/service/global.service';
import { JcDeliverService } from '../../jc-deliver.service';
import { ApiLibrary } from 'src/app/shared/helpers/http-params';
import { UserDelivery } from '../../jc-deliver.model';

@Component({
  selector: 'app-jc-del-user-list',
  templateUrl: './jc-del-user-list.component.html',
  styleUrls: ['./jc-del-user-list.component.css']
})
export class JcDelUserListComponent implements OnInit {
  pagination: NewPageInfo;
  search: string;
  users: UserDelivery[];
  constructor(
    private global: GlobalService,
    private jsCelService: JcDeliverService
  ) { }

  ngOnInit() {
    this.getUsers({
      page: 1
    });
  }

  getPage(event) {
    this.getUsers({
      page: 1
    });
  }

  filterUsers() {

  }

  private getUsers(params?: any) {
    this.jsCelService
      .getAllUsers(
        this.global.headers,
        new ApiLibrary().cleanObjectFromComponent(params)
      )
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.users = response.data;
            this.pagination = response.pageInfo;
          }
        },
      );
  }

}
