import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { HomecleanCompany } from '@app/modules/homeclean/modules/homeclean-company/shared/models';
import { expand, reduce } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class HomecleanCompanyHttpService {
  private readonly url = environment.jcDeliveryServer + 'hc-companies';

  constructor(private readonly http: HttpClient) {}

  getCompanies(params: any = {}): Observable<HomecleanCompany[]> {
    return this.http
      .get<ApiResponse<HomecleanCompany[]>>(this.url, { params })
      .pipe(
        expand((res) =>
          !res.pageInfo.nextPage
            ? EMPTY
            : this.http.get<ApiResponse<HomecleanCompany[]>>(
                this.url,
                this.updateRequestFormat(params, res.pageInfo.nextPage),
              ),
        ),
        reduce((records, res) => records.concat(res.data), []),
      ) as any;
  }

  createCompany(data: Partial<any>): Observable<ApiResponse<HomecleanCompany>> {
    return this.http.post<ApiResponse<any>>(this.url, data);
  }

  updateCompany(
    id: number,
    data: Partial<HomecleanCompany>,
  ): Observable<ApiResponse<HomecleanCompany>> {
    return this.http.patch<ApiResponse<HomecleanCompany>>(
      `${this.url}/${id}`,
      data,
    );
  }

  removeCompany(id: number): Observable<void> {
    return this.http.delete<void>(`${this.url}/${id}`);
  }

  private updateRequestFormat(params: any, page: number): { params: any } {
    params.page = page;
    return {
      params,
    };
  }
}
