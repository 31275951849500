import { takeUntil, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { DialogRef } from '@app/shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@app/shared/components/dialog/services/dialog.token';
import { HcPriorityChannel } from '@app/shared/models';
import { HomeCleaningBranchesService } from '@app/shared/service/home-cleaning/home-cleaning-branches.service';

@Component({
  selector: 'app-change-company-priority',
  templateUrl: './change-company-priority.component.html',
  styleUrls: ['./change-company-priority.component.scss'],
})
export class ChangeCompanyPriorityComponent implements OnInit, OnDestroy {
  isSaving: boolean;
  selectedSection: number;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: {
      hcPriorityChannelId: number;
      branch: { id: number; name: string };
      channels: HcPriorityChannel[];
    },
    private readonly dialogRef: DialogRef<ChangeCompanyPriorityComponent>,
    private readonly homecleanBranchesService: HomeCleaningBranchesService,
  ) {}

  ngOnInit(): void {
    this.selectedSection = this.data.hcPriorityChannelId;
  }

  close(isSubmit?: boolean): void {
    this.dialogRef.close(isSubmit ? this.selectedSection : null);
  }

  selectSection(hcPriorityChannelId): void {
    this.selectedSection = hcPriorityChannelId;
  }

  save(): void {
    this.isSaving = true;
    this.homecleanBranchesService
      .updateHcBranch(this.data.branch.id, {
        hcPriorityChannelId: this.selectedSection,
      })
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.isSaving = false)),
      )
      .subscribe(() => this.close(true));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
