import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Area } from '@shared/models';
import { AreaHttpService } from './area-http.service';

@Injectable({ providedIn: 'root' })
export class AreaService {
  constructor(private readonly groupHttpService: AreaHttpService) {}

  getAreaByID(areaId: number): Observable<Area> {
    const toDate = (res) => res.data;
    return this.groupHttpService.getAreaByID(areaId).pipe(map(toDate));
  }

  getAllAreas(params: Record<string, string | number>): Observable<Area[]> {
    return this.groupHttpService.getAllAreas(params);
  }
}
