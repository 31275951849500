import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { BranchRoutingModule } from './branch-routing.module';
import { BranchListComponent } from './components/branch-list/branch-list.component';
import { CreateBranchComponent } from './components/create-branch/create-branch.component';
import { BranchInfoComponent } from './components/create-branch/branch-info/branch-info.component';
import { BranchAreasComponent } from './components/create-branch/branch-areas/branch-areas.component';
import { BranchTimeslotsComponent } from './components/create-branch/branch-timeslots/branch-timeslots.component';
import { BranchSettingsComponent } from './components/create-branch/branch-settings/branch-settings.component';
import { BranchItemPriceComponent } from './components/create-branch/branch-item-price/branch-item-price.component';
import { CreateMinOrderPerAreaComponent } from './components/create-branch/create-min-order-per-area/create-min-order-per-area.component';
import { DiscountComponent } from './components/create-branch/discount/discount.component';
import { ListAuthDevicesComponent } from './devices/list-auth-devices/list-auth-devices.component';
import { DeviceAuthDetailComponent } from './devices/device-auth-detail/device-auth-detail.component';
import { BranchTatComponent } from './components/create-branch/branch-tat/branch-tat.component';
import { SpecialIconsComponent } from './components/create-branch/special-icons/special-icons.component';
import { PosContractComponent } from './components/create-branch/pos-contract/pos-contract.component';
import { SplashBannerComponent } from './components/create-branch/splash-banner/splash-banner.component';
import { BranchBillingComponent } from './components/create-branch/branch-billing/branch-billing.component';
import { BranchContractComponent } from './components/create-branch/branch-contract/branch-contract.component';
import { BranchUserTypesComponent } from './components/create-branch/branch-user-types/branch-user-types.component';
import {
  LaundryBranchAreaCoverageDialogComponent
} from '@app/modules/branch/components/create-branch/area-coverage-dialog/area-coverage-dialog.component';
import { SharedLogisticsModule } from '@app/modules/logistics/shared/shared-logistics.module';

@NgModule({
  imports: [CommonModule, SharedModule.forRoot(), BranchRoutingModule, SharedLogisticsModule],
  declarations: [
    BranchListComponent,
    CreateBranchComponent,
    BranchInfoComponent,
    BranchAreasComponent,
    BranchTimeslotsComponent,
    BranchSettingsComponent,
    BranchItemPriceComponent,
    CreateMinOrderPerAreaComponent,
    DiscountComponent,
    ListAuthDevicesComponent,
    DeviceAuthDetailComponent,
    BranchTatComponent,
    SpecialIconsComponent,
    PosContractComponent,
    SplashBannerComponent,
    BranchBillingComponent,
    BranchContractComponent,
    BranchUserTypesComponent,
    LaundryBranchAreaCoverageDialogComponent,
  ],
})
export class BranchModule {}
