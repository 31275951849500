import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { HcOrder, HcUserTransaction } from '@app/shared/models';

import { GlobalService } from '@shared/service/global.service';
import { OrderDetailsItemSize } from '../shared/enums/order-details-item-size.enum';

@Component({
  selector: 'app-homeclean-order-details-header',
  templateUrl: './homeclean-order-details-header.component.html',
  styleUrls: ['./homeclean-order-details-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomecleanOrderDetailsHeaderComponent implements OnInit{
  @Input() order: HcOrder;
  currency;

  constructor(private readonly globalService: GlobalService) {}

  readonly OrderDetailsItemSize = OrderDetailsItemSize;


  ngOnInit(): void {
    this.currency = this.globalService.currency;
  }

  getPaymentMethods(order: HcOrder): string {
    return order.userTransactions.reduce(
      (result: string, el: HcUserTransaction) =>
        `${result ? `${result}, ` : ''}${el.paymentMethod.name.en} (${
          el.amount
        })`,
      '',
    );
  }
}
