import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CarWashPackageListComponent } from './components/carwash-package-list/carwash-package-list.component';

const routes: Routes = [
  {
    path: 'packages/list',
    component: CarWashPackageListComponent,
    data: {title: 'Car wash company listing'}
  },
  {
    path: 'packages',
    redirectTo: 'packages/list',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CarWashPackageRoutingModule {}
