import { Component, OnInit, ViewChild } from '@angular/core';
import {
  PosContractData,
  PosCondition,
  PosCustomContractData,
  PosContractCreationBody
} from 'src/app/shared/models/laundries/pos';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/shared/service/global.service';
import { BranchesService } from 'src/app/shared/service/branches.service';
import { ToastService, IMyOptions, ModalDirective } from 'ng-uikit-pro-standard';
import { zip } from 'rxjs';
import { ApiLibrary } from 'src/app/shared/helpers/http-params';
import { PageInfo } from 'src/app/shared/models/global/response';
import { GroupService } from '@app/shared/service/group';
import { AppHeaders } from '@app/shared/models/user/generic-backend';

@Component({
  selector: 'app-pos-contract',
  templateUrl: './pos-contract.component.html',
  styleUrls: ['./pos-contract.component.css']
})
export class PosContractComponent implements OnInit {

  customContractsList: PosCustomContractData[];
  contractListByBranch: PosContractData[];
  pagination: PageInfo;
  contractLoaded: boolean;
  subscriptionCustomPackageId: number;
  // ------------------------------
  contractData: PosContractData;
  oldContractData: PosContractData;

  customContractData: PosCustomContractData;
  customContractDataStatic: PosCustomContractData;

  allSubscriptions: any;
  custom: boolean;
  customFlag: string;
  type: any;
  typeModal: number;
  conditions: PosCondition[];
  branchId: number;
  groupId: number;
  serviceCompanyId: number;
  isUpdate: boolean;
  branchIdKey: string;
  expiryDateOption: IMyOptions = {};
  expiryCustomDateOption: IMyOptions = {};
  expiryStartDateOption: IMyOptions = {};
  currency: string;
  loader = false; chan;
  newHeaders: AppHeaders = {};
  @ViewChild('createOfferModal', { static: true }) modal: ModalDirective;
  @ViewChild('customContracts', { static: true }) customContract: ModalDirective;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private branchService: BranchesService,
    private toast: ToastService,
    private groupService: GroupService

  ) { }

  ngOnInit() {
    this.custom = false;
    this.typeModal = null;
    this.customContractData = new PosCustomContractData();
    this.customContractDataStatic = new PosCustomContractData();
    this.contractLoaded = false;
    const today = new Date().getDate();
    this.expiryStartDateOption = {
      disableUntil: {
        day: today - 1,
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
      },
      closeAfterSelect: true,
    };
    this.branchId = this.route.snapshot.params.branchId;
    this.serviceCompanyId = this.route.parent.snapshot.params.id;
    this.groupId = Number(this.route.snapshot.queryParams.groupId) || 1;
    this.groupService.getGroupById(this.groupId).subscribe(res => {
      this.branchIdKey = this.groupService.getBranchIdKey(res);
      this.currency = this.globalService.currency;
      this.newHeaders = this.globalService.headers;
      this.newHeaders.group_id = this.groupId.toString();
      this.getSubscriptionList();
      this.getAllContracts();
      this.getContractData();      
    });
    
  }
  onHidden() {
    this.modal.hide();
  }
  changeStatus() {
    this.contractData.status =
      this.contractData.status === 'active' ? 'inactive' : 'active';
  }

  closeCustomContract() {
    console.log(this.customContractData);
    this.customContractData.startDate = null;
    this.customContractData.expiryDate = null;
    this.customContractData.amount = null;
    this.modal.hide();
  }

  saveCustomContract() {
    this.createCustomContract();
  }

  showCustomContracts() {
    this.customContract.show();

  }

  changeCustom() {
    this.customFlag =
    this.customFlag === 'active' ? 'inactive' : 'active';
    if (this.customFlag === 'active') {
      this.modal.show();
    }
  }
  AddNewCustom() {
    this.modal.show();
  }
  goBack() {
    this.router.navigate([
      '/dashboard/' +
        window.localStorage.getItem('module') +
        '/branch-list/' +
        this.serviceCompanyId
    ]);
  }

  showDate(date: string, isStart: boolean) {
    return date
      ? this.globalService.formatDate(date, 'DD-MM-YYYY')
      : isStart
      ? 'Select Start Date'
      : 'Select Expiry Date';
  }

  startDateChanged(event) {
    const date = this.globalService.formatDate(
      this.globalService.datePlusDays(event.actualDateFormatted, 0).toString(),
      'YYYY-MM-DD'
    );
    this.expiryDateOption = {
      disableUntil: {
        year: parseInt(date.split('-')[0], 10),
        month: parseInt(date.split('-')[1], 10),
        day: parseInt(date.split('-')[2], 10)
      }
    };
       const day = Number(this.globalService.formatDate(
        this.globalService.datePlusDays(event.actualDateFormatted, 0).toString(),
        'D'
      ));
      const month = Number(this.globalService.formatDate(
        this.globalService.datePlusDays(event.actualDateFormatted, 0).toString(),
        'M'
      ));

    let range = 29;

    if (day === 1) {
      if ( [1, 3, 5, 7, 8, 10, 12].includes(month)) {
        range = 30;
      } else if (month === 2) {
        range = 27;
      }
    }

    const endDate = this.globalService.formatDate(
      this.globalService.datePlusDays(event.actualDateFormatted, range).toString(),
      'YYYY-MM-DD'
    );
    const startDate = this.globalService.formatDate(
      this.globalService.datePlusDays(event.actualDateFormatted, 0).toString(),
      'YYYY-MM-DD'
    );
    this.contractData.startDate = startDate;

    this.contractData.expiryDate = endDate;

    if (this.contractData.startDate && this.contractData.expiryDate &&
      this.contractData.amount && this.contractData.subscriptionPackageId) {
      this.getCustomSubscriptions();
    }
  }

  startCustomDateChanged(event) {
    this.customContractData.expiryDate = null;
    const date = this.globalService.formatDate(
      this.globalService.datePlusDays(event.actualDateFormatted, 30).toString(),
      'YYYY-MM-DD'
    );
    this.expiryCustomDateOption = {
      disableUntil: {
        year: parseInt(date.split('-')[0], 10),
        month: parseInt(date.split('-')[1], 10),
        day: parseInt(date.split('-')[2], 10)
      }
    };
  }

  makeConditionActive(id: number) {
    this.contractData.laundryPosConditionId = id;
    this.conditions.forEach(e => {
      if (e.id === id) {
        e.status = 'active';
      } else {
        e.status = 'inactive';
      }
    });
  }

  subscriptionUpdate(id: number) {
   const value = this.allSubscriptions.find(obj => obj.id === id);
   this.contractData.amount = value.subscriptionPackageCountry[0].totalAmount;
   if (this.contractData.startDate && this.contractData.expiryDate &&
    this.contractData.amount && this.contractData.subscriptionPackageId) {
    this.getCustomSubscriptions();
  }
  }

  getContractAmount() {
    if (this.allSubscriptions) {
      const value = this.allSubscriptions.find(obj => obj.id === this.contractData.subscriptionPackageId);
      this.contractData.amount = value.subscriptionPackageCountry[0].totalAmount;
    }
  }

  getName(id: number) {
   return this.allSubscriptions.find(obj => obj.id === id).name;
  }
 getSubscriptionList() {
   this.branchService.getSubscriptionList(this.newHeaders,
    Number(window.localStorage.getItem('country'))).subscribe(
      response => {
        if (response.code === 200) {
          this.allSubscriptions = response.data;
          this.type = response.data.map(value => {
            return {
              value: value.id,
              label: value.name,
            };
          });
        } else {
          this.toast.error(response.message);
        }
      }
    );
 }

 createCustomContract() {
  const body: PosCustomContractData = {
    startDate: this.customContractData.startDate,
    subscriptionPackageId: this.contractData.subscriptionPackageId,
    expiryDate: this.customContractData.expiryDate,
    amount: Number(this.customContractData.amount),
      [this.branchIdKey]: Number(this.branchId)  };
  this.closeCustomContract();
  this.branchService
  .createCustomPackage(
    this.newHeaders,
    new ApiLibrary().cleanObjectFromComponent(body)
  ).subscribe(
    response => {
      if (response.code === 200) {
        console.log(response);
        this.subscriptionCustomPackageId = response.data.id;
        // --------- if there is no selected customPackageId --------------
        if (!this.contractData.subscriptionCustomPackageId) {
          this.contractData.subscriptionCustomPackageId = response.data.id;
        }
       // --------- if there is selected customPackageId --------------
        if (this.contractData.subscriptionCustomPackageId) {
          if (!this.customContractDataStatic?.startDate) {
            this.getCustomPackage(Number(this.contractData.subscriptionCustomPackageId ));
          }
          this.getCustomSubscriptions();
        }

        this.toast.success(response.message);
      } else {
        this.toast.error(response.message);
      }
    }
  );
 }

  saveContract() {
    this.loader = true;
    const isPost = this.oldContractData.startDate !== this.contractData.startDate
    || this.oldContractData.subscriptionCustomPackageId !== this.contractData.subscriptionCustomPackageId ||
     this.oldContractData.expiryDate !== this.contractData.expiryDate ;
    const body: PosContractCreationBody = {
      amount: Number(this.contractData.amount),
      expiryDate: this.globalService.getEndOfDayDateTime(this.contractData.expiryDate),
      startDate: this.contractData.startDate,
      status: this.contractData.status,
      countryId: Number(window.localStorage.getItem('country')),
      groupId: this.groupId,
      userAdminId: Number(window.localStorage.getItem('userId')),
      laundryPosConditionId: this.contractData.laundryPosConditionId,
      [this.branchIdKey]: Number(this.branchId),
      subscriptionPackageId: this.contractData.subscriptionPackageId,
      comment: this.contractData.comment,
      type: this.contractData.type,
      subscriptionCustomPackageId: this.contractData.subscriptionCustomPackageId ?
       this.contractData.subscriptionCustomPackageId : this.subscriptionCustomPackageId,
      id: this.isUpdate && isPost ? null : this.contractData.id
    };

    this.branchService
      .savePosContractData(
        this.newHeaders, isPost,
        new ApiLibrary().cleanObjectFromComponent(body)
      )
      .subscribe(
        response => {
          if (response.code === 200) {
            this.toast.success(response.message);
          } else {
            this.toast.error(response.message);
          }
        },
        error => {
        },
        () => {
          this.loader = false;
          this.getAllContracts();
          this.getContractData();
        }
      );
  }

  validate() {
    return (
      this.contractData.startDate &&
      this.contractData.expiryDate &&
      this.contractData.amount &&
      this.contractData.laundryPosConditionId &&
      this.contractData.comment
    );
  }

  showCaptionForTextField() {
    return this.contractData && this.contractData.type === 'amount'
      ? 'Monthly Fee ' + this.currency
      : 'Percentage';
  }

  isChecked(type: string) {
    return type === this.contractData.type;
  }

  private getContractData() {
    zip(
      this.branchService.getPosConditions(this.newHeaders),
      this.branchService.getPosActiveContractData(
        this.newHeaders,
        this.branchId,
        this.branchIdKey,
        Number(window.localStorage.getItem('country')))
    ).subscribe(
      response => {
        const conditions = response[0];
        const contract = response[1];
        if (conditions.code === 200 && contract.code === 200) {
          this.contractData = contract.data.length > 0
            ? contract.data[0]
            : new PosContractData();
            this.contractData.type = (this.contractData.type) ? this.contractData.type : 'amount';
          this.conditions = conditions.data;
          this.makeConditionActive(this.contractData.laundryPosConditionId);
          this.isUpdate = this.contractData.id ? true : false;
          if (this.contractData.subscriptionCustomPackageId) {
              this.getCustomPackage(Number(this.contractData.subscriptionCustomPackageId ));
          }
          if (this.isUpdate) {
            this.contractData.expiryDate = this.globalService.getDateAvoidingTimeZone(this.contractData.expiryDate, 'YYYY-MM-DD');
            const dateStart = this.globalService.formatDate(
              this.globalService.datePlusDays(this.contractData.expiryDate, 0).toString(),
              'YYYY-MM-DD'
            );
            this.expiryStartDateOption = {
              disableUntil: {
                year: parseInt(dateStart.split('-')[0], 10),
                month: parseInt(dateStart.split('-')[1], 10),
                day: parseInt(dateStart.split('-')[2], 10)
              }
            };
            const date = this.globalService.formatDate(
              this.globalService
                .datePlusDays(this.contractData.startDate, 1)
                .toString(),
              'YYYY-MM-DD'
            );
            this.expiryDateOption = {
              disableUntil: {
                year: parseInt(date.split('-')[0], 10),
                month: parseInt(date.split('-')[1], 10),
                day: parseInt(date.split('-')[2], 10)
              }
            };
          }
          this.oldContractData = {...this.contractData};

        }
        if (conditions.code !== 200) {
          this.toast.error(conditions.message);
        }
        if (contract.code !== 200) {
          this.toast.error(contract.message);
        }
        if (contract.data.length > 0 && this.contractData.subscriptionCustomPackageId !== null) {
          this.getCustomSubscriptions();
        }
      }
    );
  }
getCustomPackage(packageId: number) {
  this.branchService.getCustomPackage(this.newHeaders,
    packageId).subscribe(
      response => {
        if (response.code === 200) {
            this.customContractDataStatic = response.data;
        } else {
          this.toast.error(response.message);
        }
      }
    );
}
  // --------------------------------------------

  getAllContracts() {
    this.branchService.getPosContractData(this.newHeaders, Number(this.branchId), this.branchIdKey)
      .subscribe(
        response => {
          if (response.code === 200) {
            const data = response.data;
            this.contractListByBranch = data;
            if (this.contractListByBranch?.length) {
              /** Fromat all expiry dates to avoid displaying next day date issue by avoiding the timezone */
              this.contractListByBranch.forEach(contract => {
                contract.expiryDate = this.globalService.getDateAvoidingTimeZone(contract.expiryDate, 'DD-MM-YYYY');
              });
            }
            this.contractLoaded = true;
          }
        },
        error => {
          this.loader = false;
          // this.toaster.error(error.error.message);
        }
      );
  }
  getCustomSubscriptions(params?: any) {
    this.branchService.getCustomSubscriptionList(this.newHeaders,
      Number(window.localStorage.getItem('country')), Number(this.branchId), this.contractData.subscriptionPackageId
      , this.contractData.expiryDate, this.contractData.startDate, this.branchIdKey)
      .subscribe(
        response => {
          if (response.code === 200) {
            const data = response.data[0];
            this.customContractsList = data.subscriptionCustomPackages;
            this.customContractDataStatic = this.customContractsList[this.customContractsList.length - 1];
            this.contractData.amount = this.customContractsList[this.customContractsList.length - 1] ?
            this.customContractsList[this.customContractsList.length - 1].amount :
            this.contractData.amount;
            this.contractData.subscriptionCustomPackageId = this.customContractsList[this.customContractsList.length - 1] ?
            this.customContractsList[this.customContractsList.length - 1].id :
            this.contractData.subscriptionCustomPackageId;
            }
        },
        error => {
          this.loader = false;
          // this.toaster.error(error.error.message);
        },
        () => {
          if (!this.customContractsList || this.customContractsList.length < 1) {
            this.contractData.subscriptionCustomPackageId = null;
            this.getContractAmount();
          }
        }
      );
  }


  getPage(page) {
    this.getCustomSubscriptions({ page: page });
  }
}
