import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { GroupsRoutingModule } from './groups-routing.module';
import { GroupListComponent } from './components/group-list/group-list.component';
import { GroupEditDialogComponent } from './components/group-edit-dialog/group-edit-dialog.component';
import { GroupFormComponent } from './components/group-edit-dialog/group-form/group-form.component';
import { AssignCountryDialogComponent } from './components/assign-country-dialog/assign-country-dialog.component';
import { GroupPositioningComponent } from "@app/modules/groups/components/group-positioning/group-positioning.component";
import { CdkDrag, CdkDragHandle, CdkDropList } from "@angular/cdk/drag-drop";

@NgModule({
  declarations: [
    GroupListComponent,
    GroupEditDialogComponent,
    GroupFormComponent,
    AssignCountryDialogComponent,
    GroupPositioningComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    GroupsRoutingModule,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
  ],
})
export class GroupsModule {}
