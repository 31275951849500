import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BackEndResponse } from 'src/app/shared/models/global/response';
import { tap } from 'rxjs/operators';
import { FeedBackBody } from '../models/segments';

@Injectable({
  providedIn: 'root'
})
export class MarketingService {
  readonly URL_USERS = environment.serverUrl + 'marketing';
  readonly URL_QUESTIONS = environment.serverUrl + 'marketing/questions';
  readonly URL_SUBMIT_FEEDBACK = environment.serverUrl + 'marketing';
  constructor(private http: HttpClient) {}

  getUsersPerSegment(headers, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_USERS, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(tap((data) => console.log(data)));
  }

  getQuestions(headers, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_QUESTIONS, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(tap((data) => console.log(data)));
  }

  saveFeedback(headers, body: FeedBackBody) {
    return this.http
      .post<BackEndResponse>(this.URL_SUBMIT_FEEDBACK, body, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }
}
