import { Component, OnInit } from '@angular/core';
import {
  CouponDetails,
  CouponCustomerType,
  CouponDiscountType
} from 'src/app/shared/models/offers/offers';
import { GlobalService } from 'src/app/shared/service/global.service';
import { CouponsService } from 'src/app/shared/service/coupons.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-coupon-details',
  templateUrl: './coupon-details.component.html',
  styleUrls: ['./coupon-details.component.css']
})
export class CouponDetailsComponent implements OnInit {
  coupon: CouponDetails;
  couponId: number;
  dataReady = false;
  constructor(
    protected globalService: GlobalService,
    private couponService: CouponsService,
    private toast: ToastService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.couponId = this.route.snapshot.queryParams.id;
    this.getDetails();
  }

  pretyTextForTypeCustomer(type: string) {
    switch (type) {
      case CouponCustomerType.all:
        return 'All';
      case CouponCustomerType.firstTime:
        return 'First Order';
      case CouponCustomerType.reorder:
        return 'Reorder';
    }
  }

  showAmount() {
    if (this.coupon.discount_type) {
      return this.coupon.discount_type === CouponDiscountType.percentage
        ? this.coupon.discount_percentage + ' %'
        : this.coupon.discount_amount + ' ' + this.globalService.getCurrency();
    } else {
      return this.coupon.amount + ' ' + this.globalService.getCurrency();
    }
  }

  showPrettyDate(date: string) {
    return this.globalService.getDateOrderFormat(date);
  }

  showLaundries() {
    if (this.coupon.laundries) {
      return this.coupon.laundries.toString();
    }
    if (this.coupon.laundry) {
      return this.coupon.laundry.name;
    }
  }

  showMessage() {
    if (this.coupon.voucher_message) {
      return (
        '<h4 class="green-blue-color"> EN: ' +
        this.coupon.voucher_message.en.message +
        '</h4><br>' +
        '<h4 class="green-blue-color"> AR: ' +
        this.coupon.voucher_message.ar.message +
        '</h4><br>'
      );
    }
  }
  getLaunderies() {
    if (this.coupon.laundries) {
      let html = '';
      if (Array.isArray(this.coupon.laundries)) {
        this.coupon.laundries.forEach((e, i) => {
          html +=
            '<h4 class="green-blue-color">' + (i + 1) + '- ' + e + '</h4><br>';
        });
      }

      return Array.isArray(this.coupon.laundries) &&
        this.coupon.laundries.length > 0
        ? html
        : this.coupon.laundries === 0
        ? '<h4 class="error-text"> All Laundries</h4>'
        : '<h4 class="error-text"> No Laundry</h4>';
    } else {
      if (this.coupon.laundry) {
        return (
          '<h4 class="green-blue-color">' +
          this.coupon.laundry.name +
          '</h4><br>'
        );
      } else {
        return '<h4 class="error-text"> No Laundry</h4>';
      }
    }
  }

  private getDetails() {
    this.couponService
      .getCouponDetails(this.globalService.headers, this.couponId)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.coupon = {
              ...response.data,
              laundries: response.data?.laundries?.map(
                (el) => el?.laundry?.name || '',
              ),
            };
            this.dataReady = true;
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }
}
