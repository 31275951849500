import { takeUntil, finalize } from 'rxjs/operators';
import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { DIALOG_DATA } from '@app/shared/components/dialog/services/dialog.token';
import { Subject } from 'rxjs';
import { DialogRef } from '@app/shared/components/dialog/dialog-ref/dialog-ref';
import { HcOrderJob, DateSlot } from '@app/shared/models';
import { HomeCleaningOrdersService } from '@app/shared/service/home-cleaning/home-cleaning-orders.service';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { GlobalService } from '@app/shared/service/global.service';
import { HcServiceOpeningHoursService } from '@app/shared/service/home-cleaning/hc-service-opening-hours.service';

@Component({
  selector: 'app-reschedule-dialog',
  templateUrl: './reschedule-dialog.component.html',
  styleUrls: ['./reschedule-dialog.component.scss'],
})
export class RescheduleDialogComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>() ;

  isLoading: boolean;
  selectedTimeId: number;
  enableDateOption: IMyOptions;
  dateSlot: DateSlot[];
  selectedDateSlot: DateSlot;

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: { job: HcOrderJob; callbackFn: Function } | any,
    private readonly homeCleaningOrdersService: HomeCleaningOrdersService,
    private readonly hcServiceOpeningHoursService: HcServiceOpeningHoursService,
    private readonly globalService: GlobalService,
    private readonly dialogRef: DialogRef<RescheduleDialogComponent>,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadServiceTimeSlot();
  }

  close(isRefresh?: boolean): void {
    this.dialogRef.close();
    if (isRefresh) {
      this.data.callbackFn();
    }
  }

  reschedule() {
    this.isLoading = true;
    this.homeCleaningOrdersService
      .rescheduleJob({
        id: this.data.job.id,
        startDateId: this.selectedDateSlot.id,
        startTimeId: this.selectedTimeId,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.cd.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.close(true));
  }

  changeDate(jsdate: Date): void {
    const format = 'YYYY-MM-DD';
    this.selectedDateSlot = this.dateSlot.find(
      (dateObj) =>
        this.globalService.formatDate(dateObj.date, format) ===
        this.globalService.formatDate(jsdate.toString(), format),
    );
    this.selectedTimeId = null;
  }

  private loadServiceTimeSlot(): void {
    const hcServiceId = this.data?.job?.packageHoursId;
    const groupId = this.data?.job?.hcOrder?.groupId;
    if (hcServiceId) {
      this.hcServiceOpeningHoursService
        .getActiveOpeningHours(hcServiceId, groupId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((dateSlot: DateSlot[]) => {
          this.dateSlot = dateSlot;
          this.setDateOptions();
          this.cd.detectChanges();
        });
    }
  }

  private setDateOptions(): void {
    const firstDay = new Date(this.dateSlot[0].date);
    /** last day should be start date + 15 days  */
    const lastDay = new Date(this.dateSlot[this.dateSlot.length - 1].date);

    this.enableDateOption = {
      disableUntil: {
        year: firstDay.getFullYear(),
        month: firstDay.getMonth() + 1,
        day: firstDay.getDate() - 1,
      },
      disableSince: {
        year: lastDay.getFullYear(),
        month: lastDay.getMonth() + 1,
        day: lastDay.getDate(),
      },
      disableDays: this.dateSlot
        .filter(({ timeSlotTimes }) => !timeSlotTimes?.length)
        .map((dateObj) => {
          const date = new Date(dateObj.date);
          return {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate(),
          };
        }),
    };
  }

  private unsubscribe(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
