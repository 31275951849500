import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class CanActivateViaAuthGuard  {
  loggedIn: boolean;

  constructor(private global: GlobalService, private router: Router) {}

  canActivate() {
    let url = window.location.href;
    const index = url.indexOf('/dashboard');
    url = url.substring(index + 1);
    if (!url.includes('login')) {
      window.localStorage.setItem('url', url);
    }
    if (window.localStorage.getItem('loggedIn') === 'true') {
      this.global.setHeaders();
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
