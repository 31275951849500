import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { combineLatest, Subject, Subscription } from 'rxjs';

import { FormStatus } from '@shared/enums/form-status.enum';
import { CarwashBranch, OpenHours, PaymentMethod } from '@shared/models';
import { CarwashBranchReservationOptions } from '@app/modules/carwash/modules/carwash-branch/shared/enums';
import { CarwashTimeRangeComponent } from '@app/modules/carwash/modules/carwash-branch/shared/components/carwash-time-range/carwash-time-range.component';
import { PaymentHelper } from '@shared/helpers/payment';
import { CountryService } from '@app/shared/service/country.service';
import {
  AreaList,
  GovernorateList,
} from '@app/shared/models/countries/country';
import { SelectOptions } from '@app/shared/models/global/response';

@Component({
  selector: 'app-carwash-branch-form',
  templateUrl: './carwash-branch-form.component.html',
  styleUrls: ['./carwash-branch-form.component.scss'],
})
export class CarwashBranchFormComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  userPaymentMethods: PaymentMethod[] = [];
  posPaymentMethods: PaymentMethod[] = [];
  selecetedPaymentMethods: PaymentMethod[] = [];
  formBranch: UntypedFormGroup;

  @Input() branch: CarwashBranch;
  @Output() formChanged: EventEmitter<Partial<CarwashBranch>> =
    new EventEmitter<Partial<any>>();
  @Output() formStatusChanged: EventEmitter<FormStatus> =
    new EventEmitter<FormStatus>();
  @ViewChild(CarwashTimeRangeComponent, { static: true })
  carwashTimeRangeComponent: CarwashTimeRangeComponent;

  language: string;
  private readonly destroy$: Subject<void> = new Subject<void>() ;
  OriginalAreas: Array<AreaList>;
  areas: Array<SelectOptions<number>> = [];
  governorates: SelectOptions<number>[];
  selectedGovernorate: number;
  isPosPaymentMethods: boolean = false;
  subscribers$: Array<Subscription> = [];

  constructor(
    private readonly countryService: CountryService,
    private readonly fb: UntypedFormBuilder,
    private readonly paymentHelper: PaymentHelper,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.language = localStorage.getItem('language') || 'en';
    this.initForm();
    this.checkPosStatus();
    this.setPaymentMethods();
    this.loadGovernorates();
  }

  ngAfterViewInit(): void {
    this.handleFormChanges();
    this.handleFormStatusChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.unsubscriber();
  }

  getPaymentLabel(payment) {
    const getSubString = () =>
      payment.isPosPaymentMethod ? '[Pos]' : '[User]';
    return `${payment.name.en} (${payment.paymentGateway}) ${
      payment.isPosPaymentMethod || payment.isUserPaymentMethod
        ? getSubString()
        : ''
    }`;
  }

  checkPosStatus() {
    if (this.branch) this.isPosPaymentMethods = this.branch.hasPosStatus;
  }

  setPaymentMethods() {
    this.checkPosStatus();
    if (this.isPosPaymentMethods) {
      this.setPaymentMethod(true);
    }
    this.setPaymentMethod(false);
  }

  setPaymentMethod(isPos?: boolean) {
    const listKeyName = isPos ? 'posPaymentMethods' : 'userPaymentMethods';
    const subscriber$ = this.paymentHelper
      .getPaymentMethods({ countryId: this.countryId }, isPos)
      .subscribe((response) => {
        this.setPaymentMethodList(response, listKeyName, isPos);
        this.setSelectedPaymentMethods(listKeyName);
        this.patchFormPaymentMethods();
      });
    this.subscribers$.push(subscriber$);
  }

  private setSelectedPaymentMethods(listKeyName: string): void {
    const isPaymentChecked = (paymentMethod) => paymentMethod.checked;
    this.selecetedPaymentMethods.push(
      ...this[listKeyName].filter(isPaymentChecked),
    );
  }

  private setPaymentMethodList(
    response: any,
    listKeyName: string,
    isPos?: boolean,
  ): void {
    const flagKeyName = isPos ? 'isPosPaymentMethod' : 'isUserPaymentMethod';

    this[listKeyName] = response.data
      .filter((item) => item[flagKeyName] && item.status === 'active')
      .map((paymentMethod) => {
        return {
          ...paymentMethod,
          checked:
            this.formBranch.value.paymentMethods
              ?.map(({ id }) => id)
              .includes(paymentMethod.id) || false,
        };
      });
  }

  private patchFormPaymentMethods(): void {
    this.formBranch
      .get('paymentMethods')
      .patchValue(this.selecetedPaymentMethods);
    this.cdr.detectChanges();
  }

  private get allPaymentMethods(): PaymentMethod[] {
    return this.posPaymentMethods?.concat(this.userPaymentMethods);
  }

  private get countryId(): number {
    return +localStorage.getItem('country');
  }

  changeGov(event) {
    this.showAreas(event);
    this.formBranch.controls['areaId'].reset();
  }

  loadGovernorates() {
    this.subscribers$.push(
      this.countryService.getListCities({}).subscribe((response) => {
        if (response.code === 200) {
          this.governorates = (response.data as GovernorateList[]).map(
            (gov) => ({ value: gov.id, label: gov.name?.en || '' }),
          );
          this.loadAreas();
        }
      }),
    );
  }

  private showAreas(govId: number) {
    this.areas = this.OriginalAreas.filter(
      (area) => area.governorate_id === govId,
    ).map((area) => ({ value: area.id, label: area.name?.en || '' }));
  }

  private loadAreas(): void {
    this.subscribers$.push(
      this.countryService
        .getListAreas({}, { page: 0 })
        .subscribe((response) => {
          this.OriginalAreas = response.data;
          if (this.branch?.areaId) {
            const selectedArea = this.OriginalAreas.find(
              (area) => area.id === this.branch.areaId,
            );
            this.changeGov(selectedArea.governorate_id);
            this.selectedGovernorate = selectedArea.governorate_id;
            this.formBranch.controls['areaId'].setValue(selectedArea.id);
          }
        }),
    );
  }

  changeReservationOption(reservation: CarwashBranchReservationOptions): void {
    this.formBranch.patchValue({ reservation });
  }

  onChangePayment(payment: PaymentMethod, event) {
    const isPaymentChecked = (paymentMethod) => paymentMethod.checked;
    payment.checked = event.checked;
    this.formBranch
      .get('paymentMethods')
      .patchValue(this.allPaymentMethods.filter(isPaymentChecked));
  }

  openHoursChange(openHours: OpenHours[]): void {
    this.formBranch.get('openingHours').setValue(openHours);
  }

  private initForm(): void {
    this.formBranch = this.fb.group({
      companyName: this.fb.group({
        en: ['', [Validators.required]],
        ar: ['', [Validators.required]],
      }),
      operatingName: this.fb.group({
        en: [''],
        ar: [''],
      }),
      branchAddress: this.fb.group({
        en: ['', [Validators.required]],
        ar: ['', [Validators.required]],
      }),
      managerName: [''],
      managerEmail: ['', Validators.email],
      managerMobile: [''],
      contactName: [''],
      contactEmail: ['', Validators.email],
      contactMobile: [''],
      latitude: ['', [Validators.required]],
      longitude: ['', [Validators.required]],
      highestPrice: [''],
      lowestPrice: [''],
      gmapsLink: [''],
      openTime: [null, [Validators.required]],
      closeTime: [null, [Validators.required]],
      trnNumber: [''],
      tax: [''],
      areaId: ['', Validators.required],
      paymentMethods: [null, [Validators.required]],
      reservation: [CarwashBranchReservationOptions.both],
      openingHours: null,
    });

    this.patchForm();
  }

  setDefaultPaymentMethods() {
    return this.branch.paymentMethods;
  }
  private handleFormChanges(): void {
    const emitValue = (value: Partial<any>) => this.formChanged.emit(value);

    this.formBranch.valueChanges
      .pipe(debounceTime(500), tap(emitValue), takeUntil(this.destroy$))
      .subscribe();
  }

  private handleFormStatusChanges(): void {
    combineLatest([
      this.formBranch.statusChanges.pipe(startWith(this.formBranch.status)),
      this.carwashTimeRangeComponent.form.statusChanges.pipe(
        startWith(this.carwashTimeRangeComponent.form.status),
      ),
    ])
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((statuses: [FormStatus, FormStatus]) => {
        const isStatusValid = (status: FormStatus): boolean =>
          status === FormStatus.Valid;
        this.formStatusChanged.emit(
          statuses.every(isStatusValid) ? FormStatus.Valid : FormStatus.Invalid,
        );
      });
  }

  private patchForm(): void {
    if (!this.branch) {
      return;
    }

    this.formBranch.patchValue(this.branch);
    this.formChanged.emit(this.formBranch.value);
  }

  private unsubscriber(): void {
    this.subscribers$.forEach((subscriber$) => subscriber$.unsubscribe());
  }
}
