import { Translation } from 'libs/justclean-models/src/lib/justclean-models';
import { Status } from '@app/shared/enums/status.enum';

export interface Area {
    name: Translation;
    id: number;
    status: Status;
    latitude: number;
    longitude: number;
    cityId: number;
    countryId: number;
}