import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  Query,
} from '@angular/fire/compat/firestore';
import { DriversTrackingFromFirebase } from '../models/orders/order-map';
// import { firestore } from 'firebase/compat';
@Injectable({
  providedIn: 'root',
})
export class FirebaseDriverTrackingService {
  constructor(private afs: AngularFirestore) {}
  getDrivers() {
    /* const NORTH = new firestore.GeoPoint(31.1927526, 33.9370733);
    const SOUTH = new firestore.GeoPoint(15.5881062, 55.1026423); */
    return this.afs.collection('logistics_tracking', (ref) => {
      let query: CollectionReference | Query = ref;
      query = query
        .where('deleted', '==', false)
        .where('partner', '==', true);
        /* .where('geoPoint', '<=', NORTH)
        .where('geoPoint', '>=', SOUTH); */
      return query;
    }) as AngularFirestoreCollection<DriversTrackingFromFirebase>;
  }
}
