import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AdvertisementToBeSaved, AdsDeleteBody } from '../models/laundries/branch-ads';
import { BackEndResponse } from '../models/global/response';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdsService {
  readonly URL_ADS = environment.serverUrl + 'advertisement/advertisement';
  readonly URL_ADS_STOP = environment.serverUrl + 'advertisement/advertisement-stop';
  readonly URL_ADS_REASONS = environment.serverUrl + 'advertisement/reasons';
  constructor(private http: HttpClient) {}

  createBranchAds(headers, body: AdvertisementToBeSaved) {
    return this.http
      .post<BackEndResponse>(this.URL_ADS, body, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  stopBranchAds(headers, body: AdsDeleteBody) {
    return this.http
      .post<BackEndResponse>(this.URL_ADS_STOP, body, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  getBranchAds(headers, branchId: number) {
    return this.http
      .get<BackEndResponse>(this.URL_ADS + '/' + branchId, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  getStopBranchAdsReasons(headers, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_ADS_REASONS , {
        headers: new HttpHeaders(headers),
        params: new HttpParams({fromObject: params})
      })
      .pipe(tap(data => console.log(data)));
  }

}
