import { Component, OnInit, ViewChild } from '@angular/core';
import { CategoriesService } from '../../../../shared/service/categories.service';
import { GlobalService } from '../../../../shared/service/global.service';
import {
  Category,
  CategoryList,
  CategoryPositionBody,
} from '../../../../shared/models/items/cats';
import {
  BackEndResponse,
  PageInfo,
} from '../../../../shared/models/global/response';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { GroupService } from "@shared/service/group";
import { map } from "rxjs/operators";
import { Group } from "@shared/models";
import { Observable } from "rxjs";
import { AppHeaders } from "@shared/models/user/generic-backend";

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
})
export class CategoryListComponent implements OnInit {
  isShown: boolean;
  categories: CategoryList[];
  filteredCategories: CategoryList[];
  response: BackEndResponse;
  pagination: PageInfo;
  catToBeUpdated: CategoryList;
  catTobeDeleted: Category;
  toggleDeleteModal: boolean;
  loader: boolean;
  @ViewChild('updatePosition', { static: true })
  modal: ModalDirective;
  selectedCat: CategoryList;
  changePositionBody: CategoryPositionBody;
  catCount: number;
  selectedGroup: Group;
  groups$: Observable<Array<Group>>;
  headers: AppHeaders = {};
  constructor(
    private catService: CategoriesService,
    private globalService: GlobalService,
    protected toast: ToastService,
    private groupsService: GroupService,
  ) {}

  ngOnInit() {
    this.headers = this.globalService.headers
    this.toggleDeleteModal = false;
    this.loader = false;
    this.isShown = false;
    this.initData();
    this.getCats(1);
    this.getCount();

    this.loadLaundryGroups();
  }

  getCats(page) {
    this.catService
      .getCategories(this.headers, page)
      .subscribe((response) => {
        this.response = response;
        this.pagination = response.page_info;
        console.log(response);

        this.categories = this.response.data;
        this.filteredCategories = this.categories;
      });
  }

  refreshData(event) {
    console.log(event);

    if (event) {
      setTimeout(() => {
        this.getCats(this.pagination.current_page);
      }, 1000);
    }
  }

  changeStatus(id: number, status: string) {
    this.catService
      .changeStatus(this.headers, id, status)
      .subscribe((response) => {
        this.getCats(this.pagination.current_page);
      });
  }

  selectCatToDelete(cat: Category) {
    this.catTobeDeleted = cat;
    this.toggleDeleteModal = true;
  }

  deleteCat($event) {
    this.loader = true;
    this.catService
      .deleteCategory(this.headers, this.catTobeDeleted.id)
      .subscribe((response) => {
        if (response.code === 200) {
          this.loader = false;
          this.toggleDeleteModal = false;
          this.getCats(this.pagination.current_page);
        }
      });
  }

  isClosed(event) {
    this.catToBeUpdated = null;
    this.isShown = !event;
  }

  getPage(page) {
    this.getCats(page);
  }

  openUpdatePositionModal(cat: CategoryList) {
    this.selectedCat = cat;
    this.changePositionBody.from = cat.sort_order;
    this.changePositionBody.category_id = cat.id;
    this.changePositionBody.to = cat.sort_order;
    this.getCount();
    this.modal.show();
  }

  changePosition() {
    this.loader = true;
    this.catService
      .changePosition(this.headers, this.changePositionBody)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.getCats(this.pagination.current_page);
            this.modal.hide();
            this.initData();
            this.toast.success(response.message);
          } else {
            this.toast.error(response.message);
          }
          this.loader = false;
        },
        (error) => {
          this.getCats(this.pagination.current_page);
          this.loader = false;
        }
      );
  }

  openUpdate(cat: CategoryList) {
    this.isShown = true;
    this.catToBeUpdated = cat;
  }

  initData() {
    this.changePositionBody = {
      from: null,
      to: null,
      category_id: null,
    };
    this.selectedCat = null;
  }

  getCount() {
    this.catService
      .getCategories(this.headers, 0)
      .subscribe((response) => {
        if (response.code === 200) {
          this.catCount = response.data.length;
          console.log(this.catCount);
        }
      });
  }

  loadLaundryGroups(): void {
    this.groups$ = this.groupsService.getGroups().pipe(
      map((groups) => {
        if (this.selectedGroup) {
          /** This to bind with groups dropdown */
          this.selectedGroup = groups.find(
            ({ id }) => id === this.selectedGroup.id,
          );
        } else {
          // assign the first group by default
          this.selectedGroup = groups.find(() => true);
        }
        return groups.filter((group) =>
          this.groupsService.checkLaundryDesign(group),
        );
      }),
    );
  }

  setGroup(): void {
    this.headers.group_id = this.selectedGroup.id.toString();
    this.initData();
    this.getCats(0);
  }
}
