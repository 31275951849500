// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.referral-cashout__content {
  min-width: 30rem;
}
.referral-cashout__btn-copy {
  width: 6rem;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/referral-cashout-requests/components/referral-cashout-requests-edit-dialog/referral-cashout-requests-dialog.component.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;AAAR;AAEI;EACI,WAAA;AAAR","sourcesContent":[".referral-cashout {\n    &__content {\n        min-width: 30rem;\n    }\n    &__btn-copy {\n        width: 6rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
