import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { FormStatus } from '@shared/enums/form-status.enum';
import { CarwashCompany, Variant } from "@shared/models";
import { CarWashPackage } from "@shared/models/carwash/car-wash-package.model";
import { CarWashPackageService } from "@shared/service/car-wash-packages";

@Component({
  templateUrl: './car-wash-package-pricing-edit-dialog.component.html',
})
export class CarWashPackagePricingEditDialogComponent implements OnInit {
  isEditMode: boolean;
  isDisableButton: boolean;
  formData: Partial<CarWashPackage>;
  carWashPackageImageEn: string;
  carWashPackageImageAr: string;
  isLoading: boolean;

  private readonly destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: { carWashPackage: CarWashPackage | null; groupId: number; cwGarageBranchId: number; variants$: Observable<Array<Variant>>; } | any,
    private readonly dialogRef: DialogRef<CarWashPackagePricingEditDialogComponent>,
    private readonly carWashPackageService: CarWashPackageService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.isEditMode = !!this.data.carWashPackage;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(): void {
    this.dialogRef.close();
  }

  formChanged(carWashPackage: Partial<CarWashPackage>): void {
    this.formData = {
      ...carWashPackage,
    };
  }

  formStatusChanged(status: FormStatus): void {
    this.isDisableButton = status === FormStatus.Invalid;
  }

  createPackage(): void {
    const data: Partial<CarwashCompany> = {
      ...this.formData,
      ...(this.carWashPackageImageEn && {
        image: {
          en: this.carWashPackageImageEn,
          ar: this.carWashPackageImageAr,
        },
      }),
      ...(this.data.groupId ? { groupId: this.data.groupId } : {}),
    };
    const action$ = this.isEditMode
      ? this.carWashPackageService.updatePackage(this.data.carWashPackage.id, data)
      : this.carWashPackageService.createPackage(data);

    this.isLoading = true;
    action$
      .pipe(
        tap(() => {
          this.isLoading = false;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (carWashPackage: CarWashPackage) => {
          this.dialogRef.close(carWashPackage);
        },
        (error) => {
          this.isLoading = false;
          this.cdr.markForCheck();
        },
      );
  }


}
