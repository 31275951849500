export interface HcServiceArea {
  areaId?: number;
  hcServiceId?: number;
  id?: number;
  serviceCharge?: number;
  serviceChargeThreshold?: number;
  serviceChargeAfterThreshold?: number;
  deliveryCharge?: number;
  deliveryChargeThreshold?: number;
  deliveryChargeAfterThreshold?: number;
}
