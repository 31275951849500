import { GroupService } from '@app/shared/service/group';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { FormStatus } from '@shared/enums/form-status.enum';
import { ReferralCampaign, UserSegment } from '@shared/models';
import { GlobalService } from '@app/shared/service/global.service';
import { Status } from '@app/shared/enums/status.enum';
import { SelectOptions } from '@app/shared/models/global/response';
import { ReferralCampaignService } from '@app/shared/service/referral-campaign';
import { UserSegmentService } from '@app/shared/service/user-segment';
import { IMyOptions } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-referral-campaign-form',
  templateUrl: './referral-campaign-form.component.html',
  styleUrls: ['./referral-campaign-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferralCampaignFormComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  groups$: Observable<SelectOptions<number>[]>;
  segments$: Observable<UserSegment[]>;
  currency: string;
  STATUS = Status;
  selectedRecievableGroups: SelectOptions<number>[];
  selectedDeductibleGroups: SelectOptions<number>[];
  isLoading: boolean;
  startDateOptions: IMyOptions;
  endDateOptions: IMyOptions;

  @Input() referralCampaign: ReferralCampaign;
  @Output() formChanged: EventEmitter<Partial<ReferralCampaign>> =
    new EventEmitter<Partial<ReferralCampaign>>();
  @Output() formStatusChanged: EventEmitter<FormStatus> =
    new EventEmitter<FormStatus>();

  private readonly destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly referralCampaignService: ReferralCampaignService,
    private readonly userSegmentService: UserSegmentService,
    private readonly globalService: GlobalService,
    private readonly groupService: GroupService,
  ) {}

  ngOnInit(): void {
    this.currency = this.globalService.currency;
    this.initForm();
    this.handleFormChanges();
    this.handleFormStatusChanges();
    this.loadGroups();
    this.loadAllSegments();
    this.setDateOptions();
  }

  selectGroup(selectedGroupIDs: number[], formControlName: string): void {
    this.form
      .get(formControlName)
      .setValue(selectedGroupIDs.map((groupId) => ({ groupId })));
    this.checkFormStatus();
  }

  getGroupName(groupId: number, groups: SelectOptions<number>[]): string {
    return groups.find((group) => group.value === groupId)?.label;
  }

  private initForm(): void {
    this.form = this.fb.group({
      name: this.fb.group({
        en: ['', [Validators.required]],
        ar: ['', []],
      }),
      status: [Status.Active],
      senderAmount: [0, Validators.required],
      senderAmountType: ['percentage'],
      senderPercentage: [1],
      receiverAmount: [0],
      referralReceivableGroups: [[], Validators.required],
      referralDeductibleGroups: [[], Validators.required],
      senderValidityInMinutes: ['', [Validators.required]],
      receiverValidityInMinutes: ['', [Validators.required]],
      senderUserSegmentId: ['', [Validators.required]],
      receiverUserSegmentId: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
    });
    this.patchForm();
  }

  private patchForm(): void {
    if (!this.referralCampaign) {
      return;
    }

    this.isLoading = true;
    this.referralCampaignService
      .getReferralCampaign(this.referralCampaign.id)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe((campaignDetails: ReferralCampaign) => {
        this.referralCampaign = campaignDetails;
        this.form.patchValue(this.referralCampaign);
        this.formChanged.emit(this.form.value);
      });
  }

  private handleFormChanges(): void {
    const emitValue = (value: Partial<ReferralCampaign>) => {
      value.name.ar = value.name.en;
      this.formChanged.emit(value);
    };

    this.form.valueChanges
      .pipe(debounceTime(500), tap(emitValue), takeUntil(this.destroy$))
      .subscribe();
  }

  private handleFormStatusChanges(): void {
    const emitStatus = (value: FormStatus) => {
      this.checkFormStatus();
    };

    this.form.statusChanges
      .pipe(
        startWith(this.form.status),
        distinctUntilChanged(),
        tap(emitStatus),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private checkFormStatus(): void {
    this.formStatusChanged.emit(
      this.form.valid ? FormStatus.Valid : FormStatus.Invalid,
    );
  }

  private setDateOptions(): void {
    this.setStartDateOptions();
    this.setEndDateOptions();
  }

  private setStartDateOptions(): void {
    const today = new Date();

    this.startDateOptions = {
      disableUntil: {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        day: today.getDate() - 1,
      },
      closeAfterSelect: true,
    };
  }

  setEndDateOptions(startDate?: Date): void {
    const today = startDate || new Date();
    const after30Days = this.globalService
      .datePlusDays(today.toString(), 28)
      .toDate();

    this.endDateOptions = {
      disableUntil: {
        year: after30Days.getFullYear(),
        month: after30Days.getMonth() + 1,
        day: after30Days.getDate(),
      },
      closeAfterSelect: true,
      showTodayBtn: false,
    };
  }

  private loadGroups(): void {
    this.groups$ = this.groupService.getGroups().pipe(
      map((groups) =>
        groups.map((group) => ({
          value: group.id,
          label: group.name?.en,
        })),
      ),
    );
  }

  private loadAllSegments(): void {
    this.segments$ = this.userSegmentService.getAllUserSegments();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
