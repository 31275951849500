import { NgModule } from '@angular/core';

import { CarwashRoutingModule } from './carwash-routing.module';
import { CarwashCompanyModule } from '@app/modules/carwash/modules/carwash-company/carwash-company.module';
import { CarwashOrderModule } from '@app/modules/carwash/modules/carwash-order/carwash-order.module';
import { CarwashPackageModule } from '@app/modules/carwash/modules/carwash-packages/carwash-package.module';
import { CarwashBranchModule } from '@app/modules/carwash/modules/carwash-branch/carwash-branch.module';
import { CarwashStationModule } from '@app/modules/carwash/modules/carwash-station/carwash-station.module';
import { CarWashPackagesModule } from "@app/modules/carwash/modules/car-wash-packages/carwash-package.module";
import { CarWashAddonsModule } from "@app/modules/carwash/modules/car-wash-add-ons/car-wash-add-ons.module";
import { CarWashPackagesPricingModule } from "@app/modules/carwash/modules/car-wash-package-pricing/car-wash-package-pricing.module";
import { CarWashAddOnPricingModule } from "@app/modules/carwash/modules/car-wash-addons-pricing/car-wash-add-on-pricing.module";

@NgModule({
  imports: [
    CarwashRoutingModule,
    CarwashOrderModule,
    CarwashBranchModule,
    CarwashCompanyModule,
    CarwashPackageModule,
    CarwashStationModule,
    CarWashPackagesModule,
    CarWashAddonsModule,
    CarWashPackagesPricingModule,
    CarWashAddOnPricingModule,
  ],
})
export class CarwashModule {}
