// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input:focus {
  outline: 0;
}

.filter-hight {
  height: 450px;
  overflow-y: scroll;
}

.md-form {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.form-control {
  margin: 0 !important;
}

.md-tabs {
  width: 600px !important;
  margin: 0 auto !important;
  margin-bottom: -20px !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/jc-deliver/components/delivery-orders/delivery-orders.component.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,8BAA8B;AAChC;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,+BAA+B;AACjC","sourcesContent":["input:focus {\n  outline: 0;\n}\n\n.filter-hight {\n  height: 450px;\n  overflow-y: scroll;\n}\n\n.md-form {\n  margin-top: 1rem !important;\n  margin-bottom: 1rem !important;\n}\n\n.form-control {\n  margin: 0 !important;\n}\n\n.md-tabs {\n  width: 600px !important;\n  margin: 0 auto !important;\n  margin-bottom: -20px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
