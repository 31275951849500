import { MENU_ITEMS_LIST } from '@app/shared/service/panel-menu/menu-items-list';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormArray,
  UntypedFormControl,
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { FormStatus } from '@shared/enums/form-status.enum';
import { Crud, APIConfig, FieldConfig, AnotherAction } from '@shared/models';
import { SelectOptions } from '@app/shared/models/global/response';
import { APIVersion, DataType } from '@app/shared/enums';
import { CrudService } from '@app/shared/service/crud';
import { ACTION_COMPONENT_MAP } from '@app/modules/crud/shared/action-component-map/action-component-map';

@Component({
  selector: 'app-crud-form',
  templateUrl: './crud-form.component.html',
  styleUrls: ['./crud-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrudFormComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  paramsForm: UntypedFormGroup;
  customAPIConfigsForm: UntypedFormGroup;
  fieldParamsForm: UntypedFormGroup;
  otherActionsForm: UntypedFormGroup;
  fieldsForm: UntypedFormGroup;
  fieldStaticList: UntypedFormGroup;
  hasCustomPaths: boolean;
  hasParameters: boolean;
  fieldHasParameters: boolean;
  isDynamicList: boolean;
  isStaticList: boolean;
  isBoolean: boolean;
  hasValidations: boolean;
  isFromValue: boolean;
  isFieldFromValue: boolean;
  isValueNumber: boolean;
  isGETSubmitted: boolean;
  isDataModelSubmitted: boolean;
  selectedGroupedFieldsIndex: number;
  extraParameters: string;
  fieldExtraParameters: string;
  callTypes$: Observable<Array<SelectOptions<string>>>;
  localStorageKeys: Array<string>;
  editedIndex: number;
  getPageNameBounded: Function;
  @Input() crud: Crud;
  @Input() allCruds: Array<Crud>;
  API_VERSION = APIVersion;
  apiVersionKeys: Array<string> = Object.keys(APIVersion);
  DATA_TYPE = DataType;
  dataTypeKeys: Array<string> = Object.keys(DataType);
  actionComponentMapKeys = Object.keys(ACTION_COMPONENT_MAP);
  parentMenuItems = MENU_ITEMS_LIST.map((menuItem) => ({
    id: menuItem.id,
    name: menuItem.name,
  }));
  @Output() formChanged: EventEmitter<Partial<Crud>> = new EventEmitter<
    Partial<Crud>
  >();
  @Output() formStatusChanged: EventEmitter<FormStatus> =
    new EventEmitter<FormStatus>();

  private readonly destroy$: Subject<void> = new Subject<void>() ;
  editedIndexAnotherAction: number;
  isOpenAnotherPage: boolean;
  isNavigateToUrl: boolean;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly crudService: CrudService,
  ) {}

  ngOnInit(): void {
    this.initForms();
    this.handleFormChanges();
    this.handleFormStatusChanges();
    this.setLocalStorageKeys();
    this.getPageNameBounded = this.getPageName.bind(this);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  //#region GET API Info
  addParameter() {
    const control = new UntypedFormControl({
      [this.paramsForm.get('name').value]: this.paramsForm.get('value').value,
    });
    (this.form.get('extraParameters') as UntypedFormArray).push(control);
    this.refreshExtraParameters();
  }

  private refreshExtraParameters(): void {
    this.paramsForm.reset();
    this.isFromValue = false;
    const arr = (
      this.form.get('extraParameters') as UntypedFormArray
    ).controls.map((control) => {
      const key = Object.keys(control.value)[0];
      return `${key}=${control.value[key]}`;
    });
    if (arr.length) {
      this.extraParameters = `?${arr.join('&')}`;
    } else {
      this.extraParameters = '';
    }
  }

  removeParameter(index: number) {
    (this.form.get('extraParameters') as UntypedFormArray).removeAt(index);
    this.refreshExtraParameters();
  }
  //#endregion

  //#region Other Actions
  toggleOtherActions(isChecked: boolean): void {
    if (!isChecked) {
      (this.form.get('otherActions') as UntypedFormArray).value?.forEach(() =>
        this.removeAnotherAction(0),
      );
    }
  }
  addAnotherAction() {
    const values = {
      actionName: this.otherActionsForm.get('actionName').value,
      component: this.otherActionsForm.get('component').value,
      linkedPageId: this.otherActionsForm.get('linkedPageId').value,
      navigationUrl: this.otherActionsForm.get('navigationUrl').value,
      foreignKeyName: this.otherActionsForm.get('foreignKeyName').value,
      isSeparateAction: this.otherActionsForm.get('isSeparateAction').value,
      isSuperAdmin: this.otherActionsForm.get('isSuperAdmin').value,
      emailsList: this.otherActionsForm.get('emailsList').value,
    };
    if (this.editedIndexAnotherAction || this.editedIndexAnotherAction === 0) {
      (this.form.get('otherActions') as UntypedFormArray).controls[
        this.editedIndexAnotherAction
      ].setValue(values);
    } else {
      const control = new UntypedFormControl(values);
      (this.form.get('otherActions') as UntypedFormArray).push(control);
    }
    this.refreshExtraOtherActions();
  }

  private refreshExtraOtherActions(): void {
    this.otherActionsForm.reset();
    this.editedIndexAnotherAction = null;
    this.isOpenAnotherPage = false;
    this.isNavigateToUrl = false;
  }

  removeAnotherAction(index: number) {
    (this.form.get('otherActions') as UntypedFormArray).removeAt(index);
    this.refreshExtraOtherActions();
  }

  editAnotherAction(index: number) {
    this.editedIndexAnotherAction = index;
    const selectedAction: AnotherAction = (
      this.form.get('otherActions') as UntypedFormArray
    ).value[index];
    this.isOpenAnotherPage = !!selectedAction.linkedPageId;
    this.isNavigateToUrl = !!selectedAction.navigationUrl;
    selectedAction.isSuperAdmin = selectedAction.isSuperAdmin || false;
    selectedAction.emailsList = selectedAction.emailsList || '';
    this.otherActionsForm.patchValue(selectedAction);
  }
  //#endregion

  //#region Data model
  addField(selectedForm: UntypedFormArray) {
    const values = {
      backendKeyName: this.fieldsForm.get('backendKeyName').value,
      isRequired: this.fieldsForm.get('isRequired').value,
      showAsToggleInTable: !!this.fieldsForm.get('showAsToggleInTable').value,
      labelText: this.fieldsForm.get('labelText').value,
      tableHeaderText: this.fieldsForm.get('tableHeaderText').value,
      isEnglishOnly: this.fieldsForm.get('isEnglishOnly').value,
      dataType: this.fieldsForm.get('dataType').value,
      minLength: this.fieldsForm.get('minLength').value,
      maxLength: this.fieldsForm.get('maxLength').value,
      apiConfig: !selectedForm['isDynamicList']
        ? undefined
        : {
            apiVersion: this.fieldsForm.get('apiConfig').get('apiVersion')
              .value,
            path: this.fieldsForm.get('apiConfig').get('path').value,
            extraParameters: this.crudService.getMappedParameters(
              this.fieldsForm.get('fieldExtraParameters').value,
            ),
          },
      list: this.fieldsForm.get('list').value,
      toggleConfig: !selectedForm['isBoolean']
        ? undefined
        : {
            activeValue: this.fieldsForm.get('toggleConfig').get('activeValue')
              .value,
            inactiveValue: this.fieldsForm
              .get('toggleConfig')
              .get('inactiveValue').value,
          },
    };

    if (selectedForm['editedIndex'] || selectedForm['editedIndex'] === 0) {
      selectedForm.controls[selectedForm['editedIndex']].setValue(values);
    } else {
      selectedForm.push(new UntypedFormControl(values));
    }

    this.editedIndex = null;
    this.isDynamicList = selectedForm['isDynamicList'] = false;
    this.isStaticList = selectedForm['isStaticList'] = false;
    this.isBoolean = selectedForm['isBoolean'] = false;
    this.hasValidations = selectedForm['hasValidations'] = false;
    selectedForm['fieldHasParameters'] = false;
    this.fieldExtraParameters = '';
    (this.fieldsForm.get('list') as UntypedFormArray).value?.forEach(() =>
      (this.fieldsForm.get('list') as UntypedFormArray).removeAt(0),
    );
    this.fieldsForm.reset();
    selectedForm['editedIndex'] = null;
  }

  removeField(index: number, selectedForm: UntypedFormArray) {
    selectedForm.removeAt(index);
  }

  moveUp(index: number, selectedForm: UntypedFormArray) {
    if (index !== 0) {
      selectedForm.controls.splice(index - 1, 0, selectedForm.controls[index]);
      this.removeField(index + 1, selectedForm);
    }
  }

  moveDown(index: number, selectedForm: UntypedFormArray) {
    if (index !== selectedForm.controls.length - 1) {
      selectedForm.controls.splice(index + 2, 0, selectedForm.controls[index]);
      this.removeField(index, selectedForm);
    }
  }

  editField(index: number, selectedForm: UntypedFormArray) {
    this.editedIndex = index;
    this.fieldsForm.patchValue(selectedForm.value[index]);
    this.dataTypeChange(
      this.fieldsForm.get('dataType').value,
      selectedForm,
      selectedForm.value[index],
    );
    const fieldExtraParameters = this.crudService.getReversedParameters(
      selectedForm.value[index]?.apiConfig?.extraParameters,
    );
    this.fieldHasParameters = !!fieldExtraParameters.length;
    fieldExtraParameters.forEach((param) => {
      const key = Object.keys(param)[0];
      this.fieldParamsForm.get('name').setValue(key);
      this.fieldParamsForm.get('value').setValue(param[key]);
      this.addFieldParameter();
    });
  }

  dataTypeChange(
    dataType: DataType,
    selectedForm: UntypedFormArray,
    fieldConfig?: FieldConfig,
  ): void {
    this.isDynamicList = selectedForm['isDynamicList'] =
      dataType === DataType.dynamicList;
    this.isStaticList = selectedForm['isStaticList'] =
      dataType === DataType.staticList;
    if (this.isStaticList) {
      fieldConfig.list?.forEach((item) =>
        (this.fieldsForm.get('list') as UntypedFormArray).push(
          new UntypedFormControl(item),
        ),
      );

      this.refreshFieldStatiListForm();
    }
    this.isBoolean = selectedForm['isBoolean'] = dataType === DataType.boolean;
    this.hasValidations = selectedForm['hasValidations'] = [
      DataType.translation,
      DataType.translationSingle,
      DataType.string,
      DataType.textareaTranslation,
      DataType.textareaTranslationSingle,
      DataType.textarea,
    ].includes(dataType);
  }
  //#endregion

  //#region Field extra parameters
  addFieldParameter() {
    const control = new UntypedFormControl({
      [this.fieldParamsForm.get('name').value]:
        this.fieldParamsForm.get('value').value,
    });
    (this.fieldsForm.get('fieldExtraParameters') as UntypedFormArray).push(
      control,
    );
    this.refreshFieldExtraParameters();
  }

  private refreshFieldExtraParameters(): void {
    this.fieldParamsForm.reset();
    this.isFieldFromValue = false;
    const arr = (
      this.fieldsForm.get('fieldExtraParameters') as UntypedFormArray
    ).controls.map((control) => {
      const key = Object.keys(control.value)[0];
      return `${key}=${control.value[key]}`;
    });
    if (arr.length) {
      this.fieldExtraParameters = `?${arr.join('&')}`;
    } else {
      this.fieldExtraParameters = '';
    }
  }

  removeFieldParameter(index: number) {
    (this.fieldsForm.get('fieldExtraParameters') as UntypedFormArray).removeAt(
      index,
    );
    this.refreshFieldStatiListForm();
  }
  //#endregion

  //#region Field static list
  addStatisListItem() {
    if (this.isValueNumber) {
      this.fieldStaticList.value.value = +this.fieldStaticList.value.value;
    }
    const control = new UntypedFormControl(this.fieldStaticList.value);
    (this.fieldsForm.get('list') as UntypedFormArray).push(control);
    this.refreshFieldStatiListForm();
  }

  private refreshFieldStatiListForm(): void {
    this.fieldStaticList.reset();
  }

  removeStatisListItem(index: number) {
    (this.fieldsForm.get('list') as UntypedFormArray).removeAt(index);
    this.refreshFieldStatiListForm();
  }
  //#endregion

  //#region Bottom grouped fields
  addNewGroupedFields(): void {
    const values = this.fb.group({
      groupName: [''],
      backendArrayName: [''],
      backendObjectName: [''],
      dataModel: new UntypedFormArray([]),
    });
    (this.form.get('bottomGroupedFields') as UntypedFormArray).push(
      new UntypedFormControl(values),
    );
    this.selectedGroupedFieldsIndex =
      (this.form.get('bottomGroupedFields') as UntypedFormArray).length - 1;
  }

  removeGroupedFields(index: number) {
    (this.form.get('bottomGroupedFields') as UntypedFormArray).removeAt(index);
  }
  //#endregion

  private initForms(): void {
    this.form = this.fb.group({
      id: [],
      pageName: ['', Validators.required],
      menuNamePrefix: ['List of'],
      parentMenuItemId: [''],
      isActive: [false],
      isSuperAdmin: [false],
      isHideFromMenu: [false],
      canCreate: [true],
      canCreateSA: [false],
      canUpdate: [false],
      canUpdateSA: [false],
      canDelete: [false],
      canDeleteSA: [false],
      apiConfig: this.fb.group({
        apiVersion: [this.apiVersionKeys[2], Validators.required],
        path: ['', Validators.required],
      }),
      extraParameters: new UntypedFormArray([]),
      otherActions: new UntypedFormArray([]),
      dataModel: new UntypedFormArray([]),
      bottomGroupedFields: new UntypedFormArray([]),
      customAPIConfigs: this.fb.group({
        POST: [''],
        PATCH: [''],
        DELETE: [''],
      }),
    });

    this.customAPIConfigsForm = this.fb.group({
      POST: [(this.crud?.customAPIConfigs?.POST as APIConfig)?.path || ''],
      PATCH: [(this.crud?.customAPIConfigs?.PATCH as APIConfig)?.path || ''],
      DELETE: [(this.crud?.customAPIConfigs?.DELETE as APIConfig)?.path || ''],
    });

    this.patchForm();

    this.paramsForm = this.fb.group({
      name: ['', Validators.required],
      value: ['', Validators.required],
    });

    this.otherActionsForm = this.fb.group({
      actionName: ['', Validators.required],
      component: [''],
      linkedPageId: [''],
      navigationUrl: [''],
      foreignKeyName: [''],
      isSeparateAction: [false],
      isSuperAdmin: [false],
      emailsList: [''],
    });

    this.fieldParamsForm = this.fb.group({
      name: ['', Validators.required],
      value: ['', Validators.required],
    });

    this.fieldStaticList = this.fb.group({
      label: ['', Validators.required],
      value: ['', Validators.required],
    });

    this.fieldsForm = this.fb.group({
      backendKeyName: ['', Validators.required],
      isRequired: [false],
      showAsToggleInTable: [false],
      labelText: ['', Validators.required],
      tableHeaderText: [''],
      isEnglishOnly: [false],
      dataType: ['', Validators.required],
      minLength: [''],
      maxLength: [''],
      apiConfig: this.fb.group({
        apiVersion: [this.apiVersionKeys[2]],
        path: [''],
      }),
      toggleConfig: this.fb.group({
        activeValue: [''],
        inactiveValue: [''],
      }),
      fieldExtraParameters: new UntypedFormArray([]),
      list: new UntypedFormArray([]),
    });
  }

  private patchForm(): void {
    if (!this.crud) {
      return;
    }

    this.form.patchValue(this.crud);

    this.crud.dataModel?.forEach((field) => {
      const control = new UntypedFormControl(field);
      (this.form.get('dataModel') as UntypedFormArray).push(control);
    });

    this.crud.otherActions?.forEach((action) => {
      const control = new UntypedFormControl(action);
      (this.form.get('otherActions') as UntypedFormArray).push(control);
    });

    this.crud.bottomGroupedFields?.forEach((group) => {
      const values = this.fb.group({
        groupName: [group.groupName],
        backendArrayName: [group.backendArrayName],
        backendObjectName: [group.backendObjectName],
        dataModel: new UntypedFormArray(
          group.dataModel?.map((field) => new UntypedFormControl(field)),
        ),
      });
      (this.form.get('bottomGroupedFields') as UntypedFormArray).push(
        new UntypedFormControl(values),
      );
    });

    const keys = Object.keys(this.crud.apiConfig?.extraParameters || {});
    this.hasParameters = keys.length > 0;
    this.hasCustomPaths = !!(
      this.crud.customAPIConfigs?.POST ||
      this.crud.customAPIConfigs?.PATCH ||
      this.crud.customAPIConfigs?.DELETE
    );
    this.isGETSubmitted = true;
    this.isDataModelSubmitted = true;
    keys.forEach((keyName) => {
      const control = new UntypedFormControl({
        [keyName]: this.crud.apiConfig?.extraParameters[keyName],
      });
      (this.form.get('extraParameters') as UntypedFormArray).push(control);
    });

    this.customAPIConfigsForm.valueChanges.subscribe(() => {
      const post = this.customAPIConfigsForm.get('POST').value;
      const patch = this.customAPIConfigsForm.get('PATCH').value;
      const del = this.customAPIConfigsForm.get('DELETE').value;
      const values = {
        POST: !post ? '' : { ...this.form.get('apiConfig').value, path: post },
        PATCH: !patch
          ? ''
          : { ...this.form.get('apiConfig').value, path: patch },
        DELETE: !del ? '' : { ...this.form.get('apiConfig').value, path: del },
      };
      this.form.get('customAPIConfigs').setValue(values);
    });

    this.formChanged.emit(this.form.value);
  }

  private handleFormChanges(): void {
    const emitValue = (value: Partial<Crud>) => this.formChanged.emit(value);

    this.form.valueChanges
      .pipe(debounceTime(500), tap(emitValue), takeUntil(this.destroy$))
      .subscribe();
  }

  private handleFormStatusChanges(): void {
    const emitStatus = (value: FormStatus) =>
      this.formStatusChanged.emit(value);

    this.form.statusChanges
      .pipe(
        startWith(this.form.status),
        distinctUntilChanged(),
        tap(emitStatus),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private setLocalStorageKeys(): void {
    /** Set only numeric keys */
    this.localStorageKeys = Object.keys(localStorage).filter(
      (key) => +localStorage[key],
    );
  }

  private getPageName(pageId: number): string {
    return this.allCruds.find((page) => page.id === +pageId)?.pageName || '-';
  }

  change(value: string, form = this.paramsForm) {
    form.get('value').setValue(`{${value}}`);
    if (form === this.fieldsForm) {
      this.fieldsForm
        .get('apiConfig')
        .get('path')
        .setValidators(Validators.required);
      this.fieldsForm
        .get('apiConfig')
        .get('value')
        .setValidators(Validators.required);
    }
  }
}
