import { takeUntil, tap, toArray } from 'rxjs/operators';
import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { Status } from '@shared/enums/status.enum';
import { concat, Observable, Subject } from 'rxjs';

import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { HomeCleaningPackagesService } from '@app/shared/service/home-cleaning/home-cleaning-packages.service';
import { PackageHour } from '@app/shared/models/home-cleaning/package-hour';

@Component({
  selector: 'app-package-hours',
  templateUrl: './package-hours.component.html',
  styleUrls: ['./package-hours.component.scss'],
})
export class PackageHoursComponent implements OnInit {
  isLoading: boolean;
  isLoadingData: boolean;
  decimals: number;
  form: UntypedFormArray;

  private readonly destroy$: Subject<void> = new Subject<void>() ;
  private objectsToUpdate: { [id: number]: Partial<PackageHour> } = {};

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: {
      dataRow: any;
      PageNamePrefix: string;
      foreignKeyParam: Object;
    } | any,
    private readonly dialogRef: DialogRef<PackageHoursComponent>,
    private readonly cd: ChangeDetectorRef,
    private readonly fb: UntypedFormBuilder,
    private readonly homeCleaningPackagesService: HomeCleaningPackagesService,
  ) {}

  ngOnInit(): void {
    this.decimals = +localStorage.getItem('decimal') || 2;
    this.loadData();
  }

  saveTimes(): void {
    this.isLoading = true;

    const subscribers$: Array<Observable<any>> = Object.keys(
      this.objectsToUpdate,
    ).map((id) => {
      return this.homeCleaningPackagesService.updatePackageHour(
        +id,
        this.objectsToUpdate[id],
      );
    });

    if (subscribers$.length) {
      /** calling observables sequentially */
      concat(...subscribers$)
        .pipe(
          toArray(),
          tap(() => {
            this.isLoading = false;
          }),
          takeUntil(this.destroy$),
        )
        .subscribe(
          () => this.dialogRef.close({}),
          (error) => {
            this.isLoading = false;
            this.cd.markForCheck();
          },
        );
    } else {
      this.dialogRef.close();
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  private mapActiveStatus(config: PackageHour) {
    return {
      ...config,
      status: config.status === Status.Active,
    };
  }

  private loadData(): void {
    this.isLoadingData = true;
    this.homeCleaningPackagesService
      .getPackageHours(this.data?.dataRow?.id)
      .subscribe(
        (packageHours) => {
          this.form = this.fb.array(
            packageHours.map((packageHour) => {
              const formGroup = this.fb.group(
                this.mapActiveStatus(packageHour),
              );
              formGroup
                .get('hourlyRate')
                .setValue(packageHour.hourlyRate?.toFixed(this.decimals));
              formGroup
                .get('suppliesRate')
                .setValue(packageHour.suppliesRate?.toFixed(this.decimals));

              formGroup.valueChanges
                .pipe(takeUntil(this.destroy$))
                .subscribe((changes: PackageHour) => {
                  this.objectsToUpdate[changes.id] = {
                    hourlyRate: +changes.hourlyRate,
                    suppliesRate: +changes.suppliesRate,
                    status: changes.status ? Status.Active : Status.Inactive,
                  };
                });

              return formGroup;
            }),
          );

          this.isLoadingData = false;
          this.cd.detectChanges();
        },
        (error) => {
          this.dialogRef.close();
        },
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
