export class Name {
  en: string;
  ar: string;
}

export class Category {
  id: number;
  cat_name: string;
  cat_image: string;
  group_id: number;
  sort_order: number;
  name: Name;
  status: string;
}


export class CategoryList {
  id: number;
  name: Name;
  image: string;
  group_id: number;
  sort_order: number;
  status: string;
  selection_type: string;
}

export class CategoryTranslation {
  language_id: number;
  name: string;
}

export class CategoryToBeSaved {
  id?: number;
  sort_order: number;
  image: string;
  category_translations: CategoryTranslation[];
  selection_type: string;
}

export interface CategoryPositionBody {
  to: number;
  from: number;
  category_id: number;
}

export enum CATEEGORY_TYPE {
  MULTIPLE = 'multiple',
  SINGLE = 'single'
}
