import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Languages } from '@app/modules/carwash/modules/carwash-packages/shared/models';
import { OrderDetailsItemSize } from '../shared/enums/order-details-item-size.enum';


@Component({
  selector: 'app-homeclean-order-details-item',
  templateUrl: './homeclean-order-details-item.component.html',
  styleUrls: ['./homeclean-order-details-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomecleanOrderDetailsItemComponent {
  @Input() name: string;
  @Input() color: string;
  @Input() value: string | string[] | Languages[];
  @Input() size: OrderDetailsItemSize = OrderDetailsItemSize.Medium;

  isArray(value): boolean {
    return Array.isArray(value);
  }
}
