import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@env/environment';
import { GlobalService } from '@shared/service/global.service';
import { TatItem } from '@app/modules/items/shared/models/tat-item.model';
import { ApiResponse } from '@shared/models/global/apiResponse';

@Injectable({
  providedIn: 'root',
})
export class TatService {

  headers: HttpHeaders;
  readonly URL_TAT = environment.jcDeliveryServer + 'tat-items';
  constructor(
    private readonly http: HttpClient,
    private readonly globalService: GlobalService,
  ) {
    this.headers = new HttpHeaders(this.globalService.headers as any);
  }


  getTatItems(itemId: number): Observable<TatItem[]> {
    const isCountry = (item: TatItem) =>
      item.countryId === this.globalService.countryId;

    return this.http
      .get<ApiResponse<TatItem[]>>(`${this.URL_TAT}`, {
        params: { itemId: itemId.toString() },
        headers: this.headers,
      })
      .pipe(map((res: ApiResponse<TatItem[]>) => res.data.filter(isCountry)));
  }

  updateTat(id: number, deliveryMin: string): Observable<TatItem> {
    return this.http
      .patch<any>(
        `${this.URL_TAT}/${id}`,
        { deliveryMin },
        {
          headers: this.headers,
        },
      )
      .pipe(map((res) => res.data));
  }
}
