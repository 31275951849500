import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import {
  GovernorateList,
  AreaList
} from '../../../../../shared/models/countries/country';
import { GlobalService } from '../../../../../shared/service/global.service';
import { BranchesService } from '../../../../../shared/service/branches.service';
import { CountryService } from '../../../../../shared/service/country.service';
import {
  BranchToBeSaved,
  BranchToBeUpdated
} from '../../../../../shared/models/laundries/branch';
import { MarkerForMap } from 'src/app/shared/models/global/response';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-branch-info',
  templateUrl: './branch-info.component.html',
  styleUrls: ['./branch-info.component.css']
})
export class BranchInfoComponent implements OnInit {
  branchName: string;
  branchManager: string;
  mobile: string;
  alt_mobile: string;
  email: string;
  numDrivers: number;
  addressText: string;
  governorates: any[];
  governorate: number;
  areas: any[];
  areasFromBackend: AreaList[];
  area: number;
  long: number;
  lat: number;
  laundryId: number;
  loader = false;
  showAreas = false;
  isBackendError: boolean;
  errorText: string;
  branchId: number;
  isUpdate: boolean;
  zoom = 14;
  markers: MarkerForMap[] = [];
  showMap = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected globalService: GlobalService,
    protected branchService: BranchesService,
    protected countryService: CountryService,
  ) {}

  ngOnInit() {
    this.laundryId = this.route.parent.snapshot.params.id;
    this.isUpdate =
      this.route.snapshot.queryParams.update === 'true' ? true : false;
    this.showMap = this.isUpdate;
    this.branchId = this.route.snapshot.queryParams.branchId;
    console.log(this.showMap);
    this.initForm();
  }

  getGovernorates() {
    this.countryService
      .getListCities(this.globalService.headers)
      .subscribe(response => {
        if (response.code === 200) {
          this.governorates = [];
          let gov = response.data as GovernorateList[];
          gov = gov.filter(e => e.country_id === this.globalService.countryId);
          gov.forEach(governorate => {
            this.governorates.push({
              value: governorate.id,
              label: governorate.name.en
            });
          });
        }
      });
  }

  getAreas(govId, currentId?: number) {
    this.countryService
      .getListAreas(this.globalService.headers, { page: 0 })
      .subscribe(response => {
        if (response.code === 200) {
          this.areas = [];
          let areas = response.data as AreaList[];

          areas = areas
            .filter(e => e.governorate_id === govId)
            .sort((a, b) => {
              const nameA = a.name.en.toLowerCase(),
                nameB = b.name.en.toLowerCase();
              if (nameA < nameB) {
                // sort string ascending
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
          areas.forEach(area => {
            this.areas.push({
              value: area.id,
              label: area.name.en
            });
          });
          this.areasFromBackend = areas;
        }
        this.area = this.isUpdate ? currentId : null;
      });
  }

  saveBranch() {
    this.loader = true;
    const branchInfo = new BranchToBeSaved();
    branchInfo.address = this.addressText;
    branchInfo.governorate_id = this.governorate;
    branchInfo.area_id = this.area;
    branchInfo.email = this.email;
    branchInfo.manager = this.branchManager;
    branchInfo.name = this.branchName;
    branchInfo.mobile = this.mobile;
    branchInfo.alternate_mobile = this.alt_mobile;
    branchInfo.laundry_id = this.laundryId;
    branchInfo.latitude = this.lat;
    branchInfo.longitude = this.long;
    branchInfo.no_of_drivers = this.numDrivers;
    console.log(branchInfo);
    if (this.isUpdate) {
      this.branchService
        .updateBranch(this.globalService.headers, branchInfo, this.branchId)
        .subscribe(
          response => {
            if (response.code === 200) {
              this.loader = false;
              this.router.navigate([
                'dashboard/branches/create-branch/' +
                  this.laundryId +
                  '/step4/' +
                  this.branchId +
                  '/' +
                  this.isUpdate
              ]);
            }
          },
          error => {
            this.loader = false;
          }
        );
    } else {
      this.branchService
        .createBranch(this.globalService.headers, branchInfo)
        .subscribe(
          response => {
            if (response.code === 200) {
              const id = response.data.id;
              this.loader = false;
              this.router.navigate([
                'dashboard/branches/create-branch/' +
                  this.laundryId +
                  '/step4/' +
                  id +
                  '/' +
                  this.isUpdate
              ]);
            }
          },
          error => {
            this.loader = false;
          }
        );
    }
  }

  changeGov(event) {
    this.getAreas(event);
    this.showAreas = true;
  }

  changeArea(areaId: number) {
    const area = this.areasFromBackend.find(e => e.id === areaId);
    this.markers = [];
    this.markers.push({
      lng: area.longitude,
      lat: area.latitude,
      draggable: true
    });
    this.lat = area.latitude;
    this.long = area.longitude;
    this.showMap = true;
  }

  markerDragEnd($event) {
    this.long = $event.coords.lng;
    this.lat = $event.coords.lat;
  }

  updateMarkerFromInput(model: NgModel) {
    if (model.name === 'longitude') {
      this.markers[0].lng = model.value;
    } else {
      this.markers[0].lat = model.value;
    }
  }

  pressEnter(event: KeyboardEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  protected initForm() {
    this.getGovernorates();
    if (this.isUpdate) {
      this.getBranchInfoForUpdate(this.branchId);
    } else {
      const initLonLat = this.globalService.getLongLat();
      this.markers.push({
        lng: initLonLat.longitude,
        lat: initLonLat.latitude,
        draggable: true
      });
    }
  }

  validateForm() {
    return this.governorate && this.area;
  }

  private getBranchInfoForUpdate(id: number) {
    this.branchService
      .getBranchInfo(this.globalService.headers, id)
      .subscribe(response => {
        if (response.code === 200) {
          const branchInfo = response.data as BranchToBeUpdated;
          this.branchName = branchInfo.name;
          this.branchManager = branchInfo.manager;
          this.mobile = branchInfo.mobile;
          this.email = branchInfo.email;
          this.numDrivers = branchInfo.no_of_drivers;
          this.addressText = branchInfo.address;
          this.governorate = branchInfo.governorate_id;
          this.lat = branchInfo.latitude;
          this.long = branchInfo.longitude;
          this.alt_mobile = branchInfo.alternate_mobile;
          this.markers.push({
            lng: this.long,
            lat: this.lat,
            draggable: true
          });
          this.getAreas(this.governorate, branchInfo.area_id);
          this.showAreas = true;
        }
      });
  }
}
