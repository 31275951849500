import { GeneratedPageFormComponent } from './components/generated-pages/generated-page-edit-dialog/generated-page-form/generated-page-form.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { CrudsRoutingModule } from './crud-routing.module';
import { CrudListComponent } from './components/crud-list/crud-list.component';
import { CrudEditDialogComponent } from './components/crud-edit-dialog/crud-edit-dialog.component';
import { CrudFormComponent } from './components/crud-edit-dialog/crud-form/crud-form.component';
import { GeneratedPageListComponent } from './components/generated-pages/generated-page-list/generated-page-list.component';
import { GeneratedPageEditDialogComponent } from './components/generated-pages/generated-page-edit-dialog/generated-page-edit-dialog.component';
import { ServiceAreasComponent } from './shared/action-components/service-areas/service-areas.component';
import { WorkingHoursComponent } from './shared/action-components/working-hours/working-hours.component';
import { AssignServiceToCompanyComponent } from './shared/action-components/assign-service-to-company/assign-service-to-company.component';
import { PackageHoursComponent } from './shared/action-components/package-hours/package-hours.component';
import { ServicePaymentMethodsComponent } from './shared/action-components/service-payment-methods/service-payment-methods.component';
import { WorkingHoursPriceInputComponent } from './shared/action-components/working-hours/working-hours-price-input/working-hours-price-input.component';
import { ManagePermissionsComponent } from './components/manage-permissions/manage-permissions.component';

const PAGE_GENERATOR_COMPONENTS = [
  CrudListComponent,
  CrudEditDialogComponent,
  CrudFormComponent,
];

const GENERATED_PAGE_COMPONENTS = [
  GeneratedPageListComponent,
  GeneratedPageEditDialogComponent,
  GeneratedPageFormComponent,
];

const ACTION_COMPONENTS = [
  ServiceAreasComponent,
  WorkingHoursComponent,
  AssignServiceToCompanyComponent,
  PackageHoursComponent,
  ServicePaymentMethodsComponent,
  WorkingHoursPriceInputComponent,
];
@NgModule({
  declarations: [
    ...PAGE_GENERATOR_COMPONENTS,
    ...GENERATED_PAGE_COMPONENTS,
    ...ACTION_COMPONENTS,
    ManagePermissionsComponent,
  ],
  imports: [CommonModule, SharedModule.forRoot(), CrudsRoutingModule],
})
export class CrudsModule {}
