export class LaundryTranslation {
  language_id: number;
  name: string;
}

export class LaundryToBeSaved {
  laundry_translations: LaundryTranslation[];
  address: string;
  area_id: number;
  owner_name: string;
  mobile: string;
  mobile_alt: string;
  email: string;
  trn_name: string;
  trn_number: string;
  name_actual: string;
  laundry_id?: number;
}

export class LaundryFromList {
  id: number;
  code: string;
  name: string;
  image_logo: string;
  owner_name: string;
  mobile: string;
  status: string;
  rating_value: number;
  rating_count: number;
  number_of_branch: number;
  number_of_order: number;
  number_of_driver: number;
  is_logistics?: boolean;
  is_corporate?: boolean;
  terminate?: boolean;
  laundry_branches: LaundryBranch[];
}
export interface LaundryBranch {
  id: number;
  is_corporate: boolean;
  campaign_cashback: boolean;
  status: string;
  campaign_offeritem: boolean;
  name: string;
}
export class NameTranslations {
  en: string;
  ar: string;
}

export class LaundryInfo {
  id: number;
  code: string;
  name: string;
  address: string;
  owner_name: string;
  mobile: string;
  mobile_alt: string;
  email: string;
  area_id: number;
  name_translations: NameTranslations;
  trn_name: string;
  trn_number: string;
  name_actual: string;
}

export class LaundryForCountry {
  id: number;
  name: string;
  code: string;
}

export class LaundryCommission {
  from_order: number;
  to_order: number;
  charge_value: string;
  charge_type: string;
}

export class ContractDocument {
  name: string;
  url: string;
  file_type: string;
}

export class LaundryContract {
  laundry_id: number;
  id?: number;
  contract_start_date: string;
  contract_end_date: string;
  is_market_place: boolean;
  is_corporate: boolean;
  comission_type: string;
  laundry_commission: LaundryCommission[];
  contract_document: ContractDocument[];
  contract_id?: number;
  subscription_fee_monthly: number;
}

export class LaundryContractInfo {
  id: number;
  comission_type: string;
  contract_start_date: string;
  contract_end_date: string;
  is_market_place: boolean;
  is_corporate: boolean;
  status: string;
  laundry_id: number;
  laundry_commissions: LaundryCommission[];
  contract_document: [
    {
      name: string;
      url: string;
      file_type: string;
    }
  ];
  subscription_fee_monthly: number;
}

export class Translation {
  description: string;
  language_id: number;
  logo: string;
  main_image: string;
}

export class LaundryImage {
  name: string;
  url: string;
  file_type: string;
}

export class LaundryMarketing {
  laundry_id: number;
  translations: Translation[];
  laundry_images: LaundryImage[];
}

export class LaundryLegalDoc {
  id: number;
  name: string;
  file_type: string;
  flag_type: string;
  url: string;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  laundry_id: number;
  laundry_contract_id?: any;
}

export class Desc {
  name: string;
  description: string;
}

export class Translations {
  en: Desc;
  ar: Desc;
}

export class LaundryMArketingInfo {
  id: number;
  name: string;
  code: string;
  image_logo: string;
  image_backdrop: string;
  laundry_legal_docs: LaundryLegalDoc[];
  translations: Translations;
}
