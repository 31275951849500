import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-laundry',
  templateUrl: './create-laundry.component.html',
  styleUrls: ['./create-laundry.component.css']
})
export class CreateLaundryComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
