export const ORDER_MAP_SUBSCRIPTION = `
subscription {
  newOrderSubscription {
    currency
    latitude
    longitude
    id
    branchName
    currencyDecimal
    updateType
    type
    groupId
    amount
    countryId
    firstName
    lastName
    zoneName
  }
}
`;
