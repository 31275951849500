import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { FormStatus } from '@shared/enums/form-status.enum';
import { UserSegment } from '@shared/models';
import { UserSegmentService } from '@shared/service/user-segment';

@Component({
  templateUrl: './user-segment-edit-dialog.component.html',
})
export class UserSegmentEditDialogComponent implements OnInit {
  isEditMode: boolean;
  isDisableButton: boolean;
  formData: Partial<UserSegment>;
  isLoading: boolean;
  isFormValid: boolean;

  private readonly destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    @Inject(DIALOG_DATA) public readonly data: UserSegment | any,
    private readonly dialogRef: DialogRef<UserSegmentEditDialogComponent>,
    private readonly userSegmentService: UserSegmentService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.isEditMode = !!this.data;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(): void {
    this.dialogRef.close();
  }

  formChanged(userSegment: Partial<UserSegment>): void {
    this.formData = {
      ...userSegment,
    };
  }

  formStatusChanged(status: FormStatus): void {
    this.isFormValid = status === FormStatus.Valid;
    this.changeSaveButtonStatus();
  }

  createUserSegment(): void {
    if (this.formData.orderStartRange < this.formData.orderEndRange) {
      const data: Partial<UserSegment> = {
        ...this.formData,
      };

      this.isLoading = true;
      this.userSegmentService
        .createUserSegment(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (userSegment: UserSegment) => {
            this.dialogRef.close(userSegment);
          },
          (error) => {
            this.isLoading = false;
            this.cdr.markForCheck();
          },
        );
    }
  }

  private changeSaveButtonStatus(): void {
    this.isDisableButton = !this.isFormValid;
  }
}
