import { GlobalService } from 'src/app/shared/service/global.service';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ReferralCampaignHttpService } from '@shared/service/referral-campaign/referral-campaign-http.service';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { ReferralCampaign } from '@shared/models';
import { PaginationHelper } from '@app/shared/helpers/pagination-helper';
import { PageInfo } from '@app/shared/models/global/response';

@Injectable({ providedIn: 'root' })
export class ReferralCampaignService {
  readonly pagination$: Subject<PageInfo> = new Subject<PageInfo>();

  constructor(
    private readonly referralCampaignHttpService: ReferralCampaignHttpService,
    private readonly globalService: GlobalService,
  ) {}

  getReferralCampaigns(
    filters?: Record<string, string>,
  ): Observable<ReferralCampaign[]> {
    const toData = (res: ApiResponse<ReferralCampaign[]>) =>
      res.data.map((item) => this.mapCampaignItem(item));

    const toPagination = (res: ApiResponse<ReferralCampaign[]>) =>
      res.pageInfo &&
      this.pagination$.next(PaginationHelper.camelToSnakeCase(res.pageInfo));

    return this.referralCampaignHttpService
      .getReferralCampaigns(filters)
      .pipe(tap(toPagination), map(toData));
  }

  getReferralCampaign(id: number): Observable<ReferralCampaign> {
    const toData = (res: ApiResponse<ReferralCampaign>) =>
      this.mapCampaignItem(res.data);

    return this.referralCampaignHttpService
      .getReferralCampaign(id)
      .pipe(map(toData));
  }

  createReferralCampaign(
    data: Partial<ReferralCampaign>,
  ): Observable<ReferralCampaign> {
    const toReferralCampaign = (res: ApiResponse<ReferralCampaign>) => res.data;

    data = this.mapCampaignItem(data as ReferralCampaign, false);

    return this.referralCampaignHttpService
      .createReferralCampaign(data)
      .pipe(map(toReferralCampaign));
  }

  updateReferralCampaign(
    id: number,
    data: Partial<ReferralCampaign>,
  ): Observable<ReferralCampaign> {
    const toReferralCampaign = (res: ApiResponse<ReferralCampaign>) => res.data;

    data = this.mapCampaignItem(data as ReferralCampaign, false);

    return this.referralCampaignHttpService
      .updateReferralCampaign(id, data)
      .pipe(map(toReferralCampaign));
  }

  private mapCampaignItem(
    referralCampaign: ReferralCampaign,
    isGet: boolean = true,
  ): ReferralCampaign {
    if (isGet) {
      return {
        ...referralCampaign,
        senderPercentage: (referralCampaign.senderPercentage || 0) * 100,
        senderValidityInMinutes:
          referralCampaign.senderValidityInMinutes / 1440,
        receiverValidityInMinutes:
          referralCampaign.senderValidityInMinutes / 1440,
        startDate: this.globalService.utcToLocalDate(
          referralCampaign.startDate,
        ),
        endDate: this.globalService.utcToLocalDate(referralCampaign.endDate),
      };
    }

    return {
      ...referralCampaign,
      senderPercentage:
        referralCampaign.senderAmountType === 'percentage'
          ? referralCampaign.senderPercentage / 100
          : 0,
      senderAmount:
        referralCampaign.senderAmountType === 'amount'
          ? referralCampaign.senderAmount
          : 0,
      countryId: referralCampaign.countryId || this.globalService.countryId,
      senderValidityInMinutes: referralCampaign.senderValidityInMinutes * 1440,
      receiverValidityInMinutes:
        referralCampaign.receiverValidityInMinutes * 1440,
      startDate: this.globalService.localToUtcDate(referralCampaign.startDate),
      endDate: this.globalService.localToUtcDate(
        `${referralCampaign.endDate?.split('T')[0]}T23:59:59`,
      ),
    } as ReferralCampaign;
  }
}
