import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../../shared/service/global.service';
import { CountryList } from '../../../../shared/models/countries/country';
import { CountryService } from '../../../../shared/service/country.service';

@Component({
  selector: 'app-countries-list',
  templateUrl: './countries-list.component.html',
  styleUrls: ['./countries-list.component.scss']
})
export class CountriesListComponent implements OnInit {
  countries: CountryList[];
  dataReady: boolean;
  showCreate: boolean;
  constructor(
    private countryService: CountryService,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.dataReady = false;
    this.showCreate = (localStorage.getItem('email') === 'superadminkw@justclean.com');
    this.countryService
      .getCountries(this.globalService.headers)
      .subscribe(response => {
        console.log(response);
        this.dataReady = true;
        this.countries = response.data;
      });
  }
}
