// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host::ng-deep .pagination {
  justify-content: center;
}

.personal-photo {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  margin-inline-end: 22px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/crud/components/generated-pages/generated-page-list/generated-page-list.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ;;AACA;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;AAEJ","sourcesContent":[":host::ng-deep .pagination {\n    justify-content: center;\n}\n.personal-photo {\n    width: 52px;\n    height: 52px;\n    border-radius: 100%;\n    margin-inline-end: 22px;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
