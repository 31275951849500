import { Component, OnInit } from '@angular/core';
import { SelectOptions, PageInfo } from 'src/app/shared/models/global/response';
import { LaundryService } from 'src/app/shared/service/laundry.service';
import { GlobalService } from 'src/app/shared/service/global.service';
import { LaundryFromList } from 'src/app/shared/models/laundries/laundry';
import { BranchesService } from 'src/app/shared/service/branches.service';
import { DevicesService } from 'src/app/shared/service/devices.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { DeviceFromList } from 'src/app/shared/models/laundries/device';
import { BranchList } from 'src/app/shared/models/laundries/branch';
import { ApiLibrary } from 'src/app/shared/helpers/http-params';

@Component({
  selector: 'app-list-auth-devices',
  templateUrl: './list-auth-devices.component.html',
  styleUrls: ['./list-auth-devices.component.css']
})
export class ListAuthDevicesComponent implements OnInit {
  laundries: SelectOptions<number>[];
  branches: SelectOptions<number>[];
  statuses: SelectOptions<boolean>[];
  devices: DeviceFromList[];
  search: string;
  selectedStatus: number;
  selectedLaundry: number;
  selectedBranch: number;
  pagination: PageInfo;
  constructor(
    private laundryService: LaundryService,
    private globalService: GlobalService,
    private branchService: BranchesService,
    private deviceService: DevicesService,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.statuses = [
      {
        value: true,
        label: 'Authorized'
      },
      {
        value: false,
        label: 'Unauthorized'
      }
    ];
    this.getDevices(1);
    this.getLaunderies();
  }

  getLaunderies() {
    this.laundryService
      .getLaundriesWithParams(this.globalService.headers, { page: 0 })
      .subscribe(response => {
        const laundry = response.data as LaundryFromList[];
        this.laundries = [];
        laundry.forEach(laundryElementt => {
          this.laundries.push({
            value: laundryElementt.id,
            label: laundryElementt.name
          });
        });
      });
  }

  getBranches(laundryId: number) {
    this.branchService
      .getBranchList(this.globalService.headers, laundryId)
      .subscribe(data => {
        if (data.code === 200) {
          const branches = data.data as BranchList[];
          this.branches = [];
          branches.forEach(branchElement => {
            this.branches.push({
              value: branchElement.id,
              label: branchElement.name
            });
          });
          this.filter();
        }
      });
  }

  getDevices(page) {
    let params = {
      page: page,
      is_authorized: this.selectedStatus,
      search: this.search,
      laundry_branch_id: this.selectedBranch,
      laundry_id: this.selectedLaundry
    };

    params = new ApiLibrary().cleanObjectFromComponent(params);
    if (params.search && params.search.length > 0) {
      delete params.page;
    }
    this.deviceService.getDevices(this.globalService.headers, params).subscribe(
      response => {
        if (response.code === 200) {
          this.devices = response.data;
          this.pagination = response.page_info;
        } else {
          this.toast.error(response.message);
        }
      }
    );
  }

  initFilters() {
    this.selectedBranch = null;
    this.selectedStatus = null;
    this.selectedLaundry = null;
    this.search = null;
    this.getDevices(1);
  }

  filter() {
    const page =
      this.pagination && this.pagination.current_page > 0
        ? this.pagination.current_page
        : 1;
    this.getDevices(page);
  }

  fetchBranchesAndFilter(event) {
    this.getBranches(event);
  }

  toggleStatus(id: number, status: boolean) {
    this.deviceService
      .toggleStatus(this.globalService.headers, id, status)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.getDevices(
              this.pagination.current_page !== 0
                ? this.pagination.current_page
                : 1
            );
          } else {
            this.toast.error(response.message);
            this.getDevices(
              this.pagination.current_page !== 0
                ? this.pagination.current_page
                : 1
            );
          }
        },
        error => {
          this.getDevices(
            this.pagination.current_page !== 0
              ? this.pagination.current_page
              : 1
          );
        }
      );
  }
}
