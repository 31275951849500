import { Status } from '@shared/enums/status.enum';
import { CompanyType } from '@shared/enums/company-type.enum';
import { CarwashBranchReservationOptions } from '@app/modules/carwash/modules/carwash-branch/shared/enums';
import { Nullable } from '@shared/models/nullable.model';
import { Name } from '@shared/models/name.model';

export interface PaymentMethod {
  createdAt: Date;
  deletedAt: Date | null;
  icon: string;
  id: number;
  integrationType: string;
  isHidden: boolean;
  isPosPaymentMethod: boolean;
  isUserPaymentMethod: boolean;
  name: Name;
  paymentGateway: string;
  sourceId: string;
  status: string;
  updatedAt: Date | null;
  checked?: boolean;
}

export interface CarwashBranchTimeSlot {
  createdAt: Date;
  cwGarageBranchId: number;
  deletedAt: Nullable<Date>;
  status: Status;
  subscriptionStatus: Status;
  timeSlotTimeId: number;
  updatedAt: Date;
}

export interface OpenHours {
  dayId: number;
  openTime: string;
  closeTime: string;
  isOpen: boolean;
}

export interface CarwashBranch {
  id?: number;
  companyName: Name;
  operatingName?: Name;
  branchAddress?: Name;
  managerName?: string;
  managerEmail?: string;
  managerMobile?: string;
  countryCode?: string;
  contactName?: string;
  contactEmail?: string;
  contactMobile?: string;
  currency?: string;
  lowestPrice?: number;
  highestPrice?: number;
  gmapsLink?: string;
  hasPosStatus:boolean;
  openTime?: string;
  closeTime?: string;
  noOfBranches?: number;
  latitude?: number;
  longitude?: number;
  discountBy?: string;
  ratingValue?: number;
  ratingCount?: number;
  trnName?: string;
  trnNumber?: string;
  tax?: number;
  status?: Status;
  type?: CompanyType.CarwashGarage;
  cwGarageId?: number;
  areaId?: number;
  countryId: number;
  createdById?: number;
  reservation: CarwashBranchReservationOptions;
  openingHours: Nullable<OpenHours>[];
  cwGarageBranchSSTD: CarwashBranchTimeSlot[];
  paymentMethods: PaymentMethod[];
}
