// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qr-code-img {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 12rem;
}

.custom-addons-container {
  width: 25%; /* Adjust the width as needed */
}`, "",{"version":3,"sources":["webpack://./src/app/modules/carwash/modules/carwash-order/components/carwash-order-details/carwash-order-details.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,OAAA;EACA,QAAA;EACA,YAAA;EACA,YAAA;AACJ;;AAEA;EACE,UAAA,EAAA,+BAAA;AACF","sourcesContent":[".qr-code-img {\n    position: absolute;\n    left: 0;\n    right: 0;\n    margin: auto;\n    width: 12rem;\n}\n\n.custom-addons-container {\n  width: 25%; /* Adjust the width as needed */\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
