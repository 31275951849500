import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ModalDirective } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html'
})
export class DeleteModalComponent implements OnChanges {
  @Input() title;
  @Input() toggleModal;
  @Input() loader;

  @Output() deleteData: EventEmitter<boolean> = new EventEmitter();
  @Output() closed: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.title && changes.title.currentValue) {
      this.title = changes.title.currentValue;
    }
    if (changes.toggleModal) {
      this.toggleModal = changes.toggleModal.currentValue;
      if (this.toggleModal) {
        this.deleteModal.show();
      } else {
        this.deleteModal.hide();
      }
    }
    if (changes.loader && changes.loader.currentValue) {
      this.loader = changes.loader.currentValue;
    }
  }

  delete() {
    this.deleteData.emit(true);
  }

  onHidden() {
    this.deleteModal.hide();
    this.closed.emit(false);
  }
}
