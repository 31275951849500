import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { KpiSetupComponent } from './components/kpi-setup/kpi-setup.component';
import { KpiAchievementComponent } from './components/kpi-achievement/kpi-achievement.component';


const routes: Routes = [
  {
    path: 'kpi-setup',
    component: KpiSetupComponent,
    data: {
      title: 'KPI Setup'
    }
  },
  {
    path: 'kpi-achievement/:id/:agentId',
    component: KpiAchievementComponent,
    data: {
      title: 'Achievements Setup'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SalesRoutingModule { }
