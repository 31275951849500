import { Translation } from 'libs/justclean-models/src/lib/justclean-models';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AdHttpService } from '@shared/service/ads/ads-http.service';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { Ad, Group } from '@shared/models';
import { PaginationHelper } from '@app/shared/helpers/pagination-helper';
import { PageInfo } from '@app/shared/models/global/response';

@Injectable({ providedIn: 'root' })
export class AdService {
  readonly pagination$: Subject<PageInfo> = new Subject<PageInfo>();
  branchKey: string;
  branchName: Translation;
  branchId: number;
  group: Group;

  constructor(private readonly adsHttpService: AdHttpService) {}

  getAds(
    filters?: Record<string, string | number | boolean>,
  ): Observable<Ad[]> {
    const toData = (res: ApiResponse<Ad[]>) => {
      return res.data;
    };

    const toPagination = (res: ApiResponse<Ad[]>) =>
      this.pagination$.next(PaginationHelper.camelToSnakeCase(res.pageInfo));

    return this.adsHttpService
      .getAds(filters)
      .pipe(tap(toPagination), map(toData));
  }

  createAd(data: Partial<Ad>): Observable<Ad> {
    const toAd = (res: ApiResponse<Ad>) => res.data;

    return this.adsHttpService.createAd(data).pipe(map(toAd));
  }

  updateAd(id: number, data: Partial<Ad>): Observable<Ad> {
    const toAd = (res: ApiResponse<Ad>) => res.data;

    return this.adsHttpService.updateAd(id, data).pipe(map(toAd));
  }

  deleteAd(id: number): Observable<ApiResponse<void>> {
    return this.adsHttpService.deleteAd(id);
  }
}
