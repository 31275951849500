import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContractListComponent } from './components/contract-list/contract-list.component';
import { ContractInfoComponent } from './components/contract-create/contract-info/contract-info.component';
import { ContractSettingsComponent } from './components/contract-create/contract-settings/contract-settings.component';
import { CorporateCustomerListComponent } from './components/corporate-customer-list/corporate-customer-list.component';
import { ScheduleOrderComponent } from './components/schedule-order/schedule-order.component';
import { OrdersListComponent } from './components/orders-list/orders-list.component';

const routes: Routes = [
  {
    path: 'contract-list',
    component: ContractListComponent,
    data: {
      title: 'List of Corp Contracts'
    }
  },
  {
    path: 'corp-customer-list',
    component: CorporateCustomerListComponent,
    data: {
      title: 'List of Corp Customers'
    }
  },
  {
    path: 'corp-customer-create',
    component: ContractInfoComponent,
    data: {
      title: 'Create Corp Customer'
    }
  },
  {
    path: 'contract-settings',
    component: ContractSettingsComponent,
    data: {
      title: 'Contract Settings'
    }
  },
  {
    path: 'schedule-order',
    component: ScheduleOrderComponent,
    data: {
      title: 'Schedule Corp Order'
    }
  },
  {
    path: 'contract-orders-list',
    component: OrdersListComponent,
    data: {
      title: 'List of Contract Orders'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CorporateRoutingModule {}
