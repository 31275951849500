import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/shared/service/global.service';
import { LogDriverService } from 'src/app/shared/service/log-driver.service';
import {
  DriverAreaToBeSaved,
  LogDriverAreas,
  LogDriverArea
} from 'src/app/shared/models/cars/log-driver';

@Component({
  selector: 'app-assign-area-driver',
  templateUrl: './assign-area-driver.component.html',
  styleUrls: ['./assign-area-driver.component.css']
})
export class AssignAreaDriverComponent implements OnInit {
  driverId: number;
  areas: LogDriverAreas[];
  list: LogDriverArea[] = [];
  selectAll = false;
  loader = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private driverLogService: LogDriverService,
  ) {}

  ngOnInit() {
    this.driverId = this.route.snapshot.queryParams.id;
    this.getAreas();
  }

  getAreas() {
    this.driverLogService
      .getDriversArea(this.globalService.headers, this.driverId)
      .subscribe(response => {
        if (response.code === 200) {
          this.areas = response.data as LogDriverAreas[];
          this.areas = this.areas.filter(
            area => area.country_id === this.globalService.countryId
          );
        }
      });
  }

  addToList(area: LogDriverArea) {
    if (!this.elementExists(area)) {
      this.list.push({
        area_id: area.area_id,
        is_selected: false
      });
    } else {
      this.deleteElemet(area);
    }
    console.log(this.list);
  }

  elementExists(area: LogDriverArea) {
    const found = this.list.find(element => element.area_id === area.area_id);
    return found ? true : false;
  }

  deleteElemet(area: LogDriverArea) {
    this.list.forEach((element, i) => {
      if (element.area_id === area.area_id) {
        this.list.splice(i, 1);
      }
    });
  }

  toggleArea(area: LogDriverArea) {
    area.is_selected = !area.is_selected;
  }

  toggleSelectAll() {
    this.selectAll = !this.selectAll;
    this.areas.forEach(ele => {
      ele.is_selected = this.selectAll ? true : false;
    });

    console.log('toggle', this.list);
  }

  checkSelected() {
    return this.areas && this.areas.find(e => e.is_selected === true)
      ? true
      : false;
  }

  convertAreas() {
    return this.areas
      .filter(e => e.is_selected)
      .map(e => {
        return {
          area_id: e.id as number,
          is_selected: e.is_selected
        };
      });
  }

  saveDriverArea() {
    this.loader = true;
    const driverArea = new DriverAreaToBeSaved();
    driverArea.driver_id = parseFloat(this.driverId.toString());
    driverArea.driverAreas = this.convertAreas();
    console.log(JSON.stringify(driverArea));

    this.driverLogService
      .saveDriverAreas(this.globalService.headers, driverArea)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.loader = false;
            this.router.navigate([
              'dashboard/logistics/drivers/logistics-driver'
            ]);
          }
        },
        error => {
          this.loader = false;
          this.router.navigate([
            'dashboard/logistics/drivers/logistics-driver'
          ]);
        }
      );
  }
}
