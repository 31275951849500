import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { HomeCleaningCompanyBranch } from '@app/modules/homeclean/modules/homeclean-branch/shared/models/home-cleaning-company-branch-model';
import { HomeCleaningCompanyBranchService } from '@app/modules/homeclean/modules/homeclean-branch/shared/services/home-cleaning-company-branch.service';

@Component({
  selector: 'app-home-cleaning-company-branch-list',
  templateUrl: './home-cleaning-company-branch-list.component.html',
  styleUrls: ['./home-cleaning-company-branch-list.component.css'],
})
export class HomeCleaningCompanyBranchListComponent implements OnInit {
  constructor(private readonly homeCleaningCompanyBranchService: HomeCleaningCompanyBranchService) {}

  branches$: Observable<HomeCleaningCompanyBranch[]>;
  createBranch = 'CARWASH.BRANCH.CREATE_BRANCH';
  noBranches = {
    text: 'CARWASH.BRANCH.NO_BRANCHES.TEXT',
  };
  table = {
    columns: [
      {
        title: '#',
      },
      {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.COLUMNS.BRANCH_ID',
      },
      {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.COLUMNS.BRANCH_NAME',
      },
      {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.COLUMNS.OPERATING',
      },
      {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.COLUMNS.BRANCH_MANAGER_NAME',
      },
      {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.COLUMNS.BRANCH_MANAGER_NUMBER',
      },
      {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.COLUMNS.LOWEST_PRICE',
      },
      {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.COLUMNS.HIGHEST_PRICE',
      },
      {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.COLUMNS.GOOGLE_LINK',
      },
      {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.COLUMNS.OPEN_TIME',
      },
      {
        title: 'CARWASH.BRANCH.TABLES.BRANCHES.COLUMNS.CLOSE_TIME',
      },
      {
        title: 'ACTIONS',
      },
    ],
  };

  ngOnInit(): void {
    this.branches$ = this.homeCleaningCompanyBranchService.getBranches();
  }
}
