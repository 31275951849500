import { Days } from '@shared/enums';
import { SelectOptions } from '@shared/models/global/response';
import { map, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { ChangeCompanyPriorityComponent } from './components/change-company-priority/change-company-priority.component';
import { DialogService } from '@app/shared/components/dialog/services/dialog.service';
import { AddCompanyPriorityComponent } from './components/add-company-priority/add-company-priority.component';
import { HomeCleaningBranchesService } from '@app/shared/service/home-cleaning/home-cleaning-branches.service';
import { HcPriorityChannelsService } from '@app/shared/service/home-cleaning/hc-priority-channels.service';
import { HcPriorityChannel } from '@app/shared/models';

@Component({
  selector: 'app-homeclean-priority-booking-view',
  templateUrl: './homeclean-priority-booking-view.component.html',
  styleUrls: ['./homeclean-priority-booking-view.component.scss'],
})
export class HomecleanPriorityBookingViewComponent
  implements OnInit, OnDestroy
{
  channels: HcPriorityChannel[];
  colors: string[] = ['g300', 'o300', 'r300'];
  branches: Array<
    SelectOptions<{ id: number; name: string; priority: number }>
  >;
  selectedBranches: {
    [hcPriorityChannelId: number]: Array<{ id: number }>;
  } = {};
  days: Array<SelectOptions<number>>;
  selectedDay: number = 0;
  search: string;

  private destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    private readonly hcPriorityChannelsService: HcPriorityChannelsService,
    private readonly homecleanBranchesService: HomeCleaningBranchesService,
    private readonly cd: ChangeDetectorRef,
    private readonly dialog: DialogService,
  ) {}

  ngOnInit(): void {
    this.setDays();
    this.loadChannels();
  }

  editCompanyPriority(
    hcPriorityChannelId: number,
    branch: { id: number; name: string },
  ): void {
    this.dialog
      .open(ChangeCompanyPriorityComponent, {
        hcPriorityChannelId,
        branch,
        channels: this.channels,
      })
      .afterClosed$.subscribe((selectedSection) => {
        if (selectedSection) {
          this.swapSectionsCompany(
            hcPriorityChannelId,
            +selectedSection,
            +branch.id,
          );
        }
      });
  }

  addBranches(hcPriorityChannelId: number): void {
    const otherSectionsBranches =
      this.getOtherSectionsBranches(hcPriorityChannelId);
    this.dialog
      .open(AddCompanyPriorityComponent, {
        hcPriorityChannelId,
        otherSectionsBranches,
        selectedDay: this.days[this.selectedDay - 1] || 'DEFAULT',
        channels: this.channels,
      })
      .afterClosed$.subscribe(
        (selectedBranches: { [branchId: number]: number }) => {
          const branchIDs = Object.keys(selectedBranches);
          branchIDs.forEach((branchId) => {
            this.swapSectionsCompany(
              selectedBranches[+branchId],
              hcPriorityChannelId,
              +branchId,
            );
          });
        },
      );
  }

  private swapSectionsCompany(
    fromSection: number,
    toSection: number,
    branchId: number,
  ): void {
    const branch = this.selectedBranches[fromSection].find(
      (el) => el.id === +branchId,
    );
    this.selectedBranches[toSection].push(branch);
    const index = this.selectedBranches[fromSection].indexOf(branch);
    this.selectedBranches[fromSection].splice(index, 1);
  }

  private getOtherSectionsBranches(hcPriorityChannelId: number): {
    [hcPriorityChannelId: number]: { id: number; name: string }[];
  } {
    const selectedBranches = JSON.parse(JSON.stringify(this.selectedBranches));
    selectedBranches[hcPriorityChannelId] = [];
    return selectedBranches;
  }

  private setDays(): void {
    this.days = [];

    this.days.push(
      ...Object.values(Days)
        .filter((value) => typeof value === 'string')
        .map((dayName: string) => ({
          value: Days[dayName],
          label: dayName,
        })),
    );
  }

  private loadChannels(): void {
    this.hcPriorityChannelsService
      .getChannels()
      .pipe(takeUntil(this.destroy$))
      .subscribe((channels) => {
        this.channels = channels;
        this.channels.forEach(({ id }) => (this.selectedBranches[id] = []));
        this.cd.detectChanges();
        this.loadBranches();
      });
  }

  private loadBranches(): void {
    const countryId = localStorage.getItem('country');
    this.homecleanBranchesService
      .getHCAllBranches({ countryId })
      .pipe(
        map((branches) =>
          branches.map((branch) => ({
            value: {
              id: branch.id,
              name: branch.companyName.en,
              priority: branch.hcPriorityChannelId,
            },
            label: branch.companyName.en,
          })),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((options) => {
        this.branches = options;
        this.channels.forEach((channel) => {
          this.selectedBranches[channel.id] = JSON.parse(
            JSON.stringify(
              this.branches
                .filter(({ value: { priority } }) => priority === channel.id)
                .map(({ value }) => value),
            ),
          );
          this.cd.detectChanges();
        });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
