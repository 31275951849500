import { Component, OnInit } from '@angular/core';
import {
  PageInfo,
  BackEndResponse
} from 'src/app/shared/models/global/response';
import { GlobalService } from 'src/app/shared/service/global.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { DataManager } from 'src/app/shared/helpers/data-manager';
import { DataExchangeWithBackend } from 'src/app/shared/models/user/generic-backend';
import { Subject } from 'rxjs';
import { PartnersService } from 'src/app/shared/service/partners.service';
import {
  PartnersFromList,
  PartnerToBeSaved
} from 'src/app/shared/models/cars/partners';
import { Router } from '@angular/router';

@Component({
  selector: 'app-partners-list',
  templateUrl: './partners-list.component.html'
})
export class PartnersListComponent implements OnInit {
  partners: PartnersFromList[];
  pagination: PageInfo;
  isShown: boolean;
  isCapacityModalShown: boolean;
  toggleDeleteModal = false;
  loader = false;
  partner: PartnerToBeSaved;
  isUpdate: boolean;
  refreshingCar = false;
  dataSubject: Subject<DataExchangeWithBackend> = new Subject();
  constructor(
    private globalService: GlobalService,
    private partnersService: PartnersService,
    private toast: ToastService,
    private router: Router
  ) {}

  ngOnInit() {
    const showPage =
      window.localStorage.getItem('showPartner') === 'true' ? true : false;
    console.log(showPage);

    if (showPage) {
      this.getPartners({ page: 1 });
      setInterval(() => {
        this.getPartners({
          page: this.pagination.current_page ? this.pagination.current_page : 1
        });
      }, 30000);
    } else {
      this.router.navigate(['dashboard/not-found']);
    }
  }

  getPartners(params?: any) {
    /* this.drivers = [];
    this.logisticsDriver
      .getAllDriverForLogistics(this.globalService.headers, params)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.drivers = response.data;
            this.pagination = response.page_info;
          }
        },
        error => {
          this.toast.error(error.error.message);
        }
      ); */
    DataManager.subscribeToAnObservable<BackEndResponse, any>(
      this.partnersService.getAllPartners(this.globalService.headers, params),
      this.partners,
      this.toast,
      false,
      this.dataSubject
    );
    this.dataSubject.subscribe(data => {
      this.partners = data.list;
      this.pagination = data.pagination;
    });
  }

  getPage(event) {
    this.getPartners({ page: event });
  }

  refreshData(isCreate: boolean) {
    this.getPage(1);
    this.refreshingCar = true;
  }

  showAreas(partner: PartnersFromList) {
    let html = '';
    partner.partners_area.forEach((e, i) => {
      html +=
        '<span class="green-blue-color">' +
        (i + 1) +
        '- ' +
        e.name +
        '</span><br>';
    });
    return partner.partners_area.length > 0
      ? html
      : '<span class="error-text"> Not Assigned to Any Area yet</span>';
  }

  triggerDelete(partner: PartnerToBeSaved) {
    this.partner = partner;
    this.toggleDeleteModal = true;
  }

  deletePartner(partner) {
    this.loader = true;
    this.partnersService
      .changeStatusPartner(this.globalService.headers, this.partner.id, {})
      .subscribe(
        response => {
          this.loader = false;
          this.toggleDeleteModal = false;
          this.refreshingCar = true;
          this.getPage(this.pagination.current_page);
        }
      );
  }

  isClosed(event) {
    this.isShown = false;
    this.isUpdate = false;
  }

  openDialog(isUpdate: boolean, partner?: PartnerToBeSaved) {
    this.isShown = true;
    this.isUpdate = isUpdate;
    this.partner = isUpdate ? partner : null;
  }

  openCapacityModal(partner: PartnerToBeSaved) {
    this.isCapacityModalShown = true;
    this.partner = partner;
  }

  capacityModalClosed(event) {
    this.isCapacityModalShown = false;
    this.getPage(this.pagination.current_page);
  }

  toggleStatus(partner: PartnersFromList) {
    this.refreshingCar = false;
    this.partnersService
      .changeStatusPartner(this.globalService.headers, partner.id, {
        status: partner.status === 'active' ? 'inactive' : 'active'
      })
      .subscribe(
        response => {
          if (response.code === 200) {
            this.getPage(this.pagination.current_page);
            this.refreshingCar = true;
            this.toast.success(response.message);
          } else {
            this.toast.error(response.message);
            this.getPage(this.pagination.current_page);
            this.toast.warning(response.message);
          }
        },
        error => {
          this.getPage(this.pagination.current_page);
        }
      );
  }

  fixNull(val: any): any {
    return this.globalService.fixNullValues(val);
  }

  isDeleteClosed(event) {
    this.toggleDeleteModal = false;
  }

  goToAssignAreas(partner: PartnersFromList) {
    this.partnersService
      .getPartnerAreas(this.globalService.headers, partner.id)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.router.navigate(
              ['dashboard/partners/partners-management/areas'],
              {
                queryParams: {
                  id: partner.id
                }
              }
            );
          }
        }
      );
  }
}
