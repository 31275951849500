import { Status } from '@shared/enums/status.enum';
import { GlobalService } from 'src/app/shared/service/global.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { DialogService } from '@shared/components/dialog/services/dialog.service';
import { CashbackCampaign } from '@shared/models';
import { PageInfo } from '@shared/models/global/response';
import { filter, takeUntil, finalize } from 'rxjs/operators';
import { CashbackCampaignService } from '@shared/service/cashback-campaign';
import { CashbackCampaignEditDialogComponent } from '../cashback-campaign-edit-dialog/cashback-campaign-edit-dialog.component';

@Component({
  selector: 'app-cashback-campaign-list',
  templateUrl: './cashback-campaign-list.component.html',
})
export class CashbackCampaignListComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>() ;
  pagination$: Observable<PageInfo>;
  cashbackCampaigns$: Observable<Array<CashbackCampaign>>;
  currency: string;
  STATUS = Status;
  currentPage: number = 1;

  private get countryId(): number {
    return +localStorage.getItem('country');
  }

  constructor(
    private readonly cashbackCampaignService: CashbackCampaignService,
    private readonly globalService: GlobalService,
    private readonly dialog: DialogService,
  ) {}

  ngOnInit(): void {
    this.loadCashbackCampaigns();
    this.currency = this.globalService.currency;
    this.pagination$ = this.cashbackCampaignService.pagination$;
  }

  openDialog(cashbackCampaign?: CashbackCampaign): void {
    this.dialog
      .open(CashbackCampaignEditDialogComponent, cashbackCampaign)
      .afterClosed$.pipe(takeUntil(this.destroy$), filter(Boolean))
      .subscribe(() => this.loadCashbackCampaigns());
  }

  loadCashbackCampaigns(page?: number): void {
    this.currentPage = page || this.currentPage;
    this.cashbackCampaigns$ = this.cashbackCampaignService.getCashbackCampaigns(
      {
        page: this.currentPage.toString(),
        countryId: this.countryId.toString(),
      },
    );
  }

  changeStatus(cashbackCampaign: CashbackCampaign) {
    cashbackCampaign['isUpdaing'] = true;
    const status =
      cashbackCampaign.status === Status.Active
        ? Status.Inactive
        : Status.Active;
    this.cashbackCampaignService
      .updateCashbackCampaign(cashbackCampaign.id, {
        ...cashbackCampaign,
        status,
      })
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          cashbackCampaign['isUpdaing'] = false;
        }),
      )
      .subscribe(
        () => (cashbackCampaign.status = status),
        () => this.loadCashbackCampaigns(),
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
