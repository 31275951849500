import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsMarketingTeamGuard } from '@app/shared/guards/is-marketing-team.guard';
import { AdListComponent } from './components/ad-list/ad-list.component';
import { MarketingAdListComponent } from './components/marketing-ad-list/marketing-ad-list.component';
import { SalesAdListComponent } from "@app/modules/ads/components/sales-ad-list/sales-ad-list.component";

const routes: Routes = [
  {
    path: 'list/:branchId',
    component: AdListComponent,
    data: { title: 'Ad listing' },
    pathMatch: 'full',
  },
  {
    path: 'marketing/list',
    component: MarketingAdListComponent,
    canActivate: [IsMarketingTeamGuard],
    data: { title: 'Post order ads' },
    pathMatch: 'full',
  },
  {
    path: 'sales/list',
    component: SalesAdListComponent,
    data: { title: 'Home ads' },
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdsRoutingModule {}
