export interface AreaTranslation {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
  areaId: number;
  area_id: number;
  language_id: number;
  languageId: number;
}

export interface Area {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  area_translations: AreaTranslation[];
}

export interface UserAddress {
  id: number;
  name: string;
  block: string;
  street: string;
  avenue: string;
  house_no: string;
  apartment_no: string;
  floor: string;
  latitude: number;
  longitude: number;
  phone_ext: string;
  phone: string;
  directions: string;
  pathway: string;
  neighbourhood: string;
  legacy_id: number;
  uid?: any;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  country_id: number;
  area_id: number;
  user_id: number;
  users_pos_id?: any;
  users_corporate_id?: any;
  usersCorporateId?: any;
  area: Area;
}

export interface UserOrder {
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  user_addresses: UserAddress[];
}

export class AreaOrder {
  id: number;
  name: string;
}

export class DeliveryAddress {
  id: number;
  name: string;
  block: string;
  street: string;
  avenue: string;
  house_no: string;
  apartment_no: string;
  floor: string;
  latitude: number;
  longitude: number;
  directions: string;
  country_id: number;
  area_id: number;
  user_id: number;
  area: AreaOrder;
  phone: string;
  phone_ext: string;
}

export class StatusesUserOrder {
  id: number;
  name: string;
  pending: boolean;
}

export class LaundryOrder {
  id: number;
  name: string;
}

export class LaundryBranchOrder {
  id: number;
  name: string;
  manager: string;
  laundry: LaundryOrder;
  is_logistics: boolean;
  is_partner: boolean;
  latitude: number;
  longitude: number;
}

export class OrderPayments {
  amount: number;
}

export class PaymentMethodOrder {
  id: number;
  name: string;
  order_payments: OrderPayments;
}

export class OrderResult {
  id: number;
  unique_id: string;
  item_count: number;
  item_merchandise_count: number;
  bill_amount_items: number;
  bill_amount_merchandise: number;
  bill_amount_total: number;
  bill_amount_remaining: number;
  is_paid: boolean;
  placed_at: Date;
  type: string;
  updated_at: Date;
  is_fast_service: boolean;
  is_tracking_enabled: boolean;
  is_closed: boolean;
  pickup_at: Date;
  delivery_at: Date;
  delivery_charge: number;
  service_charge: number;
  extra_charges: number;
  extra_discounts: number;
  special_instruction: string;
  basket_type_id: number;
  user: UserOrder;
  delivery_address: DeliveryAddress;
  statuses_user: StatusesUserOrder;
  order_tracking_details: OrderTrackingDetails[];
  laundry_branch: LaundryBranchOrder;
  payment_methods: PaymentMethodOrder[];
  amount_minimum_order: number;
  tax: number;
  total_delivery_charge: number;
  order_amount: number;
  is_assigned: boolean;
  is_customer_available: boolean;
  payment_to_laundry: number;
  price_per_route: number;
  waive: boolean;
  is_compensated: boolean;
  app_version: string;
  device_type: string;
}

export class BasketTypeOrder {
  id: number;
  name: string;
}

export class ItemOrder {
  id: number;
  name: string;
}

export class ServiceOrder {
  id: number;
  name: string;
}

export class OrderBasket {
  id: number;
  unit_price: number;
  quantity: number;
  total_price: number;
  item: ItemOrder;
  service: ServiceOrder;
}

export class OrderDetails {
  order_result: OrderResult;
  basket_type: BasketTypeOrder;
  order_basket: OrderBasket[];
  discounts: OrderDiscounts;
}

export class OrderTrackingDetails {
  id: number;
  start_time: Date;
  stop_time: Date;
  type: string;
  schedule_date: Date;
  driver?: JlmDriver;
  driver_answer?: DriverAnswer;
}

export class PendingOrders {
  id: number;
  unique_id: string;
  item_count: number;
  item_merchandise_count: number;
  bill_amount_merchandise: number;
  bill_amount_total: number;
  is_paid: boolean;
  placed_at: string;
  type: string;
  updated_at: string;
  is_fast_service: boolean;
  is_tracking_enabled: boolean;
  pickup_at: string;
  delivery_at: string;
  delivery_charge: number;
  service_charge: number;
  extra_charges: number;
  extra_discounts: number;
  special_instruction: string;
  laundry_branch: LaundryBranchOrder;
  user: UserOrder;
  statuses_user: StatusesUserOrderList;
  order_tracking_details: OrderTrackingDetails[];
  basket_type: BasketTypeOrder;
  late_hours: string;
  payment_methods: PaymentMethodOrder[];
  actionTime: string;
}

export class StatusesUserOrderList {
  id: number;
  name: string;
  pending_name?: any;
  status: string;
  color_late?: any;
}

export class ActiveOrders {
  id: number;
  unique_id: string;
  item_count: number;
  item_merchandise_count: number;
  merchandise_amount: number;
  bill_amount: number;
  is_paid: boolean;
  placed_at: string;
  type: string;
  updated_at: Date;
  is_fast_service: boolean;
  is_tracking_enabled: boolean;
  pickup_at: string;
  delivery_at: string;
  delivery_charge: number;
  service_charge: number;
  extra_charges: number;
  extra_discounts: number;
  special_instruction: string;
  laundry_branch: LaundryBranchOrder;
  user: UserOrder;
  statuses_user: StatusesUserOrder;
  jlm_order_details: JlmOrderDetails[];
  basket_type: BasketTypeOrder;
  payment_methods: PaymentMethodOrder[];
  order_tracking_details: OrderTrackingDetails[];
}

export class JlmDriver {
  id: number;
  first_name: string;
  last_name: string;
  mobile_no: string;
}

export class JlmOrderDetails {
  id: number;
  order_pickup_start: string;
  order_pickup_stop: string;
  order_delivery_start: string;
  order_delivery_stop: string;
  schedule_date: Date;
  jlm_driver: JlmDriver;
  driver_answer?: any;
}

export class OrderToBeRescheduled {
  order_id: number;
  timeslot_id: string;
  time_at: string;
  reason: string;
  type: string;
  is_fast: boolean;
  reason_id: number;
  sub_reason_id: number;
}

export class OrderLaundryChange {
  branch_id: number;
  order_id: number;
}

export class Item {
  id: number;
  service_id: number;
  quantity: number;
}

export class ItemToBeChangedInOrder {
  laundry_branch_id: number;
  order_id: number;
  user_admin_id: number;
  items: Item[];
}

export class DriverAnswer {
  id: number;
  answer: string;
}

export const STATUSES = [
  'Placed',
  'To Be Collected',
  'Out For PickUp',
  'Collected',
  'In progress',
  'Ready',
  'Out for Delivery',
  'Delivered',
  'Completed',
  'Canceled',
  'Test',
  'Duplicated',
  'Collected From Laundry',
  'Returned',
  'To Be Returned',
  'Pending Pickup',
];

export class OrderFilter {
  customerName: string;
  customerMobile: string;
  driverName: string;
  driverMobile: string;
  orderNumber: string;
  updatedAt: string;
  placed_at: string;
  searchstr: string;
  type: string = 'marketplace';
  is_logistics: boolean;
  pickup_at: string;
  delivery_at: string;
  start_time: string;
  stop_time: string;
  group_id = 1;
}

export class ApprovalSubmission {
  data?: string;
  approve: boolean;
  from_email?: boolean;
  order_id?: number;
  request_id?: number;
}

export class ApprovalResponse {
  id: number;
  reason: string;
  status: string;
  link_expired: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  country_id: number;
  group_id: number;
  order_id: number;
  status_from_id: number;
  status_to_id: number;
  user_admin_from_id: number;
}

export interface OrderDiscounts {
  discount_percentage: number;
  discount_amount: number;
  discount_type: string;
}

export interface Reason {
  id: number;
  reason: string;
  type: string;
  sub_reasons: SubReason[];
}

export interface SubReason {
  id: number;
  sub_reason: string;
}

export interface SelectedReason {
  reason: number;
  sub: number;
}

export interface WaiveBody {
  order_id: number;
  reason_id: number;
  sub_reason_id?: number;
  comment: string;
}

export class OrderToBeCompensated {
  order_id: number;
  compensate_amount: number;
  reason_id: number;
  compensation_type_id: number;
  promo_code: string;
  comment: string;
  discount_by_justclean: number;
  discount_by_laundry: number;
}

export interface OrderCashPayment {
  order_id: number;
  description: string;
  driver_id: number;
}
