import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { CampaignListComponent } from './components/campaign-list/campaign-list.component';
import { CampaignsRoutingModule } from './marketing-campaigns-routing.module';
import { CreateCashBackComponent } from './components/create-cash-back/create-cash-back.component';
import { CreateBogoComponent } from './components/create-bogo/create-bogo.component';

@NgModule({
  declarations: [CampaignListComponent, CreateCashBackComponent, CreateBogoComponent],
  imports: [CommonModule, SharedModule.forRoot(), CampaignsRoutingModule]
})
export class MarketingCampaignsModule {}
