export class DriverForCar {
  id: number;
  first_name: string;
  last_name: string;
}

export class LogisticCarsList {
  id: number;
  name: string;
  plate_no: string;
  device_id: string;
  is_active: boolean;
  driver: DriverForCar;
  laundries: string[];
}

export class LogisticsCar {
  private static instance?: LogisticsCar;
  id?: number;
  name: string;
  plate_no: string;
  device_id: string;

  static getInstance() {
    return this.instance ? this.instance : new LogisticsCar();
  }
}
