import { OffersRoutingModule } from './offers-routing.module';
import { SharedModule } from '../shared/shared.module';
import { OffersListComponent } from './components/offers-list/offers-list.component';
import { NgModule } from '@angular/core';
import { OfferCreateComponent } from './components/offer-create/offer-create.component';
import { CouponCreateComponent } from './components/coupon-create/coupon-create.component';
import { CouponListComponent } from './components/coupon-list/coupon-list.component';
import { CouponDetailsComponent } from './components/coupon-details/coupon-details.component';

@NgModule({
  imports: [OffersRoutingModule, SharedModule.forRoot()],
  declarations: [
    OffersListComponent,
    OfferCreateComponent,
    CouponCreateComponent,
    CouponListComponent,
    CouponDetailsComponent
  ]
})
export class OffersModule {}
