import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BackEndResponse } from '../models/global/response';
import { ApiLibrary } from '../helpers/http-params';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  readonly URL_SAVE = environment.serverUrl + 'users/add';
  readonly URL_UPDATE = environment.serverUrl + 'users/update/';
  readonly URL_DELETE = environment.serverUrl + 'users/delete/';
  readonly URL_USERS = environment.serverUrl + 'users';
  readonly GET_USER = environment.serverUrl + 'users/get-user/';
  readonly STATUS = environment.serverUrl + 'users/change-status/';
  readonly URL_User = environment.serverUrl + 'users/jlm-users';
  readonly URL_RESET_PASSWORD = environment.serverUrl + 'users/reset-user-password';
  constructor(private http: HttpClient) {}

  saveUser(headers, userToBeSaved) {
    return this.http.post<BackEndResponse>(this.URL_SAVE, userToBeSaved, {
      headers: this.setHeaders(headers)
    });
  }

  getUsers(headers, params?: any) {
    return this.http.get<BackEndResponse>(this.URL_USERS, {
      headers: this.setHeaders(headers),
      params: new HttpParams({ fromObject: params })
    });
  }

  getLaundryUsers(headers, params?: any) {
    if (params) {
      params = new ApiLibrary().cleanObject(params);
    }
    return this.http
      .get<BackEndResponse>(this.URL_User, {
        headers: this.setHeaders(headers),
        params: new HttpParams({ fromObject: params })
      })
      .pipe(tap(data => console.log(data)));
  }

  updateLaundryUser(headers, userId: number, user?: any) {
    return this.http
      .patch<BackEndResponse>(this.URL_User + '/' + userId, user, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  getUserByID(headers, id: number) {
    console.log(this.GET_USER + id);
    return this.http.get<BackEndResponse>(this.GET_USER + id, {
      headers: this.setHeaders(headers)
    });
  }

  updateUser(headers, userToBeSaved) {
    console.log(this.URL_UPDATE + userToBeSaved.id);
    return this.http.put<BackEndResponse>(
      this.URL_UPDATE + userToBeSaved.id,
      userToBeSaved,
      {
        headers: this.setHeaders(headers)
      }
    );
  }

  deleteUser(headers, id) {
    console.log(this.URL_DELETE + id);
    return this.http.post<any>(this.URL_DELETE + id, null, {
      headers: this.setHeaders(headers)
    });
  }

  changeStatus(headers, id, status: string) {
    return this.http.post<BackEndResponse>(
      this.STATUS + id + '/' + status,
      null,
      {
        headers: this.setHeaders(headers)
      }
    );
  }

  setHeaders(headers) {
    return new HttpHeaders(headers);
  }

  resetPassword(headers, user_id: number) {
    return this.http.post<any>(this.URL_RESET_PASSWORD, {
      user_id: user_id
    }, {
      headers: this.setHeaders(headers)
    });
  }

}
