import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  QuestionsForAssignDriver,
  OrderAssignmentBody
} from 'src/app/shared/models/cars/partners';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { LogDriverService } from 'src/app/shared/service/log-driver.service';
import { GlobalService } from 'src/app/shared/service/global.service';
import { ApiLibrary } from 'src/app/shared/helpers/http-params';

@Component({
  selector: 'app-questions-modal',
  templateUrl: './questions-modal.component.html',
  styleUrls: ['./questions-modal.component.css']
})
export class QuestionsModalComponent implements OnChanges {
  @Input() answers: QuestionsForAssignDriver[];
  @Input() isOpened: boolean;
  @Input() isTransfer: boolean;
  @Input() assignmentObject: OrderAssignmentBody;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('answersModal', { static: true }) modal: ModalDirective;
  currentAnswer: QuestionsForAssignDriver;
  loader: boolean;
  orderId: number;
  constructor(
    private logDriverService: LogDriverService,
    private globalService: GlobalService,
    private toast: ToastService
  ) {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.isOpened && changes.isOpened.currentValue === true) {
      this.modal.show();
    } else {
      this.modal.hide();
    }
  }

  onClose(event) {
    this.closeModal.emit(event);
  }

  saveData() {
    this.loader = true;
    if (!this.isTransfer) {
      this.assignmentObject.id = null;
      this.assignmentObject.is_partner = null;
    }
    this.logDriverService
      .assignReassignOrder(
        this.globalService.headers,
        new ApiLibrary().cleanObjectFromComponent(this.assignmentObject)
      )
      .subscribe(
        response => {
          if (response.code === 200) {
            this.onClose(true);
          } else {
            this.toast.error(response.message);
          }
          this.loader = false;
        },
        error => {
          this.loader = false;
        }
      );
  }
}
