import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { DialogService } from '@shared/components/dialog/services/dialog.service';
import { Group } from '@shared/models';
import { Status } from '@shared/enums/status.enum';
import { PageInfo } from '@shared/models/global/apiResponse';
import { filter } from 'rxjs/operators';
import { GroupService } from '@shared/service/group';
import { GroupEditDialogComponent } from '../group-edit-dialog/group-edit-dialog.component';
import { AssignCountryDialogComponent } from '../assign-country-dialog/assign-country-dialog.component';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
})
export class GroupListComponent implements OnInit {
  readonly Status = Status;
  pagination$: Observable<PageInfo>;
  groups$: Observable<Array<Group>>;

  constructor(
    private readonly groupService: GroupService,
    private readonly dialog: DialogService,
  ) {}

  ngOnInit(): void {
    this.loadGroups();
    this.pagination$ = this.groupService.pagination$;
  }

  openDialog(group: Group = null): void {
    this.dialog
      .open(GroupEditDialogComponent, group)
      .afterClosed$.pipe(filter(Boolean))
      .subscribe(() => this.loadGroups(true));
  }

  openAssignCountryDialog(group: Group = null): void {
    this.dialog
      .open(AssignCountryDialogComponent, group.id)
      .afterClosed$.pipe(filter(Boolean))
      .subscribe(() => this.loadGroups());
  }

  private loadGroups(forceLoad?: boolean): void {
    this.groups$ = this.groupService.getGroups(null, forceLoad);
  }
}
