import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BackEndResponse } from '../models/global/response';
import {
  OrderToBeRescheduled,
  OrderLaundryChange,
  ItemToBeChangedInOrder,
  ApprovalSubmission,
  WaiveBody,
  OrderToBeCompensated,
  OrderCashPayment
} from '../models/orders/order';
import { ApiLibrary } from '../helpers/http-params';
import { OrderApproval } from '../models/offers/offers';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  readonly URL_CHANGE_ORDER_STATUS =
    environment.serverUrl + 'orders/change-status';
  readonly URL_GET_ORDER_DETAILS =
    environment.serverUrl + 'orders/order-detail/';
  readonly URL_GET_PENDING_ACTION_ORDER = environment.serverUrl + 'orders/list';
  readonly URL_GET_ACTIVE_ACTION_ORDER =
    environment.serverUrl + 'orders/active-list';
  readonly URL_RESCHEDULE_ORDER =
    environment.serverUrl + 'orders/reschedule-order';
  readonly URL_CHANHGE_LAUNDRY_ORDER =
    environment.serverUrl + 'orders/change-laundry';
  readonly URL_ADD_ITEM_ORDER = environment.serverUrl + 'orders/add-item-order';
  readonly URL_ALL_ORDERS = environment.serverUrl + 'orders/all-order';
  readonly URL_ALL_LOG_ORDERS = environment.serverUrl + 'logistics/order';
  readonly URL_ORDER_APPROVAL =
    environment.serverUrl + 'orders/request-status-change';
  readonly URL_COMMISSION_APPROVAL =
    environment.serverUrl + 'orders/response-partner-commission';
  readonly URL_ORDER_APPROVAL_ACTION =
    environment.serverUrl + 'orders/request-status-action';
  readonly URL_REQUEST_COMMISSION =
    environment.serverUrl + 'orders/request-partner-commission';
  readonly URL_REASONS =
    environment.serverUrl + 'orders/reasons';
  readonly URL_ORDER_COMPENSATION =
    environment.serverUrl + 'laundry/compensation';
  readonly URL_ORDER_COMPENSATION_TYPE =
    environment.serverUrl + 'laundry/compensation-type';
  readonly URL_PAY_CASH =
    environment.serverUrl + 'orders/update-order-waive';
  readonly URL_CANCEL_ORDER_REQUEST =
    environment.serverUrl + 'orders/cancellation-requests';
  readonly URL_ORDERS = environment.jcDeliveryServer +  '/orders';

  constructor(protected http: HttpClient) {}

  getOrderDetails(headers, orderId: any) {
    return this.http
      .get<BackEndResponse>(this.URL_GET_ORDER_DETAILS + orderId, {
        headers: new HttpHeaders(headers)
      })
  }

  getOrdersList(headers, params?: any) {
    const pms = new ApiLibrary().cleanObject(params);
    console.log(pms);
    return this.http
      .get<BackEndResponse>(this.URL_GET_PENDING_ACTION_ORDER, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: pms })
      })
  }
  /** params: ?searchstr=se&laundry_id=1&branch_id=1&payment_type=false
   * &market=false&corporate=false&jcpremium=false&order_status=1&page=1
   * */
  getActiveOrdersList(headers, params?: any) {
    const pms = new ApiLibrary().cleanObject(params);
    return this.http
      .get<BackEndResponse>(this.URL_GET_ACTIVE_ACTION_ORDER, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: pms })
      })
  }
  /** page=1&order_status=2&payment_type=false&searchstr=test&order_type=pending
   * */
  getAllOrrders(headers, params?: any) {
    const pms = new ApiLibrary().cleanObjectFromComponent(params);
    return this.http
      .get<BackEndResponse>(this.URL_ALL_ORDERS, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: pms })
      })
  }

  getAllLogOrders(headers, params?: any) {
    const pms = new ApiLibrary().cleanObject(params);
    return this.http
      .get<BackEndResponse>(this.URL_ALL_LOG_ORDERS, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: pms })
      })
  }

  rescheduleOrder(headers, order: OrderToBeRescheduled) {
    return this.http
      .put<BackEndResponse>(this.URL_RESCHEDULE_ORDER, order, {
        headers: new HttpHeaders(headers)
      })
  }

  changeLaundry(headers, laundryOrder: OrderLaundryChange) {
    return this.http
      .put<BackEndResponse>(this.URL_CHANHGE_LAUNDRY_ORDER, laundryOrder, {
        headers: new HttpHeaders(headers)
      })
  }

  addItemToOrder(headers, item: ItemToBeChangedInOrder) {
    return this.http
      .post<BackEndResponse>(this.URL_ADD_ITEM_ORDER, item, {
        headers: new HttpHeaders(headers)
      })
  }

  changeStatus(headers, orderId: any, statusId: number) {
    const requestBody = {
      order_id: orderId,
      order_status_id: statusId
    };
    return this.http
      .patch<BackEndResponse>(this.URL_CHANGE_ORDER_STATUS, requestBody, {
        headers: new HttpHeaders(headers)
      })
  }

  requestApproval(headers, requestApproval: OrderApproval) {
    return this.http
      .post<BackEndResponse>(this.URL_ORDER_APPROVAL, requestApproval, {
        headers: new HttpHeaders(headers)
      })
  }

  answerApprovalRequest(headers, approvalSubmission: ApprovalSubmission) {
    return this.http
      .post<BackEndResponse>(
        this.URL_ORDER_APPROVAL_ACTION,
        approvalSubmission,
        {
          headers: new HttpHeaders(headers)
        }
      )
  }

  requestCommission(
    headers,
    approvalSubmission: {
      order_id: number;
      reason: string;
    }
  ) {
    return this.http
      .post<BackEndResponse>(this.URL_REQUEST_COMMISSION, approvalSubmission, {
        headers: new HttpHeaders(headers)
      })
  }

  responseCommission(headers, approvalSubmission: ApprovalSubmission) {
    return this.http
      .post<BackEndResponse>(this.URL_COMMISSION_APPROVAL, approvalSubmission, {
        headers: new HttpHeaders(headers)
      })
  }

  // reasons

  getAllReasons(headers, params?: any) {
    const pms = new ApiLibrary().cleanObject(params);
    return this.http
      .get<BackEndResponse>(this.URL_REASONS, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: pms })
      })
  }

  // waive order

  waiveOrder(headers, body: WaiveBody) {
    return this.http
      .post<BackEndResponse>(this.URL_REASONS, body, {
        headers: new HttpHeaders(headers)
      })
  }

  // compensation
  saveCompensation(headers, body: OrderToBeCompensated) {
    return this.http
      .post<BackEndResponse>(this.URL_ORDER_COMPENSATION, body, {
        headers: new HttpHeaders(headers)
      })
  }

  getCompensationTypes(headers) {
    return this.http
      .get<BackEndResponse>(this.URL_ORDER_COMPENSATION_TYPE, {
        headers: new HttpHeaders(headers)
      })
  }

  // pay cash
  orderPaidCash(headers, body: OrderCashPayment) {
    return this.http
      .post<BackEndResponse>(this.URL_PAY_CASH, body, {
        headers: new HttpHeaders(headers)
      })
  }

  getCancelOrderRequests(headers, params?: any) {
    console.log(params);

    const pms = new ApiLibrary().cleanObject(params);
    return this.http.get<BackEndResponse>(this.URL_CANCEL_ORDER_REQUEST, {
      headers: new HttpHeaders(headers),
      params: new HttpParams({ fromObject: pms }),
    });
  }


  getOrderLogs(headers, orderId: string, pageNo: number) {
    let params = new HttpParams()
      .set('pageNo', pageNo.toString());

    return this.http
      .get<BackEndResponse>(`${this.URL_ORDERS}/${orderId}/logs`, {
        headers: new HttpHeaders(headers),
        params: params
      })
      .pipe(
        map((response) => response.data),
      );
  }
}
