import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";

import { OrderDetailsItemSize } from '@app/modules/carwash/modules/carwash-order/components/carwash-order-details/shared/enums/order-details-item-size.enum';
import { CarwashOrderService } from '@app/modules/carwash/modules/carwash-order/components/shared/services/carwash-order.service';
import { ActivatedRoute } from '@angular/router';
import { CarwashOrder, CwgBranchTicketAddon } from "@app/modules/carwash/modules/carwash-order/components/shared/models/carwash-order.model";
import { GlobalService } from "@shared/service/global.service";
import { catchError, map, switchMap } from "rxjs/operators";
import {
  CwCancelOrderDialogComponent
} from "@app/modules/carwash/modules/carwash-order/components/cw-cancel-order-dialog/cw-cancel-order-dialog.component";
import { DialogService } from "@shared/components/dialog/services/dialog.service";
import { GroupService } from "@shared/service/group";
import { UniqueIdHelper } from '@shared/helpers/unique-id.helper';

@Component({
  selector: 'app-carwash-order-details',
  templateUrl: './carwash-order-details.component.html',
  styleUrls: ['./carwash-order-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarwashOrderDetailsComponent implements OnInit {
  id: number;
  order$: Observable<CarwashOrder>;
  addons$: Observable<CwgBranchTicketAddon[]>;
  isLoadingAddons$: Observable<boolean>;
  isMobileType: boolean;
  currency: string;
  private isOutCallSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  isOutCall$: Observable<boolean> = this.isOutCallSubject.asObservable();

  constructor(
    private readonly carwashOrderService: CarwashOrderService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly globalService: GlobalService,
    private readonly groupService: GroupService,
    private readonly dialog: DialogService,
    private readonly cd: ChangeDetectorRef,
  ) {}
  readonly OrderDetailsItemSize = OrderDetailsItemSize;

  ngOnInit(): void {
    const { id } = this.activatedRoute.snapshot.params;
    this.id = Number(id);
    this.initVariables();
  }
  refresh(): void {
    // Fetch the addons data once
    if (!this.addons$) {
      this.addons$ = this.carwashOrderService.getCwgBranchTicketAddons({
        ordersCwGarageId: this.id,
      });
    }

    // Fetch the order data and merge it with addons using switchMap
    this.order$ = this.carwashOrderService.getOrderById(this.id).pipe(
      switchMap((order) => {
        return this.addons$.pipe(
          catchError(() => of([])),
          map((addons) => {
            // Assign addons to the order
            this.groupService.setSelectedGroup(order.groupId);
            this.setOutCall(this.groupService.checkOutCall());
            order.cwGarageBranchTickets.forEach((ticket) => {
              ticket.cwgBranchTicketAddons = addons.filter(
                (addon) => addon.cwGarageBranchTicketId === ticket.id,
              );
            });
            return order;
          }),
        );
      }),
    );

    this.cd.detectChanges();
  }

  private initVariables(): void {
    this.currency = this.globalService.getCurrency();
    this.isMobileType = !!this.activatedRoute.snapshot.queryParams.isMobileType;
    this.refresh();
  }

  getPaymentMethods(order: CarwashOrder): string {
    if (order.userTransactions) {
      return order.userTransactions.reduce(
        (result, el) =>
          !el.deletedAt
            ? `${result ? `${result}, ` : ''}${el.paymentMethod.name.en} (${
                el.amount
              })`
            : result,
        '',
      );
    }

    return '-';
  }

  getPurchaseDate(date: Date): string {
    return this.globalService.formatDate(String(date), 'ddd DD MMMM YYYY, LT');
  }

  getProgressWidth(statusCwGarageBranchTicketId: number): string {
    // Calculate the progress width based on the status
    if (statusCwGarageBranchTicketId === 1) {
      return '11.5%';
    } else if (statusCwGarageBranchTicketId === 2) {
      return '100%';
    } else if (statusCwGarageBranchTicketId === 3) {
      return '100%';
    } else if (statusCwGarageBranchTicketId === 4) {
      return '11.5%';
    } else if (statusCwGarageBranchTicketId === 5) {
      return '11.5%';
    } else if (statusCwGarageBranchTicketId === 6) {
      return '35%';
    } else if (statusCwGarageBranchTicketId === 7) {
      return '62.5%';
    } else if (statusCwGarageBranchTicketId === 8) {
      return '100%';
    } else if (statusCwGarageBranchTicketId === 9) {
      return '100%';
    } else {
      return '0%'; // Default or other values
    }
  }

  getStatusName(statusCwGarageBranchTicketId: number): string {
    // Calculate the progress width based on the status
    if (statusCwGarageBranchTicketId === 1) {
      return 'Ticket Valid';
    } else if (statusCwGarageBranchTicketId === 2) {
      return 'Ticket Expired';
    } else if (statusCwGarageBranchTicketId === 3) {
      return 'Ticket Redeemed';
    } else if (statusCwGarageBranchTicketId === 4) {
      return 'status ticket';
    } else if (statusCwGarageBranchTicketId === 5) {
      return 'Order Placed';
    } else if (statusCwGarageBranchTicketId === 6) {
      return 'On the Way';
    } else if (statusCwGarageBranchTicketId === 7) {
      return 'Washing Started';
    } else if (statusCwGarageBranchTicketId === 8) {
      return 'Washing Finished';
    } else if (statusCwGarageBranchTicketId === 9) {
      return 'Wash Canceled';
    }
  }

  getStatusTextColor(desiredStatus: number, currentStatus: number): string {
    // Calculate the text color based on the desired status and the current status
    if (currentStatus === desiredStatus) {
      return '#641bb4'; // Purple color for the desired status
    } else {
      return 'black'; // Default or other text color
    }
  }

  openCancellationModal(order: CarwashOrder, ticket): void {
    this.dialog.open(CwCancelOrderDialogComponent, {
      order: order,
      ticket: ticket,
      callbackFn: () => this.refresh(),
    });
  }

  setOutCall(value: boolean): void {
    this.isOutCallSubject.next(value);
  }

  getGroupPrefix(groupId: number){
    return UniqueIdHelper.getPrefix(groupId);
  }
}
