import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ItemCreateComponent } from './components/item-create/item-create.component';
import { ItemsListComponent } from './components/items-list/items-list.component';
import { CategoryCreateComponent } from './components/category-create/category-create.component';
import { CategoryListComponent } from './components/category-list/category-list.component';

const routes: Routes = [
  {
    path: 'item-create/:id',
    component: ItemCreateComponent,
    data: {
      title: 'Create Items'
    }
  },
  {
    path: 'item-list',
    component: ItemsListComponent,
    data: {
      title: 'List of Items'
    }
  },
  {
    path: 'category-create/:id',
    component: CategoryCreateComponent,
    data: {
      title: 'Create Category'
    }
  },
  {
    path: 'category-list',
    component: CategoryListComponent,
    data: {
      title: 'List of Categories'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ItemsRoutingModule {}
