import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CorporateRoutingModule } from './corporate-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ContractListComponent } from './components/contract-list/contract-list.component';
import { ContractCreateComponent } from './components/contract-create/contract-create.component';
import { ContractInfoComponent } from './components/contract-create/contract-info/contract-info.component';
import { ContractSettingsComponent } from './components/contract-create/contract-settings/contract-settings.component';
import { CorporateCustomerListComponent } from './components/corporate-customer-list/corporate-customer-list.component';
import { ScheduleOrderComponent } from './components/schedule-order/schedule-order.component';
import { OrdersListComponent } from './components/orders-list/orders-list.component';

@NgModule({
  declarations: [
    ContractListComponent,
    ContractCreateComponent,
    ContractInfoComponent,
    ContractSettingsComponent,
    CorporateCustomerListComponent,
    ScheduleOrderComponent,
    OrdersListComponent
  ],
  imports: [CommonModule, CorporateRoutingModule, SharedModule.forRoot()]
})
export class CorporateModule {}
