import {
  Component,
  Input,
  SimpleChanges,
  OnChanges,
  Output,
  ViewChild,
  EventEmitter,
  OnInit
} from '@angular/core';
import { GlobalService } from '../../../../../shared/service/global.service';
import { OrderService } from '../../../../../shared/service/order.service';
import { BranchesService } from '../../../../../shared/service/branches.service';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { OrderDetails } from '../../../../../shared/models/orders/order';

@Component({
  selector: 'app-change-laundry',
  templateUrl: './change-laundry.component.html',
  styleUrls: ['./change-laundry.component.css']
})
export class ChangeLaundryComponent implements OnChanges, OnInit {
  @Input()
  showDialog: boolean;
  @Input()
  order: OrderDetails;
  @Output()
  done: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('changeLaundry', { static: true })
  modal: ModalDirective;
  branches: any[];
  selectedBranch: number;
  loader = false;

  constructor(
    protected globalService: GlobalService,
    protected orderService: OrderService,
    protected branchService: BranchesService,
    protected toast: ToastService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    console.log(this.order);
    if (this.showDialog) {
      this.modal.show();
    }
    this.getbranches();
  }

  ngOnInit() {}

  getbranches() {
    if (this.order) {
      this.branchService
        .getBranchListForAssignNewLaundry(this.globalService.headers, {
          order_id: this.order.order_result.id,
          area_id: this.order.order_result.delivery_address.area.id
        })
        .subscribe(response => {
          this.branches = [];
          response.data.forEach(element => {
            this.branches.push({
              value: element.id,
              label: element.name
            });
          });
        });
    }
  }

  onClose() {
    this.done.emit(false);
  }

  saveChanges() {
    this.loader = true;
    this.orderService
      .changeLaundry(this.globalService.headers, {
        branch_id: this.selectedBranch,
        order_id: this.order.order_result.id
      })
      .subscribe(
        response => {
          this.loader = false;
          this.modal.hide();
          this.done.emit(true);
        },
        error => {
          this.loader = false;
        }
      );
  }

  timeSlotChanged(event) {}

  validateForm() {
    return this.selectedBranch;
  }
}
