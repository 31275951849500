import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomecleanStartTimeComponent } from '@app/modules/homeclean/modules/homeclean-start-time/components/homeclean-start-time/homeclean-start-time.component';

const routes: Routes = [
  {
    path: 'start-time',
    component: HomecleanStartTimeComponent,
    data: { title: 'Home cleaning start times' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomecleanStartTimeRoutingModule {}
