import { AbstractControl, Validators } from '@angular/forms';

const getEmailPattern = () => {
  return (
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))' +
    '@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
  );
};

export const emailValidator = (control: AbstractControl): null | Validators => {
  const reg = new RegExp(getEmailPattern()).test(control.value);
  if (!reg && control.value?.length) {
    return { email: '' };
  }
  return null;
};
