import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BackEndResponse } from '../models/global/response';
import { tap } from 'rxjs/operators';
import {
  ContractToBeSaved,
  CorporateClientToBeSaved,
  CorporateOrderData
} from '../models/laundries/corporate';
import { ApiLibrary } from '../helpers/http-params';

@Injectable({
  providedIn: 'root'
})
export class CorporateService {
  readonly URL_USERS = environment.serverUrl + 'users/corporate-users';
  readonly URL_CONTRACTS = environment.serverUrl + 'corporate/corporate';
  readonly URL_CONTRACTS_TOGGLE =
    environment.serverUrl + 'corporate/activate-contract';
  readonly URL_CUSTOMERS = environment.serverUrl + 'corporate/user';
  readonly URL_SCHEDULE = environment.serverUrl + 'corporate/schedule-order';

  constructor(private http: HttpClient) {}

  getCorporates(headers) {
    return this.http
      .get<BackEndResponse>(this.URL_USERS, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  getContracts(headers, id: number, params?: any) {
    const pms = new ApiLibrary().cleanObject(params);
    return this.http
      .get<BackEndResponse>(this.URL_CONTRACTS + '/' + id, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: pms })
      })
      .pipe(tap(data => console.log(data)));
  }

  getCorpCustomers(headers, params?: any) {
    const pms = new ApiLibrary().cleanObject(params);
    return this.http
      .get<BackEndResponse>(this.URL_CUSTOMERS, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: pms })
      })
      .pipe(tap(data => console.log(data)));
  }

  createCorpCustomer(headers, client: CorporateClientToBeSaved) {
    return this.http
      .post<BackEndResponse>(this.URL_CUSTOMERS, client, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  createUpdateContract(headers, contract: ContractToBeSaved) {
    return this.http
      .post<BackEndResponse>(this.URL_CONTRACTS, contract, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  toggleStatus(
    headers,
    body: {
      contract_id: number;
      status: string;
    }
  ) {
    return this.http
      .post<BackEndResponse>(this.URL_CONTRACTS_TOGGLE, body, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  scheduleOrder(headers, orderData: CorporateOrderData) {
    return this.http
      .post<BackEndResponse>(this.URL_SCHEDULE, orderData, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  getScheduledOrderForContract(headers, contractId: number) {
    return this.http
      .get<BackEndResponse>(this.URL_SCHEDULE + '/' + contractId, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }
}
