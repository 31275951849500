// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-row {
  font-weight: bold;
}

.file-field {
  margin-bottom: 25px;
}

form {
  min-width: 760px;
}

:host::ng-deep .mdb-select-outline .mdb-select-value {
  padding: 0.375rem 0.75rem;
}

.success-switch {
  position: relative;
}

.custom-btn {
  height: 1rem;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 25px;
}

:host::ng-deep .sub-variants-container .company-file {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 25px;
}
:host::ng-deep .sub-variants-container .company-file .company-file__image-area {
  width: 50px !important;
  height: 55px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/variant/components/variant-edit-dialog/variant-form/variant-form.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAGE;EACE,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,mBAAA;AAAJ;AACI;EACE,sBAAA;EACA,uBAAA;AACN","sourcesContent":[".title-row {\n  font-weight: bold;\n}\n\n.file-field {\n  margin-bottom: 25px;\n}\n\nform {\n  min-width: 760px;\n}\n\n:host::ng-deep .mdb-select-outline .mdb-select-value {\n  padding: 0.375rem 0.75rem;\n}\n\n.success-switch {\n  position: relative;\n}\n\n.custom-btn {\n  height: 1rem;\n  padding: 2px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin: auto;\n  margin-bottom: 25px;\n}\n\n:host::ng-deep .sub-variants-container {\n  .company-file {\n    display: flex;\n    align-items: center;\n    justify-content: space-around;\n    margin-bottom: 25px;\n    .company-file__image-area {\n      width: 50px !important;\n      height: 55px !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
