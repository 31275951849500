import { concat, Observable } from 'rxjs';
import { HcBranch, HcPriorityChannel } from '@app/shared/models';
import { Component, Inject, OnInit } from '@angular/core';
import { DialogRef } from '@app/shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@app/shared/components/dialog/services/dialog.token';
import { HomeCleaningBranchesService } from '@app/shared/service/home-cleaning/home-cleaning-branches.service';
import { toArray, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-add-company-priority',
  templateUrl: './add-company-priority.component.html',
  styleUrls: ['./add-company-priority.component.scss'],
})
export class AddCompanyPriorityComponent implements OnInit {
  isSaving: boolean;
  selectedBranches: { [branchId: number]: number } = {};
  selectedBranchesLength = 0;
  search: String;
  minTime: number;

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: {
      hcPriorityChannelId: number;
      otherSectionsBranches: {
        [hcPriorityChannelId: number]: {
          id: number;
          name: string;
        }[];
      };
      selectedDay: string;
      channels: HcPriorityChannel[];
    },
    private readonly dialogRef: DialogRef<AddCompanyPriorityComponent>,
    private readonly homecleanBranchesService: HomeCleaningBranchesService,
  ) {}

  ngOnInit(): void {
    this.minTime = this.data.channels.find(
      ({ id }) => id === this.data.hcPriorityChannelId,
    )?.minTime;
  }

  close(submit?: boolean): void {
    this.dialogRef.close(submit ? this.selectedBranches : []);
  }

  selectBranch(branchId: number, hcPriorityChannelId: number): void {
    if (this.selectedBranches[branchId] !== undefined) {
      delete this.selectedBranches[branchId];
      this.selectedBranchesLength--;
    } else {
      this.selectedBranches[branchId] = hcPriorityChannelId;
      this.selectedBranchesLength++;
    }
  }

  save(): void {
    this.isSaving = true;

    const subscribers$ = this.getSubscribers$();

    if (subscribers$.length) {
      /** calling observables sequentially */
      concat(...subscribers$)
        .pipe(
          toArray(),
          finalize(() => (this.isSaving = false)),
        )
        .subscribe(() => this.close(true));
    } else {
      this.close();
    }
  }

  private getSubscribers$(): Observable<HcBranch>[] {
    return Object.keys(this.selectedBranches).map((branchId) => {
      return this.homecleanBranchesService.updateHcBranch(+branchId, {
        hcPriorityChannelId: this.data.hcPriorityChannelId,
      });
    });
  }
}
