import { gql } from 'graphql-request';

export const GET_ORDER_MAP_QUERY = (
  startDate: string,
  endDate: string,
  isScheduledToday: boolean,
) => gql`
  query {
    getOrderMapData(
      endDate: "${endDate}"
      startDate: "${startDate}"
      isScheduledToday: ${isScheduledToday}
    ) {
      currency
      latitude
      longitude
      id
      branchName
      currencyDecimal
      updateType
      type
      groupId
      amount
      countryId
      firstName
      lastName
      zoneName
    }
  }
`;
