import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListSupplierComponent } from './components/list-supplier/list-supplier.component';
import { AssignAreaSupplierComponent } from './components/assign-area-supplier/assign-area-supplier.component';

const routes: Routes = [
  {
    path: 'supplier-list',
    component: ListSupplierComponent,
    data: {
      title: 'List of Logistics Suppliers'
    }
  },
  {
    path: 'supplier-areas',
    component: AssignAreaSupplierComponent,
    data: {
      title: 'Assign Area Logistics Suppliers'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SupplierRoutingModule {}
