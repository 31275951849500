import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RolesService } from '../../../../shared/service/roles.service';
import { UsersService } from '../../../../shared/service/users.service';
import { User, RolesList } from '../../../../shared/models/user/user';
import { GlobalService } from '../../../../shared/service/global.service';
import {
  PageInfo,
  SelectOptions,
} from '../../../../shared/models/global/response';
import { CountryService } from '../../../../shared/service/country.service';
import { CountryList } from '../../../../shared/models/countries/country';
import { LaundryUserFromList } from 'src/app/shared/models/user/laundry-user';
import { LaundryService } from 'src/app/shared/service/laundry.service';
import { LaundryFromList } from 'src/app/shared/models/laundries/laundry';
import { ApiLibrary } from 'src/app/shared/helpers/http-params';
import { ToastService } from 'ng-uikit-pro-standard';
import { filter } from 'rxjs/operators';
import { DialogService } from '@shared/components/dialog/services/dialog.service';
import { UserAssignBranchComponent } from '@app/modules/users/components/user-assign-branch/user-assign-branch.component';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss'],
})
export class ListUserComponent implements OnInit {
  search: string;
  searchLaundry: string;
  roles: any[];
  countries: SelectOptions<number>[];
  selectedCountry: number;
  selectedRole: number;
  users: User[];
  filteredUsers: User[];
  lang: string;
  userToBeDeleted: User = new User();
  pagination: PageInfo;
  toggleDeleteModal: boolean;
  loader: boolean;
  laundryUser: LaundryUserFromList[];
  laundryUserPagination: PageInfo;
  selectedLaundry: number;
  laundries: any[];
  isFilterUpdate = false;
  constructor(
    public translate: TranslateService,
    private roleService: RolesService,
    private countriesService: CountryService,
    private userService: UsersService,
    private laundryService: LaundryService,
    private readonly dialog: DialogService,
    protected globalService: GlobalService,
    protected toast: ToastService,
  ) {}

  ngOnInit() {
    this.toggleDeleteModal = false;
    this.search = '';
    this.translate.use(this.translate.defaultLang);
    this.lang = this.translate.getDefaultLang();
    this.loader = false;
    this.getUsers(1);
    this.getLaundryUsers(1);
    this.getLaunderies();
  }

  getUsers(page: number) {
    this.userService
      .getUsers(
        this.globalService.headers,
        new ApiLibrary().cleanObjectFromComponent({
          country_id: this.selectedCountry,
          search: this.search,
          role_id: this.selectedRole,
          page: page,
        }),
      )
      .subscribe((response) => {
        console.log(response);

        this.users = response.data as User[];
        this.filteredUsers = this.users;
        this.pagination = response.page_info;
        this.getFilters();
      });
  }
  // laundry user
  getLaundryUsers(page: number) {
    this.userService
      .getLaundryUsers(
        this.globalService.headers,
        new ApiLibrary().cleanObjectFromComponent({
          page: page,
          search: this.searchLaundry,
          laundry_id: this.selectedLaundry,
        }),
      )
      .subscribe((response) => {
        if (response.code === 200) {
          this.laundryUser = response.data as LaundryUserFromList[];
          this.laundryUserPagination = response.page_info;
        }
      });
  }
  getFilters(id?: number) {
    this.roleService
      .getRoles(this.globalService.headers)
      .subscribe((response) => {
        this.roles = [];
        const roles = response.data as RolesList[];
        roles.forEach((element) => {
          this.roles.push({ value: element.id, label: element.name });
        });
      });

    this.countriesService
      .getCountries(this.globalService.headers)
      .subscribe((data) => {
        this.countries = [];
        const countries = data.data as CountryList[];
        countries.forEach((element) => {
          this.countries.push({ value: element.id, label: element.name.en });
        });
      });
  }

  filterUsers() {
    this.getUsers(1);
  }

  delete(user: User) {
    this.userToBeDeleted = user;
    this.toggleDeleteModal = true;
  }

  deleteUser(event) {
    this.loader = true;
    this.userService
      .deleteUser(this.globalService.headers, this.userToBeDeleted.id)
      .subscribe((data) => {
        console.log(data);
        this.toggleDeleteModal = false;
        this.getUsers(this.pagination.current_page);
        this.loader = false;
      });
  }

  changeStatus(id, status) {
    const statusToSend = status === 'active' ? 'inactive' : 'active';
    this.userService
      .changeStatus(this.globalService.headers, id, statusToSend)
      .subscribe((response) => {
        if (response.code === 200) {
          this.getUsers(this.pagination.current_page);
        }
      });
  }

  resetFilterUser() {
    this.filteredUsers = this.users;
    this.selectedCountry = null;
    this.selectedRole = null;
    this.search = null;
    this.selectedLaundry = null;
    this.isFilterUpdate = false;
    this.getUsers(1);
  }

  resetFilterJlm() {
    this.searchLaundry = null;
    this.isFilterUpdate = false;
    this.getLaundryUsers(1);
  }

  getPage(page) {
    this.getUsers(page);
  }
  getPageLaundry(page) {
    this.getLaundryUsers(page);
  }
  pageArray() {
    return Array(this.pagination.page_count);
  }

  formatDate(date: string) {
    return this.globalService.getDateOrderFormat(date);
  }

  getLaunderies() {
    this.laundryService
      .getLaundriesWithParams(this.globalService.headers, { page: 0 })
      .subscribe((response) => {
        const laundry = response.data as LaundryFromList[];
        this.laundries = [];
        laundry.forEach((laundryElement) => {
          this.laundries.push({
            value: laundryElement.id,
            label: laundryElement.name,
          });
        });
      });
  }

  filterLaundry() {
    this.getLaundryUsers(1);
  }

  changeStatusLaundryUser(id: number, status: string): void {
    status = status === 'active' ? 'inactive' : 'active';
    this.userService
      .updateLaundryUser(this.globalService.headers, id, { status })
      .subscribe((response) => {
        if (response.code === 200) {
          if (this.searchLaundry && this.searchLaundry.length > 0) {
            this.filterLaundry();
          } else {
            this.getLaundryUsers(this.laundryUserPagination.current_page);
          }
        }
      });
  }

  showCountries(user: User) {
    const countries = [];
    if (user.assigned_country && this.countries) {
      user.assigned_country.forEach((e) => {
        const countryT = this.countries
          .filter((c) => c.label !== user.country.name)
          .find((country) => country.value === e);
        if (countryT) {
          countries.push(countryT.label);
        }
      });
    }
    if (countries.length === 0) {
      countries.push('-');
    }
    return countries.toString();
  }

  showVoip(user: User) {
    return user.user_admin_voips.length > 0;
  }

  voipData(user: User) {
    let html = '';
    user.user_admin_voips.forEach((e, i) => {
      html +=
        '<span class="green-blue-color"> ' +
        e.type +
        ': ' +
        e.voip_id +
        ' </span><br>';
    });
    return user.user_admin_voips.length > 0
      ? html
      : '<span class="error-text"></span>';
  }

  showResetPassword() {
    const user = localStorage.getItem('email');
    return user && user === 'superadminkw@justclean.com';
  }

  resetPassword(userId: number) {
    this.userService
      .resetPassword(this.globalService.headers, userId)
      .subscribe((response) => {
        if (response.code === 200) {
          this.toast.success(response.message);
        } else {
          this.toast.error(response.message);
        }
      });
  }

  isLaundryUser(id: number): boolean {
    return [1, 2, 3, 4, 5].includes(id);
  }

  openAssignBranchDialog(user: LaundryUserFromList): void {
    this.dialog
      .open(UserAssignBranchComponent, user)
      .afterClosed$.pipe(filter(Boolean))
      .subscribe(() => {});
  }
}
