import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from '@shared/service/global.service';
import { NotificationsService } from '@app/modules/notifications/services/notifications.service';
import { debounceTime } from 'rxjs/operators';
import { ToastService } from 'ng-uikit-pro-standard';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-users-notifications-main',
  templateUrl: './users-notifications-main.component.html',
  styleUrls: ['./users-notifications-main.component.css']
})
export class UsersNotificationsMainComponent implements OnInit {
  @ViewChild('stepper') stepper: any;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  validUsers: UserInfo[] = [];

  constructor(
    private readonly notificationsService: NotificationsService,
    private readonly globalService: GlobalService,
    private readonly toastr: ToastService,
    private readonly formBuilder: FormBuilder
  ) {
  }

  ngOnInit() {
    this.firstFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(/^\s*[\w+.-]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,3}(?:,\s*[\w+.-]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,3})*\s*$/)]]
    });

    this.secondFormGroup = this.formBuilder.group({
      titleEn: ['', Validators.required],
      bodyEn: ['', Validators.required],
      titleAr: ['', Validators.required],
      bodyAr: ['', Validators.required],
      link: ['']
    });


    this.firstFormGroup.get('email').valueChanges
      .pipe(debounceTime(500)) // Add debounceTime operator to wait for 0.5 seconds
      .subscribe((value: string) => {
        this.validateEmail(value);
      });
  }

  get email() {
    return this.firstFormGroup.get('email');
  }

  get password() {
    return this.secondFormGroup.get('password');
  }

  validateEmail(emails: string) {
    // Clear the existing user information
    this.validUsers = [];

    if (!emails) {
      return
    }

    const emailList: string[] = emails
      .split(',')
      .map((email: string) => email.trim())
      .filter((email: string) => email.length > 0);

    emailList.forEach(async (email: string) => {
      const validEmail = Validators.email(new FormControl(email)) === null;

      let users = [];
      if (validEmail) {
        users = await this.getUser(email);
      }

      if (users.length) {
        users.map(user=> this.validUsers.push(user));
      } else {
        this.validUsers.push({
          enteredEmail: email,
          validEmail: validEmail,
          userFound: false,
        });
      }
    });
  }

  private async getUser(email: string): Promise<UserInfo[] | null> {
    try {
      const response = await this.notificationsService.getUserByEmail(
        this.globalService.headers,
        {
          email: email
        }
      ).toPromise();

      if (response.code === 200) {
        console.log('response is 200');
        const data = response.data;

        console.log('response.data is : ', data);
        if (!data) {
          return null;
        }
        return data.map(user=> {
          return {
            enteredEmail: email,
            validEmail: !!user.id,
            userFound: !!user.id,
            userId: user.id,
            userEmail: user.email,
            phoneNumber: `${user.phoneExt} ${user.phone}`,
            createdAt: user.createdAt,
            languageId: user.languageId
          }
        });
      }
    } catch (error) {
      console.error('Error occurred while fetching user:', error);
    }

    return null;
  }
  onSubmit() {
    const { titleEn, bodyEn, titleAr, bodyAr, link } = this.secondFormGroup.value;
    const sendNotificationsDtoEn: SendNotificationsDto = {
      userIds: this.validUsers
        .filter(user => user.validEmail && user.userFound && user.languageId === 1)
        .map(user => parseInt(user.userId, 0)),
      title: titleEn,
      body: bodyEn,
      link: link || ''
    };

    const sendNotificationsDtoAr: SendNotificationsDto = {
      userIds: this.validUsers
        .filter(user => user.validEmail && user.userFound && user.languageId === 2)
        .map(user => parseInt(user.userId, 0)),
      title: titleAr,
      body: bodyAr,
      link: link || ''
    };

    const sendNotificationRequests = [];

    if (sendNotificationsDtoEn.userIds.length > 0) {
      sendNotificationRequests.push(
        this.notificationsService.sendNotificationToUsers(this.globalService.headers, sendNotificationsDtoEn)
      );
    }

    if (sendNotificationsDtoAr.userIds.length > 0) {
      sendNotificationRequests.push(
        this.notificationsService.sendNotificationToUsers(this.globalService.headers, sendNotificationsDtoAr)
      );
    }

    if (sendNotificationRequests.length === 0) {
      console.log('No valid users found for sending notifications.');
      return;
    }

    forkJoin(sendNotificationRequests).subscribe((responses) => {
      console.log('Notifications sent:', responses);
      this.toastr.success('Notifications sent successfully!');
      this.secondFormGroup.reset(); // Reset the form fields
      this.validUsers = []; // Clear the user list
      this.stepper.resetAll(); // Reset the stepper to the first step
    }, (error) => {
      console.error('Error occurred while sending notifications:', error);
    });
  }


}

