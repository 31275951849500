import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CarwashBranchListComponent } from '@app/modules/carwash/modules/carwash-branch/components/carwash-branch-list/carwash-branch-list.component';
import { CarwashBranchTimeServiceComponent } from '@app/modules/carwash/modules/carwash-branch/components/carwash-branch-time-service/carwash-branch-time-service.component';

const routes: Routes = [
  {
    path: 'branches/:id/list',
    component: CarwashBranchListComponent,
    data: { title: 'Car wash branches' },
  },
  {
    path: 'time-service/:id',
    component: CarwashBranchTimeServiceComponent,
    data: { title: 'Start service times' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CarwashBranchRoutingModule {}
