import { takeUntil, finalize } from 'rxjs/operators';
import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { DIALOG_DATA } from '@app/shared/components/dialog/services/dialog.token';
import { Subject } from 'rxjs';
import { DialogRef } from '@app/shared/components/dialog/dialog-ref/dialog-ref';
import { HcOrder, HcBranch } from '@app/shared/models';
import { HomeCleaningBranchesService } from '@app/shared/service/home-cleaning/home-cleaning-branches.service';
import { HomeCleaningOrdersService } from '@app/shared/service/home-cleaning/home-cleaning-orders.service';

@Component({
  selector: 'app-change-branch-dialog',
  templateUrl: './change-branch-dialog.component.html',
  styleUrls: ['./change-branch-dialog.component.scss'],
})
export class ChangeBranchDialogComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>() ;

  isLoading: boolean;
  branches: HcBranch[];
  selectedBranch: number;

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: { order: HcOrder; callbackFn: Function } | any,
    private readonly homecleanBranchesService: HomeCleaningBranchesService,
    private readonly homeCleaningOrdersService: HomeCleaningOrdersService,
    private readonly dialogRef: DialogRef<ChangeBranchDialogComponent>,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadBranches();
  }

  close(isRefresh?: boolean): void {
    this.dialogRef.close();
    if (isRefresh) {
      this.data.callbackFn();
    }
  }

  changeBranch() {
    this.isLoading = true;
    this.homeCleaningOrdersService
      .transferOrderToAnotherBranch(this.data.order.id, {
        hcCompanyBranchId: this.selectedBranch,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.cd.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.close(true));
  }

  private loadBranches(): void {
    if (this.data.order) {
      const countryId = localStorage.getItem('country');
      const groupId= this.data.order.groupId;
      const hcOrderId = this.data.order.id;
      this.homecleanBranchesService
        .getHCAllBranches({ countryId, groupId, hcOrderId })
        .pipe(takeUntil(this.destroy$))
        .subscribe((branches) => {
          this.branches = branches;
          this.selectedBranch = this.data.order.hcCompanyBranchId;
          this.cd.detectChanges();
        });
    }
  }

  private unsubscribe(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
