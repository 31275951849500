import { takeUntil, finalize } from 'rxjs/operators';
import { getTicketStatuses } from "@app/modules/carwash/shared/helpers/ticket-statuses";
import { HcOrderJob, LogHcOrder } from '@app/shared/models';
import {
  Component,
  Input,
  ChangeDetectorRef,
  EventEmitter,
  Output, OnInit, OnDestroy,
} from '@angular/core';
import { IOption } from 'ng-uikit-pro-standard';
import { GlobalService } from '@shared/service/global.service';

import { interval, Subject, Subscription } from 'rxjs';
import { GroupService } from '@app/shared/service/group';
import { OrderDetailsItemSize } from '../shared/enums/order-details-item-size.enum';
import { HomeCleaningOrderJobsService } from '@app/shared/service/home-cleaning/home-cleaning-order-jobs.service';
import { ChangeBranchDialogComponent } from '../../components/change-branch-dialog/change-branch-dialog.component';
import { CancelOrderDialogComponent } from './../../components/cancel-order-dialog/cancel-order-dialog.component';
import { DialogService } from '@app/shared/components/dialog/services/dialog.service';
import { JobStatus } from '@app/shared/enums';
import { RescheduleDialogComponent } from '../../components/reschedule-dialog/reschedule-dialog.component';
import moment from 'moment/moment';

@Component({
  selector: 'app-homeclean-order-details-card',
  templateUrl: './homeclean-order-details-card.component.html',
  styleUrls: ['./homeclean-order-details-card.component.scss'],
})
export class HomecleanOrderDetailsCardComponent implements OnInit, OnDestroy {
  @Input() isMobileType: boolean;
  @Input() jobId: number;
  @Input() groupId: number;
  @Input() orderDetails = null;
  @Input() unCancellable: boolean = false;
  @Input() companyName: string;
  @Output() onRefresh = new EventEmitter();
  readonly OrderDetailsItemSize = OrderDetailsItemSize;

  jobDetails: HcOrderJob;
  isLoading: boolean;
  tax: number;

  options: IOption[];
  activeJobStatus: string;
  currency: string;
  language = localStorage.getItem('language') || 'ar';
  isCancellable: boolean;
  isCancelDisabled: boolean = true;
  private timerSubscription: Subscription;
  public countdownText: string = '';
  public cancelTooltipMessage = "The current start time of this booking is within 12 hours and you don't have the option to cancel unless it's more than 12 hours before start time";
  copyTooltipMessage = 'Copy';
  isCancelled: boolean;
  isAcknowledged: boolean;
  isAbleToReschedule: boolean;

  private destroy$: Subject<void> = new Subject<void>();
  @Input() jobCount!: number;

  constructor(
    private readonly globalService: GlobalService,
    private readonly groupService: GroupService,
    private readonly homeCleaningOrderJobsService: HomeCleaningOrderJobsService,
    private readonly dialog: DialogService,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.enableCancelForUsers();
    this.loadJobDetails();
    this.currency = this.globalService.getCurrency();
    this.tax = this.globalService.getTax() * 100;
  }

  openCancellationModal(): void {
    this.dialog.open(CancelOrderDialogComponent, {
      order: this.orderDetails,
      callbackFn: () => this.onRefresh.emit(),
    });
  }

  openChangeBranchDialog() {
    this.dialog.open(ChangeBranchDialogComponent, {
      order: this.orderDetails,
      callbackFn: () => this.onRefresh.emit(),
    });
  }

  openRescheduleDialog() {
    this.dialog.open(RescheduleDialogComponent, {
      job: this.jobDetails,
      callbackFn: this.loadJobDetails.bind(this),
    });
  }

  private loadJobDetails(): void {
    this.resetVariables();
    this.isLoading = true;
    this.homeCleaningOrderJobsService
      .getHcJobById(this.jobId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.jobDetails = data;
        this.setJobStatuses();
        this.isLoading = false;
        this.cd.detectChanges();
      });
  }

  private resetVariables(): void {
    this.jobDetails = null;
    this.isCancellable = false;
    this.isAcknowledged = false;
    this.cd.detectChanges();
  }

  private setJobStatuses(): void {
    const groupId = this.groupId || this.groupService.selectedGroup?.id;

    if (groupId) {
      this.loadLocalStatuses();
      return;
    }

    this.checkAndSetSelectedGroup(groupId);

    if (!this.groupService.selectedGroup) {
      this.loadLocalStatuses();
      return;
    }

    this.groupService
      .getGroupStatuses(groupId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.cd.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (data) => {
          this.options = data as any;
          this.setActiveJobStatus();
        },
        () => this.loadLocalStatuses()
      );
  }

  private checkAndSetSelectedGroup(groupId: number): void {
    if (!this.groupService.selectedGroup) {
      this.groupService.setSelectedGroup(groupId);
    }
  }
  private loadLocalStatuses(): void {
    this.options = getTicketStatuses(null, true);
    this.setActiveJobStatus();
  }

  private setActiveJobStatus(): void {
    this.activeJobStatus = this.options.find(
      (option: IOption) =>
        Number(option.value) === this.jobDetails.statusesUserId,
    )?.value;

    this.isCancellable = [
      JobStatus.new_job_request,
      JobStatus.acknowledged,
      JobStatus.on_the_way,
    ].includes(this.jobDetails.statusesUserId) && !this.unCancellable;

    this.startCountdown()

    if (
      this.isMobileType &&
      this.activeJobStatus &&
      [
        JobStatus.new_job_request,
        JobStatus.acknowledged,
        JobStatus.on_the_way,
        JobStatus.job_started,
      ].includes(this.jobDetails.statusesUserId)
    ) {
      this.isAcknowledged =
        this.jobDetails.statusesUserId === JobStatus.acknowledged && !this.unCancellable;
      this.checkRescheduleAbility();
    } else if (this.isMobileType && this.activeJobStatus) {
      this.isCancelled =
        this.jobDetails.statusesUserId === JobStatus.job_canceled;
    }
    this.cd.detectChanges();
  }

  private checkRescheduleAbility(): void {
    this.isAbleToReschedule = [
      JobStatus.new_job_request,
      JobStatus.acknowledged,
      JobStatus.on_the_way,
    ].includes(this.jobDetails.statusesUserId);
  }

  private startCountdown() {
    if (this.enableCancelForUsers()) {
      this.isCancelDisabled = false;
      return;
    }
    // Update every second
    this.timerSubscription = interval(1000).subscribe(() => {
      this.updateCountdown();
    });
  }


  private updateCountdown() {
    const now = moment().utc(); // Convert current time to UTC
    const combinedDateTime = moment(this.jobDetails.startDate.date).utc()
      .hours(parseInt(this.jobDetails.startTime.time.split(':')[0]))
      .minutes(parseInt(this.jobDetails.startTime.time.split(':')[1]))
      .seconds(parseInt(this.jobDetails.startTime.time.split(':')[2]))
      .utc();

    const duration = moment.duration(combinedDateTime.diff(now));
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    // Update isCancelDisabled - also use UTC time for the 12-hour comparison
    this.isCancelDisabled = combinedDateTime.isBefore(moment().utc().add(12, 'hours'));
    if (hours <= 12) {
      this.isCancelDisabled = true;
      this.countdownText = ''
    } else if (hours >= 1) {
      this.countdownText = `(${hours}h ${minutes}m ${seconds}s  remaining)`;
    } else if (minutes >= 1) {
      this.countdownText = `(${minutes}m ${seconds}s remaining)`;
    } else if (seconds >= 1) {
      this.countdownText = `(${seconds}s remaining)`;
    }

    this.cd.detectChanges();
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  getProgressWidth(statusCwGarageBranchTicketId: number): string {
    // Calculate the progress width based on the status
  if (statusCwGarageBranchTicketId === 17) {
      return '7.5%';
    } else if (statusCwGarageBranchTicketId === 18) {
    return '32.5%'
    } else if (statusCwGarageBranchTicketId ===19) {
    return '52.5%';
    } else if (statusCwGarageBranchTicketId === 20) {
    return '75%';
    } else if (statusCwGarageBranchTicketId === 21) {
    return '100%';
  } else if (statusCwGarageBranchTicketId === 22) {
    return '100%';
  } else {
      return '0%'; // Default or other values
    }
  }

  getStatusName(statusCwGarageBranchTicketId: number): string {
    // Calculate the progress width based on the status
    if (statusCwGarageBranchTicketId === 17) {
      return 'New request';
    } else if (statusCwGarageBranchTicketId === 18) {
      return 'Acknowledged';
    } else if (statusCwGarageBranchTicketId === 19) {
      return 'On the way';
    } else if (statusCwGarageBranchTicketId === 20) {
      return 'Started';
    } else if (statusCwGarageBranchTicketId === 21) {
      return 'Finished';
    } else if (statusCwGarageBranchTicketId === 22) {
      return 'Canceled';
    }
  }

  getStatusTextColor(desiredStatus: number, currentStatus: number): string {
    // Calculate the text color based on the desired status and the current status
    if (currentStatus === desiredStatus) {
      return '#641bb4'; // Purple color for the desired status
    } else {
      return 'black'; // Default or other text color
    }
  }

  enableCancelForUsers(): boolean {
    const userEmail = this.globalService.getUser().email;
    const allowedEmails = [
      'superadminnpereira@justclean.com',
      'superadminkw@justclean.com'
    ];

    return allowedEmails.includes(userEmail);
  }

  copyJobDetails() {
    const timeZone = localStorage.getItem('timeZone');
    const formattedDate = moment
      .utc(this.jobDetails?.startDate?.date?.replace('00:00:00', this.jobDetails?.startTime?.time))
      .tz(timeZone)
      .format('D MMM YYYY - h:mma');

    const jobText = `Job ID: ${this.jobId}\nPackage: ${this.jobDetails?.hcServicePackage?.name?.en}\nService: ${this.jobDetails?.hcServicePackage?.hcService?.name?.en}\nCleaning materials: ${this.jobDetails.hasSupplies ? 'yes': 'no'}\nNo. of cleaners: ${this.jobDetails.noOfWorkers}\nNo. of hours: ${this.jobDetails.hcServicePackageHours.hours}\nCustomer area: ${this.jobDetails?.hcOrder?.userAddress?.area?.name?.en}\nJob status: ${this.jobDetails.statusesUser.name.en}\nReservation date and time: ${formattedDate}`;

    navigator.clipboard.writeText(jobText).then(() => {
      this.copyTooltipMessage = 'Copied!';
      this.cd.detectChanges();
      setTimeout(() => {
        this.copyTooltipMessage = 'Copy'; // Reset tooltip after 2s
        this.cd.detectChanges();
      }, 2000);
    }).catch(err => {
      console.error('Failed to copy:', err);
    });
  }

  fetchLogBy(log: LogHcOrder): string {
    console.error('orderDetails: ', this.orderDetails)
    let logBy = 'unknown';
    if (log.userJlm) {
      logBy = `${log.userJlm.firstName} ${log.userJlm.lastName} (Staff)`
    } else if (log.userAdmin) {
      logBy = `${log.userAdmin.name} (Admin)`
    } else if (log.userId) {
      logBy = `Customer`
    }
    return logBy
  }

}
