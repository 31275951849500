import { map, tap } from 'rxjs/operators';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HcOrderJob } from '@app/shared/models';
import { PageInfo } from '@shared/models/global/response';
import { PaginationHelper } from '@app/shared/helpers/pagination-helper';

@Injectable({
  providedIn: 'root',
})
export class HomeCleaningOrderJobsService {
  private readonly hcOrderJobsUrl = `${environment.jcDeliveryServer}hc-order-jobs`;
  private pagination$: BehaviorSubject<PageInfo> =
    new BehaviorSubject<PageInfo>(null);

  constructor(private readonly http: HttpClient) {}

  getHcOrderJobs(params: any): Observable<Array<HcOrderJob>> {
    const toData = (res) => res.data;
    return this.http
      .get<ApiResponse<Array<HcOrderJob>>>(this.hcOrderJobsUrl, {
        params,
      })
      .pipe(
        tap((res: ApiResponse<Array<HcOrderJob>>) =>
          this.pagination$.next(
            PaginationHelper.camelToSnakeCase(res.pageInfo),
          ),
        ),
        map(toData),
      );
  }

  getHcJobById(id: number): Observable<HcOrderJob> {
    const toData = ({ data }) => data;
    return this.http
      .get<ApiResponse<HcOrderJob>>(`${this.hcOrderJobsUrl}/${id}`)
      .pipe(map(toData));
  }

  getPagination(): Observable<PageInfo> {
    return this.pagination$;
  }
}
