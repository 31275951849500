import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, Type } from '@angular/core';
import { DialogComponent } from '@shared/components/dialog/components/dialog.component';
import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';

@Injectable()
export class DialogService {
  constructor(private overlay: Overlay) {}

  open<R = any, T = any>(content: Type<any>, data: T): DialogRef<R> {
    const overlayRef: OverlayRef = this.overlay.create(this.getOverlayConfig());
    const dialogOverlayRef = new DialogRef<R, T>(overlayRef, content, data);
    const injector: Injector = Injector.create({
      providers: [
        { provide: DialogRef, useValue: dialogOverlayRef },
        { provide: DIALOG_DATA, useValue: data },
      ],
    });

    overlayRef.attach(new ComponentPortal(DialogComponent, null, injector));

    return dialogOverlayRef;
  }

  private getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    return new OverlayConfig({
      hasBackdrop: true,
      panelClass: ['modal-open'],
      positionStrategy,
    });
  }
}
