import { GroupService } from '@shared/service/group';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { GlobalService } from '@app/shared/service/global.service';
import { CustomerService } from '@app/shared/service/customer.service';
import { CarwashOrderService } from '@app/modules/carwash/modules/carwash-order/components/shared/services/carwash-order.service';
import { PageInfo, SelectOptions } from '@app/shared/models/global/response';
import { Observable, Subscription, of } from 'rxjs';
import { OrderCustomerNew } from '@app/shared/models/customer/customer';
import { CarwashOrder } from '@app/modules/carwash/modules/carwash-order/components/shared/models/carwash-order.model';
import { OrderService } from '@app/shared/service/order.service';
import { Group, HcOrderJob, HcBranch } from '@app/shared/models';
import { finalize, map } from 'rxjs/operators';
import { HomeCleaningBranchesService } from '@app/shared/service/home-cleaning/home-cleaning-branches.service';
import { HomeCleaningOrderJobsService } from '@app/shared/service/home-cleaning/home-cleaning-order-jobs.service';
import { AreaService } from '@app/shared/service/area';
import { AppHeaders } from "@shared/models/user/generic-backend";

@Component({
  selector: 'app-group-orders',
  templateUrl: './group-orders.component.html',
  styleUrls: ['./group-orders.component.css'],
})
export class GroupOrdersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isFrameless: boolean;
  @Input() hideSearch: boolean;
  @Input() customerId: number;
  @Input() group: Group;
  orders: Array<OrderCustomerNew> | Array<CarwashOrder> | Array<HcOrderJob>;
  pagination: PageInfo;
  currentPage = 1;
  subscribers$: Array<Subscription> = [];
  otherPagination$: Observable<PageInfo>;
  isLoading: boolean;
  getDateBounded: Function;
  getAreaNameBounded: Function;
  loadedAreas: { [areaId: number]: string } = {};
  getCompanyNameBounded: Function;
  loadedCompanies: { [branchId: number]: string } = {};
  isOutCall: boolean;
  statuses$: Observable<Array<SelectOptions<number>>>;
  hcBranches$: Observable<Array<HcBranch>>;
  selectedStatus: number;
  selectedDate: string;
  selectedStartTime: string;
  selectedEndTime: string;
  selectedHcBranch: number;
  searchInputValue: string;
  isLaundryDesign: boolean;
  isCWDesign: boolean;
  isHCDesign: boolean;
  private headers: AppHeaders = {};

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly groupService: GroupService,
    private readonly areaService: AreaService,
    private readonly globalService: GlobalService,
    private readonly customerService: CustomerService,
    private readonly carwashOrderService: CarwashOrderService,
    private readonly homecleanBranchesService: HomeCleaningBranchesService,
    private readonly homeCleaningOrderJobsService: HomeCleaningOrderJobsService,
    private readonly orderService: OrderService,
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.group) {
      this.loadData();
      this.loadStatuses();
      this.loadHcBranches();
    }
  }

  ngOnInit(): void {
    this.headers = this.globalService.headers;
    this.getDateBounded = this.getDate.bind(this);
    this.getAreaNameBounded = this.getAreaName.bind(this);
    this.getCompanyNameBounded = this.getCompanyName.bind(this);

    if (!this.customerId) {
      this.route.params.subscribe((params) => {
        if (!params || !this.groupService.selectedGroup) {
          if (params.id) {
            this.loadGroup(+params.id);
            this.headers.group_id = `${params.id}`
          } else {
            this.router.navigate(['dashboard/orders/orders-all']);
          }
        } else {
          this.refreshPageData();
        }
      });
    }
  }

  getPage(pageNumber: number) {
    this.currentPage = pageNumber;
    this.loadData();
  }

  goToDetails(url: string, isNewTab: boolean, extraParameters?: any): void {
    if (isNewTab) {
      window.open(url);
    } else {
      this.router.navigate([url], { queryParams: extraParameters });
    }
  }

  resetFilters(): void {
    this.currentPage = 1;
    this.selectedStatus = null;
    this.selectedHcBranch = null;
    this.selectedDate = null;
    this.selectedStartTime = null;
    this.selectedEndTime = null;
    this.searchInputValue = null;
    this.loadData();
  }

  private refreshPageData(): void {
    this.resetFilters();
    this.loadStatuses();
    this.loadHcBranches();
  }

  private loadGroup(id: number) {
    if (!this.groupService.allGroups) {
      const subscriber$ = this.groupService.pagination$.subscribe(() => {
        setTimeout(() => {
          this.groupService.setSelectedGroup(id);
          this.refreshPageData();
        }, 0);
      });
      this.subscribers$.push(subscriber$);
    } else {
      this.groupService.setSelectedGroup(id);
      this.refreshPageData();
    }
  }

  private getAreaName(areaId: number): Observable<string> {
    if (!areaId) {
      return of('');
    }

    if (this.loadedAreas[areaId]) {
      return of(this.loadedAreas[areaId]);
    }

    this.loadedAreas[areaId] = '-';

    return this.areaService.getAreaByID(areaId).pipe(
      map((area) => {
        this.loadedAreas[areaId] = area.name.en;
        return area.name.en;
      }),
    );
  }

  private getCompanyName(branchId: number): Observable<string> {
    if (!branchId) {
      return of('-');
    }

    if (this.loadedCompanies[branchId]) {
      return of(this.loadedCompanies[branchId]);
    }

    this.loadedCompanies[branchId] = '-';

    return this.homecleanBranchesService.getHcBranchById(branchId).pipe(
      map((branch) => {
        this.loadedCompanies[branchId] = branch.hcCompany?.name?.en;
        return this.loadedCompanies[branchId];
      }),
    );
  }

  private loadStatuses(): void {
    if (!this.hideSearch) {
      this.isOutCall = this.groupService.checkOutCall();
      this.statuses$ = this.groupService.getGroupStatuses(
        // TODO: Uncomment this later when we have statuses table for each group to load them dynamically
        // this.isLaundryDesign ? 1 : this.groupService.selectedGroup.id,
        this.isLaundryDesign
          ? 1
          : this.isHCDesign
          ? this.groupService.selectedGroup.id
          : this.isOutCall
          ? 6
          : 4,
      );
    }
  }

  private loadHcBranches(): void {
    if (this.isHCDesign) {
      this.selectedHcBranch = null;
      this.hcBranches$ = this.homecleanBranchesService.getHCAllBranches({
        countryId: localStorage.getItem('country'),
      });
    }
  }

  private loadData(): void {
    this.isOutCall = this.groupService.checkOutCall();
    this.isLaundryDesign = this.groupService.checkLaundryDesign();
    this.isHCDesign = this.groupService.checkHomeCleaningDesign();
    this.isCWDesign = this.groupService.checkCarwashGarageDesign();

    if (this.isLaundryDesign) {
      if (this.customerId) {
        this.getCustomerOrders();
      } else {
        this.loadLaundryOrders();
      }
    } else if (this.isHCDesign) {
      this.loadHCOrderJobs();
    } else {
      this.loadCWOrders();
    }
  }

  private getDate(date: string) {
    return this.globalService.getDateTimeFromString(date);
  }

  private getCustomerOrders() {
    this.isLoading = true;
    this.orders = null;
    this.pagination = null;

    this.unsubscribe();
    const subscriber = this.customerService
      .getOrders(this.headers, this.customerId, this.getParams())
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((data) => {
        if (data.code === 200) {
          this.orders = data.data;
          this.pagination = data.page_info;
        }
      });
    this.subscribers$.push(subscriber);
  }

  private loadLaundryOrders() {
    this.isLoading = true;
    this.orders = null;
    this.pagination = null;

    this.unsubscribe();
    const subscriber = this.orderService
      .getAllOrrders(this.headers, this.getParams())
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((data) => {
        if (data.code === 200) {
          this.orders = data.data;
          this.pagination = data.page_info;
        }
      });
    this.subscribers$.push(subscriber);
  }

  private loadCWOrders() {
    this.isLoading = true;
    this.orders = null;
    this.pagination = null;
    this.unsubscribe();

    const subscriber = this.carwashOrderService
      .getOrders(this.getParams(true))
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((data) => (this.orders = data));
    this.otherPagination$ = this.carwashOrderService.getPagination();
    this.subscribers$.push(subscriber);
  }

  private loadHCOrderJobs() {
    this.isLoading = true;
    this.orders = null;
    this.pagination = null;
    this.unsubscribe();

    this.otherPagination$ = this.homeCleaningOrderJobsService.getPagination();
    const countryId = localStorage.getItem('country');
    const subscriber = this.homeCleaningOrderJobsService
      .getHcOrderJobs({ ...this.getParams(true, true), countryId })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((data) => (this.orders = data));
    this.subscribers$.push(subscriber);
  }

  private getParams(isV4?: boolean, isHC?: boolean): any {
    const params: any = {
      [isHC ? 'sortName' : isV4 ? 'sortBy' : 'sort_by']: 'id',
      [isHC ? 'sortOrder' : isV4 ? 'sortValue' : 'sort_value']: 'DESC',
      page: this.currentPage,
      [isV4 ? 'groupId' : 'group_id']: this.groupService.selectedGroup.id,
    };
    if (this.customerId) {
      params[isV4 ? 'userId' : 'user_id'] = +this.customerId;
    }
    if (this.selectedStatus) {
      if (this.isLaundryDesign) {
        params.order_status = this.selectedStatus;
      } else if (this.isHCDesign) {
        params.statusesUserId = this.selectedStatus;
      } else {
        params.statusCwGarageBranchTicketIds = [this.selectedStatus];
      }
    }
    if (this.selectedHcBranch) {
      params.hcCompanyBranchId = this.selectedHcBranch;
    }
    if (this.searchInputValue) {
      params.search = this.searchInputValue;
    }
    this.setDateFilterParams(params);
    return params;
  }

  private setDateFilterParams(params: any): void {
    if (this.selectedDate) {
      const fromStartDateTimeUTC = this.globalService
        .localToUtcDate(
          `${this.selectedDate}T${this.selectedStartTime || '00:00:00'}`,
        )
        .split('T');
      const toStartDateTimeUTC = this.globalService
        .localToUtcDate(
          `${this.selectedDate}T${this.selectedEndTime || '23:59:59'}`,
        )
        .split('T');
      params[this.isHCDesign ? 'fromStartDatetime' : 'fromStartDate'] =
        fromStartDateTimeUTC[0] !== this.selectedDate
          ? `${this.selectedDate}T00:00:00`
          : fromStartDateTimeUTC.join('T');
      params[this.isHCDesign ? 'toStartDatetime' : 'toStartDate'] =
        toStartDateTimeUTC[0] !== this.selectedDate
          ? `${this.selectedDate}T00:00:00`
          : toStartDateTimeUTC.join('T');
    }
  }

  private unsubscribe(): void {
    this.subscribers$.forEach((subscriber) => subscriber.unsubscribe());
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    this.groupService.selectedGroup = null;
  }
}
