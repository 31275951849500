export interface UserDelivery {
  id: number;
  firebaseUid: string;
  firstName: string;
  lastName: string;
  phoneExt: string;
  phone: string;
  email: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: any;
  countryId: number;
  languageId: number;
}

export interface PickupAddress {
  id: number;
  uid: string;
  name: string;
  type: string;
  areaName: string;
  description: string;
  phoneExt: string;
  phone: string;
  latitude: number;
  longitude: number;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: any;
  countryId: number;
  userDeliveryId: number;
}

export interface DeliveryAddress {
  id: number;
  uid: string;
  name: string;
  type: string;
  areaName: string;
  description: string;
  phoneExt: string;
  phone: string;
  latitude: number;
  longitude: number;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: any;
  countryId: number;
  userDeliveryId: number;
}

export interface JcDeliverOrders {
  id: number;
  uid: string;
  type: string;
  pickupAt: Date;
  deliveryAt: Date;
  notes: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: any;
  countryId: number;
  userDeliveryId: number;
  statusDeliveryId: number;
  paymentStatusId: number;
  pickupAddressId: number;
  deliveryAddressId: number;
  userDelivery: UserDelivery;
  pickupAddress: PickupAddress;
  deliveryAddress: DeliveryAddress;
  ordersDeliveryTracking: OrderTrackingData[];
}

export interface OrderTrackingData {
  id: number;
  type: string;
  status: string;
  startAt?: any;
  stopAt?: any;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: any;
  orderDeliveryId: number;
  driverId?: number;
  partnerId: number;
  driver?: JcDelDriver;
  partner?: JcDelPartner;
}


export interface JcDelPartner {
  id: number;
  firebaseUid?: any;
  firstName: string;
  lastName: string;
  mobileNo: string;
  pinNo: number;
  carPlateNo: string;
  balance: number;
  isAvailable: boolean;
  isProfileCompleted: boolean;
  status: string;
  lastUpdated: Date;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  countryId: number;
  groupId: number;
  languageId: number;
  supplierId: number;
}

export interface JcDelDriver {
  id: number;
  firstName: string;
  lastName: string;
  mobileNo: string;
  score: number;
  capacity: number;
  isLogistics: boolean;
  isActive: boolean;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  laundryBranchId: number;
  countryId: number;
  groupId: number;
}

export interface OrderTrackingToBeSaved {
  type?: string;
  status?: string;
  orderDeliveryId?: number;
  driverId?: number;
  partnerId?: number;
  countryId?: number;
}


export const JCDeliverStatuses = {
  1: 'Looking for a collector',
  2: 'Collecting item',
  3: 'At collection location',
  4: 'Items collected',
  5: 'Delivering item',
  6: 'At delivery location',
  7: 'Items delivered',
  8: 'Completed',
  9: 'Canceled',
  10: 'Test',
};

export interface OrderListParams {
  statusDeliveryIds?: string;
  sortBy?: string;
  sortValue?: string;
  page: number;
  userDeliveryId?: number;
  id?: number;
}

export enum SORT_VALUE {
  up = 'ASC',
  down = 'DESC'
}

export const Statuses = [
  {
    value: 1,
    label: JCDeliverStatuses[1],
  },
  {
    value: 2,
    label: JCDeliverStatuses[2],
  },
  {
    value: 3,
    label: JCDeliverStatuses[3],
  },
  {
    value: 4,
    label: JCDeliverStatuses[4],
  },
  {
    value: 5,
    label: JCDeliverStatuses[5],
  },
  {
    value: 6,
    label: JCDeliverStatuses[6],
  },
  {
    value: 7,
    label: JCDeliverStatuses[7],
  },
  {
    value: 8,
    label: JCDeliverStatuses[8],
  },
  {
    value: 9,
    label: JCDeliverStatuses[9],
  },
  {
    value: 10,
    label: JCDeliverStatuses[10],
  },
];
