import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { LaundryService } from '../../../../shared/service/laundry.service';
import { GlobalService } from '../../../../shared/service/global.service';
import { LaundryFromList } from '../../../../shared/models/laundries/laundry';
import { PageInfo } from '../../../../shared/models/global/response';
import { CountryService } from '../../../../shared/service/country.service';
import { AreaList } from '../../../../shared/models/countries/country';
import { ActivatedRoute, Router } from "@angular/router";
import { Group } from "@shared/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { GroupService } from "@shared/service/group";
import { AppHeaders } from "@shared/models/user/generic-backend";

@Component({
  selector: 'app-log-laundry-list',
  templateUrl: './log-laundry-list.component.html',
  styleUrls: ['./log-laundry-list.component.scss']
})
export class LogLaundryListComponent implements OnInit {
  laundries: LaundryFromList[];
  filteredLaundries: LaundryFromList[];
  pagination: PageInfo;
  rate: any;
  status: any;
  statusModal: string;
  rateModel: number;
  showGroupSelector = true;
  areas;
  areaModel: number;
  search: string;
  isFilterApplies = false;
  isLoadingGroups = false;
  isLoadingLaundries = false;
  page = 1;

  selectedGroup: Group;
  groups$: Observable<Array<Group>>;
  groupId: number;
  private headers: AppHeaders = {};

  constructor(
    private globalService: GlobalService,
    private laundryService: LaundryService,
    private countryService: CountryService,
    private route: ActivatedRoute,
    private router: Router,
  private groupsService: GroupService,
  private readonly changeDetectorRef: ChangeDetectorRef
  ) {}



  ngOnInit() {
    this.headers = this.globalService.headers;
    if (this.groupId) {
      this.headers.group_id = `${this.groupId}`;
    }
    this.page = this.route.snapshot.queryParams.page
      ? this.route.snapshot.queryParams.page
      : 1;
    this.initFilters();


    this.loadLaundryGroups();
    this.setGroup();
    if (this.groupsService.selectedGroup) {
      this.selectedGroup = this.groupsService.selectedGroup;
    }
  }
  getLaundries(params?: any) {
    if (params) {
      this.laundryService
        .getLaundriesWithParams(this.globalService.headers, params)
        .subscribe(response => {
          this.laundries = response.data;
          this.filteredLaundries = response.data;
          this.pagination = response.page_info;
        });
    } else {
      this.laundryService
        .getLaundries(this.globalService.headers)
        .subscribe(response => {
          this.laundries = response.data;
          this.filteredLaundries = response.data;
          this.pagination = response.page_info;
        });
    }

    this.changeDetectorRef.detectChanges();
  }

  getPage(number: number) {
    this.getLaundries({ page: number, is_logistics: true });
  }

  initFilters() {
    this.statusModal = null;
    this.areaModel = null;
    this.rateModel = null;
    this.search = null;
    this.isFilterApplies = false;
    this.rate = [
      { value: 0, label: 0 },
      { value: 1, label: 1 },
      { value: 2, label: 2 },
      { value: 3, label: 3 },
      { value: 4, label: 4 },
      { value: 5, label: 5 }
    ];

    this.status = [
      {
        value: 'active',
        label: 'active'
      },
      {
        value: 'inactive',
        label: 'inactive'
      }
    ];

    this.countryService
      .getListAreas(this.globalService.headers, { page: 0 })
      .subscribe(response => {
        const areas = (response.data as AreaList[]).filter(
          area => area.country_id === this.globalService.countryId
        );
        this.areas = [];
        areas.forEach(element => {
          this.areas.push({
            value: element.id,
            label: element.name.en
          });
        });
      });
    this.getLaundries({ page: 1, is_logistics: true });
  }

  filter() {
    this.isFilterApplies = true;
    const filters = {
      searchstr: this.search ? this.search : '',
      area_id: this.areaModel ? this.areaModel : '',
      rating: this.rateModel ? this.rateModel : '',
      status: this.statusModal ? this.statusModal : '',
      is_logistics: true
    };
    this.getLaundries(filters);
  }
  changeStatus(laundry: LaundryFromList, isLogistics?: boolean) {
    const status = {
      laundry_id: laundry.id,
      status: laundry.status === 'active' ? 'inactive' : 'active',
      is_logistics: laundry.is_logistics ? false : true
    };
    if (isLogistics) {
      delete status.status;
    } else {
      delete status.is_logistics;
    }
    this.laundryService
      .changeStatus(this.globalService.headers, status)
      .subscribe(
        response => {
          if (this.isFilterApplies) {
            this.filter();
          } else {
            this.getLaundries({
              page: this.pagination.current_page,
              is_logistics: true
            });
          }
        }
      );
  }

  goToBranch(laundry: LaundryFromList) {
    window.localStorage.setItem('isLog', laundry.is_logistics + '');
    window.localStorage.setItem('module', 'logistics');
    this.router.navigate(['dashboard/logistics/branch-list/' + laundry.id]);
  }


  loadLaundryGroups(): void {
    this.isLoadingGroups = true;
    this.groups$ = this.groupsService.getGroups().pipe(
      map((groups) => {
        if (this.groupId || this.selectedGroup) {
          /** This to bind with groups dropdown */
          this.selectedGroup = groups.find(
            ({ id }) => id === this.groupId || id === this.selectedGroup?.id,
          );
        } else {
          // assign the first group by default
          this.selectedGroup = groups.find(() => true);
        }

        console.log('selected group = ', this.selectedGroup)
        this.isLoadingGroups = false;
        return groups.filter((group) =>
          this.groupsService.checkLaundryDesign(group),
        );
      }),
    );
  }

  setGroup(): void {
    this.headers.group_id = this.selectedGroup?.id ? this.selectedGroup.id.toString() : `${this.groupId || '1'}`;

    this.laundries = [];
    this.getLaundries({ page: this.page, is_logistics: true });
  }
}
