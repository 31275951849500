import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { FormStatus } from '@shared/enums/form-status.enum';
import { GlobalService } from '@shared/service/global.service';
import { emailValidator } from '@app/shared/helpers/email-validator';
import { phoneValidator } from '@app/shared/helpers/phone-validator';
import { HomecleanCompany } from '@app/modules/homeclean/modules/homeclean-company/shared/models';

@Component({
  selector: 'app-homeclean-company-form',
  templateUrl: './homeclean-company-form.component.html',
  styleUrls: ['./homeclean-company-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomecleanCompanyFormComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;

  @Input() company: HomecleanCompany;
  @Input() companyLogo: string;
  @Input() contractFile: string;
  @Output() formChanged: EventEmitter<Partial<HomecleanCompany>> =
    new EventEmitter<Partial<HomecleanCompany>>();
  @Output() formStatusChanged: EventEmitter<FormStatus> =
    new EventEmitter<FormStatus>();
  @Output() changeCompanyLogoFile: EventEmitter<File> =
    new EventEmitter<File>();
  @Output() changeContractFile: EventEmitter<File> = new EventEmitter<File>();

  private readonly destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly globalService: GlobalService,
  ) {}

  get companyLogoUrl(): string {
    return this.form.get('companyLogoUrl').get('en').value || this.companyLogo;
  }

  get companyContractUrl(): string {
    return (
      this.form.get('companyContractUrl').get('en').value || this.contractFile
    );
  }

  ngOnInit(): void {
    this.initForm();
    this.handleFormChanges();
    this.handleFormStatusChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateCompanyLogoFile(file: File): void {
    this.changeCompanyLogoFile.next(file);
  }

  updateContractFile(file: File): void {
    this.changeContractFile.next(file);
  }
  private initForm(): void {
    this.form = this.fb.group({
      name: this.fb.group({
        en: ['', [Validators.maxLength(25)]],
        ar: ['', [Validators.maxLength(25)]],
      }),
      description: this.fb.group({
        en: ['', [Validators.maxLength(250)]],
        ar: ['', [Validators.maxLength(250)]],
      }),
      companyLogoUrl: this.fb.group({
        en: [''],
        ar: [''],
      }),
      companyContractUrl: this.fb.group({
        en: [''],
        ar: [''],
      }),
      managerName: ['', [Validators.maxLength(25)]],
      managerEmail: ['', [emailValidator.bind(this)]],
      managerMobile: ['', [phoneValidator.bind(this)]],
      contactName: ['', [Validators.maxLength(25)]],
      contactEmail: ['', [emailValidator.bind(this)]],
      contactMobile: ['', [phoneValidator.bind(this)]],
      financeName: ['', [Validators.maxLength(25)]],
      financeEmail: ['', [emailValidator.bind(this)]],
      financeMobile: ['', [phoneValidator.bind(this)]],
      latitude: [''],
      longitude: [''],
      address: ['', [Validators.maxLength(100)]],
      countryId: this.globalService.countryId,
    });
    this.patchForm();
  }

  private patchForm(): void {
    if (!this.company) {
      return;
    }

    this.form.patchValue(this.company);
    this.formChanged.emit(this.form.value);
  }

  private handleFormChanges(): void {
    const emitValue = (value: Partial<HomecleanCompany>) =>
      this.formChanged.emit(value);

    this.form.valueChanges
      .pipe(debounceTime(500), tap(emitValue), takeUntil(this.destroy$))
      .subscribe();
  }

  private handleFormStatusChanges(): void {
    const emitStatus = (value: FormStatus) =>
      this.formStatusChanged.emit(value);

    this.form.statusChanges
      .pipe(
        startWith(this.form.status),
        distinctUntilChanged(),
        tap(emitStatus),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
