import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BackEndResponse } from '../models/global/response';
import { tap } from 'rxjs/operators';
import { CouponToBeSaved } from '../models/offers/offers';
import { environment } from 'src/environments/environment';
import { ApiLibrary } from '../helpers/http-params';

@Injectable({
  providedIn: 'root',
})
export class CouponsService {
  readonly URL_COUPONS_TYPE = `${environment.serverUrl}coupon/get-coupon-types`;
  readonly URL_COUPONS_CREATE = `${environment.serverUrl}coupon/coupon`;
  readonly URL_COUPONS_VERIFY = `${environment.jcDeliveryServer}coupons/validate`;
  readonly URL_COUPONS_LIST = `${environment.serverUrl}coupon/coupon-list`;
  readonly URL_COUPONs_USER_LIST = `${environment.serverUrl}coupon/userphone-list?search={0}`;
  readonly URL_COUPONS_ACTIVE = `${environment.serverUrl}coupon/change-status-coupon`;

  constructor(private http: HttpClient) {}

  getCouponsType(headers) {
    return this.http
      .get<BackEndResponse>(this.URL_COUPONS_TYPE, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  getCuponUsers(headers, search: string) {
    return this.http
      .get<BackEndResponse>(this.URL_COUPONs_USER_LIST.replace('{0}', search), {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data))) as any;
  }

  saveCoupon(headers, coupon: CouponToBeSaved) {
    return this.http
      .post<BackEndResponse>(this.URL_COUPONS_CREATE, coupon, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  verifyCoupon(body: Record<string, string | number>) {
    return this.http.post<BackEndResponse>(this.URL_COUPONS_VERIFY, body);
  }

  getCoupons(headers, params?: any) {
    const pms = new ApiLibrary().cleanObject(params);
    return this.http
      .get<BackEndResponse>(this.URL_COUPONS_LIST, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: pms }),
      })
      .pipe(tap((data) => console.log(data)));
  }

  getCouponDetails(headers, couponId: number) {
    return this.http
      .get<BackEndResponse>(this.URL_COUPONS_CREATE + '/' + couponId, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  changeStatus(
    headers,
    status: {
      coupon_id: number;
      status: string;
    },
  ) {
    return this.http
      .post<BackEndResponse>(this.URL_COUPONS_ACTIVE, status, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  cleanCouponObject(coupon: CouponToBeSaved, idKey: string) {
    if ([1, 5].includes(coupon.coupon_category_id)) {
      if (coupon.type === 'Gift') {
        delete coupon[idKey];
      }
      delete coupon.user_id;
    } else if (coupon.coupon_category_id === 3) {
      delete coupon.user_id;
      delete coupon.translations;
    } else if ([2, 4].includes(coupon.coupon_category_id)) {
      delete coupon[idKey];
      delete coupon.type;
      delete coupon.customer_type;
      delete coupon.discount_type;
      coupon.coupon_count = 1;
      delete coupon.translations;
    }

    coupon.code = coupon.code.toUpperCase();
    return coupon;
  }
}
