import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Reason, SelectedReason } from '../../models/orders/order';

@Component({
  selector: 'app-main-reason',
  templateUrl: './main-reason.component.html',
  styleUrls: ['./main-reason.component.css']
})
export class MainReasonComponent implements OnInit {
  @Input() parentComponent: string;
  @Input() reasons: Reason[];
  @Input() selectedReason: number;
  @Output() selectedReasonEventEmitter: EventEmitter<SelectedReason> = new EventEmitter();
  currentReason: number;
  currentSub: number;
  constructor() { }

  ngOnInit() {
  }

  selected(id: number) {
    this.currentReason = id;
    this.currentSub = -1;
    this.selectedReasonEventEmitter.emit({
      reason: id,
      sub: -1
    });
  }

  selectedSub(id: number) {
    this.currentSub = id;
    this.selectedReasonEventEmitter.emit({
      reason: this.selectedReason,
      sub: id
    });
  }

  checkSelectedReason(id: number) {
    return this.selectedReason === id;
  }

}
