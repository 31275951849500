import { expand, map, reduce } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { GlobalService } from '@shared/service/global.service';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { TimeSlotsConfig } from '@app/modules/carwash/modules/carwash-branch/shared/models/time-slots-config.model';

@Injectable({ providedIn: 'root' })
export class CarwashBranchTimeServiceHttpService {
  readonly headers: HttpHeaders;
  private readonly timesUrl = `${environment.jcDeliveryServer}time-slots/times`;

  constructor(
    private readonly http: HttpClient,
    private readonly globalService: GlobalService,
  ) {
    this.headers = new HttpHeaders(globalService.headers as any);
  }

  getTimesConfig(
    extraParams?: any,
  ): Observable<ApiResponse<TimeSlotsConfig[]>> {
    const params = {
      ...extraParams,
      has15MinInterval: String(true),
      limit: '1000',
    };

    return this.http
      .get<ApiResponse<TimeSlotsConfig[]>>(this.timesUrl, {
        params,
        headers: this.headers,
      })
      .pipe(
        map((res: ApiResponse<TimeSlotsConfig[]>) => ({
          ...res,
          data: res.data.map((item: TimeSlotsConfig) =>
            this.getMappedItem(item),
          ),
        })),
      );
  }

  getAllTimesConfig(params: any = {}): Observable<Array<TimeSlotsConfig>> {
    const allParams = {
      ...params,
      has15MinInterval: String(true),
      limit: '1000',
    };
    return this.http
      .get<ApiResponse<Array<TimeSlotsConfig>>>(`${this.timesUrl}`, {
        params: allParams,
      })
      .pipe(
        map((res: ApiResponse<TimeSlotsConfig[]>) => ({
          ...res,
          data: res.data.map((item: TimeSlotsConfig) =>
            this.getMappedItem(item),
          ),
        })),
        expand((res: ApiResponse<TimeSlotsConfig[]>) =>
          !res.pageInfo.nextPage
            ? EMPTY
            : this.http.get<ApiResponse<Array<TimeSlotsConfig>>>(
                this.timesUrl,
                this.updateRequestFormat(allParams, res.pageInfo.nextPage),
              ),
        ),
        reduce((records, res) => records.concat(res.data), []),
      );
  }

  private updateRequestFormat(params: any, page: number) {
    params.page = page;
    return {
      params,
    };
  }

  private getMappedItem(item: TimeSlotsConfig): TimeSlotsConfig {
    return {
      ...item,
      time: this.globalService.utcToLocalTime(item.time),
    } as TimeSlotsConfig;
  }
}
