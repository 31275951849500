import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomecleanStartTimeRoutingModule } from '@app/modules/homeclean/modules/homeclean-start-time/homeclean-start-time-routing.module';
import { HomecleanStartTimeComponent } from './components/homeclean-start-time/homeclean-start-time.component';

@NgModule({
  declarations: [
    HomecleanStartTimeComponent
  ],
  imports: [CommonModule, HomecleanStartTimeRoutingModule],
})
export class HomecleanStartTimeModule {}
