import { takeUntil, finalize } from 'rxjs/operators';
import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { DIALOG_DATA } from '@app/shared/components/dialog/services/dialog.token';
import { Subject, Observable } from 'rxjs';
import { DialogRef } from '@app/shared/components/dialog/dialog-ref/dialog-ref';
import { HcOrder, CancelReason } from '@app/shared/models';
import { HomeCleaningOrdersService } from '@app/shared/service/home-cleaning/home-cleaning-orders.service';
import { CancelReasonsService } from '@app/shared/service/home-cleaning/hc-cancel-reasons.service';
import { HcOrderStatus, JobStatus } from '@app/shared/enums';

@Component({
  selector: 'app-cancel-order-dialog',
  templateUrl: './cancel-order-dialog.component.html',
  styleUrls: ['./cancel-order-dialog.component.scss'],
})
export class CancelOrderDialogComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>() ;

  isLoading: boolean;
  reasons$: Observable<CancelReason[]>;
  selectedReason: CancelReason;
  isConfirmationOpen: boolean;
  anotherReason: string = '';
  maxReasonLength = 350;

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: { order: HcOrder; callbackFn: Function } | any,
    private readonly cancelReasonsService: CancelReasonsService,
    private readonly homeCleaningOrdersService: HomeCleaningOrdersService,
    private readonly dialogRef: DialogRef<CancelOrderDialogComponent>,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadCancelReasons();
  }

  close(isRefresh?: boolean): void {
    this.dialogRef.close();
    if (isRefresh) {
      this.data.callbackFn();
    }
  }

  openCancelConfirmation(): void {
    this.isConfirmationOpen = true;
  }
  closeCancelConfirmation(): void {
    this.isConfirmationOpen = false;
  }

  cancelOrder() {
    this.isLoading = true;
    const userAdminId = localStorage.getItem('userId');
    this.homeCleaningOrdersService
      .updateOrderStatus(
        this.data.order.id,
        JobStatus.job_canceled,
        HcOrderStatus.CANCELLED,
        +userAdminId,
        this.selectedReason.id,
        this.selectedReason.name.en,
      )
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isLoading = false;
          this.cd.detectChanges();
        }),
      )
      .subscribe(() => {
        this.close(true);
        this.isConfirmationOpen = false;
      });
  }

  private loadCancelReasons(): void {
    if (this.data.order) {
      this.reasons$ = this.cancelReasonsService.getCancelReasons({
        type: 'cancel',
        platform: 'admin',
        groupId: this.data.order.groupId,
      });
    }
  }

  private unsubscribe(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
