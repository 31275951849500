import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DriversListComponent } from './components/drivers-list/drivers-list.component';
import { DriversCreateComponent } from './components/drivers-create/drivers-create.component';
import { DriversBookingsLogsComponent } from '@app/modules/drivers/components/drivers-bookings-logs/drivers-bookings-logs.component';

const routes: Routes = [
  {
    path: 'drivers-list/:laundryId/:branchId',
    component: DriversListComponent,
    data: {
      title: 'List of Drivers',
    },
  },
  {
    path: 'drivers-create',
    component: DriversCreateComponent,
    data: {
      title: 'Create Driver',
    },
  },
  {
    path: 'bookings-logs',
    component: DriversBookingsLogsComponent,
    data: {
      title: 'Driver bookings logs',
    },
  },
  {
    path: '',
    redirectTo: 'drivers-list/1',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DriversRoutingModule {}
