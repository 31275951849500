import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomecleanServiceRoutingModule } from '@app/modules/homeclean/modules/homeclean-service/homeclean-service-routing.module';
import { HomecleanServiceListComponent } from './components/homeclean-service-list/homeclean-service-list.component';

@NgModule({
  declarations: [HomecleanServiceListComponent],
  imports: [CommonModule, HomecleanServiceRoutingModule],
})
export class HomecleanServiceModule {}
