// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-container {
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.centered-cell {
  text-align: center;
  vertical-align: middle;
}

img {
  margin-right: 8px;
}

.text {
  display: flex;
  flex-direction: column;
}

.en-small {
  font-size: 12px; /* Adjust the font size as needed */
}

.ar-small {
  font-size: 12px; /* Adjust the font size as needed */
}`, "",{"version":3,"sources":["webpack://./src/app/modules/carwash/modules/car-wash-packages/components/carwash-package-list/carwash-package-list.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,sBAAA;AACF;;AAGA;EACE,iBAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;AAAF;;AAGA;EACE,eAAA,EAAA,mCAAA;AAAF;;AAGA;EACE,eAAA,EAAA,mCAAA;AAAF","sourcesContent":[".item-container {\n  display: flex;\n  flex-direction: column;\n}\n\n.item {\n  display: flex;\n  align-items: center;\n  margin-bottom: 4px;\n}\n\n.centered-cell {\n  text-align: center;\n  vertical-align: middle;\n}\n\n\nimg {\n  margin-right: 8px;\n}\n\n.text {\n  display: flex;\n  flex-direction: column;\n}\n\n.en-small {\n  font-size: 12px; /* Adjust the font size as needed */\n}\n\n.ar-small {\n  font-size: 12px; /* Adjust the font size as needed */\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
