import { OverlayRef } from '@angular/cdk/overlay';
import { Type } from '@angular/core';
import { Subject } from 'rxjs';

export class DialogRef<R = any, T = any> {
  afterClosed$ = new Subject<unknown>();

  constructor(
    public overlay: OverlayRef,
    public content: Type<any>,
    public data: T,
  ) {
    overlay.backdropClick().subscribe(() => this.disposeOverlay(null));
  }

  close(data?: unknown): void {
    this.disposeOverlay(data);
  }

  private disposeOverlay(data: unknown): void {
    this.overlay.dispose();
    this.afterClosed$.next(data);

    this.afterClosed$.complete();
  }
}
