import { HomeCleaningOrdersService } from '@app/shared/service/home-cleaning/home-cleaning-orders.service';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { HcOrder, HcOrderJob } from "@app/shared/models";
import { OrderDetailsItemSize } from './shared/enums/order-details-item-size.enum';
import { JobStatus } from "@shared/enums";
import { GlobalService } from '@shared/service/global.service';
import { UniqueIdHelper } from '@shared/helpers/unique-id.helper';

@Component({
  selector: 'app-home-cleaning-order-details',
  templateUrl: './home-cleaning-order-details.component.html',
  styleUrls: ['./home-cleaning-order-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeCleaningOrderDetailsComponent implements OnInit {
  order$: Observable<HcOrder>;
  isMobileType: boolean;
  companyName: string;

  constructor(
    private readonly homeCleaningOrdersService: HomeCleaningOrdersService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly globalService: GlobalService,
  ) {}
  readonly OrderDetailsItemSize = OrderDetailsItemSize;
  currency: string;
  groupPrefix: string;

  ngOnInit(): void {
    this.initVariables();
  }

  initVariables(): void {
    const { id } = this.activatedRoute.snapshot.params;
    this.currency = this.globalService.getCurrency();
    this.isMobileType = !!this.activatedRoute.snapshot.queryParams.isMobileType;
    this.companyName = this.activatedRoute.snapshot.queryParams.companyName;
    this.order$ = this.homeCleaningOrdersService.getOrderById(Number(id)).pipe(
      map(order => {
        if (order && order.hcOrderJobs) {
          // Sort the hcOrderJobs array by startDate.id
          order.hcOrderJobs = this.sortByStartDateId(order.hcOrderJobs);
        }
        return order;
      })
    );
  }

  hasProcessedJobs(hcOrderJobs: HcOrderJob[]): boolean {
    return !!hcOrderJobs.find(hcOrderJob=>[
      JobStatus.on_the_way,
      JobStatus.job_started,
      JobStatus.job_finished,
      JobStatus.job_canceled,
    ].includes(hcOrderJob.statusesUser.id));
  }

  getFreeSessionAmount(hcOrderJobs: HcOrderJob[]): number {
    return hcOrderJobs.reduce(((previousValue, currentValue, index) => previousValue + currentValue.sessionDiscountTotalAmount), 0)
  }

  getPurchaseDate(date: string): string {
    return this.globalService.formatDate(date, 'ddd DD MMMM YYYY, LT');
  }

  getPaymentMethods(order: HcOrder): string {
    if (order.userTransactions) {
      return order.userTransactions.reduce(
        (result, el) =>
          !el.deletedAt
            ? `${result ? `${result}, ` : ''}${el.paymentMethod.name.en} (${
              el.amount
            })`
            : result,
        '',
      );
    }

    return '-';
  }

  getGroupPrefix(groupId: number){
    return UniqueIdHelper.getPrefix(groupId);
  }

  sortByStartDateId(jobs: any[]): any[] {
    return [...jobs].sort((a, b) => {
      const aId = a.startDate && a.startDate.id ? a.startDate.id : 0;
      const bId = b.startDate && b.startDate.id ? b.startDate.id : 0;
      return aId - bId;
    });
  }
}
