import { GroupOrdersComponent } from '@shared/components/group-orders/group-orders.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GroupListComponent } from './components/group-list/group-list.component';
import { IsSuperAdminGuard } from '@app/shared/guards';
import { GroupPositioningComponent } from "@app/modules/groups/components/group-positioning/group-positioning.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full',
  },
  {
    path: 'list',
    component: GroupListComponent,
    canActivate: [IsSuperAdminGuard],
    data: {title: 'Group listing'}
  },
  {
    path: 'positioning',
    component: GroupPositioningComponent,
    canActivate: [IsSuperAdminGuard],
    data: {title: 'Group Positioning'}
  },
  {
    path: ':id/orders',
    component: GroupOrdersComponent,
    data: {title: 'Group listing'}
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GroupsRoutingModule { }
