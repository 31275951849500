import { Component, OnInit } from '@angular/core';
import {
  LaundryContract,
  LaundryContractInfo
} from '../../../../../shared/models/laundries/laundry';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../../../../shared/service/global.service';
import { LaundryService } from '../../../../../shared/service/laundry.service';
import { ToastService, IMyOptions } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-step-contract',
  templateUrl: './step-contract.component.html',
  styleUrls: ['./step-contract.component.css']
})
export class StepContractComponent implements OnInit {
  startDateText: any;
  endDateText: any;
  loader = false;
  percentageText = '0';
  amountText = 0;
  isCorporate = false;
  isMarketPlace = false;
  laundryId: number;
  laundryInfo: LaundryContractInfo;
  isUpdate: any;
  endDateOptions: IMyOptions = {};
  noContractMessage: boolean;
  page: number;
  currency: string;
  constructor(
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private laundryService: LaundryService,
    private router: Router,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.laundryId = this.route.snapshot.params.id;
    this.page = this.route.snapshot.queryParams.page;
    this.currency = this.globalService.currency;
    this.getLaundryContract();
  }

  saveLaundry() {
    this.loader = true;
    const contract = new LaundryContract();
    contract.laundry_id = this.laundryId;
    contract.comission_type = 'percentage';
    contract.is_corporate = this.isCorporate;
    contract.is_market_place = this.isMarketPlace;
    contract.contract_start_date = this.startDateText;
    contract.contract_end_date = this.endDateText;
    contract.laundry_commission = [
      {
        from_order: 0,
        to_order: 0,
        charge_value: this.percentageText,
        charge_type: 'percent'
      }
    ];
    contract.contract_document = [];
    contract.subscription_fee_monthly = this.amountText;
    if (!this.isUpdate) {
      this.laundryService
        .saveLaundryContract(this.globalService.headers, contract)
        .subscribe(
          response => {
            if (response.code === 200) {
              this.loader = false;
              this.router.navigate(['dashboard/laundries/laundry-list'], {
                queryParams: { page: this.page }
              });
            } else {
              this.toast.error(response.message);
            }
          }
        );
    } else {
      contract.contract_id = this.laundryInfo.id;
      this.laundryService
        .updateLaundryContract(this.globalService.headers, contract)
        .subscribe(
          response => {
            if (response.code === 200) {
              this.loader = false;
              this.toast.success(response.message);
            } else {
              this.toast.error(response.message);
            }
          }
        );
    }
  }

  selectStartDate(event) {
    const date = this.globalService.formatDate(
      this.globalService.datePlusDays(event.actualDateFormatted, 0).toString(),
      'YYYY-MM-DD'
    );
    this.endDateOptions = {
      disableUntil: {
        year: parseInt(date.split('-')[0], 10),
        month: parseInt(date.split('-')[1], 10),
        day: parseInt(date.split('-')[2], 10)
      }
    };
  }

  goBack() {
    this.router.navigate(['dashboard/laundries/laundry-list'], {
      queryParams: { page: this.page }
    });
  }

  private getLaundryContract() {
    this.laundryService
      .getLaundryContract(this.globalService.headers, this.laundryId)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.isUpdate = true;
            this.laundryInfo = response.data as LaundryContractInfo;
            this.percentageText = this.laundryInfo.laundry_commissions[0]
              ? this.laundryInfo.laundry_commissions[0].charge_value
              : '0';
            this.startDateText = this.laundryInfo.contract_start_date
              ? this.globalService.getDateFromStringForDatePicker(
                  this.laundryInfo.contract_start_date
                )
              : null;
              const date = this.globalService.formatDate(
                this.globalService.datePlusDays(this.laundryInfo.contract_start_date, 1).toString(),
                'YYYY-MM-DD'
              );
              this.endDateOptions = {
                disableUntil: {
                  year: parseInt(date.split('-')[0], 10),
                  month: parseInt(date.split('-')[1], 10),
                  day: parseInt(date.split('-')[2], 10)
                }
              };
            this.endDateText = this.laundryInfo.contract_end_date
              ? this.globalService.getDateFromStringForDatePicker(
                  this.laundryInfo.contract_end_date
                )
              : null;
            this.isCorporate = this.laundryInfo.is_corporate
              ? this.laundryInfo.is_corporate
              : null;
            this.isMarketPlace = this.laundryInfo.is_market_place
              ? this.laundryInfo.is_market_place
              : null;
            this.amountText = this.laundryInfo.subscription_fee_monthly ? this.laundryInfo.subscription_fee_monthly : 0;
          } else {
            this.isUpdate = false;
            this.noContractMessage = true;
          }
        },
        error => {
          this.noContractMessage = true;
        }
      );
  }
}
