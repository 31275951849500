import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CashbackCampaignsRoutingModule } from './cashback-campaign-routing.module';
import { CashbackCampaignListComponent } from './components/cashback-campaign-list/cashback-campaign-list.component';
import { CashbackCampaignEditDialogComponent } from './components/cashback-campaign-edit-dialog/cashback-campaign-edit-dialog.component';
import { CashbackCampaignFormComponent } from './components/cashback-campaign-edit-dialog/cashback-campaign-form/cashback-campaign-form.component';

@NgModule({
  declarations: [
    CashbackCampaignListComponent,
    CashbackCampaignEditDialogComponent,
    CashbackCampaignFormComponent,
  ],
  imports: [SharedModule, CashbackCampaignsRoutingModule],
})
export class CashbackCampaignsModule {}
