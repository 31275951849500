import { MenuItem } from './../../models/panel/menu-item';

export const MENU_ITEMS_LIST: Array<MenuItem> = [
  //#region Operations
  {
    id: 1,
    name: 'Operations',
    children: [
      {
        name: 'ActionPending Orders',
        navigationUrl: 'orders/orders-list',
      },
      {
        name: 'All Orders',
        navigationUrl: 'orders/orders-all',
      },
      {
        name: 'Customers',
        navigationUrl: 'customers/customers-list',
      },
      {
        name: 'Driver bookings logs',
        navigationUrl: 'drivers/bookings-logs',
        methodNameToDisplay: 'isPriorityBookingViewingManagementTeamBounded',
      },
      {
        name: 'JC delivery Orders',
        navigationUrl: 'jc-delivery/order-management',
      },
      {
        name: 'JC delivery Customers',
        navigationUrl: 'jc-delivery/users-management',
      },
      {
        name: 'Reviews',
        navigationUrl: 'reviews/reviews-list',
        methodNameToDisplay: 'isOpsLeadersBounded',
      },
      {
        name: 'Close Monthly Orders',
        navigationUrl: 'finance/close-month',
      },
      {
        name: 'Marketing User Segments',
        navigationUrl: 'marketing/users-segments',
      },
      {
        name: 'Order Cancellation Request',
        navigationUrl: 'orders/cancel-requests',
      },
    ],
  },

  //#endregion

  //#region Verticals
  {
    id: 2,
    name: 'CARWASH.GARAGE',
    children: [
      {
        name: 'CARWASH.COMPANY_GARAGE',
        navigationUrl: 'carwash/companies',
      },
      {
        name: 'CARWASH.VERTICAL_ORDERS_MAP',
        navigationUrl: 'groups-orders-map',
        methodNameToDisplay: 'isLiveOrdersManagementTeamBounded',
      },
      /** rest of items will be loaded from groups API */
    ],
  },
  //#endregion

  //#region Home Cleaning
  {
    id: 3,
    name: 'HOME_CLEANING.TITLE',
    isGeneratedPagesFirst: true,
    children: [
      {
        name: 'HOME_CLEANING.PRIORITY_BOOKING_VIEWING.MENU_TITLE',
        navigationUrl: 'homeclean/priority-booking-viewing',
        methodNameToDisplay: 'isPriorityBookingViewingManagementTeamBounded',
      },
    ],
  },
  //#endregion
  // carWash
  {
    id: 11,
    name: 'CAR_WASH.TITLE',
    methodNameToDisplay: 'isSuperAdminBounded',
    children: [
      {
        name: 'CAR_WASH.PACKAGES.TITLE',
        navigationUrl: 'carwash/packages',
      },
      {
        name: 'CAR_WASH.ADD_ONS.TITLE',
        navigationUrl: 'carwash/add-ons',
      },
    ],
  },

  //#region JC Offers
  {
    id: 4,
    name: 'JC Offers',
    methodNameToDisplay: 'isCouponUserBounded',
    children: [
      {
        name: 'Offers',
        navigationUrl: 'offers/offer-list',
      },
      {
        name: 'Coupons',
        navigationUrl: 'offers/coupon-list',
      },
      {
        name: 'Campaigns',
        navigationUrl: 'campaigns/campaigns-list',
      },
      {
        name: 'JC Credit Packages',
        navigationUrl: 'jc-credit-offers/jc-credit-offers-list',
      },
    ],
  },
  //#endregion

  //#region Sales
  {
    id: 5,
    name: 'Sales',
    children: [
      {
        name: 'Laundries',
        navigationUrl: 'laundries/laundry-list',
      },
      //   { // hidding as it was *ngIf="false" in panel component
      //     name: 'Laundry Positions',
      //     navigationUrl: 'laundries/laundry-pos',
      //   },
      {
        name: 'Cars',
        navigationUrl: 'cars/cars-list',
      },
      {
        name: 'Devices',
        navigationUrl: 'branches/devices',
        methodNameToDisplay: 'isCorpTeamBounded',
      },
      {
        name: 'Driver bookings logs',
        navigationUrl: 'drivers/bookings-logs',
      },
      {
        name: 'KPI Setup',
        navigationUrl: 'sales/kpi-setup',
      },
      {
        name: 'Corporate Customer list',
        navigationUrl: 'corporate/corp-customer-list',
        methodNameToDisplay: 'isCorpTeamBounded',
      },
      {
        name: 'Home ads',
        navigationUrl: 'ads/sales/list/',
      },
    ],
  },
  //#endregion

  //#region Marketing
  {
    id: 10,
    name: 'Marketing',
    methodNameToDisplay: 'isMarketingTeamBounded',
    children: [
      {
        name: 'Post order ads',
        navigationUrl: 'ads/marketing/list/',
      },
    ],
  },
  //#endregion

  //#region Logistics
  {
    id: 6,
    name: 'Logistics',
    methodNameToDisplay: 'isLogTeamBounded',
    children: [
      {
        name: 'Laundries',
        navigationUrl: 'logistics/laundry-list',
      },
      {
        name: 'Drivers',
        navigationUrl: 'logistics/drivers/logistics-driver',
      },
      {
        name: 'Cars',
        navigationUrl: 'logistics/cars/logistics-cars',
      },
      {
        name: 'Orders',
        navigationUrl: 'logistics/log-orders',
      },
      {
        name: 'Partners',
        navigationUrl: 'partners/partners-management/partners-list',
        methodNameToDisplay: 'showPartnersBounded',
      },
      {
        name: 'Suppliers',
        navigationUrl: 'suppliers/supplier-list',
        methodNameToDisplay: 'showPartnersBounded',
      },
      {
        name: 'Orders Map',
        navigationUrl: 'logistics/orders-map',
      },
    ],
  },
  //#endregion

  //#region Finance
  {
    id: 7,
    name: 'Finance',
    methodNameToDisplay: 'isFinanceTeamBounded',
    children: [
      {
        name: 'Online Payment Charges',
        navigationUrl: 'finance/payment-methods',
      },
      {
        name: 'Laundry Billing (detailed)',
        navigationUrl: 'finance/laundry-billing',
      },
      {
        name: 'Laundry Billing (Summary)',
        navigationUrl: 'finance/laundry-detailed-billing',
      },
      {
        name: 'Export JC Invoices',
        navigationUrl: 'finance/export-invoices',
      },
      {
        name: 'REFERRAL.CASHOUT_REQUETS.MENU_ITEM',
        navigationUrl: 'referral-cashout-requests',
      },
      {
        name: 'Provider commission',
        navigationUrl: 'finance/provider-commission',
      },
    ],
  },
  //#endregion

  //#region Settings
  {
    id: 8,
    name: 'SETTINGS',
    children: [
      {
        name: 'COUNTRY.LIST',
        navigationUrl: 'countries/countries-list',
        methodNameToDisplay: 'isSuperAdminBounded',
      },
      {
        name: 'ITEM.NAV.LIST_ITEMS',
        navigationUrl: 'items/item-list',
      },
      {
        name: 'ITEM.NAV.LIST_CAT',
        navigationUrl: 'items/category-list',
      },
      {
        name: 'USERS.NAV.CREATE_USERS',
        navigationUrl: 'users/create-users/0',
      },
      {
        name: 'USERS.NAV.LIST_USERS',
        navigationUrl: 'users/list-users',
      },
      {
        name: 'SEGMENT.MENU_ITEM',
        navigationUrl: 'user-segments',
        methodNameToDisplay: 'isSuperAdminBounded',
      },
      {
        name: 'VARIANT.VARIANTS',
        navigationUrl: 'variants',
        methodNameToDisplay: 'isSuperAdminBounded',
      },
      {
        name: 'GROUP.JC_GROUPS',
        navigationUrl: 'groups',
        methodNameToDisplay: 'isSuperAdminBounded',
      },
      {
        name: 'CASHBACK.CAMPAIGN.MENU_ITEM',
        navigationUrl: 'cashback-campaigns',
        methodNameToDisplay: 'isSuperAdminBounded',
      },
      {
        name: 'REFERRAL.CAMPAIGN.MENU_ITEM',
        navigationUrl: 'referral-campaigns',
        methodNameToDisplay: 'isSuperAdminBounded',
      },
    ],
  },
  //#endregion

  //#region Consfigurations
  {
    id: 8,
    name: 'CONFIGURATIONS',
    methodNameToDisplay: 'isSuperAdminBounded',
    children: [
      {
        name: 'CRUD.PAGE_GENERATOR',
        navigationUrl: 'crud/page-generator',
      },
    ],
  },
  //#endregion
];
