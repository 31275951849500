import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { VariantHttpService } from '@shared/service/variant/variant-http.service';
import { ApiResponse, PageInfo } from '@shared/models/global/apiResponse';
import { SubVariant, Variant } from '@shared/models';
import { SelectOptions } from '@app/shared/models/global/response';

@Injectable({ providedIn: 'root' })
export class VariantService {
  readonly pagination$: Subject<PageInfo> = new Subject<PageInfo>();
  selectedVariant: Variant;
  loadedVariants: Array<Variant> = [];

  constructor(private readonly variantHttpService: VariantHttpService) { }

  getVariants(filters?: Record<string, string>, page: number = 1): Observable<Variant[]> {
    const toData = (res: ApiResponse<Variant[]>) => {
      this.loadedVariants = res.data;
      return res.data;
    };

    const toPagination = (res: ApiResponse<Variant[]>) =>
      this.pagination$.next(res.pageInfo);

    return this.variantHttpService
      .getVariants(filters, page)
      .pipe(tap(toPagination), map(toData));
  }

  createVariant(data: Partial<Variant>): Observable<Variant> {
    const toVariant = (res: ApiResponse<Variant>) => res.data;

    return this.variantHttpService.createVariant(data).pipe(map(toVariant));
  }

  updateVariant(id: number, data: Partial<Variant>): Observable<Variant> {
    const toVariant = (res: ApiResponse<Variant>) => res.data;

    return this.variantHttpService.updateVariant(id, data).pipe(map(toVariant));
  }

  deleteVariant(id: number): Observable<ApiResponse<void>> {
    return this.variantHttpService.deleteVariant(id);
  }

  createSubVariant(
    data: Partial<SubVariant>,
  ): Observable<ApiResponse<SubVariant>> {
    return this.variantHttpService.createSubVariant(data);
  }

  updateSubVariant(
    id: number,
    data: Partial<SubVariant>,
  ): Observable<ApiResponse<SubVariant>> {
    return this.variantHttpService.updateSubVariant(id, data);
  }

  deleteSubVariant(id: number): Observable<ApiResponse<void>> {
    return this.variantHttpService.deleteSubVariant(id);
  }

  assignCountries(variantId: number, selectedCountryId: Array<number>) {
    // TODO: implement this method
    console.log(variantId, selectedCountryId);
    return of({});
  }
  getVariantCountries(variantId: number) {
    // TODO: implement this method
    console.log(variantId);
    return of([]);
  }

  getVariantStatuses(
    isOutCall: boolean,
    variantId: number,
  ): Observable<Array<SelectOptions<number>>> {
    return this.variantHttpService.getVariantStatuses(isOutCall, variantId);
  }

  getDesignTypes(): Observable<Array<SelectOptions<string>>> {
    // TODO: load types from API
    const designTypes: SelectOptions<string>[] = [
      { label: 'Laundry Design', value: 'laundry' },
      { label: 'in call design', value: 'carwasHome' },
      { label: 'out call design', value: 'carwashGarage' },
      { label: 'home cleaning design', value: 'homeCleaning' },
    ];
    return of(designTypes);
  }

  getCallTypes(): Observable<Array<SelectOptions<string>>> {
    // TODO: load types from API
    const callTypes: SelectOptions<string>[] = [
      { label: 'in call', value: 'inCall' },
      { label: 'out call', value: 'outCall' },
    ];
    return of(callTypes);
  }
}
