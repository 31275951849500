import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BackEndResponse } from '../models/global/response';
import { CarToBeSaved } from '../models/cars/cars';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CarsService {
  readonly GET_CARS = environment.serverUrl + 'laundry/car-list';
  readonly ADD_CARS = environment.serverUrl + 'laundry/car';
  readonly CAR_DETAILS = environment.serverUrl + 'laundry/car-detail/';

  constructor(private http: HttpClient) {}

  setHeaders(headers) {
    return new HttpHeaders(headers);
  }

  getCars(headers, params?: any): Observable<BackEndResponse> {
    return this.http
      .get<BackEndResponse>(this.GET_CARS, {
        headers: this.setHeaders(headers),
        params: new HttpParams({ fromObject: params })
      })
      .pipe(tap(data => console.log(data)));
  }

  saveCar(headers, car: CarToBeSaved): Observable<BackEndResponse> {
    return this.http.post<BackEndResponse>(this.ADD_CARS, car, {
      headers: this.setHeaders(headers)
    });
  }

  getCarDetails(headers, carId: number) {
    return this.http
      .get<BackEndResponse>(this.CAR_DETAILS + carId, {
        headers: this.setHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }
}
