import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeCleaningCompanyBranchListComponent } from '@app/modules/homeclean/modules/homeclean-branch/components/home-cleaning-company-branch-list/home-cleaning-company-branch-list.component';
import { HomeCleaningCompanyBranchRoutingModule } from '@app/modules/homeclean/modules/homeclean-branch/home-cleaning-company-branch-rouiting.module';
import { CardsModule } from 'ng-uikit-pro-standard';
import { SharedModule } from '@app/modules/homeclean/shared/shared.module';

@NgModule({
  declarations: [HomeCleaningCompanyBranchListComponent],
  imports: [
    CommonModule,
    HomeCleaningCompanyBranchRoutingModule,
    CardsModule,
    SharedModule,
  ],
})
export class HomeCleaningCompanyBranchModule {}
