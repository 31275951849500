import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Subject, Observable, concat } from 'rxjs';
import { takeUntil, toArray } from 'rxjs/operators';

import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { ReferralCashoutRequest } from '@shared/models';
import { ReferralCashoutRequestService } from '@shared/service/referral-cashout-requests';
import { GlobalService } from '@app/shared/service/global.service';

@Component({
  templateUrl: './referral-cashout-requests-dialog.component.html',
  styleUrls: ['./referral-cashout-requests-dialog.component.scss'],
})
export class ReferralCashoutDialogComponent implements OnInit {
  path: string;

  formData: Partial<ReferralCashoutRequest>;
  isLoading: boolean;
  isFormValid: boolean;

  private readonly destroy$: Subject<void> = new Subject<void>() ;
  currency: string;
  decimals: number;
  totalCashout = 10;
  totalRequestedAmount: number;
  totalTransactionFee: number;
  totalAmount: number;

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: {
      type: 'details' | 'approve' | 'decline';
      selected: ReferralCashoutRequest[];
    },
    private readonly dialogRef: DialogRef<ReferralCashoutDialogComponent>,
    private readonly referralCashoutRequestService: ReferralCashoutRequestService,
    private readonly globalService: GlobalService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.currency = this.globalService.currency;
    this.decimals = +localStorage.getItem('decimal') || 2;
    this.setPath();
    this.setTotals();
  }

  close(): void {
    this.dialogRef.close();
  }

  copyToClipboard(value: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  submit(): void {
    this.isLoading = true;

    const subscribers$: Observable<ReferralCashoutRequest>[] = [];

    this.data.selected.forEach((referralCashoutRequest) => {
      subscribers$.push(
        this.referralCashoutRequestService.updateReferralCashoutRequest(
          referralCashoutRequest.id,
          {
            status: this.data.type === 'approve' ? 'approved' : 'rejected',
          },
        ),
      );
    });

    /** calling observables sequentially */
    concat(...subscribers$)
      .pipe(toArray(), takeUntil(this.destroy$))
      .subscribe(
        () => this.dialogRef.close(true),
        (error) => {
          this.isLoading = false;
          this.cdr.markForCheck();
        },
      );
  }

  private setPath(): void {
    this.path = `REFERRAL.CASHOUT_REQUETS.${this.data.type.toUpperCase()}`;
  }

  private setTotals(): void {
    this.totalRequestedAmount = 0;
    this.totalTransactionFee = 0;

    this.data.selected.forEach(({ requestedAmount, feeCollected }) => {
      this.totalRequestedAmount += requestedAmount || 0;
      this.totalTransactionFee += feeCollected || 0;
    });

    this.totalCashout = this.totalRequestedAmount - this.totalTransactionFee;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
