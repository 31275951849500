import { NgModule } from '@angular/core';
import { CarsListComponent } from './components/cars-list/cars-list.component';
import { CarsRoutingModule } from './cars-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CarCreateComponent } from './components/car-create/car-create.component';

@NgModule({
  imports: [CarsRoutingModule, SharedModule.forRoot()],
  declarations: [CarsListComponent, CarCreateComponent]
})
export class CarsModule {}
