import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LogLaundryListComponent } from './components/laundry-list/log-laundry-list.component';
import { LogBranchListComponent } from './components/branch-list/log-branch-list.component';
import { LogDriversListComponent } from './components/drivers-list/log-drivers-list.component';
import { DriverManagementComponent } from './components/driver-management/driver-management.component';
import { LogAllDriversListComponent } from './components/driver-management/log-all-drivers-list/log-all-drivers-list.component';
import { CarManagementComponent } from './components/car-management/car-management.component';
import { LogCarsListComponent } from './components/car-management/log-cars-list/log-car-list.component';
import { LogisticOrdersComponent } from './components/orders/logistic-orders/logistic-orders.component';
import { LogisticOrderDetailsComponent } from './components/orders/logistic-order-details/logistic-order-details.component';
import { AssignAreaDriverComponent } from './components/driver-management/assign-area-driver/assign-area-driver.component';
import { OrdersMapComponent } from './components/orders-map/orders-map.component';
const routes: Routes = [
  {
    path: 'laundry-list',
    component: LogLaundryListComponent,
    data: {
      title: 'List of Logistics Laundries'
    }
  },
  {
    path: 'log-orders',
    component: LogisticOrdersComponent,
    data: {
      title: 'List of Logistics Orders'
    }
  },
  {
    path: 'log-order-details/:orderId',
    component: LogisticOrderDetailsComponent,
    data: {
      title: 'Logistics Order Details'
    }
  },
  {
    path: 'branch-list/:id',
    component: LogBranchListComponent,
    data: {
      title: 'List of Logistics Branches'
    }
  },
  { path: 'drivers-list/:branchId', component: LogDriversListComponent },
  {
    path: 'drivers',
    component: DriverManagementComponent,
    children: [
      {
        path: 'logistics-driver',
        component: LogAllDriversListComponent,
        data: {
          title: 'List of Logistics Drivers'
        }
      },
      {
        path: 'assign-driver',
        component: AssignAreaDriverComponent,
        data: {
          title: 'Assign areas to Logistics Drivers'
        }
      }
    ]
  },
  {
    path: 'cars',
    component: CarManagementComponent,
    children: [
      {
        path: 'logistics-cars',
        component: LogCarsListComponent,
        data: {
          title: 'List of Logistics Cars'
        }
      }
    ]
  },
  {
    path: 'orders-map',
    component: OrdersMapComponent,
    data: {
      title: 'Orders Map'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LogisiticsRoutingModule {}
