import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { VariantsRoutingModule } from './variant-routing.module';
import { VariantListComponent } from './components/variant-list/variant-list.component';
import { VariantEditDialogComponent } from './components/variant-edit-dialog/variant-edit-dialog.component';
import { VariantFormComponent } from './components/variant-edit-dialog/variant-form/variant-form.component';
import { AssignCountryDialogComponent } from './components/assign-country-dialog/assign-country-dialog.component';

@NgModule({
  declarations: [
    VariantListComponent,
    VariantEditDialogComponent,
    VariantFormComponent,
    AssignCountryDialogComponent,
  ],
  imports: [SharedModule, VariantsRoutingModule],
})
export class VariantsModule {}
