import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { HomeCleaningCompanyBranch } from '@app/modules/homeclean/modules/homeclean-branch/shared/models/home-cleaning-company-branch-model';

@Injectable({ providedIn: 'root' })
export class HomeCleaningCompanyBranchService {
  getBranches(): Observable<HomeCleaningCompanyBranch[]> {
    // temporary mock data
    return of([
      {
        id: '1',
        code: '2031',
        company_name: {
          ar: 'home cleaning company name ar',
          en: 'home cleaning company name',
        },
        operating_at: {
          ar: 'operating at ar',
          en: 'operating at',
        },
        manager_name: {
          ar: 'manager name ar',
          en: 'manager name',
        },
        manager_mobile: '4442323',
        lowest_price: '0.521',
        highest_price: '0.918',
        gmaps_link: 'gmaps link',
        open_time: '11: 30: 00',
        close_time: '15: 30: 00',
      },
    ]);
  }
}
