import { map } from 'rxjs/operators';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HcService, HcServicePaymentMethod } from '@app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class HCServicePaymentMethodsService {
  private readonly hcServicesPaymentMethodUrl = `${environment.jcDeliveryServer}payment-methods/hc-services`;
  constructor(private readonly http: HttpClient) {}

  getHcServicePaymentMethods(
    hcServiceId: number,
  ): Observable<Array<HcServicePaymentMethod>> {
    const toData = (res) => res.data;
    return this.http
      .get<ApiResponse<Array<HcService>>>(this.hcServicesPaymentMethodUrl, {
        params: { hcServiceId: hcServiceId.toString() },
      })
      .pipe(map(toData));
  }

  assignServicePaymentMethod(
    body: Partial<HcServicePaymentMethod>,
  ): Observable<HcServicePaymentMethod> {
    const toData = (res) => res.data;
    return this.http
      .post<ApiResponse<HcServicePaymentMethod>>(`${this.hcServicesPaymentMethodUrl}`, body)
      .pipe(map(toData));
  }

  removeServicePaymentMethod(id: number): Observable<HcServicePaymentMethod> {
    const toData = (res) => res.data;
    return this.http
      .delete<ApiResponse<HcServicePaymentMethod>>(
        `${this.hcServicesPaymentMethodUrl}/${id}`,
      )
      .pipe(map(toData));
  }
}
