import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { GlobalService } from '../../../../shared/service/global.service';
import { CountryService } from '../../../../shared/service/country.service';
import {
  Country,
  Governorate,
  AreaToBeSaved,
  CountrySLA
} from '../../../../shared/models/countries/country';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { Router } from '@angular/router';
import { CategoriesService } from 'src/app/shared/service/categories.service';

@Component({
  selector: 'app-create-country',
  templateUrl: './create-country.component.html',
  styleUrls: ['./create-country.component.css']
})
export class CreateCountryComponent implements OnInit {
  @ViewChild('createAreaModal', { static: true })
  createAreaModal: ModalDirective;
  createCountry: UntypedFormGroup;
  createArea: UntypedFormGroup;
  createCity: UntypedFormGroup;
  currencyForm: UntypedFormGroup;
  slaForm: UntypedFormGroup;
  capacityForm: UntypedFormGroup;
  loader: boolean;
  stepOne: boolean;
  stepTwo: boolean;
  stepThree: boolean;
  currentCountry: Country;
  currentGovernorate: Governorate;
  savedAreas: AreaToBeSaved[];
  countryWithAreas: number;
  countrySla: CountrySLA = new CountrySLA();
  polylines: string;
  file: File;
  fileError: {
    error: string;
    success: string;
  };
  loaderFile = false;
  constructor(
    protected countryService: CountryService,
    protected global: GlobalService,
    protected router: Router,
    protected toast: ToastService,
    private catservice: CategoriesService
  ) {}

  ngOnInit() {
    this.savedAreas = [];
    this.stepOne = this.stepTwo = this.stepThree = false;
    this.loader = false;
    this.initForm();
  }

  saveCountry() {
    this.loader = true;
    this.countryWithAreas = 1;
    const country = {
      translations: [
        { language_id: 1, name: this.createCountry.controls.nameEn.value },
        { language_id: 2, name: this.createCountry.controls.nameAr.value }
      ],
      polylines: this.polylines
    };

    this.countrySla.country_url = this.createCountry.controls.countryUrl.value
      ? this.createCountry.controls.countryUrl.value + '.justclean.com'
      : null;

    this.countryService.saveCountry(this.global.headers, country).subscribe(
      response => {
        if (response.code === 200) {
          this.currentCountry = response.data;
          this.loader = false;
          this.stepOne = true;
          console.log(this.currentCountry);
        } else {
          this.loader = false;
          this.toast.error(response.message);
        }
      },
      error => {
        this.loader = false;
      }
    );
  }
  /*
  saveCity() {
    if (this.currentCountry.id) {
      this.loader = true;
      const city = {
        country_id: this.currentCountry.id,
        translations: [
          {
            language_id: 1,
            name: this.createCity.controls.nameEn.value
          },
          {
            language_id: 2,
            name: this.createCity.controls.nameAr.value
          }
        ],
        latitude: '00000000',
        longitude: '0000000'
      };
      this.countryService.saveGovernorate(this.global.headers, city).subscribe(
        response => {
          this.currentGovernorate = response.data;
          console.log(this.currentGovernorate);
          this.loader = false;
        },
        error => {
          this.loader = false;
        }
      );
    }
  }

  saveArea() {
    if (this.currentCountry && this.currentGovernorate) {
      this.loader = true;
      const area = {
        country_id: this.currentCountry.id,
        governorate_id: this.currentGovernorate.id,
        country_with_area_flag: this.countryWithAreas,
        areas: [
          {
            translations: [
              {
                language_id: 1,
                name: this.createArea.controls.areaEn.value
              },
              {
                language_id: 2,
                name: this.createArea.controls.areaAr.value
              }
            ]
          }
        ]
      };

      this.countryService.saveArea(this.global.headers, area).subscribe(
        response => {
          if (response.code === 200) {
            this.savedAreas.push(area);
            this.loader = false;
          }
          this.loader = false;
          if (this.createAreaModal) this.createAreaModal.hide();
        },
        error => {
          this.loader = false;
        }
      );
    }
  }

  doneCity() {
    this.stepTwo = true;
  }
  */

  saveSla() {
    this.loader = true;
    const tax = this.currencyForm.controls.tax.value as number;
    this.countrySla.country_id = this.currentCountry.id;
    this.countrySla.translations = [
      { language_id: 1, name: this.createCountry.controls.nameAr.value },
      { language_id: 2, name: this.createCountry.controls.nameEn.value }
    ];
    this.countrySla.accept_order = '' + this.slaForm.controls.acceptOrder.value;
    this.countrySla.confirm_order_normal =
      '' + this.slaForm.controls.confirmOrderNormal.value;
    this.countrySla.confirm_order_fast =
      '' + this.slaForm.controls.confirmOrderFast.value;
    this.countrySla.complete_order =
      '' + this.slaForm.controls.completeOrder.value;
    this.countrySla.warning = '' + this.slaForm.controls.warning.value;
    this.countrySla.late = '' + this.slaForm.controls.late.value;
    this.countrySla.urgent = '' + this.slaForm.controls.urgent.value;
    this.countrySla.currency = '' + this.currencyForm.controls.currency.value;
    this.countrySla.currency_symbol =
      '' + this.currencyForm.controls.symbol.value;
    this.countrySla.tax = tax.toFixed(1).toString();
    this.countrySla.currency_decimal =
      '' + this.currencyForm.controls.decimal.value;
    this.countrySla.thousands_seperator =
      '' + this.currencyForm.controls.thousands.value;
    this.countrySla.base_coverage =
      '' + this.capacityForm.controls.baseCoverage.value;
    this.countrySla.base_capacity =
      '' + this.capacityForm.controls.baseCapacity.value;
    this.countrySla.additional_driver_hour =
      '' + this.capacityForm.controls.additionalDriverOrderPerHour.value;
    this.countrySla.additional_driver_radius =
      '' + this.capacityForm.controls.additionalDriverRadius.value;

    this.countryService.saveSLA(this.global.headers, this.countrySla).subscribe(
      response => {
        if (response.code === 200) {
          this.stepThree = true;
          this.loader = false;
          this.router.navigate(['dashboard/countries/countries-list']);
        } else {
          this.loader = false;
          this.toast.error(response.message);
        }
      },
      error => {
        this.loader = false;
      }
    );
  }

  private initForm() {
    this.createCountry = new UntypedFormGroup({
      nameAr: new UntypedFormControl(null, [Validators.required]),
      nameEn: new UntypedFormControl(null, Validators.required),
      countryUrl: new UntypedFormControl(null)
    });

    this.createCity = new UntypedFormGroup({
      nameAr: new UntypedFormControl(null, [Validators.required]),
      nameEn: new UntypedFormControl(null, Validators.required),
      radioArea: new UntypedFormControl(1)
    });

    this.initCreateAreaForm();

    this.createCity.controls.radioArea.valueChanges.subscribe(value => {
      this.countryWithAreas = value;
    });

    this.currencyForm = new UntypedFormGroup({
      currency: new UntypedFormControl(null, [Validators.required]),
      symbol: new UntypedFormControl(null, Validators.required),
      tax: new UntypedFormControl(null, Validators.required),
      decimal: new UntypedFormControl(null, Validators.required),
      thousands: new UntypedFormControl(1)
    });

    this.slaForm = new UntypedFormGroup({
      acceptOrder: new UntypedFormControl(0, [Validators.required]),
      confirmOrderNormal: new UntypedFormControl(0, Validators.required),
      confirmOrderFast: new UntypedFormControl(0, Validators.required),
      completeOrder: new UntypedFormControl(0, Validators.required),
      warning: new UntypedFormControl(0, Validators.required),
      late: new UntypedFormControl(0, Validators.required),
      urgent: new UntypedFormControl(0, Validators.required)
    });

    this.capacityForm = new UntypedFormGroup({
      baseCoverage: new UntypedFormControl(0, [Validators.required]),
      baseCapacity: new UntypedFormControl(0, Validators.required),
      additionalDriverOrderPerHour: new UntypedFormControl(0, Validators.required),
      additionalDriverRadius: new UntypedFormControl(0, Validators.required)
    });

    this.file = new File([''], 'Upload Document(*)', {
      type: 'KML'
    });
  }

  private initCreateAreaForm() {
    this.createArea = new UntypedFormGroup({
      areaEn: new UntypedFormControl(null, [Validators.required]),
      areaAr: new UntypedFormControl(null, Validators.required)
    });
  }

  onHidden() {
    this.initCreateAreaForm();
  }

  loadSplashEnglish(event): void {
    this.fileError = {
      error: null,
      success: null,
    };
    this.file = event.target.files[0];
    console.log(this.file);
    if (
      (this.file.type.toLowerCase().includes('image') &&
        this.file.size / 1024 <= 200) ||
      (this.file.type.toLowerCase().includes('pdf') &&
        this.file.size / 1024 <= 2048) ||
      (this.file.type.toLowerCase() === '' && this.file.size / 1024 <= 2048)
    ) {
      this.loaderFile = true;
      this.global['headersImage']['country'] = this.createCountry.controls.nameEn.value;
      this.global['headersImage']['Access-Control-Allow-Origin'] = '*';
      this.catservice
        .imageUpload(this.file, 'kml')
        .subscribe(
          (response) => {
            if (response.code === 200) {
              this.fileError.error = null;
              this.fileError.success = response.message;
              this.loaderFile = false;
              this.polylines = response.data;
            } else {
              this.fileError = {
                error: response.message,
                success: null,
              };
              this.file = new File([''], 'Upload New Document/KML');
              this.loaderFile = false;
            }
          },
          (error) => {
            this.file = new File([''], 'Upload New Document/KML');
            this.fileError = {
              error: error.error.message,
              success: null,
            };
            this.loaderFile = false;
          }
        );
    } else {
      this.fileError = {
        error: this.file.type.toLowerCase().includes('image')
          ? 'file size must be less or equals 200kb '
          : 'file size must be less or equals 2mb',
        success: null,
      };
      this.loaderFile = false;
      console.log(this.fileError);
    }
  }

  removeFile() {
    this.file = new File([''], 'Upload New Document/KML');
    this.fileError = null;

    this.polylines = null;
  }
}
