// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td {
  text-align: center;
    vertical-align: middle;
}

th {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/order/components/order-cancel-requests/order-cancel-requests.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;IAChB,sBAAsB;AAC1B;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["td {\n  text-align: center;\n    vertical-align: middle;\n}\n\nth {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
