import { GlobalService } from 'src/app/shared/service/global.service';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ReferralSettingHttpService } from '@shared/service/referral-settings/referral-settings-http.service';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { ReferralSetting } from '@shared/models';
import { PageInfo } from '@app/shared/models/global/response';

@Injectable({ providedIn: 'root' })
export class ReferralSettingService {
  readonly pagination$: Subject<PageInfo> = new Subject<PageInfo>();

  constructor(
    private readonly referralSettingHttpService: ReferralSettingHttpService,
    private readonly globalService: GlobalService,
  ) {}

  getReferralSetting(userId: number): Observable<ReferralSetting> {
    const toData = (res: ApiResponse<ReferralSetting>) => res.data;

    return this.referralSettingHttpService
      .getReferralSetting(userId, this.globalService.countryId)
      .pipe(map(toData));
  }

  updateReferralSetting(
    userId: number,
    data: Partial<ReferralSetting>,
  ): Observable<ReferralSetting> {
    const toReferralSetting = (res: ApiResponse<ReferralSetting>) => res.data;

    return this.referralSettingHttpService
      .updateReferralSetting(userId, data)
      .pipe(map(toReferralSetting));
  }
}
