import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { FormStatus } from '@shared/enums/form-status.enum';
import { CarwashCompany } from '@shared/models';
import { ImageUploaderHttpService } from '@shared/service/image-uploader-http.service';
import { CarwashCompanyService } from '@shared/service/carwash-company';

@Component({
  templateUrl: './carwash-company-edit-dialog.component.html',
})
export class CarwashCompanyEditDialogComponent implements OnInit {
  isEditMode: boolean;
  isDisableButton: boolean;
  formData: Partial<CarwashCompany>;
  imageCompanyLogoLink: string;
  imageOperatingLogoLink: string;
  isLoading: boolean;

  private readonly destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: { company: CarwashCompany | null; groupId: number } | any,
    private readonly dialogRef: DialogRef<CarwashCompanyEditDialogComponent>,
    private readonly carwashCompanyService: CarwashCompanyService,
    private readonly imageUploaderHttpService: ImageUploaderHttpService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.isEditMode = !!this.data.company;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(): void {
    this.dialogRef.close();
  }

  formChanged(company: Partial<CarwashCompany>): void {
    this.formData = {
      ...company,
      latitude: +company.latitude,
      longitude: +company.longitude,
    };
  }

  formStatusChanged(status: FormStatus): void {
    this.isDisableButton = status === FormStatus.Invalid;
  }

  createCompany(): void {
    const data: Partial<CarwashCompany> = {
      ...this.formData,
      ...(this.imageCompanyLogoLink && {
        companyLogoUrl: {
          en: this.imageCompanyLogoLink,
          ar: this.imageCompanyLogoLink,
        },
      }),
      ...(this.imageOperatingLogoLink && {
        operatingLogoUrl: {
          en: this.imageOperatingLogoLink,
          ar: this.imageOperatingLogoLink,
        },
      }),
      ...(this.data.groupId ? { groupId: this.data.groupId } : {}),
    };
    const action$ = this.isEditMode
      ? this.carwashCompanyService.updateCompany(this.data.company.id, data)
      : this.carwashCompanyService.createCompany(data);

    this.isLoading = true;
    action$
      .pipe(
        tap(() => {
          this.isLoading = false;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (company: CarwashCompany) => {
          this.dialogRef.close(company);
        },
        (error) => {
          this.isLoading = false;
          this.cdr.markForCheck();
        },
      );
  }

  uploadCompanyLogoFile(file: File): void {
    this.uploadImage(file)
      .pipe(takeUntil(this.destroy$))
      .subscribe((link: string) => {
        this.imageCompanyLogoLink = link;
      });
  }

  changeOperatingFile(file: File): void {
    this.uploadImage(file)
      .pipe(takeUntil(this.destroy$))
      .subscribe((link: string) => {
        this.imageOperatingLogoLink = link;
      });
  }

  private uploadImage(file): Observable<string> {
    this.isLoading = true;

    return this.imageUploaderHttpService.imageUpload(file).pipe(
      tap(() => {
        this.cdr.markForCheck();
        this.isLoading = false;
      }),
    );
  }
}
