import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sub-reason',
  templateUrl: './sub-reason.component.html',
  styleUrls: ['./sub-reason.component.css']
})
export class SubReasonComponent implements OnInit {
  @Input() label: string;
  @Input() value: number;
  @Input() parent: number;
  @Input() selectedSubReason: number;
  @Output() selectedSubReasonEventEmitter: EventEmitter<number> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  selectSubReason(id: number) {
    this.selectedSubReasonEventEmitter.emit(id);
  }

}
