import { Status } from '@shared/enums/status.enum';
import { GlobalService } from 'src/app/shared/service/global.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateSlot, TimeSlotTime } from '@app/shared/models';
import { ApiResponse } from '@app/shared/models/global/apiResponse';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class HcServiceOpeningHoursService {
  private readonly hcServiceOpeningHoursUrl = `${environment.jcDeliveryServer}hc-service-package-hours/opening-hours`;

  constructor(
    private readonly http: HttpClient,
    private readonly globalService: GlobalService,
  ) {}

  getActiveOpeningHours(
    packageHoursId: number,
    groupId: number,
  ): Observable<DateSlot[]> {
    const params = { id: packageHoursId, globalGroupId: groupId };

    const toData = (res: ApiResponse<DateSlot[]>) => {
      return res.data.map((item) => this.getMappedItem(item));
    };
    return this.http
      .get<ApiResponse<DateSlot[]>>(this.hcServiceOpeningHoursUrl, {
        params: params as any,
      })
      .pipe(map(toData));
  }

  private getMappedItem(item: DateSlot): DateSlot {
    return {
      ...item,
      timeSlotTimes: item.timeSlotTimes
        .filter((time) => time.status === Status.Active)
        .map((time) => this.getMappedTime(time)),
    };
  }

  private getMappedTime(item: TimeSlotTime): TimeSlotTime {
    return {
      ...item,
      start: this.globalService.utcToLocalTime(item.start, 'H:mm'),
      end: this.globalService.utcToLocalTime(item.end, 'H:mm'),
    } as TimeSlotTime;
  }
}
