// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ad-form-container {
  min-width: 800px;
}
.ad-form-container.is-marketing {
  min-width: auto;
}
.ad-form-container .file-field {
  margin-bottom: 2rem;
  position: relative;
}
.ad-form-container .success-switch {
  position: relative;
}
.ad-form-container__sectoin-title {
  text-decoration: underline;
  display: inline-block;
}
.ad-form-container .disabled {
  opacity: 0.7;
}
.ad-form-container label.form-check-label {
  top: 0.75rem;
  width: max-content;
}
.ad-form-container span.lever {
  top: -0.75rem;
}
.ad-form-container table td {
  padding: 1rem 0;
}
.ad-form-container app-danger-switch {
  top: 50%;
  left: -0.5rem;
  transform: translate(-15%, -50%);
}
.ad-form-container label.from-value {
  position: relative;
  cursor: pointer;
}
.ad-form-container label.from-value input {
  opacity: 1;
  position: relative;
}

:host::ng-deep .mdb-select-outline .mdb-select-value {
  padding: 0.375rem 0.75rem;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/ads/components/sales-ad-edit-dialog/sales-ad-form/sales-ad-form.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAAE;EACE,eAAA;AAEJ;AAAE;EACE,mBAAA;EACA,kBAAA;AAEJ;AACE;EACE,kBAAA;AACJ;AAEE;EACE,0BAAA;EACA,qBAAA;AAAJ;AAGE;EACE,YAAA;AADJ;AAIE;EACE,YAAA;EACA,kBAAA;AAFJ;AAKE;EACE,aAAA;AAHJ;AAME;EACE,eAAA;AAJJ;AAOE;EACE,QAAA;EACA,aAAA;EACA,gCAAA;AALJ;AAQE;EACE,kBAAA;EACA,eAAA;AANJ;AAQI;EACE,UAAA;EACA,kBAAA;AANN;;AAWA;EACE,yBAAA;AARF","sourcesContent":[".ad-form-container {\n  min-width: 800px;\n  &.is-marketing {\n    min-width: auto;\n  }\n  .file-field {\n    margin-bottom: 2rem;\n    position: relative;\n  }\n\n  .success-switch {\n    position: relative;\n  }\n\n  &__sectoin-title {\n    text-decoration: underline;\n    display: inline-block;\n  }\n\n  .disabled {\n    opacity: 0.7;\n  }\n\n  label.form-check-label {\n    top: 0.75rem;\n    width: max-content;\n  }\n\n  span.lever {\n    top: -0.75rem;\n  }\n\n  table td {\n    padding: 1rem 0;\n  }\n\n  app-danger-switch {\n    top: 50%;\n    left: -0.5rem;\n    transform: translate(-15%, -50%);\n  }\n\n  label.from-value {\n    position: relative;\n    cursor: pointer;\n\n    input {\n      opacity: 1;\n      position: relative;\n    }\n  }\n}\n\n:host::ng-deep .mdb-select-outline .mdb-select-value {\n  padding: 0.375rem 0.75rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
