import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { FormStatus } from '@shared/enums/form-status.enum';
import { ImageUploaderHttpService } from '@shared/service/image-uploader-http.service';
import { HomecleanCompanyService } from '@app/modules/homeclean/modules/homeclean-company/shared/services/homeclean-company.service';
import { HomecleanCompany } from '@app/modules/homeclean/modules/homeclean-company/shared/models';
import { Nullable } from '@shared/models';

@Component({
  templateUrl: './homeclean-company-edit-dialog.component.html',
})
export class HomecleanCompanyEditDialogComponent implements OnInit {
  isEditMode: boolean;
  isDisableButton: boolean;
  formData: Partial<HomecleanCompany>;
  imageCompanyLogoLink: string;
  contractFile: string;
  isLoading: boolean;

  private readonly destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    @Inject(DIALOG_DATA) public readonly data: Nullable<HomecleanCompany> | any,
    private readonly dialogRef: DialogRef<HomecleanCompanyEditDialogComponent>,
    private readonly homecleanCompanyService: HomecleanCompanyService,
    private readonly imageUploaderHttpService: ImageUploaderHttpService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.isEditMode = !!this.data;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(): void {
    this.dialogRef.close();
  }

  formChanged(company: Partial<HomecleanCompany>): void {
    this.formData = {
      ...company,
      latitude: +company.latitude,
      longitude: +company.longitude,
    };
  }

  formStatusChanged(status: FormStatus): void {
    this.isDisableButton = status === FormStatus.Invalid;
  }

  createCompany(): void {
    const data: Partial<HomecleanCompany> = {
      ...this.formData,
      ...(this.imageCompanyLogoLink && {
        companyLogoUrl: {
          en: this.imageCompanyLogoLink,
          ar: this.imageCompanyLogoLink,
        },
      }),
      ...(this.contractFile && {
        companyContractUrl: {
          en: this.contractFile,
          ar: this.contractFile,
        },
      }),
    };
    const action$ = this.isEditMode
      ? this.homecleanCompanyService.updateCompany(this.data.id, data)
      : this.homecleanCompanyService.createCompany(data);

    this.isLoading = true;
    action$
      .pipe(
        tap(() => {
          this.isLoading = false;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (company: any) => {
          this.dialogRef.close(company);
        },
        (error) => {
          this.isLoading = false;
          this.cdr.markForCheck();
        },
      );
  }

  uploadCompanyLogoFile(file: File): void {
    this.uploadImage(file, 'homecleaning-company-logos')
      .pipe(takeUntil(this.destroy$))
      .subscribe((link: string) => {
        this.imageCompanyLogoLink = link;
      });
  }

  changeContractFile(file: File): void {
    this.uploadImage(file, 'homecleaning-contracts')
      .pipe(takeUntil(this.destroy$))
      .subscribe((link: string) => {
        this.contractFile = link;
      });
  }

  private uploadImage(file, imageTypeName: string): Observable<string> {
    this.isLoading = true;

    return this.imageUploaderHttpService.imageUpload(file, imageTypeName).pipe(
      tap(() => {
        this.cdr.markForCheck();
        this.isLoading = false;
      }),
    );
  }
}
