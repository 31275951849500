import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomecleanRoutingModule } from '@app/modules/homeclean/homeclean-routing.module';
import { HomecleanCompanyModule } from '@app/modules/homeclean/modules/homeclean-company/homeclean-company.module';
import { HomecleanServiceModule } from '@app/modules/homeclean/modules/homeclean-service/homeclean-service.module';
import { HomeCleaningOrderModule } from '@app/modules/homeclean/modules/homeclean-order/home-cleaning-order.module';
import { HomecleanStartTimeModule } from '@app/modules/homeclean/modules/homeclean-start-time/homeclean-start-time.module';
import { HomeCleaningCompanyBranchModule } from '@app/modules/homeclean/modules/homeclean-branch/home-cleaning-company-branch.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HomecleanRoutingModule,
    HomecleanCompanyModule,
    HomeCleaningOrderModule,
    HomecleanServiceModule,
    HomecleanStartTimeModule,
    HomeCleaningCompanyBranchModule,
  ],
})
export class HomecleanModule {}
