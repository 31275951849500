import { Component, OnInit, ViewChild } from '@angular/core';
import { LaundryService } from 'src/app/shared/service/laundry.service';
import { GlobalService } from 'src/app/shared/service/global.service';
import { BranchesService } from 'src/app/shared/service/branches.service';
import {
  ToastService,
  MDBDatePickerComponent,
  IMyOptions,
} from 'ng-uikit-pro-standard';
import { LaundryFromList } from 'src/app/shared/models/laundries/laundry';
import { BranchList } from 'src/app/shared/models/laundries/branch';
import { SelectOptions } from 'src/app/shared/models/global/response';
import { FinanceService } from '../../services/finance.service';
import {
  LaundryBillingParams,
  FinanceLaundryBill,
  LaundryBillOrders,
  LaundryBillOrdersForExport,
} from '../../models/finance';
import { ExcellService } from 'src/app/shared/service/excell.service';

@Component({
  selector: 'app-laundry-billing',
  templateUrl: './laundry-billing.component.html',
  styleUrls: ['./laundry-billing.component.css'],
})
export class LaundryBillingComponent implements OnInit {
  laundries: SelectOptions<number>[];
  branches: SelectOptions<number>[];
  selectedLaundry: number;
  selectedBranch: number[] = [];
  selectedStartDate: string;
  selectedEndDate: string;
  expiryDateOption: IMyOptions;
  bill: FinanceLaundryBill;
  dataReady = false;
  loader = false;
  selectedYear: string;
  selectedMonth: string;
  years: SelectOptions<string>[] = [];
  months: SelectOptions<string>[] = [];
  @ViewChild('from', {
    static: true,
  })
  from: MDBDatePickerComponent;
  @ViewChild('to', {
    static: true,
  })
  to: MDBDatePickerComponent;
  constructor(
    private laundryService: LaundryService,
    private globalService: GlobalService,
    private branchService: BranchesService,
    private financeService: FinanceService,
    private excelService: ExcellService,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.getLaundries();
    this.initData();
  }

  getLaundries() {
    this.laundryService
      .getLaundriesWithParams(this.globalService.headers, { page: 0 })
      .subscribe((response) => {
        const laundry = response.data as LaundryFromList[];
        this.laundries = [];
        laundry.forEach((e) => {
          this.laundries.push({
            value: e.id,
            label: e.name,
          });
        });
      });
  }

  getBranches(laundryId: number) {
    this.branchService
      .getBranchList(this.globalService.headers, laundryId)
      .subscribe((data) => {
        if (data.code === 200) {
          const branches = data.data as BranchList[];
          this.branches = [];
          branches.forEach((e) => {
            this.branches.push({
              value: e.id,
              label: e.name,
            });
          });
        }
      });
    this.selectedBranch = null;
  }

  startDateChanged(event) {
    if (event.epoc) {
      const date = this.globalService.formatDate(
        this.globalService
          .datePlusDays(event.actualDateFormatted, 0)
          .toString(),
        'YYYY-MM-DD'
      );
      this.expiryDateOption = {
        disableUntil: {
          year: parseInt(date.split('-')[0], 10),
          month: parseInt(date.split('-')[1], 10),
          day: parseInt(date.split('-')[2], 10),
        },
      };
      this.selectedStartDate = date;
    }
  }

  endDateChanged(event) {
    if (event.epoc) {
      const date = this.globalService.formatDate(
        this.globalService
          .datePlusDays(event.actualDateFormatted, 0)
          .toString(),
        'YYYY-MM-DD'
      );
      this.selectedEndDate = date;
    }
  }

  generateData() {
    this.getData();
  }

  prettifyFieldName(s: string) {
    return s
      .replace('_', ' ')
      .split(' ')
      .map((e) => e[0].toLocaleUpperCase)
      .join(' ');
  }

  private getData() {
    this.dataReady = false;
    this.loader = true;
    this.financeService
      .getLaundryBilling(this.globalService.headers, {
        month: this.selectedMonth,
        year: this.selectedYear,
        laundry_branch_id: this.selectedBranch.toString(),
        laundry_id: `${this.selectedLaundry}`,
      } as LaundryBillingParams)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.bill = response.data;
            this.dataReady = true;
            if (this.bill.orders.length > 0) {
              this.excelService.generateLaundryBill(
                this.cleanOrders(this.bill.orders),
                this.bill.laundry + '/' + this.bill.invoice_month,
                this.bill
              );
            } else {
              this.toast.info(
                `this laundry ${this.bill.laundry.toUpperCase()} has no order for this period`
              );
            }
          } else {
            this.toast.error(response.message);
          }
          this.loader = false;
        },
        (error) => {
          this.loader = false;
        }
      );
  }

  private cleanOrders(orders: LaundryBillOrders[]) {
    const data: LaundryBillOrdersForExport[] = [];
    if (this.globalService.countryId === 1) {
      orders.forEach((e) => {
        const row: LaundryBillOrdersForExport = {
          Date: this.globalService.formatDate(e.date.toString(), 'DD-MM-YYYY'),
          'Order ID': e.order_id,
          'Cash On Delivery': e.cash,
          'Knet Tap': e.tapKnet,
          'Credit Card Checkout': e.credit_card_checkout,
          'Credit Card Tap': e.credit_card_tap,
          Gift: e.gift,
          'JC Credit': e.jccredit,
          'Delivery Charge': e.delivery_charge,
          VAT: e.vat,
          'Total Paid Amount': e.total_paid,
          'Discount From': e.discount_from,
          'Discount Amount': e.discount_amount,
          'Total Commission': e.total_commission,
          Status: e.status,
          'Compensation Amount': e.compensation_amount,
          'Waived Amount': e.waived_amount,
        };
        data.push(row);
      });
    }
    if (this.globalService.countryId === 2) {
      orders.forEach((e) => {
        const row: LaundryBillOrdersForExport = {
          Date: this.globalService.formatDate(e.date.toString(), 'DD-MM-YYYY'),
          'Order ID': e.order_id,
          'Cash On Delivery': e.cash,
          'Credit Card Checkout': e.credit_card_checkout,
          'Credit Card Tap': e.credit_card_tap,
          Gift: e.gift,
          'JC Credit': e.jccredit,
          'Delivery Charge': e.delivery_charge,
          VAT: e.vat,
          'Total Paid Amount': e.total_paid,
          'Discount From': e.discount_from,
          'Discount Amount': e.discount_amount,
          'Total Commission': e.total_commission,
          Status: e.status,
          'Compensation Amount': e.compensation_amount,
          'Waived Amount': e.waived_amount,
        };
        data.push(row);
      });
    }
    if (this.globalService.countryId === 3) {
      orders.forEach((e) => {
        const row: LaundryBillOrdersForExport = {
          Date: this.globalService.formatDate(e.date.toString(), 'DD-MM-YYYY'),
          'Order ID': e.order_id,
          'Cash On Delivery': e.cash,
          Benifit: e.benifit,
          'Credit Card Checkout': e.credit_card_checkout,
          'Credit Card Tap': e.credit_card_tap,
          Gift: e.gift,
          'JC Credit': e.jccredit,
          'Delivery Charge': e.delivery_charge,
          VAT: e.vat,
          'Total Paid Amount': e.total_paid,
          'Discount From': e.discount_from,
          'Discount Amount': e.discount_amount,
          'Total Commission': e.total_commission,
          Status: e.status,
          'Compensation Amount': e.compensation_amount,
          'Waived Amount': e.waived_amount,
        };
        data.push(row);
      });
    }
    if (this.globalService.countryId === 4) {
      orders.forEach((e) => {
        const row: LaundryBillOrdersForExport = {
          Date: this.globalService.formatDate(e.date.toString(), 'DD-MM-YYYY'),
          'Order ID': e.order_id,
          'Cash On Delivery': e.cash,
          MADA: e.mada,
          'Credit Card Checkout': e.credit_card_checkout,
          'Credit Card Tap': e.credit_card_tap,
          Gift: e.gift,
          'JC Credit': e.jccredit,
          'Delivery Charge': e.delivery_charge,
          VAT: e.vat,
          'Total Paid Amount': e.total_paid,
          'Discount From': e.discount_from,
          'Discount Amount': e.discount_amount,
          'Total Commission': e.total_commission,
          Status: e.status,
          'Compensation Amount': e.compensation_amount,
          'Waived Amount': e.waived_amount,
        };
        data.push(row);
      });
    }
    if (this.globalService.countryId === 5) {
      orders.forEach((e) => {
        const row: LaundryBillOrdersForExport = {
          Date: this.globalService.formatDate(e.date.toString(), 'DD-MM-YYYY'),
          'Order ID': e.order_id,
          'Cash On Delivery': e.cash,
          QPAY: e.qpay,
          'Credit Card Checkout': e.credit_card_checkout,
          'Credit Card Tap': e.credit_card_tap,
          Gift: e.gift,
          'JC Credit': e.jccredit,
          'Delivery Charge': e.delivery_charge,
          VAT: e.vat,
          'Total Paid Amount': e.total_paid,
          'Discount From': e.discount_from,
          'Discount Amount': e.discount_amount,
          'Total Commission': e.total_commission,
          Status: e.status,
          'Compensation Amount': e.compensation_amount,
          'Waived Amount': e.waived_amount,
        };
        data.push(row);
      });
    }

    return data;
  }
  private initData() {
    const currentYear = new Date().getFullYear();
    const startYear = 2018;
    for (let i = startYear; i <= currentYear; i++) {
      this.years.push({
        label: i + '',
        value: i + '',
      });
    }
    const months = this.globalService.getMonthList();
    const keys = Object.keys(months);
    for (const key of keys) {
      this.months.push({
        label: months[key],
        value: parseInt(key, 10) + 1 +  '',
      });
    }
  }
}
