import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationsRoutingModule } from 'src/app/modules/notifications/notifications-routing.module';
import { UsersNotificationsMainComponent } from 'src/app/modules/notifications/components/users-notifications-main/users-notifications-main.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [UsersNotificationsMainComponent],
  imports: [
    CommonModule,
    NotificationsRoutingModule,
    SharedModule.forRoot()
  ]
})
export class NotificationsModule { }
