import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appPressEnter], appPressEnter',
  exportAs: 'appPressEnter'
})
export class PressEnterDirective {
  @Output() callBack = new EventEmitter();
  constructor() {}

  @HostListener('keyup', ['$event']) keyup(event) {
    if (event.code === 'Enter') {
      this.callBack.emit(true);
    }
  }
}
