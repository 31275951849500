// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.md-form label {
    color: black !important;
}.md-form label {
    color: black !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/laundries/components/create-laundry/step-creation/step-creation.component.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B,CAAC;IACG,uBAAuB;AAC3B","sourcesContent":[".md-form label {\n    color: black !important;\n}.md-form label {\n    color: black !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
