import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GlobalService } from './shared/service/global.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';
  constructor(
    translate: TranslateService,
    private pageTitle: Title,
    private router: Router,
    private global: GlobalService
  ) {
    translate.addLangs(['en', 'ar']);
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');
  }
  ngOnInit() {
    this.router.events
      .pipe(filter(e => e instanceof ActivationEnd))
      .subscribe(event => {
        event = event as ActivationEnd;
        if (event.snapshot.children.length === 0) {
          const countryCode = window.localStorage.getItem('countryCode');
          const title = countryCode
            ? countryCode + ' || ' + event.snapshot.data.title
            : event.snapshot.data.title;
          this.pageTitle.setTitle(title);
        }
      });
    this.global.countryCodeSubject.subscribe(value => {
      const title = this.pageTitle.getTitle();
      if (!title.includes('||')) {
        this.pageTitle.setTitle(value + ' || ' + title);
      }
    });
  }
}
