import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from "@shared/service/global.service";
import { BranchesService } from "@shared/service/branches.service";
import {
  BrArea,
  NewBranchArea
} from "@shared/models/laundries/branch";

@Component({
  selector: 'app-create-min-order-per-area',
  templateUrl: './create-min-order-per-area.component.html',
  styleUrls: ['./create-min-order-per-area.component.css']
})
export class CreateMinOrderPerAreaComponent implements OnInit {
  laundryBranchId: number;
  laundryId: number;
  cwGarageBranchId: number;
  cwGarageId: number;
  branchAreas: BrArea[];
  loader: boolean;
  isLogistics: boolean;

  constructor(
    private route: ActivatedRoute,
    // private router: Router,
    private globalService: GlobalService,
    private branchService: BranchesService,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.laundryBranchId = params['laundryBranchId'];
      this.laundryId = params['laundryId'];
      this.cwGarageBranchId = params['cwGarageBranchId'];
      this.cwGarageId = params['cwGarageId'];
    });
    this.isLogistics = window.localStorage.getItem('isLog') === 'true';
    this.getBranchAreas();
  }

  getBranchAreas() {

    const queryParams: {is_selected: boolean, laundryBranchId?: number, cwGarageBranchId?: number} = {
      is_selected: true
    };

    if (this.laundryBranchId) {
      queryParams.laundryBranchId = this.laundryBranchId
    }

    if (this.cwGarageBranchId) {
      queryParams.cwGarageBranchId = this.cwGarageBranchId
    }

    this.branchService
      .getBranchAreas(this.globalService.headers,  queryParams)
      .subscribe(response => {
        console.log(response);
        this.branchAreas = response.data as BrArea[];

        // Initialize the default values only if they are not present
        this.branchAreas.forEach(area => {
          area.amount_minimum_order = area.amount_minimum_order || 0.00;
          area.amount_delivery_charge_threshold = area.amount_delivery_charge_threshold || 0.00;
          area.amount_delivery_charge = area.amount_delivery_charge || 0.00;
          area.amount_delivery_charge_after_threshold = area.amount_delivery_charge_after_threshold || 0.00;
          area.amount_service_charge_threshold = area.amount_service_charge_threshold || 0.00;
          area.amount_service_charge = area.amount_service_charge || 0.00;
          area.amount_service_charge_after_threshold = area.amount_service_charge_after_threshold || 0.00;
        });
      });
  }

  saveMinOrder() {
    this.loader = true;
    const branchAreas = new NewBranchArea();
    branchAreas.laundry_branch_id = this.laundryBranchId;
    branchAreas.cw_garage_branch_id = this.cwGarageBranchId;
    branchAreas.laundry_branch_areas = this.branchAreas;
    this.branchService
      .createBranchArea(this.globalService.headers, branchAreas)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.loader = false;
            // this.router.navigate([
            //   'dashboard/' +
            //   window.localStorage.getItem('module') +
            //   '/branch-list/' +
            //   this.laundryId
            // ]);
          }
        },
        error => {
          this.loader = false;
        }
      );
  }
}
