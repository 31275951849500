import { NgModule } from '@angular/core';

import { CarwashPackageRoutingModule } from 'src/app/modules/carwash/modules/carwash-packages/carwash-package-routing.module';
import { CarwashPackageFormComponent } from '@app/modules/carwash/modules/carwash-packages/components/carwash-package-edit-dialog/carwash-package-form/carwash-package-form.component';
import { SharedModule } from '@app/modules/carwash/shared/shared.module';
import { CarwashPackageListComponent } from '@app/modules/carwash/modules/carwash-packages/components/carwash-package-list/carwash-package-list.component';
import { CarwashPackageEditDialogComponent } from '@app/modules/carwash/modules/carwash-packages/components/carwash-package-edit-dialog/carwash-package-edit-dialog.component';
import { CarwashPackageTimeWashComponent } from './shared/components/carwash-package-time-wash/carwash-package-time-wash.component';
import { AssignVariantDialogComponent } from './components/assign-vaiant-dialog/assign-variant-dialog.component';

@NgModule({
  imports: [CarwashPackageRoutingModule, SharedModule],
  declarations: [
    CarwashPackageListComponent,
    CarwashPackageFormComponent,
    CarwashPackageEditDialogComponent,
    CarwashPackageTimeWashComponent,
    AssignVariantDialogComponent,
  ],
})
export class CarwashPackageModule {}
