// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cancel-order-modal-body {
  min-width: 50rem;
}

.reason-length {
  position: absolute;
  right: 0;
  top: 2rem;
}

.relative {
  position: relative;
}

.red-border {
  border-color: red !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/carwash/modules/carwash-order/components/cw-cancel-order-dialog/cw-cancel-order-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AACE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;AAEJ;;AAAE;EACE,kBAAA;AAGJ;;AADE;EACE,4BAAA;AAIJ","sourcesContent":[".cancel-order-modal-body {\n    min-width: 50rem;\n  }\n  .reason-length {\n    position: absolute;\n    right: 0;\n    top: 2rem;\n  }\n  .relative {\n    position: relative;\n  }\n  .red-border {\n    border-color: red !important;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
