import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-create-item-list',
  templateUrl: './create-item-list.component.html',
  styleUrls: ['./create-item-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CreateItemListComponent {
  @Input() text: string;
  @Input() buttonName: string;
  @Input() disabled: boolean;
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

  buttonClick() {
    this.clicked.emit();
  }
}
