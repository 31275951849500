import { UserSegmentService } from '@shared/service/user-segment';
import { Status } from '@shared/enums/status.enum';
import { GlobalService } from 'src/app/shared/service/global.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';

import { DialogService } from '@shared/components/dialog/services/dialog.service';
import { ReferralCampaign, UserSegment } from '@shared/models';
import { PageInfo } from '@shared/models/global/response';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ReferralCampaignService } from '@shared/service/referral-campaign';
import { ReferralCampaignEditDialogComponent } from '../referral-campaign-edit-dialog/referral-campaign-edit-dialog.component';

@Component({
  selector: 'app-referral-campaign-list',
  templateUrl: './referral-campaign-list.component.html',
})
export class ReferralCampaignListComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>() ;
  pagination$: Observable<PageInfo>;
  referralCampaigns$: Observable<Array<ReferralCampaign>>;
  currency: string;
  STATUS = Status;
  currentPage: number = 1;

  getSegmentNameBounded = this.getSegmentName.bind(this);
  loadedSegments: { [segmentId: number]: string } = {};

  private get countryId(): number {
    return +localStorage.getItem('country');
  }

  constructor(
    private readonly referralCampaignService: ReferralCampaignService,
    private readonly userSegmentService: UserSegmentService,
    private readonly globalService: GlobalService,
    private readonly dialog: DialogService,
  ) {}

  ngOnInit(): void {
    this.loadReferralCampaigns();
    this.currency = this.globalService.currency;
    this.pagination$ = this.referralCampaignService.pagination$;
  }

  openDialog(referralCampaign?: ReferralCampaign): void {
    this.dialog
      .open(ReferralCampaignEditDialogComponent, referralCampaign)
      .afterClosed$.pipe(takeUntil(this.destroy$), filter(Boolean))
      .subscribe(() => this.loadReferralCampaigns());
  }

  loadReferralCampaigns(page?: number): void {
    this.currentPage = page || this.currentPage;
    this.referralCampaigns$ = this.referralCampaignService.getReferralCampaigns(
      {
        page: this.currentPage.toString(),
        countryId: this.countryId.toString(),
      },
    );
  }

  private getSegmentName(
    segmentId: number,
  ): Observable<{ [segmentId: number]: string }> {
    if (!segmentId) {
      return of('');
    }

    if (this.loadedSegments[segmentId]) {
      return of(this.loadedSegments);
    }

    this.loadedSegments[segmentId] = '- ';

    return this.userSegmentService.getSegmentById(segmentId).pipe(
      takeUntil(this.destroy$),
      map((segment: UserSegment) => {
        this.loadedSegments[segmentId] = `${segment?.name?.en}`;
        return this.loadedSegments;
      }),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
