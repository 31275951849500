import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomecleanServiceListComponent } from '@app/modules/homeclean/modules/homeclean-service/components/homeclean-service-list/homeclean-service-list.component';

const routes: Routes = [
  {
    path: 'services/list',
    component: HomecleanServiceListComponent,
    data: { title: 'Home cleaning services' },
  },

  {
    path: 'services',
    redirectTo: 'services/list',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomecleanServiceRoutingModule {}
