import { Component, OnInit, ViewChild } from '@angular/core';
import { CategoriesService } from '../../../../shared/service/categories.service';
import { GlobalService } from '../../../../shared/service/global.service';
import { ItemsService } from '../../../../shared/service/items.service';
import { CategoryList } from '../../../../shared/models/items/cats';
import {
  Item,
  ItemFromListItem,
  ItemChangePositionBody,
} from '../../../../shared/models/items/items';
import { PageInfo } from '../../../../shared/models/global/response';
import { ToastService, ModalDirective } from 'ng-uikit-pro-standard';
import { DialogService } from '@shared/components/dialog/services/dialog.service';
import { ItemsTatDialogComponent } from '@app/shared/components/items-tat-dialog/items-tat-dialog.component';
import { map } from "rxjs/operators";
import { Group } from "@shared/models";
import { Observable } from "rxjs";
import { AppHeaders } from "@shared/models/user/generic-backend";
import { GroupService } from "@shared/service/group";

@Component({
  selector: 'app-items-list',
  templateUrl: './items-list.component.html',
  styleUrls: ['./items-list.component.scss'],
})
export class ItemsListComponent implements OnInit {
  items: ItemFromListItem[];
  filteredItems: ItemFromListItem[];
  categories: CategoryList[];
  selectedCategory: number;
  search: string;
  isShown: boolean;
  isUpdate = false;
  catData: any[];
  pagination: PageInfo;
  itemLoaded: boolean;
  itemToBeUpdated: ItemFromListItem;
  toggleDeleteModal: boolean;
  loader: boolean;
  itemToBeDeleted: Item;
  @ViewChild('updatePosition', { static: true })
  modal: ModalDirective;
  selectedItem: ItemFromListItem;
  changePositionBody: ItemChangePositionBody;
  itemCount: number;
  selectedGroup: Group;
  groups$: Observable<Array<Group>>;
  headers: AppHeaders = {};
  constructor(
    private readonly catService: CategoriesService,
    private readonly globalService: GlobalService,
    private readonly itemsService: ItemsService,
    private readonly dialog: DialogService,
    protected toast: ToastService,
    private groupsService: GroupService,
  ) {}

  ngOnInit() {
    this.headers = this.globalService.headers
    this.isShown = false;
    this.itemLoaded = false;
    this.initData();
    this.getCategories();
    this.getItems(1);
    this.getItemCount();
    this.loadLaundryGroups();
  }

  getCategories() {
    this.catService
      .getCategories(this.headers, 0)
      .subscribe((response) => {
        this.catData = [];
        this.categories = response.data as CategoryList[];
        this.categories.forEach((cat) => {
          this.catData.push({ value: cat.id, label: cat.name.en });
        });
      });
  }

  getItems(page) {
    this.itemsService
      .getItems(this.headers, page)
      .subscribe((response) => {
        if (response.code === 200) {
          console.log(response);
          this.items = response.data;
          this.filteredItems = this.items;
          this.pagination = response.page_info;
          this.itemLoaded = true;
        }
      });
  }

  getPage(page) {
    this.getItems(page);
  }

  updateItem(item: ItemFromListItem) {
    this.itemToBeUpdated = item;
    this.isUpdate = true;
    this.isShown = true;
  }

  openTatDialog(item): void {
    this.dialog.open(ItemsTatDialogComponent, { id: item.id });
  }

  itemToDelete(item) {
    this.itemToBeDeleted = item;
    this.toggleDeleteModal = true;
  }

  deleteItem(event) {
    this.loader = true;
    this.itemsService
      .deleteItem(this.headers, this.itemToBeDeleted.id)
      .subscribe((response) => {
        if (response.code === 200) {
          this.loader = false;
          this.toggleDeleteModal = false;
          this.getItems(this.pagination.current_page);
        }
      });
  }

  changeStatus(id: number, status: string) {
    this.itemsService
      .changeStatus(
        this.headers,
        id,
        status === 'active' ? 'inactive' : 'active',
      )
      .subscribe(
        (response) => {
          this.getItems(this.pagination.current_page);
        },
        (error) => {
          this.getItems(this.pagination.current_page);
        },
      );
  }

  toggleMarketPlaceCorporate(body) {
    this.itemsService
      .toggleMarketPlaceCorporate(this.headers, body)
      .subscribe(
        (response) => {
          this.getItems(this.pagination.current_page);
        },
        (error) => {
          this.getItems(this.pagination.current_page);
        },
      );
  }
  togglePos(body) {
    this.itemsService
      .togglePosStatus(this.headers, body)
      .subscribe(
        (response) => {
          this.getItems(this.pagination.current_page);
        },
        (error) => {
          this.getItems(this.pagination.current_page);
        },
      );
  }

  refreshData(event) {
    if (event) {
      this.getItems(this.pagination.current_page);
    }
  }

  filter() {
    if (this.selectedCategory) {
      this.filteredItems = this.items.filter((item) =>
        item.categories.find((cat) => cat.id === this.selectedCategory)
          ? true
          : false,
      );
    }
    if (this.search) {
      this.filteredItems = this.items.filter(
        (item) =>
          item.name.en.name.includes(this.search) ||
          item.name.en.name.includes(this.search),
      );
    }
  }

  resetFilter() {
    this.filteredItems = this.items;
    this.search = null;
  }

  isClosed(event) {
    this.isShown = !event;
    this.isUpdate = false;
    this.itemToBeUpdated = null;
  }

  openUpdatePositionModal(item: ItemFromListItem) {
    this.selectedItem = item;
    this.changePositionBody.current_position = item.sort_order;
    this.changePositionBody.item_id = item.id;
    this.changePositionBody.next_position = item.sort_order;
    this.getItemCount();
    this.modal.show();
  }

  changePosition() {
    this.loader = true;
    this.itemsService
      .changePosition(this.headers, this.changePositionBody)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.getItems(this.pagination.current_page);
            this.modal.hide();
            this.initData();
            this.toast.success(response.message);
          } else {
            this.toast.error(response.message);
          }
          this.loader = false;
        },
        (error) => {
          this.getItems(this.pagination.current_page);
          this.loader = false;
        },
      );
  }

  private initData() {
    this.changePositionBody = {
      current_position: null,
      next_position: null,
      item_id: null,
    };
    this.selectedItem = null;
  }
  private getItemCount() {
    this.itemsService
      .getItems(this.headers, 0)
      .subscribe((response) => {
        if (response.code === 200) {
          this.itemCount = response.data.length;
        }
      });
  }



  loadLaundryGroups(): void {
    this.groups$ = this.groupsService.getGroups().pipe(
      map((groups) => {
        if (this.selectedGroup) {
          /** This to bind with groups dropdown */
          this.selectedGroup = groups.find(
            ({ id }) => id === this.selectedGroup.id,
          );
        } else {
          // assign the first group by default
          this.selectedGroup = groups.find(() => true);
        }
        return groups.filter((group) =>
          this.groupsService.checkLaundryDesign(group),
        );
      }),
    );
  }


  setGroup(): void {
    this.headers.group_id = this.selectedGroup.id.toString();
    this.initData();
    this.getItems(0);
    this.getCategories();
  }
}
