export interface CarwashBranchAreaRequest {
  areaId?: number;
  cwGarageBranchId?: number;
  laundryBranchId?: number;
  minimumOrderAmount: number;
  deliveryCharge?: number;
  deliveryChargeThreshold?: number;
  deliveryChargeAfterThreshold?: number;
  serviceCharge?: number;
  serviceChargeThreshold?: number;
  serviceChargeAfterThreshold?: number;
  sortOrder: number;
}
