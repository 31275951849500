import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GroupsOrdersMapRoutingModule } from './groups-orders-map-routing.module';
import { GroupsOrdersMapComponent } from './groups-orders-map.component';
import { NgMapsMarkerClustererModule } from "@ng-maps/marker-clusterer";

@NgModule({
  declarations: [GroupsOrdersMapComponent],
  imports: [
    CommonModule,
    SharedModule,
    GroupsOrdersMapRoutingModule,
    NgMapsMarkerClustererModule,
  ],
})
export class GroupsOrdersMapModule {}
