import moment from 'moment-timezone';

export class TimeHelper {
  public static formatTime(
    value: string,
    isTimeSlot = false,
    formate = 'h:m z'
  ): Date | string {
    const time_zone = localStorage.getItem('timeZone');
    let date = value ? new Date(value) : '';
    date = isTimeSlot
      ? date
        ? moment(date)
          .tz(time_zone)
          .format(formate) +
        ' - ' +
        moment(date)
          .add(1, 'h')
          .tz(time_zone)
          .format(formate)
        : ''
      : date
        ? moment(date)
          .add(1, 'h')
          .tz(time_zone)
          .format(formate)
        : '';

    return date;
  }
}