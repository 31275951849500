import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { GlobalService } from 'src/app/shared/service/global.service';
import { CouponsService } from 'src/app/shared/service/coupons.service';
import {
  CouponToBeSaved,
  CouponType,
  CouponTypeEnum,
  CouponDiscountType,
  CouponCustomerType,
} from 'src/app/shared/models/offers/offers';
import {
  ModalDirective,
  IMyOptions,
  ToastService,
  MDBDatePickerComponent,
} from 'ng-uikit-pro-standard';
import { LaundryService } from 'src/app/shared/service/laundry.service';
import { LaundryFromList } from 'src/app/shared/models/laundries/laundry';
import { UntypedFormControl, NgForm } from '@angular/forms';
import {
  SelectOptions,
  JustCleanLaundryPercentage
} from "src/app/shared/models/global/response";
import { BehaviorSubject, Observable, of, Subscription } from "rxjs";
import { map, startWith, debounceTime } from 'rxjs/operators';
import { CarwashCompanyHttpService } from '@app/shared/service/carwash-company';
import { CarwashCompany, Group } from '@app/shared/models';
import { GroupService } from '@app/shared/service/group';
import { HomecleanCompanyService } from '@app/modules/homeclean/modules/homeclean-company/shared/services/homeclean-company.service';
import { AppHeaders } from "@shared/models/user/generic-backend";
import moment from 'moment/moment';

@Component({
  selector: 'app-coupon-create',
  templateUrl: './coupon-create.component.html',
  styleUrls: ['./coupon-create.component.css'],
})
export class CouponCreateComponent implements OnInit, OnChanges, OnDestroy {
  coupon: CouponToBeSaved;
  couponsTypes: SelectOptions<number>[];
  groups: Array<Group>;
  customers: any[];
  selectedCouponType: number;
  laundries: any[];
  cwGarages: Array<CarwashCompany>;
  selectedLaundry: number;
  selectedCustomer: number;
  @Input() openDialog: boolean;
  @Output() hideDialog: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('createCoupon', { static: true }) couponModal: ModalDirective;
  @ViewChild('couponForm', { static: false }) form: NgForm;
  @ViewChild('startDate', { static: false }) startDate: MDBDatePickerComponent;
  @ViewChild('endDate', { static: false }) endDate: MDBDatePickerComponent;
  today = new Date().getDate();
  startDateOptions: IMyOptions = {
    disableUntil: {
      day: this.today - 1,
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    },
    closeAfterSelect: true,
  };
  loader = false;
  endDateOptions: IMyOptions;
  customerTypes: SelectOptions<string>[];
  justCleanOrLaundry: JustCleanLaundryPercentage;
  searchInput = new UntypedFormControl('');
  filteredCustomers: Observable<Observable<any[]>>;
  hideTypeOption: boolean;
  CouponTypeEnum = CouponTypeEnum;
  CouponDiscountType = CouponDiscountType;

  dropdownListMap: {
    [key: number]: { placeholder?: string; list?: any[]; idKey?: string };
  } = {};
  currentIdKey: string;
  currentPlaceholder: string;
  subscriber$: Subscription;
  currentList: Array<SelectOptions<number>>;
  loadedGroupCompanies: {
    groupId?: number;
    list?: Array<SelectOptions<number>>;
  } = {};
  getGroupOptionFn: Function;
  isSpecificGroup: boolean = true;
  private groupId: number;
  private _isLoadingLaundries = new BehaviorSubject<boolean>(false);
  isLoadingLaundries$ = this._isLoadingLaundries.asObservable();
  isMultiUse: boolean;


  constructor(
    private globalService: GlobalService,
    private couponService: CouponsService,
    private laundriesService: LaundryService,
    private carwashCompanyService: CarwashCompanyHttpService,
    private homecleanCompanyService: HomecleanCompanyService,
    private groupService: GroupService,
    private toast: ToastService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getLaundries();
    this.initCustomersFilteration();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const openDialog =
      changes &&
      changes.openDialog.currentValue &&
      changes.openDialog.currentValue === true;
    if (openDialog) {
      this.couponModal.show();
    }
  }

  datePicker(event: MDBDatePickerComponent) {
    event.showSelector = false;
    event.removeInlineStyle();
    console.log(event);
  }

  onHide(isReload: boolean): void {
    this.couponModal.hide();
    this.selectedCouponType = 1;
    this.initForm();
    this.form.resetForm();
    if (this.startDate) {
      this.startDate.showSelector = false;
      this.startDate.removeInlineStyle();
    }
    if (this.endDate) {
      this.endDate.showSelector = false;
      this.endDate.removeInlineStyle();
    }

    this.hideDialog.emit(isReload);
  }

  selectStartDate(event) {
    this.coupon.expiry_date = null;
    this.endDateOptions = {
      disableUntil: {
        day: new Date(event).getDate() - 1,
        month: new Date(event).getMonth() + 1,
        year: new Date(event).getFullYear(),
      },
      closeAfterSelect: true,
    };

  }

  changeType(event) {
    this.selectedCouponType = event;
  }

  changeGroup(selectedGroup: Group) {
    this.groupId = selectedGroup.id;
    this.currentIdKey = this.getIdKey(selectedGroup);
    /** if laundry design type */
    if (this.groupService.checkLaundryDesign(selectedGroup)) {
      this.getLaundries();
    } else if (this.groupService.checkHomeCleaningDesign(selectedGroup)) {
      this.handleHomeCleaning(selectedGroup);
    } else {
      this.handleCarWash(selectedGroup);
    }
  }

  private handleLaundry(): void {
    // this.coupon.type = CouponTypeEnum.gift;
    this.hideTypeOption = false;
    this.currentPlaceholder = 'Select Laundries';
    this.currentList = this.laundries;
  }

  private handleCarWash(selectedGroup: Group): void {
    this.handleCwAndHc(this.getCWGarages(selectedGroup.id), selectedGroup.id);
  }

  private handleHomeCleaning(selectedGroup: Group): void {
    const countryId = localStorage.getItem('country');
    this.handleCwAndHc(
      this.homecleanCompanyService.getCompanies({ countryId }),
      selectedGroup.id,
    );
  }

  private handleCwAndHc(apiObserver: Observable<any>, groupId: number): void {
    this.coupon.type = CouponTypeEnum.voucher;
    this.hideTypeOption = true;
    this.currentPlaceholder = 'Select Companies (*)';
    if (this.loadedGroupCompanies[groupId]) {
      this.currentList = this.loadedGroupCompanies[groupId];
    } else {
      this.currentList = [];
      this.unsubscribe();

      this.subscriber$ = apiObserver
        .pipe(
          map((garages) =>
            garages.map((garage) => ({
              value: garage.id,
              label: garage.name.en,
            })),
          ),
        )
        .subscribe((companies) => {
          this.currentList = companies;
          this.loadedGroupCompanies[groupId] = companies.slice();
        });
    }
  }

  saveCoupon() {
    this.prepareCouponObject();

    this.couponService
      .saveCoupon(this.globalService.headers, this.coupon)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.loader = false;
            this.onHide(true);
            this.initForm();
          } else {
            this.loader = false;
            this.toast.error(response.message);
          }
        },
        (error) => {
          this.loader = false;
        },
      );
  }

  initForm() {
    this.coupon = new CouponToBeSaved();
    this.coupon.is_multi_use = false;
    this.coupon.translations = [];
    this.coupon.translations[0] = {
      language_id: 1,
      message: '',
    };
    this.coupon.translations[1] = {
      language_id: 2,
      message: '',
    };
    this.coupon.coupon_category_id = 1;
    this.coupon.type = CouponTypeEnum.gift;

    this.justCleanOrLaundry = {
      justclean: 100,
      laundry: 0,
    };
    this.getTypes();
    this.getGroups();
  }

  validateForm(form: NgForm) {
    if (form) {
      return (
        (this.isMarketingValid ||
          this.isOperationValid ||
          this.isPromotionValid) &&
        form.valid
      );
    }
    return false;
  }

  getTypeName(id: number): string {
    return (
      this.couponsTypes && this.couponsTypes.find((e) => e.value === id).label
    );
  }

  isGift() {
    return this.coupon.type === CouponTypeEnum.gift;
  }

  isVoucher() {
    return this.coupon.type === CouponTypeEnum.voucher;
  }

  getSliderValues(event: JustCleanLaundryPercentage) {
    this.justCleanOrLaundry = event;
    console.log(event);
  }

  getGroupName(groupId: number, groups: Group[]): string {
    return groups.find(({ id }) => id === groupId)?.name?.en;
  }

  private get isMarketingValid(): boolean {
    return !!(
      [1, 5].includes(this.coupon.coupon_category_id) &&
      (this.isGift() ||
        (this.isVoucher() &&
          this.justCleanOrLaundry.laundry >= 0 &&
          this.justCleanOrLaundry.justclean >= 0 &&
          ((this.coupon[this.currentIdKey] &&
              (this.coupon[this.currentIdKey] === 0 ||
                this.coupon[this.currentIdKey].length > 0)) ||
            !this.isSpecificGroup) &&
          this.coupon.customer_type))
    );
  }

  private get isOperationValid(): boolean {
    return !!(
      [2, 4].includes(this.coupon.coupon_category_id) && this.coupon.user_id
    );
  }

  private get isPromotionValid(): boolean {
    return !!(
      this.coupon.coupon_category_id === 3 && this.coupon[this.currentIdKey]
    );
  }

  private prepareCouponObject(): void {
    this.loader = true;
    const idKey = this.currentIdKey || 'laundry_id';
    this.setGroupId();

    if (this.isVoucher()) {
      this.setVoucherKeys();
    }

    if (this.coupon.start_date) {
      this.coupon.start_date = this.globalService.localToUtcDate(moment(this.coupon.start_date).startOf('day').format());
    }

    if (this.coupon.expiry_date) {
      this.coupon.expiry_date = this.globalService.localToUtcDate(moment(this.coupon.expiry_date).endOf('day').format());
    }

    this.coupon = this.couponService.cleanCouponObject(this.coupon, idKey);
  }

  private getIdKey(group: Group): string {
    return this.groupService.checkLaundryDesign(group)
      ? 'laundry_id'
      : this.groupService.checkCarwashGarageDesign(group)
        ? 'cw_garage_id'
        : 'hc_company_id';
  }

  private setGroupId(): void {
    if (!this.isSpecificGroup) {
      /** Create one coupon for laundry group (1) with null in laundry_id, cw_garage_id & hc_company_id, and it'll work for all groups */
      this.coupon.group_id = 1;
      this.coupon.laundry_id = 0;
    } else if (this.coupon.group_id) {
      this.coupon.group_id = this.coupon.group_id['id'];
    }
  }

  private setVoucherKeys(): void {
    this.coupon.discount_by_justclean = this.justCleanOrLaundry.justclean;
    this.coupon.discount_by_laundry = this.justCleanOrLaundry.laundry;
  }

  private getTypes() {
    this.couponService
      .getCouponsType(this.globalService.headers)
      .subscribe((response) => {
        this.couponsTypes = [];
        if (response.code === 200) {
          const types = response.data as CouponType[];
          types.forEach((type) => {
            if (!type.name.toLowerCase().includes('promo')) {
              this.couponsTypes.push({
                value: type.id,
                label: type.name,
              });
            }
          });
          this.getCustomerType();
        }
      });
  }

  private getGroups() {
    this.groupService.getGroups().subscribe((groups) => {
      this.groups = groups;
      this.getGroupOptionFn = this.getGroupOption.bind(this);
    });
  }

  private getGroupOption(groups: Array<Group>): Array<SelectOptions<number>> {
    return groups.map((group) => ({
      label: group.name?.en || '',
      value: group.id,
    }));
  }

  private getLaundries() {
    this._isLoadingLaundries.next(true);  // <-- Start loading

    let appHeaders: AppHeaders = this.globalService.headers;
    if (this.groupId) {
      appHeaders.group_id = `${this.groupId}`;
    }
    return this.laundriesService.getLaundries(appHeaders, { page: 0 }).subscribe(response => {
      if (response.code === 200) {
        this.laundries = [];
        const laundries = response.data as LaundryFromList[];
        laundries.forEach((laundry) => {
          if (laundry.status === 'active') {
            this.laundries.push({
              value: laundry.id,
              label: laundry.name,
            });
          }
        });
        this.handleLaundry();
      }
      this._isLoadingLaundries.next(false);  // <-- Stop loading
    }, error => {
      this._isLoadingLaundries.next(false);  // <-- Stop loading even if there's an error
    });
  }


  private getCWGarages(groupId: number) {
    return this.carwashCompanyService
      .getCompanies({ status: 'active', groupId: groupId.toString() })
      .pipe(map((response) => response.data));
  }

  private getCustomerType() {
    this.customerTypes = [
      {
        value: CouponCustomerType.all,
        label: 'All',
      },
      {
        value: CouponCustomerType.firstTime,
        label: 'First Order',
      },
      {
        value: CouponCustomerType.reorder,
        label: 'Reorder',
      },
    ];
  }

  private initCustomersFilteration(): void {
    this.filteredCustomers = this.searchInput.valueChanges.pipe(
      startWith(''),
      debounceTime(1000),
      map((value: string) => this.filter(value)),
    );
  }

  private filter(value: string) {
    const filterValue = value.toLowerCase();
    if (value.length > 3) {
      return this.couponService.getCuponUsers(
        this.globalService.headers,
        filterValue,
      );
    }
    return of([]);
  }

  private unsubscribe(): void {
    if (this.subscriber$) {
      this.subscriber$.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  toggleMultiUse() {
    this.isMultiUse = !this.isMultiUse;
    this.coupon.is_multi_use = this.isMultiUse;
  }
}
