import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { DialogRef } from '@app/shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@app/shared/components/dialog/services/dialog.token';
import { GlobalService } from '@app/shared/service/global.service';
import { Subject } from 'rxjs';
import {  finalize, takeUntil } from 'rxjs/operators';
import { CarwashOrder, CwGarageBranchTicket } from '../shared/models/carwash-order.model';
import { DateSlot } from '@app/shared/models';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { CarwashTicketService } from '../../../carwash-packages/shared/services/carwash-ticket.service';

@Component({
  selector: 'app-cw-reschedule-dialog',
  templateUrl: './cw-reschedule-dialog.component.html',
  styleUrls: ['./cw-reschedule-dialog.component.css']
})
export class CwRescheduleDialogComponent implements OnInit {
  private readonly destroy$: Subject<void> = new Subject<void>() ;

  isLoading: boolean;
  selectedTimeId: number;
  enableDateOption: IMyOptions;
  dateSlot: DateSlot[];
  selectedDateSlot: DateSlot;

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: { ticket: CwGarageBranchTicket ;orderDetails: CarwashOrder; callbackFn: Function } | any,
    private readonly carwashTicketService: CarwashTicketService,
    private readonly globalService: GlobalService,
    private readonly dialogRef: DialogRef<CwRescheduleDialogComponent>,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadServiceTimeSlot();
  }

  close(isRefresh?: boolean): void {
    this.dialogRef.close();
    if (isRefresh) {
      this.data.callbackFn();
    }
  }

  reschedule() {
    this.isLoading = true;
    const userAdminId = localStorage.getItem('userId');
    const id = this.data.ticket.cwGarageBranchSchedule.id;
    this.carwashTicketService
      .reScheduleTicket({
        id:id,
        userId: +userAdminId,
        cwGarageBranchTicketId: this.data.ticket.id,
        timeSlotDateId: this.selectedDateSlot.id,
        startTimeSlotTimeId: this.selectedTimeId,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.cd.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.close(true));
  }

  changeDate(jsdate: Date): void {
    const format = 'YYYY-MM-DD';
    this.selectedDateSlot = this.dateSlot.find(
      (dateObj) =>
        this.globalService.formatDate(dateObj.date, format) ===
        this.globalService.formatDate(jsdate.toString(), format),
    );
    this.selectedTimeId = null;
  }

  private loadServiceTimeSlot(): void {
    const groupId = this.data.orderDetails.groupId;
    if (this.data.orderDetails.cwGarageBranchId) {
      this.carwashTicketService
        .getCwOpeningHoures(+this.data.orderDetails.cwGarageBranchId, groupId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((dateSlot: DateSlot[]) => {
          this.dateSlot = dateSlot;
          this.setDateOptions();
          this.cd.detectChanges();
        });
    }
}

private setDateOptions(): void {
  const firstDay = new Date(this.dateSlot[0].date);
  /** last day should be start date + 15 days  */
  const lastDay = new Date(this.dateSlot[this.dateSlot.length - 1].date);

  this.enableDateOption = {
    disableUntil: {
      year: firstDay.getFullYear(),
      month: firstDay.getMonth() + 1,
      day: firstDay.getDate() - 1,
    },
    disableSince: {
      year: lastDay.getFullYear(),
      month: lastDay.getMonth() + 1,
      day: lastDay.getDate(),
    },
    disableDays: this.dateSlot
      .filter(({ timeSlotTimes }) => !timeSlotTimes?.length)
      .map((dateObj) => {
        const date = new Date(dateObj.date);
        return {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
        };
      }),
  };
}

private unsubscribe(): void {
  this.destroy$.next();
  this.destroy$.complete();
}

ngOnDestroy(): void {
  this.unsubscribe();
}
}
