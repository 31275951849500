import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@env/environment';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { Variant, SubVariant } from '@shared/models';
import { SelectOptions } from '@app/shared/models/global/response';

@Injectable({ providedIn: 'root' })
export class VariantHttpService {
  private readonly url = `${environment.jcDeliveryServer}variants`;
  private readonly urlSubVariants = `${environment.jcDeliveryServer}sub-variants`;
  private readonly urlOutCallStatuses = `${environment.jcDeliveryServer}statuses-cw-garage-branch-ticket`;
  private readonly urlInCallStatuses = `${environment.jcDeliveryServer}statuses-user`;

  constructor(private readonly http: HttpClient) {}

  getVariants(
    filters?: Record<string, string>,
    page?:number
  ): Observable<ApiResponse<Variant[]>> {
    const params = new HttpParams().appendAll({
      ...(filters || {}),
      page:page
    });    
       
    return this.http.get<ApiResponse<Variant[]>>(this.url, { params });
  }

  createVariant(data: Partial<Variant>): Observable<ApiResponse<Variant>> {
    return this.http.post<ApiResponse<Variant>>(this.url, data);
  }

  updateVariant(
    id: number,
    data: Partial<Variant>,
  ): Observable<ApiResponse<Variant>> {
    return this.http.patch<ApiResponse<Variant>>(`${this.url}/${id}`, data);
  }

  deleteVariant(id: number): Observable<ApiResponse<void>> {
    return this.http.delete<ApiResponse<void>>(`${this.url}/${id}`);
  }

  createSubVariant(
    data: Partial<SubVariant>,
  ): Observable<ApiResponse<SubVariant>> {
    return this.http.post<ApiResponse<SubVariant>>(this.urlSubVariants, data);
  }

  updateSubVariant(
    id: number,
    data: Partial<SubVariant>,
  ): Observable<ApiResponse<SubVariant>> {
    return this.http.patch<ApiResponse<SubVariant>>(
      `${this.urlSubVariants}/${id}`,
      data,
    );
  }

  deleteSubVariant(id: number): Observable<ApiResponse<void>> {
    return this.http.delete<ApiResponse<void>>(`${this.urlSubVariants}/${id}`);
  }

  getVariantStatuses(
    isOutCall: boolean,
    variantId: number,
  ): Observable<Array<SelectOptions<number>>> {
    const apiURL = isOutCall ? this.urlOutCallStatuses : this.urlInCallStatuses;
    const params = { variantId: variantId.toString() };
    return this.http.get<ApiResponse<any>>(apiURL, { params }).pipe(
      map((res) => {
        if (res.code === 200) {
          return res.data
            ?.filter((el) => el?.name?.en)
            ?.map((el) => {
              const statuse: SelectOptions<number> = {
                value: +el.id,
                label: el.name.en,
              };
              return statuse;
            });
        }
      }),
    );
  }
}
