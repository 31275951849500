import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {UntypedFormGroup, Validators, UntypedFormBuilder} from '@angular/forms';
import { Router } from '@angular/router';
import { Credentials } from '../../models/user/credentials';
import { AuthService } from '../../service/auth.service';
import { GlobalService } from '../../service/global.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  userCredentials: Credentials;
  loader: boolean;
  lang: string;
  isNotificationVisible = false;
  loginForm: UntypedFormGroup;
  userName: string;
  submitted = false;
  errorMesage: string;
  isButtonDisabled: boolean;
  isPasswordFocused: boolean;
  isUserNameFocused: boolean;
  isPasswordVisible: boolean;
  language = 'en';
  constructor(
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private auth: AuthService,
    private router: Router,
    protected global: GlobalService
  ) {
  }

  ngOnInit() {
    this.loader = false;

    if (this.auth.isUserLoggedIn) {
      this.navigateLoggedInUser();
    }

    this.setInitialAttributes();
    this.setLoginForm();
  }

  private setInitialAttributes() {
    this.isButtonDisabled = false;
    this.isPasswordVisible = false;
    this.isNotificationVisible = false;
  }

  onSubmit() {
    this.loader = true;
    this.getFormControls();

    if (this.loginForm.invalid) {
      return;
    }

    this.auth.authenticate(
      this.getFormControls().userName.value,
      this.getFormControls().password.value
    ).subscribe((response) => {
      if (response.code === 200) {
        this.global.saveUser(response.data).subscribe({
          next: (user) => {
            this.navigateLoggedInUser();
            this.loader = false; },
          error: (error) => {
            this.loader = false;
          },
        });
      } else {
        this.loader = false;
        this.isNotificationVisible = true;
        this.isButtonDisabled = false;
        this.errorMesage = response.message;
      }
    },
      (error) => {
        this.isNotificationVisible = true;
        this.isButtonDisabled = false;
        this.errorMesage = error.error.message;
      });
  }

  navigateLoggedInUser() {
    const url = localStorage.getItem('url');
    if (url) {
      this.global.loggedIn = true;
      const redirectUrl = url;
      if (
        redirectUrl.includes('?data=') &&
        redirectUrl.includes('?data=')
      ) {
        const urls = redirectUrl.split('?data=');
        const query = urls[1].split('&approve=');
        this.router.navigate([urls[0]], {
          queryParams: { data: query[0], approve: query[1] }
        });
      } else {
        this.router.navigate([url]);
      }
    } else {
      this.router.navigate(['dashboard/orders/orders-list']);
    }
  }


  getFormControls(): UntypedFormGroup['controls'] {
    return this.loginForm.controls;
  }

  toggleViewPassword(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  private setLoginForm(): void {
    this.loginForm = this.formBuilder.group({
      userName: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(4)]]
    });
  }

}
