import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormArray,
  AbstractControl,
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { FormStatus } from '@shared/enums/form-status.enum';
import { SelectOptions } from '@app/shared/models/global/response';
import { CrudService } from '@app/shared/service/crud';
import { BottomGroup, FieldConfig, ToggleConfig } from '@app/shared/models';
import { DataType } from '@app/shared/enums';
import { ImageUploaderHttpService } from '@app/shared/service/image-uploader-http.service';
import { GlobalService } from '@app/shared/service/global.service';
import { phoneValidator } from '@app/shared/helpers/phone-validator';
import { emailValidator } from '@app/shared/helpers/email-validator';

@Component({
  selector: 'app-generated-page-form',
  templateUrl: './generated-page-form.component.html',
  styleUrls: ['./generated-page-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneratedPageFormComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;

  staticLists: { [keyName: string]: Array<SelectOptions<any>> } = {};
  dynamicLists$: {
    [keyName: string]: Observable<Array<SelectOptions<number>>>;
  } = {};
  readonly DATA_TYPES = DataType;
  getRequiredStarBounded: Function;

  @Input() generatedPage: any;
  @Output() formChanged: EventEmitter<any> = new EventEmitter();
  @Output() formStatusChanged: EventEmitter<FormStatus> =
    new EventEmitter<FormStatus>();
  @Output() changeLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  private readonly destroy$: Subject<void> = new Subject<void>() ;

  private timeFields: Array<string> = [];
  private booleanFields: {
    [key: string]: ToggleConfig;
  } = {};
  // TODO: date and date-time in UI
  private dateFields: Array<string> = [];
  private dateTimeFields: Array<string> = [];
  private numericTranslationFields: Array<string> = [];

  constructor(
    private readonly fb: UntypedFormBuilder,
    public readonly crudService: CrudService,
    private readonly imageUploaderHttpService: ImageUploaderHttpService,
    private readonly globalService: GlobalService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.handleFormChanges();
    this.handleFormStatusChanges();
    this.setBoundedFunctions();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateGeneratedPageLogoFile(
    file: File,
    control: AbstractControl,
    translationKeys: Array<string>,
    isMultiple?: boolean,
  ): void {
    this.changeLoading.emit(true);
    this.uploadImage(file)
      .pipe(takeUntil(this.destroy$))
      .subscribe((link: string) => {
        if (translationKeys?.length) {
          translationKeys.forEach((translationKey) =>
            control.get(translationKey).setValue(link),
          );
        } else {
          if (isMultiple) {
            const arr: Array<string> = control.value || [];
            control.setValue([...arr, link]);
          } else {
            control.setValue(link);
          }
        }
        this.changeLoading.emit(false);
      });
  }

  addMoreGroup(formArrayName: string): void {
    const arrayFormFields = {};
    const group = this.crudService.selectedCrud.bottomGroupedFields.find(
      (group) => group.backendArrayName === formArrayName,
    );
    group.dataModel.forEach((fieldConfig) => {
      this.setFormControls(fieldConfig, arrayFormFields);
    });
    (this.form.get(formArrayName) as UntypedFormArray).push(
      this.fb.group(arrayFormFields),
    );
  }

  removeArrayGroupRow(index: number, formArrayName: string): void {
    (this.form.get(formArrayName) as UntypedFormArray).removeAt(index);
  }

  private uploadImage(file): Observable<string> {
    return this.imageUploaderHttpService.imageUpload(file);
  }
  radioChange($event) {
    // console.log($event);
  }
  private initForm(): void {
    const formFields = {};
    this.crudService.selectedCrud.dataModel.forEach((fieldConfig) => {
      this.setFormControls(fieldConfig, formFields);
    });

    /** Map the bottom grouped fields section */
    this.crudService.selectedCrud.bottomGroupedFields?.forEach((group) => {
      if (group.backendArrayName) {
        this.handleFormArray(group, formFields);
      } else if (group.backendObjectName) {
        this.handleFormObject(group, formFields);
      } else {
        group.dataModel.forEach((fieldConfig) => {
          this.setFormControls(fieldConfig, formFields);
        });
      }
    });

    this.form = this.fb.group(formFields);

    this.handleTranslationSingleFields();
    this.patchForm();
  }

  private handleFormArray(group: BottomGroup, formFields: Object): void {
    const forms = this.getFormArrayFromData(group);
    const formArray: UntypedFormArray = new UntypedFormArray(forms || []);
    formFields[group.backendArrayName] = formArray;
  }

  getFormArrayFromData(group: BottomGroup): any {
    return this.generatedPage
      ? this.generatedPage[group.backendArrayName]?.map((data) => {
          const arrayFields = {};
          group.dataModel.forEach((fieldConfig) => {
            this.setFormControls(fieldConfig, arrayFields, data);
          });
          return this.fb.group(arrayFields);
        })
      : [];
  }

  private handleFormObject(group: BottomGroup, formFields: Object): void {
    const objectFields = {};
    group.dataModel.forEach((fieldConfig) =>
      this.setFormControls(fieldConfig, objectFields),
    );

    formFields[group.backendObjectName] = this.fb.group(objectFields);
  }

  /** handle all translation fields that displayed in a single field */
  private handleTranslationSingleFields(): void {
    this.crudService.selectedCrud.dataModel
      .filter((fieldConfig) => fieldConfig.dataType.includes('(1)'))
      .forEach((singleTranslationField) => {
        this.form
          .get(singleTranslationField.backendKeyName)
          .get('en')
          .valueChanges.pipe(takeUntil(this.destroy$))
          .subscribe((value) =>
            this.form
              .get(singleTranslationField.backendKeyName)
              .get('ar')
              .setValue(value),
          );
      });
  }

  private setFormControls(
    fieldConfig: FieldConfig,
    formFields: Object,
    data?: any,
  ): void {
    /**
        (2) --> translation, translationSingle, textareaTranslation, textareaTranslationSingle, imageTranslation,
        (1) --> imageTranslationSingle, pdfTranslation, pdfTranslationSingle, numberTranslation, numberTranslationSingle
     */
    if (
      fieldConfig.dataType.includes('(2)') ||
      fieldConfig.dataType.includes('(1)')
    ) {
      formFields[fieldConfig.backendKeyName] = this.fb.group({
        en: [
          (data && data[fieldConfig.backendKeyName]?.en) || '',
          this.getValidators(fieldConfig),
        ],
        ar: [
          (data && data[fieldConfig.backendKeyName]?.ar) || '',
          this.getValidators(fieldConfig),
        ],
      });

      this.handleNumericTranslationFields(fieldConfig);
    } else {
      switch (fieldConfig.dataType) {
        case DataType.staticList:
          this.staticLists[fieldConfig.backendKeyName] = fieldConfig.list;
          break;
        case DataType.dynamicList:
          this.dynamicLists$[fieldConfig.backendKeyName] =
            this.crudService.getDropdownData(fieldConfig.apiConfig);
          break;
        case DataType.time:
          this.timeFields.push(fieldConfig.backendKeyName);
          break;
        case DataType.date:
          this.dateFields.push(fieldConfig.backendKeyName);
          break;
        case DataType.dateTime:
          this.dateTimeFields.push(fieldConfig.backendKeyName);
          break;
        case DataType.boolean:
          this.booleanFields[fieldConfig.backendKeyName] =
            this.getBooleanValues(fieldConfig);
          break;
      }
      this.setField(formFields, fieldConfig, data);
    }
  }

  private setField(
    formFields: Object,
    fieldConfig: FieldConfig,
    data: any,
  ): void {
    formFields[fieldConfig.backendKeyName] = this.getFieldConfig(
      fieldConfig,
      data,
    );
  }

  private getFieldConfig(fieldConfig: FieldConfig, data: any): Array<any> {
    return [
      (data && data[fieldConfig.backendKeyName]) ||
        (fieldConfig.dataType === DataType.boolean
          ? this.getBooleanValues(fieldConfig)?.inactiveValue
          : ''),
      this.getValidators(fieldConfig),
    ];
  }

  private getBooleanValues(fieldConfig: FieldConfig): ToggleConfig {
    if (
      fieldConfig.toggleConfig?.activeValue === 'true' &&
      fieldConfig.toggleConfig?.inactiveValue === 'false'
    ) {
      return {
        activeValue: true,
        inactiveValue: false,
      };
    }
    return {
      activeValue: fieldConfig.toggleConfig?.activeValue,
      inactiveValue: fieldConfig.toggleConfig?.inactiveValue,
    };
  }

  private getValidators(fieldConfig: FieldConfig): Validators {
    const validators = fieldConfig.isRequired ? [Validators.required] : [];
    if (fieldConfig.minLength) {
      validators.push(Validators.minLength(fieldConfig.minLength));
    }
    if (fieldConfig.maxLength) {
      validators.push(Validators.maxLength(fieldConfig.maxLength));
    }
    if (fieldConfig.dataType === DataType.email) {
      validators.push(emailValidator.bind(this));
    } else if (fieldConfig.dataType === DataType.phone) {
      validators.push(phoneValidator.bind(this));
    }
    return validators;
  }

  private patchForm(): void {
    if (!this.generatedPage) {
      return;
    }

    this.handleDateFields();
    this.handleTimeFields();
    this.handleDateTimeFields();

    this.form.patchValue(this.generatedPage);
    this.formChanged.emit(this.form.value);
  }

  private handleNumericTranslationFields(fieldConfig: FieldConfig): void {
    if (
      [DataType.numberTranslation, DataType.numberTranslationSingle].includes(
        fieldConfig.dataType as DataType,
      )
    ) {
      this.numericTranslationFields.push(fieldConfig.backendKeyName);
    }
  }

  private handleFormChanges(): void {
    const emitValue = (value: any) =>
      this.formChanged.emit({
        generatedPage: value,
        numericTranslationFields: this.numericTranslationFields,
        dateFields: this.dateFields,
        timeFields: this.timeFields,
        dateTimeFields: this.dateTimeFields,
      });

    this.form.valueChanges
      .pipe(debounceTime(500), tap(emitValue), takeUntil(this.destroy$))
      .subscribe();
  }

  private handleFormStatusChanges(): void {
    const emitStatus = (value: FormStatus) =>
      this.formStatusChanged.emit(value);

    this.form.statusChanges
      .pipe(
        startWith(this.form.status),
        distinctUntilChanged(),
        tap(emitStatus),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private handleDateFields(): void {
    this.dateFields.forEach(
      (dateFieldName) =>
        (this.generatedPage[dateFieldName] = this.globalService.utcToLocalDate(
          this.generatedPage[dateFieldName],
        )),
    );
  }

  private handleTimeFields(): void {
    this.timeFields.forEach(
      (timeFieldName) =>
        (this.generatedPage[timeFieldName] = this.globalService.utcToLocalTime(
          this.generatedPage[timeFieldName],
          'HH:mm',
        )),
    );
  }

  private handleDateTimeFields(): void {
    this.dateTimeFields.forEach(
      (dateTimeFieldName) =>
        (this.generatedPage[dateTimeFieldName] =
          this.globalService.utcToLocalDate(
            this.generatedPage[dateTimeFieldName],
          )),
    );
  }

  private setBoundedFunctions(): void {
    this.getRequiredStarBounded = this.getRequiredStar.bind(this);
  }
  private getRequiredStar(isRequired: boolean): string {
    return isRequired ? ' *' : '';
  }
}
