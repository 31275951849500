import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import 'moment-timezone';

@Pipe({
  name: 'jcLocalToUtcTime'
})
export class LocalToUtcTimePipe implements PipeTransform {

  transform(time: string, format?: string): string | Date {
    if (!time) {
      return null;
    }

    const timeZone = localStorage.getItem('timeZone');
    time = time.replace('.', ':');
    const dateOnly = moment().format('DDD MMM DD YYYY');
    return moment.tz(`${dateOnly} ${time}`, 'DDD MMM DD YYYY h:mm:ss', timeZone).utc().format(format);
  }

}
