import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectOptions, PageInfo } from 'src/app/shared/models/global/response';
import { ApiLibrary } from 'src/app/shared/helpers/http-params';
import { AchievementFromList, AchievementToBeSaved } from '../../models/kpi';
import { GlobalService } from 'src/app/shared/service/global.service';
import { ToastService, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { SalesService } from '../../services/sales.service';
import { CategoriesService } from 'src/app/shared/service/categories.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, NgModel } from '@angular/forms';

@Component({
  selector: 'app-kpi-achievement',
  templateUrl: './kpi-achievement.component.html',
  styleUrls: ['./kpi-achievement.component.css'],
})
export class KpiAchievementComponent implements OnInit {
  kpiId: number;
  selectedKpiType: number;
  selectedAchievement: AchievementFromList;
  kpiTypes: SelectOptions<number>[] = [];
  achievements: AchievementFromList[];
  pagination: PageInfo;
  achievementToBeSaved: AchievementToBeSaved;
  toggleDelete = false;
  loader = false;
  loaderFile = false;
  isUpdate = false;
  // modal

  isCreateModalOpened = false;
  file: File;
  fileError: {
    error: string;
    success: string;
  };
  agentId: number;
  @ViewChild('date', {
    static: true,
  })
  from: MDBDatePickerComponent;
  @ViewChild('myForm', { static: true }) myForm: NgForm;
  @ViewChild('description', { static: true }) comment: NgModel;

  constructor(
    private globalService: GlobalService,
    private toast: ToastService,
    private salesService: SalesService,
    private catservice: CategoriesService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.kpiId = parseInt(this.route.snapshot.params.id, 10);
    this.agentId = parseInt(this.route.snapshot.params.agentId, 10);
    this.file = new File([''], 'Upload Document(*)');
    this.getPage(1);
    this.initSaveKpiBody(false);
  }

  getPage(event) {
    if (event) {
      this.getAchievements({ page: event });
    }
  }

  triggerDelete(achievement: AchievementFromList) {
    this.selectedAchievement = achievement;
    this.toggleDelete = true;
  }

  deleteKPI(event) {
    this.loader = true;
    this.salesService
      .deleteAchievement(
        this.globalService.headers,
        this.selectedAchievement.id,
      )
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.getAchievements({ page: this.pagination.current_page });
            this.toast.success(response.message);
            this.toggleDelete = false;
          } else {
            this.toast.error(response.message);
          }
          this.loader = false;
        },
        (error) => {
          this.loader = false;
        },
      );
  }

  saveAchievement() {
    this.loader = true;
    this.salesService
      .saveAchievement(this.globalService.headers, this.achievementToBeSaved)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.getAchievements({ page: this.pagination.current_page });
            this.toast.success(response.message);
            this.closeCreateModal(true);
          } else {
            this.toast.error(response.message);
          }
          this.loader = false;
        },
        (error) => {
          this.loader = false;
        },
      );
  }

  initSaveKpiBody(isUpdate: boolean) {
    this.isUpdate = isUpdate;
    if (!isUpdate) {
      this.achievementToBeSaved = {
        amount: null,
        count: null,
        comment: null,
        user_admin_id: this.agentId,
        key_performance_indicator_id: this.kpiId,
        weight: null,
        achievement_date: null,
        image: null,
        kpi_percent: null,
      };
    } else {
      this.achievementToBeSaved = {
        id: this.selectedAchievement.id,
        amount: this.selectedAchievement.amount,
        count: this.selectedAchievement.count,
        comment: this.selectedAchievement.comment,
        user_admin_id: this.agentId,
        weight: this.selectedAchievement.weight,
        achievement_date: this.selectedAchievement.achievement_date.toString(),
        image: this.selectedAchievement.image,
        key_performance_indicator_id:
          this.selectedAchievement.key_performance_indicator_id,
        kpi_percent: this.selectedAchievement.kpi_percent,
      };
    }
  }

  // create modal public functions
  closeCreateModal(event) {
    this.isCreateModalOpened = false;
    this.selectedAchievement = null;
    this.from.clearDate();
    this.myForm.reset();
    this.comment.reset();
    this.file = new File([''], 'Upload New Document/Image');
  }

  validate() {
    return (
      this.achievementToBeSaved &&
      this.achievementToBeSaved.achievement_date &&
      this.achievementToBeSaved.image
    );
  }

  dateChanged(event) {
    if (event.epoc) {
      const date = this.globalService.formatDate(
        this.globalService
          .datePlusDays(event.actualDateFormatted, 0)
          .toString(),
        'YYYY-MM-DD',
      );
      this.achievementToBeSaved.achievement_date = date;
    }
  }

  openCreate() {
    this.initSaveKpiBody(false);
    this.isCreateModalOpened = true;
    this.myForm.reset();
  }

  openUpdate(achievement: AchievementFromList) {
    this.selectedAchievement = achievement;
    this.initSaveKpiBody(true);
    this.isCreateModalOpened = true;
  }

  loadSplashEnglish(event): void {
    this.fileError = {
      error: null,
      success: null,
    };
    this.file = event.target.files[0];

    if (
      (this.file.type.toLowerCase().includes('image') &&
        this.file.size / 1024 <= 200) ||
      (this.file.type.toLowerCase().includes('pdf') &&
        this.file.size / 1024 <= 2048)
    ) {
      this.loaderFile = true;
      this.catservice
        .imageUpload(this.file, 'advertisements/splash/en')
        .subscribe(
          (response) => {
            if (response.code === 200) {
              this.fileError.error = null;
              this.fileError.success = response.message;
              this.achievementToBeSaved.image = response.data;
              this.loaderFile = false;
            } else {
              this.fileError = {
                error: response.message,
                success: null,
              };
              this.loaderFile = false;
            }
          },
          (error) => {
            this.fileError = {
              error: error.error.message,
              success: null,
            };
            this.loaderFile = false;
          },
        );
    } else {
      this.fileError = {
        error: this.file.type.toLowerCase().includes('image')
          ? 'file size must be less or equals 200kb '
          : 'file size must be less or equals 2mb',
        success: null,
      };
      console.log(this.fileError);
    }
  }
  removeFile() {
    this.file = new File([''], 'Upload New Document/Image');
    this.achievementToBeSaved.image = null;
    this.fileError = null;
  }

  goBack() {
    this.router.navigate(['dashboard/sales/kpi-setup']);
  }

  private getAchievements(params?: any) {
    this.salesService
      .getAchievement(
        this.globalService.headers,
        this.kpiId,
        new ApiLibrary().cleanObjectFromComponent(params),
      )
      .subscribe((response) => {
        if (response.code === 200) {
          this.achievements = response.data as AchievementFromList[];
          this.pagination = response.page_info;
        }
      });
  }

  // create modal private functions
}
