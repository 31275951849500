// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-row {
  font-weight: bold;
}

.file-field {
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  position: relative;
}

form {
  min-width: 760px;
}

:host::ng-deep .mdb-select-outline .mdb-select-value {
  padding: 0.375rem 0.75rem;
}

.success-switch {
  position: relative;
}

.custom-btn {
  height: 1rem;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 25px;
}

.segment-wrapper {
  width: 129px;
}

.rmove-segment {
  color: red;
  cursor: pointer;
  opacity: 0.7;
}
.rmove-segment:hover {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/cashback-campaign/components/cashback-campaign-edit-dialog/cashback-campaign-form/cashback-campaign-form.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AACA;EACE,UAAA;EACA,eAAA;EACA,YAAA;AAEF;AADE;EACE,UAAA;AAGJ","sourcesContent":[".title-row {\n  font-weight: bold;\n}\n\n.file-field {\n  margin-bottom: 25px;\n  display: flex;\n  flex-direction: row;\n  position: relative;\n}\n\nform {\n  min-width: 760px;\n}\n\n:host::ng-deep .mdb-select-outline .mdb-select-value {\n  padding: 0.375rem 0.75rem;\n}\n\n.success-switch {\n  position: relative;\n}\n\n.custom-btn {\n  height: 1rem;\n  padding: 2px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin: auto;\n  margin-bottom: 25px;\n}\n\n.segment-wrapper {\n  width: 129px;\n}\n.rmove-segment {\n  color: red;\n  cursor: pointer;\n  opacity: 0.7;\n  &:hover {\n    opacity: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
