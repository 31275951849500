import { Component, OnInit, ViewChild } from '@angular/core';
import {
  IMyOptions,
  ToastService,
  MDBDatePickerComponent,
} from 'ng-uikit-pro-standard';
import { GlobalService } from 'src/app/shared/service/global.service';
import {
  BranchBillingList,
  BillListQueryParams,
  BillToBeSaved,
} from 'src/app/shared/models/laundries/branch-bill';
import { BranchesService } from 'src/app/shared/service/branches.service';
import {
  PageInfo,
  SelectOptions,
  IdType,
  JustCleanLaundryPercentage,
} from 'src/app/shared/models/global/response';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriesService } from 'src/app/shared/service/categories.service';
import { ApiLibrary } from 'src/app/shared/helpers/http-params';
import { NgForm, NgModel } from '@angular/forms';

@Component({
  selector: 'app-branch-billing',
  templateUrl: './branch-billing.component.html',
  styleUrls: ['./branch-billing.component.css'],
})
export class BranchBillingComponent implements OnInit {
  billToBeSaved: BillToBeSaved;
  expiryDateOption: IMyOptions;
  billList: BranchBillingList[];
  billTypes: SelectOptions<number>[];
  branchId: any;
  laundryId: any;
  pagination: PageInfo;
  queryParams: BillListQueryParams;
  decimal: number;
  currency: string;
  isCreateModalOpened = false;
  isConfirmationModalOpened = false;
  loader = {
    contract: false,
    saveLoader: false,
  };
  contractFile: File;
  contractFileError: {
    error: string;
    success: string;
  };
  justCleanOrLaundry: JustCleanLaundryPercentage;
  @ViewChild('myForm', {
    static: true,
  })
  myForm: NgForm;
  @ViewChild('description', {
    static: true,
  })
  description: NgModel;
  @ViewChild('datePicker', {
    static: true,
  })
  date: MDBDatePickerComponent;
  @ViewChild('from', {
    static: true,
  })
  from: MDBDatePickerComponent;
  @ViewChild('to', {
    static: true,
  })
  to: MDBDatePickerComponent;
  resetSlider = false;
  constructor(
    private globalService: GlobalService,
    private branchService: BranchesService,
    private route: ActivatedRoute,
    private router: Router,
    private catService: CategoriesService,
    private toast: ToastService,
  ) {}

  ngOnInit() {
    this.decimal = parseInt(localStorage.getItem('decimal'), 10);
    this.currency = this.globalService.currency;
    this.branchId = parseInt(this.route.snapshot.params.branchId, 10);
    this.laundryId = this.route.parent.snapshot.params.id;
    this.reset();
    this.getBranchBillingTypes();
  }

  showDate(date: string, isStart: boolean) {
    return date
      ? this.globalService.formatDate(date, 'DD-MM-YYYY')
      : isStart
      ? 'Select Start Date'
      : 'Select Expiry Date';
  }

  startDateChanged(event) {
    if (event.epoc) {
      const date = this.globalService.formatDate(
        this.globalService
          .datePlusDays(event.actualDateFormatted, 0)
          .toString(),
        'YYYY-MM-DD',
      );
      this.expiryDateOption = {
        disableUntil: {
          year: parseInt(date.split('-')[0], 10),
          month: parseInt(date.split('-')[1], 10),
          day: parseInt(date.split('-')[2], 10),
        },
      };
      this.queryParams.startdate = date;
      this.getBranchBillingList(this.queryParams);
    }
  }

  endDateChanged(event) {
    if (event.epoc) {
      const date = this.globalService.formatDate(
        this.globalService
          .datePlusDays(event.actualDateFormatted, 0)
          .toString(),
        'YYYY-MM-DD',
      );
      this.queryParams.enddate = date;
      this.getBranchBillingList(this.queryParams);
    }
  }

  getPage(number: number) {
    this.queryParams.page = number;
    this.getBranchBillingList(this.queryParams);
  }

  reset() {
    this.from.clearDate();
    this.to.clearDate();
    this.queryParams = {
      laundry_branch_id: this.branchId,
      page: 1,
      enddate: null,
      startdate: null,
    };
    this.getBranchBillingList(this.queryParams);
    this.initFormValues();
  }

  showPrettyDate(date: string) {
    return this.globalService.formatDate(date, 'DD-MM-YYYY');
  }

  openConfirmationModal() {
    this.isConfirmationModalOpened = true;
  }

  closeCreateModal($event) {
    this.isCreateModalOpened = false;
    this.initFormValues();
  }

  closeConfirmationModal($event) {
    this.isConfirmationModalOpened = false;
    this.initFormValues();
  }

  validate() {
    return (
      this.billToBeSaved &&
      this.billToBeSaved.invoice_type_id &&
      this.billToBeSaved.invoice_date &&
      this.billToBeSaved.description &&
      this.billToBeSaved.document &&
      this.billToBeSaved.document.file &&
      this.billToBeSaved.invoice_cost
    );
  }

  goBack() {
    this.router.navigate([
      '/dashboard/' +
        window.localStorage.getItem('module') +
        '/branch-list/' +
        this.laundryId,
    ]);
  }

  saveBill() {
    this.loader.saveLoader = true;
    this.billToBeSaved.discount_by_justclean =
      this.justCleanOrLaundry.justclean;
    this.billToBeSaved.discount_by_laundry = this.justCleanOrLaundry.laundry;
    this.branchService
      .saveBranchBill(this.globalService.headers, this.billToBeSaved)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.toast.success(response.message);
          }
          this.loader.saveLoader = false;
          this.closeConfirmationModal(true);
          this.closeCreateModal(true);
          this.reset();
          this.resetSlider = true;
        },
        (error) => {
          this.loader.saveLoader = false;
        },
      );
  }

  removeContractFile() {
    this.contractFile = new File([''], 'Upload the Contract(*)');
    this.billToBeSaved.document = null;
    this.contractFileError = null;
  }

  loadContractDocument(event): void {
    this.contractFileError = {
      error: null,
      success: null,
    };
    this.contractFile = event.target.files[0];
    console.log(this.contractFile.size / 1024000);

    if (this.contractFile.size / 1024000 <= 2) {
      this.loader.contract = true;
      this.catService
        .imageUpload(this.contractFile, 'advertisements/contract')
        .subscribe(
          (response) => {
            if (response.code === 200) {
              this.contractFileError.error = null;
              this.contractFileError.success = response.message;
              this.billToBeSaved.document = {
                file: response.data,
                name: this.contractFile.name,
              };
              this.loader.contract = false;
            } else {
              this.contractFileError = {
                error: response.message,
                success: null,
              };
              this.loader.contract = false;
            }
          },
          (error) => {
            this.contractFileError = {
              error: error.error.message,
              success: null,
            };
            this.loader.contract = false;
          },
        );
    } else {
      this.contractFileError = {
        error: 'The contract file must be less or equals 2mb',
        success: null,
      };
    }
  }

  getSliderValues(event: JustCleanLaundryPercentage) {
    this.resetSlider = false;
    this.justCleanOrLaundry = event;
  }

  private initFormValues() {
    this.billToBeSaved = new BillToBeSaved();
    this.billToBeSaved.document = {
      file: null,
      name: null,
    };
    this.billToBeSaved.invoice_date = null;
    this.billToBeSaved.laundry_branch_id = this.branchId;
    this.contractFile = new File([''], 'Upload the Contract(*)');
    this.justCleanOrLaundry = {
      justclean: 50,
      laundry: 50,
    };
    this.contractFileError = {
      error: null,
      success: null,
    };
    this.myForm.reset();
    this.description.reset();
    this.date.clearDate();
    this.date.ChangeZIndex();
  }

  private getBranchBillingList(params: BillListQueryParams) {
    this.branchService
      .getBranchBills(
        this.globalService.headers,
        new ApiLibrary().cleanObjectFromComponent(params),
      )
      .subscribe((response) => {
        if (response.code === 200) {
          this.billList = response.data;
          this.pagination = response.page_info;
        } else {
          this.toast.error(response.message);
        }
      });
  }

  private getBranchBillingTypes() {
    this.branchService
      .getBranchBillTypes(this.globalService.headers)
      .subscribe((response) => {
        if (response.code === 200) {
          const types = response.data as IdType[];
          this.billTypes = types.map((e) => {
            return {
              label: e.type,
              value: e.id,
            };
          });
        } else {
          this.toast.error(response.message);
        }
      });
  }
}
