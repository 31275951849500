export enum DataType {
  string = 'String',
  translationSingle = '(1)String {ar, en}',
  translation = '(2)String {ar, en}',
  textarea = 'Textarea',
  textareaTranslationSingle = '(1)Textarea {ar, en}',
  textareaTranslation = '(2)Textarea {ar, en}',
  image = 'Image',
  multipleImages = 'Multiple images',
  imageTranslationSingle = '(1)Image {ar, en}',
  imageTranslation = '(2)Image {ar, en}',
  pdf = 'PDF',
  multiplePDFs = 'Multiple PDFs',
  pdfTranslationSingle = '(1)PDF {ar, en}',
  pdfTranslation = '(2)PDF {ar, en}',
  number = 'Numeric',
  numberTranslationSingle = '(1)Numeric {ar, en}',
  numberTranslation = '(2)Numeric {ar, en}',
  boolean = 'Boolean',
  staticList = 'Static list',
  dynamicList = 'Dynamic list',
  date = 'Date',
  time = 'Time',
  dateTime = 'Date & Time',
  email = 'Email',
  phone = 'Phone',
  password = 'Password',
}
