import { HomeCleaningBranchesService } from '@app/shared/service/home-cleaning/home-cleaning-branches.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';

import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { HomecleanCompanyService } from '@app/modules/homeclean/modules/homeclean-company/shared/services/homeclean-company.service';
import { HcService, HcServiceBranch, HcBranch } from '@app/shared/models';
import { HomeCleaningServicesService } from '@app/shared/service/home-cleaning/home-cleaning-services.service';

@Component({
  selector: 'app-assign-service-to-company',
  templateUrl: './assign-service-to-company.component.html',
  styleUrls: ['./assign-service-to-company.component.scss'],
})
export class AssignServiceToCompanyComponent implements OnInit {
  isLoading: boolean;
  isLoadingData: boolean;
  form: UntypedFormArray;
  companies: any[];
  selectedCompanyId: any;

  private hcServiceBranches: Array<HcServiceBranch>;
  private readonly destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: {
      dataRow: HcService;
      PageNamePrefix: string;
      foreignKeyParam: Object;
    } | any,
    private readonly dialogRef: DialogRef<AssignServiceToCompanyComponent>,
    private readonly cd: ChangeDetectorRef,
    private readonly fb: UntypedFormBuilder,
    private readonly homeCleaningCompanyService: HomecleanCompanyService,
    private readonly homeCleaningBranchesService: HomeCleaningBranchesService,
    private readonly homeCleaningServicesService: HomeCleaningServicesService,
  ) {}

  ngOnInit(): void {
    this.loadSelectedBranches();
  }

  close(): void {
    this.dialogRef.close();
  }

  changeCompany(companyId: number): void {
    this.loadCompanyBranches(companyId);
  }

  getOddIndexes(controls: UntypedFormControl[]): UntypedFormControl[] {
    return controls?.filter((ctrl, index) => index % 2 === 1);
  }

  getEvenIndexes(controls: UntypedFormControl[]): UntypedFormControl[] {
    return controls?.filter((ctrl, index) => index % 2 === 0);
  }

  private mapActiveStatus(config: HcBranch) {
    return {
      id: config.id,
      companyName: config.companyName?.en,
      status: !!this.hcServiceBranches.find(
        (branch) => branch.hcCompanyBranchId === config.id,
      ),
    };
  }

  private loadSelectedBranches(): void {
    this.isLoadingData = true;
    this.homeCleaningBranchesService
      .getHCAllBranches({
        hcServiceId: this.data.dataRow?.id,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((branches) => {
        this.hcServiceBranches = branches.map((branch) => ({
          hcCompanyBranchId: branch.id,
          hcCompanyId: branch.hcCompanyId,
        }));
        this.loadCompanies();
      });
  }

  private loadCompanies(): void {
    const countryId = localStorage.getItem('country');
    this.homeCleaningCompanyService
      .getCompanies({ countryId, groupId: this.data.dataRow.groupId })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (companies) => {
          this.companies = [];
          this.companies = companies?.map((company) => ({
            value: company.id,
            label: company.name.en,
          }));
          if (companies?.length) {
            this.selectedCompanyId = companies[0].id;
            this.loadCompanyBranches(companies[0].id);
          } else {
            this.isLoadingData = false;
            this.cd.detectChanges();
          }
        },
        (error) => {
          this.dialogRef.close();
        },
      );
  }

  private loadCompanyBranches(hcCompanyId: number): void {
    this.isLoadingData = true;
    const countryId = localStorage.getItem('country');
    this.homeCleaningBranchesService
      .getHcBranches({ countryId, hcCompanyId })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (branches) => {
          this.form = this.fb.array(
            branches.map((branch) => {
              const formGroup = this.fb.group(this.mapActiveStatus(branch));

              formGroup.valueChanges
                .pipe(takeUntil(this.destroy$))
                .subscribe((changes: { id: number; status: boolean }) => {
                  this.modifyHcBranchesArray(
                    changes.id,
                    changes.status,
                    hcCompanyId,
                  );
                  this.updateService();
                });

              return formGroup;
            }),
          );

          this.isLoadingData = false;
          this.cd.detectChanges();
        },
        (error) => {
          this.dialogRef.close();
        },
      );
  }

  private modifyHcBranchesArray(
    hcCompanyBranchId: number,
    status: boolean,
    hcCompanyId: number,
  ): void {
    if (!status) {
      this.hcServiceBranches = this.hcServiceBranches.filter(
        (branch) => branch.hcCompanyBranchId !== hcCompanyBranchId,
      );
    } else {
      this.hcServiceBranches?.push({ hcCompanyBranchId, hcCompanyId });
    }
  }

  private updateService(): void {
    this.isLoading = true;
    this.homeCleaningServicesService
      .updateHcService(this.data.dataRow.id, {
        hcCompanyServices: this.hcServiceBranches,
      })
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isLoading = false;
          this.cd.detectChanges();
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
