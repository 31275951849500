import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../../../../shared/service/global.service';
import {
  GovernorateList,
} from '../../../../../shared/models/countries/country';
import {
  AreaArray,
  BrArea,
  NewBranchArea,
} from '../../../../../shared/models/laundries/branch';
import { BranchesService } from '../../../../../shared/service/branches.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { CountryService } from 'src/app/shared/service/country.service';

@Component({
  selector: 'app-branch-areas',
  templateUrl: './branch-areas.component.html',
  styleUrls: ['./branch-areas.component.css'],
})
export class BranchAreasComponent implements OnInit {
  laundryId: number;
  branchId: number;
  areas: BrArea[];
  initialSelectedAreas: Set<number> = new Set(); // Track initially selected areas
  listToBeShown: BrArea[];
  list: AreaArray[] = [];
  selectAll = false;
  loader = false;
  isUpdate: boolean;
  governorates: any[];
  governorate: number;
  isTouched: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private branchService: BranchesService,
    private countryService: CountryService,
    private toast: ToastService,
  ) {}

  ngOnInit() {
    this.laundryId = this.route.parent.snapshot.params.id;
    this.branchId = this.route.snapshot.params.branchId;
    this.isUpdate = this.route.snapshot.params.update === 'true' ? true : false;
    this.getGovernorates();
    this.getAreas();
  }

  changeGov(event) {
    this.showAreas(event);
    this.isTouched = false;
  }

  getGovernorates() {
    this.countryService
      .getAllListCities(this.globalService.headers)
      .subscribe((response) => {
        this.governorates = [];
        let gov = response as GovernorateList[];
        gov = gov.filter(
          (e) => e.country_id === this.globalService.countryId,
        );
        gov.forEach((governorate) => {
          this.governorates.push({
            value: governorate.id,
            label: governorate.name.en,
          });
        });
      });
  }

  getAreas() {
    this.branchService
      .getBranchAr(this.globalService.headers, this.branchId, {})
      .subscribe((response) => {
        if (response.code === 200) {
          this.areas = (response.data as BrArea[]) || [];
          this.areas = this.areas.filter(
            (area) => area.country_id === this.globalService.countryId,
          );
          // Store initially selected areas
          this.initialSelectedAreas = new Set(
            this.areas.filter(area => area.is_selected).map(area => area.id)
          );
        }
      });
  }

  private getCountryId(): string {
    return localStorage.getItem('country');
  }

  showAreas(govId: number) {
    this.listToBeShown = this.areas.filter(
      (area) => area.governorate_id === govId,
    );
    this.selectAll = !this.listToBeShown.find((e) => !e.is_selected);
  }

  toggleArea(area: BrArea) {
    area.is_selected = !area.is_selected;
    this.isTouched = true;
  }

  toggleSelectAll() {
    this.selectAll = !this.selectAll;
    this.isTouched = true;
    this.listToBeShown.forEach((ele) => {
      ele.is_selected = !!this.selectAll;
    });
  }

  checkSelected() {
    return !!(this.areas && this.areas.find((e) => e.is_selected === true));
  }

  saveBranchArea() {
    this.loader = true;
    const branchAreas = new NewBranchArea();
    branchAreas.laundry_branch_id = this.branchId;
    branchAreas.laundry_branch_areas = this.areas;

    if (this.getCountryId() === '2') {
      branchAreas.laundry_branch_areas = branchAreas.laundry_branch_areas.map(laundryBranchArea => {
        // Only set default values for newly selected areas
        if (laundryBranchArea.is_selected && !this.initialSelectedAreas.has(laundryBranchArea.id)) {
          return {
            ...laundryBranchArea,
            amount_delivery_charge: 0,
            amount_service_charge: 5,
            amount_delivery_charge_after_threshold: 0,
            amount_service_charge_threshold: 0,
            amount_service_charge_after_threshold: 5
          };
        }
        return laundryBranchArea;
      });
    }

    this.branchService
      .createBranchArea(this.globalService.headers, branchAreas)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.loader = false;
            this.toast.success(response.message);
            this.getAreas();
          }
        },
        (error) => {
          this.loader = false;
        },
      );
  }

  goBack() {
    this.router.navigate([
      '/dashboard/' +
      window.localStorage.getItem('module') +
      '/branch-list/' +
      this.laundryId,
    ]);
  }
}
