import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { PaginationHelper } from '@shared/helpers/pagination-helper';
import { LaundryService } from '@shared/service/laundry.service';
import { ProviderCommission } from './provider-commission.model';
import { BranchesService } from '@shared/service/branches.service';
import { CarwashCompanyService } from '@shared/service/carwash-company';
import { Group } from '@shared/models';
import { HomecleanCompanyService } from '@shared/service/home-cleaning/homeclean-company.service';
import { LocalStoreService } from '../../../../../../libs/justclean-services/src/lib/local-store/local-store.service';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { HomecleanCompany } from '@app/modules/homeclean/modules/homeclean-company/shared/models';

@Injectable({
  providedIn: 'root',
})
export class ProviderCommissionService {
  private readonly lang = 'en';
  private _pagination$: Subject<any> = new Subject<any>();
  pagination$: Observable<any> = this._pagination$.asObservable();
  isLaundryDesign: boolean;
  hcDesign: boolean;
  cwgDesign: boolean;

  constructor(
    private readonly branchesService: BranchesService,
    private readonly laundryService: LaundryService,
    private readonly carwashCompanyService: CarwashCompanyService,
    private readonly homecleanCompanyService: HomecleanCompanyService,
  ) {}

  getCommissions(
    currentPage,
    search,
    headers,
    selectedGroup: Group,
  ): Observable<ProviderCommission[]> {
    if (selectedGroup.designType === 'laundry') {
      this.isLaundryDesign = true;
      this.hcDesign = false;
      this.cwgDesign = false;
      return this.getLaundriesCommissions(currentPage, search, headers);
    } else if (selectedGroup.designType === 'homeCleaning') {
      this.isLaundryDesign = false;
      this.hcDesign = true;
      this.cwgDesign = false;

      return this.getHomeCleaningCommissions(currentPage, search, headers);
    } else {
      this.isLaundryDesign = false;
      this.hcDesign = false;
      this.cwgDesign = true;
      return this.getCarwashCommissions(currentPage, search, headers);
    }
  }

  getCommissionsWithoutPagination(
    currentPage,
    search,
    headers,
    selectedGroup: Group,
  ) {
    if (selectedGroup.designType === 'laundry') {
      this.isLaundryDesign = true;
      this.hcDesign = false;
      this.cwgDesign = false;
      return this.getAllLaundriesCommissions(headers);
    } else if (selectedGroup.designType === 'homeCleaning') {
      this.isLaundryDesign = false;
      this.hcDesign = true;
      this.cwgDesign = false;

      return this.getAllHomeCleaningCommissions(headers);
    } else {
      this.isLaundryDesign = false;
      this.hcDesign = false;
      this.cwgDesign = true;
      return this.getAllCarwashCommissions(headers, selectedGroup.id);
    }
  }

  getHomeCleaningCommissions(
    page,
    search,
    headers,
  ): Observable<ProviderCommission[]> {
    const countryId: number = +LocalStoreService.getItems<string>('country');

    return this.homecleanCompanyService
      .getCompaniesWithPagination({
        page,
        countryId,
        ...(search && { search }),
      })
      .pipe(
        switchMap((res: ApiResponse<HomecleanCompany[]>) => {
          const hcBranches: any[] = res.data
            .map(({ name, id, hcCompanyBranches }) => {
              return hcCompanyBranches.map((lb) => ({
                ...lb,
                laundryName: name[this.lang],
                laundryId: id,
              }));
            })
            .flat();
          const branchesEndpoints = hcBranches.map(
            ({ id, laundryName, name, laundryId, companyName }) => {
              return this.branchesService
                .getBranchContractInfo(headers, { hc_company_branch_id: id })
                .pipe(
                  map((c) => ({
                    data: c.data,
                    laundryName,
                    branchName: name || companyName[this.lang],
                    branchId: id,
                    laundryId,
                    date: c.data.log_laundry_branch_contracts[
                      c.data.log_laundry_branch_contracts.length - 1
                    ].created_at,
                    user: c.data.log_laundry_branch_contracts[
                      c.data.log_laundry_branch_contracts.length - 1
                    ].user_admin,
                  })),
                  catchError(() => {
                    return of({
                      data: null,
                      laundryName,
                      branchName: name || companyName[this.lang],
                      branchId: id,
                      laundryId,
                    });
                  }),
                );
            },
          );
          this._pagination$.next(
            PaginationHelper.camelToSnakeCase(res.pageInfo),
          );
          return forkJoin(branchesEndpoints);
        }),
      );
  }

  getCarwashCommissions(
    page,
    search,
    headers,
  ): Observable<ProviderCommission[]> {
    return this.carwashCompanyService
      .getCompaniesWithPagination({
        page,
        groupId: headers.group_id,
        ...(search && { search }),
      })
      .pipe(
        switchMap((res) => {
          const laundryBranches: any[] = res.data
            .map(({ name, id, cwGarageBranches }) => {
              return cwGarageBranches.map((lb) => ({
                ...lb,
                laundryName: name[this.lang],
                laundryId: id,
              }));
            })
            .flat();
          const branchesEndpoints = laundryBranches.map(
            ({ id, laundryName, name, laundryId, companyName }) => {
              return this.branchesService
                .getBranchContractInfo(headers, { cw_garage_branch_id: id })
                .pipe(
                  map((c) => ({
                    data: c.data,
                    laundryName,
                    branchName: name || companyName[this.lang],
                    branchId: id,
                    laundryId,
                    date: c.data.log_laundry_branch_contracts[
                      c.data.log_laundry_branch_contracts.length - 1
                    ].created_at,
                    user: c.data.log_laundry_branch_contracts[
                      c.data.log_laundry_branch_contracts.length - 1
                    ].user_admin,
                  })),
                  catchError(() => {
                    return of({
                      data: null,
                      laundryName,
                      branchName: name || companyName[this.lang],
                      branchId: id,
                      laundryId,
                    });
                  }),
                );
            },
          );
          this._pagination$.next(
            PaginationHelper.camelToSnakeCase(res.pageInfo),
          );
          return forkJoin(branchesEndpoints);
        }),
      );
  }

  getLaundriesCommissions(
    currentPage,
    search,
    headers,
  ): Observable<ProviderCommission[]> {
    return this.laundryService
      .getLaundriesWithParams(headers, {
        page: currentPage,
        ...(search && { searchstr: search }),
      })
      .pipe(
        switchMap((res) => {
          const laundryBranches: any[] = res.data
            .map(({ name, id, laundry_branches }) => {
              return laundry_branches.map((lb) => ({
                ...lb,
                laundryName: name,
                laundryId: id,
              }));
            })
            .flat();
          const branchesEndpoints = laundryBranches.map(
            ({ id, laundryName, name, laundryId }) => {
              return this.branchesService
                .getBranchContractInfo(headers, { laundry_branch_id: id })
                .pipe(
                  map((c) => ({
                    data: c.data,
                    laundryName,
                    branchName: name,
                    branchId: id,
                    laundryId,
                    date: c.data.log_laundry_branch_contracts[
                      c.data.log_laundry_branch_contracts.length - 1
                    ].created_at,
                    user: c.data.log_laundry_branch_contracts[
                      c.data.log_laundry_branch_contracts.length - 1
                    ].user_admin,
                  })),
                  catchError(() => {
                    return of({
                      data: null,
                      laundryName,
                      branchName: name,
                      branchId: id,
                      laundryId,
                    });
                  }),
                );
            },
          );
          this._pagination$.next(
            PaginationHelper.camelToSnakeCase(res.page_info),
          );
          return forkJoin(branchesEndpoints);
        }),
      );
  }

  getAllLaundriesCommissions(headers): Observable<ProviderCommission[]> {
    return this.laundryService.getLaundriesAllWithParams(headers, {}).pipe(
      switchMap((res) => {
        const laundryBranches: any[] = res.data
          .map(({ name, id, laundry_branches }) => {
            return laundry_branches.map((lb) => ({
              ...lb,
              laundryName: name,
              laundryId: id,
            }));
          })
          .flat();
        const branchesEndpoints = laundryBranches.map(
          ({ id, laundryName, name, laundryId }) => {
            return this.branchesService
              .getBranchContractInfo(headers, { laundry_branch_id: id })
              .pipe(
                map((c) => ({
                  data: c.data,
                  laundryName,
                  branchName: name,
                  branchId: id,
                  laundryId,
                  date: c.data.log_laundry_branch_contracts[
                    c.data.log_laundry_branch_contracts.length - 1
                  ].created_at,
                  user: c.data.log_laundry_branch_contracts[
                    c.data.log_laundry_branch_contracts.length - 1
                  ].user_admin,
                })),
                catchError(() => {
                  return of({
                    data: null,
                    laundryName,
                    branchName: name,
                    branchId: id,
                    laundryId,
                  });
                }),
              );
          },
        );
        return forkJoin(branchesEndpoints);
      }),
    );
  }

  getAllHomeCleaningCommissions(headers): Observable<ProviderCommission[]> {
    const countryId: number = +LocalStoreService.getItems<string>('country');

    return this.homecleanCompanyService
      .getCompanies({
        countryId,
      })
      .pipe(
        switchMap((res: HomecleanCompany[]) => {
          const hcBranches: any[] = res
            .map(({ name, id, hcCompanyBranches }) => {
              return hcCompanyBranches.map((lb) => ({
                ...lb,
                laundryName: name[this.lang],
                laundryId: id,
              }));
            })
            .flat();
          const branchesEndpoints = hcBranches.map(
            ({ id, laundryName, name, laundryId, companyName }) => {
              return this.branchesService
                .getBranchContractInfo(headers, { hc_company_branch_id: id })
                .pipe(
                  map((c) => ({
                    data: c.data,
                    laundryName,
                    branchName: name || companyName[this.lang],
                    branchId: id,
                    laundryId,
                    date: c.data.log_laundry_branch_contracts[
                      c.data.log_laundry_branch_contracts.length - 1
                    ].created_at,
                    user: c.data.log_laundry_branch_contracts[
                      c.data.log_laundry_branch_contracts.length - 1
                    ].user_admin,
                  })),
                  catchError(() => {
                    return of({
                      data: null,
                      laundryName,
                      branchName: name || companyName[this.lang],
                      branchId: id,
                      laundryId,
                    });
                  }),
                );
            },
          );
          return forkJoin(branchesEndpoints);
        }),
      );
  }

  getAllCarwashCommissions(headers, groupId): Observable<ProviderCommission[]> {
    return this.carwashCompanyService.getCompanies({ groupId }).pipe(
      switchMap((res) => {
        const laundryBranches: any[] = res
          .map(({ name, id, cwGarageBranches }) => {
            return cwGarageBranches.map((lb) => ({
              ...lb,
              laundryName: name[this.lang],
              laundryId: id,
            }));
          })
          .flat();
        const branchesEndpoints = laundryBranches.map(
          ({ id, laundryName, name, laundryId, companyName }) => {
            return this.branchesService
              .getBranchContractInfo(headers, { cw_garage_branch_id: id })
              .pipe(
                map((c) => ({
                  data: c.data,
                  laundryName,
                  branchName: name || companyName[this.lang],
                  branchId: id,
                  laundryId,
                  date: c.data.log_laundry_branch_contracts[
                    c.data.log_laundry_branch_contracts.length - 1
                  ].created_at,
                  user: c.data.log_laundry_branch_contracts[
                    c.data.log_laundry_branch_contracts.length - 1
                  ].user_admin,
                })),
                catchError(() => {
                  return of({
                    data: null,
                    laundryName,
                    branchName: name || companyName[this.lang],
                    branchId: id,
                    laundryId,
                  });
                }),
              );
          },
        );
        return forkJoin(branchesEndpoints);
      }),
    );
  }
}
