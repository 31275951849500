// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
  /* Additional spinner styles */
  animation: spinner 400ms linear infinite;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  animation: spinner 1s linear infinite;
}

/* Animation styles */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/spinner/spinner.component.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,wCAAA;EACA,gCAAA;EACA,+BAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,sBAAA;EACA,qBAAA;EACA,sBAAA;EACA,qCAAA;AACJ;;AAEE,qBAAA;AACA;EACE;IAAK,uBAAA;EAEP;EADE;IAAO,yBAAA;EAIT;AACF","sourcesContent":[".spinner {  \n    /* Additional spinner styles */\n    animation: spinner 400ms linear infinite;\n    border-bottom-color: transparent;\n    border-right-color: transparent;\n    border-style: solid;\n    border-width: 2px;\n    border-radius: 50%;  \n    box-sizing: border-box;\n    display: inline-block;\n    vertical-align: middle;\n    animation: spinner 1s linear infinite;\n  }\n  \n  /* Animation styles */\n  @keyframes spinner {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
