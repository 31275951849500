export class Laundry {
  name: string;
}

export class LaundryBranch {
  name: string;
  laundry: Laundry;
}

export class CarDriverRelation {
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  car_detail_id: number;
  driver_id: number;
}

export class CarDetail {
  name: string;
  car_driver_relation: CarDriverRelation;
}

export class DriverList {
  id: number;
  first_name: string;
  last_name: string;
  mobile_no: string;
  score: number;
  capacity: number;
  pin_no: number;
  role: string;
  car_for: string;
  token: string;
  status: string;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  laundry_branch_id: number;
  area_id?: any;
  laundry_branch: LaundryBranch;
  area?: any;
  car_details: CarDetail[];
  driver_translations: any[];
  is_logistics?: boolean;
}

export class DriverTranslation {
  language_id: number;
  first_name: string;
  last_name: string;
}

export class DriverToBeSaved {
  mobile_no: string;
  laundry_branch_id: number;
  car_id: number;
  area_id: number;
  car_for: string;
  driver_translations: DriverTranslation[];
}

export class DriverToBeUpdated {
  car_id: number;
  driver_translations: DriverTranslation[];
}
