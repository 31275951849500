export class NumbersHelper {

  static padDigits(unPaddedNumber: any, digits: number) {
    return Array(Math.max(digits - String(unPaddedNumber).length + 1, 0)).join(String(0)) + unPaddedNumber;
  }

  static roundNumber(value: number, scale: number): number {
    const normalizer = Math.pow(10, scale);
    return Math.round(value * normalizer) / normalizer;
  }

}
