import { map, mergeMap } from 'rxjs/operators';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { environment } from '@env/environment';
import { forkJoin, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HcService, HcServiceArea } from '@app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class HomeCleaningServicesService {
  private readonly hcServicesUrl = `${environment.jcDeliveryServer}hc-services`;
  readonly URL_SELECTED_AREAS = `${environment.jcDeliveryServer}hc-service-areas`;
  constructor(private readonly http: HttpClient) {}

  getHcServices(
    params: Record<string, string | number>,
  ): Observable<Array<HcService>> {
    const toData = (res) => res.data;
    return this.http
      .get<ApiResponse<Array<HcService>>>(this.hcServicesUrl, {
        params,
      })
      .pipe(map(toData));
  }

  getHcServiceById(id: number): Observable<HcService> {
    const toData = (res) => res.data;
    return this.http
      .get<ApiResponse<HcService>>(`${this.hcServicesUrl}/${id}`)
      .pipe(map(toData));
  }

  updateHcService(id: number, body: any): Observable<HcService> {
    const toData = (res) => res.data;
    return this.http
      .patch<ApiResponse<HcService>>(`${this.hcServicesUrl}/${id}`, body)
      .pipe(map(toData));
  }

  getHcServicesAreas(
    params: Record<string, string | number>,
  ): Observable<Array<HcServiceArea>> {
    const toData = (res) => res.data;
    return this.http
      .get<ApiResponse<Array<HcService>>>(this.URL_SELECTED_AREAS, {
        params,
      })
      .pipe(map(toData));
  }

  getAllHcServicesAreas(
    baseParams: Record<string, string | number>
  ): Observable<HcServiceArea[]> {
    // Function to create an observable for a single page
    const getPage = (page: number): Observable<ApiResponse<HcServiceArea[]>> => {
      const params = { ...baseParams, page: page.toString() };
      return this.http.get<ApiResponse<HcServiceArea[]>>(this.URL_SELECTED_AREAS, { params });
    };

    // Get first page to determine total number of pages
    return getPage(1).pipe(
      mergeMap((firstPageResponse: ApiResponse<HcServiceArea[]>) => {
        const totalPages = firstPageResponse.pageInfo?.pageCount || 1;
        const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

        // Include the first page results
        const allData = [...firstPageResponse.data];

        // If only one page, return immediately
        if (totalPages <= 1) {
          return of(allData);
        }

        // Create observables for remaining pages
        const remainingPages = pageNumbers.slice(1).map(page => getPage(page));

        // Combine all page requests
        return forkJoin(remainingPages).pipe(
          map((responses: ApiResponse<HcServiceArea[]>[]) => {
            // Combine data from all pages
            responses.forEach(response => {
              allData.push(...response.data);
            });
            return allData;
          })
        );
      })
    );
  }

  assignAreasToService(payload: HcServiceArea) {
    return this.http.post(this.URL_SELECTED_AREAS, payload);
  }

  unAssignAreasFromService(uniqueId: number) {
    return this.http.delete(`${this.URL_SELECTED_AREAS}/${uniqueId}`);
  }
}
