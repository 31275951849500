import { CarwashTicketWashTime } from '@app/modules/carwash/modules/carwash-packages/shared/enums';

export const timesWash = [
  {
    label: '0.25 hours',
    value: CarwashTicketWashTime.quarter,
  },
  {
    label: '0.5 hours',
    value: CarwashTicketWashTime.half,
  },
  {
    label: '0.75 hours',
    value: CarwashTicketWashTime.halfAndQuarter,
  },
  {
    label: '1.0 hours',
    value: CarwashTicketWashTime.one,
  },
  {
    label: '1.25 hours',
    value: CarwashTicketWashTime.oneQuarter,
  },
  {
    label: '1.5 hours',
    value: CarwashTicketWashTime.oneHalf,
  },
  {
    label: '1.75 hours',
    value: CarwashTicketWashTime.oneHalfAndQuarter,
  },
  {
    label: '2.0 hours',
    value: CarwashTicketWashTime.two,
  },
  {
    label: '2.25 hours',
    value: CarwashTicketWashTime.twoQuarter,
  },
  {
    label: '2.5 hours',
    value: CarwashTicketWashTime.twoHalf,
  },
  {
    label: '2.75 hours',
    value: CarwashTicketWashTime.twoHalfAndQuarter,
  },
  {
    label: '3.0 hours',
    value: CarwashTicketWashTime.three,
  },
  {
    label: '3.25 hours',
    value: CarwashTicketWashTime.threeQuarter,
  },
  {
    label: '3.5 hours',
    value: CarwashTicketWashTime.threeHalf,
  },
  {
    label: '3.75 hours',
    value: CarwashTicketWashTime.threeHalfAndQuarter,
  },
  {
    label: '4.0 hours',
    value: CarwashTicketWashTime.four,
  },
  {
    label: '4.25 hours',
    value: CarwashTicketWashTime.fourQuarter,
  },
  {
    label: '4.5 hours',
    value: CarwashTicketWashTime.fourHalf,
  },
  {
    label: '4.75 hours',
    value: CarwashTicketWashTime.fourHalfAndQuarter,
  },
  {
    label: '5.0 hours',
    value: CarwashTicketWashTime.five,
  },
  {
    label: '5.25 hours',
    value: CarwashTicketWashTime.fiveQuarter,
  },
  {
    label: '5.5 hours',
    value: CarwashTicketWashTime.fiveHalf,
  },
  {
    label: '5.75 hours',
    value: CarwashTicketWashTime.fiveHalfAndQuarter,
  },
  {
    label: '6.0 hours',
    value: CarwashTicketWashTime.six,
  },
];
