import { GlobalService } from 'src/app/shared/service/global.service';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CashbackCampaignHttpService } from '@shared/service/cashback-campaign/cashback-campaign-http.service';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { CashbackCampaign } from '@shared/models';
import { PaginationHelper } from '@app/shared/helpers/pagination-helper';
import { PageInfo } from '@app/shared/models/global/response';

@Injectable({ providedIn: 'root' })
export class CashbackCampaignService {
  readonly pagination$: Subject<PageInfo> = new Subject<PageInfo>();

  constructor(
    private readonly cashbackCampaignHttpService: CashbackCampaignHttpService,
    private readonly globalService: GlobalService,
  ) {}

  getCashbackCampaigns(
    filters?: Record<string, string>,
  ): Observable<CashbackCampaign[]> {
    const toData = (res: ApiResponse<CashbackCampaign[]>) =>
      res.data.map((item) => this.mapCampaignItem(item));

    const toPagination = (res: ApiResponse<CashbackCampaign[]>) =>
      res.pageInfo &&
      this.pagination$.next(PaginationHelper.camelToSnakeCase(res.pageInfo));

    return this.cashbackCampaignHttpService
      .getCashbackCampaigns(filters)
      .pipe(tap(toPagination), map(toData));
  }

  getCashbackCampaign(id: number): Observable<CashbackCampaign> {
    const toData = (res: ApiResponse<CashbackCampaign>) =>
      this.mapCampaignItem(res.data);

    return this.cashbackCampaignHttpService
      .getCashbackCampaign(id)
      .pipe(map(toData));
  }

  createCashbackCampaign(
    data: Partial<CashbackCampaign>,
  ): Observable<CashbackCampaign> {
    const toCashbackCampaign = (res: ApiResponse<CashbackCampaign>) => res.data;

    data = this.mapCampaignItem(data as CashbackCampaign, false);

    return this.cashbackCampaignHttpService
      .createCashbackCampaign(data)
      .pipe(map(toCashbackCampaign));
  }

  updateCashbackCampaign(
    id: number,
    data: Partial<CashbackCampaign>,
  ): Observable<CashbackCampaign> {
    const toCashbackCampaign = (res: ApiResponse<CashbackCampaign>) => res.data;

    data = this.mapCampaignItem(data as CashbackCampaign, false);

    return this.cashbackCampaignHttpService
      .updateCashbackCampaign(id, data)
      .pipe(map(toCashbackCampaign));
  }

  private mapCampaignItem(
    cbCampaign: CashbackCampaign,
    isGet: boolean = true,
  ): CashbackCampaign {
    if (isGet) {
      return {
        ...cbCampaign,
        cashbackPercentage: cbCampaign.cashbackPercentage * 100,
        validityInMinutes: cbCampaign.validityInMinutes / 1440,
        startDate: this.globalService.utcToLocalDate(cbCampaign.startDate),
        endDate: this.globalService.utcToLocalDate(cbCampaign.endDate),
        cashbackSegments: cbCampaign.cashbackSegments?.map((segment) => ({
          ...segment,
          cashbackPercentage: segment.cashbackPercentage * 100,
        })),
      };
    }

    return {
      cashbackPercentage: cbCampaign.cashbackPercentage / 100,
      countryId: cbCampaign.countryId || this.globalService.countryId,
      maxCashbackAmount: cbCampaign.maxCashbackAmount,
      name: cbCampaign.name,
      status: cbCampaign.status,
      validityInMinutes: cbCampaign.validityInMinutes * 1440,
      userSegmentId: cbCampaign.userSegmentId,
      startDate: this.globalService.localToUtcDate(cbCampaign.startDate),
      endDate: this.globalService.localToUtcDate(
        `${cbCampaign.endDate?.split('T')[0]}T23:59:59`,
      ),
      cashbackReceivableGroups: cbCampaign.cashbackReceivableGroups,
      cashbackDeductibleGroups: cbCampaign.cashbackDeductibleGroups,
      cashbackSegments: cbCampaign.cashbackSegments?.map((segment) => ({
        ...segment,
        cashbackPercentage: segment.cashbackPercentage / 100,
      })),
    } as CashbackCampaign;
  }
}
