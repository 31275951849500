import { NgModule } from '@angular/core';
import { SharedModule } from '@app/modules/carwash/shared/shared.module';
import {
  CarWashPackagePricingListComponent
} from "@app/modules/carwash/modules/car-wash-package-pricing/components/car-wash-package-pricing-list/car-wash-package-pricing-list.component";
import {
  CarWashPackagePricingEditDialogComponent
} from "@app/modules/carwash/modules/car-wash-package-pricing/components/car-wash-package-pricing-edit-dialog/car-wash-package-pricing-edit-dialog.component";
import { CarWashPackagePricingRoutingModule } from "@app/modules/carwash/modules/car-wash-package-pricing/car-wash-package-pricing-routing.module";
import {
  CarWashPackagePricingFormComponent
} from "@app/modules/carwash/modules/car-wash-package-pricing/components/car-wash-package-pricing-edit-dialog/car-wash-package-pricing-form/car-wash-package-pricing-form.component";
@NgModule({
  imports: [CarWashPackagePricingRoutingModule, SharedModule],
  declarations: [
    CarWashPackagePricingListComponent,
    CarWashPackagePricingFormComponent,
    CarWashPackagePricingEditDialogComponent,
  ],
})
export class CarWashPackagesPricingModule {}
