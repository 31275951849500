import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {MDBModalRef} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-jc-credit-package-create',
  templateUrl: './jc-credit-package-create.component.html',
  styleUrls: ['./jc-credit-package-create.component.scss']
})
export class JcCreditPackageCreateComponent implements OnInit {
  public editableRow: { id?: string, packageName: string, packageValue: number, freeAmount: number, baseAmount: number, type: string };
  public saveButtonClicked: Subject<any> = new Subject();

  public form: UntypedFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl({value: null, disabled: true}),
    packageName: new UntypedFormControl('', Validators.required),
    packageValue: new UntypedFormControl({value: '', disabled: true}),
    freeAmount: new UntypedFormControl('', Validators.required),
    baseAmount: new UntypedFormControl('', Validators.required)
  });

  constructor(public modalRef: MDBModalRef) { }

  ngOnInit() {
    this.form.controls['packageName'].patchValue('');
    this.form.controls['baseAmount'].patchValue(0);
    this.form.controls['freeAmount'].patchValue(0);
    this.form.controls['packageValue'].patchValue(0);
  }

  editRow() {
    this.editableRow = this.form.getRawValue();
    this.editableRow.type = 'ishtirakat';
    this.saveButtonClicked.next(this.editableRow);
    this.modalRef.hide();
  }


  get packageName() { return this.form.get('packageName'); }
  get baseAmount() { return this.form.get('baseAmount'); }
  get freeAmount() { return this.form.get('freeAmount'); }
  get packageValue() { return this.form.get('packageValue'); }

  onUpdateAmount() {
    this.form.controls['packageValue'].setValue(this.baseAmount.value + this.freeAmount.value);
  }

}
