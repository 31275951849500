export class SupplierToBeSaved {
  supplier_translations: SupplierTranslation[];
  mobile: string;
  alternate_mobile: string;
  email: string;
  governorate_id: number;
  address: string;
  price_per_route: number;
  id?: number;
  commission: number;
  password: string;
  is_jc_deliver_orders: boolean;
  is_jc_tasks: boolean;
  jc_delivery_commission: number;
}

export interface SupplierTranslation {
  id?: number;
  name?: string;
  contact_name?: string;
  created_at?: Date;
  updated_at?: Date;
  language_id?: number;
  supplier_id?: number;
}

export interface SupplierFormList {
  id: number;
  name: string;
  contact_name: string;
  mobile: string;
  alternate_mobile: string;
  email: string;
  price_per_route: number;
  address: string;
  status: string;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  governorate_id: number;
  country_id: number;
  group_id: number;
  supplier_translations: SupplierTranslation[];
  total_partner: number;
  active_partner: number;
  pin_no: number;
  suppliers_area: SupplierAreas[];
  commission: number;
  balance: number;
  is_jc_deliver_orders: boolean;
  is_jc_tasks: boolean;
  jc_delivery_commission: number;
}

export class SupplierAreas {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  status: string;
  country_id: number;
  governorate_id: number;
  governorateId: number;
  is_selected: boolean;
}

export class SupplierArea {
  area_id: number;
  is_selected: boolean;
}

export class SupplierAreaToBeSaved {
  supplierAreas: SupplierArea[];
  supplier_id: number;
}
