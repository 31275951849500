import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ReferralCampaignsRoutingModule } from './referral-campaign-routing.module';
import { ReferralCampaignListComponent } from './components/referral-campaign-list/referral-campaign-list.component';
import { ReferralCampaignEditDialogComponent } from './components/referral-campaign-edit-dialog/referral-campaign-edit-dialog.component';
import { ReferralCampaignFormComponent } from './components/referral-campaign-edit-dialog/referral-campaign-form/referral-campaign-form.component';

@NgModule({
  declarations: [
    ReferralCampaignListComponent,
    ReferralCampaignEditDialogComponent,
    ReferralCampaignFormComponent,
  ],
  imports: [SharedModule, ReferralCampaignsRoutingModule],
})
export class ReferralCampaignsModule {}
