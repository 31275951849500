import { NgModule } from '@angular/core';

import { PartnersRoutingModule } from './partners-routing.module';
import { SharedModule } from '../shared/shared.module';
import { PartnerManagementComponent } from './components/partners-management/partner-management.component';
import { CreatePartnerComponent } from './components/partners-management/create-partner/create-partner.component';
import { PartnersListComponent } from './components/partners-management/partners-list/partners-list.component';
import { AssignAreaPartnerComponent } from './components/partners-management/assign-area-partner/assign-area-partner.component';

@NgModule({
  imports: [SharedModule.forRoot(), PartnersRoutingModule],
  declarations: [
    PartnerManagementComponent,
    PartnersListComponent,
    CreatePartnerComponent,
    AssignAreaPartnerComponent
  ]
})
export class PartnersModule {}
