export enum Department {
  Operations = 1,
  Sales,
  Marketing,
  Finance,
  Executive,
  Laundries,
  SysAdmin,
  CorporateClients,
  CarWashGarages,
  HomeCleaning,
}
