import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CarwashBranchTimeServiceHttpService } from '@app/modules/carwash/modules/carwash-branch/shared/services/carwash-branch-time-service-http/carwash-branch-time-service-http.service';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { TimeSlotsConfig } from '@app/modules/carwash/modules/carwash-branch/shared/models/time-slots-config.model';

@Injectable({ providedIn: 'root' })
export class CarwashBranchTimeServiceService {
  constructor(
    private readonly carwashBranchTimeServiceHttpService: CarwashBranchTimeServiceHttpService,
  ) {}

  getTimesConfig(): Observable<TimeSlotsConfig[]> {
    const toData = (res: ApiResponse<TimeSlotsConfig[]>) => res.data;

    return this.carwashBranchTimeServiceHttpService
      .getTimesConfig()
      .pipe(map(toData));
  }

  getAllTimesConfig(
    params?: Record<string, number>,
  ): Observable<TimeSlotsConfig[]> {
    return this.carwashBranchTimeServiceHttpService.getAllTimesConfig(params);
  }

  sortTimes(arr: TimeSlotsConfig[]): void {
    arr.sort((a, b) =>
      +a.time.replace(/:/g, '') > +b.time.replace(/:/g, '') ? 1 : -1,
    );
  }
}
