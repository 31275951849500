import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { FormStatus } from '@shared/enums/form-status.enum';
import { Crud } from '@shared/models';
import { CrudService } from '@app/shared/service/crud';

@Component({
  templateUrl: './crud-edit-dialog.component.html',
  styles: [
    `
      .crud-modal-container {
        max-width: inherit;
        width: 90vw;
      }
    `,
  ],
})
export class CrudEditDialogComponent implements OnInit {
  isEditMode: boolean;
  isDisableButton: boolean;
  formData: Partial<Crud>;
  isLoading: boolean;

  private readonly destroy$: Subject<void> = new Subject<void>() ;
  isPreview: boolean;
  private permissionsConfig: { [key: string]: string };

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: { selected: Crud; allCruds: Array<Crud> },
    private readonly dialogRef: DialogRef<CrudEditDialogComponent>,
    private readonly crudService: CrudService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.isEditMode = !!this.data.selected;
    if (this.data?.selected?.permissionsConfig) {
      this.permissionsConfig = this.data?.selected?.permissionsConfig;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(): void {
    this.dialogRef.close();
  }

  formChanged(crud: Partial<Crud>): void {
    this.formData = {
      ...crud,
    };
  }

  formStatusChanged(status: FormStatus): void {
    this.isDisableButton = status === FormStatus.Invalid;
  }

  preview(): void {
    this.crudService.selectedCrud = this.getFinalCrudObject() as Crud;
    this.isPreview = !this.isPreview;
  }

  createCrud(): void {
    this.formData.apiConfig.extraParameters =
      this.crudService.getMappedParameters(
        this.formData['extraParameters'] as any[],
      );

    const data: Partial<Crud> = this.getFinalCrudObject();
    const action$ = this.isEditMode
      ? this.crudService.updateCrud(this.data.selected.id, data)
      : this.crudService.createCrud(data);

    this.isLoading = true;
    action$
      .pipe(
        tap(() => {
          this.isLoading = false;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (crud: Crud) => {
          this.dialogRef.close(crud);
        },
        (error) => {
          this.isLoading = false;
          this.cdr.markForCheck();
        },
      );
  }

  private getFinalCrudObject(): Partial<Crud> {
    const data: Partial<Crud> = {
      ...this.formData,
      ...{
        extraParameters: undefined,
      },
      bottomGroupedFields: this.formData.bottomGroupedFields.map(
        (groupForm) => groupForm['value'],
      ),
      ...(this.permissionsConfig
        ? { permissionsConfig: this.permissionsConfig }
        : {}),
    };
    return data;
  }
}
