import { expand, reduce } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@env/environment';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { Area } from '@app/shared/models';

@Injectable({ providedIn: 'root' })
export class AreaHttpService {
  private readonly url = `${environment.jcDeliveryServer}areas`;

  constructor(private readonly http: HttpClient) {}

  getAreaByID(areaId: number): Observable<ApiResponse<Area>> {
    return this.http.get<ApiResponse<Area>>(`${this.url}/${areaId}`);
  }

  getAllAreas(params: Record<string, string | number>): Observable<Area[]> {
    return this.http.get<ApiResponse<Area[]>>(this.url, { params }).pipe(
      expand((res) =>
        !res.pageInfo.nextPage
          ? EMPTY
          : this.http.get<ApiResponse<Area[]>>(
              this.url,
              this.updateRequestFormat(params, res.pageInfo.nextPage),
            ),
      ),
      reduce((records, res) => records.concat(res.data), []),
    );
  }

  private updateRequestFormat(params: any, page: number) {
    params.page = page;
    return {
      params: new HttpParams({ fromObject: params }),
    };
  }
}
