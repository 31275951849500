import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { NgForm } from '@angular/forms';
import { GlobalService } from 'src/app/shared/service/global.service';
import { SelectOptions } from 'src/app/shared/models/global/response';
import { Subject } from 'rxjs';
import { DataExchangeWithBackend } from 'src/app/shared/models/user/generic-backend';
import { CountryService } from 'src/app/shared/service/country.service';
import { GovernorateList } from 'src/app/shared/models/countries/country';
import { SupplierToBeSaved } from 'src/app/shared/models/laundries/suppliers';
import { SupplierService } from 'src/app/shared/service/supplier.service';
@Component({
  selector: 'app-create-supplier',
  templateUrl: './create-supplier.component.html',
  styleUrls: ['./create-supplier.component.css']
})
export class CreateSupplierComponent implements OnInit, OnChanges {
  @Input() isShown: boolean;
  @Input() isUpdate: boolean;
  @Input() supplier: SupplierToBeSaved;
  @Input() refreshCar: boolean;
  @Output() isClosed: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('createSupplierModal', { static: true }) modal: ModalDirective;
  @ViewChild('createSupplier', { static: false }) form: NgForm;

  name: string;
  nameAr: string;
  contactPersonAr: string;
  contactPerson: string;
  mobile: string;
  cars: { value: any; label: any }[];
  selectedCar: number;
  loader = false;
  dataReady = false;
  dataSubject: Subject<DataExchangeWithBackend> = new Subject();
  carOptions: string;
  governorate: number;
  governorates: SelectOptions<number>[];
  supplierAddress: string;
  altMobileNumber: string;
  pricePerRoute: number;
  supplierCommission: number;
  jcDeliverCommission: number;
  isJustclean: boolean;
  isJcDeliver: boolean;
  password: string;
  email: string;
  constructor(
    private globalService: GlobalService,
    private supplierService: SupplierService,
    private toaster: ToastService,
    private countryService: CountryService
  ) {}

  ngOnInit() {
    this.initForm(false);
    this.getGovernorates();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isShown) {
      this.modal.show();
    }

    this.initForm(this.isUpdate);
  }

  onHidden() {
    this.isClosed.emit(true);
    this.initForm(false);
    this.modal.hide();
  }

  saveDriver() {
    this.loader = true;
    const supplier = this.supplier ? this.supplier : new SupplierToBeSaved();
    supplier.governorate_id = this.governorate;
    supplier.supplier_translations = [];
    supplier.mobile = this.mobile.toString();
    supplier.alternate_mobile = this.altMobileNumber;
    supplier.supplier_translations[0] = {
      language_id: 1,
      name: this.name,
      contact_name: this.contactPerson
    };
    supplier.supplier_translations[1] = {
      language_id: 2,
      name: this.nameAr,
      contact_name: this.contactPersonAr
    };
    supplier.price_per_route = this.pricePerRoute;
    supplier.commission = this.supplierCommission;
    supplier.address = this.supplierAddress;
    supplier.password = this.password;
    supplier.email = this.email;
    supplier.is_jc_deliver_orders = this.isJcDeliver;
    supplier.is_jc_tasks = this.isJustclean;
    supplier.jc_delivery_commission = this.isJcDeliver ? this.jcDeliverCommission : 0;
    this.supplierService
      .saveUpdate(this.globalService.headers, supplier)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.loader = false;
            this.onHidden();
            this.refreshData.emit(!this.isUpdate);
          } else {
            this.toaster.error(response.message);
          }
        },
        error => {
          this.loader = false;
        }
      );
  }

  initForm(isUpdate: boolean) {
    if (this.form) {
      this.form.reset();
      this.governorate = null;
      this.isJcDeliver = false;
      this.isJustclean = false;
    }
    if (this.isUpdate) {
      this.name = this.supplier.supplier_translations[0].name;
      this.nameAr = this.supplier.supplier_translations[1].name;
      this.contactPerson = this.supplier.supplier_translations[0].contact_name;
      this.contactPersonAr = this.supplier.supplier_translations[1].contact_name;
      this.mobile = this.supplier.mobile;
      this.altMobileNumber = this.supplier.alternate_mobile;
      this.governorate = this.supplier.governorate_id;
      this.supplierAddress = this.supplier.address;
      this.pricePerRoute = this.supplier.price_per_route;
      this.email = this.supplier.email;
      this.supplierCommission = this.supplier.commission;
      this.isJustclean = this.supplier.is_jc_tasks;
      this.isJcDeliver = this.supplier.is_jc_deliver_orders;
      this.jcDeliverCommission = this.supplier.is_jc_deliver_orders ? this.supplier.jc_delivery_commission : 0;
    }
  }

  getGovernorates() {
    this.countryService
      .getListCities(this.globalService.headers)
      .subscribe(response => {
        if (response.code === 200) {
          this.governorates = [];
          let gov = response.data as GovernorateList[];
          gov = gov.filter(e => e.country_id === this.globalService.countryId);
          gov.forEach(governorate => {
            this.governorates.push({
              value: governorate.id,
              label: governorate.name.en,
              selected: this.governorate === governorate.id ? true : false
            });
          });
        }
      });
  }
}
