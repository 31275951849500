import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { NewBackEndResponse } from 'src/app/shared/models/global/response';
import { OrderTrackingToBeSaved } from './jc-deliver.model';

@Injectable({
  providedIn: 'root',
})
export class JcDeliverService {
  readonly URL_ORDERS = environment.jcDeliveryServer + 'orders-delivery';
  readonly URL_DRIVER = environment.jcDeliveryServer + 'drivers';
  readonly URL_PARTNER = environment.jcDeliveryServer + 'partners';
  readonly URL_USERS = environment.jcDeliveryServer + 'users-delivery';
  readonly URL_ORDER_TRACKING =
    environment.jcDeliveryServer + 'orders-delivery-tracking';
  constructor(private http: HttpClient) {}

  getAllOrders(headers, params?: any) {
    return this.http.get<NewBackEndResponse>(this.URL_ORDERS, {
      headers: new HttpHeaders(headers),
      params: new HttpParams({ fromObject: params }),
    });
  }

  getAllUsers(headers, params?: any) {
    return this.http.get<NewBackEndResponse>(this.URL_USERS, {
      headers: new HttpHeaders(headers),
      params: new HttpParams({ fromObject: params }),
    });
  }

  getUserById(headers, userId: number) {
    return this.http.get<NewBackEndResponse>(this.URL_USERS + '/' + userId, {
      headers: new HttpHeaders(headers),
    });
  }

  getDrivers(headers, params?: any) {
    return this.http.get<NewBackEndResponse>(this.URL_DRIVER, {
      headers: new HttpHeaders(headers),
      params: new HttpParams({ fromObject: params }),
    });
  }

  changeStatus(headers, orderId: number, statusId: number) {
    return this.http.patch<NewBackEndResponse>(
      this.URL_ORDERS + '/' + orderId,
      {
        statusDeliveryId: statusId,
      },
      {
        headers: new HttpHeaders(headers),
      }
    );
  }

  getPartners(headers, params?: any) {
    return this.http.get<NewBackEndResponse>(this.URL_PARTNER, {
      headers: new HttpHeaders(headers),
      params: new HttpParams({ fromObject: params }),
    });
  }

  saveOrderTracking(headers, body: OrderTrackingToBeSaved) {
    return this.http.post<NewBackEndResponse>(this.URL_ORDER_TRACKING, body, {
      headers: new HttpHeaders(headers),
    });
  }
}
