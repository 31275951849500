import { Component, OnInit } from '@angular/core';
import {
  DiscountForBranch,
  DiscountOnCreate,
} from 'src/app/shared/models/laundries/branch';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/shared/service/global.service';
import { BranchesService } from 'src/app/shared/service/branches.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { JustCleanLaundryPercentage } from 'src/app/shared/models/global/response';

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.css'],
})
export class DiscountComponent implements OnInit {
  discount: DiscountForBranch = new DiscountForBranch();
  loader = false;
  discountTypes = [
    {
      value: 'amount',
      label: 'Amount',
      disabled: true,
    },
    {
      value: 'percentage',
      label: 'Percentage',
    },
  ];
  branchId: number;
  options = { toastClass: 'opacity' };
  laundryId: any;
  justCleanOrLaundry: JustCleanLaundryPercentage;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private branchService: BranchesService,
    private toast: ToastService,
  ) {}

  ngOnInit() {
    this.branchId = this.route.snapshot.params.branchId;
    this.laundryId = this.route.parent.snapshot.params.id;
    this.justCleanOrLaundry = {
      justclean: 0,
      laundry: 100,
    };
    this.getDiscount(this.branchId);
  }

  getCurrency() {
    return this.globalService.getCurrency();
  }

  changeStatus() {
    this.branchService
      .changeDiscountStatus(this.globalService.headers, {
        laundry_branch_id: this.branchId,
        has_discount: this.discount.has_discount ? false : true,
      })
      .subscribe((response) => {
        if (response.code === 200) {
          this.toast.success('Successfully Updated');
          this.getDiscount(this.branchId);
        } else {
          this.toast.error(response.message, this.options);
        }
      });
  }

  saveDiscount() {
    this.loader = true;
    const discountToBesaved = new DiscountOnCreate();
    discountToBesaved.discount_amount = this.discount.discount_amount;
    discountToBesaved.discount_percentage =
      this.discount.discount_percentage / 100;
    discountToBesaved.discount_type = this.discount.discount_type;
    discountToBesaved.laundry_branch_id = this.branchId;
    discountToBesaved.discount_by_justclean = this.justCleanOrLaundry.justclean;
    discountToBesaved.discount_by_laundry = this.justCleanOrLaundry.laundry;
    if (discountToBesaved.discount_type === 'amount') {
      delete discountToBesaved.discount_percentage;
    } else {
      delete discountToBesaved.discount_amount;
    }
    this.branchService
      .saveDiscount(this.globalService.headers, discountToBesaved)
      .subscribe((response) => {
        if (response.code === 200) {
          this.loader = false;
          this.toast.success('Successfully Updated');
          this.getDiscount(this.branchId);
        } else {
          this.toast.error(response.message, this.options);
        }
      });
  }

  goBack() {
    this.router.navigate([
      '/dashboard/' +
        window.localStorage.getItem('module') +
        '/branch-list/' +
        this.laundryId,
    ]);
  }

  private getDiscount(branchId: number) {
    this.branchService
      .getDiscountForBranch(this.globalService.headers, branchId)
      .subscribe((response) => {
        if (response.code === 200) {
          this.discount = response.data;
          this.discount.discount_percentage = parseInt(
            (this.discount.discount_percentage * 100).toFixed(0),
            10,
          );
        } else {
          this.toast.error(response.message, this.options);
        }
      });
  }
}
