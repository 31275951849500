import { gql } from 'graphql-request';

export const GET_ZONE_ORDER_COUNT_QUERY = (
  startDate: string,
  endDate: string,
  isScheduledToday: boolean,
) => gql`
  query {
    countryOrderCount(
      endDate: "${endDate}"
      startDate: "${startDate}"
      isScheduledToday: ${isScheduledToday}
    ) {
      zoneName
      countryId
      totalCount
    }
  }
`;
