import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { NgForm } from '@angular/forms';
import { LogDriverService } from 'src/app/shared/service/log-driver.service';
import { GlobalService } from 'src/app/shared/service/global.service';
import { LogisticsDriverToSave } from 'src/app/shared/models/cars/log-driver';
import { DataManager } from 'src/app/shared/helpers/data-manager';
import { BackEndResponse } from 'src/app/shared/models/global/response';
import { Subject } from 'rxjs';
import { DataExchangeWithBackend } from 'src/app/shared/models/user/generic-backend';

@Component({
  selector: 'app-create-logistics-driver',
  templateUrl: './create-logistics-driver.component.html',
  styleUrls: ['./create-logistics-driver.component.css']
})
export class CreateLogisticsDriverComponent implements OnInit, OnChanges {
  @Input() isShown: boolean;
  @Input() isUpdate: boolean;
  @Input() driver: LogisticsDriverToSave;
  @Input() refreshCar: boolean;
  @Output() isClosed: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('createDriverModal', { static: true }) modal: ModalDirective;
  @ViewChild('createDriver', { static: false }) form: NgForm;
  @Input() branchId: number;
  @Input() laundryId: number;

  name: string;
  firstName: string;
  nameAr: string;
  firstNameAr: string;
  mobile: string;
  cars: { value: any; label: any }[];
  selectedCar: number;
  loader = false;
  dataReady = false;
  dataSubject: Subject<DataExchangeWithBackend> = new Subject();
  CarOptionsList = [
    { value: 'pickup_and_delivery', label: 'Pick and Delivery' },
    { value: 'pickup', label: 'PickUp' },
    { value: 'delivery', label: 'Delivery' }
  ];
  carOptions: string;

  constructor(
    private globalService: GlobalService,
    private driverService: LogDriverService,
    private toaster: ToastService
  ) {}

  ngOnInit() {
    this.initForm(false);
    this.getCars();
    this.dataSubject.subscribe(data => {
      this.dataReady = data.isDataReady;
      this.cars = data.list;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isShown) {
      this.modal.show();
    }
    if (this.refreshCar) {
      this.getCars();
      this.refreshCar = false;
    }
    this.initForm(this.isUpdate);
  }

  getCars() {
    /*
    this.driverService
      .getCarsForLogistics(this.globalService.headers)
      .subscribe(
        response => {
          const cars = response.data as IdName[];
          this.cars = [];
          cars.forEach(car => {
            this.cars.push({ value: car.id, label: car.name });
          });
          this.dataReady = true;
        },
        error => {
          this.toaster.error(error.error.message);
        }
      );
    */
    DataManager.subscribeToAnObservable<BackEndResponse, any>(
      this.driverService.getCarsForLogistics(this.globalService.headers),
      this.cars,
      this.toaster,
      true,
      this.dataSubject,
      'id',
      'name'
    );
  }

  onHidden() {
    this.isClosed.emit(true);
    this.getCars();
    this.initForm(false);
  }

  saveDriver() {
    this.loader = true;
    const driver = this.driver ? this.driver : new LogisticsDriverToSave();
    driver.car_id = this.selectedCar;
    driver.car_for = this.carOptions;
    driver.driver_translations = [];
    driver.mobile_no = this.mobile.toString();
    driver.first_name = this.firstName;
    driver.last_name = this.name;
    driver.driver_translations[0] = {
      language_id: 1,
      last_name: this.name,
      first_name: this.firstName
    };
    driver.driver_translations[1] = {
      language_id: 2,
      last_name: this.nameAr,
      first_name: this.firstNameAr
    };

    this.driverService
      .saveLogisticsDriver(this.globalService.headers, driver)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.loader = false;
            this.modal.hide();
            this.onHidden();
            this.refreshData.emit(!this.isUpdate);
          } else {
            this.toaster.error(response.message);
          }
        },
        error => {
          this.loader = false;
        }
      );
  }

  initForm(isUpdate: boolean) {
    if (this.form) {
      this.form.reset();
    }
    if (this.isUpdate) {
      this.name = this.driver.driver_translations[0].last_name;
      this.nameAr = this.driver.driver_translations[1].last_name;
      this.firstNameAr = this.driver.driver_translations[1].first_name;
      this.firstName = this.driver.driver_translations[0].first_name;
      this.mobile = this.driver.mobile_no;
      this.selectedCar = this.driver.car_id;
      this.carOptions = this.driver.car_for;
    }
  }
}
