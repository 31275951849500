import { TimeRangeOpenHour } from '@app/modules/carwash/modules/carwash-branch/shared/components/carwash-time-range/carwash-time-range.component';
import { Days } from '@shared/enums';

export const timeRangeOpenHours: TimeRangeOpenHour[] = [
  {
    dayName: Days[Days.Monday],
    dayId: Days.Monday,
    closeTime: '',
    openTime: '',
    isOpen: false,
  },
  {
    dayName: Days[Days.Tuesday],
    dayId: Days.Tuesday,
    closeTime: '',
    openTime: '',
    isOpen: false,
  },
  {
    dayName: Days[Days.Wednesday],
    dayId: Days.Wednesday,
    closeTime: '',
    openTime: '',
    isOpen: false,
  },
  {
    dayName: Days[Days.Thursday],
    dayId: Days.Thursday,
    closeTime: '',
    openTime: '',
    isOpen: false,
  },
  {
    dayName: Days[Days.Friday],
    dayId: Days.Friday,
    closeTime: '',
    openTime: '',
    isOpen: false,
  },
  {
    dayName: Days[Days.Saturday],
    dayId: Days.Saturday,
    closeTime: '',
    openTime: '',
    isOpen: false,
  },
  {
    dayName: Days[Days.Sunday],
    dayId: Days.Sunday,
    closeTime: '',
    openTime: '',
    isOpen: false,
  },
];
