import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { RouterModule, ExtraOptions } from '@angular/router';
import { appRoutes } from './app.routing';
import { AuthService } from './shared/service/auth.service';
import { SharedModule } from './modules/shared/shared.module';
import { LoginComponent } from './shared/components/login/login.component';
import { PanelComponent } from './shared/components/panel/panel.component';
import { CanActivateViaAuthGuard } from './shared/service/can-activate-via-auth-guard.service';
import { MDBSpinningPreloader, ToastModule } from 'ng-uikit-pro-standard';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { CanActivateReviewsService } from './shared/service/can-activate-reviews.service';
import { AngularFireModule } from '@angular/fire/compat';

import { environment } from 'src/environments/environment';
import { MatTableModule as MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule as MatInputModule } from '@angular/material/input';
import { MatPaginatorModule as MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { Interceptor } from './shared/interceptors/interceptor';
import { GOOGLE_MAPS_API_CONFIG, NgMapsGoogleModule } from "@ng-maps/google";
import { NgMapsDirectionModule } from "@ng-maps/direction";
import { NgMapsCoreModule } from "@ng-maps/core";

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PanelComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    SharedModule.forRoot(),
    RouterModule.forRoot(appRoutes, routingConfiguration),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgMapsCoreModule,
    NgMapsGoogleModule.forRoot({
      apiKey: 'AIzaSyDRAXkxaZkXD7N4QR1ftrT4UkEUkmOAWDU',
      libraries: ['geometry']
    }),
    NgMapsDirectionModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    ToastModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [
    MDBSpinningPreloader,
    AuthService,
    CanActivateViaAuthGuard,
    CanActivateReviewsService,
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    {
      provide: GOOGLE_MAPS_API_CONFIG,
      useValue: {
        apiKey: 'AIzaSyDRAXkxaZkXD7N4QR1ftrT4UkEUkmOAWDU'
      }
    }
  ],
  exports: [TranslateModule, BrowserModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
