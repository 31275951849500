// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gold {
    background: #D7B740;
}
.gold-color {
    color: #D7B740;
}

.text-center {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/laundries/components/laundry-pos/laundry-pos.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,cAAc;AAClB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".gold {\n    background: #D7B740;\n}\n.gold-color {\n    color: #D7B740;\n}\n\n.text-center {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
