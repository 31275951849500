import {AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {JcCreditPackagesService} from '../../../../shared/service/jc-credit-packages.service';
import {GlobalService} from '../../../../shared/service/global.service';
import {MDBModalRef, MDBModalService, MdbTableDirective, MdbTablePaginationComponent} from 'ng-uikit-pro-standard';
import {JcCreditPackage} from '../../../../shared/models/jc-credit-package.model';
import {JcCreditPackageCreateComponent} from '../jc-credit-package-create/jc-credit-package-create.component';

@Component({
  selector: 'app-jc-credit-pages-list',
  templateUrl: './jc-credit-packages-list.component.html',
  styleUrls: ['./jc-credit-packages-list.component.scss']
})
export class JcCreditPackagesListComponent implements OnInit, AfterViewInit {

  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  jcCreditPackages: any = [];
  previous: any = [];

  displayedColumns = ['id', 'Name', 'Value', 'Base Amount', 'Free Amount', 'Status'];

  searchText = '';

  pageNo = 1;
  showCreateModal = false;
  isUpdate: boolean;
  jcCreditPackage: JcCreditPackage;

  modalRef: MDBModalRef;

  constructor(
    private globalService: GlobalService,
    private jcCreditPackagesService: JcCreditPackagesService,
    private modalService: MDBModalService,
    private cdRef: ChangeDetectorRef
    ) {}

  @HostListener('input') oninput() {
    this.mdbTablePagination.searchText = this.searchText;
  }

  ngOnInit() {
    this.loadJcCreditPackagesPage();
    this.mdbTable.setDataSource(this.jcCreditPackages);
    this.jcCreditPackages = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();
  }

  ngAfterViewInit() {
    this.mdbTablePagination.firstItemIndex = 1;
    this.mdbTablePagination.calculateLastItemIndex();
    this.mdbTablePagination.lastItemIndex += 1;
    this.cdRef.detectChanges();
  }

  loadJcCreditPackagesPage() {
    this.jcCreditPackagesService.list(
      this.globalService.headers,
      this.globalService.countryId,
      this.pageNo,
      this.searchText
    ).subscribe(result => {
      this.jcCreditPackages = result.data;
      this.mdbTablePagination.setMaxVisibleItemsNumberTo(result.pageInfo.itemCount);
      this.cdRef.detectChanges();
    });
  }


  createJcCreditPackage(jcCreditPackage: JcCreditPackage) {
    this.jcCreditPackagesService.create(
      this.globalService.headers,
      {...jcCreditPackage, countryId: this.globalService.countryId}
    ).subscribe(result => {
      const newJcCreditPackage = result.data;
      this.jcCreditPackages.push(newJcCreditPackage);
      this.cdRef.detectChanges();
    });
  }

  updateJcCreditPackage(id, status) {
    this.jcCreditPackagesService.update(
      this.globalService.headers,
      id,
      status
    ).subscribe(result => {
      const updatedJcCreditPackage = result.data;
      const index = this.jcCreditPackages.findIndex(jcCreditPackage => jcCreditPackage.id === updatedJcCreditPackage.id);
      this.jcCreditPackages[index] = updatedJcCreditPackage;
      this.cdRef.detectChanges();
    });
  }

  refreshData(event) {
    if (event) {
      this.loadJcCreditPackagesPage();
    }
  }

  isClosed(event) {
    this.showCreateModal = !event;
    this.isUpdate = false;
    this.jcCreditPackage  = null;
  }


  createNewPackageModal() {
    const modalOptions = {
      ignoreBackdropClick: false,
      animated: true,
      data: {
        editableRow: null
      }
    };
    this.modalRef = this.modalService.show(JcCreditPackageCreateComponent, modalOptions);
    this.modalRef.content.saveButtonClicked.subscribe((jcCreditPackage: any) => {
      this.createJcCreditPackage(jcCreditPackage);
    });
    this.mdbTable.setDataSource(this.jcCreditPackages);
  }
}
