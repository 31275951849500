import { takeUntil, finalize } from 'rxjs/operators';
import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import {
  OnlineProfileDescription,
  OnlineProfileImage,
  OnlineProfileStatus,
} from '@app/shared/models';
import { OnlineProfileService } from '@app/shared/service/carwash-branch';
import { ProfileStatus } from '@app/shared/enums';

@Component({
  selector: 'app-online-profile',
  templateUrl: './online-profile.component.html',
  styleUrls: ['./online-profile.component.scss'],
})
export class OnlineProfileComponent implements OnInit {
  isLoadingDescriptions: boolean;
  onlineProfileStatuses: OnlineProfileStatus[];
  getStatusNameBounded: Function = this.getStatusName.bind(this);
  getClassNameBounded: Function = this.getClassName.bind(this);
  images$: Observable<OnlineProfileImage[]>;
  descriptions: OnlineProfileDescription;
  PROFILE_STATUS = ProfileStatus;
  clickedImagePath: string;
  changingEnDescription: boolean;
  changingArDescription: boolean;
  gallerySettings = {
    counter: false,
    download: false,
  };
  imagesToUpdate: {
    [id: number]: boolean;
  } = {};
  descriptionsToUpdate: {
    [id: number]: boolean;
  } = {};

  private readonly destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    @Inject(DIALOG_DATA) public readonly branchId: number | any,
    private readonly dialogRef: DialogRef<OnlineProfileComponent>,
    private readonly cd: ChangeDetectorRef,
    private readonly hcBranchOnlineProfileService: OnlineProfileService,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  changeImageStatus(
    image: OnlineProfileImage,
    newStatus: ProfileStatus, // 'approved' | 'rejected'
    isActive: boolean,
  ): void {
    this.imagesToUpdate[image.id] = true;
    if (!isActive) {
      image.profileStatusId = ProfileStatus.inReviewImage;
    } else {
      image.profileStatusId = newStatus;
    }

    this.hcBranchOnlineProfileService
      .updateOnlineProfileImage(image.id, {
        profileStatusId: image.profileStatusId,
        cwGarageBranchId: this.branchId,
      })
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.imagesToUpdate[image.id] = undefined;
          this.cd.markForCheck();
        }),
      )
      .subscribe();
  }

  changeDescriptionStatus(
    description: OnlineProfileDescription,
    newStatus: ProfileStatus, // 'approved' | 'rejected'
    isActive: boolean,
  ): void {
    this.descriptionsToUpdate[description.id] = true;
    if (!isActive) {
      description.profileStatusId = ProfileStatus.inReviewDescription;
    } else {
      description.profileStatusId = newStatus;
    }
    this.hcBranchOnlineProfileService
      .updateOnlineProfileDescription(description.id, {
        cwGarageBranchId: this.branchId,
        profileStatusId: description.profileStatusId,
      })
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.descriptionsToUpdate[description.id] = undefined;
          this.cd.markForCheck();
        }),
      )
      .subscribe();
  }

  close(): void {
    this.dialogRef.close();
  }

  private loadData(): void {
    this.hcBranchOnlineProfileService
      .getOnlineProfileStatuses()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (statuses: OnlineProfileStatus[]) => {
          this.onlineProfileStatuses = statuses;
          this.loadImages();
          this.loadDescriptions();
          this.cd.markForCheck();
        },
        (error) => {
          this.dialogRef.close();
        },
      );
  }

  private loadImages(): void {
    this.images$ = this.hcBranchOnlineProfileService.getOnlineProfileImages(
      this.branchId,
    );
  }

  private loadDescriptions(): void {
    this.isLoadingDescriptions = true;
    this.hcBranchOnlineProfileService
      .getOnlineProfileDescriptions(this.branchId)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.isLoadingDescriptions = false)),
      )
      .subscribe((descriptions: OnlineProfileDescription) => {
        this.descriptions = descriptions;
        this.cd.markForCheck();
      });
  }

  /**
   * Method for returning CSS class name based on statud id
   */
  private getClassName(statusId: number): string {
    const statusName = this.getStatusName(statusId).toLowerCase();
    if (statusName.includes('approved')) {
      return 'approved';
    } else if (statusName.includes('rejected')) {
      return 'rejected';
    } else {
      return 'in-review';
    }
  }

  private getStatusName(statusId: number): string {
    return (
      this.onlineProfileStatuses?.find((status) => status.id === statusId)?.name
        ?.en || '-'
    );
  }

  private unsubscribe(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
