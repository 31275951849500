import { NewPageInfo } from './../../../../shared/models/global/response';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { CustomerNameSpace } from 'src/app/shared/models/customer/customer';
import { CustomerService } from 'src/app/shared/service/customer.service';
import { GlobalService } from 'src/app/shared/service/global.service';
@Component({
  selector: 'app-customer-schedules',
  templateUrl: './customer-schedules.component.html',
  styleUrls: ['./customer-schedules.component.css'],
})
export class CustomerSchedulesComponent implements OnInit {
  scheduleList: CustomerNameSpace.CustomerSchedule[];
  scheduleParams: CustomerNameSpace.ScheduleParams;
  customerId: number;
  pagination: NewPageInfo;
  scheduleTobeDeleted: CustomerNameSpace.CustomerSchedule;
  loader = false;
  openDelete: boolean;
  constructor(
    private route: ActivatedRoute,
    public globalService: GlobalService,
    protected customerService: CustomerService,
    protected toast: ToastService
  ) {}

  ngOnInit() {
    this.customerId = this.route.snapshot.params.id;
    this.scheduleParams = {
      userId: this.customerId
    };
    this.getSchedules();
  }

  getSchedules() {
    this.customerService.getCustomerSchedules(this.globalService.headers, this.scheduleParams)
    .subscribe(
      response => {
        if (response.code === 200) {
          this.scheduleList = response.data;
          this.pagination = response.pageInfo;
        } else {
          this.toast.error(response.message);
        }
      }
    );
  }

  getPage(page: number) {
    this.scheduleParams.page = page;
    this.getSchedules();
  }

  initiateDelete(schedule: CustomerNameSpace.CustomerSchedule) {
    this.openDelete = true;
    this.scheduleTobeDeleted = schedule;
  }

  deleteSchedule(event: boolean) {
    this.loader = true;
    this.customerService.deleteCustomerSchedules(this.globalService.headers, this.scheduleTobeDeleted.id)
    .subscribe(
      response => {
        if (response.code === 200) {
          this.openDelete = false;
          this.getSchedules();
        } else {
          this.toast.error(response.message);
        }
        this.loader = false;
      },
      error => {
        this.loader = false;
      }
    );
  }

  toggleStatus(schedule: CustomerNameSpace.CustomerSchedule) {
    this.customerService.updateCustomerSchedules(this.globalService.headers, schedule.id, {
      status: schedule.status === 'active' ? 'inactive' : 'active'
    })
    .subscribe(
      response => {
        if (response.code === 200) {
          this.getSchedules();
        } else {
          this.toast.error(response.message);
        }
      }
    );
  }
}
