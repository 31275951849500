import { Status } from '@shared/enums/status.enum';
import { CompanyType } from '@shared/enums/company-type.enum';
import { Nullable } from '@shared/models/nullable.model';

interface Languages {
  en: string;
  ar: string;
}

export interface CarwashCompany {
  id: number;
  code: string;
  name: Languages;
  operatingName: Languages;
  companyLogoUrl: Languages;
  operatingLogoUrl: Languages;
  description: Languages;
  address: string;
  contactName: string;
  contactEmail: string;
  contactMobile: string;
  countryCode: Nullable<string>;
  managerName: string;
  managerMobile: string;
  managerEmail: string;
  financeName: string;
  financeEmail: string;
  financeMobile: string;
  ratingValue: number;
  ratingCount: number;
  status: Status;
  sortOrder: number;
  trnName: Nullable<string>;
  trnNumber: Nullable<string>;
  nameActual: Nullable<string>;
  terminate: boolean;
  type: CompanyType.CarwashGarage;
  createdAt: Date;
  updatedAt: Date;
  countryId: number;
  groupId: number;
  areaId: Nullable<number>;
  cwGarageBranches: any[];
  deletedAt: Nullable<Date>;
  operators: any[];
  latitude: number;
  longitude: number;
  lowestPrice: number;
  highestPrice: number;
}
