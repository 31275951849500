import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { FormStatus } from '@shared/enums/form-status.enum';
import { CashbackCampaign } from '@shared/models';
import { CashbackCampaignService } from '@shared/service/cashback-campaign';

@Component({
  templateUrl: './cashback-campaign-edit-dialog.component.html',
})
export class CashbackCampaignEditDialogComponent implements OnInit {
  isEditMode: boolean;
  isDisableButton: boolean;
  formData: Partial<CashbackCampaign>;
  isLoading: boolean;
  isFormValid: boolean;

  private readonly destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    @Inject(DIALOG_DATA) public readonly data: CashbackCampaign | any,
    private readonly dialogRef: DialogRef<CashbackCampaignEditDialogComponent>,
    private readonly cashbackCampaignService: CashbackCampaignService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.isEditMode = !!this.data;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(): void {
    this.dialogRef.close();
  }

  formChanged(cashbackCampaign: Partial<CashbackCampaign>): void {
    this.formData = {
      ...cashbackCampaign,
    };
  }

  formStatusChanged(status: FormStatus): void {
    this.isFormValid = status === FormStatus.Valid;
    this.changeSaveButtonStatus();
  }

  createCashbackCampaign(): void {
    const data: Partial<CashbackCampaign> = {
      ...this.formData,
      cashbackSegments: this.formData.cashbackSegments.map(
        (segment: any) => segment.value,
      ),
    };

    this.isLoading = true;
    this.cashbackCampaignService
      .createCashbackCampaign(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (cashbackCampaign: CashbackCampaign) => {
          this.dialogRef.close(cashbackCampaign);
        },
        (error) => {
          this.isLoading = false;
          this.cdr.markForCheck();
        },
      );
  }

  private changeSaveButtonStatus(): void {
    this.isDisableButton = !this.isFormValid;
  }
}
