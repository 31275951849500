import { Component, OnInit } from '@angular/core';
import { NewPageInfo } from 'src/app/shared/models/global/response';
import { UserDelivery, OrderListParams, JCDeliverStatuses, SORT_VALUE } from '../../jc-deliver.model';
import { GlobalService } from 'src/app/shared/service/global.service';
import { JcDeliverService } from '../../jc-deliver.service';
import { ActivatedRoute } from '@angular/router';
import { ApiLibrary } from 'src/app/shared/helpers/http-params';

@Component({
  selector: 'app-jc-del-user-profile',
  templateUrl: './jc-del-user-profile.component.html',
  styleUrls: ['./jc-del-user-profile.component.css']
})
export class JcDelUserProfileComponent implements OnInit {
  pagination: NewPageInfo;
  user: UserDelivery;
  userIdFromRoute: number;
  orders: any;
  orderFilters: OrderListParams;
  statuses = JCDeliverStatuses;
  constructor(
    private global: GlobalService,
    private jsCelService: JcDeliverService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.userIdFromRoute = parseInt(this.route.snapshot.params.id, 10);
    this.orderFilters = {
      page: 1,
      userDeliveryId: this.userIdFromRoute,
      sortBy: 'id',
      sortValue: SORT_VALUE.down
    };
    this.getUser(this.userIdFromRoute);
    this.getOrders(this.orderFilters);
  }

 getPage(event) {
  this.orderFilters.page = event;
  this.getOrders(this.orderFilters);
 }

 private getUser(userId: number) {
  this.jsCelService
  .getUserById(
    this.global.headers,
    userId
  )
  .subscribe(
    (response) => {
      if (response.code === 200) {
        this.user = response.data;
      }
    },
  );
 }

 private getOrders(params?: any) {
  this.jsCelService
    .getAllOrders(
      this.global.headers,
      new ApiLibrary().cleanObjectFromComponent(params)
    )
    .subscribe(
      (response) => {
        if (response.code === 200) {
          this.orders = response.data;
          this.pagination = response.pageInfo;
        }
      },
    );
}

}
