import { Component, OnDestroy, OnInit } from '@angular/core';

import { PageInfo } from 'src/app/shared/models/global/response';
import { GlobalService } from 'src/app/shared/service/global.service';
import {
  LogisticCarsList,
  LogisticsCar
} from 'src/app/shared/models/cars/log-cars';
import { LogCarService } from 'src/app/shared/service/log-car.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-log-cars-list',
  templateUrl: './log-car-list.component.html',
  styleUrls: ['./log-car-list.component.css']
})
export class LogCarsListComponent implements OnInit, OnDestroy {
  cars: LogisticCarsList[];
  search: string = '';
  isShown: boolean;
  carsData: any[];
  pagination: PageInfo;
  toggleDeleteModal: boolean;
  loader: boolean;
  carLoaded: boolean;
  carToBeUpdated: LogisticsCar;
  isUpdate = false;
  carId: number;
  carToBeDeleted: LogisticCarsList;
  private searchSubject = new Subject<string>();
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private globalService: GlobalService,
    private carsService: LogCarService,
    private toaster: ToastService
  ) {}


  ngOnInit() {
    this.isShown = false;
    this.carLoaded = false;
    this.setupSearch();
    this.getCars({ page: 1 });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setupSearch() {
    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(() => {
      this.getCars({ page: 1, search: this.search });
    });
  }


  onSearchChange() {
    this.searchSubject.next(this.search);
  }

  getCars(params?: any) {
    this.carLoaded = false;
    this.carsService
      .getLogisticsCars(this.globalService.headers, params)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.cars = response.data;
            this.pagination = response.page_info;
            this.carLoaded = true;
          } else {
            this.toaster.error(response.message);
          }
        },
        error => {
          this.loader = false;
          this.carLoaded = true;
        }
      );
  }

  getPage(page) {
    this.getCars({ page: page, search: this.search });
  }

  isClosed($event) {
    this.isShown = false;
    this.isUpdate = false;
  }

  refreshData(event) {
    this.getPage(
      event
        ? this.pagination.page_count <= 0
          ? 1
          : this.pagination.page_count
        : this.pagination.current_page <= 0
        ? 1
        : this.pagination.current_page
    );
  }

  openUpdate(car: LogisticCarsList) {
    this.carToBeUpdated = LogisticsCar.getInstance();
    this.carToBeUpdated.name = car.name;
    this.carToBeUpdated.id = car.id;
    this.carToBeUpdated.device_id = car.device_id;
    this.carToBeUpdated.plate_no = car.plate_no;
    this.isShown = true;
    this.isUpdate = true;
  }

  getLaunderies(car: LogisticCarsList) {
    let html = '';
    car.laundries.forEach((e, i) => {
      html +=
        '<span class="green-blue-color">' + (i + 1) + '- ' + e + '</span><br>';
    });
    return car.laundries.length > 0
      ? html
      : '<span class="error-text"> Not assigned to any Laundry yet</span>';
  }

  toggleStatus(car: LogisticCarsList) {
    this.carsService
      .toggleStatusAndDelete(this.globalService.headers, car.id, {
        is_active: !car.is_active
      })
      .subscribe(
        response => {
          if (response.code === 200) {
            this.getCars({ page: this.pagination.current_page });
          } else {
            this.toaster.error(response.message);
          }
        }
      );
  }

  triggerDelete(car: LogisticCarsList) {
    this.carToBeDeleted = car;
    this.toggleDeleteModal = true;
  }

  deleteCar(event) {
    this.loader = true;
    this.carsService
      .toggleStatusAndDelete(
        this.globalService.headers,
        this.carToBeDeleted.id,
        {}
      )
      .subscribe(
        response => {
          if (response.code === 200) {
            this.getCars({ page: this.pagination.current_page });
            this.loader = false;
            this.toggleDeleteModal = false;
          } else {
            this.toaster.error(response.message);
          }
        }
      );
  }

  isDeleteClosed(event) {
    this.toggleDeleteModal = false;
  }
}
