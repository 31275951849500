import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { ReferralCampaign } from '@shared/models';

@Injectable({ providedIn: 'root' })
export class ReferralCampaignHttpService {
  private readonly url = `${environment.jcDeliveryServer}referral-campaigns`;

  constructor(private readonly http: HttpClient) {}

  getReferralCampaigns(
    params?: Record<string, string>,
  ): Observable<ApiResponse<ReferralCampaign[]>> {
    return this.http.get<ApiResponse<ReferralCampaign[]>>(this.url, { params });
  }

  getReferralCampaign(id: number): Observable<ApiResponse<ReferralCampaign>> {
    return this.http.get<ApiResponse<ReferralCampaign>>(`${this.url}/${id}`);
  }

  createReferralCampaign(
    data: Partial<ReferralCampaign>,
  ): Observable<ApiResponse<ReferralCampaign>> {
    return this.http.post<ApiResponse<ReferralCampaign>>(this.url, data);
  }

  updateReferralCampaign(
    id: number,
    data: Partial<ReferralCampaign>,
  ): Observable<ApiResponse<ReferralCampaign>> {
    return this.http.patch<ApiResponse<ReferralCampaign>>(
      `${this.url}/${id}`,
      data,
    );
  }
}
