import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { PendingOrders, STATUSES } from 'src/app/shared/models/orders/order';
import { GlobalService } from 'src/app/shared/service/global.service';
import { OrderService } from 'src/app/shared/service/order.service';
import { zip } from 'rxjs';
import { FirebaseDriverTrackingService } from 'src/app/shared/service/firebase-driver-tracking.service';
import { DriversTrackingFromFirebase } from 'src/app/shared/models/orders/order-map';
import {} from 'googlemaps';

export const markerIcons = {
  partner: 'assets/img/icons/p.svg',
  driver: 'assets/img/icons/l.svg',
  laundry: 'assets/img/icons/laundry.svg',
  client: 'assets/img/icons/c.svg',
};

export interface AdminMaker {
  latitude: number;
  longitude: number;
  iconUrl: string;
  draggable: boolean;
  type: string;
  visible: boolean;
  driver?: DriversTrackingFromFirebase;
  order?: PendingOrders;
}
@Component({
  selector: 'app-orders-map',
  templateUrl: './orders-map.component.html',
  styleUrls: ['./orders-map.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersMapComponent implements OnInit {
  pendingOrders: PendingOrders[];
  filteredOrders: PendingOrders[];
  filter = '';
  selectedOrder: PendingOrders;
  drivers: DriversTrackingFromFirebase[];
  markers: AdminMaker[];
  zoom = 12;
  showMap = false;
  bounds: google.maps.LatLngBounds;
  initMarker: {
    lat: number;
    lng: number;
  };
  staticMarkers: {
    lat: number;
    lng: number;
  };
  origin: {
    lat: number;
    lng: number;
  };
  destination: {
    lat: number;
    lng: number;
  };
  loaderOrder = false;
  loaderOrdersFromAPI = false;
  loaderRoute = false;
  markerRouteOptions = [];
  constructor(
    protected globalService: GlobalService,
    protected orderService: OrderService,
    protected firebaseDrivers: FirebaseDriverTrackingService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getPendingOrders();
    setInterval(() => {
      this.cd.detectChanges();
      console.log('detected');
    }, 60000);
  }

  getPendingOrders() {
    this.loaderOrdersFromAPI = true;
    zip(
      this.orderService.getAllOrrders(this.globalService.headers, {
        page: 0,
        pickup_at: this.globalService.formatDate(
          new Date().toString(),
          'YYYY-MM-DD'
        ),
        is_address: true,
        order_status: 1,
      }),
      this.orderService.getAllOrrders(this.globalService.headers, {
        page: 0,
        pickup_at: this.globalService.formatDate(
          new Date().toString(),
          'YYYY-MM-DD'
        ),
        is_address: true,
        order_status: 2,
      }),
      this.orderService.getAllOrrders(this.globalService.headers, {
        page: 0,
        pickup_at: this.globalService.formatDate(
          new Date().toString(),
          'YYYY-MM-DD'
        ),
        is_address: true,
        order_status: 5,
      }),
      this.orderService.getAllOrrders(this.globalService.headers, {
        page: 0,
        pickup_at: this.globalService.formatDate(
          new Date().toString(),
          'YYYY-MM-DD'
        ),
        is_address: true,
        order_status: 13,
      }),
      this.orderService.getAllOrrders(this.globalService.headers, {
        page: 0,
        pickup_at: this.globalService.formatDate(
          new Date().toString(),
          'YYYY-MM-DD'
        ),
        is_address: true,
        order_status: 15,
      }),
      this.orderService.getAllOrrders(this.globalService.headers, {
        page: 0,
        delivery_at: this.globalService.formatDate(
          new Date().toString(),
          'YYYY-MM-DD'
        ),
        is_address: true,
        order_status: 1,
      }),
      this.orderService.getAllOrrders(this.globalService.headers, {
        page: 0,
        delivery_at: this.globalService.formatDate(
          new Date().toString(),
          'YYYY-MM-DD'
        ),
        is_address: true,
        order_status: 2,
      }),
      this.orderService.getAllOrrders(this.globalService.headers, {
        page: 0,
        delivery_at: this.globalService.formatDate(
          new Date().toString(),
          'YYYY-MM-DD'
        ),
        is_address: true,
        order_status: 5,
      }),
      this.orderService.getAllOrrders(this.globalService.headers, {
        page: 0,
        delivery_at: this.globalService.formatDate(
          new Date().toString(),
          'YYYY-MM-DD'
        ),
        is_address: true,
        order_status: 13,
      }),
      this.orderService.getAllOrrders(this.globalService.headers, {
        page: 0,
        delivery_at: this.globalService.formatDate(
          new Date().toString(),
          'YYYY-MM-DD'
        ),
        is_address: true,
        order_status: 15,
      })
    ).subscribe(
      (response) => {
        if (
          response[0].code === 200 &&
          response[1].code === 200 &&
          response[2].code === 200 &&
          response[3].code === 200 &&
          response[4].code === 200 &&
          response[5].code === 200 &&
          response[6].code === 200 &&
          response[7].code === 200 &&
          response[8].code === 200 &&
          response[9].code === 200
        ) {
          this.pendingOrders = response[0].data
            .concat(response[1].data)
            .concat(response[2].data)
            .concat(response[3].data)
            .concat(response[4].data)
            .concat(response[5].data)
            .concat(response[6].data)
            .concat(response[7].data)
            .concat(response[8].data)
            .concat(response[9].data);
          this.pendingOrders = this.sortOrderByTimeSlot();
          this.filteredOrders = this.pendingOrders;
          this.initMarker = {
            lat: this.globalService.countries.find(
              (e) => e.countryId === this.globalService.countryId
            ).latitude,
            lng: this.globalService.countries.find(
              (e) => e.countryId === this.globalService.countryId
            ).longitude,
          };
          this.staticMarkers = {
            lat: this.globalService.countries.find(
              (e) => e.countryId === this.globalService.countryId
            ).latitude,
            lng: this.globalService.countries.find(
              (e) => e.countryId === this.globalService.countryId
            ).longitude,
          };
          this.firebaseDrivers
            .getDrivers()
            .snapshotChanges()
            .subscribe(
              (fbDrivers) => {
                if (this.drivers && this.drivers.length > 0) {
                  fbDrivers.forEach((e) => {
                    if (e.type === 'modified') {
                      this.drivers[e.payload.oldIndex] = e.payload.doc.data();
                      this.removeDriverMarker(e.payload.doc.data());
                      this.addDriverMarker(e.payload.doc.data());
                    }
                    if (e.type === 'added') {
                      this.drivers[e.payload.newIndex] = e.payload.doc.data();
                      this.addDriverMarker(e.payload.doc.data());
                    }
                    if (e.type === 'removed') {
                      this.drivers.splice(e.payload.oldIndex, 1);
                      this.removeDriverMarker(e.payload.doc.data());
                    }
                  });
                  this.cd.detectChanges();
                } else {
                  this.drivers = [];
                  fbDrivers.forEach((e) => {
                    this.drivers[e.payload.newIndex] = e.payload.doc.data();
                  });
                  this.markers = [
                    ...this.getOrderMarkers(this.pendingOrders),
                    ...this.getDriverMarkers(this.drivers),
                  ];
                  this.cd.detectChanges();
                }
                // this.drivers = fbDrivers.for;
                this.loaderOrdersFromAPI = false;
              },
            );
        }
      },
    );
  }

  filterByID() {
    this.filteredOrders = this.filter
      ? this.filteredOrders.filter((e) => e.id.toString().includes(this.filter))
      : this.pendingOrders;
    this.cd.detectChanges();
  }

  selectOrder(order: PendingOrders) {
    this.selectedOrder = order;
    const currentMarkerIndex = this.markers.findIndex(
      (e) => e.order && e.order.id === order.id
    );
    this.HideOrderMarker();
    this.markers[currentMarkerIndex].visible = true;
    this.initMarker.lat = this.markers[currentMarkerIndex].latitude;
    this.initMarker.lng = this.markers[currentMarkerIndex].longitude;
    this.cd.detectChanges();
  }

  showOrderMarkers() {
    this.markers.forEach((e) => {
      if (e.type === 'order') {
        e.visible = true;
      }
    });
  }

  HideOrderMarker() {
    this.markers.forEach((e) => {
      if (e.type === 'order') {
        e.visible = false;
      }
    });
  }

  resetOrders() {
    this.loaderOrder = true;
    setTimeout(() => {
      this.filter = null;
      this.filterByID();
      this.selectedOrder = null;
      this.showOrderMarkers();
      this.initMarker.lat = this.staticMarkers.lat;
      this.initMarker.lng = this.staticMarkers.lng;
      this.loaderOrder = false;
      this.cd.detectChanges();
    }, 500);
  }

  getDriverMarkers(drivers: DriversTrackingFromFirebase[]) {
    return drivers.map((driver) => {
      const marker: any = {
        latitude: driver.collector.latitude,
        longitude: driver.collector.longitude,
        iconUrl: markerIcons.partner,
        draggable: false,
        label: driver.collector.name + '/' + driver.collector.mobileNumber,
        type: 'partner',
        driver: driver,
        visible: true,
      };
      return marker;
    });
  }

  addDriverMarker(driver: DriversTrackingFromFirebase) {
    this.markers.push({
      latitude: driver.collector.latitude,
      longitude: driver.collector.longitude,
      iconUrl: markerIcons.partner,
      draggable: false,
      type: 'partner',
      driver: driver,
      visible: true,
    });
  }

  removeDriverMarker(driver: DriversTrackingFromFirebase) {
    const markerIndex = this.markers.findIndex(
      (e) => e.driver && e.driver.uid === driver.uid
    );
    this.markers.splice(markerIndex, 1);
  }

  getOrderMarkers(orders: PendingOrders[]) {
    let marker;
    return orders.map((order) => {
      if (
        order.statuses_user.id === 1 ||
        order.statuses_user.id === 2 ||
        order.statuses_user.id === 13
      ) {
        marker = {
          latitude: order.user.user_addresses[0].latitude,
          longitude: order.user.user_addresses[0].longitude,
          iconUrl: markerIcons.client,
          draggable: false,
          label: order.user.phone,
          type: 'order',
          order: order,
          visible: true,
        };
      }
      if (order.statuses_user.id === 5 || order.statuses_user.id === 15) {
        marker = {
          latitude: order.laundry_branch.latitude,
          longitude: order.laundry_branch.longitude,
          iconUrl: markerIcons.laundry,
          draggable: false,
          label: order.laundry_branch.name,
          type: 'order',
          order: order,
          visible: true,
        };
      }

      return marker;
    });
  }

  clickMarker(marker: AdminMaker) {
    if (marker.type === 'partner' && marker.driver && marker.driver.order) {
      this.markerRouteOptions = [];
      this.destination = {
        lat: marker.driver.order.customer.address.latitude,
        lng: marker.driver.order.customer.address.longitude,
      };
      this.origin = {
        lat: marker.driver.collector.latitude,
        lng: marker.driver.collector.longitude,
      };

      /* this.markerRouteOptions.push({
        origin: {
          icon: marker.iconUrl,
        },
        destination: {
          icon:
            marker.driver.order.orderStatus < 5
              ? markerIcons.client
              : markerIcons.laundry,
        },
      }); */
      this.cd.detectChanges();
    }
    /*  if (marker.type === 'order') {
      const assigned = this.markers.filter(
        (e) => e.driver && e.driver.orderId === marker.order.id
      );
      if (assigned && assigned.length > 0) {
        this.destination = {
          lat: marker.latitude,
          lng: marker.longitude,
        };
        this.origin = {
          lat: assigned[0].driver.collector.latitude,
          lng: assigned[0].driver.collector.longitude,
        };
        this.markerRouteOptions.push({
          origin: {
            icon: assigned[0].iconUrl,
          },
          destination: {
            icon: marker.iconUrl,
          },
        });
      }
    } */
  }

  resetRoute() {
    this.loaderRoute = true;
    setTimeout(() => {
      this.destination = null;
      this.origin = null;
      this.loaderRoute = false;
      this.markerRouteOptions = [];
      this.cd.detectChanges();
    }, 500);
  }

  getStatus(statusId: number) {
    return STATUSES[statusId - 1];
  }

  formatTimeSlot(timeSlot: string) {
    const hour = this.globalService
      .formatDate(timeSlot, 'HH:MM:ss')
      .split(':')[0];
    const nextHour = this.globalService
      .formatDate(
        this.globalService.dateAddition(timeSlot, 1, 'hour').toString(),
        'HH:MM:ss'
      )
      .split(':')[0];
    return `${hour} H - ${nextHour} H`;
  }

  refreshMap() {
    this.drivers = [];
    this.getPendingOrders();
  }
  private sortOrderByTimeSlot() {
    this.pendingOrders.forEach((e) => {
      e.actionTime =
        e.statuses_user.id < 5
          ? e.pickup_at.toString()
          : e.delivery_at.toString();
    });
    return this.globalService.uniq(
      this.pendingOrders.sort((current, next) => {
        return this.globalService.isBefore(current.actionTime, next.actionTime)
          ? -1
          : this.globalService.isSame(current.actionTime, next.actionTime)
          ? 0
          : 1;
      }),
      'id'
    );
  }
}
