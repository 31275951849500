import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient /*HttpParams*/ } from '@angular/common/http';

import { environment } from '@env/environment';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { CashbackCampaign } from '@shared/models';

@Injectable({ providedIn: 'root' })
export class CashbackCampaignHttpService {
  private readonly url = `${environment.jcDeliveryServer}cashback-campaigns`;

  constructor(private readonly http: HttpClient) {}

  getCashbackCampaigns(
    params?: Record<string, string>,
  ): Observable<ApiResponse<CashbackCampaign[]>> {
    return this.http.get<ApiResponse<CashbackCampaign[]>>(this.url, { params });
  }

  getCashbackCampaign(id: number): Observable<ApiResponse<CashbackCampaign>> {
    return this.http.get<ApiResponse<CashbackCampaign>>(`${this.url}/${id}`);
  }

  createCashbackCampaign(
    data: Partial<CashbackCampaign>,
  ): Observable<ApiResponse<CashbackCampaign>> {
    return this.http.post<ApiResponse<CashbackCampaign>>(this.url, data);
  }

  updateCashbackCampaign(
    id: number,
    data: Partial<CashbackCampaign>,
  ): Observable<ApiResponse<CashbackCampaign>> {
    return this.http.patch<ApiResponse<CashbackCampaign>>(
      `${this.url}/${id}`,
      data,
    );
  }
}
