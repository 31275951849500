import {
  Component,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  OnInit, ChangeDetectorRef
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { CarToBeSaved, CarDetails } from '../../../../shared/models/cars/cars';

import { CarsService } from '../../../../shared/service/cars.service';

import { GlobalService } from '../../../../shared/service/global.service';
import { LaundryService } from '../../../../shared/service/laundry.service';
import { LaundryFromList } from '../../../../shared/models/laundries/laundry';
import { BranchesService } from '../../../../shared/service/branches.service';
import { BranchList, BrArea } from '../../../../shared/models/laundries/branch';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { map } from "rxjs/operators";
import { GroupService } from "@shared/service/group";
import { Observable } from "rxjs";
import { Group } from "@shared/models";
import { AppHeaders } from "@shared/models/user/generic-backend";

@Component({
  selector: 'app-car-create',
  templateUrl: './car-create.component.html',
  styleUrls: ['./car-create.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarCreateComponent implements OnChanges, OnInit {
  @Input() isShown: boolean;
  @Input() car: CarDetails;
  @Input() isUpdate: boolean;
  @Output() isClosed: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('createCarModal', { static: true }) modal: ModalDirective;

  createCarForm: UntypedFormGroup;
  carToBeSaved: CarToBeSaved;
  laundries: any[];
  selectedLaundry: number;
  branches: any[];
  areas: any[];
  loader: boolean = false;
  hasExpiry = false;
  showError = false;
  errorBackEnd: any;
  showBranch = false;
  laundry_id: number;
  branch_id: number;
  area_id: number;
  dataReady = false;
  isLoadingGroups: boolean;
  isLoadingLaundries: boolean;
  groups$: Observable<Array<Group>>;
  selectedGroup: Group;
  headers: AppHeaders = {};

  constructor(
    private carsService: CarsService,
    private globalService: GlobalService,
    private router: Router,
    private laundryService: LaundryService,
    private branchService: BranchesService,
    private groupsService: GroupService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.headers = this.globalService.headers
    this.loadLaundryGroups();
    this.carToBeSaved = new CarToBeSaved();
    this.getLaundries();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loader = false;
    if (this.isShown) {
      this.initForm();
      this.modal.show();
    }
  }

  saveCar() {
    this.loader = true;
    this.carToBeSaved.device_id = this.createCarForm.controls.deviceId.value;
    this.carToBeSaved.plate_no = this.createCarForm.controls.plateNo.value;
    this.carToBeSaved.name = this.createCarForm.controls.name.value;
    this.carToBeSaved.laundry_branch_id = this.isUpdate
      ? this.branch_id
      : this.carToBeSaved.laundry_branch_id;
    this.carToBeSaved.area_id = this.isUpdate
      ? this.area_id
      : this.carToBeSaved.area_id;
    this.carToBeSaved.car_id = this.isUpdate ? this.car.id : null;
    if (!this.isUpdate) {
      delete this.carToBeSaved.car_id;
    }
    this.carsService
      .saveCar(this.headers, this.carToBeSaved)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.router.navigate(['dashboard/cars/cars-list']);
            this.loader = false;
            this.modal.hide();
            this.refreshData.emit(true);
          }
        },
        error => {
          this.loader = false;
          this.showError = true;
          this.errorBackEnd = error;
        }
      );
  }

  onHidden() {
    this.isUpdate = false;
    this.car = null;
    this.initForm();
    this.isClosed.emit(true);
  }

  getLaundries() {
    this.isLoadingLaundries = true;
    this.laundryService
      .getLaundriesWithParams(this.headers, { page: 0 })
      .subscribe(response => {
        const laundry = response.data as LaundryFromList[];
        this.laundries = [];
        laundry.forEach(laundryElement => {
          this.laundries.push({
            value: laundryElement.id,
            label: laundryElement.name
          });
        });
        this.isLoadingLaundries = false;
        this.changeDetectorRef.detectChanges()
      });
  }

  getBranches(laundryId) {
    this.branchService
      .getBranchList(this.headers, laundryId)
      .subscribe(response => {
        const branch = response.data as BranchList[];
        this.branches = [];
        branch.forEach(branchElement => {
          this.branches.push({
            value: branchElement.id,
            label: branchElement.name
          });
        });
      });
  }

  getAreas(branchId) {
    this.branchService
      .getBranchAr(this.headers, branchId, { is_selected: false })
      .subscribe(response => {
        const areas = response.data as BrArea[];
        this.areas = [];
        areas.forEach(area => {
          this.areas.push({ value: area.id, label: area.name });
        });
      });
  }

  validationCondition() {
    const isValid = this.carToBeSaved.area_id && this.carToBeSaved.laundry_branch_id;
    return isValid;
  }

  getLaundryValues(event: any) {
    this.getBranches(event.value);
    this.showBranch = true;
  }

  branchesChanges(event) {
    this.carToBeSaved.laundry_branch_id = event.value;
    this.getAreas(event.value);
  }


  getAreaValues(event: any) {
    this.carToBeSaved.area_id = event.value;
    // Force form validation check after area selection
    this.createCarForm.updateValueAndValidity();
  }

  private initForm() {
    this.createCarForm = new UntypedFormGroup({
      name: new UntypedFormControl(
        this.isUpdate ? this.car.name : null,
        Validators.required
      ),
      plateNo: new UntypedFormControl(
        this.isUpdate ? this.car.plate_no : null,
        Validators.required
      ),
      deviceId: new UntypedFormControl(
        this.isUpdate ? this.car.device_id : null,
        Validators.required
      ),
      selectedGroup: new UntypedFormControl(
        this.selectedGroup ? this.selectedGroup : null,
        Validators.required
      )
    });


    this.laundry_id = this.isUpdate ? this.car.laundry_id : null;
    this.branch_id = this.carToBeSaved.laundry_branch_id = this.isUpdate
      ? this.car.laundry_branch_id
      : null;
    this.area_id = this.carToBeSaved.area_id = this.isUpdate
      ? this.car.area_id
      : null;

    this.dataReady = true;
  }


  loadLaundryGroups(): void {
    this.isLoadingGroups = true;
    this.groups$ = this.groupsService.getGroups().pipe(
      map((groups) => {
        if (this.selectedGroup) {
          /** This to bind with groups dropdown */
          this.selectedGroup = groups.find(
            ({ id }) => id === this.selectedGroup.id,
          );
        } else {
          // assign the first group by default
          this.selectedGroup = groups.find(() => true);
        }

        this.createCarForm.get('selectedGroup').setValue(this.selectedGroup);
        this.isLoadingGroups = false;
        return groups.filter((group) =>
          this.groupsService.checkLaundryDesign(group),
        );
      }),
    );
  }


  setGroup(): void {
    this.headers.group_id = this.selectedGroup?.id ? this.selectedGroup.id.toString() : '1';
    this.laundries = [];
    this.getLaundries();
    this.createCarForm.get('selectedGroup').setValue(this.selectedGroup);

    this.changeDetectorRef.detectChanges();
  }


}
