import { CarwashStation } from '@app/modules/carwash/modules/carwash-station/shared/model/carwash-station.model';
import { CarwashStationService } from '@app/modules/carwash/modules/carwash-station/shared/services/carwash-station.service';
import { finalize, map, takeUntil } from 'rxjs/operators';
import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { DIALOG_DATA } from '@app/shared/components/dialog/services/dialog.token';
import { Observable, Subject } from 'rxjs';
import { DialogRef } from '@app/shared/components/dialog/dialog-ref/dialog-ref';
import { UsersService } from '@app/shared/service/users.service';
import { LaundryUserFromList } from '@app/shared/models/user/laundry-user';

@Component({
  selector: 'app-assign-staff-dialog',
  templateUrl: './assign-staff-dialog.component.html',
  styleUrls: ['./assign-staff-dialog.component.scss'],
})
export class AssignStaffDialogComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>() ;

  staffList$: Observable<Array<LaundryUserFromList>>;
  staffList: Array<LaundryUserFromList>;
  selectedStaffId: number;
  isLoading: boolean;

  constructor(
    @Inject(DIALOG_DATA) public readonly carwashStation: CarwashStation | any,
    private readonly stationService: CarwashStationService,
    private readonly usersService: UsersService,
    private readonly dialogRef: DialogRef<AssignStaffDialogComponent>,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadStaffList();
    this.selectedStaffId = this.carwashStation.userJlmId;
  }

  close(isSubmit?: boolean): void {
    this.dialogRef.close(isSubmit);
  }

  save(): void {
    this.isLoading = true;
    this.stationService
      .assignStaff(this.carwashStation.id, this.selectedStaffId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.cd.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.close(true));
  }

  private loadStaffList(): void {
    this.staffList$ = this.usersService
      .getLaundryUsers(null, {
        cw_garage_branch_id: this.carwashStation.cwGarageBranchId,
      })
      .pipe(
        map((res) => {
          this.staffList = res.data;
          return res.data;
        }),
      );
  }

  private unsubscribe(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
