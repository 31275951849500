import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { CustomerService } from 'src/app/shared/service/customer.service';
import { GlobalService } from 'src/app/shared/service/global.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit, OnChanges {
  @Input() isPasswordModalOpened: boolean;
  @Input() customerId: string;
  @Output() isClosedModal: EventEmitter<boolean> = new EventEmitter();
  password: string;
  confirmPassword: string;
  isButtonDisabled: boolean;

  constructor(
    private globalService: GlobalService,
    private customerService: CustomerService,
    private toaster: ToastService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.customerId &&
      changes.customerId.currentValue !== changes.customerId.previousValue
    ) {
      this.initFormData();
    }
  }

  ngOnInit() {
    this.initFormData();
  }

  initFormData(): void {
    this.password = '';
    this.confirmPassword = '';
    this.isButtonDisabled = false;
  }
  changeCustomerPassword(): void {
    this.isPasswordModalOpened = true;
  }

  updatePassword(): void {
    this.isButtonDisabled = true;
    const param = { password: this.password };
    this.customerService
      .updatePassword(this.globalService.headers, this.customerId, param)
      .subscribe(
        (response) => {
          this.toaster.success(
            `${response.data.firstName} password updated successfully!`
          );
          this.initFormData();
          this.isClosedModal.emit(false);
        },
        (error) => {
          console.log('Something went worng!', error.error.message);
          this.isButtonDisabled = false;
        }
      );
  }

  isPasswordMatch(): boolean {
    return this.password !== this.confirmPassword;
  }

  closePasswordModal(): void {
    this.isClosedModal.emit(false);
  }

  isFormValid(): boolean {
    return (
      this.isButtonDisabled ||
      this.password.length <= 5 ||
      this.password.length > 7 ||
      this.confirmPassword.length <= 5 ||
      this.password !== this.confirmPassword
    );
  }
}
