import { BrArea } from './../models/laundries/branch';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { BackEndResponse, NewBackEndResponse } from '../models/global/response';
import {
  BranchToBeSaved,
  BranchSettings,
  ItemPriceToBeAdd,
  NewBranchArea,
  BranchTimeSlotToBeSavedNew,
  BranchPosition,
  DiscountOnCreate,
  BranchTATServiceToBeSaved,
  UniqTimeSlot,
  BranchContractInfoToBeSaved,
  UserTypeApiRequstBody,
} from '../models/laundries/branch';
import { environment } from '../../../environments/environment';

import { expand, map, reduce, scan } from 'rxjs/operators';
import { ApiLibrary } from '../helpers/http-params';
import {
  PosContractCreationBody,
  PosCustomContractData,
} from '../models/laundries/pos';
import { BillToBeSaved } from '../models/laundries/branch-bill';
import { SpecialDateToBeSaved } from '../models/laundries/branch-special-timeslots';
import { BranchArea, CarwashBranchAreaRequest } from '../models';
import { EMPTY, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class BranchesService {
  readonly URL_SAVE_LAUNDRY = `${environment.serverUrl}laundry/branch`;
  readonly URL_GET_LAUNDRY_INFO = `${environment.serverUrl}laundry/branch-info/`;
  readonly URL_GET_LIST = `${environment.serverUrl}laundry/branch/`;
  readonly URL_GET_LIST_WISE = `${environment.serverUrl}laundry/branch-list`;
  readonly URL_GET_LIST_CWG_WISE = `${environment.jcDeliveryServer}cw-garage-branches`;
  readonly URL_GET_LIST_CW_GARAGES = `${environment.jcDeliveryServer}cw-garages`;
  readonly URL_GET_LIST_CHANGE_LAUNDRY_WISE = `${environment.serverUrl}laundry/available-branch-list`;
  readonly URL_GET_ORDER_FOR_BRANCH = `${environment.serverUrl}laundry/branch/orders/`;
  readonly URL_GET_ORDER_DETAILS = `${environment.serverUrl}laundry/branch/order-detail/`;
  readonly URL_CREATE_BRANCH_AREA = `${environment.serverUrl}laundry/areas`;
  readonly URL_GET_BRANCH_AREA = `${environment.serverUrl}laundry/areas/`;
  readonly URL_CREATE_LAUNDRY_SETTINGS = `${environment.serverUrl}laundry/configure-laundry-setting`;
  readonly URL_GET_LAUNDRY_SETTINGS = `${environment.serverUrl}laundry/get-laundry-setting/`;
  readonly URL_CREATE_TIMESLOT = `${environment.serverUrl}laundry/create-timeslot`;
  readonly URL_GET_TIMESLOT = `${environment.serverUrl}laundry/get-timeslot/`;
  readonly URL_GET_TIMESLOT_PER_DAY = `${environment.serverUrl}laundry/timeslots/`;
  readonly URL_GET_DAY_TIMESLOT = `${environment.serverUrl}laundry/timeslots-days`;
  readonly URL_PRICE_LIST = `${environment.serverUrl}laundry/configure-branch-item-price`;
  readonly URL_GET_PRICE_LIST = `${environment.serverUrl}laundry/get-branch-item-price`;
  readonly URL_GET_ALL_TIMESLOT = `${environment.serverUrl}laundry/get-timeslot`;
  readonly URL_BRAREA = `${environment.serverUrl}laundry/branches/areas`;
  readonly URL_NEW_TIME_SLOT = `${environment.serverUrl}laundry/timeslots`;
  readonly URL_ACTIVATE_BRANCH = `${environment.serverUrl}laundry/change-status-branch`;

  readonly URL_CHANGE_POS = `${environment.serverUrl}laundry/change-position`;
  readonly URL_CHANGE_POS_V4 = `${environment.jcDeliveryServer}laundry-branch-areas/positioning`;
  readonly URL_CHANGE_POS_STATUS = `${environment.serverUrl}laundry/change-status-pos-branch`;
  readonly URL_DISCOUNT = `${environment.serverUrl}discount/discount`;
  readonly URL_DISCOUNT_STATUS = `${environment.serverUrl}discount/change-status`;
  readonly URL_MAKE_CORPORATE = `${environment.serverUrl}laundry/make-corporate-branch`;
  readonly URL_NO_CONTRACT_BRANCH = `${environment.serverUrl}laundry/laundry-without-contract/`;
  readonly URL_CHANGE_FAST_SERVICE = `${environment.serverUrl}laundry/activate-fast-service`;
  readonly URL_UPDATE_TAT = `${environment.serverUrl}laundry/update-branch-tat`;
  readonly URL_GET_BRANCH_TAT = `${environment.serverUrl}laundry/get-branch-tat/`;
  readonly URL_GET_ALL_TAT = `${environment.serverUrl}laundry/get-tat-services`;
  readonly URL_GET_SPECIAL_ICONS = `${environment.serverUrl}laundry/special-services`;
  readonly URL_POS_CONDITIONS = `${environment.serverUrl}pos/conditions`;
  readonly URL_POS_CONTRACT = `${environment.jcDeliveryServer}laundry-subscriptions`;
  readonly URL_INVOICE_TYPE = `${environment.serverUrl}invoices/invoice-types`;
  readonly URL_INVOICES = `${environment.serverUrl}invoices/invoices`;
  readonly URL_POSITION_CONTRACT_LIST = `${environment.serverUrl}laundry/position-contract`;
  readonly URL_POSITION_AVAILABLE = `${environment.serverUrl}laundry/laundry-branch-position`;
  readonly URL_BRANCH_CONTRACTS = `${environment.serverUrl}laundry-branches`;
  readonly URL_BRANCH_DETAILS = `${environment.jcDeliveryServer}laundry-branches/`;
  readonly URL_SUBSCRIPTION_LIST = `${environment.jcDeliveryServer}subscription-packages`;
  readonly URL_CUSTOM_PACKAGE = `${environment.jcDeliveryServer}subscription-custom-packages`;
  readonly URL_SELECTED_AREAS = `${environment.jcDeliveryServer}laundry-branch-areas`;
  readonly URL_BRANCH_ALL_AREAS = `${environment.jcDeliveryServer}areas`;

  readonly URL_LAUNDRIES = `${environment.jcDeliveryServer}laundries`;
  readonly URL_LAUNDRY_BRANCHES = `${environment.jcDeliveryServer}laundry-branches`;
  readonly urlLaundryBranchesUserJlm =
    environment.jcDeliveryServer + 'users-jlm/laundry-branches';

  // special timesslot
  readonly URL_DEFAULT_TIME_SPECIAL_DATES = `${environment.serverUrl}laundry/timeslot-date-times`;
  readonly URL_LIST_SPECIAL_DATES_AND_SAVE = `${environment.serverUrl}laundry/timeslot-times`;
  isLaundryLogistics: boolean;
  constructor(private http: HttpClient) {}

  createBranch(headers, branch: BranchToBeSaved) {
    return this.http.post<BackEndResponse>(this.URL_SAVE_LAUNDRY, branch, {
      headers: new HttpHeaders(headers),
    });
  }
  createCustomPackage(headers, customPackage: PosCustomContractData) {
    return this.http.post<BackEndResponse>(
      this.URL_CUSTOM_PACKAGE,
      customPackage,
      {
        headers: new HttpHeaders(headers),
      },
    );
  }

  getCustomPackage(headers, id: number) {
    return this.http.get<BackEndResponse>(`${this.URL_CUSTOM_PACKAGE}/${id}`, {
      headers: new HttpHeaders(headers),
    });
  }

  getLaundryBranchesUserJlm(headers, userJlmId: number) {
    return this.http.get<BackEndResponse>(`${this.urlLaundryBranchesUserJlm}`, {
      headers: new HttpHeaders(headers),
      params: { userJlmId },
    });
  }

  updateBranch(headers, branch: BranchToBeSaved, id: number) {
    return this.http.put<BackEndResponse>(
      `${this.URL_SAVE_LAUNDRY}/${id}`,
      branch,
      {
        headers: new HttpHeaders(headers),
      },
    );
  }

  getBranchDetailsWithUserTypes(headers, id: number) {
    return this.http.get<BackEndResponse>(this.URL_BRANCH_DETAILS + id, {
      headers: new HttpHeaders(headers),
    });
  }

  getSubscriptionList(headers, id: number) {
    const params = {
      countryId: id,
      isTrial: false,
    };
    return this.http.get<BackEndResponse>(this.URL_SUBSCRIPTION_LIST, {
      headers: new HttpHeaders(headers),
      params,
    });
  }

  getCustomSubscriptionList(
    headers,
    id: number,
    branchId: number,
    subscripeID: number,
    startDate: string,
    expiryDate: string,
    branchIdKey: string,
  ) {
    const params = {
      countryId: id,
      isTrial: false,
      [branchIdKey]: branchId,
      id: subscripeID,
      startDate: startDate,
      expiryDate: expiryDate,
    };
    return this.http.get<BackEndResponse>(this.URL_SUBSCRIPTION_LIST, {
      headers: new HttpHeaders(headers),
      params,
    });
  }

  updateBranchDetailsUserTypes(
    headers,
    params: UserTypeApiRequstBody,
    id: number,
  ) {
    return this.http.patch<BackEndResponse>(
      this.URL_BRANCH_DETAILS + id,
      params,
      {
        headers: new HttpHeaders(headers),
      },
    );
  }

  getBranchInfo(headers, id: number) {
    return this.http.get<BackEndResponse>(this.URL_GET_LAUNDRY_INFO + id, {
      headers: new HttpHeaders(headers),
    });
  }

  getSpecialIcons(headers) {
    return this.http.get<BackEndResponse>(this.URL_GET_SPECIAL_ICONS, {
      headers: new HttpHeaders(headers),
    });
  }

  getBranchList(headers, laundryId: number) {
    return this.http.get<BackEndResponse>(this.URL_GET_LIST + laundryId, {
      headers: new HttpHeaders(headers),
    });
  }

  getBranchWise(headers, params?: any) {
    return this.http.get<BackEndResponse>(this.URL_GET_LIST_WISE, {
      headers: new HttpHeaders(headers),
      params: params ? params : null,
    });
  }
  //
  // getLaundryList(headers, params?: any) {
  //   return this.http.get<NewBackEndResponse>(this.URL_LAUNDRIES, {
  //     headers: new HttpHeaders(headers),
  //     params: params ? params : null,
  //   })
  //     .pipe(map((res) => res.data));
  //
  // }

  getLaundryList(headers, params?: any) {
    if (params && params.page === 'all') {
      let page = 1;
      return this.fetchLaundryPage(headers, { ...params, page }).pipe(
        expand((laundries) => {
          if (laundries.length === 0) {
            return EMPTY;
          } else {
            return this.fetchLaundryPage(headers, { ...params, page: ++page });
          }
        }),
        scan((acc, laundries) => acc.concat(laundries), []),
      );
    } else {
      return this.http
        .get<NewBackEndResponse>(this.URL_LAUNDRIES, {
          headers: new HttpHeaders(headers),
          params: params ? params : null,
        })
        .pipe(map((res) => res.data));
    }
  }

  private fetchLaundryPage(headers, params): Observable<any> {
    return this.http
      .get<NewBackEndResponse>(this.URL_LAUNDRIES, {
        headers: new HttpHeaders(headers),
        params: params ? params : null,
      })
      .pipe(map((res) => res.data));
  }

  getLaundryBranchList(headers, params?: any) {
    return this.http
      .get<NewBackEndResponse>(this.URL_LAUNDRY_BRANCHES, {
        headers: new HttpHeaders(headers),
        params: params ? params : null,
      })
      .pipe(map((res) => res.data));
  }

  getCwGarageList(headers, params?: any) {
    if (params && params.page === 'all') {
      let page = 1;
      return this.fetchCwGaragePage(headers, { ...params, page }).pipe(
        expand((laundries) => {
          if (laundries.length === 0) {
            return EMPTY;
          } else {
            return this.fetchCwGaragePage(headers, { ...params, page: ++page });
          }
        }),
        scan((acc, laundries) => acc.concat(laundries), []),
      );
    } else {
      return this.http
        .get<NewBackEndResponse>(this.URL_GET_LIST_CW_GARAGES, {
          headers: new HttpHeaders(headers),
          params: params ? params : null,
        })
        .pipe(map((res) => res.data));
    }
  }

  private fetchCwGaragePage(headers, params): Observable<any> {
    return this.http
      .get<NewBackEndResponse>(this.URL_GET_LIST_CW_GARAGES, {
        headers: new HttpHeaders(headers),
        params: params ? params : null,
      })
      .pipe(map((res) => res.data));
  }

  getCwgBranchList(headers, params?: any) {
    return this.http
      .get<NewBackEndResponse>(this.URL_GET_LIST_CWG_WISE, {
        headers: new HttpHeaders(headers),
        params: params ? params : null,
      })
      .pipe(map((res) => res.data));
  }

  getCwgBranchWise(headers, params?: any) {
    return this.http.get<NewBackEndResponse>(this.URL_GET_LIST_CWG_WISE, {
      headers: new HttpHeaders(headers),
      params: params ? params : null,
    });
  }

  getBranchListForAssignNewLaundry(headers, params) {
    return this.http.get<BackEndResponse>(
      this.URL_GET_LIST_CHANGE_LAUNDRY_WISE,
      {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params }),
      },
    );
  }

  getBranchOrders(headers, branchId: number) {
    return this.http.get<BackEndResponse>(
      this.URL_GET_ORDER_FOR_BRANCH + branchId,
      {
        headers: new HttpHeaders(headers),
      },
    );
  }

  getBranchOrderDetails(headers, orderId: number) {
    return this.http.get<BackEndResponse>(
      this.URL_GET_ORDER_DETAILS + orderId,
      {
        headers: new HttpHeaders(headers),
      },
    );
  }

  createBranchArea(headers, branchArea: NewBranchArea) {
    return this.http.patch<BackEndResponse>(this.URL_BRAREA, branchArea, {
      headers: new HttpHeaders(headers),
    });
  }

  getBranchAres(headers, branchId: number) {
    return this.http.get<BackEndResponse>(this.URL_GET_BRANCH_AREA + branchId, {
      headers: new HttpHeaders(headers),
    });
  }

  getBranchAllAreas(params?: any): Observable<Array<BrArea>> {
    return this.http
      .get<BackEndResponse>(`${this.URL_BRANCH_ALL_AREAS}`, {
        params,
      })
      .pipe(
        expand((res) =>
          !res.pageInfo.nextPage
            ? EMPTY
            : this.http.get<BackEndResponse>(
                this.URL_BRANCH_ALL_AREAS,
                this.updateRequestFormat(params, res.pageInfo.nextPage),
              ),
        ),
        reduce((records, res) => records.concat(res.data), []),
      );
  }

  getBranchSelectedAreas(params?: any): Observable<BranchArea[]> {
    return this.http
      .get<BackEndResponse>(this.URL_SELECTED_AREAS, {
        params,
      })
      .pipe(
        expand((res) =>
          !res.pageInfo.nextPage
            ? EMPTY
            : this.http.get<BackEndResponse>(
                this.URL_SELECTED_AREAS,
                this.updateRequestFormat(params, res.pageInfo.nextPage),
              ),
        ),
        reduce((records, res) => records.concat(res.data), []),
      );
  }

  assignAreasToBranch(payload: CarwashBranchAreaRequest) {
    return this.http.post(this.URL_SELECTED_AREAS, payload);
  }

  unAssignAreasFromBranch(uniqueId: number) {
    return this.http.delete(`${this.URL_SELECTED_AREAS}/${uniqueId}`);
  }

  getBranchAr(headers, branchId: number, params?: any) {
    const pms = new ApiLibrary().cleanObject(params);
    return this.http.get<BackEndResponse>(`${this.URL_BRAREA}/${branchId}`, {
      headers: new HttpHeaders(headers),
      params: new HttpParams({ fromObject: pms }),
    });
  }

  getBranchAreas(headers, params?: any) {
    const pms = new ApiLibrary().cleanObject(params);
    return this.http.get<BackEndResponse>(`${this.URL_BRAREA}`, {
      headers: new HttpHeaders(headers),
      params: new HttpParams({ fromObject: pms }),
    });
  }

  createLaundrySettings(headers, branchSettings: BranchSettings) {
    return this.http.post<BackEndResponse>(
      this.URL_CREATE_LAUNDRY_SETTINGS,
      branchSettings,
      {
        headers: new HttpHeaders(headers),
      },
    );
  }

  getLaundrySettings(headers, laundryId: number) {
    return this.http.get<BackEndResponse>(
      this.URL_GET_LAUNDRY_SETTINGS + laundryId,
      {
        headers: new HttpHeaders(headers),
      },
    );
  }

  createLaundryTimeSlot(headers, laundryTimeSlot: BranchTimeSlotToBeSavedNew) {
    return this.http.patch<BackEndResponse>(
      this.URL_NEW_TIME_SLOT,
      laundryTimeSlot,
      {
        headers: new HttpHeaders(headers),
      },
    );
  }

  getBranchTimeSlot(headers, branchId: number, params?: any) {
    return this.http.get<BackEndResponse>(
      `${this.URL_NEW_TIME_SLOT}/${branchId}`,
      {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params }),
      },
    );
  }

  getBranchDaysTimeSlot(headers) {
    return this.http.get<BackEndResponse>(this.URL_GET_DAY_TIMESLOT, {
      headers: new HttpHeaders(headers),
    });
  }
  getBranchTimeSlotPerDay(
    headers,
    branchId: number,
    dayId: number,
    params?: any,
  ) {
    return this.http.get<BackEndResponse>(
      `${this.URL_GET_TIMESLOT_PER_DAY}${branchId}/${dayId}`,
      {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params }),
      },
    );
  }

  saveUniqTimeSlot(headers, body: UniqTimeSlot) {
    return this.http.patch<BackEndResponse>(this.URL_GET_DAY_TIMESLOT, body, {
      headers: new HttpHeaders(headers),
    });
  }

  getAllTimeSlots(headers, gap: any) {
    return this.http.get<BackEndResponse>(this.URL_GET_ALL_TIMESLOT, {
      headers: new HttpHeaders(headers),
      params: { time_gap: gap },
    });
  }

  saveItemPriceList(headers, itemPriceL: ItemPriceToBeAdd) {
    return this.http.post<BackEndResponse>(this.URL_PRICE_LIST, itemPriceL, {
      headers: new HttpHeaders(headers),
    });
  }

  getBranchItemPrice(headers, branchId: number, searchTerm?: any) {
    return this.http.get<BackEndResponse>(
      `${this.URL_GET_PRICE_LIST}/${branchId}`,
      {
        headers: new HttpHeaders(headers),
        params: searchTerm ? searchTerm : null,
      },
    );
  }

  changeBranchStatus(
    headers,
    body: {
      branch_id: number;
      status?: string;
      is_logistics?: boolean;
    },
  ) {
    return this.http.post<BackEndResponse>(this.URL_ACTIVATE_BRANCH, body, {
      headers: new HttpHeaders(headers),
    });
  }

  changePosition(headers, body: BranchPosition) {
    return this.http.patch<BackEndResponse>(this.URL_CHANGE_POS_V4, body, {
      headers: new HttpHeaders(headers),
    });
  }

  getDiscountForBranch(headers, id: number) {
    return this.http.get<BackEndResponse>(`${this.URL_DISCOUNT}/${id}`, {
      headers: new HttpHeaders(headers),
    });
  }

  saveDiscount(headers, body: DiscountOnCreate) {
    return this.http.post<BackEndResponse>(this.URL_DISCOUNT, body, {
      headers: new HttpHeaders(headers),
    });
  }

  changeDiscountStatus(
    headers,
    body: {
      laundry_branch_id: number;
      has_discount: boolean;
    },
  ) {
    return this.http.post<BackEndResponse>(this.URL_DISCOUNT_STATUS, body, {
      headers: new HttpHeaders(headers),
    });
  }

  changeBranchPosStatus(
    headers,
    body: { laundry_branch_id: number; status: boolean },
  ) {
    return this.http.post<BackEndResponse>(this.URL_CHANGE_POS_STATUS, body, {
      headers: new HttpHeaders(headers),
    });
  }

  changeBranchCorporateStatus(
    headers,
    body: { laundry_branch_id: number; is_corporate: boolean },
  ) {
    return this.http.post<BackEndResponse>(this.URL_MAKE_CORPORATE, body, {
      headers: new HttpHeaders(headers),
    });
  }

  getBranchesWithNocontractCorporate(
    headers,
    laundryId: number,
    corpId: number,
  ) {
    return this.http.get<BackEndResponse>(
      `${this.URL_NO_CONTRACT_BRANCH}${laundryId}/${corpId}`,
      {
        headers: new HttpHeaders(headers),
      },
    );
  }

  getBranchTats(headers, branchId: number) {
    return this.http.get<BackEndResponse>(this.URL_GET_BRANCH_TAT + branchId, {
      headers: new HttpHeaders(headers),
    });
  }

  getAllTats(headers) {
    return this.http.get<BackEndResponse>(this.URL_GET_ALL_TAT, {
      headers: new HttpHeaders(headers),
    });
  }

  changeFastServiceStatus(headers, branchId: number, status: boolean) {
    return this.http.post<BackEndResponse>(
      this.URL_CHANGE_FAST_SERVICE,
      {
        laundry_branch_id: branchId,
        has_fast_service: !status,
      },
      {
        headers: new HttpHeaders(headers),
      },
    );
  }

  updateTATService(headers, body: BranchTATServiceToBeSaved) {
    return this.http.post<BackEndResponse>(this.URL_UPDATE_TAT, body, {
      headers: new HttpHeaders(headers),
    });
  }

  getPosConditions(headers) {
    return this.http.get<BackEndResponse>(this.URL_POS_CONDITIONS, {
      headers: new HttpHeaders(headers),
    });
  }

  getPosContractData(headers, branchId: number, branchIdKey: string) {
    return this.http.get<BackEndResponse>(
      this.URL_POS_CONTRACT + `?${branchIdKey}=` + branchId,
      {
        headers: new HttpHeaders(headers),
      },
    );
  }

  getPosActiveContractData(
    headers,
    branchId: number,
    branchIdKey: string,
    countryId: number,
  ) {
    const params = {
      showActiveSubscription: true,
      [branchIdKey]: branchId,
      countryId: countryId,
    };
    return this.http.get<BackEndResponse>(this.URL_POS_CONTRACT, {
      headers: new HttpHeaders(headers),
      params,
    });
  }

  savePosContractData(headers, isPost: boolean, body: PosContractCreationBody) {
    if (isPost) {
      return this.http.post<BackEndResponse>(this.URL_POS_CONTRACT, body, {
        headers: new HttpHeaders(headers),
      });
    } else {
      return this.http.patch<BackEndResponse>(
        `${this.URL_POS_CONTRACT}/${body.id}`,
        body,
        {
          headers: new HttpHeaders(headers),
        },
      );
    }
  }

  // params : laundry_branch_id=16&page=1&startdate=2020-02-13&enddate=2020-02-14

  getBranchBills(headers, params: any) {
    return this.http.get<BackEndResponse>(this.URL_INVOICES, {
      headers: new HttpHeaders(headers),
      params: new HttpParams({ fromObject: params }),
    });
  }

  getBranchBillTypes(headers, params?: any) {
    return this.http.get<BackEndResponse>(this.URL_INVOICE_TYPE, {
      headers: new HttpHeaders(headers),
      params: new HttpParams({ fromObject: params }),
    });
  }

  saveBranchBill(headers, body: BillToBeSaved) {
    return this.http.post<BackEndResponse>(this.URL_INVOICES, body, {
      headers: new HttpHeaders(headers),
    });
  }

  getDefaultTimeSlotsForSpecialDate(headers) {
    return this.http.get<BackEndResponse>(this.URL_DEFAULT_TIME_SPECIAL_DATES, {
      headers: new HttpHeaders(headers),
    });
  }
  // laundry_branch_id=14&page=0
  getBranchSpecialTIme(headers, params?: any) {
    return this.http.get<BackEndResponse>(
      this.URL_LIST_SPECIAL_DATES_AND_SAVE,
      {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params }),
      },
    );
  }

  saveBranchSpecialDate(headers, body: SpecialDateToBeSaved) {
    return this.http.post<BackEndResponse>(
      this.URL_LIST_SPECIAL_DATES_AND_SAVE,
      body,
      {
        headers: new HttpHeaders(headers),
      },
    );
  }

  toggleStatusSpecialDate(headers, body: { id: number; status: boolean }) {
    return this.http.patch<BackEndResponse>(this.URL_BRANCH_CONTRACTS, body, {
      headers: new HttpHeaders(headers),
    });
  }

  saveBranchContract(headers, body: BranchContractInfoToBeSaved) {
    return this.http.post<BackEndResponse>(this.URL_BRANCH_CONTRACTS, body, {
      headers: new HttpHeaders(headers),
    });
  }

  updateBranchContract(
    headers,
    contractId: number,
    body: BranchContractInfoToBeSaved,
  ) {
    return this.http.patch<BackEndResponse>(
      `${this.URL_BRANCH_CONTRACTS}/${contractId}`,
      body,
      {
        headers: new HttpHeaders(headers),
      },
    );
  }

  assignBranch(
    headers,
    body: { userJlmId: number; laundryId: number; laundryBranchId: number },
  ): Observable<BackEndResponse> {
    return this.http.post<BackEndResponse>(
      `${this.urlLaundryBranchesUserJlm}`,
      body,
      {
        headers: new HttpHeaders(headers),
      },
    );
  }

  unAssignBranch(
    headers,
    body: { userJlmId: number; laundryId: number; laundryBranchId: number },
  ): Observable<BackEndResponse> {
    return this.http.delete<BackEndResponse>(
      `${this.urlLaundryBranchesUserJlm}`,
      {
        body: { ...body },
        headers: new HttpHeaders(headers),
      },
    );
  }

  getBranchContractInfo(headers, params: Record<string, string>) {
    return this.http.get<BackEndResponse>(this.URL_BRANCH_CONTRACTS, {
      headers: new HttpHeaders(headers),
      params: new HttpParams({
        fromObject: params,
      }),
    });
  }

  getLaundryBranchesByLaundryId(headers, params: Record<string, string>) {
    return this.http.get<BackEndResponse>(this.URL_BRANCH_DETAILS, {
      headers: new HttpHeaders(headers),
      params: new HttpParams({
        fromObject: params,
      }),
    });
  }

  private updateRequestFormat(params: any, page: number) {
    params.page = page;
    return {
      params,
    };
  }
}
