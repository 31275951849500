export class LanguageData {
  name: string;
  message: string;
}

export class Translations {
  en: LanguageData;
  ar: LanguageData;
}

export class CashBackFromList {
  id: number;
  message: string;
  translations: Translations;
  start_date: Date;
  expiry_date: Date;
  total_cashback: number;
  total_used: number;
  remaining: number;
  status: string;
}

// create cash back

export class Translation {
  language_id: number;
  name: string;
  message: string;
}

export class CashBackToBeSaved {
  translations: Translation[];
  cashback_type: string;
  min_amount_order: number;
  max_amount_order: number;
  discount_type: string;
  discount_value: number;
  start_date: string;
  expiry_date: string;
  laundry_branch_ids: number[];
  validity: number;
  discount_by_justclean: number;
  discount_by_laundry: number;
}

// BOGO
export class BOGOFromList {
  id: number;
  message: string;
  translations: Translations;
  start_date: Date;
  expiry_date: Date;
  total_items: number;
  total_claimed: number;
  remaining: number;
  status: string;
  type: string;
}

export class Offer {
  item_id?: number;
  service_id?: number;
}

export class BOGOToBeSaved {
  translations: Translation[];
  offers: Offer[];
  start_date: string;
  expiry_date: string;
  laundry_branch_ids: number[];
  validity: number;
  next_order: boolean;
  discount_by_justclean: number;
  discount_by_laundry: number;
}
