import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { BackEndResponse } from '../models/global/response';
import { ApiLibrary } from '../helpers/http-params';
import { tap } from 'rxjs/operators';
import { Reviews } from '../models/customer/reviews';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {
  readonly listReviewsUrl = environment.serverUrl + 'setting/get-rating';
  readonly updateReviewsUrl =
    environment.serverUrl + 'setting/change-rating-status';
  readonly BULK_REVIEWS =
    environment.serverUrl + 'setting/update-bulk-rating';
  constructor(private http: HttpClient) {}

  getReviews(headers, params?: any) {
    const pms = new ApiLibrary().cleanObject(params);
    return this.http
      .get<BackEndResponse>(this.listReviewsUrl, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: pms })
      })
      .pipe(tap(data => console.log(data)));
  }

  updateReview(headers, review: Reviews.ReviewsFromList) {
    return this.http
      .post<BackEndResponse>(this.updateReviewsUrl, review, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  updateBulkReview(headers, body: {
    id: number[],
    status: string
  }) {
    return this.http
      .post<BackEndResponse>(this.BULK_REVIEWS, body, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }
}
