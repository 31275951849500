import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';

import { HomecleanPriorityBookingViewRoutingModule } from './homeclean-priority-booking-view-routing.module';
import { HomecleanPriorityBookingViewComponent } from './homeclean-priority-booking-view.component';
import { ChangeCompanyPriorityComponent } from './components/change-company-priority/change-company-priority.component';
import { AddCompanyPriorityComponent } from './components/add-company-priority/add-company-priority.component';

@NgModule({
  declarations: [
    HomecleanPriorityBookingViewComponent,
    ChangeCompanyPriorityComponent,
    AddCompanyPriorityComponent,
  ],
  imports: [SharedModule, HomecleanPriorityBookingViewRoutingModule],
})
export class HomecleanPriorityBookingViewModule {}
