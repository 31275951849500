import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-working-hours-price-input',
  templateUrl: './working-hours-price-input.component.html',
  styleUrls: ['./working-hours-price-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkingHoursPriceInputComponent {
  @Input() priceControl: AbstractControl;

  changeSign(): void {
    const value = this.priceControl.value;
    if (value > 0) {
      this.priceControl.setValue(-Math.abs(value));
    } else {
      this.priceControl.setValue(Math.abs(value));
    }
  }
}
