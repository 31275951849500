import { Router, ActivatedRoute } from '@angular/router';
import { GroupService } from '@shared/service/group';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { DialogService } from '@shared/components/dialog/services/dialog.service';
import { CarwashCompanyEditDialogComponent } from '@app/modules/carwash/modules/carwash-company/components/carwash-company-edit-dialog/carwash-company-edit-dialog.component';
import { CarwashCompany, Group } from '@shared/models';
import { Status } from '@shared/enums/status.enum';
import { PageInfo } from '@shared/models/global/response';
import { filter, finalize, map } from 'rxjs/operators';
import { CarwashCompanyService } from '@shared/service/carwash-company';

@Component({
  selector: 'app-carwash-company-list',
  templateUrl: './carwash-company-list.component.html',
})
export class CarwashCompanyListComponent implements OnInit {
  readonly Status = Status;
  private subscriber$: Subscription;
  pagination$: Observable<PageInfo>;
  companies: CarwashCompany[];
  isLoading: boolean;
  groups$: Observable<Array<Group>>;
  currentPage = 1;
  companyTypeMap = {
    inCall: 'Station',
    outCall: 'Mobile',
  };
  selectedGroup: Group;
  isMobileType: boolean;
  isLaundryDesign: boolean;

  constructor(
    private readonly carwashCompanyService: CarwashCompanyService,
    private readonly groupService: GroupService,
    private readonly dialog: DialogService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.checkCompanySelectedGroup();
    this.pagination$ = this.carwashCompanyService.pagination$;
    this.groups$ = this.groupService.getGroups().pipe(
      map((groups) => {
        if (this.selectedGroup) {
          /** This to bind with groups dropdown */
          this.selectedGroup = groups.find(
            ({ id }) => id === this.selectedGroup.id,
          );
        }
        return groups.filter(
          (group) => !this.groupService.checkHomeCleaningDesign(group),
        );
      }),
    );

    if (this.groupService.selectedGroup) {
      this.selectedGroup = this.groupService.selectedGroup;
      this.loadGroupCompanies();
    }
  }

  loadGroupCompanies(): void {
    localStorage.setItem(
      'companySelectedGroup',
      JSON.stringify(this.selectedGroup),
    );
    this.currentPage = 1;
    this.isMobileType = this.groupService.checkOutCall(this.selectedGroup);
    this.isLaundryDesign = this.groupService.checkLaundryDesign(
      this.selectedGroup,
    );

    if (!this.isLaundryDesign) {
      this.loadData();
    }

    this.router.navigate([`.`], {
      relativeTo: this.route,
      queryParams: { groupId: this.selectedGroup.id },
    });

    this.changeDetectorRef.detectChanges();
  }

  openDialog(company: CarwashCompany = null): void {
    this.dialog
      .open(CarwashCompanyEditDialogComponent, {
        company,
        groupId: this.selectedGroup?.id,
      })
      .afterClosed$.pipe(filter(Boolean))
      .subscribe(() => this.loadData());
  }

  removeCompany(id: number): void {
    this.carwashCompanyService
      .removeCompany(id)
      .subscribe(() => this.loadData());
  }

  changeCompanyStatus(id: number, status: Status): void {
    this.carwashCompanyService
      .updateCompany(id, {
        status: status === Status.Inactive ? Status.Active : Status.Inactive,
      })
      .subscribe();
  }

  getPage(pageNumber: number) {
    this.currentPage = pageNumber;
    this.loadData();
  }

  openBranches(companyId: number): void {
    this.groupService.selectedGroup = this.selectedGroup;
    this.router.navigate([`/dashboard/carwash/branches/${companyId}/list`], {
      queryParams: {
        isMobileType: this.isMobileType,
        groupId: this.selectedGroup?.id,
      },
    });
  }

  private checkCompanySelectedGroup(): void {
    const companySelectedGroup = localStorage.getItem('companySelectedGroup');
    if (companySelectedGroup) {
      this.selectedGroup = this.groupService.selectedGroup =
        JSON.parse(companySelectedGroup);
    }
  }

  private loadData(): void {
    this.isLoading = true;
    this.companies = null;
    this.unsubscribe();

    this.subscriber$ = this.carwashCompanyService
      .getCompanies(this.getParams())
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((data) => (this.companies = data));
  }

  private getParams(): any {
    const params: any = {
      page: this.currentPage,
    };
    if (this.selectedGroup) {
      params.groupId = this.selectedGroup.id.toString();
    }
    return params;
  }

  private unsubscribe(): void {
    if (this.subscriber$) {
      this.subscriber$.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
