import { Component, OnInit } from '@angular/core';
import {
  DriverFromAllList,
  LogisticsDriverToSave
} from 'src/app/shared/models/cars/log-driver';
import {
  PageInfo,
  BackEndResponse
} from 'src/app/shared/models/global/response';
import { GlobalService } from 'src/app/shared/service/global.service';
import { LogDriverService } from 'src/app/shared/service/log-driver.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { DataManager } from 'src/app/shared/helpers/data-manager';
import { DataExchangeWithBackend } from 'src/app/shared/models/user/generic-backend';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-log-all-drivers-list',
  templateUrl: './log-all-drivers-list.component.html',
  styleUrls: ['./log-all-drivers-list.component.css']
})
export class LogAllDriversListComponent implements OnInit {
  drivers: DriverFromAllList[];
  pagination: PageInfo;
  isShown: boolean;
  isCapacityModalShown: boolean;
  toggleDeleteModal = false;
  loader = false;
  driver: LogisticsDriverToSave;
  isUpdate: boolean;
  refreshingCar = false;
  dataSubject: Subject<DataExchangeWithBackend> = new Subject();
  constructor(
    private globalService: GlobalService,
    private logisticsDriver: LogDriverService,
    private toast: ToastService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getDrivers({ page: 1 });
  }

  getDrivers(params?: any) {
    /* this.drivers = [];
    this.logisticsDriver
      .getAllDriverForLogistics(this.globalService.headers, params)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.drivers = response.data;
            this.pagination = response.page_info;
          }
        },
        error => {
          this.toast.error(error.error.message);
        }
      ); */
    DataManager.subscribeToAnObservable<BackEndResponse, any>(
      this.logisticsDriver.getAllDriverForLogistics(
        this.globalService.headers,
        params
      ),
      this.drivers,
      this.toast,
      false,
      this.dataSubject
    );
    this.dataSubject.subscribe(data => {
      this.drivers = data.list;
      this.pagination = data.pagination;
    });
  }

  getPage(event) {
    this.getDrivers({ page: event });
  }

  refreshData(isCreate: boolean) {
    this.getPage(
      isCreate
        ? this.pagination.page_count <= 0
          ? 1
          : this.pagination.page_count
        : this.pagination.current_page <= 0
        ? 1
        : this.pagination.current_page
    );
    this.refreshingCar = true;
  }

  getLaunderies(driver: DriverFromAllList) {
    let html = '';
    driver.laundries.forEach((e, i) => {
      html +=
        '<span class="green-blue-color">' + (i + 1) + '- ' + e + '</span><br>';
    });
    return driver.laundries.length > 0
      ? html
      : '<span class="error-text"> Not Assigned to Any laundry yet</span>';
  }

  showAreas(driver: DriverFromAllList) {
    let html = '';
    driver.areas.forEach((e, i) => {
      html +=
        '<span class="green-blue-color">' +
        (i + 1) +
        '- ' +
        e.name +
        '</span><br>';
    });
    return driver.areas.length > 0
      ? html
      : '<span class="error-text"> Not Assigned to Any Area yet</span>';
  }

  triggerDelete(driver: LogisticsDriverToSave) {
    this.driver = driver;
    this.toggleDeleteModal = true;
  }

  deleteDriver(driver) {
    this.loader = true;
    this.logisticsDriver
      .changeStatusLogisticDriver(
        this.globalService.headers,
        this.driver.id,
        {}
      )
      .subscribe(
        response => {
          this.loader = false;
          this.toggleDeleteModal = false;
          this.refreshingCar = true;
          this.getPage(this.pagination.current_page);
        }
      );
  }

  isClosed(event) {
    this.isShown = false;
    this.isUpdate = false;
  }

  openDialog(isUpdate: boolean, driver?: LogisticsDriverToSave) {
    this.isShown = true;
    this.isUpdate = isUpdate;
    this.driver = isUpdate ? driver : null;
  }

  openCapacityModal(driver: LogisticsDriverToSave) {
    this.isCapacityModalShown = true;
    this.driver = driver;
  }

  capacityModalClosed(event) {
    this.isCapacityModalShown = false;
    this.getPage(this.pagination.current_page);
  }

  toggleStatus(driver: DriverFromAllList) {
    this.refreshingCar = false;
    this.logisticsDriver
      .changeStatusLogisticDriver(this.globalService.headers, driver.id, {
        is_active: !driver.is_active
      })
      .subscribe(
        response => {
          if (response.code === 200) {
            this.getPage(this.pagination.current_page);
            this.refreshingCar = true;
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }

  fixNull(val: any): any {
    return this.globalService.fixNullValues(val);
  }

  isDeleteClosed(event) {
    this.toggleDeleteModal = false;
  }

  goToAssignAreas(driver: DriverFromAllList) {
    this.logisticsDriver
      .getDriversArea(this.globalService.headers, driver.id)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.router.navigate(
              ['dashboard/logistics/drivers/assign-driver'],
              {
                queryParams: {
                  id: driver.id
                }
              }
            );
          }
        }
      );
  }
}
