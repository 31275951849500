import { ActivatedRoute, Router } from '@angular/router';
import { DataType } from '@app/shared/enums';
import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  HostListener,
} from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';

import { DialogService } from '@shared/components/dialog/services/dialog.service';
import { Status } from '@shared/enums/status.enum';
import { PageInfo } from '@shared/models/global/response';
import { filter, finalize, map } from 'rxjs/operators';
import { GeneratedPageEditDialogComponent } from '../generated-page-edit-dialog/generated-page-edit-dialog.component';
import { CrudService } from '@app/shared/service/crud';
import {
  AnotherAction,
  APIConfig,
  ToggleConfig,
  FieldConfig,
} from '@app/shared/models';
import { ACTION_COMPONENT_MAP } from '@app/modules/crud/shared/action-component-map/action-component-map';
import { GlobalConfig, ToastService } from 'ng-uikit-pro-standard';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-generated-page-list',
  templateUrl: './generated-page-list.component.html',
  styleUrls: ['./generated-page-list.component.scss'],
})
export class GeneratedPageListComponent implements OnInit, OnDestroy {
  readonly Status = Status;
  pagination$: Observable<PageInfo>;
  loadedData$: Observable<Array<any>>;
  loadedData: Array<any>;
  pageName: string;
  tableConfig: Array<{
    header: string;
    key: string;
    innerKey?: string;
    toggleConfig?: ToggleConfig;
    apiConfig?: APIConfig;
    dataType?: DataType | string;
    showAsToggleInTable?: boolean;
  }>;
  canCreate: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  otherActions: Array<AnotherAction>;
  separateActions: Array<AnotherAction>;
  noListMsg: string;
  subscribers$: Array<Subscription> = [];
  isConfirmationOpened: boolean;
  isDeleting: boolean;
  selectedDataToDelete: any;
  getNameBounded: Function;
  loadedNames: { [keyname: string]: { [id: number]: string } } = {};
  foreignKeyParam: any;
  isLoading: boolean;
  currentPage: number = 1;
  PageNamePrefix: string;
  fullPageName: string;
  readonly DATA_TYPES = DataType;
  isMobileResolution: boolean;
  private window: Window;

  constructor(
    public readonly crudService: CrudService,
    private readonly dialog: DialogService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly toast: ToastService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.initializeWindowObject();
    if (!this.crudService.selectedCrud && !this.crudService.allPages) {
      this.crudService.loadCrudSubject.subscribe(() => {
        this.initializeGenerator();
      });
    } else {
      this.initializeGenerator();
    }
  }
  initializeGenerator() {
    this.subscribers$.push(
      this.route.params.subscribe((params) => {
        this.currentPage = 1;
        if (params.pageName) {
          this.crudService.setSelectedCrud(params.pageName);
        }
        if (this.crudService.selectedCrud) {
          this.refreshPage();
        } else {
          this.router.navigate(['dashboard/orders/orders-all']);
        }
      }),
    );
    this.subscribers$.push(
      this.route.queryParams.subscribe((params) => {
        this.currentPage = 1;
        this.foreignKeyParam = { ...params };
        if (this.foreignKeyParam?.rowDataName) {
          this.PageNamePrefix = this.foreignKeyParam.rowDataName;
          delete this.foreignKeyParam.rowDataName;
        } else {
          this.PageNamePrefix = '';
        }
        if (this.crudService.selectedCrud) {
          this.refreshPage();
        }
      }),
    );
  }

  openDialog(data?: any, componentSelector?: string): void {
    if (componentSelector && !ACTION_COMPONENT_MAP[componentSelector]) {
      const options: GlobalConfig = this.toast.toastConfig;
      options.preventDuplicates = true;
      options.closeButton = true;

      this.toast.warning(
        'This feature is not deployed yet',
        'Coming soon!',
        options,
      );

      return;
    }

    this.dialog
      .open(
        ACTION_COMPONENT_MAP[componentSelector] ||
          GeneratedPageEditDialogComponent,
        {
          dataRow: data || this.loadedData,
          PageNamePrefix: this.PageNamePrefix,
          foreignKeyParam: this.foreignKeyParam,
        },
      )
      .afterClosed$.pipe(filter(Boolean))
      .subscribe((el) => {
        if (el) {
          this.loadedData$ = null;
          this.resetPage();
          this.loadData();
        }
      });
  }

  navigateToUrl(url: string, foreignKeyName?: string, data?: any): void {
    let name = data.name;
    if (!name) {
      name = data[this.getkeyNameOfDataName()];
    }
    this.router.navigate([url.replace('{id}', data.id)], {
      queryParams: foreignKeyName
        ? { [foreignKeyName]: name?.en, [`${foreignKeyName}AR`]: name?.ar }
        : {},
    });
  }

  onDelete(): void {
    this.isDeleting = true;
    this.crudService
      .delete(this.selectedDataToDelete.id)
      .pipe(
        finalize(() => {
          this.isDeleting = false;
          this.isConfirmationOpened = false;
          this.selectedDataToDelete = null;
        }),
      )
      .subscribe(() => {
        this.loadedData$ = null;
        this.resetPage();
        this.loadData();
      });
  }

  openConfirmationModal(data: any) {
    this.selectedDataToDelete = data;
    this.isConfirmationOpened = true;
  }

  closeConfirmationModal() {
    this.isConfirmationOpened = false;
  }

  openAnotherPage(
    linkedPageId: number,
    foreignKeyName?: string,
    data?: any,
  ): void {
    let pkDataName = data.name?.en || data.name;
    if (!pkDataName) {
      pkDataName = data[this.getkeyNameOfDataName()];
      pkDataName = pkDataName?.en || pkDataName;
    }
    this.crudService.setPage(linkedPageId);
    this.router.navigate(
      [
        `dashboard/crud/page/${this.crudService.selectedCrud.pageName?.toLowerCase()}`,
      ],
      {
        queryParams: foreignKeyName
          ? { [foreignKeyName]: data.id, rowDataName: pkDataName }
          : {},
      },
    );
  }

  toggleFlag(id: number, apiKeyName: string, apiValue: any): void {
    this.crudService.update(id, { [apiKeyName]: apiValue }).subscribe(() => {
      this.loadedData$ = null;
      this.resetPage();
      this.loadData();
    });
  }

  getPage(pageNumber: number) {
    this.currentPage = pageNumber;
    this.loadData();
  }

  /**
   * find the first data model object that includes "Name" keyword
   * @returns first property includes "Name" keyword, like companyName, branchName, ...etc
   */
  private getkeyNameOfDataName(): string {
    return this.crudService.selectedCrud.dataModel.find((dm) =>
      dm.backendKeyName.includes('Name'),
    )?.backendKeyName;
  }

  private getName(
    id: number,
    keyName: string,
    apiConfig: APIConfig,
  ): Observable<any> {
    if (!id) {
      return of('');
    }

    if (!this.loadedNames[keyName]) {
      this.loadedNames[keyName] = {};
    }

    if (this.loadedNames[keyName][id]) {
      return of(this.loadedNames[keyName]);
    }

    this.loadedNames[keyName][id] = '-';

    return this.crudService.getSingleRow(id, apiConfig).pipe(
      map((obj) => {
        this.loadedNames[keyName][id] = obj.name?.en || obj.name;
        return this.loadedNames[keyName];
      }),
    );
  }

  private checkPermission(action: AnotherAction): boolean {
    if (action.isSuperAdmin) {
      return this.crudService.isSuperAdmin;
    }
    const emailsList = action.emailsList?.trim()?.split(',');
    if (emailsList?.length && emailsList[0]) {
      return emailsList.includes(this.crudService.user?.email);
    }
    return true;
  }

  private refreshPage(): void {
    this.isLoading = true;
    this.getNameBounded = this.getName.bind(this);
    this.pagination$ = this.crudService.genericPagination$;
    this.otherActions =
      this.crudService.selectedCrud.otherActions?.filter(
        (action) => !action.isSeparateAction && this.checkPermission(action),
      ) || [];
    this.separateActions =
      this.crudService.selectedCrud.otherActions?.filter(
        (action) => action.isSeparateAction && this.checkPermission(action),
      ) || [];
    this.fullPageName = this.crudService.getMenuItemPageName(
      null,
      this.PageNamePrefix,
    );
    this.pageName = `${this.crudService.selectedCrud.pageName[0].toUpperCase()}${this.crudService.selectedCrud.pageName
      .slice(1)
      .toLowerCase()}`;
    this.noListMsg = `<b>No listed ${this.pageName.toLowerCase()}s</b> <br>You currently have no listed ${this.pageName.toLowerCase()}s as of yet, please create a ${this.pageName.toLowerCase()} for it to list here`;
    const idHeader = `${this.pageName} ID`;
    this.tableConfig = [{ header: idHeader, key: 'id' }];

    this.crudService.selectedCrud.dataModel
      .concat(
        ...this.crudService.selectedCrud.bottomGroupedFields.map(
          (group) => group.dataModel,
        ),
      )
      .filter((field) => field.tableHeaderText)
      .forEach((field) => {
        this.setFormControls(field);
      });

    /** Move toggle to the end of table */
    const toggleColumn = this.tableConfig.find(
      (tableColumn) => tableColumn.toggleConfig,
    );
    if (toggleColumn) {
      this.tableConfig.splice(this.tableConfig.indexOf(toggleColumn), 1);
      this.tableConfig.push(toggleColumn);
    }

    const selectedPage = this.crudService.selectedCrud;
    const isSuperAdmin = this.crudService.isSuperAdmin;

    this.canCreate = selectedPage.canCreateSA
      ? isSuperAdmin && selectedPage.canCreate
      : selectedPage.canCreate;
    this.canUpdate = selectedPage.canUpdateSA
      ? isSuperAdmin && selectedPage.canUpdate
      : selectedPage.canUpdate;
    this.canDelete = selectedPage.canDeleteSA
      ? isSuperAdmin && selectedPage.canDelete
      : selectedPage.canDelete;

    if (selectedPage.pageName === 'service' && (this.crudService.user?.email === 'npereira@justclean.com' || this.crudService.user?.email === 'aalsaleh@justclean.com')) {
      this.canCreate = true;
      this.canUpdate = true;
    }

    this.loadData();
  }

  private loadData(): void {
    this.resetPage();
    this.loadedData$ = this.crudService
      .getPageData(this.foreignKeyParam, this.currentPage)
      .pipe(
        finalize(() => (this.isLoading = false)),
        map((data) => (this.loadedData = data)),
      );
  }

  private setFormControls(field: FieldConfig): void {
    if (this.checkTranlationField(field.dataType)) {
      if (field.isEnglishOnly) {
        this.tableConfig.push(this.getTableLanguageColumn(field, 'en'));
      } else {
        this.tableConfig.push(this.getTableLanguageColumn(field, 'en', true));
        this.tableConfig.push(this.getTableLanguageColumn(field, 'ar', true));
      }
    } else if (this.checkSingleTranlationField(field.dataType)) {
      this.tableConfig.push(this.getTableLanguageColumn(field, 'en'));
    } else {
      this.tableConfig.push({
        header: field.tableHeaderText,
        key: field.backendKeyName,
        toggleConfig: field.toggleConfig,
        apiConfig: field.apiConfig,
        dataType: field.dataType,
        showAsToggleInTable: field.showAsToggleInTable,
      });
    }
  }

  /**
   * @param dataType  translation, textareaTranslation, imageTranslation, pdfTranslation, numberTranslation
   * @returns true if one of these types
   */
  private checkTranlationField(dataType: string): boolean {
    return dataType.includes('(2)');
  }
  /**
   * @param dataType  translationSingle, textareaTranslationSingle, imageTranslationSingle, pdfTranslationSingle, numberTranslationSingle
   * @returns true if one of these types
   */
  private checkSingleTranlationField(dataType: string): boolean {
    return dataType.includes('(1)');
  }

  private getTableLanguageColumn(
    field: FieldConfig,
    lang: 'en' | 'ar',
    withLanguage?: boolean,
  ): any {
    const langs = { en: 'English', ar: 'Arabic' };
    return {
      header: `${field.tableHeaderText}${
        withLanguage ? ` (${langs[lang]})` : ''
      }`,
      key: `${field.backendKeyName}`,
      innerKey: lang,
    };
  }

  private resetPage(): void {
    this.loadedData = null;
  }

  private initializeWindowObject() {
    this.window = this.document.defaultView;
    this.checkMobileResolution();
  }

  @HostListener('window:resize', ['$event'])
  checkMobileResolution(): void {
    this.isMobileResolution = this.window.innerWidth < 1200;
  }

  ngOnDestroy(): void {
    this.subscribers$.forEach((subscriber$) => {
      subscriber$.unsubscribe();
    });
  }
}
