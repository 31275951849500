// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `map-view {
    height: 400px;
  }

  .address-modal-container .modal-dialog {
    max-width: 60%;
  }
  .address-modal-container .modal-body {
      max-height: 32rem;
      overflow-y: auto;
  }
`, "",{"version":3,"sources":["webpack://./src/app/modules/customer/components/create-address/create-address.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,cAAc;EAChB;EACA;MACI,iBAAiB;MACjB,gBAAgB;EACpB","sourcesContent":["map-view {\n    height: 400px;\n  }\n\n  .address-modal-container .modal-dialog {\n    max-width: 60%;\n  }\n  .address-modal-container .modal-body {\n      max-height: 32rem;\n      overflow-y: auto;\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
