import { NgModule } from '@angular/core';
import { LaundryRoutingModule } from './laundry-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CreateLaundryComponent } from './components/create-laundry/create-laundry.component';
import { StepCreationComponent } from './components/create-laundry/step-creation/step-creation.component';
import { StepContractComponent } from './components/create-laundry/step-contract/step-contract.component';
import { StepMarketingComponent } from './components/create-laundry/step-marketing/step-marketing.component';
import { LaundryPositionComponent } from './components/laundry-position/laundry-position.component';
import { AreaPositionComponent } from "@app/modules/laundries/components/area-position/area-position.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { LaundryPosComponent } from "@app/modules/laundries/components/laundry-pos/laundry-pos.component";

@NgModule({
  imports: [SharedModule.forRoot(), LaundryRoutingModule, DragDropModule],
  declarations: [
    CreateLaundryComponent,
    StepCreationComponent,
    StepContractComponent,
    StepMarketingComponent,
    LaundryPosComponent,
    LaundryPositionComponent,
    AreaPositionComponent
  ]
})
export class LaundriesModule {}
