// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay-container .modal {
  display: block;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/dialog/components/dialog.component.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,kBAAA;AAAJ","sourcesContent":[".modal-overlay-container {\n  .modal {\n    display: block;\n    position: relative;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
