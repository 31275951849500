import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import {
  MDBBootstrapModulesPro,
  MDBSpinningPreloader,
  MDB_DATE_OPTIONS,
} from 'ng-uikit-pro-standard';
import { SearchPipe } from 'src/app/shared/pipes/search.pipe';
import { PressEnterDirective } from 'src/app/shared/directives/press-enter.directive';
import { MainReasonComponent } from 'src/app/shared/components/main-reason/main-reason.component';
import { SubReasonComponent } from 'src/app/shared/components/main-reason/sub-reason/sub-reason.component';
import { JcModalComponent } from 'src/app/shared/components/jc-modal/jc-modal.component';
import { JcLaundrySliderComponent } from 'src/app/shared/components/jc-laundry-slider/jc-laundry-slider.component';
import { PaginationNewComponent } from 'src/app/shared/components/pagination-new/pagination-new.component';
import { SafePipe } from 'src/app/shared/pipes/safe.pipe';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TimeslotDropdownComponent } from 'src/app/shared/components/timeslot-dropdown/timeslot-dropdown.component';
import { FormFieldErrorModule } from '@shared/components/form-field-error/form-field-error.module';
import { DeleteModalComponent } from '@shared/components/delete-modal/delete-modal.component';
import { PaginationComponent } from '@shared/components/pagination/pagination.component';
import { AuthService } from '@shared/service/auth.service';
import { CanActivateViaAuthGuard } from '@shared/service/can-activate-via-auth-guard.service';
import { DialogModule } from '@shared/components/dialog/dialog.module';
import { ItemsTatDialogComponent } from '@shared/components/items-tat-dialog/items-tat-dialog.component';
import { DangerSwitchModule } from '@shared/components/danger-switch/danger-switch.module';
import { CreateItemListComponent } from '@shared/components/create-item-list/create-item-list.component';
import { FileUploaderComponent } from '@shared/components/file-uploader/file-uploader.component';
import { UtcToLocalTimePipe } from '@app/shared/pipes/utc-to-local/utc-to-local-time.pipe';
import { LocalToUtcTimePipe } from '@app/shared/pipes/local-to-utc/local-to-utc-time.pipe';
import { StringToJsonPipe } from 'src/app/shared/pipes/string-to-json.pipe';
import { GroupOrdersComponent } from '@shared/components/group-orders/group-orders.component';
import { GenericPipe } from '@app/shared/pipes/generic.pipe';
import { UtcToLocalDatePipe } from '@app/shared/pipes/utc-to-local/utc-to-local-date.pipe';
import { LocalToUtcDatePipe } from '@app/shared/pipes/local-to-utc/local-to-utc-date.pipe';
import { LaundryListComponent } from '../laundries/components/laundry-list/laundry-list.component';
import { NumberToArrayPipe } from '@app/shared/pipes/number-to-array.pipe';
import { NewDesignDropdownComponent } from '@app/shared/components/new-design-dropdown/new-design-dropdown.component';
import { ClickOutsideDirective } from '@app/shared/directives/click-outside/click-outside.directive';
import { JcTimeZonePipe } from '@app/shared/pipes/jc-time-zone.pipe';
import { FilterPipe } from '@app/shared/pipes/filter/filter.pipe';
import { SpinnerComponent } from '@app/shared/components/spinner/spinner.component';
import { SkeletonLoaderComponent } from '@app/shared/components/skeleton-loader/skeleton-loader.component';
import { TranslationPipe } from '@shared/pipes/translation/translation.pipe';
import { NgMapsCoreModule } from "@ng-maps/core";
import { NgMapsGoogleModule } from "@ng-maps/google";
import { GlobalService } from "@shared/service/global.service";

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const SHARED_COMPONENTS = [
  ItemsTatDialogComponent,
  PaginationComponent,
  PaginationNewComponent,
  DeleteModalComponent,
  MainReasonComponent,
  SubReasonComponent,
  JcModalComponent,
  JcLaundrySliderComponent,
  TimeslotDropdownComponent,
  CreateItemListComponent,
  FileUploaderComponent,
  GroupOrdersComponent,
  LaundryListComponent,
  NewDesignDropdownComponent,
  SpinnerComponent,
  SkeletonLoaderComponent,
];

const SHARED_PIPES = [
  SearchPipe,
  SafePipe,
  NumberToArrayPipe,
  GenericPipe,
  StringToJsonPipe,
  UtcToLocalTimePipe,
  LocalToUtcTimePipe,
  UtcToLocalDatePipe,
  LocalToUtcDatePipe,
  JcTimeZonePipe,
  FilterPipe,
  TranslationPipe,
];
const SHARED_DIRECTIVES = [PressEnterDirective, ClickOutsideDirective];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    MDBBootstrapModulesPro.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AngularFireModule.initializeApp(environment.firebase),
    FormFieldErrorModule,
    DialogModule,
    DangerSwitchModule,
  ],
  declarations: [...SHARED_COMPONENTS, ...SHARED_PIPES, ...SHARED_DIRECTIVES],
  providers: [
    MDBSpinningPreloader,
    AuthService,
    CanActivateViaAuthGuard,
    { provide: MDB_DATE_OPTIONS, useValue: { closeAfterSelect: true } },
    AngularFirestore,
    GlobalService
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModulesPro,
    NgMapsCoreModule,
    NgMapsGoogleModule,
    HttpClientModule,
    TranslateModule,
    FormFieldErrorModule,
    DialogModule,
    DangerSwitchModule,
    ...SHARED_COMPONENTS,
    ...SHARED_PIPES,
    ...SHARED_DIRECTIVES
  ],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<any> {
    return { ngModule: SharedModule };
  }
}
