import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../../../shared/service/global.service';
import { CategoriesService } from '../../../../../shared/service/categories.service';
import { ItemsService } from '../../../../../shared/service/items.service';
import { BranchesService } from '../../../../../shared/service/branches.service';
import {
  ActivatedRoute,
  Router,
} from '../../../../../../../node_modules/@angular/router';
import { CategoryList } from '../../../../../shared/models/items/cats';
import {
  ItemPriceToBeAdd,
  ItemList,
} from '../../../../../shared/models/laundries/branch';
import {
  CategoryOfItem,
  ItemFromListItem,
} from '../../../../../shared/models/items/items';
import { ItemsTatDialogComponent } from '@shared/components/items-tat-dialog/items-tat-dialog.component';
import { DialogService } from '@shared/components/dialog/services/dialog.service';
import { AppHeaders } from "@shared/models/user/generic-backend";
import { LaundryService } from "@shared/service/laundry.service";

@Component({
  selector: 'app-branch-item-price',
  templateUrl: './branch-item-price.component.html',
  styleUrls: ['./branch-item-price.component.css'],
})
export class BranchItemPriceComponent implements OnInit {
  laundryId: number;
  branchId: number;
  isUpdate: string;
  cats: any[];
  cat: number;
  branchPriceList: ItemPriceToBeAdd = new ItemPriceToBeAdd();
  loader = false;
  itemPricesUpdate: ItemList[];
  currency: string;
  private headers: AppHeaders = {};
  showOldPrices: boolean;
  hideOldPricesToggle: boolean = false;
  constructor(
    protected globalService: GlobalService,
    protected catService: CategoriesService,
    protected itemService: ItemsService,
    protected branchService: BranchesService,
    protected laundriesService: LaundryService,
    protected route: ActivatedRoute,
    protected router: Router,
    private readonly dialog: DialogService,
  ) {}

  ngOnInit() {
    this.headers = this.globalService.headers;
    this.route.params.subscribe((data) => {
      this.laundryId = data.id;
      this.branchId = data.branchId;
      this.isUpdate = data.update;
      this.getLaundryInfo();
    });
    this.currency = this.globalService.getCurrency();
  }

  getCats() {
    this.catService
      .getCategories(this.headers)
      .subscribe((response) => {
        const cats = response.data as CategoryList[];
        this.cats = [];
        cats.forEach((element) => {
          this.cats.push({
            value: element.id,
            label: element.name.en,
          });
        });
      });
  }

  getItems(catId: number) {
    this.itemService
      .getItems(this.headers, 'all')
      .subscribe((response) => {
        let items = response.data as ItemFromListItem[];
        items = items.filter((element) =>
          this.isItemBelongToCat(element.categories, catId),
        );
        console.log(items);

        this.branchPriceList.item_price = [];
        items.forEach((element) => {
          if (this.isUpdate === 'false') {
            this.branchPriceList.item_price.push({
              fast_dryandwash: 0,
              fast_dryclean: 0,
              fast_iron: 0,
              normal_dryandwash: 0,
              normal_dryclean: 0,
              normal_iron: 0,
              item_id: element.id,
              itemMap: {
                name: element.name.en.name,
                picture: element.image,
              },
            });
          } else {
            const found = this.elementExists(element.id);
            this.branchPriceList.item_price.push({
              fast_dryandwash:
                found >= 0 ? this.itemPricesUpdate[found].fast_washandiron : 0,
              fast_dryclean:
                found >= 0 ? this.itemPricesUpdate[found].fast_dryclean : 0,
              fast_iron:
                found >= 0 ? this.itemPricesUpdate[found].fast_iron : 0,
              normal_dryandwash:
                found >= 0
                  ? this.itemPricesUpdate[found].normal_washandiron
                  : 0,
              normal_dryclean:
                found >= 0 ? this.itemPricesUpdate[found].normal_dryclean : 0,
              normal_iron:
                found >= 0 ? this.itemPricesUpdate[found].normal_iron : 0,
              item_id: element.id,
              itemMap: {
                name: element.name.en.name,
                picture: element.image,
              },
            });
          }
          this.branchPriceList.laundry_branch_id = this.branchId;
        });
      });
  }

  openTatDialog(id: number): void {
    this.dialog.open(ItemsTatDialogComponent, { id, isNoneOptions: true });
  }

  selectCat(event) {
    this.cat = event;
    this.getItems(this.cat);
  }

  isItemBelongToCat(cat: CategoryOfItem[], catId: number): boolean {
    const found = cat.find((c) => c.id === catId);

    return found ? true : false;
  }

  saveBranch() {
    this.loader = true;
    this.cleanItemObject();
    this.branchService
      .saveItemPriceList(this.headers, this.cleanItemObject())
      .subscribe(
        (response) => {
          this.loader = false;
          this.router.navigate([
            'dashboard/' +
              window.localStorage.getItem('module') +
              '/branch-list/' +
              this.laundryId,
          ]);
        },
        (error) => {
          this.loader = false;
        },
      );
  }

  cleanItemObject() {
    const pricelist = JSON.parse(JSON.stringify(this.branchPriceList));
    pricelist.item_price.forEach((ele) => delete ele.itemMap);
    console.log(pricelist);

    return pricelist;
  }

  getItemPrices() {
    this.branchService
      .getBranchItemPrice(this.headers, this.branchId)
      .subscribe((response) => {
        if (response.code === 200) {
          this.itemPricesUpdate = response.data;
        }
      });
  }

  elementExists(id) {
    let found = -1;
    this.itemPricesUpdate.forEach((ele, i) => {
      if (ele.item.id === id) {
        found = i;
      }
    });
    return found;
  }


  getLaundryInfo() {
    this.laundriesService
      .getLaundryInfo(this.headers, this.laundryId)
      .subscribe((response) => {
      if (response.code === 200) {
        if (this.showOldPrices) {
          this.headers.group_id = '1';
        } else {
          this.headers.group_id = response.data?.group_id;
          if (response.data?.group_id === '1') {
            this.hideOldPricesToggle = true
          }
        }

        this.getCats();
        if (this.isUpdate) {
          this.getItemPrices();
        }
      }
    });
  }

  toggleOldItemPrices() {
    this.showOldPrices = !this.showOldPrices;
    this.cats = [];
    this.branchPriceList.item_price = [];
    this.getLaundryInfo();
  }
}
