import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BackEndResponse } from '../models/global/response';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  readonly URL_ROLES = environment.serverUrl + 'users/roles';
  readonly URL_DEP = environment.serverUrl + 'users/department';
  headers = {
    'Access-Control-Allow-Origin': '*'
  };
  constructor(private http: HttpClient) {}

  getRoles(headers, id?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_ROLES, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: id })
      })
      .pipe(tap(data => console.log(data)));
  }

  getDep(headers) {
    return this.http
      .get<BackEndResponse>(this.URL_DEP, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }
}
