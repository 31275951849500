import { Component, OnInit } from '@angular/core';
import {
  CorporateOrderData,
  SCHEDULE_TYPE,
  ScheduleOrder
} from 'src/app/shared/models/laundries/corporate';
import { GlobalService } from 'src/app/shared/service/global.service';
import { BranchesService } from 'src/app/shared/service/branches.service';
import { BranchTimeSlotNew } from 'src/app/shared/models/laundries/branch';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { CorporateService } from 'src/app/shared/service/corporate.service';
import { SelectOptions } from 'src/app/shared/models/global/response';

@Component({
  selector: 'app-schedule-order',
  templateUrl: './schedule-order.component.html',
  styleUrls: ['./schedule-order.component.css']
})
export class ScheduleOrderComponent implements OnInit {
  order: CorporateOrderData;
  branchId: number;
  slots: BranchTimeSlotNew[];
  dailySlots: SelectOptions<number>[];
  weeklyPickupTimes: {
    days: SelectOptions<number>[];
    times: SelectOptions<number>[];
  }[] = [];
  weeklyDeliveryTimes: {
    days: SelectOptions<number>[];
    times: SelectOptions<number>[];
  }[] = [];
  days: SelectOptions<number>[];
  dataReady: boolean;
  loader: boolean;

  constructor(
    private globalService: GlobalService,
    private corporateService: CorporateService,
    private branchService: BranchesService,
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.order = new CorporateOrderData();
    this.initData();
    this.branchId = this.route.snapshot.queryParams.branchId;
    this.order.corporate_laundry_contract_id = this.route.snapshot.queryParams.contractId;
    this.getTimeSlots();
  }

  getTimeSlots() {
    this.branchService
      .getBranchTimeSlot(this.globalService.headers, this.branchId)
      .subscribe(response => {
        if (response.code === 200) {
          this.slots = response.data;
          this.dataReady = true;
        }
      });
  }

  getType(type: string) {
    return SCHEDULE_TYPE[type];
  }

  formatTime(date: string) {
    return this.globalService.getHours(date);
  }

  fillData() {
    this.initData();
    if (this.order.type === SCHEDULE_TYPE.DAILY) {
      const currentDay = this.globalService.getDayName(
        new Date().toISOString()
      );
      this.dailySlots = [];
      const slot = this.slots.filter(day => day.day === currentDay)[0];
      this.fillList(slot, this.dailySlots);
      this.order.schedule[0].pickup_day_id = this.order.schedule[0].delivery_day_id =
        slot.id;
    } else {
      this.days = [];
      this.slots.forEach(slot => {
        this.days.push({
          value: slot.id,
          label: slot.day
        });
      });
      this.weeklyPickupTimes.push({ days: this.days, times: [] });
      this.weeklyDeliveryTimes.push({ days: this.days, times: [] });
      console.log(this.weeklyPickupTimes);
    }
  }
  pickUpDayChanges(event, i) {
    const day = this.getDayFromSlots(event);
    this.weeklyPickupTimes[i].times = [];
    this.fillList(day, this.weeklyPickupTimes[i].times);
    const pickupDays: SelectOptions<number>[] = JSON.parse(
      JSON.stringify(this.weeklyPickupTimes[i].days)
    );
    this.weeklyDeliveryTimes[i].days = pickupDays.filter(
      elem => elem.value !== event
    );
  }
  deliveryDayChanges(event, i) {
    const day = this.getDayFromSlots(event);
    this.weeklyDeliveryTimes[i].times = [];
    this.fillList(day, this.weeklyDeliveryTimes[i].times);
  }
  saveOrder() {
    this.loader = true;
    this.corporateService
      .scheduleOrder(this.globalService.headers, this.order)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.router.navigate(['dashboard/corporate/contract-orders-list'], {
              queryParams: {
                branchId: this.branchId,
                contractId: this.order.corporate_laundry_contract_id
              }
            });
          } else {
            this.toast.error(response.message);
          }
          this.loader = false;
        },
        error => {
          this.loader = false;
        }
      );
  }
  addOrder() {
    this.order.schedule.push(new ScheduleOrder());
    this.weeklyPickupTimes.push({ days: this.days, times: [] });
    this.weeklyDeliveryTimes.push({ days: this.days, times: [] });
  }

  deleteOrder(index: number) {
    this.order.schedule.splice(index, 1);
    this.weeklyPickupTimes.splice(index, 1);
    this.weeklyDeliveryTimes.splice(index, 1);
  }

  validateWeeklyOrder() {
    return (
      this.order.schedule.filter(
        schedule => schedule.pickup_timeslot_id && schedule.delivery_timeslot_id
      ).length === this.order.schedule.length
    );
  }

  private initData() {
    this.order.schedule = [];
    this.order.schedule[0] = new ScheduleOrder();
  }

  private fillList(slots: BranchTimeSlotNew, list: SelectOptions<number>[]) {
    slots.times.forEach(time => {
      if (time.is_selected) {
        list.push({
          value: time.id,
          label:
            this.globalService.getHours(time.start) +
            ' H - ' +
            this.globalService.getHours(time.end) +
            ' H'
        });
      }
    });
    return list;
  }

  private getDayFromSlots(dayId: number): BranchTimeSlotNew {
    return this.slots.find(slot => slot.id === dayId);
  }
}
