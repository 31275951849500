import { ItemPrice } from './branch';

export enum ContractCategory {
  PRICE_PER_KG = 'pricePerKg',
  FLAT = 'flat',
  PRICE_PER_ITEM = 'pricePerItem'
}

export enum ContractTypePrettyName {
  pricePerKg = 'Price per Kg',
  flat = 'Flat',
  pricePerItem = 'Price per Item'
}
export class CorporateClientToBeSaved {
  email: string;
  title: string;
  first_name: string;
  last_name: string;
  phone: string;
  phone_ext: string;
  password: string;
  business_name: string;
  area_id: number;
  block: string;
  street: string;
  avenue: string;
  building: string;
  floor: string;
  extra_info: string;
  longitude: number;
  latitude: number;
}

export class ContractToBeSaved {
  contract_name: string;
  user_corporate_id: number;
  laundry_branch_id: number;
  laundry_id: number;
  contract_type: string;
  commission: number;
  capacity: number;
  amount: number;
  items: PriceItem[];
  corporate_contract_id: number;
}
export class PriceItem {
  id: number;
  price: number;
}
export class Laundry {
  id: number;
  name: string;
}
export class LaundryBranch {
  id: number;
  name: string;
}

export class CorporateContract {
  title: string;
  business_name: string;
  contract_name: string;
  first_name: string;
  last_name: string;
  mobile: string;
  email: string;
  area_id: number;
  block: string;
  street: string;
  avenue: string;
  building: string;
  floor: string;
  extra_info: string;
  longitude: number;
  latitude: number;
}

export class ContractFromList {
  id: number;
  start_date: Date;
  contract_name: string;
  contract_type: string;
  commission: number;
  amount: number;
  capacity: number;
  status: string;
  laundry: Laundry;
  laundry_branch: LaundryBranch;
  corporate_contract: CorporateContract;
  items: ItemPrice[];
}

export enum CorporateStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  CANCELED = 'cancelled'
}

export class CorpCustomerAddress {
  id: number;
  name?: any;
  block: string;
  street: string;
  avenue: string;
  house_no: string;
  apartment_no: string;
  floor: string;
  directions: string;
  users_corporate_id: number;
}

export class CorpCustomerFromList {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  business_name: string;
  uid: string;
  phone: string;
  active_contract: number;
  user_addresses: CorpCustomerAddress[];
}

export class ScheduleOrder {
  pickup_day_id: number;
  pickup_timeslot_id: number;
  delivery_day_id: number;
  delivery_timeslot_id: number;
}
export class CorporateOrderData {
  corporate_laundry_contract_id: number;
  type: string;
  schedule: ScheduleOrder[];
}

export class CorporateOrderSchedule {
  id: number;
  type: string;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  corporate_laundry_contract_id: number;
  country_id: number;
  pickup_timeslot_id: number;
  delivery_timeslot_id: number;
  pickup_day_id: number;
  delivery_day_id: number;
}
export class CorporateOrderScheduleValues {
  id: number;
  type: string;
  pickUpTimeSlot: string;
  deliveryTimeSLot: string;
  pickUpDay: string;
  deliveryDay: string;
}

export class ContractOrders {
  id: number;
  contract_name?: any;
  start_date: Date;
  end_date?: any;
  contract_type: string;
  commission: number;
  amount: number;
  capacity: number;
  status: string;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  country_id: number;
  group_id: number;
  user_admin_id: number;
  laundry_branch_id: number;
  laundry_id?: any;
  users_corporate_id: number;
  corporate_order_schedules: CorporateOrderSchedule[];
}

export enum SCHEDULE_TYPE {
  DAILY = 'daily',
  WEEKLY = 'weekly'
}

export interface ContractHasOrder {
  id: number;
  hasOrder: boolean;
}
