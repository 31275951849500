import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReviewRoutingModule } from './review-routing.module';
import { ReviewListComponent } from './review-list/review-list.component';
import { UpdateReviewComponent } from './review-list/update-review/update-review.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [CommonModule, ReviewRoutingModule, SharedModule.forRoot()],
  declarations: [ReviewListComponent, UpdateReviewComponent]
})
export class ReviewModule {}
