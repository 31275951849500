import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../../../shared/service/global.service';
import { OrderService } from '../../../../shared/service/order.service';
import {
  PendingOrders,
  ActiveOrders,
  STATUSES,
  OrderFilter
} from '../../../../shared/models/orders/order';
import {
  PageInfo,
  SelectOptions
} from '../../../../shared/models/global/response';
import { IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css']
})
export class OrderListComponent implements OnInit {
  pendingOrders: PendingOrders[];
  activeOrders: ActiveOrders[];
  orderStatus = STATUSES;
  pagination: PageInfo;
  paginationPending: PageInfo;
  // filters
  orderFilter: OrderFilter = new OrderFilter();
  statuses: SelectOptions<number>[];
  types: SelectOptions<string>[];
  selectedStatus: number;
  selectedType: string;
  @ViewChild('placedAt', { static: true }) placedAt: MDBDatePickerComponent;
  @ViewChild('pickupAt', { static: true }) pickupAt: MDBDatePickerComponent;
  @ViewChild('deliveryAt', { static: true })
  deliveryAt: MDBDatePickerComponent;
  pickUpDeliveryDatesOptions: IMyOptions;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected globalService: GlobalService,
    protected orderService: OrderService
  ) {}


  groups: SelectOptions<number>[] = [{
    label: 'Laundry',
    value: 1
  }, {
    label: 'Car Wash',
    value: 2
  }];
  groupId = 1;

  ngOnInit() {
     this.pickUpDeliveryDatesOptions = {
      disableSince: {
        day: new Date(this.globalService.getNext7Days().toString()).getDate(),
        month: new Date(this.globalService.getNext7Days().toString()).getMonth() + 1,
        year: new Date(this.globalService.getNext7Days().toString()).getFullYear()
      },
      disableUntil: {
        day: new Date(this.globalService.getLast2MonthDate().toString()).getDate(),
        month: new Date(this.globalService.getLast2MonthDate().toString()).getMonth() + 1,
        year: new Date(this.globalService.getLast2MonthDate().toString()).getFullYear()
      },
      closeAfterSelect: true
    };
    this.initFilters();
    this.readStatuses();
  }

  getPendingOrders(page: number, params?: any) {
    this.orderService
      .getOrdersList(this.globalService.headers, {
        page: page,
        customer_name: this.orderFilter.customerName,
        customer_mobile: this.orderFilter.customerMobile,
        driver_name: this.orderFilter.driverName,
        driver_mobile: this.orderFilter.driverMobile,
        order_id: this.orderFilter.orderNumber,
        placed_at: this.orderFilter.placed_at,
        updated_at: this.orderFilter.updatedAt,
        searchstr: this.orderFilter.searchstr,
        order_status: this.selectedStatus,
        type: this.selectedType,
        is_logistics: this.orderFilter.is_logistics,
        pickup_at: this.orderFilter.pickup_at,
        delivery_at: this.orderFilter.delivery_at,
        start_time: this.orderFilter.start_time,
        stop_time: this.orderFilter.stop_time,
        group_id: this.groupId
      })
      .subscribe(response => {
        if (response.code === 200) {
          this.pendingOrders = response.data;
          this.paginationPending = response.page_info;
        }
      });
  }

  getActiveOrders(page: number, params?: any) {
    this.orderService
      .getActiveOrdersList(this.globalService.headers, {
        page: page,
        customer_name: this.orderFilter.customerName,
        customer_mobile: this.orderFilter.customerMobile,
        driver_name: this.orderFilter.driverName,
        driver_mobile: this.orderFilter.driverMobile,
        order_id: this.orderFilter.orderNumber,
        placed_at: this.orderFilter.placed_at,
        updated_at: this.orderFilter.updatedAt,
        searchstr: this.orderFilter.searchstr,
        order_status: this.selectedStatus,
        type: this.selectedType,
        is_logistics: this.orderFilter.is_logistics,
        pickup_at: this.orderFilter.pickup_at,
        delivery_at: this.orderFilter.delivery_at,
        start_time: this.orderFilter.start_time,
        stop_time: this.orderFilter.stop_time,
        group_id: this.groupId
      })
      .subscribe(response => {
        if (response.code === 200) {
          this.activeOrders = response.data;
          this.pagination = response.page_info;
        }
      });
  }

  getDateTime(date: string) {
    return this.globalService.getDateTimeFromString(date);
  }

  getPage(event) {
    this.getActiveOrders(event);
  }

  getPagePending(event) {
    this.getPendingOrders(event);
  }

  filter() {
    this.getActiveOrders(0);
    this.getPendingOrders(0);
  }

  initFilters() {
    this.orderFilter = new OrderFilter();
    this.orderFilter.is_logistics = false;
    this.selectedStatus = null;
    this.groupId = 1;
    if (!!this.placedAt) {
      this.placedAt.clearDate();
    }
    if (!!this.pickupAt) {
      this.pickupAt.clearDate();
    }
    if (!!this.deliveryAt) {
      this.deliveryAt.clearDate();
    }
    this.getActiveOrders(1);
    this.getPendingOrders(1);
  }

  existOnPending(order: ActiveOrders) {
    return this.pendingOrders.find(e => e.id === order.id);
  }

  toggleIsLogistics() {
    this.orderFilter.is_logistics = !this.orderFilter.is_logistics;
    console.log(this.orderFilter.is_logistics);
  }

  selectTimeSlotFrom(event) {
    console.log(event);
    this.orderFilter.start_time = event;
  }

  selectTimeSlotTo(event) {
    console.log(event);
    this.orderFilter.stop_time = event;
  }
  private readStatuses() {
    this.statuses = [];
    STATUSES.forEach((val, i) => {
      this.statuses.push({
        value: i + 1,
        label: val
      });
    });
    this.types = [
      {
        value: 'marketplace',
        label: 'Marketplace'
      },
      {
        value: 'corporate',
        label: 'Corporate'
      }
    ];
  }
}
