import { GroupCountry } from '@shared/models';
import { Translation } from 'libs/justclean-models/src/lib/justclean-models';
import { SubVariant } from '.';
export interface Variant {
  id: number;
  image: string;
  name: Translation;
  description: Translation;
  groupId: number;
  hasMultipleVariants: boolean;
  subVariants: Array<SubVariant>;
  countryVariants: Array<GroupCountry>;
}
