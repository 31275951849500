import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { Subject, forkJoin } from 'rxjs';

import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import {
  HcService,
  HcServicePaymentMethod,
  PaymentMethod,
} from '@app/shared/models';
import { PaymentHelper } from '@app/shared/helpers/payment';
import { HCServicePaymentMethodsService } from '@app/shared/service/home-cleaning/hc-service-payment-methods.service';

@Component({
  selector: 'app-service-payment-methods',
  templateUrl: './service-payment-methods.component.html',
  styleUrls: ['./service-payment-methods.component.scss'],
})
export class ServicePaymentMethodsComponent implements OnInit {
  isLoadingData: boolean;
  paymentMethods: PaymentMethod[];

  private readonly destroy$: Subject<void> = new Subject<void>() ;
  paymentsToUpdate: Array<number> = [];

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: {
      dataRow: HcService;
      PageNamePrefix: string;
      foreignKeyParam: Object;
    } | any,
    private readonly dialogRef: DialogRef<ServicePaymentMethodsComponent>,
    private readonly hcServicePaymentMethodsService: HCServicePaymentMethodsService,
    private readonly paymentHelper: PaymentHelper,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadPaymentMethods();
  }

  close(): void {
    this.dialogRef.close();
  }

  getPaymentLabel(payment) {
    const getSubString = () =>
      payment.isPosPaymentMethod ? '[Pos]' : '[User]';
    return `${payment.name.en} (${payment.paymentGateway}) ${
      payment.isPosPaymentMethod || payment.isUserPaymentMethod
        ? getSubString()
        : ''
    }`;
  }

  onChangePayment(payment: PaymentMethod, event) {
    this.paymentsToUpdate.push(payment.id);
    const refreshArray = () => {
      this.paymentsToUpdate = this.paymentsToUpdate.filter(
        (paymentMethodId) => paymentMethodId !== payment.id,
      );
      this.cd.detectChanges();
    };
    const successHandler = (data: HcServicePaymentMethod) => {
      payment.checked = event.checked;
      if (event.checked) {
        payment['hcServicePaymentMethodId'] = data.id;
      }
      refreshArray();
    };
    const errorHandler = () => refreshArray();
    const payload = {
      countryId: +this.getCountryId(),
      hcServiceId: this.data.dataRow.id,
      paymentMethodId: payment.id,
    };

    if (event.checked) {
      this.hcServicePaymentMethodsService
        .assignServicePaymentMethod(payload)
        .subscribe(successHandler, errorHandler);
    } else {
      this.hcServicePaymentMethodsService
        .removeServicePaymentMethod(payment['hcServicePaymentMethodId'])
        .subscribe(successHandler, errorHandler);
    }
  }

  private loadPaymentMethods(): void {
    this.isLoadingData = true;

    const allPaymentMethods$ = this.paymentHelper.getPaymentMethods({
      countryId: +this.getCountryId(),
    });
    const servicePaymentMethods =
      this.hcServicePaymentMethodsService.getHcServicePaymentMethods(
        this.data.dataRow.id,
      );

    forkJoin([allPaymentMethods$, servicePaymentMethods])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ([allPaymentMethods, servicePaymentMethods]: [
          { data: PaymentMethod[] },
          HcServicePaymentMethod[],
        ]) => {
          this.paymentMethods = allPaymentMethods.data
            .filter(
              (item) => item.isUserPaymentMethod && item.status === 'active',
            )
            .map((paymentMethod) => {
              const hcServicePayment = servicePaymentMethods?.find(
                (method) => method.paymentMethodId === paymentMethod.id,
              );
              return {
                ...paymentMethod,
                hcServicePaymentMethodId: hcServicePayment?.id,
                checked: !!hcServicePayment,
              };
            });
          this.isLoadingData = false;
          this.cd.detectChanges();
        },
        (error) => {
          this.dialogRef.close();
        },
      );
  }

  private getCountryId(): string {
    return localStorage.getItem('country');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
