import * as core from '@angular/core';
import { Router } from '@angular/router';

@core.Injectable({
  providedIn: 'root'
})
export class CanActivateReviewsService {
  constructor(private router: Router) {}

  canActivate() {
    const email = window.localStorage.getItem('email');
    if (
      email.includes('rcherchali') ||
      email.includes('balqatari') ||
      email.includes('superadmin') ||
      email.includes('dataallah') ||
      email.includes('mseid') ||
      email.includes('asakran')
    ) {
      return true;
    } else {
      this.router.navigate(['not-found']);
      return false;
    }
  }
}
