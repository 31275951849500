import { CustomerSchedulesComponent } from './components/customer-schedules/customer-schedules.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerListComponent } from './components/customer-list/customer-list.component';
import { CustomerDetailsComponent } from './components/customer-details/customer-details.component';
import { RedirectFromNumberComponent } from './components/redirect-from-number/redirect-from-number.component';

const routes: Routes = [
  {
    path: 'customers-list',
    component: CustomerListComponent,
    data: {
      title: 'List of Customers'
    }
  },
  {
    path: 'customer-details/:id',
    component: CustomerDetailsComponent,
    data: {
      title: 'Customer details'
    }
  },
  {
    path: 'customer-schedules/:id',
    component: CustomerSchedulesComponent,
    data: {
      title: 'Customer Schedules'
    }
  },
  { path: 'customer-call/:number', component: RedirectFromNumberComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerRoutingModule {}
