// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-row {
  font-weight: bold;
}

.file-field {
  margin-bottom: 25px;
}

form {
  min-width: 500px;
}

:host::ng-deep .mdb-select-outline .mdb-select-value {
  padding: 0.375rem 0.75rem;
}

:host::ng-deep .col-3 .file-field .btn {
  padding: 0.5rem;
}

.relative {
  position: relative;
}

.text-bold {
  font-weight: bold;
}

.group-array-index {
  position: absolute;
  left: -1rem;
}

.hide {
  display: none;
}

.custom-btn {
  height: 1rem;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 25px;
  position: absolute;
  right: 0;
}

.radio-btn-title {
  margin-right: 1rem;
  margin-top: -0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/crud/components/generated-pages/generated-page-edit-dialog/generated-page-form/generated-page-form.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,QAAA;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;AACF","sourcesContent":[".title-row {\n  font-weight: bold;\n}\n\n.file-field {\n  margin-bottom: 25px;\n}\n\nform {\n  min-width: 500px;\n}\n\n:host::ng-deep .mdb-select-outline .mdb-select-value {\n  padding: 0.375rem 0.75rem;\n}\n\n:host::ng-deep .col-3 .file-field .btn {\n  padding: 0.5rem;\n}\n\n.relative {\n  position: relative;\n}\n\n.text-bold {\n  font-weight: bold;\n}\n\n.group-array-index {\n  position: absolute;\n  left: -1rem;\n}\n\n.hide {\n  display: none;\n}\n\n.custom-btn {\n  height: 1rem;\n  padding: 2px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin: auto;\n  margin-bottom: 25px;\n  position: absolute;\n  right: 0;\n}\n\n.radio-btn-title {\n  margin-right: 1rem;\n  margin-top: -0.5rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
