import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { NgForm } from '@angular/forms';
import { GlobalService } from 'src/app/shared/service/global.service';
import { SelectOptions } from 'src/app/shared/models/global/response';
import { Subject } from 'rxjs';
import { DataExchangeWithBackend } from 'src/app/shared/models/user/generic-backend';
import { CountryService } from 'src/app/shared/service/country.service';
import {
  AreaList,
  GovernorateList
} from 'src/app/shared/models/countries/country';
import { PartnerToBeSaved } from 'src/app/shared/models/cars/partners';
import { PartnersService } from 'src/app/shared/service/partners.service';

@Component({
  selector: 'app-create-partner',
  templateUrl: './create-partner.component.html'
})
export class CreatePartnerComponent implements OnInit, OnChanges {
  @Input() isShown: boolean;
  @Input() isUpdate: boolean;
  @Input() partner: PartnerToBeSaved;
  @Input() refreshCar: boolean;
  @Output() isClosed: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('createPartnerModal', { static: true }) modal: ModalDirective;
  @ViewChild('createPartner', { static: false }) form: NgForm;
  @Input() branchId: number;
  @Input() laundryId: number;

  name: string;
  firstName: string;
  nameAr: string;
  firstNameAr: string;
  mobile: string;
  cars: { value: any; label: any }[];
  selectedCar: number;
  loader = false;
  dataReady = false;
  dataSubject: Subject<DataExchangeWithBackend> = new Subject();
  CarOptionsList = [
    { value: 'pickup_and_delivery', label: 'Pick and Delivery' },
    { value: 'pickup', label: 'PickUp' },
    { value: 'delivery', label: 'Delivery' }
  ];
  carOptions: string;
  areas: SelectOptions<number>[];
  area: number;
  governorate: number;
  governorates: SelectOptions<number>[];
  showAreas = false;
  plateNumber: string;
  partnerAddress: string;

  constructor(
    private globalService: GlobalService,
    private partnerService: PartnersService,
    private toaster: ToastService,
    private countryService: CountryService
  ) {}

  ngOnInit() {
    this.initForm(false);
    this.getGovernorates();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isShown) {
      this.modal.show();
    }

    this.initForm(this.isUpdate);
  }

  onHidden() {
    this.isClosed.emit(true);
    this.initForm(false);
  }

  saveDriver() {
    this.loader = true;
    const partner = this.partner ? this.partner : new PartnerToBeSaved();
    partner.area_id = this.area;
    partner.governorate_id = this.governorate;
    partner.partner_translations = [];
    partner.mobile_no = this.mobile.toString();
    partner.first_name = this.firstName;
    partner.last_name = this.name;
    partner.partner_translations[0] = {
      language_id: 1,
      last_name: this.name,
      first_name: this.firstName
    };
    partner.partner_translations[1] = {
      language_id: 2,
      last_name: this.nameAr,
      first_name: this.firstNameAr
    };
    partner.car_plate_no = this.plateNumber;
    partner.address = this.partnerAddress;
    this.partnerService
      .savePartner(this.globalService.headers, partner)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.loader = false;
            this.modal.hide();
            this.onHidden();
            this.refreshData.emit(!this.isUpdate);
          } else {
            this.toaster.error(response.message);
          }
        },
        error => {
          this.loader = false;
        }
      );
  }

  initForm(isUpdate: boolean) {
    if (this.form) {
      this.form.reset();
      this.area = null;
      this.governorate = null;
      this.showAreas = false;
    }
    if (this.isUpdate) {
      this.name = this.partner.partner_translations[0].last_name;
      this.nameAr = this.partner.partner_translations[1].last_name;
      this.firstNameAr = this.partner.partner_translations[1].first_name;
      this.firstName = this.partner.partner_translations[0].first_name;
      this.mobile = this.partner.mobile_no;
      this.plateNumber = this.partner.car_plate_no;
      this.governorate = this.partner.governorate_id;
      this.getAreas(this.governorate, this.partner.area_id);
      this.partnerAddress = this.partner.address;
      this.showAreas = true;
    }
  }

  getGovernorates() {
    this.countryService
      .getListCities(this.globalService.headers)
      .subscribe(response => {
        if (response.code === 200) {
          this.governorates = [];
          let gov = response.data as GovernorateList[];
          gov = gov.filter(e => e.country_id === this.globalService.countryId);
          gov.forEach(governorate => {
            this.governorates.push({
              value: governorate.id,
              label: governorate.name.en
            });
          });
        }
      });
  }

  getAreas(govId, currentId?: number) {
    this.countryService
      .getListAreas(this.globalService.headers, { page: 0 })
      .subscribe(response => {
        if (response.code === 200) {
          this.areas = [];
          let areas = response.data as AreaList[];

          areas = areas
            .filter(e => e.governorate_id === govId)
            .sort((a, b) => {
              const nameA = a.name.en.toLowerCase(),
                nameB = b.name.en.toLowerCase();
              if (nameA < nameB) {
                // sort string ascending
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
          areas.forEach(area => {
            this.areas.push({
              value: area.id,
              label: area.name.en
            });
          });
        }
        this.area = this.isUpdate ? currentId : null;
      });
  }

  changeGov(event) {
    this.getAreas(event);
    this.showAreas = true;
  }
}
