import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ReferralCashoutRequestsRoutingModule } from './referral-cashout-requests-routing.module';
import { ReferralCashoutRequestListComponent } from './components/referral-cashout-requests-list/referral-cashout-requests-list.component';
import { ReferralCashoutDialogComponent } from './components/referral-cashout-requests-edit-dialog/referral-cashout-requests-dialog.component';

@NgModule({
  declarations: [
    ReferralCashoutRequestListComponent,
    ReferralCashoutDialogComponent,
  ],
  imports: [SharedModule, ReferralCashoutRequestsRoutingModule],
})
export class ReferralCashoutRequestsModule {}
