import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploaderComponent {
  @Input() buttonName: string;
  @Input() fileUrl: string;
  @Input() customImageAlt: string;
  @Input() customFileName: string;
  @Input() allowPdf: boolean;
  @Input() hideFileName: boolean;
  @Input() isWideImage: boolean = false;
  @Input() isSmall: boolean;
  @Input() filesUrl: Array<string>;
  @Input() isMultiple: boolean = false;
  @Input() passSizeCheck: boolean = false;
  @Output() changeFile: EventEmitter<File> = new EventEmitter<File>();
  file: File;
  loading: boolean;
  @Input() maxSize: number = 500 * 1024;
  isSizeError: boolean;
  isTypeError: boolean;

  loadFile(files: FileList): void {
    if (this.isMultiple && files.length > 1) {
      Array.from({ length: files.length }).forEach((_, index) =>
        this.setFile(files.item(index)),
      );
    } else {
      this.setFile(files.item(0));
    }
  }

  setFile(file: File): void {
    this.file = file;
    if (!this.passSizeCheck && this.file.size > this.maxSize) {
      this.isSizeError = true;
      return;
    }
    if (this.file.type === 'application/pdf' && !this.allowPdf) {
      this.isTypeError = true;
      return;
    }
    this.changeFile.emit(this.file);
    this.isSizeError = false;
    this.isTypeError = false;
  }
}
