import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/shared/service/global.service';
import {
  PartnersArea,
  PartnerArea,
  PartnerAreaToBeSaved
} from 'src/app/shared/models/cars/partners';
import { PartnersService } from 'src/app/shared/service/partners.service';

@Component({
  selector: 'app-assign-area-partner',
  templateUrl: './assign-area-partner.component.html'
})
export class AssignAreaPartnerComponent implements OnInit {
  partnerId: number;
  areas: PartnersArea[];
  list: PartnerArea[] = [];
  selectAll = false;
  loader = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private partnerService: PartnersService,
  ) {}

  ngOnInit() {
    this.partnerId = this.route.snapshot.queryParams.id;
    this.getAreas();
  }

  getAreas() {
    this.partnerService
      .getPartnerAreas(this.globalService.headers, this.partnerId)
      .subscribe(response => {
        if (response.code === 200) {
          this.areas = response.data as PartnersArea[];
          this.areas = this.areas.filter(
            area => area.country_id === this.globalService.countryId
          );
        }
      });
  }

  addToList(area: PartnerArea) {
    if (!this.elementExists(area)) {
      this.list.push({
        area_id: area.area_id,
        is_selected: false
      });
    } else {
      this.deleteElemet(area);
    }
    console.log(this.list);
  }

  elementExists(area: PartnerArea) {
    const found = this.list.find(element => element.area_id === area.area_id);
    return found ? true : false;
  }

  deleteElemet(area: PartnerArea) {
    this.list.forEach((element, i) => {
      if (element.area_id === area.area_id) {
        this.list.splice(i, 1);
      }
    });
  }

  toggleArea(area: PartnerArea) {
    area.is_selected = !area.is_selected;
  }

  toggleSelectAll() {
    this.selectAll = !this.selectAll;
    this.areas.forEach(ele => {
      ele.is_selected = this.selectAll ? true : false;
    });

    console.log('toggle', this.list);
  }

  checkSelected() {
    return this.areas && this.areas.find(e => e.is_selected === true)
      ? true
      : false;
  }

  convertAreas() {
    return this.areas
      .map(e => {
        return {
          area_id: e.id as number,
          is_selected: e.is_selected
        };
      })
      .filter(e => e.is_selected);
  }

  saveDriverArea() {
    this.loader = true;
    const partnerArea = new PartnerAreaToBeSaved();
    partnerArea.partner_id = parseFloat(this.partnerId.toString());
    partnerArea.partnerAreas = this.convertAreas();
    console.log(JSON.stringify(partnerArea));

    this.partnerService
      .savePartnerArea(this.globalService.headers, partnerArea)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.loader = false;
            this.router.navigate([
              'dashboard/partners/partners-management/partners-list'
            ]);
          }
        },
        error => {
          this.loader = false;
          this.router.navigate([
            'dashboard/partners/partners-management/partners-list'
          ]);
        }
      );
  }
}
