import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BackEndResponse } from '../models/global/response';
import { tap } from 'rxjs/operators';
import { CashBackToBeSaved, BOGOToBeSaved } from '../models/offers/campaigns';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {
  readonly URL_CASH_BACK = environment.serverUrl + 'campaign/cashback';
  readonly URL_ACTIVATE_CASH_BACK =
    environment.serverUrl + 'campaign/activate-cashback';
  readonly URL_BOGO = environment.serverUrl + 'campaign/itemOffer';
  constructor(private http: HttpClient) {}

  getCashBackList(headers, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_CASH_BACK, {
        params: new HttpParams({ fromObject: params }),
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  saveCashBack(headers, body: CashBackToBeSaved) {
    return this.http
      .post<BackEndResponse>(this.URL_CASH_BACK, body, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  activateCashBack(headers, body: { id: number; status: string }) {
    return this.http
      .put<BackEndResponse>(this.URL_ACTIVATE_CASH_BACK, body, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  // BOGO

  getBOGOList(headers, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_BOGO, {
        params: new HttpParams({ fromObject: params }),
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  saveBogo(headers, body: BOGOToBeSaved) {
    return this.http
      .post<BackEndResponse>(this.URL_BOGO, body, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }
}
