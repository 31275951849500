import {
  Component,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  OnInit
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { CarDetails } from 'src/app/shared/models/cars/cars';
import { GlobalService } from 'src/app/shared/service/global.service';
import { LogisticsCar } from 'src/app/shared/models/cars/log-cars';
import { LogCarService } from 'src/app/shared/service/log-car.service';

@Component({
  selector: 'app-log-car-create',
  templateUrl: './log-car-create.component.html',
  styleUrls: ['./log-car-create.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogCarCreateComponent implements OnChanges, OnInit {
  @Input() isShown: boolean;
  @Input() car: CarDetails;
  @Input() isUpdate: boolean;
  @Output() isClosed: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('createCarModal', { static: true }) modal: ModalDirective;

  createCarForm: UntypedFormGroup;
  carToBeSaved: LogisticsCar;
  loader: boolean;
  hasExpiry = false;
  dataReady = false;
  constructor(
    private carsService: LogCarService,
    private globalService: GlobalService,
    private toaster: ToastService
  ) {}

  ngOnInit() {
    this.carToBeSaved = new LogisticsCar();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loader = false;
    if (this.isShown) {
      this.initForm();
      this.modal.show();
    }
  }

  saveCar() {
    this.loader = true;
    this.carToBeSaved.device_id = this.createCarForm.controls.deviceId.value;
    this.carToBeSaved.plate_no = this.createCarForm.controls.plateNo.value;
    this.carToBeSaved.name = this.createCarForm.controls.name.value;
    this.carToBeSaved.id = this.isUpdate ? this.car.id : null;
    if (!this.isUpdate) {
      delete this.carToBeSaved.id;
    }
    this.carsService
      .saveLogisticsCar(this.globalService.headers, this.carToBeSaved)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.loader = false;
            this.modal.hide();
            this.refreshData.emit(true);
          } else {
            this.toaster.error(response.message);
          }
        },
        error => {
          this.loader = false;
        }
      );
  }

  onHidden() {
    this.isUpdate = false;
    this.car = null;
    this.initForm();
    this.isClosed.emit(true);
  }

  private initForm() {
    this.createCarForm = new UntypedFormGroup({
      name: new UntypedFormControl(
        this.isUpdate ? this.car.name : null,
        Validators.required
      ),
      plateNo: new UntypedFormControl(
        this.isUpdate ? this.car.plate_no : null,
        Validators.required
      ),
      deviceId: new UntypedFormControl(
        this.isUpdate ? this.car.device_id : null,
        Validators.required
      )
    });
    this.dataReady = true;
  }
}
