import {
  Component,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  OnInit
} from '@angular/core';
import { Offer, OfferToBeSaved } from '../../../../shared/models/offers/offers';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OffersService } from '../../../../shared/service/offers.service';
import { Router } from '@angular/router';
import { GlobalService } from '../../../../shared/service/global.service';
import {
  Laundry,
  LaundryService
} from '../../../../shared/service/laundry.service';

@Component({
  selector: 'app-offer-create',
  templateUrl: './offer-create.component.html',
  styleUrls: ['./offer-create.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferCreateComponent implements OnChanges, OnInit {
  @Input() isShown: boolean;
  @Input() offer: Offer;
  @Output() isClosed: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('createOfferModal', { static: true }) modal: ModalDirective;

  createOfferForm: UntypedFormGroup;
  offerTypeData: any[];
  selectedOfferTypeId: number;
  selectedOffer: Offer;
  offerToBeSaved;
  dateValue;
  data;
  laundries;
  laundriesNeetNoPushed;
  offerArr;
  appliedArr;
  discountArr;
  offerTypeArr;
  loader: boolean;
  isUpdate: boolean;
  hasExpiry = false;

  constructor(
    private offersService: OffersService,
    private globalService: GlobalService,
    private router: Router,
    private laundryService: LaundryService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.loader = false;
    this.createOfferForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, Validators.required),
      typeAmount: new UntypedFormControl(null, Validators.required),
      discountValue: new UntypedFormControl(null, Validators.required)
    });

    this.isShown = changes.isShown.currentValue;

    if (this.isShown) {
      this.modal.show();
    }
  }

  saveOffer() {
    this.loader = true;
    this.offerToBeSaved.has_expiry = this.hasExpiry;
    this.offerToBeSaved.discount_value = this.createOfferForm.controls.discountValue.value;
    this.offerToBeSaved.name = this.createOfferForm.controls.name.value;
    this.offerToBeSaved.type_amount = this.createOfferForm.controls.typeAmount.value;

    this.offersService
      .saveOffer(this.globalService.headers, this.offerToBeSaved)
      .subscribe(response => {
        if (response.code === 200) {
          this.router.navigate(['dashboard/offers/offer-list']);
          this.loader = false;
          this.modal.hide();
          this.refreshData.emit(true);
        }
      });
  }

  onHidden() {
    this.isClosed.emit(true);
  }

  getDate(): String {
    const date = new Date();
    return `Start Date is ${date.getDay()}/${date.getMonth()}/${date.getFullYear()}`;
  }

  onStartDateChanged(event) {
    this.offerToBeSaved.start_date = event + ' ' + '00:00:00';
  }

  onEndDateChanged(event) {
    this.offerToBeSaved.end_date = event + ' ' + '00:00:00';
  }

  ngOnInit() {
    this.laundriesNeetNoPushed = [];
    this.offerToBeSaved = new OfferToBeSaved();
    this.laundries = [];
    this.getLaunderies();

    this.offerArr = [
      { value: 'order_total', label: 'Order Total' },
      { value: 'item', label: 'Item' }
    ];
    this.appliedArr = [
      { value: 'min_order_amount', label: 'Min Order Amount' },
      { value: 'any_total', label: 'Any Total' }
    ];
    this.discountArr = [
      { value: 'fixed_amount_total', label: 'Fixed Amount Total' },
      { value: 'free_delivery', label: 'Free Delivery' },
      { value: 'laundry_bag', label: 'Laundry Bag' },
      { value: 'free_service', label: 'Free Service' }
    ];
    this.offerTypeArr = [
      { value: '1', label: 'Free' },
      { value: '2', label: 'BOGO' }
    ];
  }

  addLaundry(id) {
    this.laundries.push({ laundryId: id });
    this.offerToBeSaved.laundry = this.laundries;
  }

  getArrayOfValues(event: any) {
    this.laundriesNeetNoPushed.push({ laundry_id: event.value.laundry_id });
    this.offerToBeSaved.laundry = this.laundriesNeetNoPushed;
  }

  getDiscountTypeValues(event: any) {
    this.offerToBeSaved.discount_type = event.value;
  }

  getAppliedOnValues(event: any) {
    this.offerToBeSaved.applied_on = event.value;
  }

  getOfferOnValues(event: any) {
    this.offerToBeSaved.offer_on = event.value;
  }

  getOfferTypeValues(event: any) {
    this.offerToBeSaved.offer_type_id = event.value;
  }

  validationCondition() {
    return (
      this.offerToBeSaved.offer_type_id &&
      this.offerToBeSaved.offer_on &&
      this.offerToBeSaved.laundry &&
      this.offerToBeSaved.applied_on
    );
  }

  getLaunderies() {
    this.laundryService
      .getLaundries(this.globalService.headers)
      .subscribe(base => {
        const la = base.data as Laundry[];
        la.map(laundry => {
          this.laundries.push({
            value: { laundry_id: laundry.id },
            label: laundry.name
          });
        });
      });
  }
}
