import { map, takeUntil } from 'rxjs/operators';
import { CountryService } from 'src/app/shared/service/country.service';
import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { DIALOG_DATA } from '@app/shared/components/dialog/services/dialog.token';
import { Country } from '@app/shared/models/user/user';
import { Observable, Subject } from 'rxjs';
import { DialogRef } from '@app/shared/components/dialog/dialog-ref/dialog-ref';
import { GroupService } from '@shared/service/group';
import { GroupCountry } from '@app/shared/models';

@Component({
  selector: 'app-assign-country-dialog',
  templateUrl: './assign-country-dialog.component.html',
  styleUrls: ['./assign-country-dialog.component.scss'],
})
export class AssignCountryDialogComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>() ;

  countries$: Observable<Array<Country>>;
  countries: Array<Country>;
  selectedCountries: Array<GroupCountry>;
  isLoading: boolean;
  getCountrySelectedFn: Function;
  selectedCountriesToUpdate: Array<number> = [];

  constructor(
    @Inject(DIALOG_DATA) public readonly groupId: number | any,
    private countryService: CountryService,
    private readonly dialogRef: DialogRef<AssignCountryDialogComponent>,
    private readonly cd: ChangeDetectorRef,
    private readonly groupService: GroupService,
  ) {}

  ngOnInit(): void {
    this.loadCountries();
    this.loadCountriesOfGroup();
    this.getCountrySelectedFn = this.getCountrySelected.bind(this);
  }

  close(): void {
    this.dialogRef.close();
  }

  toggleCountry(selectedCountry: Country): void {
    this.selectedCountriesToUpdate.push(selectedCountry.id);

    const existingCountry = this.selectedCountries.find(
      (country) => country.countryId === selectedCountry.id,
    );
    if (existingCountry) {
      this.groupService
        .removeAssignedCountry(existingCountry.id)
        .subscribe(() => {
          selectedCountry['isChecked'] = false;
          this.selectedCountries = this.selectedCountries.filter(
            (country) => country.countryId !== selectedCountry.id,
          );
          this.refreshSelectedCountriesToUpdate(selectedCountry.id);
        });
    } else {
      const newCountry: GroupCountry = {
        isComingSoon: true,
        groupId: this.groupId,
        countryId: selectedCountry.id,
      };

      this.groupService.assignCountry(newCountry).subscribe(({ data }) => {
        selectedCountry['isChecked'] = true;
        this.selectedCountries = [...this.selectedCountries, data];
        this.refreshSelectedCountriesToUpdate(selectedCountry.id);
      });
    }
  }

  private refreshSelectedCountriesToUpdate(countryId: number): void {
    this.selectedCountriesToUpdate = this.selectedCountriesToUpdate.filter(
      (id) => id !== countryId,
    );
    this.cd.detectChanges();
  }

  toggleIsComingSoon(selectedCountry: Country, isComingSoon: boolean): void {
    this.selectedCountriesToUpdate.push(selectedCountry.id);

    const existingCountry = this.selectedCountries.find(
      (country) => country.countryId === selectedCountry.id,
    );
    if (existingCountry) {
      this.groupService
        .updateAssignedCountry({
          id: existingCountry.id,
          isComingSoon,
          groupId: existingCountry.groupId,
          countryId: existingCountry.countryId,
        })
        .subscribe(() => {
          existingCountry.isComingSoon = isComingSoon;
          this.refreshSelectedCountriesToUpdate(selectedCountry.id);
        });
    }
  }

  private loadCountries(): void {
    this.countries$ = this.countryService.getCountries({}).pipe(
      map((res) => {
        this.countries = res.data;
        return res.data;
      }),
    );
  }

  private loadCountriesOfGroup() {
    this.groupService
      .getGroupCountries(this.groupId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.selectedCountries = res;
        this.cd.detectChanges();
      });
  }

  private getCountrySelected(
    selectedCountries: Array<GroupCountry>,
    country: Country,
  ): GroupCountry {
    const result = selectedCountries.find(
      (selectedCountry) => selectedCountry.countryId === country.id,
    );
    country['isChecked'] = !!result;
    return result;
  }

  private unsubscribe(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
