import { Status } from '@app/shared/enums/status.enum';
import { Name } from '@shared/models';

export interface ReferralCampaign {
  name: Name;
  id?: number;
  senderAmountType: 'percentage' | 'amount';
  senderAmount: number;
  senderPercentage?: number;
  receiverAmount: number;
  startDate: string;
  endDate: string;
  status: Status;
  countryId: number;
  senderUserSegmentId: number;
  receiverUserSegmentId: number;
  senderValidityInMinutes: number;
  receiverValidityInMinutes: number;
  referralReceivableGroups: { groupId: number }[];
  referralDeductibleGroups: { groupId: number }[];
}
