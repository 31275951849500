// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.referral-cashout__row:hover {
  background-color: #e7ddf3;
}
.referral-cashout__selected-row {
  background-color: #e7ddf3;
  color: #641bb4;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
}
.referral-cashout .pointer {
  cursor: pointer;
}
.referral-cashout p {
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/referral-cashout-requests/components/referral-cashout-requests-list/referral-cashout-requests-list.component.scss"],"names":[],"mappings":"AACI;EACI,yBAAA;AAAR;AAGI;EACI,yBAAA;EACA,cAAA;EACA,2BAAA;EACA,8BAAA;AADR;AAGI;EACI,eAAA;AADR;AAGI;EACI,mBAAA;AADR","sourcesContent":[".referral-cashout {\n    &__row:hover {\n        background-color: #e7ddf3;\n    }\n\n    &__selected-row {\n        background-color: #e7ddf3;\n        color: #641bb4;\n        border-top: 2px solid white;\n        border-bottom: 2px solid white;\n    }\n    .pointer {\n        cursor: pointer;    \n    }\n    p {\n        white-space: nowrap;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
