import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { OrderDetailsItemSize } from '@app/modules/carwash/modules/carwash-order/components/carwash-order-details/shared/enums/order-details-item-size.enum';
import { Languages } from '@app/modules/carwash/modules/carwash-packages/shared/models';

@Component({
  selector: 'app-carwash-order-details-item',
  templateUrl: './carwash-order-details-item.component.html',
  styleUrls: ['./carwash-order-details-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarwashOrderDetailsItemComponent {
  @Input() name: string;
  @Input() value: string | string[] | Languages[];
  @Input() size: OrderDetailsItemSize = OrderDetailsItemSize.Medium;

  get isArray(): boolean {
    return Array.isArray(this.value);
  }
}
