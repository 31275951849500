import { Router, ActivatedRoute } from '@angular/router';
import { GroupService } from '@shared/service/group';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { DialogService } from '@shared/components/dialog/services/dialog.service';
import { Group, Variant } from "@shared/models";
import { Status } from '@shared/enums/status.enum';
import { PageInfo } from '@shared/models/global/response';
import { filter, finalize, map } from 'rxjs/operators';
import { VariantService } from "@shared/service/variant";
import { GlobalService } from "@shared/service/global.service";
import { CarWashAddonService } from "@shared/service/car-wash-add-ons";
import { CarWashAddOn } from "@shared/models/carwash/car-wash-add-on.model";
import {
  CarWashAddOnPricingEditDialogComponent
} from "@app/modules/carwash/modules/car-wash-addons-pricing/components/car-wash-add-on-pricing-edit-dialog/car-wash-add-on-pricing-edit-dialog.component";

@Component({
  selector: 'app-car-wash-add-on-list',
  templateUrl: './car-wash-add-on-pricing-list.component.html',
  styleUrls: ['./car-wash-add-on-pricing-list.component.scss'],
})
export class CarWashAddOnPricingListComponent implements OnInit {
  readonly Status = Status;
  private subscriber$: Subscription;
  pagination$: Observable<PageInfo>;
  addOns: CarWashAddOn[];
  isLoading: boolean;
  groups$: Observable<Array<Group>>;
  variants$: Observable<Array<Variant>>;
  currentPage = 1;
  companyTypeMap = {
    inCall: 'Station',
    outCall: 'Mobile',
  };
  selectedGroup: Group;
  cwGarageBranchId: number;
  isMobileType: boolean;
  isLaundryDesign: boolean;
  currency: string = 'KWD';

  constructor(
    private readonly carWashAddonService: CarWashAddonService,
    private readonly groupService: GroupService,
    private readonly globalService: GlobalService,
    private readonly dialog: DialogService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly variantService: VariantService,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.cwGarageBranchId = Number(this.activatedRoute.snapshot.params.id);
    this.checkCompanySelectedGroup();
    this.currency = this.globalService.getCurrency();
    this.pagination$ = this.carWashAddonService.pagination$;
    this.groups$ = this.groupService.getGroups().pipe(
      map((groups) => {
        if (this.selectedGroup) {
          /** This to bind with groups dropdown */
          this.selectedGroup = groups.find(
            ({ id }) => id === this.selectedGroup.id,
          );
        }
        return groups.filter(
          (group) => !this.groupService.checkHomeCleaningDesign(group),
        );
      }),
    );

    if (this.groupService.selectedGroup) {
      this.selectedGroup = this.groupService.selectedGroup;
      this.loadGroupCompanies();
    }
    this.loadVariants()
  }

  loadGroupCompanies(): void {
    localStorage.setItem(
      'companySelectedGroup',
      JSON.stringify(this.selectedGroup),
    );
    this.currentPage = 1;
    this.isMobileType = this.groupService.checkOutCall(this.selectedGroup);
    this.isLaundryDesign = this.groupService.checkLaundryDesign(
      this.selectedGroup,
    );

    if (!this.isLaundryDesign) {
      this.loadData();
    }

    this.router.navigate([`.`], {
      relativeTo: this.route,
      queryParams: { groupId: this.selectedGroup.id },
    });

    this.changeDetectorRef.detectChanges();
  }

  openDialog(carWashAddOn: CarWashAddOn = null): void {
    this.dialog
      .open(CarWashAddOnPricingEditDialogComponent,{
        carWashAddOn: carWashAddOn,
        variants$: this.variants$,
        groupId: this.selectedGroup?.id,
        cwGarageBranchId: this.cwGarageBranchId,
      })
      .afterClosed$.pipe(filter(Boolean))
      .subscribe(() => this.loadData());
  }

  removeAddon(id: number): void {
    this.carWashAddonService
      .removeAddon(id)
      .subscribe(() => this.loadData());
  }

  getPage(pageNumber: number) {
    this.currentPage = pageNumber;
    this.loadData();
  }

  private checkCompanySelectedGroup(): void {
    const companySelectedGroup = localStorage.getItem('companySelectedGroup');
    if (companySelectedGroup) {
      this.selectedGroup = this.groupService.selectedGroup =
        JSON.parse(companySelectedGroup);
    }
  }

  private loadData(): void {
    this.isLoading = true;
    this.addOns = null;
    this.unsubscribe();

    this.subscriber$ = this.carWashAddonService
      .getAddons(this.getParams())
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((data) => (this.addOns = data));
  }

  private getParams(): any {
    const params: any = {
      page: this.currentPage,
      cwGarageBranchId: this.cwGarageBranchId,
      showWithoutPrices: true
    };
    if (this.selectedGroup) {
      params.groupId = this.selectedGroup.id.toString();
    }
    return params;
  }

  private unsubscribe(): void {
    if (this.subscriber$) {
      this.subscriber$.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  private loadVariants(): void {
    this.variants$ = this.variantService.getVariants({
      countryId: this.globalService.countryId.toString() ,
      filterGlobalPackages: 'true',
      groupId: '6'
    }, this.currentPage);
  }

  mapSubVariantPrice(cwAddonId: number, subVariantId: number): number {
    const selectedAddon = this.addOns.find((cwAddon) => { return cwAddon.id === cwAddonId});

    const cwAddonsCwGarageBranch = selectedAddon.cwAddonsCwGarageBranches.find((cwAddonsCwGarageBranch) => cwAddonsCwGarageBranch.subVariantId === subVariantId && cwAddonsCwGarageBranch.cwGarageBranchId === this.cwGarageBranchId);

    return selectedAddon && cwAddonsCwGarageBranch ? cwAddonsCwGarageBranch.price : 0;
  }

}
