import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringToJson',
})
export class StringToJsonPipe implements PipeTransform {
  transform(value: any, keyName: string): any {
    if (value) {
      const obj =
        typeof value === 'string' && this.isValidJsonString(value)
          ? JSON.parse(value)
          : value;

      if (obj?.hasOwnProperty(keyName)) {
        return value[keyName];
      }
    }

    return value;
  }

  private isValidJsonString(str: string): boolean {
    try {
      JSON.parse(str);
    } catch {
      return false;
    }
    return true;
  }
}
