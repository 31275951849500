import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'src/app/shared/service/customer.service';
import { GlobalService } from 'src/app/shared/service/global.service';
import { CustomerFromList } from 'src/app/shared/models/customer/customer';

@Component({
  selector: 'app-redirect-from-number',
  templateUrl: './redirect-from-number.component.html',
  styleUrls: ['./redirect-from-number.component.css']
})
export class RedirectFromNumberComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService,
    private global: GlobalService
  ) {}

  ngOnInit() {
    this.fetchCustomer(this.route.snapshot.params.number);
  }

  fetchCustomer(mobile: string) {
    if (mobile) {
      this.customerService
        .getCustomers(this.global.headers, {
          mobile: mobile
        })
        .subscribe(
          response => {
            if (response.code === 200) {
              const details = response.data as CustomerFromList[];
              if (details.length > 0) {
                this.router.navigate([
                  'dashboard/customers/customer-details/' + details[0].id
                ]);
              } else {
                this.router.navigate(['dashboard/orders/orders-list']);
              }
            } else {
              this.router.navigate(['dashboard/orders/orders-list']);
            }
          },
          error => {
            this.router.navigate(['dashboard/orders/orders-list']);
          }
        );
    } else {
      this.router.navigate(['dashboard/orders/orders-list']);
    }
  }
}
