import { map, tap } from 'rxjs/operators';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HcOrder, HcOrderJob } from '@app/shared/models';
import { PageInfo } from '@shared/models/global/response';
import { HcOrderStatus, JobStatus } from '@app/shared/enums';

@Injectable({
  providedIn: 'root',
})
export class HomeCleaningOrdersService {
  private readonly hcOrdersUrl = `${environment.jcDeliveryServer}hc-orders`;
  private readonly hcOrderJobsUrl = `${environment.jcDeliveryServer}hc-order-jobs`;
  private pagination$: BehaviorSubject<PageInfo> =
    new BehaviorSubject<PageInfo>(null);

  constructor(private readonly http: HttpClient) {}

  getHcOrders(params: any): Observable<Array<HcOrder>> {
    const toData = (res) => res.data;
    return this.http
      .get<ApiResponse<Array<HcOrder>>>(this.hcOrdersUrl, {
        params,
      })
      .pipe(
        tap((res: ApiResponse<Array<HcOrder>>) =>
          this.pagination$.next({
            page_count: res.pageInfo.pageCount,
            current_page: res.pageInfo.currentPage,
            next_page: res.pageInfo.nextPage,
            item_count: res.pageInfo.itemCount,
          }),
        ),
        map(toData),
      );
  }

  getOrderById(id): Observable<HcOrder> {
    return this.http
      .get<ApiResponse<HcOrder>>(`${this.hcOrdersUrl}/${id}`)
      .pipe(map((res: ApiResponse<HcOrder>) => res.data));
  }

  getHcOrderJobs(params: any): Observable<Array<HcOrderJob>> {
    const toData = ({ data }) => data;
    return this.http
      .get<ApiResponse<Array<HcOrderJob>>>(this.hcOrderJobsUrl, {
        params,
      })
      .pipe(map(toData));
  }

  updateOrderStatus(
    id: number,
    statusesUserId: JobStatus,
    status: HcOrderStatus,
    userAdminId: number,
    reasonId?: number,
    reason?: string,
  ) {
    const requestBody = {
      userAdminId,
      statusesUserId,
      status,
      reasonId,
      reason,
    };
    return this.http.patch<ApiResponse<HcOrder>>(
      `${this.hcOrdersUrl}/${id}`,
      requestBody,
    );
  }

  transferOrderToAnotherBranch(id: number, body: Record<string, any>) {
    return this.http.post<ApiResponse<HcOrder>>(
      `${this.hcOrdersUrl}/${id}/transfer`,
      body,
    );
  }

  rescheduleJob(body: Record<string, any>) {
    return this.http.patch<ApiResponse<HcOrder>>(
      `${this.hcOrderJobsUrl}/reschedule`,
      body,
    );
  }

  getPagination(): Observable<PageInfo> {
    return this.pagination$;
  }
}
