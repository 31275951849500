import { SelectOptions } from '@shared/models/global/response';
import { DataType } from '@app/shared/enums';
import { APIConfig, ToggleConfig } from '.';

export interface FieldConfig {
  backendKeyName: string;
  dataType?: DataType | string;
  tableHeaderText?: string;
  isEnglishOnly?: boolean;
  labelText: string;
  /** if data type is list */
  list?: Array<SelectOptions<any>>;
  apiConfig?: APIConfig;
  isRequired?: boolean;
  minLength?: number;
  maxLength?: number;

  /** if data type is boolean, has table header text and is toggle (just checkbox in UI) */
  showAsToggleInTable?: boolean;
  toggleConfig?: ToggleConfig;

  // TODO: to add more validators later
  // validations?: Array<ValidatorType>;
  /** if validator type is pattern */
  // pattern?: string;
}
