import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(arr: any, key: string, value: string, innerArrayName?: string): unknown {
    /** No data passed */
    if (!arr || !key || !value || (!innerArrayName && !arr.length) || (innerArrayName && !arr[innerArrayName])) {
      return arr;
    }
    /** arr is an object and inside it "innerArrayName" array */
    if (innerArrayName) {
      const filteredArray = arr[innerArrayName].filter(el => el[key]?.toLowerCase().includes(value?.toLowerCase()));
      return { ...arr, [innerArrayName]: filteredArray };
    }
    /** arr is an array */
    return arr.filter(el => el[key]?.toLowerCase().includes(value?.toLowerCase()));
  }

}
