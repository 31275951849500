import { HcBranch } from './hc-branch';
import { HomecleanCompany } from '@app/modules/homeclean/modules/homeclean-company/shared/models';
import { Translation } from 'libs/justclean-models/src/lib/justclean-models';
import { HcArea } from '.';
export interface HcService {
  id: number;
  name: Translation;
  description: Translation;
  minimumHours: Translation;
  startingFrom: Translation;
  icons: Array<{ link: string; name: Translation }>;
  uniqueId: string;
  openTime: string;
  closeTime: string;
  countryId: number;
  timeSlotTimes: any[];
  areas: Array<HcArea>;
  hcCompanyServices?: Array<HomecleanCompany>;
  hcCompanyBranches?: Array<HcBranch>;
  hcServiceSSTDs: Array<any>;
}
