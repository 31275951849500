import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {JcCreditPackagesListComponent} from './components/jc-credit-package-list/jc-credit-packages-list.component';

const routes: Routes = [
  {
    path: 'jc-credit-offers-list',
    component: JcCreditPackagesListComponent,
    data: {
      title: 'Jc Credit Packages'
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JcCreditOffersRoutingModule {}
