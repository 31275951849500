import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DropdownOption } from '@app/shared/models';

@Component({
  selector: 'new-design-dropdown',
  templateUrl: './new-design-dropdown.component.html',
  styleUrls: ['./new-design-dropdown.component.scss'],
})
export class NewDesignDropdownComponent implements OnInit {
  @Input() data: DropdownOption<any>[];
  @Input() selectedValue: any;
  @Input() staticIcon: string;
  @Input() disabled: boolean;
  @Output() change = new EventEmitter<DropdownOption<any>>();

  @Input() phone: string;

  defaultOpen: boolean;
  selectedItem: DropdownOption<any>;

  ngOnInit(): void {
    this.setSelectedItem();
  }

  selectItem(item: DropdownOption<any>): void {
    this.selectedItem = item;
    this.closeDropdown();
    this.change.emit(item);
  }
  closeDropdown(): void {
    this.defaultOpen = false;
  }

  toggleDropdown(evt: Event): void {
    evt.preventDefault();
    this.defaultOpen = !this.defaultOpen;
  }

  private setSelectedItem(): void {
    this.selectedItem =
      this.data.find((item) => item.value === this.selectedValue) || null;
  }
}
