import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SelectOptions } from '../../models/global/response';

@Component({
  selector: 'app-timeslot-dropdown',
  templateUrl: './timeslot-dropdown.component.html',
  styleUrls: ['./timeslot-dropdown.component.css'],
})
export class TimeslotDropdownComponent implements OnInit {
  timeslots: SelectOptions<string>[] = [];
  @Input() placeholder: string;
  @Input() value: string;
  @Output() select: EventEmitter<string> = new EventEmitter();
  constructor() {}

  ngOnInit() {
    this.generateTimeSlotdata();
  }

  selectTimeSlot(event) {
    this.select.emit(event.value);
  }

  reset() {

  }

  private generateTimeSlotdata() {
    for (let i = 8; i < 24; i++) {
      this.timeslots.push({
        value: i < 10 ? '0' + i + ':00:00' : i + ':00:00',
        label: i < 10 ? '0' + i + ':00:00' : i + ':00:00'
      });
    }
  }
}
