import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BackEndResponse, NewBackEndResponse } from '@shared/models/global/response';
import { GlobalService } from 'src/app/shared/service/global.service';
import { BranchesService } from 'src/app/shared/service/branches.service';
import { BranchPerArea, BranchPosition, BrArea } from 'src/app/shared/models/laundries/branch';
import { CountryService } from 'src/app/shared/service/country.service';
import { ToastService, ModalDirective } from 'ng-uikit-pro-standard';
import { GroupService } from '@shared/service/group';
import { CallType, Group } from '@shared/models';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-laundry-position',
  templateUrl: './laundry-position.component.html',
  styleUrls: ['./laundry-position.component.css'],
})
export class LaundryPositionComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  // Observable Subjects
  governorates$ = new BehaviorSubject<{ value: number; label: string }[]>([]);
  governoratesLoading$ = new BehaviorSubject<boolean>(false);
  areas$ = new BehaviorSubject<{ value: number; label: string }[]>([]);
  areasLoading$ = new BehaviorSubject<boolean>(false);
  groups$ = new BehaviorSubject<Group[]>([]);
  groupsLoading$ = new BehaviorSubject<boolean>(false);

  branches$ = new BehaviorSubject<any[]>([]); // new BehaviorSubject for branches

  // Other variables
  firstInit = true;
  selectedBranch: any;
  designType: string;
  selectedArea: any;
  selectedBranchPos: any;
  isLoading: boolean;
  loader = false;
  query = '';
  key = 'name';
  areaId: number;
  from: string;
  currentTab = 1;
  @ViewChild('updatePosition', { static: true })
  modal: ModalDirective;

  constructor(
    private readonly global: GlobalService,
    private readonly branchService: BranchesService,
    private readonly countryService: CountryService,
    private readonly toast: ToastService,
    private readonly groupService: GroupService,
  ) {}

  ngOnInit(): void {
    this.loadGovernorates();
    this.initGroups();
  }

  ngOnDestroy(): void {
    this.global.headers.group_id = '1';
    this.destroy$.next();
    this.destroy$.complete();

    // Complete the observables
    this.governorates$.complete();
    this.governoratesLoading$.complete();
    this.areas$.complete();
    this.areasLoading$.complete();
    this.groups$.complete();
    this.groupsLoading$.complete();
    this.branches$.complete(); // complete the branches$ Observable
  }

  selectTab(tab: number, groupId: number, designType: string): void {
    this.branches$.next([]); // reset the branches when tab is changed
    this.query = '';
    this.currentTab = tab;
    this.designType = designType;
    this.global.headers.group_id = groupId.toString();
    this.isLoading = true;
    if (designType === 'laundry') {
      this.getLaundryBranches(this.areaId, designType);
    } else if (designType === 'carwashGarage') {
      this.getCwgBranches(this.areaId, groupId);
    }
  }

  areaChanges(areaId: number): void {
    this.areaId = areaId;
    this.selectTab(
      this.currentTab || 1,
      +this.global.headers.group_id || 1,
      this.designType || 'laundry',
    );
  }

  governorateChanges(event: { label: string; value: number }): void {
    this.loadCityAreas(event.value);
  }

  changePosition(): void {
    this.loader = true;
    const branchPos = new BranchPosition();
    branchPos.toPosition = Number(this.selectedBranchPos);

    if (this.designType === 'laundry') {
      branchPos.fromPosition =
        this.selectedBranch?.laundry_branch_areas?.length > 0
          ? this.selectedBranch.laundry_branch_areas[0].sort_order
          : 0;
      branchPos.laundryBranchId =
        this.selectedBranch.laundry_branch_areas[0].laundry_branch_id;
    } else {
      branchPos.fromPosition =
        this.selectedBranch?.laundryBranchAreas?.length > 0
          ? this.selectedBranch.laundryBranchAreas[0].sortOrder
          : 0;
      branchPos.cwGarageBranchId =
        this.selectedBranch.laundryBranchAreas[0].cwGarageBranchId;
    }

    branchPos.areaId = this.areaId;
    this.branchService.changePosition(this.global.headers, branchPos).subscribe(
      (response: NewBackEndResponse) => {
        if (response.code === 200) {
          this.loader = false;
          this.modal.hide();
          this.areaChanges(this.areaId);
        }
        if (response.code > 200) {
          this.loader = false;
          this.toast.error(response.message);
        }
      },
      (error) => {
        this.loader = false;
      },
    );
  }

  openDialog(branch: BranchPerArea, designType: string): void {
    this.selectedBranch = branch;
    this.designType = designType;
    if (designType === 'laundry') {
      this.selectedBranchPos =
        this.selectedBranch?.laundry_branch_areas?.length > 0
          ? this.selectedBranch.laundry_branch_areas[0].sort_order
          : 0;
      this.from =
        this.selectedBranch?.laundry_branch_areas?.length > 0
          ? this.selectedBranch.laundry_branch_areas[0].sort_order.toString()
          : '0';
    } else {
      this.selectedBranchPos =
        this.selectedBranch?.laundryBranchAreas?.length > 0
          ? this.selectedBranch.laundryBranchAreas[0].sortOrder
          : 0;
      this.from =
        this.selectedBranch?.laundryBranchAreas?.length > 0
          ? this.selectedBranch.laundryBranchAreas[0].sortOrder.toString()
          : '0';
    }
    this.modal.show();
  }

  private getCwgBranches(areaId: number, groupId: number): void {
    this.branchService
      .getCwgBranchWise(this.global.headers, {
        groupId,
        page: 1,
        countryId: this.global.countryId,
        areaId: areaId,
      })
      .subscribe((response: NewBackEndResponse) => {
        this.branches$.next(response.data
          .filter((branch) => branch.laundryBranchAreas.length > 0)
          .sort((a, b) => {
            if (
              a.laundryBranchAreas.length > 0 &&
              b.laundryBranchAreas.length > 0
            ) {
              return (
                a.laundryBranchAreas[0].sortOrder -
                b.laundryBranchAreas[0].sortOrder
              );
            }
          }));
        this.isLoading = false;
      });
  }

  private getLaundryBranches(areaId: number, designType: string): void {
    this.branchService
      .getBranchWise(this.global.headers, {
        page: 'all',
        type: designType,
        area_id: areaId,
      })
      .subscribe((response: BackEndResponse) => {
        this.branches$.next((response.data as BranchPerArea[])
          .filter((branch) => branch.laundry_branch_areas.length > 0)
          .sort((a, b) => {
            if (
              a.laundry_branch_areas.length > 0 &&
              b.laundry_branch_areas.length > 0
            ) {
              return (
                a.laundry_branch_areas[0].sort_order -
                b.laundry_branch_areas[0].sort_order
              );
            }
          }));
        this.isLoading = false;
      });
  }

  private initGroups(): void {
    this.groupsLoading$.next(true);
    this.groupService
      .getGroups()
      .pipe(takeUntil(this.destroy$))
      .subscribe((groups: Group[]) => {
        const isGroup = (group: Group): boolean =>
          group.callType === CallType.OutCall &&
          ((group.designType === 'laundry') ||
            group.designType === 'carwashGarage');
        this.groups$.next(groups.filter(isGroup));
        this.groupsLoading$.next(false);
      });
  }

  private loadGovernorates(): void {
    this.governoratesLoading$.next(true);
    this.countryService
      .getAllListCities(this.global.headers)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.governorates$.next(
          data.map((governorate) => ({
            value: governorate.id,
            label: governorate.name.en,
          }))
        );
        this.governoratesLoading$.next(false);
      });
  }

  private loadCityAreas(governorateId: number): void {
    this.areasLoading$.next(true);
    this.branchService
      .getBranchAllAreas({
        countryId: this.global.countryId,
        cityId: governorateId,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((areas: BrArea[]) => {
        this.areas$.next(
          areas.map((area: any) => ({
            value: area.id,
            label: area.name.en,
          }))
        );
        this.areasLoading$.next(false);
      });
  }

  drop(event: CdkDragDrop<string[]>) {
    const branches = this.branches$.value;
    moveItemInArray(branches, event.previousIndex, event.currentIndex);

    // Update the sort_order for all branches in the array
    branches.forEach((branch, index) => {
      if (this.designType === 'laundry') {
        if (branch.laundry_branch_areas.length > 0) {
          branch.laundry_branch_areas[0].sort_order = index + 1;
        }
      } else {
        if (branch.laundryBranchAreas.length > 0) {
          branch.laundryBranchAreas[0].sortOrder = index + 1;
        }
      }
    });

    this.loader = true;

    // Prepare the new position for the moved item
    const branchPos = new BranchPosition();
    branchPos.toPosition = event.currentIndex + 1;

    const branch = branches[event.currentIndex];

    if (this.designType === 'laundry') {
      branchPos.fromPosition = branch?.laundry_branch_areas?.length > 0
        ? branch.laundry_branch_areas[0].sort_order
        : 0;
      branchPos.laundryBranchId = branch.laundry_branch_areas[0].laundry_branch_id;
    } else {
      branchPos.fromPosition = branch?.laundryBranchAreas?.length > 0
        ? branch.laundryBranchAreas[0].sortOrder
        : 0;
      branchPos.cwGarageBranchId = branch.laundryBranchAreas[0].cwGarageBranchId;
    }

    branchPos.areaId = this.areaId;

    // Send the updated order to the server
    this.branchService.changePosition(this.global.headers, branchPos).subscribe(
      (response: NewBackEndResponse) => {
        if (response.code === 200) {
          this.loader = false;
          this.toast.success("Position updated successfully");
        } else {
          // Handle error
          this.loader = false;
          this.toast.error(response.message);
        }
      },
      (error) => {
        this.loader = false;
        this.toast.error("Error updating position");
      }
    );

    // Update the branches
    this.branches$.next(branches);
  }



}
