import { map } from 'rxjs/operators';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OnlineProfileDescription,
  OnlineProfileImage,
  OnlineProfileStatus,
} from '@app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class OnlineProfileService {
  private readonly onlineProfileImagesUrl = `${environment.jcDeliveryServer}profiles/images`;
  private readonly onlineProfileDescriptionsUrl = `${environment.jcDeliveryServer}/profiles/pending-updates`;
  private readonly onlineProfileStatuseUrl = `${environment.jcDeliveryServer}profiles/status`;
  constructor(private readonly http: HttpClient) {}

  getOnlineProfileStatuses(): Observable<OnlineProfileStatus[]> {
    return this.http
      .get<ApiResponse<OnlineProfileStatus[]>>(this.onlineProfileStatuseUrl)
      .pipe(map(({ data }) => data));
  }

  getOnlineProfileImages(branchId: number): Observable<OnlineProfileImage[]> {
    return this.http
      .get<ApiResponse<OnlineProfileImage[]>>(this.onlineProfileImagesUrl, {
        params: { cwGarageBranchId: branchId.toString() },
      })
      .pipe(map(({ data }) => data));
  }

  /**
   * Method to load and return only one translated description
   */
  getOnlineProfileDescriptions(
    branchId: number,
  ): Observable<OnlineProfileDescription> {
    return this.http
      .get<ApiResponse<OnlineProfileDescription[]>>(
        this.onlineProfileDescriptionsUrl,
        {
          params: { cwGarageBranchId: branchId.toString() },
        },
      )
      .pipe(map(({ data }) => data[0]));
  }

  updateOnlineProfileImage(
    id: number,
    body: Partial<OnlineProfileImage>,
  ): Observable<OnlineProfileImage> {
    return this.http
      .patch<ApiResponse<OnlineProfileImage>>(
        `${this.onlineProfileImagesUrl}/${id}`,
        this.getCompleteBody(body),
      )
      .pipe(map(({ data }) => data));
  }

  updateOnlineProfileDescription(
    id: number,
    body: Partial<OnlineProfileDescription>,
  ): Observable<OnlineProfileDescription> {
    return this.http
      .patch<ApiResponse<OnlineProfileDescription>>(
        `${this.onlineProfileDescriptionsUrl}/${id}`,
        this.getCompleteBody(body),
      )
      .pipe(map(({ data }) => data));
  }

  private getCompleteBody(body: any): any {
    return { ...body, userAdminId: this.userAdminId };
  }

  private get userAdminId(): number {
    return +localStorage.getItem('userId');
  }
}
