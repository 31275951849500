import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CarwashStationListComponent } from './components/carwash-station-list/carwash-station-list.component';
import { CarwashStationRoutingModule } from '@app/modules/carwash/modules/carwash-station/carwash-station-routing.module';
import { SharedModule } from '@app/modules/carwash/shared/shared.module';
import { CarwashStationEditDialogComponent } from './components/carwash-station-edit-dialog/carwash-station-edit-dialog.component';
import { AssignStaffDialogComponent } from './components/assign-staff-dialog/assign-staff-dialog.component';

@NgModule({
  declarations: [
    CarwashStationListComponent,
    CarwashStationEditDialogComponent,
    AssignStaffDialogComponent,
  ],
  imports: [CommonModule, CarwashStationRoutingModule, SharedModule],
})
export class CarwashStationModule {}
