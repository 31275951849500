import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { BackEndResponse } from '../models/global/response';
import { tap } from 'rxjs/operators';
import { LogisticsCar } from '../models/cars/log-cars';

@Injectable({
  providedIn: 'root'
})
export class LogCarService {
  readonly URL_FETCH_CARS_LOG = environment.serverUrl + 'logistics/car-list';
  readonly URL_SAVE_UPDATE_CAR = environment.serverUrl + 'logistics/car';
  constructor(private http: HttpClient) {}

  getLogisticsCars(headers, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_FETCH_CARS_LOG, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params })
      })
      .pipe(tap(data => console.log(data)));
  }

  saveLogisticsCar(headers, car: LogisticsCar) {
    return this.http
      .post<BackEndResponse>(this.URL_SAVE_UPDATE_CAR, car, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  toggleStatusAndDelete(
    headers,
    carId: number,
    body?: { is_active?: boolean }
  ) {
    return this.http
      .patch<BackEndResponse>(this.URL_SAVE_UPDATE_CAR + '/' + carId, body, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }
}
