import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../../../shared/service/global.service';
import { OrderService } from '../../../../shared/service/order.service';
import {
  STATUSES,
  OrderDetails,
  Reason,
  SelectedReason,
  OrderCashPayment,
} from '../../../../shared/models/orders/order';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { OrderApproval } from 'src/app/shared/models/offers/offers';
import { ApiLibrary } from 'src/app/shared/helpers/http-params';
import { ArrayFunctions } from 'src/app/shared/helpers/array-functions';
import { EMPTY, Observable } from 'rxjs';
import {
  DriversBookingLogs,
  UnionDriversBookingLogs,
} from '@app/modules/drivers/shared/models';
import { map, tap } from 'rxjs/operators';
import { DriversBookingLogsService } from '@app/modules/drivers/shared/services/drivers-booking-logs.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
  groupId: string;
  logs: UnionDriversBookingLogs[] = [];
  currentPage = 1;
  isLoading = true;
  orderId: string;
  statuses: any[] = [];
  selectedStatus: number;
  previousStatus: number;
  order: OrderDetails;
  orderLogs$: Observable<any[]>;
  isFirstCall = true;
  showDialog: boolean;
  dataReady: boolean;
  showLaundry: boolean;
  currency: string;
  loader = false;
  confirmError = false;
  @ViewChild('confirmModal', { static: false })
  confirmModal: ModalDirective;
  @ViewChild('requestModal', { static: false })
  requestModal: ModalDirective;
  @ViewChild('commissionModal', { static: false })
  commissionModal: ModalDirective;
  error: string;
  approvalRequest = new OrderApproval();
  commissionRequest = {
    order_id: null,
    reason: '',
  };
  isPickUpCommission = true;
  decimal: number;
  isPartner: boolean;

  reasons: Reason[];
  selectedReason: SelectedReason;
  isConfirmModalOpen = false;

  // order pay cash
  payCashBody: OrderCashPayment;
  isPayCashOpened = false;
  initDate = false;
  commissionPartnerLoader = false;
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected globalService: GlobalService,
    protected orderService: OrderService,
    private toast: ToastService,
    private readonly driversBookingLogsService: DriversBookingLogsService,
  ) {}

  ngOnInit() {
    this.orderId = this.route.snapshot.params.orderId;
    this.getOrderDetails();
    this.loadOrderLogs();
    this.readStatuses();
    this.showDialog = false;
    this.currency = this.globalService.getCurrency();
    this.decimal = parseInt(this.globalService.fetchFromStorage('decimal'), 10);
    this.getReasons({
      type: 'cancel',
    });
    this.groupId = this.route.snapshot.queryParams.groupId;
    /**
     * Need to apply only for Laundry type.
     */
    if (this.groupId === '1') {
      this.loadDriverReasonsData$().subscribe();
    }
  }

  statusChanges(event) {
    this.selectedStatus = event;
    if (
      this.selectedStatus === 10 ||
      this.selectedStatus === 11 ||
      this.selectedStatus === 12 ||
      this.selectedStatus === 14
    ) {
      this.requestModal.show();
    } else {
      this.confirmModal.show();
    }
  }

  getDateFromString(date: string) {
    return this.globalService.getDateFromString(date);
  }

  getTimeFromString(date: string) {
    const hour = parseInt(this.globalService.getTimeFromString(date), null);
    return hour + ' H - ' + (hour + 1) + ' H';
  }

  dialogClosed(event) {
    this.showDialog = false;
    if (event) {
      this.getOrderDetails();
    }
  }

  openDialog() {
    this.showDialog = true;
  }

  openDialogLaundry(event) {
    this.showLaundry = true;
  }

  closeDialogLauindry(event) {
    this.showLaundry = false;
    if (event) {
      this.getOrderDetails();
    }
  }

  confirmAction() {
    this.confirmError = false;
    this.loader = true;
    this.orderService
      .changeStatus(
        this.globalService.headers,
        this.orderId,
        this.selectedStatus,
      )
      .subscribe(
        (data) => {
          this.loader = false;
          this.modalClosed();
        },
        (error) => {
          this.confirmError = true;
          this.loader = false;
          this.selectedStatus = this.selectedStatus;
          this.previousStatus = this.selectedStatus;
        },
      );
  }

  private readStatuses() {
    if (!this.order) {
      return false;
    }
    this.statuses = [];
    /*
    STATUSES.forEach((val, i) => {
        this.statuses.push({
          value: i + 1,
          label: val,
          selected: i + 1 === this.selectedStatus
        });
    });*/
    const orderStatus = this.order.order_result.statuses_user.id;

    switch (orderStatus) {
      case 1: {
        this.statuses.push(
          {
            value: 1,
            label: STATUSES[0],
            selected: true,
          },
          {
            value: 2,
            label: STATUSES[1],
          },
          {
            value: 4,
            label: STATUSES[3],
          },
        );
        break;
      }
      case 2: {
        this.statuses.push(
          {
            value: 2,
            label: STATUSES[1],
            selected: true,
          },
          {
            value: 4,
            label: STATUSES[3],
          },
        );
        break;
      }
      case 3: {
        this.statuses.push(
          {
            value: 2,
            label: STATUSES[1],
          },
          {
            value: 3,
            label: STATUSES[2],
            selected: true,
          },
          {
            value: 16,
            label: STATUSES[15],
            selected: true,
          },
        );
        break;
      }
      case 4: {
        this.statuses.push(
          {
            value: 2,
            label: STATUSES[1],
          },
          {
            value: 4,
            label: STATUSES[3],
            selected: true,
          },
        );
        break;
      }
      case 5: {
        {
          if (
            (this.order && this.order.order_result) ||
            (this.order.order_result.payment_methods &&
              this.order.order_result.payment_methods.length > 0 &&
              this.order.order_result.payment_methods.find(
                (e) => !this.isOnlinePayment(e.name),
              )
            ) ||
            !this.order.order_result.payment_methods ||
            this.order.order_result.payment_methods.length === 0
          ) {
            this.statuses.push({
              value: 4,
              label: STATUSES[3],
            });
          }
          this.statuses.push(
            {
              value: 5,
              label: STATUSES[4],
              selected: true,
            },
            {
              value: 8,
              label: STATUSES[7],
            },
            {
              value: 9,
              label: STATUSES[8],
            },
          );
        }
        break;
      }
      case 6: {
        this.statuses.push(
          {
            value: 6,
            label: STATUSES[5],
            selected: true,
          },
          {
            value: 8,
            label: STATUSES[7],
            selected: true,
          },
          {
            value: 9,
            label: STATUSES[8],
            selected: true,
          },
        );
        break;
      }
      case 7: {
        this.statuses.push(
          {
            value: 5,
            label: STATUSES[4],
          },
          {
            value: 7,
            label: STATUSES[6],
            selected: true,
          },
        );
        break;
      }
      case 8: {
        this.statuses.push(
          {
            value: 8,
            label: STATUSES[7],
            selected: true,
          },
          {
            value: 9,
            label: STATUSES[8],
          },
        );
        break;
      }
      case 9: {
        this.statuses.push({
          value: 9,
          label: STATUSES[8],
          selected: true,
        });
        break;
      }
      case 13: {
        this.statuses.push(
          {
            value: 13,
            label: STATUSES[12],
            selected: true,
          },
          {
            value: 15,
            label: STATUSES[14],
            selected: true,
          },
        );
        break;
      }
      case 14: {
        this.statuses.push({
          value: 14,
          label: STATUSES[13],
          selected: true,
        });
        break;
      }
      case 15: {
        this.statuses.push(
          {
            value: 13,
            label: STATUSES[12],
            selected: true,
          },
          {
            value: 15,
            label: STATUSES[14],
            selected: true,
          },
          {
            value: 14,
            label: STATUSES[13],
            selected: true,
          },
        );
        break;
      }
      case 16: {
        this.statuses.push({
          value: 16,
          label: STATUSES[15],
          selected: true,
        });
        break;
      }
    }
    if (
      this.order.order_result.statuses_user.id !== 8 &&
      this.order.order_result.statuses_user.id !== 6
    ) {
      this.statuses.push(
        {
          value: 10,
          label: STATUSES[9],
          selected: this.order.order_result.statuses_user.id === 10,
        },
        {
          value: 11,
          label: STATUSES[10],
          selected: this.order.order_result.statuses_user.id === 11,
        },
        {
          value: 12,
          label: STATUSES[11],
          selected: this.order.order_result.statuses_user.id === 12,
        },
      );
    }
  }

  modalClosed() {
    this.getOrderDetails();
    this.confirmModal.hide();
  }

  requestModalClosed() {
    this.getOrderDetails();
    this.requestModal.hide();
    this.selectedReason = null;
    this.approvalRequest.reason = null;
  }

  commissionModalClosed() {
    this.getOrderDetails();
    this.commissionModal.hide();
  }

  sendCommissionRequest() {
    this.commissionPartnerLoader = true;
    this.commissionRequest.order_id = this.order.order_result.id;
    this.orderService
      .requestCommission(this.globalService.headers, this.commissionRequest)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.commissionModalClosed();
            this.loader = false;
          } else {
            this.loader = false;
            this.toast.error(response.message);
          }
          this.commissionPartnerLoader = false;
        },
        (error) => {
          this.loader = false;
          this.commissionPartnerLoader = false;
        },
      );
  }

  sendRequest() {
    this.loader = true;
    this.approvalRequest.status_from_id =
      this.order.order_result.statuses_user.id;
    this.approvalRequest.status_to_id = this.selectedStatus;
    this.approvalRequest.order_id = this.order.order_result.id;
    this.approvalRequest.user_admin_from_id = parseInt(
      window.localStorage.getItem('userId'),
      10,
    );
    this.approvalRequest.reason_id = this.selectedReason.reason;
    this.approvalRequest.sub_reason_id = this.selectedReason.sub;
    this.approvalRequest =
      new ApiLibrary().cleanObjectFromComponentWithMinusValue(
        this.approvalRequest,
      );

    this.orderService
      .requestApproval(this.globalService.headers, this.approvalRequest)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.requestModalClosed();
            this.cancelRequestModalConfirmation();
            this.loader = false;
          } else {
            this.loader = false;
            this.selectedStatus = this.selectedStatus;
            this.previousStatus = this.selectedStatus;
            this.toast.error(response.message);
            this.isConfirmModalOpen = false;
          }
        },
        (error) => {
          this.loader = false;
          this.selectedStatus = this.selectedStatus;
          this.previousStatus = this.selectedStatus;
        },
      );
  }

  showReschedule() {
    const statusesThatCanBeRescheduled = [1, 2, 4, 5, 6, 14, 16];

    return (
      (this.order.order_result.statuses_user.id === 3 && this.isPartner) ||
      (statusesThatCanBeRescheduled.includes(
        this.order.order_result.statuses_user.id,
      ) &&
        this.order.order_result.type === 'marketplace')
    );
  }

  isDisabled() {
    return (
      this.order.order_result.statuses_user.id === 9 ||
      this.order.order_result.statuses_user.id === 10 ||
      this.order.order_result.statuses_user.id === 11 ||
      this.order.order_result.statuses_user.id === 12 ||
      this.isPending()
    );
  }

  isPending() {
    return this.order.order_result.statuses_user.pending;
  }

  showDiscounts(): string {
    if (this.order.discounts) {
      return this.order.discounts.discount_type === 'amount'
        ? this.order.discounts.discount_amount + ' ' + this.currency
        : this.order.discounts.discount_percentage + ' %';
    }
  }

  isPickupAfterDelivery(): boolean {
    return (
      this.order &&
      this.order.order_result &&
      this.globalService.isAfter(
        this.order.order_result.pickup_at.toString(),
        this.order.order_result.delivery_at.toString(),
      )
    );
  }

  disablePickupCommissionButton() {
    return (
      this.selectedStatus !== 3 ||
      this.order.order_result.is_customer_available === true ||
      this.order.order_result.is_customer_available === undefined ||
      this.order.order_result.statuses_user.pending
    );
  }
  disableDeliveryCommissionButton() {
    return (
      this.selectedStatus !== 15 ||
      this.order.order_result.is_customer_available === true ||
      this.order.order_result.is_customer_available === undefined ||
      this.order.order_result.statuses_user.pending
    );
  }

  waiveEnded(event) {
    this.getOrderDetails();
  }

  selectedReasonAndSub(event: SelectedReason) {
    this.selectedReason = event;
  }

  validateReasons() {
    return this.selectedReason && this.reasonHasSubs(this.selectedReason.reason)
      ? this.selectedReason.reason > 0 && this.selectedReason.sub > 0
      : this.selectedReason.reason > 0;
  }

  cancelRequestModalConfirmation() {
    this.isConfirmModalOpen = false;
  }

  isCompensateOrder() {
    return (
      this.order &&
      this.order.order_result &&
      [8, 9].includes(this.order.order_result.statuses_user.id) && // Completed or Delivered
      !this.order.order_result.waive &&
      !this.order.order_result.is_compensated &&
      this.order.order_result.type === 'marketplace'
    );
  }

  // pay cash methods

  payCashDataChanged(event: OrderCashPayment) {
    this.payCashBody = event;
  }

  openPayCashModal() {
    this.isPayCashOpened = true;
  }

  closePayCashModal() {
    this.isPayCashOpened = false;
  }

  savePaidCash() {
    this.loader = true;
    this.orderService
      .orderPaidCash(this.globalService.headers, this.payCashBody)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.loader = false;
            this.closePayCashModal();
            this.toast.success(response.message);
            this.getOrderDetails();
          } else {
            this.toast.error(response.message);
          }
        },
        (error) => {
          this.loader = false;
        },
      );
  }

  private reasonHasSubs(id: number) {
    const reasonIndex = ArrayFunctions.findElementInArray<Reason>(
      this.reasons,
      id,
      true,
      'id',
    );
    const reason = this.reasons[reasonIndex];
    return reason.sub_reasons && reason.sub_reasons.length > 0;
  }

  private getReasons(params?: any) {
    this.orderService
      .getAllReasons(this.globalService.headers, params)
      .subscribe((response) => {
        if (response.code === 200) {
          this.reasons = response.data;
        }
      });
  }

  private getOrderDetails(): void {
    this.isFirstCall = true;
    this.orderService
      .getOrderDetails(this.globalService.headers, this.orderId)
      .subscribe((data) => {
        this.order = data.data;
        this.selectedStatus = this.order.order_result.statuses_user.id || null;
        this.readStatuses();
        this.dataReady = true;
        this.previousStatus = this.selectedStatus;
        this.isPartner = this.order.order_result.laundry_branch.is_partner;
      });
  }

  private isOnlinePayment(payment: string): boolean {
    return ['knet', 'pay by ooredoo', 'mada', 'tap', 'credit card'].find((e) =>
      payment.includes(e),
    )
      ? true
      : false;
  }

  private loadDriverReasonsData$(): Observable<DriversBookingLogs[]> {
    return this.driversBookingLogsService
      .getBookingLogs({
        page: this.currentPage,
        sortOrder: 'DESC',
        orderId: this.orderId,
      })
      .pipe(
        tap((res: DriversBookingLogs[]) => {
          this.logs = [
            ...this.logs,
            ...res.map((log: DriversBookingLogs) => ({
              ...log,
              question$: log.driverQuestionId
                ? this.driversBookingLogsService.getDriverQuestionsById(
                    log.driverQuestionId,
                  )
                : EMPTY,
            })),
          ];
          this.isLoading = false;
        }),
      );
  }



  private loadOrderLogs(): void {
    this.orderLogs$ = this.orderService
      .getOrderLogs(this.globalService.headers, this.orderId, 1)
      .pipe(
        map((orderLogs: any[]) => {
          console.log('orderLogs: ', orderLogs);
          return orderLogs; // Now mapped with payment methods
        })
      );
  }
}
