import { NgModule } from '@angular/core';

import { CarWashPackageRoutingModule } from 'src/app/modules/carwash/modules/car-wash-packages/carwash-package-routing.module';
import { SharedModule } from '@app/modules/carwash/shared/shared.module';
import { CarWashPackageEditDialogComponent } from '@app/modules/carwash/modules/car-wash-packages/components/carwash-package-edit-dialog/carwash-package-edit-dialog.component';
import {
  CarWashPackageListComponent
} from "@app/modules/carwash/modules/car-wash-packages/components/carwash-package-list/carwash-package-list.component";
import {
  CarWashPackageFormComponent
} from "@app/modules/carwash/modules/car-wash-packages/components/carwash-package-edit-dialog/carwash-package-form/carwash-package-form.component";
@NgModule({
  imports: [CarWashPackageRoutingModule, SharedModule],
  declarations: [
    CarWashPackageListComponent,
    CarWashPackageFormComponent,
    CarWashPackageEditDialogComponent,
  ],
})
export class CarWashPackagesModule {}
