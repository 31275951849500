import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from '../../../../shared/service/global.service';
import { OrderService } from "../../../../shared/service/order.service";
import {
  PageInfo,
  SelectOptions
} from "@shared/models/global/response";
import {
  ActiveOrders,
  STATUSES,
  OrderFilter
} from "@shared/models/orders/order";
import { MDBDatePickerComponent, IMyOptions } from 'ng-uikit-pro-standard';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-all-orders',
  templateUrl: './all-orders.component.html',
  styleUrls: ['./all-orders.component.css']
})
export class AllOrdersComponent implements OnInit {
  @ViewChild('placedAt', { static: false }) placedAt: MDBDatePickerComponent;
  @ViewChild('pickupAt', { static: false }) pickupAt: MDBDatePickerComponent;
  @ViewChild('deliveryAt', { static: false }) deliveryAt: MDBDatePickerComponent;

  public constructor(
    public route: ActivatedRoute,
    private globalService: GlobalService,
    private orderService: OrderService
  ) {
    console.log('AllOrdersComponent')
    console.log('GlobalService', globalService)
    console.log('getNext7Days', globalService.getNext7Days())
    this.pickUpDeliveryDatesOptions = {
      disableSince: {
        day: new Date(this.globalService.getNext7Days().toString()).getDate(),
        month: new Date(this.globalService.getNext7Days().toString()).getMonth() + 1,
        year: new Date(this.globalService.getNext7Days().toString()).getFullYear()
      },
      disableUntil: {
        day: new Date(this.globalService.getLast2MonthDate().toString()).getDate(),
        month: new Date(this.globalService.getLast2MonthDate().toString()).getMonth() + 1,
        year: new Date(this.globalService.getLast2MonthDate().toString()).getFullYear()
      },
      closeAfterSelect: true
    };
  }
  pickUpDeliveryDatesOptions: IMyOptions
  pagination: PageInfo;
  statuses: SelectOptions<number>[];
  types: SelectOptions<string>[];
  selectedStatus: number;
  orders: ActiveOrders[];
  orderFilter: OrderFilter = new OrderFilter();


  groups: SelectOptions<number>[] = [
    {
      label: 'Laundry',
      value: 1
    },
    {
      label: 'Car Wash',
      value: 2
    },
    {
      label: 'Carpet',
      value: 3
    }
  ];
  groupId = 1;

  ngOnInit() {
    this.getAllOrders(1);
    this.filtersSelectData();
  }

  getAllOrders(page: number) {
    const params = {
      page: page,
      order_status: this.selectedStatus,
      customer_name: this.orderFilter.customerName,
      customer_mobile: this.orderFilter.customerMobile,
      driver_name: this.orderFilter.driverName,
      driver_mobile: this.orderFilter.driverMobile,
      order_id: this.orderFilter.orderNumber,
      placed_at: this.orderFilter.placed_at,
      updated_at: this.orderFilter.updatedAt,
      searchstr: this.orderFilter.searchstr,
      type: this.orderFilter.type,
      is_logistics: this.orderFilter.is_logistics,
      pickup_at: this.orderFilter.pickup_at,
      delivery_at: this.orderFilter.delivery_at,
      group_id: this.groupId
    };

    this.orderService
      .getAllOrrders(this.globalService.headers, params)
      .subscribe(response => {
        this.orders = response.data as ActiveOrders[];
        this.pagination = response.page_info;
      });
  }

  private filtersSelectData() {
    this.statuses = [];
    STATUSES.forEach((val, i) => {
      this.statuses.push({
        value: i + 1,
        label: val
      });
    });
    this.types = [
      {
        value: 'marketplace',
        label: 'Marketplace'
      },
      {
        value: 'corporate',
        label: 'Corporate'
      }
    ];
  }

  getDateTime(date: string) {
    return this.globalService.getDateTimeFromString(date);
  }

  getPage(event) {
    this.getAllOrders(event);
  }

  filter() {
    this.getAllOrders(this.pagination.current_page);
  }

  initFilters() {
    this.selectedStatus = null;
    this.orderFilter = new OrderFilter();
    this.groupId = 1;
    this.placedAt.clearDate();
    this.pickupAt.clearDate();
    this.deliveryAt.clearDate();
    this.getAllOrders(1);
  }

  toggleIsLogistics() {
    this.orderFilter.is_logistics = !this.orderFilter.is_logistics;
    console.log(this.orderFilter.is_logistics);
  }
}
