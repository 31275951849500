import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from '@shared/models/global/apiResponse';

import { environment } from '@env/environment';
import {
  CarwashOrder,
  CwGarageBranchTicket, CwgBranchTicketAddon
} from "@app/modules/carwash/modules/carwash-order/components/shared/models/carwash-order.model";

@Injectable({ providedIn: 'root' })
export class CarwashOrderHttpService {
  private readonly url = environment.jcDeliveryServer + 'orders-cw-garage';
  private readonly changeStatusUrl =
    environment.jcDeliveryServer + 'cw-garage-branch-tickets';
  private readonly addOnsUrl = environment.jcDeliveryServer + 'cwg-branch-ticket-addons';

  constructor(private readonly http: HttpClient) {}

  getOrders(
    params: Record<string, any> = null,
  ): Observable<ApiResponse<CarwashOrder[]>> {
    return this.http.get<ApiResponse<CarwashOrder[]>>(this.url, {
      params: {
        ...params,
        sortValue: 'DESC',
      },
    });
  }

  getOrderById(id): Observable<ApiResponse<CarwashOrder>> {
    return this.http.get<ApiResponse<CarwashOrder>>(`${this.url}/${id}`);
  }

  updateOrderStatus(
    id: number,
    statusCwGarageBranchTicketId: number,
    userAdminId: number,
    reasonId?: number,
    reason?: string,
  ): Observable<ApiResponse<CwGarageBranchTicket>> {
    const body = {
      statusCwGarageBranchTicketId,
      userAdminId,
      ...(reasonId ? { reasonId } : {}),
      ...(reason ? { reason } : {}),
    };
    return this.http.patch<ApiResponse<CwGarageBranchTicket>>(
      `${this.changeStatusUrl}/${id}`,
      body,
    );
  }

  getCwgBranchTicketAddons(
    params: Record<string, any> = null,
  ): Observable<ApiResponse<CwgBranchTicketAddon[]>> {
    return this.http.get<ApiResponse<CwgBranchTicketAddon[]>>(this.addOnsUrl, {
      params,
    });
  }
}
