import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { FormStatus } from '@shared/enums/form-status.enum';
import { CarwashCompany } from '@shared/models';
import { GlobalService } from '@shared/service/global.service';
import { CarWashAddOn } from "@shared/models/carwash/car-wash-add-on.model";
@Component({
  selector: 'app-car-wash-add-on-form',
  templateUrl: './car-wash-package-form.component.html',
  styleUrls: ['./car-wash-package-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarWashAddOnFormComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;

  @Input() carWashAddOn: CarWashAddOn;
  @Input() groupId: number;
  @Input() carWashAddonImageEn: string;
  @Input() carWashAddonImageAr: string;
  @Output() formChanged: EventEmitter<Partial<CarwashCompany>> =
    new EventEmitter<Partial<CarwashCompany>>();
  @Output() formStatusChanged: EventEmitter<FormStatus> =
    new EventEmitter<FormStatus>();
  @Output() changeCompanyLogoFile: EventEmitter<File> =
    new EventEmitter<File>();
  @Output() changeImageEnFile: EventEmitter<File> = new EventEmitter<File>();
  @Output() changeImageArFile: EventEmitter<File> = new EventEmitter<File>();

  private readonly destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly globalService: GlobalService,
  ) {}

  get carWashPackageImageEnUrl(): string {
    return this.form.get('image').get('en').value || this.carWashAddonImageEn;
  }

  get carWashPackageImageArUrl(): string {
    return (
      this.form.get('image').get('en').value || this.carWashAddonImageAr
    );
  }

  ngOnInit(): void {
    this.initForm();
    this.handleFormChanges();
    this.handleFormStatusChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateImageEnFile(file: File): void {
    this.changeImageEnFile.next(file);
  }
  updateImageArFile(file: File): void {
    this.changeImageArFile.next(file);
  }
  private initForm(): void {
    this.form = this.fb.group({
      name: this.fb.group({
        en: ['', [Validators.maxLength(25)]],
        ar: ['', [Validators.maxLength(25)]],
      }),
      description: this.fb.group({
        en: ['', [Validators.maxLength(250)]],
        ar: ['', [Validators.maxLength(250)]],
      }),
      image: this.fb.group({
        en: [''],
        ar: [''],
      }),
      timeToComplete: [''],
      // timeToComplete: ['', [Validators.required, Validators.min(0)]],
      groupId: this.groupId,
      countryId: this.globalService.countryId,
    });
    this.patchForm();
  }

  private patchForm(): void {
    if (!this.carWashAddOn) {
      return;
    }

    this.form.patchValue(this.carWashAddOn);
    this.formChanged.emit(this.form.value);
  }

  private handleFormChanges(): void {
    const emitValue = (value: Partial<CarwashCompany>) =>
      this.formChanged.emit(value);

    this.form.valueChanges
      .pipe(debounceTime(500), tap(emitValue), takeUntil(this.destroy$))
      .subscribe();
  }

  private handleFormStatusChanges(): void {
    const emitStatus = (value: FormStatus) =>
      this.formStatusChanged.emit(value);

    this.form.statusChanges
      .pipe(
        startWith(this.form.status),
        distinctUntilChanged(),
        tap(emitStatus),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
