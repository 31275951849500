import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  ViewChild,
  SimpleChanges, OnInit
} from "@angular/core";
import { ModalDirective } from 'ng-uikit-pro-standard';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import {
  CategoryToBeSaved,
  CategoryTranslation,
  CategoryList,
} from '../../../../shared/models/items/cats';
import { CategoriesService } from '../../../../shared/service/categories.service';
import { GlobalService } from '../../../../shared/service/global.service';
import { GroupService } from "@shared/service/group";
import { Group } from "@shared/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppHeaders } from "@shared/models/user/generic-backend";

@Component({
  selector: 'app-category-create',
  templateUrl: './category-create.component.html',
  styleUrls: ['./category-create.component.scss'],
})
export class CategoryCreateComponent implements OnInit, OnChanges {
  @Input() isShown: boolean;
  @Input() cat: CategoryList;
  @Input() catLength: number;
  @Output() isClosed: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('createCatModal', { static: true }) modal: ModalDirective;

  createCatForm: UntypedFormGroup;
  files: File;
  orderData: any[];
  loader: boolean;
  isUpdate: boolean;
  needsToUpdate: boolean;
  selectedGroup: Group;
  groups$: Observable<Array<Group>>;
  headers: AppHeaders = {};

  constructor(
    private catService: CategoriesService,
    private globalService: GlobalService,
    private groupsService: GroupService,
  ) {}

  ngOnInit(): void {
    this.loadLaundryGroups();
    this.headers = this.globalService.headers;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.needsToUpdate = false;
    this.loader = false;
    this.files = new File([''], 'Upload your icon(*)');
    this.createCatForm = new UntypedFormGroup({
      nameAr: new UntypedFormControl(null, [Validators.required]),
      nameEn: new UntypedFormControl(null, Validators.required),
      categoryType: new UntypedFormControl('multiple', Validators.required),
    });
    this.isUpdate = false;
    if (this.isShown) {
      this.modal.show();
    }

    this.createCatForm.valueChanges.subscribe((data) => {
      console.log(data);

      if (this.files.size <= 0 || !this.cat.image) {
        this.createCatForm.setErrors({ orderFile: true });
      }
    });
    if (changes.cat && changes.cat.currentValue) {
      this.cat = changes.cat.currentValue;
      this.isUpdate = true;
      this.getCatToBeUpdated();
    }
  }

  onHidden() {
    this.isClosed.emit(true);
  }

  saveCategory() {
    this.loader = true;
    const cat = new CategoryToBeSaved();
    cat.category_translations = Array.of(
      new CategoryTranslation(),
      new CategoryTranslation(),
    );
    cat.category_translations[0].name =
      this.createCatForm.controls.nameEn.value;
    cat.category_translations[1].name =
      this.createCatForm.controls.nameAr.value;
    cat.category_translations[0].language_id = 1;
    cat.category_translations[1].language_id = 2;
    cat.selection_type = this.createCatForm.controls.categoryType.value;
    // if its un update
    if (this.isUpdate) {
      cat.id = this.cat.id;
      // if the file icon wasnt updated
      if (this.files.name.includes('http')) {
        cat.image = this.files.name;
        this.catService.updateCategory(this.headers, cat).subscribe(
          (response) => {
            if (response.code === 200) {
              // this.router.navigate(['dashboard/items/category-list']);
              this.refreshData.emit(true);
              this.loader = false;
              this.modal.hide();
            }
          },
          (error) => {
            this.loader = false;
            console.log(error);
          },
        );
      } else {
        this.catService.imageUpload(this.files, 'Categories').subscribe(
          (data) => {
            if (data.code === 200) {
              cat.image = data.data;
              this.catService.updateCategory(this.headers, cat).subscribe(
                (response) => {
                  if (response.code === 200) {
                    this.loader = false;
                    this.modal.hide();
                    this.refreshData.emit(true);
                  }
                },
                (error) => {
                  this.loader = false;
                  console.log(error);
                },
              );
            }
          },
          (error) => {
            this.loader = false;
            console.log(error);
          },
        );
      }
    } else {
      this.catService.imageUpload(this.files, 'Categories').subscribe(
        (data) => {
          if (data.code === 200) {
            cat.image = data.data;
            this.catService.saveCategory(this.headers, cat).subscribe(
              (response) => {
                if (response.code === 200) {
                  this.loader = false;
                  this.modal.hide();
                  this.refreshData.emit(true);
                }
              },
              (error) => {
                this.loader = false;
                console.log(error);
              },
            );
          }
        },
        (error) => {
          this.loader = false;
          console.log(error);
        },
      );
    }
  }

  getCatToBeUpdated() {
    this.createCatForm.controls.nameEn.setValue(this.cat.name.en);
    this.createCatForm.controls.nameAr.setValue(this.cat.name.ar);
    this.createCatForm.controls.categoryType.setValue(this.cat.selection_type);
    this.files = new File([this.cat.image], this.cat.image);
  }
  // fileUpload functions

  showFiles() {
    if (this.files) {
      return this.files.name;
    }
  }

  loadFile(event): void {
    this.files = event.target.files[0];
    this.createCatForm.setErrors(null);
    this.showFiles();
    console.log(this.files);
  }

  loadLaundryGroups(): void {
    this.groups$ = this.groupsService.getGroups().pipe(
      map((groups) => {
        if (this.selectedGroup) {
          /** This to bind with groups dropdown */
          this.selectedGroup = groups.find(
            ({ id }) => id === this.selectedGroup.id,
          );
        } else {
          // assign the first group by default
          this.selectedGroup = groups.find(() => true);
        }
        return groups.filter((group) =>
          this.groupsService.checkLaundryDesign(group),
        );
      }),
    );
  }

  setSelectedGroup(): void {
    this.headers.group_id = this.selectedGroup.id.toString();
  }
}
