import {
  Component,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy, OnInit
} from "@angular/core";
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ItemsService } from "@shared/service/items.service";
import { CategoriesService } from '../../../../shared/service/categories.service';
import { GlobalService } from '../../../../shared/service/global.service';
import { Category, CategoryList } from '../../../../shared/models/items/cats';
import {
  ItemToBeSaved,
  ItemFromListItem, CategoryInItem
} from "../../../../shared/models/items/items";
import { BehaviorSubject, Observable, zip } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Group } from "@shared/models";
import { GroupService } from "@shared/service/group";
import { AppHeaders } from "@shared/models/user/generic-backend";

@Component({
  selector: 'app-item-create',
  templateUrl: './item-create.component.html',
  styleUrls: ['./item-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemCreateComponent implements OnInit, OnChanges {
  @Input() isShown: boolean;
  @Input() item: ItemFromListItem;
  @Input() isUpdate: boolean;
  @Input() selectedGroup: Group;
  @Output() isClosed: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('createItemModal', { static: true }) modal: ModalDirective;
  createItemForm: UntypedFormGroup;
  files: File;
  arabicFiles: File;
  categoryData: any[];
  selectedCatId: number;
  selectedCategory: Category;
  taxable: boolean;
  categories: CategoryList[];
  linkedCategory: Category[];
  addtags: any[];
  itemToBeSaved: ItemToBeSaved = new ItemToBeSaved();
  loader: boolean;
  groups$: Observable<Array<Group>>;
  private headers: AppHeaders;
  private isLoadingGroupsSubject = new BehaviorSubject<boolean>(false);
  isLoadingGroups$ = this.isLoadingGroupsSubject.asObservable();

  private isLoadingCategoriesSubject = new BehaviorSubject<boolean>(false);
  isLoadingCategories$ = this.isLoadingCategoriesSubject.asObservable();

  constructor(
    private itemsService: ItemsService,
    private categoriesService: CategoriesService,
    private globalService: GlobalService,
    private toast: ToastService,
    private groupsService: GroupService,
  ) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadLaundryGroups();
    this.headers = this.globalService.headers;
    this.loader = false;
    this.addtags = [];
    this.linkedCategory = [];
    this.taxable = false;
    this.files = new File([''], 'Upload your English Icon(*)');
    this.arabicFiles = new File([''], 'Upload your Arabic Icon(*)');
    this.createItemForm = new UntypedFormGroup({
      nameAr: new UntypedFormControl(null, [Validators.required]),
      nameEn: new UntypedFormControl(null, Validators.required),
      descEn: new UntypedFormControl(null, Validators.required),
      descAr: new UntypedFormControl(null, Validators.required),
      imgAlt: new UntypedFormControl(),
      imgSeo: new UntypedFormControl(),
      taxable: new UntypedFormControl(),
      cat: new UntypedFormControl(),
    });
    this.getCategories();
    this.isShown = changes.isShown?.currentValue;
    if (this.isShown) {
      this.modal.show();
    }
    this.createItemForm.valueChanges.subscribe((data) => {
      if (this.files.size <= 0) {
        this.createItemForm.setErrors({ orderFile: true });
      }
      if (this.linkedCategory.length === 0) {
        this.createItemForm.setErrors({ linkedCat: true });
      }
    });

    if (changes.item && changes.item.currentValue) {
      this.item = changes.item.currentValue;
      if (this.isUpdate) {
        this.getItemToBeUpdated(this.item);
      }
    }
  }

  categoryChanges(event) {
    this.selectedCategory = null;
    const categories: Category[] = JSON.parse(JSON.stringify(this.categories));
    this.selectedCategory = categories.find((category: Category): boolean => category.id === event);
  }

  assignCategoryToItems() {
    if (this.linkedCategory && this.linkedCategory.length === 0) {
      this.linkedCategory.push(this.selectedCategory);
    } else {
      const exist = this.linkedCategory.find(
        (category: Category) => category.id === this.selectedCategory.id
      );
      if (!exist) {
        this.linkedCategory.push(this.selectedCategory);
      }
    }
    if (this.linkedCategory.length > 0 && this.files.size > 0) {
      this.createItemForm.setErrors(null);
    }
  }

  deleteLinkedCategory(id: number) {
    this.linkedCategory = this.linkedCategory.filter((cat) => cat.id !== id);
    if (this.linkedCategory.length <= 0) {
      this.createItemForm.setErrors({ linkedCat: true });
    }
    console.log(this.linkedCategory);
  }

  onHidden() {
    this.linkedCategory = [];
    this.selectedCatId = null;
    this.createItemForm.reset();
    this.item = null;
    this.isClosed.emit(true);
  }

  toggleTaxable() {
    this.taxable = !this.taxable;
  }

  getItemToBeUpdated(item: ItemFromListItem) {
    console.error('item: ', this.item)
    this.createItemForm.controls.nameEn.setValue(item.name.en.name);
    this.createItemForm.controls.nameAr.setValue(item.name.ar.name);
    this.createItemForm.controls.descEn.setValue(item.name.en.description);
    this.createItemForm.controls.descAr.setValue(item.name.ar.description);
    this.createItemForm.controls.taxable.setValue(item.is_taxable);
    this.createItemForm.controls.imgAlt.setValue(item.alt_tag);
    this.createItemForm.controls.imgSeo.setValue(item.image);

    console.error('itemCategories: ', this.item.categories)
    this.item.categories.forEach((category: CategoryInItem) => {
      this.linkedCategory.push(this.getCategoryById(category.id));
    });
    console.error('itemCategories: ', this.item.categories)
    this.files = new File([item.name.en.image], item.name.en.image);
    this.arabicFiles = new File([item.name.ar.image], item.name.ar.image);
  }

  getCategoryById(id: number): Category {
    const categories: Category[] = JSON.parse(JSON.stringify(this.categories)) as Category[];
    return categories.find((category: Category) => category.id === id);
  }

  saveItem() {
    this.loader = true;
    this.itemToBeSaved.alt_tag = this.createItemForm.controls.imgAlt.value;
    this.itemToBeSaved.image_seo = this.createItemForm.controls.imgSeo.value;
    this.itemToBeSaved.is_taxable = this.taxable;
    this.itemToBeSaved.item_translations[0] = {
      language_id: 1,
      name: this.createItemForm.controls.nameEn.value,
      description: this.createItemForm.controls.descEn.value,
      image: null,
    };
    this.itemToBeSaved.item_translations[1] = {
      language_id: 2,
      name: this.createItemForm.controls.nameAr.value,
      description: this.createItemForm.controls.descAr.value,
      image: null,
    };
    this.itemToBeSaved.category_array = [];
    this.linkedCategory.forEach((cat) => {
      this.itemToBeSaved.category_array.push({
        cat_id: cat.id,
        is_deleted: false,
      });
    });
    console.log(this.itemToBeSaved);
    if (this.isUpdate) {
      this.itemToBeSaved.id = this.item.id;
      if (
        this.files.name.includes('http') &&
        this.arabicFiles.name.includes('http')
      ) {
        this.itemToBeSaved.item_translations[0].image = this.files.name;
        this.itemToBeSaved.item_translations[1].image = this.arabicFiles.name;
        this.itemsService
          .updateItem(this.headers, this.itemToBeSaved)
          .subscribe(
            (response) => {
              if (response.code === 200) {
                this.loader = false;
                this.modal.hide();
                this.refreshData.emit(true);
              } else {
                this.toast.error(response.message);
                this.loader = false;
              }
            },
            (error) => {
              this.loader = false;
            }
          );
      } else {
        zip(
          this.categoriesService.imageUpload(this.files, 'Items'),
          this.categoriesService.imageUpload(this.arabicFiles, 'Items')
        ).subscribe(
          (data) => {
            if (data[0].code === 200 && data[1].code === 200) {
              this.itemToBeSaved.item_translations[0].image = data[0].data;
              this.itemToBeSaved.item_translations[1].image = data[1].data;
              this.itemsService
                .updateItem(this.globalService.headers, this.itemToBeSaved)
                .subscribe(
                  (response) => {
                    if (response.code === 200) {
                      this.loader = false;
                      this.modal.hide();
                      this.refreshData.emit(true);
                    } else {
                      this.toast.error(response.message);
                      this.loader = false;
                    }
                  },
                  (error) => {
                    this.loader = false;
                  }
                );
            } else {
              this.toast.error(
                data[0].code !== 200 ? data[0].message : data[1].message
              );
              this.loader = false;
            }
          },
          (error) => {
            this.loader = false;
          }
        );
      }
    } else {
      zip(
        this.categoriesService.imageUpload(this.files, 'Items'),
        this.categoriesService.imageUpload(this.arabicFiles, 'Items')
      ).subscribe(
        (data) => {
          if (data[0].code === 200 && data[1].code === 200) {
            this.itemToBeSaved.item_translations[0].image = data[0].data;
            this.itemToBeSaved.item_translations[1].image = data[1].data;
            this.itemsService
              .saveItem(this.globalService.headers, this.itemToBeSaved)
              .subscribe(
                (response) => {
                  if (response.code === 200) {
                    this.loader = false;
                    this.modal.hide();
                    this.refreshData.emit(true);
                  } else {
                    this.toast.error(response.message);
                    this.loader = false;
                  }
                },
                (error) => {
                  this.loader = false;
                }
              );
          } else {
            this.toast.error(
              data[0].code !== 200 ? data[0].message : data[1].message
            );
            this.loader = false;
          }
        },
        (error) => {
          this.loader = false;
        }
      );
    }
  }
  // fileUpload functions

  showFiles() {
    if (this.files) {
      return this.files.name;
    }
  }

  loadFile(event): void {
    this.files = event.target.files[0];
    if (this.files && this.files.size > 0 && this.linkedCategory.length > 0) {
      this.createItemForm.setErrors(null);
    } else {
      this.createItemForm.setErrors({ orderFile: true });
    }
    this.showFiles();
  }
  //
  // fileUpload functions

  showFilesArabic() {
    if (this.arabicFiles) {
      return this.arabicFiles.name;
    }
  }

  loadFileArabic(event): void {
    this.arabicFiles = event.target.files[0];
    if (
      this.arabicFiles &&
      this.arabicFiles.size > 0 &&
      this.linkedCategory.length > 0
    ) {
      this.createItemForm.setErrors(null);
    } else {
      this.createItemForm.setErrors({ orderFile: true });
    }
    this.showFilesArabic();
  }


  getCategories() {

    console.error('hello?: ')
    this.isLoadingCategoriesSubject.next(true);
    this.categoriesService
      .getCategories(this.headers, 0)
      .pipe(
        map(response=> {
          return response.data;
        }),
        catchError(err => {
          this.isLoadingCategoriesSubject.next(false);
        console.log('Error on fetching Categories')
        return err;
      }))
      .subscribe((response) => {
        this.categoryData = [];
        this.isLoadingCategoriesSubject.next(false);
        this.categories = response as CategoryList[];
        this.categories.forEach((category: CategoryList) => {
          console.log('getCategory: ', category)
          this.categoryData.push({ value: category.id, label: category.name.en });
        });

      });
  }

  loadLaundryGroups(): void {
    this.isLoadingGroupsSubject.next(true);
    this.groups$ = this.groupsService.getGroups().pipe(
      map((groups) => {
        if (this.selectedGroup) {
          this.selectedGroup = groups.find(
            ({ id }) => id === this.selectedGroup.id,
          );
        } else {
            this.selectedGroup = groups.find(() => true);
        }

        this.setGroup();
        this.isLoadingGroupsSubject.next(false);

        return groups.filter((group) =>
          this.groupsService.checkLaundryDesign(group),
        );
      }),
    );
  }

  setGroup(): void {
    this.headers.group_id = this.selectedGroup.id.toString();
    this.getCategories()
  }

}
