import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { UserSegmentHttpService } from '@app/shared/service/user-segment/user-segment-http.service';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { UserSegment } from '@shared/models';
import { PaginationHelper } from '@app/shared/helpers/pagination-helper';
import { PageInfo } from '@app/shared/models/global/response';

@Injectable({ providedIn: 'root' })
export class UserSegmentService {
  readonly pagination$: Subject<PageInfo> = new Subject<PageInfo>();

  constructor(
    private readonly userSegmentHttpService: UserSegmentHttpService,
  ) {}

  getAllUserSegments(filters?: any): Observable<UserSegment[]> {
    return this.userSegmentHttpService.getAllUserSegments(filters);
  }

  getUserSegments(filters?: Record<string, string>): Observable<UserSegment[]> {
    const toData = (res: ApiResponse<UserSegment[]>) => res.data;

    const toPagination = (res: ApiResponse<UserSegment[]>) =>
      res.pageInfo &&
      this.pagination$.next(PaginationHelper.camelToSnakeCase(res.pageInfo));

    return this.userSegmentHttpService
      .getUserSegments(filters)
      .pipe(tap(toPagination), map(toData));
  }

  getSegmentById(id: number): Observable<UserSegment> {
    return this.userSegmentHttpService.getSegmentById(id);
  }

  createUserSegment(data: Partial<UserSegment>): Observable<UserSegment> {
    const toUserSegment = (res: ApiResponse<UserSegment>) => res.data;

    return this.userSegmentHttpService
      .createUserSegment(data)
      .pipe(map(toUserSegment));
  }

  updateUserSegment(
    id: number,
    data: Partial<UserSegment>,
  ): Observable<UserSegment> {
    const toUserSegment = (res: ApiResponse<UserSegment>) => res.data;

    return this.userSegmentHttpService
      .updateUserSegment(id, data)
      .pipe(map(toUserSegment));
  }
}
