/* tslint:disable:one-line */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CarwashBranchReservationOptions } from '@app/modules/carwash/modules/carwash-branch/shared/enums';

@Component({
  selector: 'app-carwash-branch-reservation-options',
  templateUrl: './carwash-branch-reservation-options.component.html',
  styleUrls: ['./carwash-branch-reservation-options.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarwashBranchReservationOptionsComponent
  implements OnInit, OnDestroy
{
  @Input() option: CarwashBranchReservationOptions;
  @Output() change: EventEmitter<CarwashBranchReservationOptions> =
    new EventEmitter<CarwashBranchReservationOptions>();

  form: UntypedFormGroup;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private readonly fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.initForm();
    this.handleForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initForm(): void {
    const both: boolean = this.option === CarwashBranchReservationOptions.both;
    const available: boolean =
      this.option === CarwashBranchReservationOptions.available || both;
    const notAvailable: boolean =
      this.option === CarwashBranchReservationOptions.notAvailable || both;

    this.form = this.fb.group({
      available,
      notAvailable,
    });
  }

  private handleForm(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      let option: CarwashBranchReservationOptions;

      if (value.available && value.notAvailable) {
        option = CarwashBranchReservationOptions.both;
      } else if (value.available) {
        option = CarwashBranchReservationOptions.available;
      } else {
        option = CarwashBranchReservationOptions.notAvailable;
      }

      this.change.emit(option);
    });
  }
}
