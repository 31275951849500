import { Component, OnInit } from '@angular/core';
import {
  Router,
  ActivatedRoute
} from '../../../../../../../node_modules/@angular/router';
import { GlobalService } from '../../../../../shared/service/global.service';
import { BranchesService } from '../../../../../shared/service/branches.service';
import { PaymentService } from '../../../../../shared/service/payment.service';
import { PaymentFromList } from '../../../../../shared/models/payment/payment';
import {
  BranchToBeUpdated,
  SpecialIcons,
  BranchSettings
} from '../../../../../shared/models/laundries/branch';

@Component({
  selector: 'app-branch-settings',
  templateUrl: './branch-settings.component.html',
  styleUrls: ['./branch-settings.component.css']
})
export class BranchSettingsComponent implements OnInit {
  leather = false;
  alter = false;
  shoes = false;
  laundryBag = false;
  jcPremium = 0;
  deliveryCharge = 0;
  deliveryService = 0;
  minOrderValue = 0;
  premiumTurnAroundValue = 0;
  fastTurnAroundValue = 0;
  normalTurnAroundValue = 0;
  paymentMethods: PaymentFromList[] = [];
  paymentList: number[] = [];
  loader = false;
  branchId: number;
  laundryId: number;
  currency: string;
  isPos = false;
  hasTracking = true;
  hasLogistics = false;
  termsAndConditionAr: string;
  termsAndConditionEn: string;
  isFastService: boolean;
  isUpdate: boolean;
  specialIcons: SpecialIcons[];
  specialIconsToBeSaved: number[];
  type: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected globalService: GlobalService,
    protected branchService: BranchesService,
    protected paymentService: PaymentService,
  ) {}

  ngOnInit() {
    this.branchId = this.route.snapshot.params.branchId;
    this.laundryId = this.route.parent.snapshot.params.id;
    this.isUpdate = this.route.snapshot.params.update === 'true' ? true : false;
    this.getPaymentMethods();
    this.currency = this.globalService.getCurrency();
    this.getSpecialIcons();
    if (this.isUpdate) {
      this.getBranchInfoForUpdate(this.branchId);
    }
  }

  getPaymentMethods() {
    this.paymentService
      .getPaymentMethod(this.globalService.headers)
      .subscribe(response => {
        if (response.code === 200) {
          this.paymentMethods = response.data as PaymentFromList[];
          this.paymentMethods.forEach(element => {
            if (element.default && !this.isUpdate) {
              this.addToList(element.id);
            }
          });
        }
      });
  }

  addToList(payId: number) {
    if (!this.elementExists(payId)) {
      this.paymentList.push(payId);
    } else {
      this.deleteElement(payId);
    }
    console.log(this.paymentList);
  }

  elementExists(payId: number) {
    const pay = this.paymentList.find(element => element === payId);
    return pay;
  }

  deleteElement(payment: number) {
    this.paymentList.forEach((element, i) => {
      if (element === payment) {
        this.paymentList.splice(i, 1);
      }
    });
  }

  togglePos() {
    this.isPos = !this.isPos;
    if (!this.isPos) {
      this.resetPosTc();
    }
  }

  resetPosTc() {
    this.termsAndConditionAr = this.termsAndConditionEn = null;
  }

  posPayment() {
    return this.paymentMethods.filter(
      payment => payment.is_pos_payment_method === true
    );
  }

  marketPlacepayment() {
    return this.paymentMethods.filter(
      payment => payment.is_user_payment_method === true
    );
  }

  saveBranchSettings() {
    console.log(this.paymentList);
    this.loader = true;
    const settings = {
      laundry_branch_id: this.branchId,
      special_service_leather: this.leather,
      special_service_alteration: this.alter,
      special_service_shoes: this.shoes,
      just_premium: this.jcPremium,
      delivery_charge: this.deliveryCharge,
      delivery_service: this.deliveryService,
      min_order_amount: this.minOrderValue,
      fast_turn_around: this.fastTurnAroundValue,
      normal_turn_around: this.normalTurnAroundValue,
      premium_turn_around: this.premiumTurnAroundValue,
      payment_method: this.paymentList,
      has_tracking: this.hasTracking,
      is_logistics: this.hasLogistics,
      has_pos_status: this.isPos,
      has_fast_service: this.isFastService,
      laundry_bag: this.laundryBag,
      translations: [
        {
          language_id: 1,
          content: this.termsAndConditionEn
        },
        {
          language_id: 2,
          content: this.termsAndConditionAr
        }
      ],
      branch_special_icon: this.specialIconsToBeSaved,
      type:  this.type
    } as BranchSettings;
    this.branchService
      .createLaundrySettings(this.globalService.headers, settings)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.loader = false;
            this.router.navigate([
              'dashboard/' +
                window.localStorage.getItem('module') +
                '/branch-list/' +
                this.laundryId
            ]);
          }
        },
        error => {
          this.loader = false;
        },
        () => (this.loader = false)
      );
  }

  private getBranchInfoForUpdate(id: number) {
    this.branchService
      .getBranchInfo(this.globalService.headers, id)
      .subscribe(response => {
        if (response.code === 200) {
          const branchInfo = response.data as BranchToBeUpdated;
          this.leather = branchInfo.spcl_service_leather;
          this.shoes = branchInfo.spcl_service_shoes;
          this.alter = branchInfo.spcl_service_alteration;
          this.deliveryCharge = branchInfo.delivery_charge;
          this.minOrderValue = branchInfo.min_order_amount;
          this.deliveryService = branchInfo.delivery_service;
          this.fastTurnAroundValue = branchInfo.fast_turn_around;
          this.normalTurnAroundValue = branchInfo.normal_turn_around;
          this.premiumTurnAroundValue = branchInfo.premium_turn_around;
          this.paymentList = branchInfo.payment_methods;
          this.isPos = branchInfo.has_pos_status;
          this.hasTracking = branchInfo.has_tracking;
          this.isFastService = branchInfo.has_fast_service;
          this.termsAndConditionAr = branchInfo.translations.ar;
          this.termsAndConditionEn = branchInfo.translations.en;
          this.laundryBag = branchInfo.laundry_bag;
          this.specialIconsToBeSaved = branchInfo.special_branch_icons
            ? branchInfo.special_branch_icons
            : [];
          this.type = branchInfo.type;
          console.log(this.specialIconsToBeSaved);
        }
      });
  }

  checkDisable(payment: PaymentFromList) {
    return payment.name.toLocaleLowerCase().includes('gift') ||
      payment.name.toLocaleLowerCase().includes('cashback') || payment.name.toLocaleLowerCase().includes('waive')
      ? true
      : null;
  }

  getSpecialIcons() {
    this.branchService
      .getSpecialIcons(this.globalService.headers)
      .subscribe(response => {
        if (response.code === 200) {
          this.specialIcons = response.data as SpecialIcons[];
        }
      });
  }

  toggleSpecial(id: number) {
    if (!this.specialExists(id) && this.specialIconsToBeSaved) {
      this.specialIconsToBeSaved.push(id);
    } else {
      this.specialIconsToBeSaved.splice(
        this.specialIconsToBeSaved.indexOf(id),
        1
      );
    }
    console.log(id, this.specialIconsToBeSaved);

  }

  specialExists(id: number) {
    return !!this.specialIconsToBeSaved &&
      this.specialIconsToBeSaved.find(e => e === id)
      ? true
      : false;
  }
}
