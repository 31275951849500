// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  overflow: scroll;
  height: 400px;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/order/components/order-details/waived-order/waived-order.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;AACf","sourcesContent":[".content {\n  overflow: scroll;\n  height: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
