import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsSuperAdminGuard } from '@app/shared/guards';
import { CrudListComponent } from './components/crud-list/crud-list.component';
import { GeneratedPageListComponent } from './components/generated-pages/generated-page-list/generated-page-list.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'page-generator',
    pathMatch: 'full',
  },
  {
    path: 'page-generator',
    component: CrudListComponent,
    canActivate: [IsSuperAdminGuard],
    data: { title: 'Page generator' },
  },
  {
    path: 'page-generator',
    component: CrudListComponent,
    data: { title: 'Page generator' },
  },
  {
    path: 'page/:pageName',
    component: GeneratedPageListComponent,
    data: { title: 'Dynamic list' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CrudsRoutingModule {}
