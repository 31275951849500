import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { ReferralSetting } from '@shared/models';

@Injectable({ providedIn: 'root' })
export class ReferralSettingHttpService {
  private readonly url = `${environment.jcDeliveryServer}users/{id}/referral-settings`;

  constructor(private readonly http: HttpClient) {}

  getReferralSetting(
    userId: number,
    countryId: number,
  ): Observable<ApiResponse<ReferralSetting>> {
    return this.http.get<ApiResponse<ReferralSetting>>(
      this.url.replace('{id}', userId.toString()),
      {
        params: { countryId: countryId.toString() },
      },
    );
  }

  updateReferralSetting(
    userId: number,
    data: Partial<ReferralSetting>,
  ): Observable<ApiResponse<ReferralSetting>> {
    return this.http.patch<ApiResponse<ReferralSetting>>(
      this.url.replace('{id}', userId.toString()),
      data,
    );
  }
}
