import { Component, OnInit, ViewChild } from '@angular/core';
import { CountryService } from '../../../../../shared/service/country.service';
import { GlobalService } from '../../../../../shared/service/global.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  AreaList,
  AreaToBeSaved,
} from '../../../../../shared/models/countries/country';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { NgForm } from '@angular/forms';
import { CategoriesService } from 'src/app/shared/service/categories.service';

@Component({
  selector: 'app-areas-list',
  templateUrl: './areas-list.component.html',
  styleUrls: ['./areas-list.component.css'],
})
export class AreasListComponent implements OnInit {
  dataReady: boolean;
  areas: AreaList[];
  area: AreaToBeSaved;
  @ViewChild('addArea', { static: true }) modal: ModalDirective;
  @ViewChild('createArea', { static: true }) form: NgForm;
  govId: number;
  loader: boolean;
  file: File;
  fileError: {
    error: string;
    success: string;
  };
  loaderFile = false;
  constructor(
    protected countryService: CountryService,
    protected global: GlobalService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected toast: ToastService,
    private catservice: CategoriesService
  ) {}
  ngOnInit() {
    this.govId = this.route.snapshot.params.govId;
    this.getAreas();
    this.initForm();
  }

  getAreas() {
    this.countryService
      .getListCitiesWithAreas(this.global.headers, this.govId)
      .subscribe((response) => {
        if (response.code === 200) {
          let areas = response.data.areas as any[];
          console.log(areas);

          areas = areas.sort(
            (a, b) => a.name.en.toLowerCase() - b.name.en.toLowerCase()
          );
        }

        this.areas = response.code === 200 ? response.data.areas : [];
        this.dataReady = true;
      });
  }

  saveArea() {
    this.loader = true;
    this.area.name = this.area.translations[0].name;
    this.countryService.saveArea(this.global.headers, this.area).subscribe(
      (response) => {
        if (response.code === 200) {
          this.loader = false;
          this.modal.hide();
          this.getAreas();
          this.toast.success(response.message);
        } else {
          this.loader = false;
          this.toast.error(response.message);
        }
      },
      (error) => {
        this.loader = false;
      }
    );
  }

  initForm() {
    this.area = new AreaToBeSaved();
    this.area.country_id = parseInt(this.global.headers.country_id, 10);
    this.area.translations = [];
    this.area.translations[0] = {
      name: '',
      language_id: 1,
    };
    this.area.translations[1] = {
      name: '',
      language_id: 2,
    };
    this.area.latitude = '0.000000';
    this.area.longitude = '0.00000';
    this.area.country_with_area_flag = null;
    this.area.name = '';
    this.area.governorate_id = this.govId;
    this.form.reset();
    this.area.polylines = null;
    console.log(this.area.country_with_area_flag);
    this.file = new File([''], 'Upload Document(*)', {
      type: 'KML'
    });
  }

  close() {
    console.log('closed');

    this.initForm();
  }

  loadSplashEnglish(event): void {
    this.fileError = {
      error: null,
      success: null,
    };
    this.file = event.target.files[0];
    console.log(this.file);
    if (
      (this.file.type.toLowerCase().includes('image') &&
        this.file.size / 1024 <= 200) ||
      (this.file.type.toLowerCase().includes('pdf') &&
        this.file.size / 1024 <= 2048) ||
      (this.file.type.toLowerCase() === '' && this.file.size / 1024 <= 2048)
    ) {
      this.loaderFile = true;
      this.global['headersImage']['governorate_id'] = this.govId;
      this.global['headersImage']['Access-Control-Allow-Origin'] = '*';
      this.global.headersImage.group_id = '1';
      this.catservice
        .imageUpload(this.file, 'kml')
        .subscribe(
          (response) => {
            if (response.code === 200) {
              this.fileError.error = null;
              this.fileError.success = response.message;
              this.loaderFile = false;
              this.area.polylines = response.data;
            } else {
              this.fileError = {
                error: response.message,
                success: null,
              };
              this.file = new File([''], 'Upload New Document/KML');
              this.loaderFile = false;
              this.toast.error(response.message);
            }
          },
          (error) => {
            this.file = new File([''], 'Upload New Document/KML');
            this.fileError = {
              error: error.error.message,
              success: null,
            };
            this.loaderFile = false;
          }
        );
    } else {
      this.fileError = {
        error: this.file.type.toLowerCase().includes('image')
          ? 'file size must be less or equals 200kb '
          : 'file size must be less or equals 2mb',
        success: null,
      };
      console.log(this.fileError);
    }
  }

  removeFile() {
    this.file = new File([''], 'Upload New Document/KML');
    this.fileError = null;
    this.area.polylines = null;
  }
}
