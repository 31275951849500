import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { LaundryUserFromList } from '@shared/models/user/laundry-user';
import { GlobalService } from '@shared/service/global.service';
import { BackEndResponse } from '@shared/models/global/response';
import { AppHeaders } from '@shared/models/user/generic-backend';
import { BranchesService } from '@shared/service/branches.service';
import { GroupService } from '@shared/service/group';
import { forkJoin, Observable, switchMap } from 'rxjs';
import { Group } from '@shared/models';
import { Status } from '@shared/enums/status.enum';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-user-assign-branch',
  templateUrl: './user-assign-branch.component.html',
  styleUrls: ['./user-assign-branch.component.scss'],
})
export class UserAssignBranchComponent implements OnInit {
  headers: AppHeaders = {};
  groupId: number;
  laundries = [];
  branches = [];
  allGroups: { value: number; label: string }[] = [];
  assignedBranches: { value: number; label: string }[] = [];
  selectedLaundry: number[] = [];
  selectedBranches: number[] = [];
  selectedGroup: number[] = [];
  lang: string = 'en';
  isDisableSaveBtn: boolean = false;
  isBranchesLoading: boolean = true;
  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: LaundryUserFromList,
    private readonly dialogRef: DialogRef<UserAssignBranchComponent>,
    private readonly globalService: GlobalService,
    private readonly branchService: BranchesService,
    private readonly groupService: GroupService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.headers = this.globalService.headers;
  }
  ngOnInit(): void {
    if (!this.data.laundry_id) return;
    const branchesInfo: Observable<BackEndResponse> =
      this.branchService.getLaundryBranchesUserJlm(this.headers, this.data.id);

    const groupAction: Observable<Group[]> = this.groupService.getGroups();
    forkJoin([groupAction, branchesInfo])
      .pipe(
        tap(([groups, branches]) => {
          const br = (this.branches = branches.data.map((b) => {
            return { value: b.laundryBranch.id, label: b.laundryBranch.name };
          }));
          this.branches = br;
          this.assignedBranches = br;
          this.selectedBranches = br.map((b) => b.value);
          this.groupId = branches?.data[0]?.laundry?.groupId || 1;
          this.selectedLaundry = [this.data.laundry_id];

          this.allGroups = groups.map((g: Group) => ({
            label: g.name[this.lang],
            value: g.id,
          }));
          this.selectedGroup = this.allGroups
            .filter((g) => g.value === this.groupId)
            .map((g) => g.value);
          this.headers.group_id = this.groupId.toString();
          const laundry: { name: string; id: number } = this.data.laundry;
          this.laundries = [
            { value: this.data.laundry_id, label: laundry?.name || '' },
          ];
        }),
        switchMap(() => this.applyUnAssignBranches([this.data.laundry_id])),
      )
      .subscribe((res: BackEndResponse[]) => {
        const branches: any[] = res.map((r: BackEndResponse) => r.data).flat();

        const bids = this.branches.map((b) => b.value);
        const unassignedBranches: any[] = branches.filter(
          (b) => !bids.includes(b.id),
        );

        this.branches = [
          ...this.branches,
          ...unassignedBranches.map((a) => ({
            value: a.id,
            label: a.name,
          })),
        ];
        this.isBranchesLoading = false;
        this.cdr.detectChanges();
      });
  }

  close(): void {
    this.dialogRef.close();
  }

  applyUnAssignBranches(laundriesIds: number[]): Observable<BackEndResponse[]> {
    const branchesActions: Observable<BackEndResponse>[] = laundriesIds.map(
      (id: number) => {
        return this.branchService.getLaundryBranchesByLaundryId(this.headers, {
          status: Status.Active,
          laundryId: id.toString(),
        });
      },
    );

    return forkJoin(branchesActions);
  }

  saveConfiguration(): void {
    this.isDisableSaveBtn = true;
    const assignedBranchesIds: number[] = this.assignedBranches.map(
      (asb: { value: number; label: string }) => asb.value,
    );
    this.assignedBranches.forEach(
      (asb: { value: number; label: string }): void => {
        if (!this.selectedBranches.includes(asb.value)) {
          this.branchService
            .unAssignBranch(this.headers, {
              laundryBranchId: asb.value,
              laundryId: this.data.laundry_id,
              userJlmId: this.data.id,
            })
            .subscribe();
        }
      },
    );
    this.selectedBranches.forEach((slb: number): void => {
      if (!assignedBranchesIds.includes(slb)) {
        this.branchService
          .assignBranch(this.headers, {
            laundryBranchId: slb,
            laundryId: this.data.laundry_id,
            userJlmId: this.data.id,
          })
          .subscribe();
      }
    });

    this.close();
  }
}
