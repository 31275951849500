// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrap-control {
  display: inline-block;
}
.wrap-control input {
  width: 50px;
  height: 20px;
  display: inline-block;
}
.wrap-control .button-control {
  display: inline-block;
  margin-top: 2px;
  vertical-align: top;
  border: 1px solid #000000;
  border-radius: 2px;
  padding: 0 3px;
  margin-left: 2px;
  height: 20px;
  width: 20px;
}
.wrap-control .button-control.minus {
  border: 1px solid #ff0000;
}
.wrap-control .button-control.plus {
  border: 1px solid #00c851;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/crud/shared/action-components/working-hours/working-hours-price-input/working-hours-price-input.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,qBAAA;AACJ;AAEE;EACE,qBAAA;EACA,eAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,cAAA;EACA,gBAAA;EACA,YAAA;EACA,WAAA;AAAJ;AAEI;EACE,yBAAA;AAAN;AAGI;EACE,yBAAA;AADN","sourcesContent":[".wrap-control {\n  display: inline-block;\n\n  input {\n    width: 50px;\n    height: 20px;\n    display: inline-block;\n  }\n\n  .button-control {\n    display: inline-block;\n    margin-top: 2px;\n    vertical-align: top;\n    border: 1px solid #000000;\n    border-radius: 2px;\n    padding: 0 3px;\n    margin-left: 2px;\n    height: 20px;\n    width: 20px;\n\n    &.minus {\n      border: 1px solid #ff0000;\n    }\n\n    &.plus {\n      border: 1px solid #00c851;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
