import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrderListComponent } from './components/order-list/order-list.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { AllOrdersComponent } from './components/all-orders/all-orders.component';
import { ApprovalComponent } from './components/approval/approval.component';
import { CompensateOrderComponent } from './components/compensate-order/compensate-order.component';
import { OrderCancelRequestsComponent } from './components/order-cancel-requests/order-cancel-requests.component';

const routes: Routes = [
  {
    path: 'orders-list',
    component: OrderListComponent,
    data: {
      title: 'Pending Order List'
    }
  },
  {
    path: 'orders-all',
    component: AllOrdersComponent,
    data: {
      title: 'List of All Orders'
    }
  },
  {
    path: 'compensate-order/:orderId',
    component: CompensateOrderComponent,
    data: {
      title: 'Compensate Order'
    }
  },
  {
    path: 'order-details/:orderId',
    component: OrderDetailsComponent,
    data: {
      title: 'Order details'
    }
  },
  {
    path: 'approval',
    component: ApprovalComponent,
    data: {
      title: 'Approval'
    }
  },
  {
    path: 'cancel-requests',
    component: OrderCancelRequestsComponent,
    data: {
      title: 'Order Cancellation Request'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrderRoutingModule {}
