import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '../../../../../../../node_modules/@angular/router';
import { GlobalService } from '../../../../../shared/service/global.service';
import { BranchesService } from '../../../../../shared/service/branches.service';
import {
  BranchTimeSlotNew,
  Times,
  DayTimeSLot,
  UniqTimeSlot,
} from '../../../../../shared/models/laundries/branch';
import {
  ToastService,
  IMyOptions,
  MDBDatePickerComponent,
  IMyDateRange,
  IMyMarkedDates,
} from 'ng-uikit-pro-standard';
import { NgForm } from '@angular/forms';
import { delay } from 'rxjs/operators';
import {
  SpecialDatesFromList,
  SpecialDateToBeSaved,
  DefaultSpecialTimeSlot,
  SpecialDateTimeSlot,
} from 'src/app/shared/models/laundries/branch-special-timeslots';
import { PageInfo } from 'src/app/shared/models/global/response';
interface Params {
  laundry_branch_id: number;
  page: number;
}

@Component({
  selector: 'app-branch-timeslots',
  templateUrl: './branch-timeslots.component.html',
  styleUrls: ['./branch-timeslots.component.scss'],
})
export class BranchTimeslotsComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private branchService: BranchesService,
    private toast: ToastService
  ) {}
  allTimeSlots: BranchTimeSlotNew;
  selectedDate: DayTimeSLot;
  days: DayTimeSLot[];
  loader = false;
  branchId: number;
  laundryId: number;
  isUpdate: boolean;
  validationArray = [];
  // special calendar
  specialDatesList: SpecialDatesFromList[];
  pageInfo: PageInfo;
  params: Params;
  @ViewChild('capacityForm', { static: false }) myForm: NgForm;

  // modals
  isDateModalOpened = false;
  isTimeSlotModalOpened = false;
  // date pickers
  startDateOption: IMyOptions;
  endDateOption: IMyOptions;
  @ViewChild('from', { static: false }) from: MDBDatePickerComponent;
  @ViewChild('to', { static: false }) to: MDBDatePickerComponent;
  disabledRanges: IMyDateRange[];
  disabledRangesString: { begin: string; end: string }[];
  markedDatesDisabled: IMyMarkedDates[];
  specialDateToBeSaved: SpecialDateToBeSaved;
  showDatesError = false;
  // special timeslots
  defaultSpecialTimeSlots: DefaultSpecialTimeSlot[];
  ngOnInit() {
    this.branchId = this.route.snapshot.params.branchId;
    this.laundryId = this.route.parent.snapshot.params.id;
    this.isUpdate = this.route.snapshot.params.update === 'true' ? true : false;
    this.params = {
      laundry_branch_id: this.branchId,
      page: 1,
    };
    this.getDays();
    this.getDates(this.params);
  }

  getAllTimeSlots(dayId: number) {
    this.branchService
      .getBranchTimeSlotPerDay(this.globalService.headers, this.branchId, dayId)
      .subscribe((response) => {
        if (response.code === 200) {
          this.allTimeSlots = response.data[0];
          this.allTimeSlots.times.forEach((time) => {
            time.previous_capacity = time.default_capacity;
          });
        }
      });
  }

  selectedDay(day: DayTimeSLot) {
    this.selectedDate = day;
    this.getAllTimeSlots(day.id);
  }

  isSelected(day) {
    return this.selectedDate.id === day.id;
  }

  selectSlot(timeSlot: Times) {
    timeSlot.is_selected = !timeSlot.is_selected;
    if (!timeSlot.is_selected) {
      this.saveBranchTimeSlot(timeSlot);
    }
  }

  formatTime(date: string) {
    return this.globalService.getHours(date);
  }

  isBusy(timeSlot: Times) {
    timeSlot.is_busy = !timeSlot.is_busy;
    this.saveBranchTimeSlot(timeSlot);
  }

  saveBranchTimeSlot(timeSlot: Times) {
    this.loader = true;
    const branchTimeSlot = new UniqTimeSlot();
    branchTimeSlot.laundry_branch_id = this.branchId;
    branchTimeSlot.timeslot_time_id = timeSlot.id;
    branchTimeSlot.is_busy = timeSlot.is_busy;
    branchTimeSlot.is_selected = timeSlot.is_selected;
    branchTimeSlot.default_capacity = timeSlot.default_capacity;
    branchTimeSlot.remaining_capacity = timeSlot.remaining_capacity;
    branchTimeSlot.previous_capacity = timeSlot.previous_capacity;
    const subscriber = this.branchService
      .saveUniqTimeSlot(this.globalService.headers, branchTimeSlot)
      .pipe(delay(1000));

    subscriber.subscribe(
      (response) => {
        if (response.code === 200) {
          this.getAllTimeSlots(this.selectedDate.id);
          this.loader = false;
        }
      },
      (error) => {
        this.getAllTimeSlots(this.selectedDate.id);
        this.loader = false;
      }
    );
  }

  setComparison(timeSlot: Times, i: number) {
    this.validationArray = [];

    if (timeSlot.default_capacity != null) {
      this.saveBranchTimeSlot(timeSlot);
    }
  }

  goBack() {
    this.router.navigate([
      'dashboard/' +
        window.localStorage.getItem('module') +
        '/branch-list/' +
        this.laundryId,
    ]);
  }

  // special calendar

  getPage(event) {
    this.params.page = event;
    this.getDates(this.params);
  }

  changeStatus(date: SpecialDatesFromList) {
    this.branchService
      .toggleStatusSpecialDate(this.globalService.headers, {
        id: date.id,
        status: !date.is_active,
      })
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.getDates(this.params);
            this.toast.success(response.message);
          } else {
            this.toast.error(response.message);
          }
        },
        (error) => {
          this.getDates(this.params);
        }
      );
  }

  // modals

  openDateModal() {
    this.isDateModalOpened = true;
    this.getSpecialTimeSlotsForManipulation();
  }

  closeDateModal(event) {
    this.isDateModalOpened = false;
    this.from.clearDate();
    this.to.clearDate();
    this.getPage(this.pageInfo.current_page);
  }

  openTimeSlotModal() {
    this.isTimeSlotModalOpened = true;
  }

  closeTimeSlotModal(event) {
    this.isTimeSlotModalOpened = false;
  }

  // date selection

  fromDateSelected(event) {
    if (event.epoc) {
      this.specialDateToBeSaved.start_date = event.actualDateFormatted;
      const date = this.globalService
        .formatDate(
          this.globalService.dateMinusDays(event.actualDateFormatted, 1).toString(),
          'YYYY-MM-DD'
        ).toString()
        .split('-');
      const datePlusSixtyDays = this.globalService
        .formatDate(
          this.globalService
            .dateAddition(event.actualDateFormatted, 60, 'days')
            .toString(),
          'YYYY-MM-DD'
        )
        .split('-');

      this.endDateOption = {
        disableUntil: {
          year: parseInt(date[0], 10),
          month: parseInt(date[1], 10),
          day: parseInt(date[2], 10),
        },
        disableSince: {
          year: parseInt(datePlusSixtyDays[0], 10),
          month: parseInt(datePlusSixtyDays[1], 10),
          day: parseInt(datePlusSixtyDays[2], 10),
        },
        disableDateRanges: this.startDateOption.disableDateRanges,
      };
    }
  }

  toDateSelected(event) {
    if (event.epoc) {
      this.specialDateToBeSaved.end_date = event.actualDateFormatted;
      this.showDatesError = this.isBetweenDisabledDates() ? true : false;
    }
  }

  toggleBusy(defaultTimeSlot: DefaultSpecialTimeSlot) {
    defaultTimeSlot.is_busy = !defaultTimeSlot.is_busy;
  }

  findOneBusySlotAtLeast() {
    return this.defaultSpecialTimeSlots.find((e) => e.is_busy);
  }

  saveSpecialDate() {
    this.loader = true;
    this.specialDateToBeSaved.timeslots = this.defaultSpecialTimeSlots.map(
      (e) => {
        return {
          capacity: e.capacity,
          default_capacity: e.default_capacity,
          is_busy: e.is_busy,
          time: e.start,
        } as SpecialDateTimeSlot;
      }
    );
    this.branchService
      .saveBranchSpecialDate(
        this.globalService.headers,
        this.specialDateToBeSaved
      )
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.closeTimeSlotModal(true);
            this.closeTimeSlotModal(true);
            this.closeDateModal(true);
          } else {
            this.toast.error(response.message);
          }
          this.loader = false;
        },
        (error) => {
          this.loader = false;
        }
      );
  }

  formatTimeZone(time: string) {
    return this.globalService.getTimeWithTimeZone(time);
  }

  private initDatesOptions(range: IMyDateRange[]) {
    const date = this.globalService.formatDate(
      this.globalService.datePlusDays(undefined, 0).toString(),
      'YYYY-MM-DD'
    );
    this.startDateOption = {
      disableUntil: {
        year: parseInt(date.split('-')[0], 10),
        month: parseInt(date.split('-')[1], 10),
        day: parseInt(date.split('-')[2], 10),
      },
      disableDateRanges: range,
    };
  }

  private isBetweenDisabledDates() {
    return this.disabledRangesString.find(
      (e) =>
        this.globalService.dateIsBetween(
          e.begin,
          this.specialDateToBeSaved.start_date,
          this.specialDateToBeSaved.end_date
        ) ||
        this.globalService.dateIsBetween(
          e.end,
          this.specialDateToBeSaved.start_date,
          this.specialDateToBeSaved.end_date
        )
    );
  }

  private initSpecialDateToBeSaved() {
    this.specialDateToBeSaved = {
      description: null,
      end_date: null,
      start_date: null,
      laundry_branch_id: this.branchId,
      name: null,
      timeslots: null,
    };
  }

  // backend fetch data
  private getDays() {
    this.branchService
      .getBranchDaysTimeSlot(this.globalService.headers)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.days = response.data;
            this.selectedDate = this.days[0];
            this.getAllTimeSlots(this.selectedDate.id);
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }

  // special date private functions

  private getDates(params: Params) {
    this.branchService
      .getBranchSpecialTIme(this.globalService.headers, this.params)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.initSpecialDateToBeSaved();
            this.specialDatesList = response.data;
            this.disabledRanges = [];
            this.markedDatesDisabled = [];
            this.disabledRangesString = [];
            this.specialDatesList.forEach((e) => {
              if (e.is_active) {
                const dates = e.date.split('-');
                this.disabledRanges.push({
                  begin: {
                    year: parseInt(this.globalService.formatDate(dates[0], 'YYYY'), 10),
                    month: parseInt(this.globalService.formatDate(dates[1], 'MM'), 10),
                    day: parseInt(this.globalService.formatDate(dates[2], 'DD'), 10)
                  },
                  end: {
                    year: parseInt(this.globalService.formatDate(dates[0], 'YYYY'), 10),
                    month: parseInt(this.globalService.formatDate(dates[1], 'MM'), 10),
                    day: parseInt(this.globalService.formatDate(dates[2], 'DD'), 10)
                  },
                });
                this.disabledRangesString.push({
                  begin: dates[0],
                  end: dates[1],
                });
              }
            });
            console.log(this.disabledRangesString);

            this.initDatesOptions(this.disabledRanges);

            this.pageInfo = response.page_info;
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }

  private getSpecialTimeSlotsForManipulation() {
    this.branchService
      .getDefaultTimeSlotsForSpecialDate(this.globalService.headers)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.defaultSpecialTimeSlots = response.data;
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }
}
