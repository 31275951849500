import { ToastService } from 'ng-uikit-pro-standard';
import { Observable, Subject } from 'rxjs';

import { DataExchangeWithBackend } from '../models/user/generic-backend';
import { BackEndResponse } from '../models/global/response';

export class DataManager {
  public static generateList<U, T extends BackEndResponse>(
    list: U[],
    data: T,
    toaster: ToastService,
    isSelect: boolean,
    dataSubject?: Subject<DataExchangeWithBackend>,
    value?: string,
    label?: string
  ) {
    if (data.code === 200) {
      list = [];
      if (isSelect) {
        list = this.fillListWithDataForSelect(list, data, value, label);
      } else {
        list = data.data;
      }
      dataSubject.next({
        list: list,
        isDataReady: true,
        pagination: data.page_info
      });
    } else {
      this.errorHandling(toaster, data.message);
    }
  }

  public static errorHandling(toaster: ToastService, message: string) {
    toaster.error(message);
  }

  public static subscribeToAnObservable<T extends BackEndResponse, U>(
    data: Observable<T>,
    list: U[],
    toaster: ToastService,
    isSelect: boolean,
    dataSubject?: Subject<DataExchangeWithBackend>,
    value?: string,
    label?: string
  ) {
    data.subscribe(
      success => {
        this.generateList(
          list,
          success,
          toaster,
          isSelect,
          dataSubject,
          value,
          label
        );
      }
    );
  }

  private static fillListWithDataForSelect<T extends BackEndResponse>(
    list,
    data: T,
    value: string,
    label: string
  ) {
    data.data.forEach(element => {
      list.push({
        value: element[value],
        label: element[label]
      });
    });
    return list;
  }
}
