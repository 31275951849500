import { Component, OnInit, ViewChild } from '@angular/core';
import { CountryService } from '../../../../../shared/service/country.service';
import { GlobalService } from '../../../../../shared/service/global.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  GovernorateList,
  GovernorateToBeSaved,
  CountryList
} from '../../../../../shared/models/countries/country';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { NgForm } from '@angular/forms';
import { PageInfo } from 'src/app/shared/models/global/response';

@Component({
  selector: 'app-cities-list',
  templateUrl: './cities-list.component.html',
  styleUrls: ['./cities-list.component.css']
})
export class CitiesListComponent implements OnInit {
  dataReady: boolean;
  cities: GovernorateList[];
  city: GovernorateToBeSaved;
  loader: boolean;
  countries: any[];
  selectedCountry: number;
  @ViewChild('addCity', { static: true })
  modal: ModalDirective;
  @ViewChild('createCity', { static: true })
  form: NgForm;
  countryId: number;
  pageInfo: PageInfo;
  constructor(
    protected countryService: CountryService,
    protected global: GlobalService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected toast: ToastService
  ) {}

  ngOnInit() {
    this.countryId = this.route.snapshot.params.countryId;
    this.getGovernorates({ page: 1 });
    this.initForm();
  }

  getGovernorates(params: any) {
    this.countryService.getCountries(this.global.headers).subscribe(resp => {
      const countries = resp.data as CountryList[];
      this.countries = [];
      countries.forEach(e => {
        this.countries.push({
          value: e.id,
          label: e.name.en
        });
      });
      this.countryService.getListCities(this.global.headers, params).subscribe(
        response => {
          this.cities = response.code === 200 ? response.data : [];
          this.pageInfo = response.page_info;
          console.log(this.countryId);

          this.cities = this.cities.filter(
            e => e.country_id.toString() === this.countryId.toString()
          );
          console.log(this.cities);

          this.dataReady = true;
        },
        error => {
          alert(error);
        }
      );
    });
  }

  saveCity() {
    this.loader = true;
    this.city.country_id = this.selectedCountry;
    this.countryService
      .saveGovernorate(this.global.headers, this.city)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.loader = false;

            this.modal.hide();
            this.getGovernorates({ page: this.pageInfo.current_page });
            this.toast.success(response.message);
          } else {
            this.loader = false;
            this.toast.error(response.message);
          }
        },
        error => {
          this.loader = false;
        }
      );
  }

  initForm() {
    this.city = new GovernorateToBeSaved();
    this.city.country_id = parseInt(this.global.headers.country_id, 10);
    this.city.translations = [];
    this.city.translations[0] = {
      name: '',
      language_id: 1
    };
    this.city.translations[1] = {
      name: '',
      language_id: 2
    };
    this.city.latitude = '0.000000';
    this.city.longitude = '0.00000';
    this.form.reset();
  }
  getPage(event) {
    this.getGovernorates({ page: event });
  }
  close() {
    console.log('closed');

    this.initForm();
  }
}
