import { AppUser } from './../../../../../src/app/shared/models/user/app-user';

export class LocalStoreService {
  static getLocalStoreItem(): AppUser {
    return JSON.parse(localStorage.getItem('appUser'));
  }

  static setLocalStoreItem(itemKey: string, itemValue: string): void {
    localStorage.setItem(itemKey, itemValue);
  }

  static removeLocalStoreItem(itemKey: string): void {
    localStorage.removeItem(itemKey);
  }

  static clearLocalStore(): void {
    localStorage.clear();
  }

  static getItems<T>(key: string): T {
    return JSON.parse(localStorage.getItem(key)) as T;
  }

  static setItems(key: string, value: unknown): void {
    localStorage.setItem(key, JSON.stringify(value));
  }
}

export enum LOCAL_STORAGE_KEYS {
  STATUSES = 'statuses',
  HEADER_PARAMS = 'header_params',
  PAYMENT_METHODS = 'payment_methods',
  ACTIVE_ORDER_TAB = 'order_active_tab',
  ROLES = 'roles',
  BRANCHES = 'branches',
  GOVERNORATE = 'governorate',
  AREAS = 'areas',
  USER_PERMISSIONS = 'user_permissions',
  BROWSER_FINGER_PRINT = 'browser_fingerPrint',
  LANGUAGE = 'language',
}
