// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host::ng-deep .mdb-select-outline .mdb-select-value {
  padding: 0.375rem 0.75rem;
}

.small-loader {
  position: absolute;
  right: -3rem;
}

.countries-container {
  min-width: 30rem;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/variant/components/assign-country-dialog/assign-country-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[":host::ng-deep .mdb-select-outline .mdb-select-value {\n  padding: 0.375rem 0.75rem;\n}\n\n.small-loader {\n  position: absolute;\n  right: -3rem;\n}\n\n.countries-container {\n  min-width: 30rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
