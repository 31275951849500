import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  CarWashAddOnPricingListComponent
} from "@app/modules/carwash/modules/car-wash-addons-pricing/components/car-wash-add-on-pricing-list/car-wash-add-on-pricing-list.component";

const routes: Routes = [
  {
    path: 'add-on-pricing/:id/list',
    component: CarWashAddOnPricingListComponent,
    data: { title: 'Addon Branch Pricing' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CarWashAddOnPricingRoutingModule {}
