// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-assign-branch-modal {
  max-width: inherit;
  min-width: 500px;
  width: 80vw;
  height: 70vh;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/users/components/user-assign-branch/user-assign-branch.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;AACF","sourcesContent":[".user-assign-branch-modal {\n  max-width: inherit;\n  min-width: 500px;\n  width: 80vw;\n  height: 70vh;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
