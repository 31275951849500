import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { UserSegmentsRoutingModule } from './user-segment-routing.module';
import { UserSegmentListComponent } from './components/user-segment-list/user-segment-list.component';
import { UserSegmentEditDialogComponent } from './components/user-segment-edit-dialog/user-segment-edit-dialog.component';
import { UserSegmentFormComponent } from './components/user-segment-edit-dialog/user-segment-form/user-segment-form.component';

@NgModule({
  declarations: [
    UserSegmentListComponent,
    UserSegmentEditDialogComponent,
    UserSegmentFormComponent,
  ],
  imports: [SharedModule, UserSegmentsRoutingModule],
})
export class UserSegmentsModule {}
