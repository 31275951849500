import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';

import { CarwashTicketService } from '@app/modules/carwash/modules/carwash-packages/shared/services/carwash-ticket.service';
import { DialogService } from '@shared/components/dialog/services/dialog.service';
import { CarwashPackageEditDialogComponent } from '@app/modules/carwash/modules/carwash-packages/components/carwash-package-edit-dialog/carwash-package-edit-dialog.component';
import { CarwashTicket } from '@app/modules/carwash/modules/carwash-packages/shared/models/carwash-ticket-model';
import { ActivatedRoute } from '@angular/router';
import { CarwashTicketDialogData } from '@app/modules/carwash/modules/carwash-packages/shared/models';
import { AssignVariantDialogComponent } from '../assign-vaiant-dialog/assign-variant-dialog.component';
import { Status } from '@app/shared/enums/status.enum';
import { PageInfo } from '@app/shared/models/global/response';

@Component({
  selector: 'app-carwash-ticket-list',
  templateUrl: './carwash-package-list.component.html',
  styleUrls: ['./carwash-package-list.component.scss'],
})
export class CarwashPackageListComponent implements OnInit {
  tickets$: Observable<CarwashTicket[]>;
  pagination$: Observable<PageInfo>;
  branchId: number;
  groupId: number;
  isMobileType: boolean;
  subscriber$: Subscription;

  STATUS = Status;

  constructor(
    private readonly carwashTicketService: CarwashTicketService,
    private readonly dialog: DialogService,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.branchId = Number(this.activatedRoute.snapshot.params.id);
    this.isMobileType =
      this.activatedRoute.snapshot.queryParams.isMobileType === 'true';
    this.groupId = +this.activatedRoute.snapshot.queryParams.groupId;
    this.setPagination();
    this.setTickets();
  }

  getPage(pageNumber: number) {
    this.setTickets(pageNumber);
  }

  create(): void {
    const data: CarwashTicketDialogData = {
      ticket: null,
      branchId: this.branchId,
    };
    this.dialog
      .open(CarwashPackageEditDialogComponent, data)
      .afterClosed$.pipe(filter(Boolean))
      .subscribe(() => this.setTickets());
  }

  deleteTicket(id): void {
    this.carwashTicketService.delete(id).subscribe(() => this.setTickets());
  }

  editTicket(ticket: CarwashTicket): void {
    const data: CarwashTicketDialogData = { ticket, branchId: this.branchId };
    this.dialog
      .open(CarwashPackageEditDialogComponent, data)
      .afterClosed$.pipe(filter(Boolean))
      .subscribe(() => this.setTickets());
  }

  openAssignVariantDialog(ticket: CarwashTicket): void {
    this.dialog
      .open(AssignVariantDialogComponent, { ticket, groupId: this.groupId })
      .afterClosed$.pipe(filter(Boolean))
      .subscribe(() => this.setTickets());
  }

  changeStatus(ticket: CarwashTicket) {
    this.unsubscribe();

    const newStatus =
      ticket.status === Status.Active ? Status.Inactive : Status.Active;
    this.subscriber$ = this.carwashTicketService
      .update(ticket.id, {
        status: newStatus,
      })
      .pipe(
        finalize(() => {
          this.tickets$ = null;
          this.setTickets();
        }),
      )
      .subscribe();
  }

  private setPagination(): void {
    this.pagination$ = this.carwashTicketService.pagination$;
  }

  private setTickets(page?: number): void {
    this.tickets$ = this.carwashTicketService.get({
      cwGarageBranchId: this.branchId,
      ...(page ? { page } : {}),
    });
  }

  private unsubscribe(): void {
    if (this.subscriber$) {
      this.subscriber$.unsubscribe();
    }
  }
}
