import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DangerSwitchComponent } from './danger-switch.component';

@NgModule({
  declarations: [DangerSwitchComponent],
  exports: [DangerSwitchComponent],
  imports: [CommonModule, FormsModule],
})
export class DangerSwitchModule {}
