import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  DefaultUrlSerializer,
  UrlTree
} from '@angular/router';
import { OrderService } from 'src/app/shared/service/order.service';
import {
  ApprovalSubmission,
  ApprovalResponse
} from 'src/app/shared/models/orders/order';
import { GlobalService } from 'src/app/shared/service/global.service';

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.scss']
})
export class ApprovalComponent implements OnInit {
  loader = false;
  errorFromBAckend: string;
  data: ApprovalSubmission;
  bigLoadder = true;
  serializer: DefaultUrlSerializer;
  url: UrlTree;
  is_commission: boolean;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService,
    private global: GlobalService
  ) {}

  ngOnInit() {
    this.data = {
      data: this.route.snapshot.queryParams.data
        .split('')
        .map(e => (e === ' ' ? '+' : e))
        .join(''),
      approve: this.route.snapshot.queryParams.approve === 'true' ? true : false,
    };
    this.is_commission =
      this.route.snapshot.queryParams.is_commission === 'true' ? true : false;

    console.log(this.data);

    this.doActionRequest(this.data);
  }

  refresh() {
    this.doActionRequest(this.data);
  }

  private doActionRequest(approval: ApprovalSubmission) {
    this.loader = true;
    approval.from_email = true;
    if (!this.is_commission) {
      this.orderService
        .answerApprovalRequest(this.global.headers, approval)
        .subscribe(
          response => {
            if (response.code === 200) {
              const approvalResponse: ApprovalResponse = response.data;
              this.router.navigate([
                'dashboard/orders/order-details/' + approvalResponse.order_id
              ]);
            } else {
              this.bigLoadder = false;
              this.loader = false;
              this.errorFromBAckend = response.message;
            }
          },
          error => {
            this.errorFromBAckend = error.error.message;
            this.bigLoadder = false;
            this.loader = false;
          },
          () => {
            this.bigLoadder = false;
            this.loader = false;
          }
        );
    } else {
      this.orderService
        .responseCommission(this.global.headers, approval)
        .subscribe(
          response => {
            if (response.code === 200) {
              const approvalResponse: ApprovalResponse = response.data;
              this.router.navigate([
                'dashboard/orders/order-details/' + approvalResponse.order_id
              ]);
            } else {
              this.bigLoadder = false;
              this.loader = false;
              this.errorFromBAckend = response.message;
            }
          },
          error => {
            this.errorFromBAckend = error.error.message;
            this.bigLoadder = false;
            this.loader = false;
          },
          () => {
            this.bigLoadder = false;
            this.loader = false;
          }
        );
    }
  }
}
