export const SegmentsStatus = [
  {id: 1, name: 'Spoken To'},
  {id: 2, name: 'No Answer'},
  {id: 3, name: 'Invalid Number'}
];

export interface FeedBackBody {
  user_id: number;
  status: number;
  note: string;
  question_id: number;
}

export interface MarketingFeedback {
  id: number;
  note: string;
  status: number;
  question_id: number;
}

export interface IdQuestion {
  id: number;
  question: string;
}

export interface UserSegmentList {
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  phone_ext: string;
  registered_date: string;
  registered_time: string;
  marketing_feedback: MarketingFeedback;
  last_order_date: any;
}

export interface UserSegmentParam {
  page?: number;
  segment: number;
}
