import { GroupService } from '@shared/service/group';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { FormStatus } from '@shared/enums/form-status.enum';
import { Group } from '@shared/models';
import { SelectOptions } from '@app/shared/models/global/response';

@Component({
  selector: 'app-group-form',
  templateUrl: './group-form.component.html',
  styleUrls: ['./group-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupFormComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  designTypes$: Observable<Array<SelectOptions<string>>>;
  callTypes$: Observable<Array<SelectOptions<string>>>;
  specialityServices$: Observable<Array<SelectOptions<string>>>;
  keywords$: Observable<Array<SelectOptions<string>>>;

  @Input() group: Group;
  @Input() imageGroupIconImageLink: string;
  @Input() imageGroupBackgroundImageLink: string;
  @Input() imageGroupCardImageLink: string;
  @Output() formChanged: EventEmitter<Partial<Group>> = new EventEmitter<
    Partial<Group>
  >();
  @Output() formStatusChanged: EventEmitter<FormStatus> =
    new EventEmitter<FormStatus>();
  @Output() changeGroupLogoFile: EventEmitter<File> = new EventEmitter<File>();
  @Output() changeGroupBackgroundImageFile: EventEmitter<File> = new EventEmitter<File>();
  @Output() changeGroupCardImageFile: EventEmitter<File> = new EventEmitter<File>();

  private readonly destroy$: Subject<void> = new Subject<void>() ;

  constructor(private readonly fb: UntypedFormBuilder, private groupService: GroupService) {}

  ngOnInit(): void {
    this.initForm();
    this.handleFormChanges();
    this.handleFormStatusChanges();
    this.loadDesignTypes();
    this.loadCallTypes();
    this.loadSpecialityServices();
    this.loadKeywords();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateGroupLogoFile(file: File): void {
    this.changeGroupLogoFile.next(file);
  }

  updateGroupBackgroundImageFile(file: File): void {
    this.changeGroupBackgroundImageFile.next(file);
  }

  updateGroupCardImageFile(file: File): void {
    this.changeGroupCardImageFile.next(file);
  }

  private initForm(): void {
    this.form = this.fb.group({
      name: this.fb.group({
        en: ['', [Validators.maxLength(25), Validators.required]],
        ar: ['', [Validators.maxLength(25)]],
      }),
      image: this.fb.group({
        en: [''],
        ar: [''],
      }),
      designType: ['', Validators.required],
      callType: ['', Validators.required],
      specialityService: ['', Validators.required],
      keyword: ['', Validators.required],
      backgroundImage: this.fb.group({
        en: [null],
        ar: [null],
      }),
      cardImage: this.fb.group({
        en: [null],
        ar: [null],
      }),
      selectionText: this.fb.group({
        en: [null],
        ar: [null],
      }),
    });
    this.patchForm();
  }

  private patchForm(): void {
    if (!this.group) {
      return;
    }

    this.form.patchValue(this.group);
    this.formChanged.emit(this.form.value);
  }

  private handleFormChanges(): void {
    const emitValue = (value: Partial<Group>) => this.formChanged.emit(value);

    this.form.valueChanges
      .pipe(debounceTime(500), tap(emitValue), takeUntil(this.destroy$))
      .subscribe();
  }

  private handleFormStatusChanges(): void {
    const emitStatus = (value: FormStatus) =>
      this.formStatusChanged.emit(value);

    this.form.statusChanges
      .pipe(
        startWith(this.form.status),
        distinctUntilChanged(),
        tap(emitStatus),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private loadDesignTypes(): void {
    this.designTypes$ = this.groupService.getDesignTypes();
  }
  private loadCallTypes(): void {
    this.callTypes$ = this.groupService.getCallTypes();
  }
  private loadSpecialityServices(): void {
    this.specialityServices$ = this.groupService.getSpecialityServices();
  }
  private loadKeywords(): void {
    this.keywords$ = this.groupService.getKeywords();
  }
}
