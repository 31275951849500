import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CountriesListComponent } from './components/countries-list/countries-list.component';
import { CreateCountryComponent } from './components/create-country/create-country.component';
import { CitiesListComponent } from './components/countries-list/cities-list/cities-list.component';
import { AreasListComponent } from './components/countries-list/areas-list/areas-list.component';
const routes: Routes = [
  {
    path: 'countries-list',
    component: CountriesListComponent,
    data: {
      title: 'List of Countries'
    }
  },
  {
    path: 'create-country',
    component: CreateCountryComponent,
    data: {
      title: 'Create Country'
    }
  },
  {
    path: 'cities-list/:countryId',
    component: CitiesListComponent,
    data: {
      title: 'List of Cities'
    }
  },
  {
    path: 'areas-list/:govId',
    component: AreasListComponent,
    data: {
      title: 'List of Areas'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CountriesRoutingModule {}
