import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BackEndResponse } from '../models/global/response';
import { ItemToBeSaved, ItemChangePositionBody } from '../models/items/items';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {
  readonly ADD_ITEM = environment.serverUrl + 'items/create-item';
  readonly GET_ITEMS = environment.serverUrl + 'items/get-item';
  readonly DELETE_ITEM = environment.serverUrl + 'items/del-item';
  readonly UPDATE_ITEM = environment.serverUrl + 'items/edit-item';
  readonly CHANGE_STATUS = environment.serverUrl + 'items/act-inact-item';
  readonly TOGGLE_STATUS_CORPORATE_MAKRKETPLACE =
    environment.serverUrl + 'items/change-item-type-status';
  readonly TOGGLE_STATUS_POS = environment.serverUrl + 'items/update-item-pos';
  readonly TOGGLE_ITEM_POSITION = environment.serverUrl + 'items/change-item-position';

  constructor(private http: HttpClient) {}

  getItems(headers, page?: any): Observable<BackEndResponse> {
    return this.http.get<BackEndResponse>(this.GET_ITEMS, {
      headers: this.setHeaders(headers),
      params: { page: page ? page.toString() : '0' }
    });
  }

  saveItem(headers, item: ItemToBeSaved): Observable<BackEndResponse> {
    return this.http.post<BackEndResponse>(this.ADD_ITEM, item, {
      headers: this.setHeaders(headers)
    });
  }

  updateItem(headers, item: ItemToBeSaved): Observable<BackEndResponse> {
    return this.http.post<BackEndResponse>(this.UPDATE_ITEM, item, {
      headers: this.setHeaders(headers)
    });
  }

  deleteItem(headers, id: number) {
    return this.http.post<BackEndResponse>(
      this.DELETE_ITEM,
      {
        id: id
      },
      {
        headers: this.setHeaders(headers)
      }
    );
  }

  changeStatus(headers, id: number, status: string) {
    return this.http.post<BackEndResponse>(
      this.CHANGE_STATUS,
      {
        id: id,
        status: status
      },
      {
        headers: this.setHeaders(headers)
      }
    );
  }

  toggleMarketPlaceCorporate(
    headers,
    body: {
      id: number;
      is_corporate?: boolean;
      is_market_place?: boolean;
    }
  ) {
    return this.http
      .post<BackEndResponse>(this.TOGGLE_STATUS_CORPORATE_MAKRKETPLACE, body, {
        headers: this.setHeaders(headers)
      })
      .pipe(tap(data => console.log));
  }
  togglePosStatus(
    headers,
    body: {
      is_pos: boolean;
      item_id: number;
    }
  ) {
    return this.http
      .post<BackEndResponse>(this.TOGGLE_STATUS_POS, body, {
        headers: this.setHeaders(headers)
      })
      .pipe(tap(data => console.log));
  }

  changePosition(headers, item: ItemChangePositionBody): Observable<BackEndResponse> {
    return this.http.post<BackEndResponse>(this.TOGGLE_ITEM_POSITION, item, {
      headers: this.setHeaders(headers)
    });
  }

  setHeaders(headers) {
    return new HttpHeaders(headers);
  }
}
