import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomecleanPriorityBookingViewComponent } from './homeclean-priority-booking-view.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomecleanPriorityBookingViewComponent,
    data: { title: 'Home cleaning priority booking viewing companies' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomecleanPriorityBookingViewRoutingModule {}
