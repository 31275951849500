import { Component, OnDestroy, OnInit } from '@angular/core';
import { DriversBookingLogsService } from '@app/modules/drivers/shared/services/drivers-booking-logs.service';
import {
  DriversBookingLogs,
  UnionDriversBookingLogs,
} from '@app/modules/drivers/shared/models';
import { EMPTY, Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { DialogService } from '@shared/components/dialog/services/dialog.service';
import { DriversBookingLogsAdviceComponent } from '@app/modules/drivers/components/drivers-bookings-logs/drivers-booking-logs-advice/drivers-booking-logs-advice.component';
import { GlobalService } from '@shared/service/global.service';

@Component({
  selector: 'app-drivers-bookings-logs',
  templateUrl: './drivers-bookings-logs.component.html',
  styleUrls: ['./drivers-bookings-logs.component.scss'],
})
export class DriversBookingsLogsComponent implements OnInit, OnDestroy {
  private currentPage = 1;
  private destroy$: Subject<void> = new Subject<void>() ;

  searchValue = '';
  isLoading = true;
  logs: UnionDriversBookingLogs[] = [];
  isNextPage: boolean;
  // This is groupId for laundry type
  readonly groupId = 1;

  constructor(
    private readonly driversBookingLogsService: DriversBookingLogsService,
    private readonly dialog: DialogService,
    private readonly globalService: GlobalService,
  ) {}

  ngOnInit(): void {
    this.driversBookingLogsService
      .getDriverQuestions()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
    this.loadData$().pipe(takeUntil(this.destroy$)).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  search(): void {
    if (!this.searchValue) {
      return;
    }

    this.logs = [];
    this.currentPage = 1;
    this.loadData$().pipe(takeUntil(this.destroy$)).subscribe();
  }

  clearSearch(): void {
    this.searchValue = '';
    this.logs = [];
    this.currentPage = 1;
    this.loadData$().pipe(takeUntil(this.destroy$)).subscribe();
  }

  loadMore() {
    this.currentPage = this.currentPage + 1;
    this.loadData$().pipe(takeUntil(this.destroy$)).subscribe();
  }

  showAdviceDialog(data: UnionDriversBookingLogs): void {
    this.dialog
      .open(DriversBookingLogsAdviceComponent, data)
      .afterClosed$.pipe(filter(Boolean))
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  private loadData$(): Observable<DriversBookingLogs[]> {
    this.isLoading = true;

    return this.driversBookingLogsService
      .getBookingLogs({
        page: this.currentPage,
        sortOrder: 'DESC',
        countryId: this.globalService.countryId,
        ...(this.searchValue && { orderId: this.searchValue }),
      })
      .pipe(
        tap((res: DriversBookingLogs[]) => {
          this.logs = [
            ...this.logs,
            ...res.map((log: DriversBookingLogs) => ({
              ...log,
              question$: log.driverQuestionId
                ? this.driversBookingLogsService.getDriverQuestionsById(
                    log.driverQuestionId,
                  )
                : EMPTY,
            })),
          ];
          this.isNextPage =
            this.driversBookingLogsService.pagination$.value.next_page;
          this.isLoading = false;
        }),
      );
  }
}
