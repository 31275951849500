import { Address } from './../orders/order-map';
import { HcBranch, HcOrderJob, HcUser, HcUserTransaction } from '.';

export interface HcOrder {
  id: number;
  userComment: string;
  billAmountTotal: number;
  billAmountRemaining: number;
  billAmountJobs: number;
  extraDiscounts: number;
  vatAmount: number;
  deliveryCharge: number;
  serviceCharge: number;
  status: null;
  isRated: false;
  userId: number;
  countryId: null;
  groupId: number;
  userAddressId: number;
  hcCompanyBranchId: number;
  paymentStatusId: null;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
  user: HcUser;
  userTransactions: HcUserTransaction[];
  userAddress: Address;
  hcCompanyBranch: HcBranch;
  hcOrderJobs: HcOrderJob[];
}
