import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@env/environment';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { Ad } from '@shared/models';

@Injectable({ providedIn: 'root' })
export class AdHttpService {
  private readonly url = `${environment.jcDeliveryServer}advertisements`;

  constructor(private readonly http: HttpClient) {}

  getAds(
    filters?: Record<string, string | number | boolean>,
  ): Observable<ApiResponse<Ad[]>> {
    const params = new HttpParams().appendAll({
      ...(filters || {}),
    });

    return this.http.get<ApiResponse<Ad[]>>(this.url, { params });
  }

  createAd(data: Partial<Ad>): Observable<ApiResponse<Ad>> {
    return this.http.post<ApiResponse<Ad>>(this.url, data);
  }

  updateAd(id: number, data: Partial<Ad>): Observable<ApiResponse<Ad>> {
    return this.http.patch<ApiResponse<Ad>>(`${this.url}/${id}`, data);
  }

  deleteAd(id: number): Observable<ApiResponse<void>> {
    return this.http.delete<ApiResponse<void>>(`${this.url}/${id}`);
  }
}
