
import { Translation } from 'libs/justclean-models/src/lib/justclean-models'

export interface HcOrderJobStaff {
  id: number;
  userJlmId?: number;
  assignedById?: number;
  assignedByUserId?: number;
  hcOrderJobId?: number;
  userJlm: {
    firstName: Translation;
    lastName: Translation;
    id: number;
    phoneNumber?: string;
    photoUrl?: string;
  };
}
