// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.personal-photo {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  margin-inline-end: 22px;
}

::ng-deep .cdk-overlay-backdrop {
  pointer-events: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/homeclean/modules/homeclean-order/homeclean-order-details/homeclean-order-details-card/homeclean-order-details-card.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AAEA;EAAkC,+BAAA;AAElC","sourcesContent":[".personal-photo {\n    width: 52px;\n    height: 52px;\n    border-radius: 100%;\n    margin-inline-end: 22px;\n}\n\n::ng-deep .cdk-overlay-backdrop { pointer-events: none!important; }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
