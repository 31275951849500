import { NgModule } from '@angular/core';
import { JcCreditOffersRoutingModule } from './jc-credit-offers-routing.module';
import { SharedModule } from '../shared/shared.module';
import { JcCreditPackagesListComponent } from './components/jc-credit-package-list/jc-credit-packages-list.component';
import {MatTableModule as MatTableModule} from '@angular/material/table';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {JcCreditPackageCreateComponent} from './components/jc-credit-package-create/jc-credit-package-create.component';

@NgModule({
    imports: [JcCreditOffersRoutingModule, SharedModule.forRoot(), MatTableModule, NgxDatatableModule],
  declarations: [
    JcCreditPackagesListComponent,
    JcCreditPackageCreateComponent,
  ]
})
export class JcCreditPackagesModule {}
