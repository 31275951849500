import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { OrderDetails, STATUSES } from 'src/app/shared/models/orders/order';
import { GlobalService } from 'src/app/shared/service/global.service';
import { OrderService } from 'src/app/shared/service/order.service';
import { LogDriverService } from 'src/app/shared/service/log-driver.service';
import {
  QuestionsForAssignDriver,
  OrderAssignmentBody,
  DriverToBeAssigned
} from 'src/app/shared/models/cars/partners';
import { SelectOptions } from 'src/app/shared/models/global/response';
import { ApiLibrary } from 'src/app/shared/helpers/http-params';
import { zip } from 'rxjs';
@Component({
  selector: 'app-log-order-details',
  templateUrl: './logistic-order-details.component.html',
  styleUrls: ['./logistic-order-details.component.css']
})
export class LogisticOrderDetailsComponent implements OnInit {
  orderId: string;
  statuses: any[] = [];
  selectedStatus: number;
  order: OrderDetails;
  isFirstCall = true;
  showDialog: boolean;
  dataReady: boolean;
  showLaundry: boolean;
  currency: string;
  loader = false;
  availableDriversFromBackend: DriverToBeAssigned[];
  availablePartnersFromBackend: DriverToBeAssigned[];
  availableDrivers: SelectOptions<any>[];
  isAssigned: boolean;
  selectedDriver: any;
  selectDriverId: number;
  answers: QuestionsForAssignDriver[];
  openAnswersModal = false;
  isTransfer = true;
  assignmentBody: OrderAssignmentBody;
  drivers: number[] = [];
  partners: number[] = [];
  timeToNextTask: string;
  timeToNextTaskSeconds: number;
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected globalService: GlobalService,
    protected orderService: OrderService,
    protected logDriverService: LogDriverService,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.orderId = this.route.snapshot.params.orderId;
    this.initAssignObject();
    this.getOrderDetails();
    this.readStatuses();
    this.showDialog = false;
    this.currency = this.globalService.getCurrency();
  }

  getDateFromString(date: string) {
    return this.globalService.getDateFromString(date);
  }

  getTimeFromString(date: string) {
    const hour = parseInt(this.globalService.getTimeFromString(date), null);
    return hour + ' H - ' + (hour + 1) + ' H';
  }

  showDiscounts() {
    if (this.order && this.order.discounts) {
      return this.order.discounts.discount_type === 'amount'
        ? this.order.discounts.discount_amount + ' ' + this.currency
        : this.order.discounts.discount_percentage + ' %';
    }
  }

  closeModal(event) {
    this.openAnswersModal = false;
    this.assignmentBody.notes = null;
    this.assignmentBody.answer_id = null;
    if (event === true) {
      this.getOrderDetails();
    }
  }

  assignClicked(isTransfer: boolean) {
    if (isTransfer) {
      this.openAnswersModal = true;
      this.isTransfer = true;
    } else {
      this.assignmentBody = new ApiLibrary().cleanObjectFromComponent(
        this.assignmentBody
      );
      this.loader = true;
      this.logDriverService
        .assignReassignOrder(this.globalService.headers, this.assignmentBody)
        .subscribe(
          response => {
            if (response.code === 200) {
              this.getOrderDetails();
              this.getAvailableDrivers();
            } else {
              this.toast.error(response.message);
            }
            this.loader = false;
          },
          error => {
            this.loader = false;
          }
        );
    }
  }

  changeDriver(event) {
    this.assignmentBody.is_partner =
      this.partners && this.partners.includes(event);
    this.assignmentBody.id = event;
  }

  isDriverAssigned(id: number) {
    return this.order &&
      this.order.order_result &&
      this.order.order_result.order_tracking_details[0] &&
      this.order.order_result.order_tracking_details[0].driver &&
      this.order.order_result.order_tracking_details[0].driver.id === id
      ? true
      : false;
  }

  getTimer() {
    const myTimer = setInterval(() => {
      this.checkTimeDiff();
    }, 1000);
    if (this.timeToNextTaskSeconds <= 0) {
      clearInterval(myTimer);
    }
  }
  showDuration() {
    return this.globalService.formatTimeFromSecond(this.timeToNextTaskSeconds);
  }

  private checkTimeDiff() {
    const status = this.order.order_result.statuses_user.id;
    if (status >= 5) {
      this.timeToNextTaskSeconds = this.globalService.timeDiffSecond(
        this.order.order_result.delivery_at.toString()
      );
    } else {
      this.timeToNextTaskSeconds = this.globalService.timeDiffSecond(
        this.order.order_result.pickup_at.toString()
      );
    }
    this.timeToNextTaskSeconds = this.timeToNextTaskSeconds - 30 * 60;
  }
  private initAssignObject() {
    this.assignmentBody = new OrderAssignmentBody();
    this.assignmentBody.order_id = parseInt(this.orderId, 10);
  }
  private readStatuses() {
    if (!this.order) {
      return false;
    }
    this.statuses = [];
    STATUSES.forEach((val, i) => {
      this.statuses.push({
        value: i + 1,
        label: val,
        selected: i + 1 === this.selectedStatus
      });
    });
  }

  private getOrderDetails() {
    this.isFirstCall = true;
    this.orderService
      .getOrderDetails(this.globalService.headers, this.orderId)
      .subscribe(data => {
        this.order = data.data;
        this.selectedStatus = this.order.order_result.statuses_user.id || null;
        this.readStatuses();
        this.dataReady = true;
        this.getAvailableDrivers();
        this.getQuestions();
        this.getTimer();
      });
  }

  private getAvailableDrivers() {
    zip(
      this.logDriverService.getAvailableDriverForAssignment(
        this.globalService.headers,
        parseInt(this.orderId, 10)
      ),
      this.logDriverService.getAvailablePartnerForAssignment(
        this.globalService.headers,
        parseInt(this.orderId, 10)
      )
    ).subscribe(
      response => {

        if (response[0].code === 200 && response[1].code === 200) {
          this.fetchDriverData();
          this.availableDriversFromBackend = response[0].data;
          this.availablePartnersFromBackend = response[1].data;
          this.availableDrivers = [];
          if (this.isAssigned && this.selectedDriver) {
            this.availableDrivers.push({
              value: this.selectedDriver.id,
              label:
                this.selectedDriver.first_name +
                '' +
                this.selectedDriver.last_name +
                '-' +
                this.selectedDriver.mobile_no,
              disabled: true
            });
          }
          this.availableDrivers.push({
            value: '',
            label: 'Justclean Drivers',
            group: true
          });
          this.availableDriversFromBackend.forEach(e => {
            this.availableDrivers.push({
              value: e.id,
              label: e.first_name + '' + e.last_name + '-' + e.mobile,
              selected: this.isDriverAssigned(e.id)
            });
            this.drivers.push(e.id);
          });
          this.availableDrivers.push({
            value: '',
            label: 'Partners',
            group: true
          });
          this.availablePartnersFromBackend.forEach(e => {
            this.availableDrivers.push({
              value: e.id,
              label: e.first_name + '' + e.last_name + '-' + e.mobile,
              selected: this.isDriverAssigned(e.id)
            });
            this.partners.push(e.id);
          });
          console.log(this.availableDrivers);
        } else  {
          this.toast.error((response[0].code !== 200 ) ? response[0].message : response[1].message);
        }
      }
    );
  }

  private fetchDriverData() {
    this.isAssigned =
      this.order &&
      this.order.order_result &&
      this.order.order_result.is_assigned;
    this.selectedDriver = !this.isAssigned
      ? ''
      : this.order &&
        this.order.order_result &&
        this.order.order_result.order_tracking_details &&
        this.order.order_result.order_tracking_details.length > 0
      ? this.order.order_result.order_tracking_details[
          this.order.order_result.order_tracking_details.length - 1
        ].driver
      : '';
    this.selectDriverId = this.selectedDriver ? this.selectedDriver.id : 0;
    console.log(this.selectedDriver);
  }

  private getQuestions() {
    this.logDriverService
      .getQuestionRelatedToAssignment(this.globalService.headers, {
        question_id: 5
      })
      .subscribe(
        response => {
          if (response.code === 200) {
            this.answers = response.data;
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }
}
