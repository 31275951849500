import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { PageInfo, SelectOptions } from 'src/app/shared/models/global/response';
import { SegmentsStatus, UserSegmentList, IdQuestion, FeedBackBody } from '../../../models/segments';
import { IdName } from 'src/app/shared/models/offers/offers';
import { MarketingService } from '../../../services/marketing.service';
import { GlobalService } from 'src/app/shared/service/global.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.css']
})
export class SegmentComponent implements OnInit {
  @Input() segment: number;
  @Input() raisonsSrc: IdQuestion[];
  pagination: PageInfo;
  users: UserSegmentList[];
  statuses: IdName[] = SegmentsStatus;
  selectedUser: UserSegmentList;
  selectedStatus: IdName;
  raisons: SelectOptions<number>[] = [];
  selectedAnswer: number;
  note: string;
  @ViewChild('description', {static: true}) noteModel: NgModel;
  // modal
  isNoteModalOpened = false;
  isConfirmationModalOpened = false;
  loader = false;
  constructor(
    private marketingService: MarketingService,
    private globalService: GlobalService,
    private toast: ToastService
  ) { }

  ngOnInit() {
    this.getUsers(1);
  }

  getPage(event) {
    this.getUsers(event);
  }

  getStatus(id: number) {
    return this.statuses.find(e => e.id === id);
  }

  changeStatus(user: UserSegmentList, newStatus: IdName) {
    this.selectedUser = user;
    this.selectedStatus = newStatus;
    this.openNoteModel();
  }

  addNote() {
    const body = {
      note: this.note,
      question_id: this.selectedAnswer,
      status: this.selectedStatus.id,
      user_id: this.selectedUser.id
    } as FeedBackBody;
    this.loader = true;
    this.marketingService
      .saveFeedback(
        this.globalService.headers,
        body
      )
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.getUsers(this.pagination.current_page);
            this.closeConfirmationModal(true);
            this.closeNoteModal(true);
            this.initData();
          } else {
            this.toast.error(response.message);
          }
          this.loader = false;
        },
        (error) => {
          this.loader = false;
        }
      );
  }



  // public methods for modal

  openNoteModel() {
    this.isNoteModalOpened = true;
    this.convertAnswers();
  }

  closeNoteModal(event) {
    this.isNoteModalOpened = false;
    this.initData();
  }

  openConfirmationButton() {
    this.isConfirmationModalOpened = true;
  }

  closeConfirmationModal(event) {
    this.isConfirmationModalOpened = false;
  }

  formatDate(date: string) {
    const formatted = this.globalService.formatDate(date, 'DD-MM-YYYY HH:mm:ss');
    return !formatted.toLowerCase().includes('invalid') ? formatted : 'No Order Yet';
  }

  private initData() {
    this.note = null;
    this.selectedAnswer = null;
    this.selectedStatus = null;
    this.selectedUser = null;
    this.noteModel.reset();
  }
  private getUsers(page: number) {
    this.marketingService
      .getUsersPerSegment(
        this.globalService.headers,
        { page,
        segment: this.segment }
      )
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.users = response.data as UserSegmentList[];
            this.pagination = response.page_info;
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }

  private convertAnswers() {
    this.raisons =  this.raisonsSrc.map(e => {
      return {
        value: e.id,
        label: e.question
      };
    });
  }
}
