import { ImageQuality, ImageQualityOption } from '../models';

export class ImageChecker {

  /**
   * Method to check image quality based on parameters' values
   * @param imageFile the uploaded image
   * @param minWidth minimum width to accept
   * @param maxWidth maximum width to accept
   * @param minHeight minimum height to accept
   * @param maxHeight maximum height to accept
   * @param minSize minimum size in KB to accept
   * @param maxSize maximum size in KB to accept
   * @returns true incase of match inputs, otherwise false
   */
  static async checkImageQuality(
    options: ImageQualityOption
  ): Promise<ImageQuality> {
    const result: ImageQuality = {
      isAccepted: false,
      src: '',
      isRatioIssue: false,
      isSizeIssue: false,
    };

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(options.imageFile);

      img.onload = () => {
        if (options.maxWidth || options.maxHeight) {
          result.isRatioIssue = ImageChecker.checkImageRatio(options, img);
        } else {
          result.isRatioIssue = false;
        }
        result.isSizeIssue = ImageChecker.checkImageSize(options);

        if (!result.isRatioIssue && !result.isSizeIssue) {
          result.src = img.src;
          result.isAccepted = true;
        }

        resolve(result);
      };
      img.onerror = reject;
      img.crossOrigin = 'anonymous';
    });
  }


  private static checkImageRatio(
    options: ImageQualityOption,
    img: any
  ): boolean {
    const height = img.height;
    const width = img.width;
    return !(
      width <= options.maxWidth &&
      width >= options.minWidth &&
      height <= options.maxHeight &&
      height >= options.minHeight
    );
  }

  private static checkImageSize(options: ImageQualityOption): boolean {
    const size = +parseFloat(
      (options.imageFile.size / 1024).toString()
    ).toFixed(2);
    return !(size <= options.maxSize && size >= options.minSize);
  }

}