import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'src/app/shared/service/customer.service';
import { GlobalService } from 'src/app/shared/service/global.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { SelectOptions } from 'src/app/shared/models/global/response';
import { BranchesService } from 'src/app/shared/service/branches.service';
import { UserTypeApiRequstBody } from 'src/app/shared/models/laundries/branch';

@Component({
  selector: 'app-branch-user-types',
  templateUrl: './branch-user-types.component.html',
  styleUrls: ['./branch-user-types.component.css']
})
export class BranchUserTypesComponent implements OnInit {
  laundryId: number;
  branchId: number;
  branchUserTypes: SelectOptions<number>[];
  userTypeIds: number[];
  isButtonDisabled: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected globalService: GlobalService,
    protected customerService: CustomerService,
    protected toast: ToastService,
    protected branchService: BranchesService
  ) { }

  ngOnInit() {
    this.isButtonDisabled = false;
    this.laundryId = this.route.parent.snapshot.params.id;
    this.branchId = this.route.snapshot.params.branchId;
    this.getBranchUsertypes();
  }

  goBack() {
    this.router.navigate([
      '/dashboard/' +
      window.localStorage.getItem('module') +
      '/branch-list/' +
      this.laundryId
    ]);
  }

  getUpdateApiRequestBody(): UserTypeApiRequstBody {
    const branchUsertypes = { userTypeIds: [] };
    this.branchUserTypes.forEach((userType) => {
      if (userType.selected) {
        branchUsertypes.userTypeIds.push(userType.value);
      }
    });
    return branchUsertypes;
  }

  toggleUserType(type: SelectOptions<number>, isChecked: false): void {
    type.selected = isChecked;
    this.branchUserTypes = this.branchUserTypes.map(userType => {
      return {
        value: userType.value,
        label: userType.label,
        selected: userType.value === type.value ? isChecked : userType.selected
      };
    });
  }

  updateBranchUserTypes(): void {
    this.isButtonDisabled = true;
    const params = this.getUpdateApiRequestBody() as UserTypeApiRequstBody;
    this.branchService.updateBranchDetailsUserTypes(this.globalService.headers, params, this.branchId)
      .subscribe((response) => {
        if (response.code === 200) {
          this.toast.success(response.message);
        } else {
          this.toast.error(response.message);
        }
        this.isButtonDisabled = false;
      }, (error) => {
        this.isButtonDisabled = false;
      });
  }

  private getBranchUsertypes(): void {
    this.branchService.getBranchDetailsWithUserTypes(this.globalService.headers, this.branchId)
      .subscribe((response) => {
        this.userTypeIds = response.data.userTypeIds;
        this.getUserTypes();
      }, (error) => {
        console.log(error.error.message);
      });
  }

  private getUserTypes(): void {
    this.customerService.getUserTypes(this.globalService.headers).subscribe(
      (response) => {
        if (response.code === 200) {
          this.branchUserTypes = response.data.map(type => {
            return {
              value: type.id,
              label: type.name,
              selected: this.userTypeIds.includes(type.id)
            };
          });
          console.log(this.branchUserTypes);
        } else {
          console.log('No user type fetched!');
        }
      },
      (error) => {
        console.log(error.error.message);
      }
    );
  }
}
