import { Pipe, PipeTransform } from '@angular/core';
import { Translation } from 'libs/justclean-models/src/lib/justclean-models';

@Pipe({
  name: 'translation',
})
export class TranslationPipe implements PipeTransform {
  private readonly language: string;

  constructor() {
    this.language = localStorage.getItem('language') || 'en';
  }
  transform(value: Translation): string {
    return value[this.language];
  }
}
