export interface HcPackageHour {
  id: number;
  hours: number;
  hourlyRate: number;
  suppliesRate: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
  hcServicePackageId: number;
}
