import { environment } from 'src/environments/environment';

export function NgLog(): ClassDecorator {
  return function(constructor: any) {
    if (environment.production) {
      // You can add/remove events for your needs
      console.log = () => {};
    }
  };
}
