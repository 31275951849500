import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BackEndResponse } from 'src/app/shared/models/global/response';
import { tap } from 'rxjs/operators';
import { PaymentMethodChargesBody, CloseOrderBody } from '../models/finance';

@Injectable({
  providedIn: 'root',
})
export class FinanceService {
  isLoading: boolean;

  URL_PAYMENTS = environment.serverUrl + 'setting/get-payment-method';
  URL_SAVE_PAYMENTS = environment.serverUrl + 'setting/payment-method';
  URL_MONTHLY_ORDER = environment.serverUrl + 'orders';
  URL_CLOSE_MONTHLY_ORDER = environment.serverUrl + 'orders/closed';
  URL_LAUNDRY_BILL = environment.serverUrl + 'reports/laundry-bill-detail';
  URL_DETAILED_REPORT = environment.serverUrl + 'reports/laundry-transaction-report';
  constructor(private http: HttpClient) {}

  getPaymentMethod(headers, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_PAYMENTS, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(tap((data) => console.log(data)));
  }

  savePaymentMethod(headers, body: PaymentMethodChargesBody) {
    return this.http
      .post<BackEndResponse>(this.URL_SAVE_PAYMENTS, body, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  getMonthlyOrders(headers, year: string, month: string, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_MONTHLY_ORDER + '/' + month + '/' + year, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(tap((data) => console.log(data)));
  }

  closeMonthlyOrders(headers, body: CloseOrderBody) {
    return this.http
      .post<BackEndResponse>(this.URL_CLOSE_MONTHLY_ORDER, body, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  getLaundryBilling(headers, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_LAUNDRY_BILL, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(tap((data) => console.log(data)));
  }

  getLaundryBillingDetails(headers, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_DETAILED_REPORT, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(tap((data) => console.log(data)));
  }

  /**
   * This function for setting Buffer object to window to be used in zatca package
   */
  setBufferObject(): void {
    (window as any).global = window;
    // @ts-ignore
    window.Buffer = window.Buffer || require('buffer').Buffer;
  }

  ExcelDateToJSDate(serial: number) {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);

    const fractional_day = serial - Math.floor(serial) + 0.0000001;

    let total_seconds = Math.floor(86400 * fractional_day);

    const seconds = total_seconds % 60;

    total_seconds -= seconds;

    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
  }
}
