import { GroupService } from '@app/shared/service/group';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { DialogService } from '@shared/components/dialog/services/dialog.service';
import { Group, Variant } from '@shared/models';
import { Status } from '@shared/enums/status.enum';
import { PageInfo } from '@shared/models/global/apiResponse';
import { filter, finalize } from 'rxjs/operators';
import { VariantService } from '@shared/service/variant';
import { VariantEditDialogComponent } from '../variant-edit-dialog/variant-edit-dialog.component';
import { AssignCountryDialogComponent } from '../assign-country-dialog/assign-country-dialog.component';

@Component({
  selector: 'app-variant-list',
  templateUrl: './variant-list.component.html',
})
export class VariantListComponent implements OnInit {
  readonly Status = Status;
  pagination$: Observable<PageInfo>;
  currentPage = 1;
  variants$: Observable<Array<Variant>>;
  groups: Array<Group>;
  isConfirmationOpened: boolean;
  isDeleting: boolean;
  selectedVariantToDelete: Variant;
  getGroupNameBounded: Function;

  constructor(
    private readonly variantService: VariantService,
    private readonly groupService: GroupService,
    private readonly dialog: DialogService,
  ) {}

  ngOnInit(): void {
    this.loadVariants();
    this.loadGroups();
    this.getGroupNameBounded = this.getGroupName.bind(this);
    this.pagination$ = this.variantService.pagination$;
  }

  openDialog(variant?: Variant): void {
    this.dialog
      .open(VariantEditDialogComponent, variant)
      .afterClosed$.pipe(filter(Boolean))
      .subscribe(() => this.loadVariants());
  }

  openAssignCountryDialog(variant?: Variant): void {
    this.dialog
      .open(AssignCountryDialogComponent, variant)
      .afterClosed$.pipe(filter(Boolean))
      .subscribe(() => this.loadVariants());
  }

  deleteVariant(): void {
    this.isDeleting = true;
    this.variantService
      .deleteVariant(this.selectedVariantToDelete.id)
      .pipe(
        finalize(() => {
          this.isDeleting = false;
          this.isConfirmationOpened = false;
          this.selectedVariantToDelete = null;
        }),
      )
      .subscribe(() => {
        this.variants$ = null;
        this.loadVariants();
      });
  }

  openConfirmationModal(variant: Variant) {
    this.selectedVariantToDelete = variant;
    this.isConfirmationOpened = true;
  }

  closeConfirmationModal() {
    this.isConfirmationOpened = false;
  }

  private loadVariants(): void {
    this.variants$ = this.variantService.getVariants(null, this.currentPage);
  }

  getPage(page: number) {
    this.currentPage = page;
    this.loadVariants();
  }

  private loadGroups(): void {
    this.groupService.getGroups().subscribe((groups) => (this.groups = groups));
  }

  private getGroupName(groupId: number): string {
    return this.groups.find((group) => group.id === groupId)?.name?.en;
  }
}
