import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { PageInfo } from '../../models/global/response';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {
  @Input() pagination: PageInfo;
  @Output() getPage: EventEmitter<number> = new EventEmitter();

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    this.pagination = changes.pagination.currentValue;
  }

  changePage(page) {
    this.getPage.emit(page);
  }

  pageArray() {
    return Array(this.pagination.page_count);
  }

  paginationCondition(i) {
    return (
      this.pagination.current_page === i + 1 ||
      this.pagination.current_page === i + 2 ||
      this.pagination.current_page === i + 3 ||
      this.pagination.current_page === this.pageArray().length - 3 ||
      this.pagination.current_page === this.pageArray().length - 2 ||
      this.pagination.current_page === this.pageArray().length - 1
    );
  }
}
