// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body {
  min-height: 20rem;
  max-height: 40rem;
  min-width: 800px;
}
@media (max-width: 1199.98px) {
  .modal-body {
    min-width: 0px;
    max-height: inherit;
  }
}

.working-hours-service-times {
  margin-bottom: 15px;
}

.column {
  column-count: 3;
}
@media (max-width: 1199.98px) {
  .column {
    column-count: 1;
  }
}

.table-cell {
  margin-right: 10px;
}

@media (max-width: 580px) {
  :host::ng-deep .md-tabs {
    margin: -10px;
  }
}

.mobile-actions {
  display: none;
  text-align: center;
}

@media (max-width: 580px) {
  .mobile-actions {
    display: block;
  }
  .modal-footer {
    display: none;
  }
}
:host::ng-deep .md-tabs {
  background-color: #5a1ec3;
}
@media (max-width: 1199.98px) {
  :host::ng-deep .md-tabs mdb-tab {
    overflow: scroll;
    height: 10rem;
  }
}

@media (max-width: 1199.98px) {
  :host::ng-deep mdb-tab {
    overflow: scroll;
    height: 10rem;
  }
}

.header-titles {
  font-weight: bold;
}

@media (max-width: 1199.98px) {
  :host::ng-deep .modal-dialog-scrollable {
    margin: 0;
    height: 100vh;
    width: 100vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/modules/crud/shared/action-components/working-hours/working-hours.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,iBAAA;EACA,gBAAA;AACF;AAAE;EAJF;IAKI,cAAA;IACA,mBAAA;EAGF;AACF;;AAAA;EACE,mBAAA;AAGF;;AAAA;EACE,eAAA;AAGF;AAFE;EAFF;IAGI,eAAA;EAKF;AACF;;AAFA;EACE,kBAAA;AAKF;;AADE;EADF;IAEM,aAAA;EAKJ;AACF;;AAFA;EACE,aAAA;EACA,kBAAA;AAKF;;AAFA;EACE;IACE,cAAA;EAKF;EAFA;IACE,aAAA;EAIF;AACF;AADA;EACE,yBAAA;AAGF;AAFE;EACE;IACE,gBAAA;IACA,aAAA;EAIJ;AACF;;AAAE;EADF;IAEI,gBAAA;IACA,aAAA;EAIF;AACF;;AADA;EACE,iBAAA;AAIF;;AAAE;EADF;IAEM,SAAA;IACA,aAAA;IACA,YAAA;EAIJ;AACF","sourcesContent":[".modal-body {\n  min-height: 20rem;\n  max-height: 40rem;\n  min-width: 800px;\n  @media (max-width: 1199.98px) {\n    min-width: 0px;\n    max-height: inherit;\n  }\n}\n\n.working-hours-service-times {\n  margin-bottom: 15px;\n}\n\n.column {\n  column-count: 3;\n  @media (max-width: 1199.98px) {\n    column-count: 1;\n  }\n}\n\n.table-cell {\n  margin-right: 10px;\n}\n\n:host::ng-deep .md-tabs {\n  @media (max-width: 580px) {\n      margin: -10px;\n  }\n}\n\n.mobile-actions {\n  display: none;\n  text-align: center;\n}\n\n@media (max-width: 580px) {\n  .mobile-actions {\n    display: block;\n  }\n\n  .modal-footer {\n    display: none;\n  }\n}\n\n:host::ng-deep .md-tabs {\n  background-color: #5a1ec3;\n  @media (max-width: 1199.98px) {\n    mdb-tab {\n      overflow: scroll;\n      height: 10rem;\n    }\n  }\n}\n:host::ng-deep mdb-tab {\n  @media (max-width: 1199.98px) {\n    overflow: scroll;\n    height: 10rem;\n  }\n}\n\n.header-titles {\n  font-weight: bold;\n}\n\n:host::ng-deep .modal-dialog-scrollable {\n  @media (max-width: 1199.98px) {\n      margin: 0;\n      height: 100vh;\n      width: 100vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
