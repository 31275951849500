// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reschedule-modal-body {
    min-width: 50rem;
  }
  :host::ng-deep .mdb-select-outline .mdb-select-value {
    padding: 0.375rem 0.75rem;
  }`, "",{"version":3,"sources":["webpack://./src/app/modules/carwash/modules/carwash-order/components/cw-reschedule-dialog/cw-reschedule-dialog.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB;EACA;IACE,yBAAyB;EAC3B","sourcesContent":[".reschedule-modal-body {\n    min-width: 50rem;\n  }\n  :host::ng-deep .mdb-select-outline .mdb-select-value {\n    padding: 0.375rem 0.75rem;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
