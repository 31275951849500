// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.open-orders--container {
  height: 200px;
}

.details td {
  border: solid 1px black;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/finance/components/close-monthly-orders/close-monthly-orders.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAIE;EACE,uBAAA;AADJ","sourcesContent":[".open-orders--container {\n  height: 200px;\n}\n\n\n.details {\n  td {\n    border: solid 1px black;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
