import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IsPriorityBookingViewingGuard } from '@app/shared/guards';

const routes: Routes = [
  {
    path: 'priority-booking-viewing',
    pathMatch: 'full',
    loadChildren: () =>
      import(
        './modules/homeclean-priority-booking-view/homeclean-priority-booking-view.module'
      ).then((m) => m.HomecleanPriorityBookingViewModule),
    canActivate: [IsPriorityBookingViewingGuard],
    data: { title: 'Home cleaning priority booking viewing companies' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomecleanRoutingModule {}
