export class OfferToBeSaved {
  offer_type_id: number;
  name: string;
  start_date: string;
  end_date: string;
  has_expiry: boolean;
  offer_on: string;
  applied_on: string;
  type_amount: number;
  discount_type: string;
  discount_value: number;
  laundry: Laundry[];
}

export class NameDesc {
  name: string;
}

export class Laundry {
  laundryId: number;
}

export class Name {
  en: NameDesc;
}

export class Offer {
  id: number;
  name: Name;
}

export class OfferType {
  id: number;
  name: string;
  status: string;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
}

export class OfferList {
  id: number;
  name: string;
  status: string;
  start_date: string;
  end_date: string;
  has_expiry: boolean;
  offer_on: string;
  applied_on: string;
  type_amount: number;
  discount_type: string;
  discount_value: number;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  offer_type_id: number;
  country_id: number;
  group_id: number;
  offer_type: OfferType;
}

export class CouponType {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  deleted_at?: any;
}

export class CouponToBeSaved {
  coupon_category_id: number;
  code: string;
  start_date: string;
  expiry_date: string;
  message: string;
  coupon_count: number;
  laundry_id?: any;
  amount: number;
  user_id?: number;
  type?: string;
  discount_type?: string;
  discount_value?: number;
  customer_type?: string;
  translations?: { language_id: number; name?: string; message: string }[];
  discount_by_justclean?: number;
  discount_by_laundry?: number;
  group_id?: number;
  is_multi_use: boolean;
}

export class IdName {
  id: number;
  name: string;
}

export class UserCoupon {
  id: number;
  first_name: string;
  last_name: string;
}

export class CouponsFromList {
  id: number;
  code: string;
  message: string;
  start_date: string;
  expiry_date: string;
  amount: number;
  state: string;
  legacy_id: number;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  coupon_category_id: number;
  laundry_id: number;
  user_admin_id: number;
  user_id?: any;
  country_id: number;
  group_id: number;
  coupon_category: IdName;
  user_admin: IdName;
  laundry: IdName;
  user?: UserCoupon;
  coupon_redeemed: number;
  coupon_remaining: number;
  total_coupon: number;
  expire: boolean;
  customer_type: string;
  discount_amount: number;
  discount_percentage: number;
  discount_type: string;
  type: string;
  laundries: any;
}

export class OrderApproval {
  order_id: number;
  reason: string;
  user_admin_from_id: number;
  status_from_id: number;
  status_to_id: number;
  reason_id: number;
  sub_reason_id: number;
}

export enum CouponTypeEnum {
  ishtrikat = 'Ishtirakat',
  gift = 'Gift',
  voucher = 'Voucher'
}

export enum CouponDiscountType {
  percentage = 'Percentage',
  amount = 'amount'
}

export enum CouponCustomerType {
  all = 'all',
  firstTime = 'is_first_order',
  reorder = 'reorder'
}
export enum pattern {
  positiveNumber = '^(?=.*[1-9])d*(?:.d{1,2})?$'
}

export class CouponCategory {
  id: number;
  name: string;
}

export class UserAdmin {
  id: number;
  name: string;
}

export class User {
  id: number;
  first_name: string;
  last_name: string;
}

export class CouponDetails {
  id: number;
  code: string;
  message: string;
  start_date: Date;
  expiry_date: Date;
  amount: number;
  state: string;
  laundry_id?: any;
  user_admin_id: number;
  user_id: number;
  type: string;
  discount_type: string;
  discount_percentage: number;
  discount_amount: number;
  customer_type: string;
  created_at: Date;
  is_first_order: number;
  is_multi_use: boolean;
  coupon_category: CouponCategory;
  user_admin: UserAdmin;
  laundry?: IdName;
  laundries?: string[] | number;
  user: User;
  voucher_message: {
    en: {
      message: string;
    };
    ar: {
      message: string;
    };
  };
}
