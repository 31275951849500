import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import { ModalDirective } from 'ng-uikit-pro-standard';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  NgModel,
} from '@angular/forms';
import {
  CustomerAddress,
  UserAddress,
  CustomerDetails,
} from '../../../../shared/models/customer/customer';
import { CountryService } from '../../../../shared/service/country.service';
import { GlobalService } from '../../../../shared/service/global.service';
import { CustomerService } from '../../../../shared/service/customer.service';
import { MarkerForMap } from '@app/shared/models/global/response';

@Component({
  selector: 'app-create-address',
  templateUrl: './create-address.component.html',
  styleUrls: ['./create-address.component.css'],
})
export class CreateAddressComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title: string;
  @Input() isUpdate: boolean;
  @Input() openDialog: boolean;
  @Input() addressToBeUpdated: UserAddress;
  @Input() customer: CustomerDetails;

  @ViewChild('addressModal', { static: true }) addressModal: ModalDirective;

  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
  addressForm: UntypedFormGroup;
  isHouse: boolean;
  countries: any[];
  cities: any[];
  areas: any[];
  showAreas: boolean;
  showCities: boolean;
  selectedCountry;
  selectedCity;
  selectedArea;
  addressToBeSaved: CustomerAddress = new CustomerAddress();
  showAreaError: boolean;

  zoom = 14;
  long: number;
  lat: number;
  markers: MarkerForMap[] = [];
  defaultCountry: string;

  constructor(
    protected countryService: CountryService,
    protected customerService: CustomerService,
    protected global: GlobalService,
  ) {}

  ngOnInit() {
    this.initForm();
    this.getCountries();
    this.isHouse = false;
    this.showAreaError = false;
    this.defaultCountry = this.global.headers.country_id;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.openDialog?.currentValue) {
      this.title = changes?.title?.currentValue || this.title;
      this.isUpdate = changes?.isUpdate?.currentValue || this.isUpdate;
      this.openDialog = changes?.openDialog?.currentValue || this.openDialog;
      this.addressToBeUpdated =
        changes?.addressToBeUpdated?.currentValue || this.addressToBeUpdated;
      this.toggleDialog(this.openDialog);
      if (this.isUpdate) {
        this.initFormUpdate(this.addressToBeUpdated);
        console.log(this.addressToBeUpdated);
      } else {
        this.initForm();
      }
    }
  }

  onClose(refresh?: boolean) {
    this.closeModal.emit(refresh);
  }

  getCountries() {
    this.showAreas = false;
    this.showCities = false;
    this.countryService
      .getCountries(this.global.headers)
      .subscribe((response) => {
        console.log(response);

        this.countries = [];
        const country = response.data;
        country.forEach((element) => {
          this.countries.push({ value: element.id, label: element.name.en });
        });
      });
  }

  getCities(idCountry, update: { isUpdateAdd: boolean; cityName: string }) {
    this.global.headers.country_id = idCountry.toString();
    this.countryService.getListCities(this.global.headers).subscribe((data) => {
      this.cities = [];
      let cities = data.data;
      cities = cities.filter((el) => el.country_id === idCountry);
      cities.forEach((element) => {
        this.cities.push({ value: element.id, label: element.name.en });
      });
      this.showCities = true;

      if (update.isUpdateAdd) {
        console.log(update);

        this.selectedCity = this.cities.find((c) =>
          c.label.includes(update.cityName),
        )?.value;
        this.getAreas(this.selectedCity);
        console.log(this.selectedCity);
      }
    });
  }

  getAreas(govId) {
    this.countryService
      .getListAreas(this.global.headers, { page: 0 })
      .subscribe((data) => {
        this.areas = [];
        let areas = data.data;

        areas = areas.filter((el) => el.governorate_id === govId);
        areas.forEach((element) => {
          this.areas.push({
            value: element.id,
            label: element.name.en,
            lat: element.latitude,
            long: element.longitude,
          });
        });
        this.showAreas = true;
      });
  }

  countryChange(event) {
    if (this.selectedCountry) {
      this.getCities(event, { isUpdateAdd: false, cityName: null });
    }

    if (this.selectedCountry && this.selectedCity && this.selectedArea) {
      this.addressForm.setErrors(null);
    }
  }

  cityChange(event) {
    if (this.selectedCity) {
      this.getAreas(event);

      if (this.selectedCountry && this.selectedCity && this.selectedArea) {
        this.addressForm.setErrors(null);
      }
    }
  }

  areaChange() {
    if (this.selectedCountry && this.selectedCity && this.selectedArea) {
      this.addressForm.setErrors(null);
      const area = this.areas.find(({ value }) => value === this.selectedArea);
      this.lat = area.lat;
      this.long = area.long;
      this.markers = [
        {
          lng: this.long,
          lat: this.lat,
          draggable: true,
        },
      ];
    }
  }

  initForm() {
    this.addressForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, Validators.required),
      block: new UntypedFormControl(null, Validators.required),
      street: new UntypedFormControl(null, Validators.required),
      avenue: new UntypedFormControl(null),
      floor: new UntypedFormControl(null),
      houseNo: new UntypedFormControl(null),
      apartmentNo: new UntypedFormControl(null),
      description: new UntypedFormControl(null),
      isHouse: new UntypedFormControl(),
    });
    const initLonLat = this.global.getLongLat();
    this.markers = [
      {
        lng: initLonLat.longitude,
        lat: initLonLat.latitude,
        draggable: true,
      },
    ];
  }

  initFormUpdate(address: UserAddress) {
    this.addressForm = new UntypedFormGroup({
      name: new UntypedFormControl(address.name, Validators.required),
      block: new UntypedFormControl(address.block, Validators.required),
      street: new UntypedFormControl(address.street, Validators.required),
      avenue: new UntypedFormControl(address.avenue),
      floor: new UntypedFormControl(address.floor),
      houseNo: new UntypedFormControl(address.house_no),
      apartmentNo: new UntypedFormControl(address.apartment_no),
      description: new UntypedFormControl(address.directions),
      isHouse: new UntypedFormControl(),
    });

    this.lat = address.latitude;
    this.long = address.longitude;

    this.markers = [
      {
        lng: address.longitude,
        lat: address.latitude,
        draggable: true,
      },
    ];
    this.selectedCountry = address['countryId'];
    this.getCities(this.selectedCountry, {
      isUpdateAdd: true,
      cityName: address.area['city']?.name?.en,
    });
    this.selectedArea = address['areaId'];
  }

  saveAddress() {
    if (this.areasIsValid()) {
      this.prepareAddressForSaving();
      if (this.isUpdate) {
        this.customerService
          .updateCustomerAddress(
            this.global.headers,
            this.addressToBeSaved,
            this.addressToBeUpdated.id,
          )
          .subscribe((data) => {
            if (data.code === 200) {
              this.onClose(true);
            }
          });
      } else {
        this.customerService
          .createCustomerAddress(this.global.headers, this.addressToBeSaved)
          .subscribe((data) => {
            if (data.code === 200) {
              this.onClose(true);
            }
          });
      }
    } else {
      this.showAreaError = true;
    }
  }

  markerDragEnd($event) {
    this.long = $event.coords.lng;
    this.lat = $event.coords.lat;
  }

  updateMarkerFromInput(model: NgModel) {
    if (model.name === 'longitude') {
      this.markers[0].lng = model.value;
    } else {
      this.markers[0].lat = model.value;
    }
  }

  private prepareAddressForSaving() {
    this.addressToBeSaved.name = this.addressForm.controls.name.value;
    this.addressToBeSaved.block = this.addressForm.controls.block.value;
    this.addressToBeSaved.street = this.addressForm.controls.street.value;
    this.addressToBeSaved.avenue = this.addressForm.controls.avenue.value;
    this.addressToBeSaved.floor = this.addressForm.controls.floor.value;
    this.addressToBeSaved.houseNo = this.addressForm.controls.houseNo.value;
    this.addressToBeSaved.apartmentNo =
      this.addressForm.controls.apartmentNo.value;
    this.addressToBeSaved.directions =
      this.addressForm.controls.description.value || '';
    this.addressToBeSaved.areaId = this.selectedArea;
    this.addressToBeSaved.userId = this.customer.user_details.id;
    this.addressToBeSaved.latitude = this.lat;
    this.addressToBeSaved.longitude = this.long;
  }

  private toggleDialog(open: boolean): void {
    if (this.openDialog) {
      this.addressModal.show();
    } else {
      this.addressModal.hide();
    }
  }

  private areasIsValid() {
    return this.selectedArea && this.selectedCity && this.selectedCountry;
  }

  ngOnDestroy(): void {
    this.global.headers.country_id = this.defaultCountry;
  }
}
