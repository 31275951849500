import { HcPaymentMethod } from '.';

export interface HcUserTransaction {
  id: number;
  transactionType: string;
  amount: number;
  userId: number;
  countryId: number;
  groupId: number;
  paymentMethodId: number;
  couponId?: number;
  paymentKnetId?: number;
  orderId?: number;
  jcCreditOfferId?: number;
  transactionLogId?: number;
  uid?: number;
  campaignId?: number;
  orderCwGarageId?: number;
  hcOrderId: number;
  paymentMethod: HcPaymentMethod;
  deletedAt?: string;
}
