import { Component, OnInit } from '@angular/core';
import { FinanceService } from '../../services/finance.service';
import { GlobalService } from 'src/app/shared/service/global.service';
import { PaymentMethods, PaymentMethodChargesBody } from '../../models/finance';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-online-payment-charges',
  templateUrl: './online-payment-charges.component.html',
  styleUrls: ['./online-payment-charges.component.css'],
})
export class OnlinePaymentChargesComponent implements OnInit {
  paymentMethods: PaymentMethods[];
  currency: string;
  loader = false;
  beingChanged: number;
  constructor(
    private financeService: FinanceService,
    private globalService: GlobalService,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.currency = this.globalService.currency;
    this.getPaymentMethods();
  }

  saveChangesForPaymentMethod(payment: PaymentMethods) {
    this.beingChanged = payment.id;
    this.loader = true;
    const body = {
      charges_amount: payment.charges_amount,
      charges_percentage: payment.charges_percentage,
      payment_method_id: payment.id
    } as PaymentMethodChargesBody;

    this.financeService.savePaymentMethod(this.globalService.headers, body).subscribe(
      response => {
        if (response.code === 200) {
          this.getPaymentMethods();
          this.toast.success(response.message);
        } else {
          this.toast.error(response.message);
        }
        this.loader = false;
      },
      error => {
        this.loader = false;
      }
    );
  }

  private getPaymentMethods() {
    this.financeService.getPaymentMethod(this.globalService.headers, {is_online: true}).subscribe(
      response => {
        if (response.code === 200) {
          this.paymentMethods = response.data;
        } else {
          this.toast.error(response.message);
        }
      }
    );
  }
}
