// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeslot {
  height: 55px;
  border-radius: 5px;
  border: solid 1px #dddddd;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  background-color: #b2b2b2;
}
.timeslot span {
  font-size: 25px;
  color: gray;
}

.driver-container {
  border-radius: 5px;
  box-shadow: 0px 0px 3px 1px rgb(204, 196, 204);
}`, "",{"version":3,"sources":["webpack://./src/app/modules/logistics/shared/assign-dirvers/assign-dirvers.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,qBAAA;EACA,sBAAA;EAKA,yBAAA;AAHJ;AADI;EACI,eAAA;EACA,WAAA;AAGR;;AAEA;EAEI,kBAAA;EAGA,8CAAA;AAAJ","sourcesContent":[".timeslot {\n    height: 55px;\n    border-radius: 5px;\n    border: solid 1px #dddddd;\n    display: flex;\n    justify-content: center;\n    align-content: center;\n    flex-direction: column;\n    span {\n        font-size: 25px;\n        color: gray;\n    }\n    background-color: #b2b2b2;\n}\n\n.driver-container {\n    //border: #b2b2b2 1px solid;\n    border-radius: 5px;\n    -webkit-box-shadow: 0px 0px 3px 1px rgba(204, 196, 204, 1);\n    -moz-box-shadow: 0px 0px 3px 1px rgba(204, 196, 204, 1);\n    box-shadow: 0px 0px 3px 1px rgba(204, 196, 204, 1);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
