import { CostDayLevel } from '@app/shared/enums';
import { Translation } from 'libs/justclean-models/src/lib/justclean-models';
import { HcOrder, HcPackageHour, HcServiceTimeSlot, HcOrderJobStaff } from '.';

export interface LogHcOrder {
  id: number;
  hcOrderId: number;
  userAdminId: number;
  userId: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
  userJlm: {
    firstName: Translation;
    lastName: Translation;
    id: number;
    phoneNumber?: string;
    photoUrl?: string;
  };
  userAdmin: {
    name: string;
    id: number;
  };
  statusesUser: {
    id: number,
    name: Translation,
    status: string
  }
}

export interface HcOrderJob {
  id: number;
  billAmountTotal: number;
  billAmount: number;
  extraDiscounts: number;
  vatAmount: number;
  sessionDiscountAmount: number;
  packageId: number;
  packageHoursId: number;
  hcOrderId: number;
  hcOrder: HcOrder;
  noOfWorkers: number;
  cleaningAmount: number;
  hasSupplies: true;
  suppliesAmount: number;
  startTimeId: number;
  startDateId: null;
  endTimeId: number;
  statusesUserId: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
  hcServicePackage: any;
  hcServicePackageHours: HcPackageHour;
  startTime: HcServiceTimeSlot;
  endTime: HcServiceTimeSlot;
  startDate: any;
  statusesUser: {
    name: Translation;
    description: Translation;
    id: number;
    icon: string;
    status: string;
  };
  hcOrderJobStaff: HcOrderJobStaff[];
  costDayLevel?: CostDayLevel;
  timeCostAmount?: number;
  sessionDiscountCleaningAmount: number;
  sessionDiscountSuppliesAmount: number;
  sessionDiscountTimeCostAmount: number;
  sessionDiscountTotalAmount: number;
  logs: LogHcOrder[];
}
