import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BranchListComponent } from './components/branch-list/branch-list.component';
import { CreateBranchComponent } from './components/create-branch/create-branch.component';
import { BranchInfoComponent } from './components/create-branch/branch-info/branch-info.component';
import { BranchAreasComponent } from './components/create-branch/branch-areas/branch-areas.component';
import { BranchTimeslotsComponent } from './components/create-branch/branch-timeslots/branch-timeslots.component';
import { BranchSettingsComponent } from './components/create-branch/branch-settings/branch-settings.component';
import { BranchItemPriceComponent } from './components/create-branch/branch-item-price/branch-item-price.component';
import { CreateMinOrderPerAreaComponent } from './components/create-branch/create-min-order-per-area/create-min-order-per-area.component';
import { DiscountComponent } from './components/create-branch/discount/discount.component';
import { ListAuthDevicesComponent } from './devices/list-auth-devices/list-auth-devices.component';
import { BranchTatComponent } from './components/create-branch/branch-tat/branch-tat.component';
import { SpecialIconsComponent } from './components/create-branch/special-icons/special-icons.component';
import { PosContractComponent } from './components/create-branch/pos-contract/pos-contract.component';
import { SplashBannerComponent } from './components/create-branch/splash-banner/splash-banner.component';
import { BranchBillingComponent } from './components/create-branch/branch-billing/branch-billing.component';
import { BranchContractComponent } from './components/create-branch/branch-contract/branch-contract.component';
import { BranchUserTypesComponent } from './components/create-branch/branch-user-types/branch-user-types.component';

const routes: Routes = [
  {
    path: 'min-order',
    component: CreateMinOrderPerAreaComponent,
    data: {
      title: 'Branch Min Order'
    },
  },
  {
    path: 'branch-list/:id',
    component: BranchListComponent,
    data: {
      title: 'List of Branches'
    }
  },
  {
    path: 'create-branch/:id',
    component: CreateBranchComponent,
    children: [
      {
        path: 'step1',
        component: BranchInfoComponent,
        data: {
          title: 'Create branch Info'
        }
      },
      {
        path: 'step2/:branchId/:update',
        component: BranchAreasComponent,
        data: {
          title: 'Branch Areas'
        }
      },
      {
        path: 'step3/:branchId/:update',
        component: BranchTimeslotsComponent,
        data: {
          title: 'Branch TimeSlots'
        }
      },
      {
        path: 'step4/:branchId/:update',
        component: BranchSettingsComponent,
        data: {
          title: 'Branch Settings'
        }
      },
      {
        path: 'item-price/:branchId/:update',
        component: BranchItemPriceComponent,
        data: {
          title: 'Branch Prices'
        }
      },
      {
        path: 'discount/:branchId',
        component: DiscountComponent,
        data: {
          title: 'Branch Discounts'
        }
      },
      {
        path: 'tat/:branchId',
        component: BranchTatComponent,
        data: {
          title: 'Branch Tat Services'
        }
      },
      {
        path: 'special-icons/:branchId',
        component: SpecialIconsComponent,
        data: {
          title: 'Branch Special Icons'
        }
      },
      {
        path: 'pos-contract/:branchId',
        component: PosContractComponent,
        data: {
          title: 'Branch Pos Contract'
        }
      },
      {
        path: 'advertisement/:branchId',
        component: SplashBannerComponent,
        data: {
          title: 'Branch Splash Banner'
        }
      },
      {
        path: 'billing/:branchId',
        component: BranchBillingComponent,
        data: {
          title: 'Branch Billing'
        }
      },
      {
        path: 'branch-user-types/:branchId',
        component: BranchUserTypesComponent,
        data: {
          title: 'Branch User Types'
        }
      },
      {
        path: 'contract/:branchId',
        component: BranchContractComponent,
        data: {
          title: 'Branch Contract'
        }
      },
      {
        path: '',
        redirectTo: 'step1',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'devices',
    component: ListAuthDevicesComponent,
    data: {
      title: 'Branch Devices'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BranchRoutingModule {}
