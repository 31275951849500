import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  CarWashPackagePricingListComponent
} from "@app/modules/carwash/modules/car-wash-package-pricing/components/car-wash-package-pricing-list/car-wash-package-pricing-list.component";

const routes: Routes = [
  {
    path: 'package-pricing/:id/list',
    component: CarWashPackagePricingListComponent,
    data: { title: 'Branch Pricing' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CarWashPackagePricingRoutingModule {}
