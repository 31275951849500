import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { LaundryService } from '../../../../shared/service/laundry.service';
import { GlobalService } from '../../../../shared/service/global.service';
import { LaundryFromList } from '../../../../shared/models/laundries/laundry';
import { PageInfo } from '../../../../shared/models/global/response';
import { CountryService } from '../../../../shared/service/country.service';
import { AreaList } from '../../../../shared/models/countries/country';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { map, mergeMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { AppHeaders } from "@shared/models/user/generic-backend";
import { GroupService } from "@shared/service/group";
import { Group } from "@shared/models";

@Component({
  selector: 'app-laundry-list',
  templateUrl: './laundry-list.component.html',
  styleUrls: ['./laundry-list.component.scss'],
})
export class LaundryListComponent implements OnInit {
  laundries: LaundryFromList[];
  filteredLaundries: LaundryFromList[];
  pagination: PageInfo;
  rate: any;
  status: any;
  statusModal: string;
  rateModel: number;
  areas;
  areaModel: number;
  search: string;
  isFilterApplies = false;
  page = 1;
  selectedLaundryForAction: LaundryFromList;
  @ViewChild('confirmTerminate', { static: true })
  confirmTerminate: ModalDirective;
  @ViewChild('confirmStatus', { static: true })
  confirmStatus: ModalDirective;
  loader = false;
  showGroupSelector = true;
  isLoadingGroups = false;
  isLoadingLaundries = false;
  groupId: number;
  private headers: AppHeaders = {};

  selectedGroup: Group;
  groups$: Observable<Array<Group>>;

  constructor(
    private globalService: GlobalService,
    private laundryService: LaundryService,
    private countryService: CountryService,
    private router: Router,
    private route: ActivatedRoute,
    private groupsService: GroupService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.queryParams)
    )
      .subscribe((params) => {
        if (params.groupId) {
          this.showGroupSelector = false;
          this.groupId = params.groupId;
          this.headers.group_id = `${this.groupId}`;
          this.getLaundries({ page: this.page });
        }
      });
  }

  ngOnInit() {
    this.headers = this.globalService.headers;
    if (this.groupId) {
      this.headers.group_id = `${this.groupId}`;
    }
    this.page = this.route.snapshot.queryParams.page
      ? this.route.snapshot.queryParams.page
      : 1;
    this.initFilters();


    this.loadLaundryGroups();
    this.setGroup();
    if (this.groupsService.selectedGroup) {
      this.selectedGroup = this.groupsService.selectedGroup;
    }
  }

  getLaundries(params?: any) {
    this.isLoadingLaundries = true
    if (params) {
      this.laundryService
        .getLaundriesWithParams(this.headers, params)
        .subscribe(response => {
          this.isLoadingLaundries = false;
          this.laundries = response.data;
          this.filteredLaundries = response.data;
          this.pagination = response.page_info;
        });
    } else {
      this.laundryService
        .getLaundries(this.headers)
        .subscribe(response => {
          this.isLoadingLaundries = false;
          this.laundries = response.data;
          this.filteredLaundries = response.data;
          this.pagination = response.page_info;
        });
    }

    this.changeDetectorRef.detectChanges();
  }

  getPage(number: number) {
    this.getLaundries({ page: number });
  }

  initFilters() {
    this.statusModal = null;
    this.areaModel = null;
    this.rateModel = null;
    this.search = null;
    this.isFilterApplies = false;
    this.rate = [
      { value: 0, label: 0 },
      { value: 1, label: 1 },
      { value: 2, label: 2 },
      { value: 3, label: 3 },
      { value: 4, label: 4 },
      { value: 5, label: 5 }
    ];

    this.status = [
      {
        value: 'active',
        label: 'active'
      },
      {
        value: 'inactive',
        label: 'inactive'
      }
    ];

    this.countryService
      .getListAreas(this.headers, { page: 0 })
      .subscribe(response => {
        const areas = (response.data as AreaList[]).filter(
          area => area.country_id === this.globalService.countryId
        );
        this.areas = [];
        areas.forEach(element => {
          this.areas.push({
            value: element.id,
            label: element.name.en
          });
        });
      });
    this.setGroup()
  }

  filter() {
    this.isFilterApplies = true;
    const filters: any = {
      searchstr: this.search || undefined,
      area_id: this.areaModel || undefined,
      rating: this.rateModel || undefined,
      status: this.statusModal || undefined,
    };
    Object.keys(filters).forEach(key => filters[key] === undefined ? delete filters[key] : {});
    this.getLaundries(filters);
  }
  changeStatus(laundry: LaundryFromList, isLogistics?: boolean) {
    const status = {
      laundry_id: laundry.id,
      status: laundry.status === 'active' ? 'inactive' : 'active',
      is_logistics: laundry.is_logistics ? false : true
    };
    if (isLogistics) {
      delete status.status;
    } else {
      delete status.is_logistics;
      this.loader = true;
    }
    this.laundryService
      .changeStatus(this.headers, status)
      .subscribe(
        response => {
          if (this.isFilterApplies) {
            this.filter();
            this.loader = false;
            this.confirmStatus.hide();
          } else {
            this.getLaundries({ page: this.pagination.current_page });
            if (!isLogistics) {
              this.loader = false;
              this.confirmStatus.hide();
            }
          }
        },
        error => {
          if (!isLogistics) {
            this.loader = false;
          }
        }
      );
  }

  terminateDisabled(laundry: LaundryFromList) {
    return laundry.status === 'active' ? true : false;
  }

  openTerminateConfirm(laundry: LaundryFromList) {
    this.selectedLaundryForAction = laundry;
    this.confirmTerminate.show();
  }
  openStatusConfirm(laundry: LaundryFromList) {
    this.selectedLaundryForAction = laundry;
    this.confirmStatus.show();
  }

  terminate() {
    this.loader = true;
    this.laundryService
      .terminateLaundry(this.headers, {
        laundry_id: this.selectedLaundryForAction.id,
        is_terminated: !this.selectedLaundryForAction.terminate
      })
      .subscribe(
        response => {
          if (response.code === 200) {
            this.loader = false;
            this.confirmTerminate.hide();
          }
        },
        error => {
          this.loader = false;
        }
      );
  }

  showColor() {
    return this.selectedLaundryForAction.terminate ? 'success' : 'danger';
  }
  onClose(event) {
    if (this.isFilterApplies) {
      this.filter();
    } else {
      this.getLaundries({ page: this.pagination.current_page });
    }
  }

  goToBranch(laundry: LaundryFromList) {
    window.localStorage.setItem('isLog', laundry.is_logistics + '');
    window.localStorage.setItem('module', 'branches');
    this.router.navigate([`dashboard/branches/branch-list/${laundry.id}`], {
      queryParams: { groupId: this.groupId },
    });
  }

  loadLaundryGroups(): void {
    this.isLoadingGroups = true;
    this.groups$ = this.groupsService.getGroups().pipe(
      map((groups) => {
        if (this.groupId || this.selectedGroup) {
          /** This to bind with groups dropdown */
          this.selectedGroup = groups.find(
            ({ id }) => id === this.groupId || id === this.selectedGroup?.id,
          );
        } else {
          // assign the first group by default
          this.selectedGroup = groups.find(() => true);
        }

        console.log('selected group = ', this.selectedGroup)
        this.isLoadingGroups = false;
        return groups.filter((group) =>
          this.groupsService.checkLaundryDesign(group),
        );
      }),
    );
  }

  setGroup(): void {
    this.headers.group_id = this.selectedGroup?.id ? this.selectedGroup.id.toString() : `${this.groupId || '1'}`;

    this.laundries = [];
    this.getLaundries({ page: this.page });
  }
}
