import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CarwashPackageListComponent } from './components/carwash-package-list/carwash-package-list.component';

const routes: Routes = [
  {
    path: 'packages/:id/list',
    component: CarwashPackageListComponent,
    data: { title: 'Car wash Branch package' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CarwashPackageRoutingModule {}
