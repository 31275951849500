import { map, takeUntil, finalize } from 'rxjs/operators';
import { CountryService } from 'src/app/shared/service/country.service';
import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { DIALOG_DATA } from '@app/shared/components/dialog/services/dialog.token';
import { Country } from '@app/shared/models/user/user';
import { Observable, Subject } from 'rxjs';
import { DialogRef } from '@app/shared/components/dialog/dialog-ref/dialog-ref';
import { GroupService } from '@shared/service/group';
import { GroupCountry, Variant } from '@app/shared/models';
import { VariantService } from '@app/shared/service/variant';

@Component({
  selector: 'app-assign-country-dialog',
  templateUrl: './assign-country-dialog.component.html',
  styleUrls: ['./assign-country-dialog.component.scss'],
})
export class AssignCountryDialogComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>() ;

  countries$: Observable<Array<Country>>;
  countries: Array<Country>;
  countriesOfGroup: Array<GroupCountry>;
  variantSelectedCountries: Array<GroupCountry>;
  isLoading: boolean;
  countriesOfGroupToUpdate: Array<number> = [];
  selectAll: boolean;

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: Variant | any,
    private countryService: CountryService,
    private readonly variantService: VariantService,
    private readonly dialogRef: DialogRef<AssignCountryDialogComponent>,
    private readonly cd: ChangeDetectorRef,
    private readonly groupService: GroupService,
  ) {}

  ngOnInit(): void {
    this.variantSelectedCountries = this.data.countryVariants || [];
    this.loadCountries();
    this.loadCountriesOfGroup();
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.isLoading = true;
    const selectedCountries = this.countries?.filter(
      (country) => country['isChecked'],
    );
    const payload: Partial<Variant> = {
      countryVariants: selectedCountries.map((country) => ({
        countryId: country.id,
      })) as any,
    };
    this.variantService
      .updateVariant(this.data.id, payload)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isLoading = false;
          this.cd.detectChanges();
        }),
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  checkIsSelectAll(): void {
    this.selectAll = this.countries.every(country => country['isChecked']);
  }

  toggleSelectAll(): void {
    this.selectAll = !this.selectAll;
    this.countries.forEach(
      (country) => (country['isChecked'] = this.selectAll),
    );
    this.cd.detectChanges();
  }

  private loadCountries(): void {
    this.countries$ = this.countryService.getCountries({}).pipe(
      map((res) => {
        this.countries = res.data;
        this.setEligibleCountries();
        return res.data;
      }),
    );
  }

  private loadCountriesOfGroup() {
    this.groupService
      .getGroupCountries(this.data.groupId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.countriesOfGroup = res;
        this.setEligibleCountries();
      });
  }

  private setEligibleCountries(): void {
    if (this.countriesOfGroup?.length && this.countries?.length) {
      this.countries = this.countriesOfGroup.map((el) =>
        this.countries.find((country) => el.countryId === country.id),
      );
      this.setSelectedCountries();
      this.checkIsSelectAll();
      this.cd.detectChanges();
    }
  }

  private setSelectedCountries(): void {
    this.countries.forEach(
      (country) =>
        (country['isChecked'] = this.variantSelectedCountries.find(
          (el) => el.countryId === country.id,
        )),
    );
  }

  private unsubscribe(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
