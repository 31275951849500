import { CarwashTicketService } from '@app/modules/carwash/modules/carwash-packages/shared/services/carwash-ticket.service';
import { CarwashTicket } from '@app/modules/carwash/modules/carwash-packages/shared/models/carwash-ticket-model';
import { VariantService } from '@shared/service/variant';
import { finalize, map, takeUntil } from 'rxjs/operators';
import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { DIALOG_DATA } from '@app/shared/components/dialog/services/dialog.token';
import { Observable, Subject } from 'rxjs';
import { DialogRef } from '@app/shared/components/dialog/dialog-ref/dialog-ref';
import { SubVariant } from '@app/shared/models';

@Component({
  selector: 'app-assign-variant-dialog',
  templateUrl: './assign-variant-dialog.component.html',
  styleUrls: ['./assign-variant-dialog.component.scss'],
})
export class AssignVariantDialogComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>() ;

  variantList$: Observable<Array<SubVariant>>;
  variantList: Array<SubVariant>;
  selectedVariantId: number;
  isLoading: boolean;

  constructor(
    @Inject(DIALOG_DATA)
    public readonly carwashTicket: {
      ticket: CarwashTicket | any;
      groupId: number;
    },
    private readonly variantService: VariantService,
    private readonly carwashTicketService: CarwashTicketService,
    private readonly dialogRef: DialogRef<AssignVariantDialogComponent>,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadVariantList();
    this.selectedVariantId = this.carwashTicket?.ticket?.subVariantId;
  }

  close(isSubmit?: boolean): void {
    this.dialogRef.close(isSubmit);
  }

  save(): void {
    this.isLoading = true;
    this.carwashTicketService
      .assignVariant(this.carwashTicket?.ticket?.id, this.selectedVariantId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.cd.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.close(true));
  }

  private loadVariantList(): void {
    this.variantList$ = this.variantService
      .getVariants(
        this.carwashTicket?.groupId
          ? { groupId: this.carwashTicket?.groupId?.toString() }
          : {},
      )
      .pipe(
        map((variants) => {
          const result: Array<SubVariant> = [];
          variants.forEach((variant) => {
            variant.subVariants.forEach((subVariant) => {
              result.push({
                ...subVariant,
                name: !variant.hasMultipleVariants
                  ? variant.name
                  : {
                      en: `${variant.name?.en} - ${subVariant.name?.en}`,
                      ar: `${variant.name?.ar} - ${subVariant.name?.ar}`,
                    },
              });
            });
          });
          this.variantList = result;
          return result;
        }),
      );
  }

  private unsubscribe(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
