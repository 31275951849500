import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeliveryOrdersComponent } from './components/delivery-orders/delivery-orders.component';
import { JcDelUserListComponent } from './components/user-list/jc-del-user-list.component';
import { JcDelUserProfileComponent } from './components/jc-del-user-profile/jc-del-user-profile.component';

const routes: Routes = [
  {
    path: 'order-management',
    component: DeliveryOrdersComponent,
  },
  {
    path: 'users-management',
    component: JcDelUserListComponent,
  },
  {
    path: 'users-profile/:id',
    component: JcDelUserProfileComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class JcDeliverRoutingModule {}
