import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homeclean-service-list',
  templateUrl: './homeclean-service-list.component.html',
  styleUrls: ['./homeclean-service-list.component.scss'],
})
export class HomecleanServiceListComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
