import { takeUntil, finalize } from 'rxjs/operators';
import { CancelReason } from '@app/shared/models';
import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { DialogRef } from '@app/shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@app/shared/components/dialog/services/dialog.token';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  CarwashOrder,
  CwGarageBranchTicket,
} from '../shared/models/carwash-order.model';
import { CarwashOrderService } from '../shared/services/carwash-order.service';
import { CancelReasonsService } from '@app/shared/service/home-cleaning/hc-cancel-reasons.service';

@Component({
  selector: 'app-cw-cancel-order-dialog',
  templateUrl: './cw-cancel-order-dialog.component.html',
  styleUrls: ['./cw-cancel-order-dialog.component.scss'],
})
export class CwCancelOrderDialogComponent implements OnInit {
  private readonly destroy$: Subject<void> = new Subject<void>() ;

  isLoading: boolean;
  reasons$: Observable<CancelReason[]> = null;
  isConfirmationOpen: boolean;
  selectedReason: CancelReason;
  anotherReason: string = '';
  maxReasonLength = 350;
  private subscribers$: Array<Subscription> = [];

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data:
      | {
          order: CarwashOrder;
          ticket: CwGarageBranchTicket;
          callbackFn: Function;
        }
      | any,
    private readonly cancelReasonsService: CancelReasonsService,
    private readonly carwashOrderService: CarwashOrderService,
    private readonly dialogRef: DialogRef<CwCancelOrderDialogComponent>,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadCancelReasons();
  }

  close(isRefresh?: boolean): void {
    if (isRefresh) {
       this.data.callbackFn();
    }
    this.dialogRef.close();
  }

  openCancelConfirmation(): void {
    this.isConfirmationOpen = true;
  }
  closeCancelConfirmation(): void {
    this.isConfirmationOpen = false;
  }

  cancelOrder() {
    this.unsubscribe();
    this.isLoading = true;
    const userAdminId = localStorage.getItem('userId');
    const subscriber$ = this.carwashOrderService
      .updateOrderStatus(
        this.data.ticket.id,
        9,
        +userAdminId,
        this.selectedReason.id,
        this.anotherReason ? this.anotherReason : this.selectedReason.name.en,
      )
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(() => {
        this.close(true);
        this.isConfirmationOpen = false;
      });
    this.subscribers$.push(subscriber$);
  }

  private loadCancelReasons(): void {
    if (this.data.order) {
      this.reasons$ = this.cancelReasonsService.getCancelReasons({
        type: 'cancel',
        platform: 'admin',
        groupId: this.data.order.groupId,
      });
    }
    this.cd.detectChanges();
  }

  private unsubscribe(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscribers$.forEach((subscriber$) => subscriber$.unsubscribe());
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
