import { GroupService } from '@shared/service/group';
import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  HostListener,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../service/global.service';
import { LoggedinUser } from '../../models/user/user';
import { Router, ActivationEnd } from '@angular/router';
import { CountryService } from '../../service/country.service';
import { CountryList } from '../../models/countries/country';
import { Title } from '@angular/platform-browser';
import { catchError, filter, takeUntil } from "rxjs/operators";
import { Group } from '@app/shared/models';
import { Crud } from '@app/shared/models';
import { CrudService } from '@app/shared/service/crud';
import { MenuItem } from '@app/shared/models/panel/menu-item';
import { MENU_ITEMS_LIST } from '@app/shared/service/panel-menu/menu-items-list';
import { of, Subject, Subscription } from "rxjs";
import { environment } from '@env/environment';
import { DOCUMENT } from '@angular/common';
import { envVariables } from './../../../shared/enums/crud/environment-variable.enum';
@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  lang: string;
  user: LoggedinUser;
  allCountries: CountryList[];
  countries: CountryList[];
  countryName: string;
  menuItems: Array<MenuItem>;

  isOpsLeadersBounded: Function;
  isLogTeamBounded: Function;
  isCouponUserBounded: Function;
  isCorpTeamBounded: Function;
  isFinanceTeamBounded: Function;
  isMarketingTeamBounded: Function;
  showPartnersBounded: Function;
  isSuperAdminBounded: Function;
  isLiveOrdersManagementTeamBounded: Function;
  isPriorityBookingViewingManagementTeamBounded: Function;
  isMobileResolution: boolean;
  private window: Window;
  selectedMenu: string;

  private subscribers$: Array<Subscription> = [];

  constructor(
    public translate: TranslateService,
    private global: GlobalService,
    private router: Router,
    protected country: CountryService,
    protected groupService: GroupService,
    private crudService: CrudService,
    protected pageTitle: Title,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    this.initializeWindowObject();
    this.global.getUser$().subscribe({
      next: (data) => {
        this.user = data ? (data as LoggedinUser) : null;
        this.crudService.user = this.user;
        this.crudService.isSuperAdmin = this.isSuperAdmin();
        this.country.getCountries(this.global.headers).subscribe((response) => {
          this.allCountries =
            response.code === 200 ? (response.data as CountryList[]) : [];
          if (this.user?.role_id === 5 && this.user?.assigned_country) {
            this.countries = JSON.parse(
              JSON.stringify(this.allCountries),
            ).filter((e) =>
              this.hasCountryId(e.id, this.user.assigned_country),
            );
            this.countries.push(
              this.allCountries.find((c) => c.id === this.user.country_id),
            );
          } else {
            this.countries = this.allCountries;
          }
          this.countryName = this.getCountry(
            this.global.countryId,
          ).country_code;
          setTimeout(() => {
            window.localStorage.setItem(
              'countryCode',
              this.getCountry(this.global.countryId).country_code,
            );

            window.localStorage.setItem(
              'currency',
              this.getCountry(this.global.countryId).currency,
            );
            window.localStorage.setItem(
              'timeZone',
              this.getCountry(this.global.countryId).time_zone,
            );
            window.localStorage.setItem(
              'decimal',
              this.getCountry(
                this.global.countryId,
              ).currency_decimal.toString(),
            );
            this.global.decimal = this.getCountry(
              this.global.countryId,
            ).currency_decimal;
            window.localStorage.setItem(
              'tax',
              this.getCountry(this.global.countryId).tax.toString(),
            );
            this.global.countryCodeSubject.next(
              this.getCountry(this.global.countryId).country_code,
            );
          }, 1000);
          this.countries = this.distinctArray(this.countries);
        });
      },
      error: (error) => { console.log('user error: ',error) },
    })
    this.lang = this.translate.defaultLang;
    this.translate.onDefaultLangChange.subscribe((data) => {
      this.lang = data.lang;
    });
    this.router.events
      .pipe(filter((e) => e instanceof ActivationEnd))
      .subscribe((event) => {
        this.setCountryInLocalStorage(
          parseInt(window.localStorage.getItem('country'), 10),
        );
      });
    this.setBoundedFunctions();
    this.loadActivePages();
  }

  hideNavbar(e: any): void {
    e.hide();
  }

  private initializeWindowObject() {
    this.window = this.document.defaultView;
    this.checkMobileResolution();
  }

  @HostListener('window:resize', ['$event'])
  checkMobileResolution(): void {
    this.isMobileResolution = this.window.innerWidth < 576;
  }

  goToPage(page: Crud): void {
    this.crudService.selectedCrud = page;
    this.router.navigate([
      `dashboard/crud/page/${page.pageName?.toLowerCase()}`,
    ]);
  }

  changeCountry(event: number) {
    this.setCountryInLocalStorage(event);
    window.location.reload();
  }

  isOpsLeaders() {
    const allwedUsers = [
      'rcherchali',
      'balqatari',
      'superadmin',
      'dataallah',
      'mseid',
      'kshaik',
      'asakran',
    ];
    return this.isAllowedUser(allwedUsers);
  }

  isLogTeam() {
    const allwedUsers = [
      'rcherchali',
      'aalsaleh',
      'superadmin',
      'mmarzouk',
      'pkumar',
      'kshaik',
      'skadam',
      'isafarulla',
      'hebrahim',
      'aalenezi',
      'mahmedisafarulla',
      'aabdulatifatala',
      'aabdulatifoalyousif',
      'aabdulatif',
    ];
    return this.isAllowedUser(allwedUsers);
  }

  isCouponUser() {
    const allwedUsers = [
      'rcherchali',
      'superadmin',
      'balqatari',
      'mseid',
      'hebrahim',
      'asakran',
      'kshaik',
      'dataallah',
    ];
    return this.isAllowedUser(allwedUsers);
  }

  isCorpTeam() {
    const allwedUsers = [
      'aibrahim',
      'felbib',
      'malalawi',
      'ashaukat',
      'swala',
      'arizvi',
      'shassaan',
      'stalreja',
      'aalenezi',
      'hebrahim',
      'aahmed',
      'superadmin',
      'afadhul',
      'pkumar',
      'kshaik',
      'ssingh',
      'skadam',
      'aabdulatif',
      'isafarulla',
    ];
    return this.isAllowedUser(allwedUsers);
  }
  isFinanceTeam() {
    const allwedUsers = [
      'superadminkw',
      'hshams',
      'aelmorsy',
      'mmendonca',
      'melgamily',
      'melshandawily',
      'aalnashaba',
      'mellewaa',
      'aalenezi',
      'nalenezi',
      'superadmin',
      'kshaik',
    ];
    return this.isAllowedUser(allwedUsers);
  }

  private isAllowedUser(allwedUsers: Array<string>): boolean {
    if (this.user?.email) {
      return !!allwedUsers.find((user) => this.user?.email?.includes(user));
    }
    return false;
  }

  isSuperAdmin() {
    return environment.production && environment.name === 'prod'
      ? this.user?.email === environment.superAdminEmail
      : this.user?.email.includes(environment.superAdminEmail);
  }

  isLiveOrdersManagementTeam() {
    return environment.production && environment.name === 'prod'
      ? environment.liveOrdersManagementEmails.includes(this.user?.email)
      : this.user?.email.includes(environment.superAdminEmail);
  }

  isMarketingTeam() {
    return environment.production && environment.name === 'prod'
      ? environment.marketingTeamEmails.includes(this.user?.email)
      : this.user.email.includes(environment.superAdminEmail);
  }

  isPriorityBookingViewingManagementTeam() {
    return environment.production && environment.name === 'prod'
      ? environment.priorityBookingViewingManagementEmails.includes(
          this.user?.email,
        )
      : this.user.email.includes(environment.superAdminEmail);
  }

  logout() {
    this.global.logout().subscribe((data) => {
      if (data) {
        this.router.navigate(['/login']);
      }
    });
  }

  showPartners() {
    const showPartners =
      this.global.countryId && this.countries
        ? this.getCountry(this.global.countryId).has_partners
        : false;
    window.localStorage.setItem('showPartner', showPartners + '');
    return showPartners;
  }

  private getCountry(countryId: number): CountryList | undefined {
    return (
      this.allCountries && this.allCountries.find((e) => e.id === countryId)
    );
  }

  private hasCountryId(id: number, list: number[]): boolean {
    return list.find((e) => e === id) ? true : false;
  }

  setCountryInLocalStorage(countryId: number) {
    window.localStorage.setItem('country', countryId.toString());
    this.global.setHeaders();
    const country = this.getCountry(countryId);
    if (country) {
      this.countryName = country.name.en;
      window.localStorage.setItem(
        'currency',
        country.currency_symbol,
      );
      window.localStorage.setItem(
        'countryCode',
        country.country_code,
      );
      window.localStorage.setItem(
        'timeZone',
        country.time_zone,
      );
      this.global.countryCodeSubject.next(
        country.country_code,
      );
    }
  }

  distinctArray(array: CountryList[]) {
    return Array.from(new Set(array.map((s) => s.id))).map((id) => {
      return this.allCountries.find((e) => e.id === id);
    });
  }

  openGroupOrders(group: Group): void {
    this.groupService.selectedGroup = group;
    this.router.navigate([`/dashboard/groups/${group.id}/orders`]);
  }

  private loadGroups(): void {
    this.subscribers$.push(
      this.groupService.getGroups().subscribe((groups) => {
        groups.forEach((group) => {
          const verticalsMenuItem = this.menuItems.find(
            (menuItem) => menuItem.id === 2,
          );
          verticalsMenuItem.children?.push({
            name: `${group.name?.en} orders`,
            clickAction: this.openGroupOrders.bind(this, group),
          });
        });
      }),
    );
  }

  private loadActivePages() {
    this.subscribers$.push(
      this.crudService.getActivePages()
        .pipe(
          takeUntil(this.onDestroy$),
          catchError(message => {
            console.log(message);
            this.setPredefinedMenuItems()
            return of(null);
          })
        ).subscribe(
          (pages) => {
            if (pages && pages.length) {
              this.setPermissionEnvironmentVariables(pages[0]);
            }
          this.setPredefinedMenuItems();
          this.menuItems.forEach((parentMenuItem) => {
            const staticChidlren: MenuItem[] = parentMenuItem.children;
            const generatedPages: Crud[] = pages.filter(
              (page) =>
                !page.isHideFromMenu &&
                +page.parentMenuItemId === parentMenuItem.id,
            );

            const generatedChidlren = generatedPages.map((page) => ({
              name: this.getMenuItemPageName(page),
              clickAction: this.goToPage.bind(this, page),
              methodNameToDisplay: page.isSuperAdmin
                ? 'isSuperAdminBounded'
                : null,
            }));

            parentMenuItem.children = generatedChidlren;

            const staticChildrenIndex = parentMenuItem.isGeneratedPagesFirst
              ? generatedChidlren.length
              : 0;

            parentMenuItem.children.splice(
              staticChildrenIndex,
              0,
              ...staticChidlren,
            );
          });
        },
      ),
    );
  }

  private setPermissionEnvironmentVariables(firstPage: Crud): void {
    const envVars = envVariables;

    let environmentVariables = [];
    // temp fix for undefined ENV_VARIABLE import
    if (envVariables) {
      environmentVariables = Object.keys(envVars)
    } else {
      environmentVariables = ['liveOrdersManagementEmails',  'priorityBookingViewingManagementEmails', 'marketingTeamEmails']
    }

    environmentVariables.forEach((key) =>
      this.setEnvironmentVariableEmails(
        key as envVariables,
        firstPage.permissionsConfig?.[key],
      ),
    );
  }

  private setEnvironmentVariableEmails(
    variableName: envVariables,
    emails: string,
  ): void {
    const splittedEmails = emails?.split(',');
    if (splittedEmails?.length && splittedEmails[0]) {
      environment[variableName] = splittedEmails;
    }
  }

  private setPredefinedMenuItems(): void {
    this.menuItems = JSON.parse(JSON.stringify(MENU_ITEMS_LIST));
    this.loadGroups();
  }

  private getMenuItemPageName(page: Crud): string {
    return this.crudService.getMenuItemPageName(page);
  }

  private setBoundedFunctions(): void {
    this.isOpsLeadersBounded = this.isOpsLeaders.bind(this);
    this.isLogTeamBounded = this.isLogTeam.bind(this);
    this.isCouponUserBounded = this.isCouponUser.bind(this);
    this.isCorpTeamBounded = this.isCorpTeam.bind(this);
    this.isFinanceTeamBounded = this.isFinanceTeam.bind(this);
    this.showPartnersBounded = this.showPartners.bind(this);
    this.isSuperAdminBounded = this.isSuperAdmin.bind(this);
    this.isLiveOrdersManagementTeamBounded =
      this.isLiveOrdersManagementTeam.bind(this);
    this.isMarketingTeamBounded = this.isMarketingTeam.bind(this);
    this.isPriorityBookingViewingManagementTeamBounded =
      this.isPriorityBookingViewingManagementTeam.bind(this);
  }

  ngOnDestroy(): void {
    this.subscribers$.forEach((subscriber$) => subscriber$.unsubscribe());

    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
