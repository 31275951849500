import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { GlobalService } from 'src/app/shared/service/global.service';
import { DriversService } from 'src/app/shared/service/drivers.service';
import { DriverList } from '../../../../shared/models/cars/driver';
import { PageInfo } from '../../../../shared/models/global/response';
import { LogDriverFromList } from 'src/app/shared/models/cars/log-driver';
import { LogDriverService } from 'src/app/shared/service/log-driver.service';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-drivers-list',
  templateUrl: './drivers-list.component.html',
  styleUrls: ['./drivers-list.component.css']
})
export class DriversListComponent implements OnInit {
  drivers: DriverList[];
  pagination: PageInfo;
  branchId: number;
  laundryId: number;
  isShown: boolean;
  toggleDeleteModal = false;
  loader = false;
  driverToBeDeleted: DriverList;
  constructor(
    protected globalService: GlobalService,
    private driversService: DriversService,
    private logisticsDriver: LogDriverService,
    private route: ActivatedRoute,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.branchId = this.route.snapshot.params.branchId;
    this.laundryId = this.route.snapshot.params.laundryId;
    this.getDrivers(this.branchId);
  }

  getDrivers(branchId: number, params?: any) {
    this.drivers = [];
    this.driversService
      .getDriver(this.globalService.headers, branchId)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.drivers = response.data;
            this.pagination = response.page_info;
          }
        }
      );
  }

  getPage(event) {
    this.getDrivers(this.branchId, { page: event });
  }

  changeStatus(id, status) {
    const statusToSend = status === 'active' ? 'inactive' : 'active';
    this.driversService
      .changeStatus(this.globalService.headers, id, statusToSend)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.getPage(this.pagination.current_page);
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }

  triggerDelete(driver: DriverList) {
    this.driverToBeDeleted = driver;
    this.toggleDeleteModal = true;
  }

  deleteDriver(driver) {
    this.loader = true;
    this.driversService
      .deleteDriver(this.globalService.headers, this.driverToBeDeleted.id)
      .subscribe(
        response => {
          this.loader = false;
          this.toggleDeleteModal = false;
          this.getPage(this.pagination.current_page);
        }
      );
  }

  isClosed(event) {
    this.isShown = false;
  }

  makeLogistics(driver: LogDriverFromList) {
    this.logisticsDriver
      .makeDriverLogistics(this.globalService.headers, driver.id)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.refreshData(false);
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }

  refreshData(event) {
    this.getPage(this.pagination.current_page);
  }
}
