import { Component, OnInit } from '@angular/core';
import { Status } from '@app/shared/enums/status.enum';
import { Observable } from 'rxjs';
import { HomecleanCompanyService } from '@app/modules/homeclean/modules/homeclean-company/shared/services/homeclean-company.service';
import { DialogService } from '@shared/components/dialog/services/dialog.service';
import { filter } from 'rxjs/operators';
import { HomecleanCompanyEditDialogComponent } from '@app/modules/homeclean/modules/homeclean-company/components/homeclean-company-edit-dialog/homeclean-company-edit-dialog.component';
import { HomecleanCompany } from '@app/modules/homeclean/modules/homeclean-company/shared/models';
import { Nullable } from '@shared/models';

@Component({
  selector: 'app-homeclean-company-list',
  templateUrl: './homeclean-company-list.component.html',
  styleUrls: ['./homeclean-company-list.component.scss'],
})
export class HomecleanCompanyListComponent implements OnInit {
  companies$: Observable<HomecleanCompany[]>;
  Status = Status;
  constructor(
    private readonly homecleanCompanyService: HomecleanCompanyService,
    private readonly dialog: DialogService,
  ) {}

  ngOnInit(): void {
    this.companies$ = this.homecleanCompanyService.getCompanies();
  }

  openDialog(company: Nullable<HomecleanCompany> = null): void {
    this.dialog
      .open(HomecleanCompanyEditDialogComponent, company)
      .afterClosed$.pipe(filter(Boolean))
      .subscribe(
        () => (this.companies$ = this.homecleanCompanyService.getCompanies()),
      );
  }

  removeCompany(id: number): void {
    this.homecleanCompanyService
      .removeCompany(id)
      .subscribe(
        () => (this.companies$ = this.homecleanCompanyService.getCompanies()),
      );
  }

  changeCompanyStatus(id: number, status: Status): void {
    this.homecleanCompanyService
      .updateCompany(id, {
        status: status === Status.Inactive ? Status.Active : Status.Inactive,
      })
      .subscribe();
  }
}
