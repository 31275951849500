import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { FormStatus } from '@shared/enums/form-status.enum';
import { UserSegment } from '@shared/models';
import { GlobalService } from '@app/shared/service/global.service';
import { Status } from '@app/shared/enums/status.enum';
import { SelectOptions } from '@app/shared/models/global/response';
import { IMyOptions } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-user-segment-form',
  templateUrl: './user-segment-form.component.html',
  styleUrls: ['./user-segment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSegmentFormComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  groups$: Observable<SelectOptions<number>[]>;
  segments$: Observable<UserSegment[]>;
  currency: string;
  STATUS = Status;
  selectedRecievableGroups: SelectOptions<number>[];
  selectedDeductibleGroups: SelectOptions<number>[];
  canAddSegment: boolean;
  canRemoveLastSegment: boolean;
  totalSegments: number;
  isLoading: boolean;
  startDateOptions: IMyOptions;
  endDateOptions: IMyOptions;

  @Input() userSegment: UserSegment;
  @Output() formChanged: EventEmitter<Partial<UserSegment>> = new EventEmitter<
    Partial<UserSegment>
  >();
  @Output() formStatusChanged: EventEmitter<FormStatus> =
    new EventEmitter<FormStatus>();

  private readonly destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly globalService: GlobalService,
  ) {}

  ngOnInit(): void {
    this.currency = this.globalService.currency;
    this.initForm();
    this.handleFormChanges();
    this.handleFormStatusChanges();
  }

  changeStatus(isActive: boolean) {
    const status = isActive ? Status.Active : Status.Inactive;
    this.form.get('status').setValue(status);
  }

  changeRange(controlName: string, value: number): void {
    this.form.get(controlName).setValue(value);
    this.checkRanges();
  }

  private initForm(): void {
    this.form = this.fb.group({
      name: this.fb.group({
        en: ['', [Validators.required]],
        ar: ['', [Validators.required]],
      }),
      status: [Status.Active],
      orderStartRange: [0, Validators.required],
      orderEndRange: [1, Validators.required],
    });
    this.patchForm();
  }

  private patchForm(): void {
    if (!this.userSegment) {
      return;
    }

    this.form.patchValue(this.userSegment);
    this.formChanged.emit(this.form.value);
  }

  private handleFormChanges(): void {
    const emitValue = (value: Partial<UserSegment>) =>
      this.formChanged.emit(value);

    this.form.valueChanges
      .pipe(debounceTime(500), tap(emitValue), takeUntil(this.destroy$))
      .subscribe();
  }

  private checkRanges(): void {
    const orderStartRange = this.form.get('orderStartRange')?.value;
    const orderEndtRange = this.form.get('orderEndRange')?.value;
    if (orderStartRange >= orderEndtRange) {
      this.form.get('orderStartRange').setErrors({
        message: 'Order range from must be lesser than order range to ',
      });
      this.form.get('orderEndRange').setErrors({
        message: 'Order range to must be greeter than order range from ',
      });
    } else {
      this.form.get('orderStartRange').setErrors(null);
      this.form.get('orderEndRange').setErrors(null);
    }
  }

  private handleFormStatusChanges(): void {
    const emitStatus = (value: FormStatus) =>
      this.formStatusChanged.emit(value);

    this.form.statusChanges
      .pipe(
        startWith(this.form.status),
        distinctUntilChanged(),
        tap(emitStatus),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
