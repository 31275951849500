// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.schedule-box {
    border-radius: 5px;
    height: 80px;
}

.order-card {
    height: 300px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/logistics/components/orders/logistic-order-details/logistic-order-details.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".schedule-box {\n    border-radius: 5px;\n    height: 80px;\n}\n\n.order-card {\n    height: 300px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
