import { Translation } from 'libs/justclean-models/src/lib/justclean-models';
import { GroupCountry } from "@shared/models";
export interface Group {
  id: number;
  image: Translation;
  name: Translation;
  keyword?: string;
  designType: string;
  callType: CallType;
  groupCountries?: GroupCountry[]
}

export enum CallType {
  InCall = 'inCall',
  OutCall = 'outCall',
}
