import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { createClient } from 'graphql-ws';
import { Observable } from 'rxjs';

const client = createClient({
  url: environment.graphqlWsUrl,
});

@Injectable({ providedIn: 'root' })
export class GraphQLService {
  subscription(operation): Observable<any> {
    return new Observable((observer) =>
      client.subscribe(operation, {
        next: (data) => observer.next(data),
        error: (err) => observer.error(err),
        complete: () => observer.complete(),
      }),
    );
  }
}
