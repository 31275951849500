import { NgModule } from '@angular/core';
import { AssignDirversComponent } from '@app/modules/logistics/shared/assign-dirvers/assign-dirvers.component';
import { NgClass, NgForOf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@app/modules/shared/shared.module';
@NgModule({
  imports: [NgForOf, NgClass, TranslateModule, SharedModule],
  declarations: [AssignDirversComponent],
  exports: [AssignDirversComponent],
})
export class SharedLogisticsModule {}
