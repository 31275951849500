import { Pipe, PipeTransform } from '@angular/core';
import { TimeHelper } from '../helpers/time-formate';
@Pipe({
  name: 'jcTimeZone'
})
export class JcTimeZonePipe implements PipeTransform {

   transform(
  
      dataValue: string,
  
      isTimeSlot = false,
  
      timeFormate = 'hh:mm a'
  
    ): Date | string {
      return TimeHelper.formatTime(dataValue, isTimeSlot, timeFormate);
  
    }
  
  }


