// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-row {
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 5px;
}

.file-field {
  margin-bottom: 25px;
  position: relative;
}

.remove-button {
  width: 14px;
  height: 14px;
  color: #ff144c;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
.remove-button:hover {
  cursor: pointer;
  opacity: 0.7;
}

.package-price-error {
  position: relative;
  top: 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/carwash/modules/carwash-packages/components/carwash-package-edit-dialog/carwash-package-form/carwash-package-form.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;EACA,MAAA;EACA,SAAA;EACA,QAAA;EACA,OAAA;EACA,YAAA;AACF;AACE;EACE,eAAA;EACA,YAAA;AACJ;;AAGA;EACE,kBAAA;EACA,SAAA;AAAF","sourcesContent":[".title-row {\n  font-weight: bold;\n  margin-top: 15px;\n  margin-bottom: 5px;\n}\n\n.file-field {\n  margin-bottom: 25px;\n  position: relative;\n}\n\n.remove-button {\n  width: 14px;\n  height: 14px;\n  color: #ff144c;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  margin: auto;\n\n  &:hover {\n    cursor: pointer;\n    opacity: 0.7;\n  }\n}\n\n.package-price-error {\n  position: relative;\n  top: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
