import { Status } from '@shared/enums/status.enum';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { CarwashTicketService } from '@app/modules/carwash/modules/carwash-packages/shared/services/carwash-ticket.service';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { ToastService } from 'ng-uikit-pro-standard';
import { CarwashTicketFormData } from '@app/modules/carwash/modules/carwash-packages/shared/models/carwash-ticket-form-date.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  CarwashTicket,
  CarwashTicketDialogData,
} from '@app/modules/carwash/modules/carwash-packages/shared/models';
import { CarwashPackageFormComponent } from '@app/modules/carwash/modules/carwash-packages/components/carwash-package-edit-dialog/carwash-package-form/carwash-package-form.component';

@Component({
  selector: 'app-carwash-ticket-edit-dialog',
  templateUrl: './carwash-package-edit-dialog.component.html',
  styleUrls: ['./carwash-package-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarwashPackageEditDialogComponent implements OnInit, OnDestroy {
  @ViewChild(CarwashPackageFormComponent, { static: true })
  private readonly carwashTicketFormComponent: CarwashPackageFormComponent;

  isLoading: boolean;

  get isDisableButton() {
    return this.isLoading || this.carwashTicketFormComponent.ticketForm.invalid;
  }

  private readonly destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    @Inject(DIALOG_DATA) public readonly data: CarwashTicketDialogData | any,
    private readonly dialogRef: DialogRef<CarwashPackageEditDialogComponent>,
    private readonly carwashTicketService: CarwashTicketService,
    private readonly cdr: ChangeDetectorRef,
    private readonly toast: ToastService,
  ) {}

  ngOnInit(): void {
    this.carwashTicketFormComponent.createForm(this.data.ticket);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createNewTicket(): void {
    const {
      includeDetails,
      notIncludeDetails,
      price,
      status,
      ...props
    }: CarwashTicketFormData = this.carwashTicketFormComponent.ticketForm.value;
    const { timeToComplete, ...otherProps } = props;
    const normalizedData = {
      details: [...includeDetails, ...notIncludeDetails],
      price: Number(price),
      cwGarageBranchId: this.data.branchId,
      timeToComplete,
      status: status ? Status.Active : Status.Inactive,
      ...otherProps,
    };
    this.isLoading = true;
    const action$ = this.data.ticket
      ? this.carwashTicketService.update(
          this.data.ticket.id,
          normalizedData as any,
        )
      : this.carwashTicketService.create(normalizedData);

    action$.pipe(takeUntil(this.destroy$)).subscribe(
      (response: CarwashTicket) => {
        this.dialogRef.close(response);
      },
      (err) => {
        this.isLoading = false;
        this.toast.error(err.message);
        this.cdr.markForCheck();
      },
    );
  }

  close(): void {
    this.dialogRef.close();
  }
}
