import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/shared/service/global.service';
import { CountryService } from 'src/app/shared/service/country.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { BrArea, AreaArray } from 'src/app/shared/models/laundries/branch';
import { GovernorateList } from 'src/app/shared/models/countries/country';
import { SupplierService } from 'src/app/shared/service/supplier.service';
import { SupplierAreaToBeSaved } from 'src/app/shared/models/laundries/suppliers';

@Component({
  selector: 'app-assign-area-supplier',
  templateUrl: './assign-area-supplier.component.html',
  styleUrls: ['./assign-area-supplier.component.css']
})
export class AssignAreaSupplierComponent implements OnInit {
  areas: BrArea[];
  listToBeShown: BrArea[];
  list: AreaArray[] = [];
  selectAll = false;
  loader = false;
  isUpdate: boolean;
  governorates: any[];
  governorate: number;
  isTouched: boolean;
  supplierId: number;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private countryService: CountryService,
    private supplierService: SupplierService,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.supplierId = parseInt(this.route.snapshot.queryParams.id, 10);
    this.isUpdate =
      this.route.snapshot.queryParams.update === 'true' ? true : false;
    this.getGovernorates();
    this.getAreas();
  }

  changeGov(event) {
    this.showAreas(event);
    this.isTouched = false;
  }

  getGovernorates() {
    this.countryService
      .getListCities(this.globalService.headers)
      .subscribe(response => {
        if (response.code === 200) {
          this.governorates = [];
          let gov = response.data as GovernorateList[];
          gov = gov.filter(e => e.country_id === this.globalService.countryId);
          gov.forEach(governorate => {
            this.governorates.push({
              value: governorate.id,
              label: governorate.name.en
            });
          });
        } else {
          this.toast.error(response.message);
        }
      });
  }

  getAreas() {
    this.supplierService
      .getSupplierAreas(this.globalService.headers, this.supplierId)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.areas = response.data;
            this.loader = false;
          } else {
            this.toast.error(response.message);
            this.loader = false;
          }
        },
        error => {
          this.loader = false;
        }
      );
  }

  showAreas(govId: number) {
    this.listToBeShown = this.areas.filter(
      area => area.governorate_id === govId
    );
    this.selectAll = this.listToBeShown.find(e => !e.is_selected)
      ? false
      : true;
  }

  addToList(area: any) {
    if (!this.elementExists(area)) {
      this.list.push({
        area_id: area.id,
        is_active: false
      });
    } else {
      this.deleteElemet(area);
    }
  }

  elementExists(area: any) {
    const found = this.list.find(element => element.area_id === area.id);
    return found ? true : false;
  }

  deleteElemet(area: any) {
    this.list.forEach((element, i) => {
      if (element.area_id === area.id) {
        this.list.splice(i, 1);
      }
    });
  }

  toggleArea(area: any) {
    area.is_selected = !area.is_selected;
    this.isTouched = true;
  }

  toggleSelectAll() {
    this.selectAll = !this.selectAll;
    this.isTouched = true;
    this.listToBeShown.forEach(ele => {
      ele.is_selected = this.selectAll ? true : false;
    });
  }

  checkSelected() {
    return this.areas && this.areas.find(e => e.is_selected === true)
      ? true
      : false;
  }

  saveSupplierArea() {
    this.loader = true;
    const areasToBeSaved = new SupplierAreaToBeSaved();
    areasToBeSaved.supplier_id = this.supplierId;
    areasToBeSaved.supplierAreas = this.convertAreas();
    this.supplierService
      .saveSupplierAreas(this.globalService.headers, areasToBeSaved)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.toast.success(
              ' Areas successfully assigned to the the supplier'
            );
            this.getAreas();
            this.router.navigate(['dashboard/suppliers/supplier-list']);
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }

  goBack() {
    this.router.navigate(['dashboard/suppliers/supplier-list']);
  }

  convertAreas() {
    return this.areas
      .filter(e => e.is_selected)
      .map(e => {
        return {
          area_id: e.id as number,
          is_selected: e.is_selected
        };
      });
  }
}
