import { GlobalService } from 'src/app/shared/service/global.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoggedinUser } from '../models/user/user';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IsPriorityBookingViewingGuard  {
  constructor(private readonly globalService: GlobalService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.globalService.getUser$().pipe(
      map((data) => {
        const user: LoggedinUser = data;
        return environment.production && environment.name === 'prod'
          ? environment.priorityBookingViewingManagementEmails.includes(
              user?.email,
            )
          : user.email.includes(environment.superAdminEmail);
      }),
    );
  }
}
