import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BackEndResponse } from '../models/global/response';
import { environment } from '../../../environments/environment';
import { expand, reduce, tap } from 'rxjs/operators';
import {
  LaundryToBeSaved,
  LaundryContract,
  LaundryMarketing,
} from '../models/laundries/laundry';
import { EMPTY } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class LaundryService {
  readonly URL = environment.serverUrl + 'laundry/laundry';
  readonly URL_PER_COUNTRY = environment.serverUrl + 'laundry/laundry-list';
  readonly URL_CREATE_CONTRACT =
    environment.serverUrl + 'laundry/create-contract';
  readonly URL_UPDATE_CONTRACT =
    environment.serverUrl + 'laundry/update-contract';
  readonly URL_GET_CONTRACT = environment.serverUrl + 'laundry/get-contract/';
  readonly URL_CRETAE_MARKETING =
    environment.serverUrl + 'laundry/create-marketing';
  readonly URL_GET_MARKETING = environment.serverUrl + 'laundry/get-marketing/';
  readonly URL_CHANGE_STATUS =
    environment.serverUrl + 'laundry/change-status-laundry';
  readonly URL_TERMINATE = environment.serverUrl + 'laundry/terminate-laundry';

  constructor(private http: HttpClient) {}

  getLaundries(headers, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(tap((data) => console.log(data)));
  }

  getLaundriesWithParams(headers, params: any) {
    return this.http
      .get<BackEndResponse>(this.URL, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(tap((data) => console.log(data)));
  }

  getLaundriesAllWithParams(headers, params: any) {
    return this.http
      .get<BackEndResponse>(this.URL, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(
        expand((res) =>
          !res.page_info.next_page
            ? EMPTY
            : this.http.get<BackEndResponse>(this.URL, {
                headers: new HttpHeaders(headers),
                params: new HttpParams({
                  fromObject: { ...params, page: res.page_info.next_page },
                }),
              }),
        ),
        reduce(
          (records, res) => ({
            data: records.data.concat(res.data),
          }),
          { data: [] },
        ),
      );
  }

  saveLaundry(headers, laundry: LaundryToBeSaved) {
    return this.http
      .post<BackEndResponse>(this.URL, laundry, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  updateLaundry(headers, laundry: LaundryToBeSaved, id: number) {
    return this.http
      .put<BackEndResponse>(this.URL + '/' + id, laundry, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  getLaundryInfo(headers, id: number) {
    return this.http
      .get<BackEndResponse>(this.URL + '/' + id, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  saveLaundryContract(headers, contract: LaundryContract) {
    return this.http
      .post<BackEndResponse>(this.URL_CREATE_CONTRACT, contract, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  getLaundryContract(headers, id: number) {
    return this.http
      .get<BackEndResponse>(this.URL_GET_CONTRACT + id, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  updateLaundryContract(headers, contract: LaundryContract) {
    return this.http
      .post<BackEndResponse>(this.URL_UPDATE_CONTRACT, contract, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  addMarkting(headers, marketing: LaundryMarketing) {
    return this.http
      .post<BackEndResponse>(this.URL_CRETAE_MARKETING, marketing, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  getMarketing(headers, id: number) {
    return this.http
      .get<BackEndResponse>(this.URL_GET_MARKETING + id, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  changeStatus(
    headers,
    status: {
      laundry_id: number;
      status?: string;
      is_logistics?: boolean;
    },
  ) {
    return this.http
      .post<BackEndResponse>(this.URL_CHANGE_STATUS, status, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  terminateLaundry(
    headers,
    body: {
      laundry_id: number;
      is_terminated: boolean;
    },
  ) {
    return this.http
      .post<BackEndResponse>(this.URL_TERMINATE, body, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }
}

export class Laundry {
  id: number;
  code: string;
  name: string;
  image_logo: string;
  owner_name: string;
  mobile: string;
  status: string;
  rating_value: number;
  rating_count: number;
  number_of_branch: number;
  number_of_order: number;
  number_of_driver: number;
}
