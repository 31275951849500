import {
  Component,
  OnInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import {
  ModalDirective,
  IMyOptions,
  ToastService
} from 'ng-uikit-pro-standard';
import { NgForm } from '@angular/forms';
import { CashBackToBeSaved } from 'src/app/shared/models/offers/campaigns';
import { SelectOptions, JustCleanLaundryPercentage } from 'src/app/shared/models/global/response';
import { CampaignsService } from 'src/app/shared/service/campaigns.service';
import { GlobalService } from 'src/app/shared/service/global.service';
import { LaundryService } from 'src/app/shared/service/laundry.service';
import { LaundryFromList } from 'src/app/shared/models/laundries/laundry';

@Component({
  selector: 'app-create-cash-back',
  templateUrl: './create-cash-back.component.html',
  styleUrls: ['./create-cash-back.component.css']
})
export class CreateCashBackComponent implements OnInit, OnChanges {
  @ViewChild('createCashBackModal', { static: true }) modal: ModalDirective;
  @ViewChild('createCashBack', { static: false }) form: NgForm;
  @Input() isShown: boolean;
  @Output() isClosed: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();
  cashBack: CashBackToBeSaved;
  type: SelectOptions<string>[];
  laundriesInForm: SelectOptions<any>[];
  laundries: LaundryFromList[];
  branchId: number[];
  selectAll = true;
  loader: boolean;
  validity: SelectOptions<number>[];
  selectedValidity = 1;
  justCleanOrLaundry: JustCleanLaundryPercentage;
  startDateOptions: IMyOptions;
  endDateOption: IMyOptions;
  constructor(
    private campaignService: CampaignsService,
    private globalService: GlobalService,
    private toast: ToastService,
    private laundryService: LaundryService
  ) {}


  ngOnInit() {

    this.startDateOptions = {
      disableUntil: {
        day: this.globalService
          .dateMinusDays(new Date().toDateString(), 1)
          .date(),
        month:
          this.globalService.dateMinusDays(new Date().toDateString(), 1).month() +
          1,
        year: this.globalService
          .dateMinusDays(new Date().toDateString(), 1)
          .year()
      },
      closeAfterSelect: true
    }

    this.endDateOption = {
      disableUntil: {
        day: this.globalService
          .dateMinusDays(new Date().toDateString(), 1)
          .date(),
        month:
          this.globalService.dateMinusDays(new Date().toDateString(), 1).month() +
          1,
        year: this.globalService
          .dateMinusDays(new Date().toDateString(), 1)
          .year()
      },
      closeAfterSelect: true
    };

    this.initForm();
    this.generateArrayForList();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isShown) {
      this.initForm();
      this.modal.show();
    } else {
      this.modal.hide();
    }
  }

  onHidden(event) {
    this.isClosed.emit(event);
  }

  initForm() {
    if (this.form) {
      this.form.reset();
    }
    this.cashBack = new CashBackToBeSaved();
    this.cashBack.translations = [];
    this.cashBack.translations[0] = {
      language_id: 1,
      message: '',
      name: 'cashback'
    };
    this.cashBack.translations[1] = {
      language_id: 2,
      message: '',
      name: 'cashback'
    };
    this.cashBack.discount_type = 'percentage';
    this.justCleanOrLaundry = {
      laundry: 50,
      justclean: 50
    };
    this.getLaundries();
  }

  startDateChanged(event) {
    if (event) {
      this.endDateOption = {
        disableUntil: {
          day: this.globalService
            .dateMinusDays(new Date(event).toDateString(), 1)
            .date(),
          month:
            this.globalService
              .dateMinusDays(new Date(event).toDateString(), 1)
              .month() + 1,
          year: this.globalService
            .dateMinusDays(new Date(event).toDateString(), 1)
            .year()
        },
        closeAfterSelect: true
      };
      this.validateForm();
    }
  }

  endDateChanged(event) {
    this.validateForm();
  }

  validateForm() {
    if (
      this.cashBack.cashback_type === this.type[0].value &&
      !this.cashBack.discount_type
    ) {
      this.form.hasError('discount_type');
    }
    return (
      this.form &&
      this.form.valid &&
      this.cashBack.cashback_type &&
      this.cashBack.expiry_date &&
      this.cashBack.start_date &&
      this.cashBack.laundry_branch_ids &&
      this.cashBack.laundry_branch_ids.length > 0
    );
  }

  saveCashBack() {
    this.loader = true;
    this.cashBack.validity = this.cashBack.validity * this.selectedValidity;
    this.cashBack.discount_by_justclean = this.justCleanOrLaundry.justclean;
    this.cashBack.discount_by_laundry = this.justCleanOrLaundry.laundry;
    this.campaignService
      .saveCashBack(this.globalService.headers, this.cashBack)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.loader = false;
            this.onHidden(true);
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }

  getSliderValues(event: JustCleanLaundryPercentage) {
    this.justCleanOrLaundry = event;
    console.log(event);
  }

  private generateArrayForList() {
    this.type = [
      {
        value: 'min_order_value',
        label: 'Min order Value'
      },
      {
        value: 'any_total',
        label: 'Any Total'
      }
    ];

    this.validity = [
      {
        value: 1,
        label: 'Minutes'
      },
      {
        value: 60,
        label: 'Hours'
      },
      {
        value: 1440,
        label: 'Days'
      }
    ];
  }

  private getLaundries() {
    this.laundryService
      .getLaundriesWithParams(this.globalService.headers, {
        page: 0,
        status: 'active',
        campaign: true
      })
      .subscribe(
        response => {
          if (response.code === 200) {
            this.laundries = response.data;
            this.laundriesInForm = [];
            this.laundries.forEach((laundry, i) => {
              this.laundriesInForm.push({
                value: '',
                label: laundry.name,
                group: true
              });
              laundry.laundry_branches.forEach(branch => {
                if (!branch.campaign_cashback) {
                  this.laundriesInForm.push({
                    value: branch.id,
                    label: branch.name.toString()
                  });
                }
              });
            });
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }
}
