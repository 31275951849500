import { expand, map, reduce } from 'rxjs/operators';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { environment } from '@env/environment';
import { EMPTY, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HcBranch } from '@app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class HomeCleaningBranchesService {
  private readonly hcBranchesUrl = `${environment.jcDeliveryServer}hc-company-branches`;

  constructor(private readonly http: HttpClient) {}

  getHcBranches(params: any = {}): Observable<Array<HcBranch>> {
    const toData = (res) => res.data;
    return this.http
      .get<ApiResponse<Array<HcBranch>>>(this.hcBranchesUrl, {
        params,
      })
      .pipe(map(toData));
  }

  getHCAllBranches(params?: any): Observable<Array<HcBranch>> {
    return this.http
      .get<ApiResponse<Array<HcBranch>>>(`${this.hcBranchesUrl}`, {
        params,
      })
      .pipe(
        expand((res) =>
          !res.pageInfo.nextPage
            ? EMPTY
            : this.http.get<ApiResponse<Array<HcBranch>>>(
                this.hcBranchesUrl,
                this.updateRequestFormat(params, res.pageInfo.nextPage),
              ),
        ),
        reduce((records, res) => records.concat(res.data), []),
      );
  }

  getHcBranchById(branchId: number): Observable<HcBranch> {
    const toData = (res) => res.data;
    return this.http
      .get<ApiResponse<Array<HcBranch>>>(`${this.hcBranchesUrl}/${branchId}`)
      .pipe(map(toData));
  }

  updateHcBranch(id: number, body: any): Observable<HcBranch> {
    const toData = (res) => res.data;
    return this.http
      .patch<ApiResponse<HcBranch>>(`${this.hcBranchesUrl}/${id}`, body)
      .pipe(map(toData));
  }

  private updateRequestFormat(params: any, page: number) {
    params.page = page;
    return {
      params,
    };
  }
}
