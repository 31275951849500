import { Country } from '@app/shared/models/user/user';
import { Area } from '@app/shared/models';
import { AdvertisementArea, BranchArea, HcService } from '@app/shared/models';
import { GroupService } from '@app/shared/service/group';
import { AdService } from '@shared/service/ads';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subject, Observable, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { FormStatus } from '@shared/enums/form-status.enum';
import { Ad } from '@shared/models';
import { Translation } from 'libs/justclean-models/src/lib/justclean-models';
import { Gender, PetPreference } from '@app/shared/enums';
import { HomeCleaningServicesService } from '@app/shared/service/home-cleaning/home-cleaning-services.service';
import { BranchesService } from '@app/shared/service/branches.service';
import { AreaService } from '@app/shared/service/area';
import { spaceValidator } from '@app/shared/helpers/space-validator';
import { CouponsService } from '@app/shared/service/coupons.service';

@Component({
  selector: 'app-ad-form',
  templateUrl: './ad-form.component.html',
  styleUrls: ['./ad-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdFormComponent implements OnInit, OnDestroy {
  @Input() ad: Ad;
  @Input() adImageAr: string;
  @Input() adImageEn: string;
  @Output() formChanged: EventEmitter<Partial<Ad>> = new EventEmitter<
    Partial<Ad>
  >();
  @Output() formStatusChanged: EventEmitter<FormStatus> =
    new EventEmitter<FormStatus>();
  @Output() changeAdImageEn: EventEmitter<File> = new EventEmitter<File>();
  @Output() changeAdImageAr: EventEmitter<File> = new EventEmitter<File>();

  isHomeCleaning: boolean;
  hcServices$: Observable<HcService[]>;
  form: UntypedFormGroup;
  GENDER = Gender;
  PET_PREFERENCE = PetPreference;

  private branchId: number;
  private branchName: Translation;
  private groupName: Translation;
  private groupId: number;

  private readonly destroy$: Subject<void> = new Subject<void>() ;
  areas$: Observable<BranchArea[] | Area[]>;
  allAreas: Area[] | BranchArea[];
  selectedAdvertisementAreas: { [areaId: number]: AdvertisementArea } = {};
  isAllSelected: boolean;

  //#region isMarketing variables
  @Input() isMarketing: boolean;
  @Input() countries: Country[];
  @Input() selecteCountryId: number;
  searchTextChanged: Subject<string> = new Subject<string>();
  subscriber$ = new Subscription();
  isLoading: boolean;
  couponValue: string;
  //#endregion

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly adService: AdService,
    private readonly groupService: GroupService,
    private readonly areaService: AreaService,
    private readonly hcService: HomeCleaningServicesService,
    private readonly branchService: BranchesService,
    private readonly couponsService: CouponsService,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.isMarketing) {
      this.handleCouponCodeChange();
    }
    this.initVariables();
    this.initForm();
    this.handleFormChanges();
    this.handleCouponCodeChanges();
    this.handleFormStatusChanges();
  }

  upload(file: File, isAr?: boolean): void {
    if (isAr) {
      this.changeAdImageAr.next(file);
    } else {
      this.changeAdImageEn.next(file);
    }
  }

  changePetStatus(): void {
    this.form.get('petPreference').setValue(PetPreference.hasPets);
    this.form.get('genderId').setValue(undefined);
  }

  changeGender(gender: Gender): void {
    this.form.get('genderId').setValue(gender);
    this.form.get('petPreference').setValue(undefined);
  }

  getEvenIndexes(areas: BranchArea[]): BranchArea[] {
    const isEven = (area, index) => !(index % 2);
    return areas?.filter(isEven);
  }

  getOddIndexes(areas: BranchArea[]): BranchArea[] {
    const isEven = (area, index) => index % 2;
    return areas?.filter(isEven);
  }

  toggleAll(isSelectAll: boolean): void {
    if (isSelectAll) {
      this.allAreas.forEach((area) => {
        const areaId = area.areaId || area.id;
        this.selectedAdvertisementAreas[areaId] = {
          areaId,
          hasAndroid: true,
          hasIos: true,
        };
      });
    } else {
      this.selectedAdvertisementAreas = {};
    }
    this.checkAllSelected();
  }

  toggleArea(areaId: number, checked: boolean): void {
    if (checked) {
      this.selectedAdvertisementAreas[areaId] = {
        areaId,
        hasAndroid: true,
        hasIos: true,
      };
    } else {
      delete this.selectedAdvertisementAreas[areaId];
    }
    this.checkAllSelected();
  }

  private checkAllSelected(): void {
    this.isAllSelected =
      Object.keys(this.selectedAdvertisementAreas)?.length ===
      this.allAreas?.length;
  }

  fillAreas(formData: Partial<Ad>): void {
    formData.advertisementAreas = Object.keys(
      this.selectedAdvertisementAreas,
    ).map((key) => this.selectedAdvertisementAreas[key]);
  }

  onInput(event: Event): void {
    if (event.target['value'].trim().length > 2) {
      this.searchTextChanged.next(event.target['value'].trim());
    }
  }

  private handleCouponCodeChange(): void {
    this.searchTextChanged.pipe(debounceTime(1000)).subscribe((couponCode) => {
      if (couponCode) {
        this.verifyCoupon(couponCode);
      } else {
        this.couponValue = '';
      }
    });
  }

  private verifyCoupon(couponCode: string): void {
    this.isLoading = true;
    this.couponValue = '';

    if (this.subscriber$) {
      this.subscriber$.unsubscribe();
    }

    const countryId = this.selecteCountryId || this.ad.countryId;
    const body = { countryId, code: couponCode };
    this.subscriber$ = this.couponsService
      .verifyCoupon(body)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isLoading = false;
          this.cd.detectChanges();
        }),
      )
      .subscribe((res) => {
        const coupon = res.data;
        if (coupon?.discountType === 'Percentage') {
          this.couponValue = `${coupon.discountPercentage * 100}%`;
        } else {
          const country = this.getCountry(countryId);
          this.couponValue = `${(coupon.discountAmount || 0)?.toFixed(
            country.currency_decimal,
          )} ${country.currency}`;
        }
      });
  }

  private getCountry(countryId: number): any {
    return this.countries.find((e) => e.id === countryId);
  }

  private initVariables() {
    this.branchId = this.adService.branchId;
    this.branchName = this.adService.branchName;
    this.groupName = this.adService.group?.name;
    this.groupId = this.adService.group?.id;
    this.isHomeCleaning = this.groupService.checkHomeCleaningDesign(
      this.adService.group,
    );
    if (this.isHomeCleaning) {
      this.loadHcServices();
      this.loadHcBranchAreas();
    } else {
      const isCWInCall =
        this.groupService.checkCarwashGarageDesign(this.adService.group) &&
        this.groupService.checkInCall(this.adService.group);
      if (isCWInCall) {
        this.loadCountryAreas();
      } else {
        this.loadBranchAreas();
      }
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      name: this.fb.group({
        en: [this.titleEn, [Validators.required]],
        ar: [this.titleAr, [Validators.required]],
      }),
      image: this.fb.group({
        en: [''],
        ar: [''],
      }),
      link: this.fb.group({
        en: [this.link],
        ar: [this.link],
      }),
      maxViews: [
        100,
        [Validators.required, Validators.min(100), Validators.max(5000)],
      ],
      couponCode: [
        '',
        this.isMarketing
          ? [Validators.required, spaceValidator.bind(this)]
          : [],
      ],
      petPreference: [PetPreference.notSet],
      /** null means all users */
      genderId: [null],
      hcServiceId: [
        null,
        ...(this.isHomeCleaning ? [Validators.required] : []),
      ],
      groupId: [this.groupId],
      [this.groupService.getBranchIdKey(this.adService.group)]: [this.branchId],
      advertisementAreas: new UntypedFormArray([]),
    });
    this.patchForm();
  }

  private get titleEn(): string {
    return `${this.groupName?.en} by: ${this.branchName?.en}`;
  }
  private get titleAr(): string {
    return `${this.groupName?.ar} من: ${this.branchName?.ar}`;
  }

  private get link(): string {
    return `https://justclean.com/home?isDeal=true&groupId=${this.groupId}&branchId=${this.branchId}&code={couponCode}`;
  }

  private setLink(couponCode: string): void {
    const linkForm = this.form.get('link');
    linkForm.get('en').setValue(this.link.replace('{couponCode}', couponCode));
    linkForm.get('ar').setValue(this.link.replace('{couponCode}', couponCode));
    this.formChanged.emit(this.form.value);
  }

  private patchForm(): void {
    if (this.ad) {
      this.form.patchValue(this.ad);

      this.ad.advertisementAreas.forEach((area) => {
        this.selectedAdvertisementAreas[area.areaId] = {
          areaId: area.areaId,
          hasAndroid: area.hasAndroid,
          hasIos: area.hasIos,
        };
      });
      if (this.ad.petPreference !== PetPreference.notSet) {
        this.form.get('genderId').setValue(this.ad.genderId || undefined);
      }

      if (this.isMarketing && this.ad.couponCode) {
        this.verifyCoupon(this.ad.couponCode);
      }
    }
    this.formChanged.emit(this.form.value);
  }

  private handleFormChanges(): void {
    const emitValue = (value: Partial<Ad>) => this.formChanged.emit(value);

    this.form.valueChanges
      .pipe(debounceTime(500), tap(emitValue), takeUntil(this.destroy$))
      .subscribe();
  }

  private handleCouponCodeChanges(): void {
    const emitValue = (value: Partial<Ad>) => this.formChanged.emit(value);

    this.form
      .get('couponCode')
      .valueChanges.pipe(
        debounceTime(500),
        tap(emitValue),
        takeUntil(this.destroy$),
      )
      .subscribe((couponCode: any) => {
        if (couponCode) {
          this.setLink(couponCode);
        }
      });
  }

  private handleFormStatusChanges(): void {
    const emitStatus = (value: FormStatus) =>
      this.formStatusChanged.emit(value);

    this.form.statusChanges
      .pipe(
        startWith(this.form.status),
        distinctUntilChanged(),
        tap(emitStatus),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private loadHcServices(): void {
    this.hcServices$ = this.hcService.getHcServices({
      hcCompanyBranchId: this.branchId,
      countryId: this.selecteCountryId || this.countryId,
    });
  }

  private loadBranchAreas() {
    this.areas$ = this.branchService
      .getBranchSelectedAreas({
        [this.adService.branchKey]: this.branchId,
        countryId: this.selecteCountryId || this.countryId,
      })
      .pipe(
        map((areas) => {
          this.allAreas = areas;

          if (!this.ad) {
            return areas;
          }

          const isSelected = (area) =>
            this.ad ? this.selectedAdvertisementAreas[area.areaId] : true;
          return areas?.filter(isSelected);
        }),
      );
  }

  private loadHcBranchAreas() {
    this.loadAreas({
      [this.adService.branchKey]: this.branchId,
      countryId: this.selecteCountryId || this.countryId,
    });
  }

  private loadCountryAreas() {
    this.loadAreas({
      countryId: this.selecteCountryId || this.countryId,
    });
  }

  private loadAreas(params: Record<string, string | number>): void {
    this.areas$ = this.areaService.getAllAreas(params).pipe(
      map((areas) => {
        this.allAreas = areas;
        if (!this.ad) {
          return areas;
        }

        const isSelected = (area) =>
          this.ad ? this.selectedAdvertisementAreas[area.id] : true;
        return areas?.filter(isSelected);
      }),
    );
  }

  private get countryId(): number {
    return +localStorage.getItem('country');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.subscriber$) {
      this.subscriber$.unsubscribe();
    }
  }
}
