import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";

import { OrderDetailsItemSize } from '@app/modules/carwash/modules/carwash-order/components/carwash-order-details/shared/enums/order-details-item-size.enum';
import { CarwashOrder } from '@app/modules/carwash/modules/carwash-order/components/shared/models/carwash-order.model';
import { GlobalService } from '@shared/service/global.service';

@Component({
  selector: 'app-carwash-order-details-header',
  templateUrl: './carwash-order-details-header.component.html',
  styleUrls: ['./carwash-order-details-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarwashOrderDetailsHeaderComponent implements OnInit {
  @Input() order: CarwashOrder;
  readonly OrderDetailsItemSize = OrderDetailsItemSize;
  currency;

  constructor(private readonly globalService: GlobalService) {}


  ngOnInit(): void {
    this.currency = this.globalService.currency
  }

  getPurchaseDate(date: Date): string {
    return this.globalService.formatDate(String(date), 'ddd DD MMMM YYYY, LT');
  }

  getPaymentMethods(order: CarwashOrder): string {
    if (order.userTransactions) {
      return order.userTransactions.reduce(
        (result, el) =>
          !el.deletedAt
            ? `${result ? `${result}, ` : ''}${el.paymentMethod.name.en} (${
                el.amount
              })`
            : result,
        '',
      );
    }

    return '-';
  }

}
