import { Component, OnInit } from '@angular/core';
import { CorpCustomerFromList } from 'src/app/shared/models/laundries/corporate';
import { PageInfo } from 'src/app/shared/models/global/response';
import { GlobalService } from 'src/app/shared/service/global.service';
import { CorporateService } from 'src/app/shared/service/corporate.service';
import { Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-corporate-customer-list',
  templateUrl: './corporate-customer-list.component.html',
  styleUrls: ['./corporate-customer-list.component.css']
})
export class CorporateCustomerListComponent implements OnInit {
  corporates: CorpCustomerFromList[];
  pagination: PageInfo;
  constructor(
    private globalService: GlobalService,
    protected corporateService: CorporateService,
    protected router: Router,
    protected toast: ToastService
  ) {}

  ngOnInit() {
    this.getCorp({ page: 1 });
  }

  getCorp(params?: any) {
    console.log(params);

    this.corporateService
      .getCorpCustomers(this.globalService.headers, params)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.corporates = response.data;
            this.pagination = response.page_info;
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }

  getPage(number: number) {
    this.getCorp({ page: number });
  }

  fixNull(data) {
    return this.globalService.fixNullValues(data);
  }
}
