// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  padding: 40px 0 90px 0;
}

.type-service {
  border: 1px solid #5a1ec3;
  margin: 5px 0;
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/items-tat-dialog/items-tat-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;;AAEA;EACE,yBAAA;EACA,aAAA;EACA,YAAA;AACF","sourcesContent":[".wrapper {\n  padding: 40px 0 90px 0;\n}\n\n.type-service {\n  border: 1px solid #5a1ec3;\n  margin: 5px 0;\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
