import { Component, OnInit, ViewChild } from '@angular/core';
import { CorporateClientToBeSaved } from 'src/app/shared/models/laundries/corporate';
import { GlobalService } from 'src/app/shared/service/global.service';
import { CountryService } from 'src/app/shared/service/country.service';
import { AreaList } from 'src/app/shared/models/countries/country';
import { Router } from '@angular/router';
import { CorporateService } from 'src/app/shared/service/corporate.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { MarkerForMap } from 'src/app/shared/models/global/response';
import { NgForm, NgModel } from '@angular/forms';

@Component({
  selector: 'app-contract-info',
  templateUrl: './contract-info.component.html',
  styleUrls: ['./contract-info.component.css']
})
export class ContractInfoComponent implements OnInit {
  areas: any[];
  corpClient: CorporateClientToBeSaved;
  dataReady = false;
  isUpdate: boolean;
  loader: boolean;
  zoom = 14;
  markers: MarkerForMap[] = [];
  showMap = false;
  @ViewChild('corpClientForm', { static: true }) form: NgForm;
  constructor(
    private globalService: GlobalService,
    private corpService: CorporateService,
    private countriesService: CountryService,
    private router: Router,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.corpClient = new CorporateClientToBeSaved();
    this.getAreas(this.globalService.headers);
    this.dataReady = true;
    const initLonLat = this.globalService.getLongLat();
    this.markers.push({
      lng: initLonLat.longitude,
      lat: initLonLat.latitude,
      draggable: true
    });
  }

  getAreas(headers) {
    this.countriesService
      .getListAreas(headers, { page: 0 })
      .subscribe(response => {
        const areas: AreaList[] = response.data;
        this.areas = [];
        areas.forEach(element => {
          this.areas.push({ value: element.id, label: element.name.en });
        });
      });
  }

  selectArea(event) {
    this.corpClient.area_id = event;
  }

  next() {
    this.loader = true;
    this.corpService
      .createCorpCustomer(this.globalService.headers, this.corpClient)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.toast.success(response.message);
            this.router.navigate(['dashboard/corporate/corp-customer-list']);
            this.loader = false;
          } else {
            this.toast.error(response.message);
            this.loader = false;
          }
        },
        error => {
          this.loader = false;
        }
      );
  }

  markerDragEnd($event) {
    this.corpClient.longitude = $event.coords.lng;
    this.corpClient.latitude = $event.coords.lat;
  }

  updateMarkerFromInput(model: NgModel) {
    if (model.name === 'longitude') {
      this.markers[0].lng = model.value;
    } else {
      this.markers[0].lat = model.value;
    }
  }

  cancel() {
    this.router.navigate(['dashboard/corporate/corp-customer-list']);
  }
}
