import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CarwashOrderRoutingModule } from '@app/modules/carwash/modules/carwash-order/carwash-order-routing.module';
import { CarwashOrderDetailsModule } from '@app/modules/carwash/modules/carwash-order/components/carwash-order-details/carwash-order-details.module';
import { SharedModule } from '@app/modules/carwash/shared/shared.module';
import { CarwashOrderListComponent } from '@app/modules/carwash/modules/carwash-order/components/carwash-order-list/carwash-order-list.component';
import { CwCancelOrderDialogComponent } from './components/cw-cancel-order-dialog/cw-cancel-order-dialog.component';
import { CwRescheduleDialogComponent } from './components/cw-reschedule-dialog/cw-reschedule-dialog.component';

@NgModule({
  declarations: [
    CarwashOrderListComponent,
    CwCancelOrderDialogComponent,
    CwRescheduleDialogComponent,
  ],
  imports: [
    CommonModule,
    CarwashOrderRoutingModule,
    CarwashOrderDetailsModule,
    SharedModule,
  ],
})
export class CarwashOrderModule {}
