import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CarwashOrderDetailsComponent } from '@app/modules/carwash/modules/carwash-order/components/carwash-order-details/carwash-order-details.component';
import { CarwashOrderListComponent } from '@app/modules/carwash/modules/carwash-order/components/carwash-order-list/carwash-order-list.component';

const routes: Routes = [
  {
    path: 'orders/details/:id',
    component: CarwashOrderDetailsComponent,
    data: { title: 'Carwash order details' },
  },
  {
    path: 'orders/list',
    component: CarwashOrderListComponent,
    data: { title: 'Carwash orders' },
  },
  {
    path: 'orders',
    redirectTo: 'orders/list',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CarwashOrderRoutingModule {}
