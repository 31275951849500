import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, take } from 'rxjs';
import { PageInfo } from '@shared/models/global/response';
import { Group } from '@shared/models';
import { GroupService } from '@shared/service/group';
import { finalize, takeUntil } from 'rxjs/operators';
import { AppHeaders } from '@shared/models/user/generic-backend';
import { GlobalService } from '@shared/service/global.service';
import * as XLSX from 'xlsx';
import { ProviderCommissionService } from '@app/modules/finance/components/provider-commission/provider-commission.service';
import { ProviderCommission } from '@app/modules/finance/components/provider-commission/provider-commission.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-provider-commission',
  templateUrl: './provider-commission.component.html',
  styleUrls: ['./provider-commission.component.css'],
  providers: [DatePipe],
})
export class ProviderCommissionComponent implements OnInit, OnDestroy {
  private headers: AppHeaders = {};
  private destroy$: Subject<void> = new Subject<void>();

  pagination$: Observable<PageInfo>;
  providerCommissions$: Observable<ProviderCommission[]>;
  isLoading: boolean;
  groups: Group[];
  currentPage = 1;
  selectedGroup: Group;
  search: string;

  constructor(
    private readonly groupService: GroupService,
    private readonly globalService: GlobalService,
    private readonly datePipe: DatePipe,
    readonly providerCommissionService: ProviderCommissionService,
  ) {
    this.headers = this.globalService.headers;
    this.pagination$ = this.providerCommissionService.pagination$;
  }

  ngOnInit(): void {
    this.groupService
      .getGroups()
      .pipe(takeUntil(this.destroy$))
      .subscribe((groups: Group[]): void => {
        this.groups = groups;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadData(): void {
    this.isLoading = true;
    this.providerCommissions$ = this.providerCommissionService
      .getCommissions(
        this.currentPage,
        this.search,
        this.headers,
        this.selectedGroup,
      )
      .pipe(finalize(() => (this.isLoading = false)));
  }

  loadGroupCompanies(): void {
    if (!this.selectedGroup) return;

    this.headers.group_id = this.selectedGroup.id.toString();
    this.currentPage = 1;
    localStorage.setItem(
      'companySelectedGroup',
      JSON.stringify(this.selectedGroup),
    );
    this.loadData();
  }

  resetSearch(): void {
    this.currentPage = 1;
    this.search = '';
    this.loadData();
  }

  setSearch(): void {
    this.currentPage = 1;
    this.loadData();
  }

  export(): void {
    this.providerCommissionService
      .getCommissionsWithoutPagination(
        this.currentPage,
        this.search,
        this.headers,
        this.selectedGroup,
      )
      .pipe(take(1))
      .subscribe((res) => {
        this.exportArrayToExcel(
          res.map((d) => ({
            BranchId: d.branchId,
            Laundry: d.laundryName,
            Branch: d.branchName,
            Current_Commission: d.data?.commission_percentage || '',
            Updated_Date: this.transformDate(d.date || ''),
            Username: d.user?.name || '',
          })),
        );
      });
  }

  getPage(pageNumber: number): void {
    this.currentPage = pageNumber;
    this.loadData();
  }

  private exportArrayToExcel(arr: any[], name?: string): void {
    let { sheetName, fileName } = this.getFileName(name);
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(arr);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  private getFileName(name: string): Record<string, string> {
    let timeSpan: string = new Date().toISOString();
    let sheetName: string = name || 'ExportResult';
    let fileName: string = `${sheetName}-${timeSpan}`;
    return {
      sheetName,
      fileName,
    };
  }

  private transformDate(date): string {
    return this.datePipe.transform(date);
  }
}
