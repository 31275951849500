import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Type,
  ViewEncapsulation,
} from '@angular/core';
import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';

@Component({
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DialogComponent implements OnInit {
  content: Type<any>;

  constructor(private ref: DialogRef) {}

  ngOnInit() {
    this.content = this.ref.content;
  }
}
