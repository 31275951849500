// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add this CSS to your stylesheet */
/* Center the card content vertically and horizontally */
.card-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Center the text content vertically */
.col-md-8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Add space between items in the text content */
.app-carwash-order-details-item {
  margin-bottom: 10px; /* Adjust as needed */
}

/* Adjust QR code image size if necessary */
.qr-code-img {
  max-width: 100%; /* Ensures the image does not exceed its container */
  height: auto; /* Maintains the aspect ratio */
  align-items: flex-end;
}

/* Adjust the card width to fit content */
.card {
  width: auto; /* You can set a specific width if needed */
}

/* Adjust the column sizes for different screen sizes */
@media (max-width: 768px) {
  .col-md-4 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.row::after {
  content: "";
  display: table;
  clear: both;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/carwash/modules/carwash-order/components/carwash-order-details/carwash-order-details-card/carwash-order-details-card.component.scss"],"names":[],"mappings":"AAAA,oCAAA;AAEA,wDAAA;AACA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAAF;;AAGA,uCAAA;AACA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;AAAF;;AAGA,gDAAA;AACA;EACE,mBAAA,EAAA,qBAAA;AAAF;;AAGA,2CAAA;AACA;EACE,eAAA,EAAA,oDAAA;EACA,YAAA,EAAA,+BAAA;EACA,qBAAA;AAAF;;AAGA,yCAAA;AACA;EACE,WAAA,EAAA,2CAAA;AAAF;;AAGA,uDAAA;AACA;EACE;IACE,aAAA;IACA,uBAAA;IACA,mBAAA;EAAF;AACF;AAEA;EACE,WAAA;EACA,cAAA;EACA,WAAA;AAAF","sourcesContent":["/* Add this CSS to your stylesheet */\n\n/* Center the card content vertically and horizontally */\n.card-body {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n/* Center the text content vertically */\n.col-md-8 {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\n/* Add space between items in the text content */\n.app-carwash-order-details-item {\n  margin-bottom: 10px; /* Adjust as needed */\n}\n\n/* Adjust QR code image size if necessary */\n.qr-code-img {\n  max-width: 100%; /* Ensures the image does not exceed its container */\n  height: auto; /* Maintains the aspect ratio */\n  align-items: flex-end;\n}\n\n/* Adjust the card width to fit content */\n.card {\n  width: auto; /* You can set a specific width if needed */\n}\n\n/* Adjust the column sizes for different screen sizes */\n@media (max-width: 768px) {\n  .col-md-4 {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n}\n.row::after {\n  content: \"\";\n  display: table;\n  clear: both;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
