export interface Contract {
  name: string;
  image: string;
}

export interface SplashAreas {
  area_id: number;
  cpd_ios: number;
  cpd_android: number;
  has_ios: boolean;
  has_android: boolean;
  name: string;
}

export interface Advertisement {
  language_id: number;
  name: string;
  image: string;
  type: string;
}

export class AdvertisementToBeSaved {
  contract: Contract;
  areas: SplashAreas[];
  advertisements: Advertisement[];
  start_date: string;
  end_date: string;
  bill_to: string;
  advertisement_reason_id: string;
  advertisement_sub_reason_id: string;
  laundry_branch_id: number;
  total_cpd: number;
  total_contract_cost: number;
  total_cpd_android: number;
  total_cpd_ios: number;
  id?: null;
}

export interface AdsDeleteBody {
  advertisement_id: number;
  advertisement_reason_id: number;
  advertisement_sub_reason_id: number;
  comment: string;
}
