import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CarsListComponent } from './components/cars-list/cars-list.component';
import { CarCreateComponent } from './components/car-create/car-create.component';

const routes: Routes = [
  {
    path: 'cars-list',
    component: CarsListComponent,
    data: {
      title: 'List of Cars'
    }
  },
  {
    path: 'car-create',
    component: CarCreateComponent,
    data: {
      title: 'Create Cars'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CarsRoutingModule {}
