import { Injectable } from '@angular/core';
import { DriversBookingLogsHttpService } from '@app/modules/drivers/shared/services/drivers-booking-logs-http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { DriversBookingLogs } from '@app/modules/drivers/shared/models';
import { PageInfo } from '@shared/models/global/response';
import { PaginationHelper } from '@shared/helpers/pagination-helper';
import { DriverQuestions } from '@app/modules/drivers/shared/models/driver-questions.model';

@Injectable({
  providedIn: 'root',
})
export class DriversBookingLogsService {
  readonly pagination$: BehaviorSubject<PageInfo> =
    new BehaviorSubject<PageInfo>(null);

  constructor(
    private readonly driversBookingLogsHttpService: DriversBookingLogsHttpService,
  ) {}

  getBookingLogs(
    params: Record<string, string | number>,
  ): Observable<DriversBookingLogs[]> {
    const toData = (res: ApiResponse<DriversBookingLogs[]>) => res.data;
    const toPagination = (res: ApiResponse<DriversBookingLogs[]>) =>
      this.pagination$.next(PaginationHelper.camelToSnakeCase(res.pageInfo));

    return this.driversBookingLogsHttpService
      .getBookingLogs(params)
      .pipe(tap(toPagination), map(toData));
  }

  getDriverQuestions(): Observable<DriverQuestions[]> {
    return this.driversBookingLogsHttpService
      .getDriverQuestions()
      .pipe(map((res: ApiResponse<DriverQuestions[]>) => res.data));
  }

  getDriverQuestionsById(id: number): Observable<DriverQuestions> {
    return this.driversBookingLogsHttpService
      .getDriverQuestionsById(id)
      .pipe(map((res: ApiResponse<DriverQuestions>) => res.data));
  }
}
