import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BackEndResponse } from 'src/app/shared/models/global/response';
import { tap } from 'rxjs/operators';
import { KPIToBeSaved, AchievementToBeSaved } from '../models/kpi';

@Injectable({
  providedIn: 'root'
})
export class SalesService {
  readonly URL_KPI_TYPES = environment.serverUrl + 'kpis/types';
  readonly URL_KPI_LIST = environment.serverUrl + 'kpis';
  readonly URL_ACHIEVEMENT = environment.serverUrl + 'achievements';
  constructor(private http: HttpClient) {}

  getKpiTypes(headers, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_KPI_TYPES, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(tap((data) => console.log(data)));
  }

  getKpis(headers, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_KPI_LIST, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(tap((data) => console.log(data)));
  }

  saveKpis(headers, body: KPIToBeSaved) {
    return this.http
      .post<BackEndResponse>(this.URL_KPI_LIST, body, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap((data) => console.log(data)));
  }

  deleteKPI(headers, kpiId: number) {
    return this.http
      .delete<BackEndResponse>(this.URL_KPI_LIST + '/' + kpiId, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap((data) => console.log(data)));
  }

  getAchievement(headers, kpiId: number, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_ACHIEVEMENT + '/' + kpiId, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(tap((data) => console.log(data)));
  }

  deleteAchievement(headers, achievementId: number) {
    return this.http
      .delete<BackEndResponse>(this.URL_ACHIEVEMENT + '/' + achievementId, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap((data) => console.log(data)));
  }

  saveAchievement(headers, body: AchievementToBeSaved) {
    return this.http
      .post<BackEndResponse>(this.URL_ACHIEVEMENT, body, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap((data) => console.log(data)));
  }

}
