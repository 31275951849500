import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { FormStatus } from '@shared/enums/form-status.enum';
import { CarwashCompany, Nullable, SubVariant, Variant } from "@shared/models";
// import { GlobalService } from '@shared/service/global.service';
import { CarWashAddOn, CwAddonsCwGarageBranch } from "@shared/models/carwash/car-wash-add-on.model";

@Component({
  selector: 'app-car-wash-add-on-pricing-form',
  templateUrl: './car-wash-add-on-form.component.html',
  styleUrls: ['./car-wash-add-on-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarWashAddOnPricingFormComponent
  implements OnInit, OnDestroy {
  form: UntypedFormGroup;

  @Input() carWashAddOn: CarWashAddOn;
  @Input() cwGarageBranchId: number;
  @Input() variants$: Observable<Array<Variant>>;
  @Output() formChanged: EventEmitter<Partial<CarWashAddOn>> =
    new EventEmitter<Partial<CarWashAddOn>>();
  @Output() formStatusChanged: EventEmitter<FormStatus> =
    new EventEmitter<FormStatus>();

  private readonly destroy$: Subject<void> = new Subject<void>();
  private initVariantPricingCompleted = new Subject<void>();

  constructor(
    private readonly fb: UntypedFormBuilder,
    // private readonly globalService: GlobalService
  ) {}

  get branchPricing(): UntypedFormArray {
    return this.form.get('cwAddonsCwGarageBranches') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.initVariantPricing();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initForm(): void {
    this.form = this.fb.group({
      cwAddonsCwGarageBranches: this.fb.array(
        this.setIncludeDetailsInitData(this.carWashAddOn)
      ),
    });
    this.patchForm();
  }

  private patchForm(): void {
    if (!this.carWashAddOn) {
      return;
    }

    this.form.patchValue(this.carWashAddOn);
    this.formChanged.emit(this.form.value);
  }

  private handleFormChanges(): void {
    const emitValue = (value: Partial<CarwashCompany>) =>
      this.formChanged.emit(value);

    this.form.valueChanges
      .pipe(debounceTime(500), tap(emitValue), takeUntil(this.destroy$))
      .subscribe();
  }

  private handleFormStatusChanges(): void {
    const emitStatus = (value: FormStatus) =>
      this.formStatusChanged.emit(value);

    this.form.statusChanges
      .pipe(
        startWith(this.form.status),
        distinctUntilChanged(),
        tap(emitStatus),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private setIncludeDetailsInitData(carWashAddOn: Nullable<CarWashAddOn>) {
    if (carWashAddOn && carWashAddOn.cwAddonsCwGarageBranches.length) {
      return carWashAddOn.cwAddonsCwGarageBranches.map(() =>
        this.createFormDetail(true)
      );
    } else {
      return [this.createFormDetail(true)];
    }
  }

  private createFormDetail(isInclude: boolean = false): UntypedFormGroup {
    return this.fb.group({
      subVariantId: ['', [Validators.maxLength(250)]],
      cwAddonId: ['', [Validators.maxLength(250)]],
      cwGarageBranchId: ['', [Validators.maxLength(250)]],
      price: ['', [Validators.maxLength(250)]],
    });
  }

  private mapSubVariantPrice(
    cwAddonId: number,
    subVariantId: number,
    subVariant: SubVariant
  ): CwAddonsCwGarageBranch {
    const cwAddonsCwGarageBranch = this.carWashAddOn.cwAddonsCwGarageBranches.find(
      (cwAddonsCwGarageBranch) =>
        cwAddonsCwGarageBranch.subVariantId === subVariantId && cwAddonsCwGarageBranch.cwGarageBranchId === this.cwGarageBranchId
    );
    return {
      subVariantId,
      cwAddonId: cwAddonId,
      cwGarageBranchId: this.cwGarageBranchId,
      price: cwAddonsCwGarageBranch ? cwAddonsCwGarageBranch.price : 0,
      subVariant
    };
  }

  private initVariantPricing() {
    this.variants$
      .pipe(
        takeUntil(this.destroy$),
        tap((variants) => {
          const subVariants = variants
            .map((variant) => variant.subVariants[0])
            .filter((subVariant) => subVariant !== undefined);

          // Update this.carWashAddOn.cwAddonsCwGarageBranches based on subVariants
          this.carWashAddOn.cwAddonsCwGarageBranches = subVariants.map((subVariant) => {
            return this.mapSubVariantPrice(this.carWashAddOn.id, subVariant.id, subVariant);
          });

          // Signal that initVariantPricing is completed
          this.initVariantPricingCompleted.next();
          this.initVariantPricingCompleted.complete();

          // After completion, proceed with the rest of the code
          this.initForm();
          this.handleFormChanges();
          this.handleFormStatusChanges();
        })
      )
      .subscribe();
  }
}
