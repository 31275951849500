import { Language } from 'libs/justclean-models/src/lib/justclean-models';

export class Translation {
  language_id: number;
  name: string;
}

export class CountryToBeSaved {
  translations: Translation[];
  polylines: string;
}

export class CountryTranslation {
  id: number;
  language_id: number;
  name: string;
  country_id: number;
  updated_at: Date;
  created_at: Date;
}

export class CountryList {
  id: number;
  country_code: string;
  name: Name;
  country_url?: any;
  currency: string;
  currency_symbol: string;
  currency_decimal: number;
  thousands_seperator: string;
  accept_order: string;
  confirm_order_normal: string;
  confirm_order_fast: string;
  complete_order: string;
  warning: string;
  late: string;
  urgent: string;
  base_coverage: string;
  base_capacity: string;
  additional_driver_hour: string;
  additional_driver_radius: string;
  tax: number;
  latitude: number;
  longitude: number;
  country_with_area_flag: string;
  status: string;
  hotline: string;
  time_zone: string;
  has_cities: boolean;
  tracking_icon: string;
  tracking_logo: string;
  flag_url: string;
  has_partners: boolean;
  polylines: string;
}

export class Country {
  currency_decimal: number;
  thousands_seperator: number;
  accept_order: string;
  confirm_order_normal: string;
  confirm_order_fast: string;
  complete_order: string;
  warning: string;
  late: string;
  urgent: string;
  base_coverage: number;
  base_capacity: number;
  additional_driver_hour: number;
  additional_driver_radius: number;
  tax: number;
  latitude: number;
  longitude: number;
  status: string;
  id: number;
  name: string;
  country_translations: CountryTranslation[];
  updated_at: Date;
  created_at: Date;
}

export class CountrySLA {
  country_id: number;
  country_url?: any;
  currency: string;
  currency_symbol: string;
  currency_decimal: string;
  thousands_seperator: string;
  tax: string;
  accept_order: string;
  confirm_order_normal: string;
  confirm_order_fast: string;
  complete_order: string;
  warning: string;
  late: string;
  urgent: string;
  status: string;
  base_coverage: string;
  base_capacity: string;
  additional_driver_hour: string;
  additional_driver_radius: string;
  translations: Translation[];
}

export class GovernorateToBeSaved {
  country_id: number;
  translations: Translation[];
  latitude: string;
  longitude: string;
}

export class GovernorateTranslation {
  id: number;
  language_id: number;
  name: string;
  governorate_id: number;
  updated_at: Date;
  created_at: Date;
}

export class Governorate {
  latitude: number;
  longitude: number;
  status: string;
  id: number;
  name: string | {en: string, ar: string};
  country_id: number;
  governorate_translations: GovernorateTranslation[];
  updated_at: Date;
  created_at: Date;
}

export class AreaTranslation {
  id: number;
  language_id: number;
  name: string;
  area_id: number;
  updated_at: string;
  created_at: string;
}

export class Area {
  latitude: number;
  longitude: number;
  status: string;
  id: number;
  name: Language;
  country_id: number;
  governorate_id: string;
  area_translations: AreaTranslation[];
  updated_at: string;
  created_at: string;
  polylines: string;
}

export class AreaLang {
  translations: Translation[];
}

export class AreaToBeSaved {
  country_id: number;
  governorate_id: number;
  country_with_area_flag: number;
  latitude: string;
  longitude: string;
  name: string;
  translations: Translation[];
  polylines: string;
}

export class IconName {
  icon: string;
  name: string;
}

export class PaymentMethod {
  id: number;
  name: string;
  icon: string;
  status: string;
  is_deleted: number;
  createdAt: Date;
  updatedAt: Date;
}

export class Name {
  en: string;
  ar: string;
}

export class AreaList {
  id: number;
  name: Name;
  latitude: number;
  longitude: number;
  status: string;
  country_id: number;
  governorate_id: number;
}

export class GovernorateList {
  id: number;
  name: Name;
  latitude: number;
  longitude: number;
  status: string;
  country_id: number;
}
