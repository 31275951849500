import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/shared/service/global.service';
import { CorporateService } from 'src/app/shared/service/corporate.service';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import {
  ContractOrders,
  CorporateOrderScheduleValues
} from 'src/app/shared/models/laundries/corporate';
import { BranchesService } from 'src/app/shared/service/branches.service';
import {
  BranchTimeSlotNew,
  Times
} from 'src/app/shared/models/laundries/branch';
import { zip } from 'rxjs';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.css']
})
export class OrdersListComponent implements OnInit {
  ordersList: ContractOrders[];
  order: CorporateOrderScheduleValues[];
  branchId: number;
  contractId: number;
  dataReady: boolean;
  constructor(
    private globalService: GlobalService,
    private corporateService: CorporateService,
    private route: ActivatedRoute,
    private toast: ToastService,
    private branchService: BranchesService
  ) {}

  ngOnInit() {
    this.order = [];
    this.branchId = this.route.snapshot.queryParams.branchId;
    this.contractId = this.route.snapshot.queryParams.contractId;
    this.getData();
  }

  getTimeSlots() {
    return this.branchService.getBranchTimeSlot(
      this.globalService.headers,
      this.branchId
    );
  }

  getOrders() {
    return this.corporateService.getScheduledOrderForContract(
      this.globalService.headers,
      this.contractId
    );
  }

  getData() {
    zip(this.getOrders(), this.getTimeSlots()).subscribe(response => {
      if (response[0].code === 200 && response[1].code === 200) {
        this.ordersList = response[0].data;
        const slots = response[1].data as BranchTimeSlotNew[];
        const order = this.ordersList[0];
        order.corporate_order_schedules.forEach((ele, i) => {
          const pickupDay = slots.find(slot => slot.id === ele.pickup_day_id);
          this.order[i] = new CorporateOrderScheduleValues();
          this.order[i].pickUpDay = pickupDay.day;
          const deliveryDay = slots.find(
            slot => slot.id === ele.delivery_day_id
          );
          this.order[i].deliveryDay = deliveryDay.day;
          let pickUp: Times;
          slots.forEach(day =>
            day.times.forEach(time => {
              if (time.id === ele.pickup_timeslot_id) {
                pickUp = time;
              }
            })
          );
          let delivery: Times;
          slots.forEach(day =>
            day.times.forEach(time => {
              if (time.id === ele.delivery_timeslot_id) {
                delivery = time;
              }
            })
          );
          this.order[i].pickUpTimeSlot =
            this.globalService.getHours(pickUp.start) +
            ' H - ' +
            this.globalService.getHours(pickUp.end) +
            ' H';
          this.order[i].deliveryTimeSLot =
            this.globalService.getHours(delivery.start) +
            ' H - ' +
            this.globalService.getHours(delivery.end) +
            ' H';
          this.order[i].id = ele.id;
          this.order[i].type = ele.type;
        });
        this.dataReady = true;
        console.log(this.order);
      } else {
        response.forEach(resp => this.toast.error(resp.message));
      }
    });
  }
}
