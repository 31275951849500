import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { LaundryInfo } from '../../../../shared/models/laundries/laundry';
import { CarList } from '../../../../shared/models/cars/cars';
import { ModalDirective } from 'ng-uikit-pro-standard';

import { CarsService } from '../../../../shared/service/cars.service';
import { GlobalService } from '../../../../shared/service/global.service';
import { LaundryService } from '../../../../shared/service/laundry.service';
import { BranchesService } from '../../../../shared/service/branches.service';
import { DriverToBeSaved } from '../../../../shared/models/cars/driver';
import { DriversService } from '../../../../shared/service/drivers.service';
import {
  OrderBasketService,
  BranchInfo
} from '../../../../shared/models/laundries/branch';

@Component({
  selector: 'app-drivers-create',
  templateUrl: './drivers-create.component.html',
  styleUrls: ['./drivers-create.component.css']
})
export class DriversCreateComponent implements OnInit, OnChanges {
  @Input() isShown: boolean;
  @Output() isClosed: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('createDriverModal', { static: true }) modal: ModalDirective;
  @ViewChild('createDriver', { static: false }) form: NgForm;
  @Input() branchId: number;
  @Input() laundryId: number;

  name: string;
  firstName: string;
  nameAr: string;
  firstNameAr: string;
  mobile: string;
  selectedLaundry: number;
  laundries: any[];
  branches: any[];
  selectedBranch: number;
  areas: any[];
  selectedArea: number;
  cars: any[];
  selectedCar: number;
  showError = false;
  errorBackEnd: any;
  loader = false;
  CarOptionsList = [
    { value: 'pickup_and_delivery', label: 'Pick and Delivery' },
    { value: 'pickup', label: 'PickUp' },
    { value: 'delivery', label: 'Delivery' }
  ];
  carOptions: string;

  constructor(
    private carsService: CarsService,
    private globalService: GlobalService,
    private laundryService: LaundryService,
    private branchService: BranchesService,
    private driverService: DriversService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.isShown = changes.isShown.currentValue;

    if (this.isShown) {
      this.modal.show();
    }
  }

  ngOnInit() {
    this.getLaunderies();
    this.getAreas();
    this.getCars();
  }

  getLaunderies() {
    this.laundryService
      .getLaundryInfo(this.globalService.headers, this.laundryId)
      .subscribe(response => {
        const laundry = response.data as LaundryInfo;
        this.laundries = [];
        this.laundries.push({
          value: laundry.id,
          label: laundry.name,
          selected: true
        });
        this.selectedLaundry = this.laundryId;
        this.branchService
          .getBranchInfo(this.globalService.headers, this.branchId)
          .subscribe(backResponse => {
            const branches = backResponse.data as BranchInfo;
            this.branches = [];
            this.branches.push({
              value: branches.id,
              label: branches.name,
              selected: true
            });
          });
      });
  }

  getAreas() {
    this.branchService
      .getBranchAres(this.globalService.headers, this.branchId)
      .subscribe(response => {
        const areas = response.data as OrderBasketService[];
        this.areas = [];
        areas.forEach(area => {
          this.areas.push({ value: area.id, label: area.name });
        });
      });
  }

  getCars() {
    this.carsService
      .getCars(this.globalService.headers, { page: 0 })
      .subscribe(response => {
        const cars = response.data as CarList[];
        this.cars = [];
        cars.forEach(car => {
          this.cars.push({ value: car.id, label: car.name });
        });
      });
  }

  onHidden() {
    this.isClosed.emit(true);
    this.initForm();
  }

  saveDriver() {
    this.loader = true;
    const driver = new DriverToBeSaved();
    driver.area_id = this.selectedArea;
    driver.car_id = this.selectedCar;
    driver.car_for = this.carOptions;
    driver.laundry_branch_id = this.selectedBranch;
    driver.driver_translations = [];
    driver.mobile_no = this.mobile.toString();
    driver.driver_translations[0] = {
      language_id: 1,
      last_name: this.name,
      first_name: this.firstName
    };
    driver.driver_translations[1] = {
      language_id: 2,
      last_name: this.nameAr,
      first_name: this.firstNameAr
    };

    this.driverService.addDriver(this.globalService.headers, driver).subscribe(
      response => {
        if (response.code === 200) {
          this.loader = false;
          this.modal.hide();
          this.refreshData.emit(true);
        }
      },
      error => {
        this.loader = false;
        this.showError = true;
        this.errorBackEnd = error.message;
      }
    );
  }

  initForm() {
    this.form.reset();
  }
}
