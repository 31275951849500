import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LaundryService } from 'src/app/shared/service/laundry.service';
import { GlobalService } from 'src/app/shared/service/global.service';
import { BranchesService } from 'src/app/shared/service/branches.service';
import {
  ToastService,
  MDBDatePickerComponent,
  IMyOptions,
} from 'ng-uikit-pro-standard';
import { LaundryFromList } from 'src/app/shared/models/laundries/laundry';
import { BranchList } from 'src/app/shared/models/laundries/branch';
import { SelectOptions } from 'src/app/shared/models/global/response';
import { FinanceService } from '../../services/finance.service';
import {
  LaundryBillingParams, FinanceSummaryReport
} from '../../models/finance';
import { ExcellService } from 'src/app/shared/service/excell.service';
const payments = {
  1: 'Knet',
  3: 'Benefit',
  4: 'Mada',
  5: 'Qpay',
};
@Component({
  selector: 'app-finance-detailed-report',
  templateUrl: './finance-detailed-report.component.html',
  styleUrls: ['./finance-detailed-report.component.css'],
})
export class FinanceDetailedReportComponent implements OnInit {
  laundries: SelectOptions<number>[];
  branches: SelectOptions<number>[];
  selectedLaundry: number;
  selectedBranch: number[] = [];
  selectedStartDate: string;
  selectedEndDate: string;
  expiryDateOption: IMyOptions;
  details: FinanceSummaryReport;
  dataReady = false;
  loader = false;
  selectedYear: string;
  selectedMonth: string;
  years: SelectOptions<string>[] = [];
  months: SelectOptions<string>[] = [];
  currency: string;
  @ViewChild('from', {
    static: true,
  })
  from: MDBDatePickerComponent;
  @ViewChild('to', {
    static: true,
  })
  to: MDBDatePickerComponent;
  @ViewChild('data', {
    static: false,
  })
  data: ElementRef;
  constructor(
    private laundryService: LaundryService,
    private globalService: GlobalService,
    private branchService: BranchesService,
    private financeService: FinanceService,
    private excelService: ExcellService,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.currency = this.globalService.currency;
    this.getLaundries();
    this.initData();
  }

  getLaundries() {
    this.laundryService
      .getLaundriesWithParams(this.globalService.headers, { page: 0 })
      .subscribe((response) => {
        const laundry = response.data as LaundryFromList[];
        this.laundries = [];
        laundry.forEach((e) => {
          this.laundries.push({
            value: e.id,
            label: e.name,
          });
        });
      });
  }

  getBranches(laundryId: number) {
    this.branchService
      .getBranchList(this.globalService.headers, laundryId)
      .subscribe((data) => {
        if (data.code === 200) {
          const branches = data.data as BranchList[];
          this.branches = [];
          branches.forEach((e) => {
            this.branches.push({
              value: e.id,
              label: e.name,
            });
          });
        }
      });
    this.selectedBranch = null;
  }

  startDateChanged(event) {
    if (event.epoc) {
      const date = this.globalService.formatDate(
        this.globalService
          .datePlusDays(event.actualDateFormatted, 0)
          .toString(),
        'YYYY-MM-DD'
      );
      this.expiryDateOption = {
        disableUntil: {
          year: parseInt(date.split('-')[0], 10),
          month: parseInt(date.split('-')[1], 10),
          day: parseInt(date.split('-')[2], 10),
        },
      };
      this.selectedStartDate = date;
    }
  }

  endDateChanged(event) {
    if (event.epoc) {
      const date = this.globalService.formatDate(
        this.globalService
          .datePlusDays(event.actualDateFormatted, 0)
          .toString(),
        'YYYY-MM-DD'
      );
      this.selectedEndDate = date;
    }
  }

  generateData() {
    this.getData();
  }

  prettifyFieldName(s: string) {
    return s
      .replace('_', ' ')
      .split(' ')
      .map((e) => e[0].toLocaleUpperCase)
      .join(' ');
  }

  onlineMethodPaymentKey() {
    return payments[this.globalService.countryId];
  }

  onlinePaymentMethodValue() {
    let value = '';
    switch (this.globalService.countryId) {
      case 1:
        value = this.details.online_payment.knet;
        break;
      case 2:
        value = this.details.online_payment.knet;
        break;
      case 3:
        value = this.details.online_payment.benifit;
        break;
      case 4:
        value = this.details.online_payment.mada;
        break;
      case 5:
        value = this.details.online_payment.qpay;
        break;
    }
    return value;
  }

  onlinePaymentMethodValueCharges() {
    let value = '';
    switch (this.globalService.countryId) {
      case 1:
        value = this.details.charge_and_discount.knet_charge;
        break;
      case 2:
        value = this.details.charge_and_discount.knet_charge;
        break;
      case 3:
        value = this.details.charge_and_discount.benifit_charge;
        break;
      case 4:
        value = this.details.charge_and_discount.mada_charge;
        break;
      case 5:
        value = this.details.charge_and_discount.qpay_charge;
        break;
    }
    return value;
  }

  toPay() {
    const amount =
      parseFloat(this.details.total_amount_laundry.total);
    return amount > 0
      ? `JustClean to pay ${this.currency}  ${Math.abs(amount).toFixed(3)}`
      : amount < 0
      ? `Laundry to pay ${this.currency}  ${Math.abs(amount).toFixed(3)}`
      : 'Nothing To pay from both parties';
  }
  exportExcel() {
    this.excelService.generateSummaryBill(
      this.data.nativeElement,
      this.laundries.find((e) => e.value === this.selectedLaundry).label +
        '-' +
        this.selectedStartDate +
        '/' +
        this.selectedEndDate,
      {
        country: this.globalService.getCountry(),
        laundry: this.laundries.find((e) => e.value === this.selectedLaundry)
          .label,
        laundryBranch: this.branches
          .filter((e) => this.selectedBranch.includes(e.value))
          .map((e) => e.label)
          .toString(),
        period: this.selectedStartDate + ' / ' + this.selectedEndDate,
      }
    );
  }

  private getData() {
    this.dataReady = false;
    this.loader = true;
    this.financeService
      .getLaundryBillingDetails(this.globalService.headers, {
        start_date: this.selectedStartDate,
        end_date: this.selectedEndDate,
        laundry_branch_id: this.selectedBranch.toString(),
        laundry_id: `${this.selectedLaundry}`,
      } as LaundryBillingParams)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.details = response.data;
            this.dataReady = true;
          } else {
            this.toast.error(response.message);
          }
          this.loader = false;
        },
        (error) => {
          this.loader = false;
        }
      );
  }
  /*
  private cleanOrders(orders: LaundryBillOrders[]) {
  } */
  private initData() {
    const currentYear = new Date().getFullYear();
    const startYear = 2018;
    for (let i = startYear; i <= currentYear; i++) {
      this.years.push({
        label: i + '',
        value: i + '',
      });
    }
    const months = this.globalService.getMonthList();
    const keys = Object.keys(months);
    for (const key of keys) {
      this.months.push({
        label: months[key],
        value: parseInt(key, 10) + 1 + '',
      });
    }
  }
}
