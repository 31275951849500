import { GenericBackend } from './generic-backend';

export class User {
  id: number;
  name: string;
  email: string;
  corporate_id?: any;
  laundry_id: number;
  created_at: Date;
  status: string;
  country: GenericBackend;
  role_admin: GenericBackend;
  department_master: GenericBackend;
  user_admin_voips: Voip[];
  assigned_country: number[];
}

export class Voip {
  id: number;
  type: string;
  voip_id: string;
  country_id: number;
}

export class RoleAdmin {
  id: number;
  name: string;
}

export class Country {
  id: number;
  name: string;
  currency_decimal?: number;
  currency_symbol?: string;
  currency?: string;
}

export class Department {
  id: number;
  name: string;
}

export class UserToBeUpated {
  id: number;
  name: string;
  email: string;
  role_admin: RoleAdmin;
  country: Country;
  department: Department;
  corporate_id?: any;
  laundry_id?: any;
  created_at: string;
  status: string;
}

export class LoggedinUser {
  id: number;
  email: string;
  name?: string;
  role_id: number;
  token: string;
  country_id: number;
  assigned_country: number[];
}

export class RolesList {
  id: number;
  name: string;
  status: string;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  departments_admin_id?: any;
}

export class CorporateList {
  id: number;
  name: string;
  status: string;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
}

export class JlmTranslations {
  first_name: string;
  last_name: string;
  language_id: number;
}

export class UserToBeSaved {
  id?: number;
  corporate_id?: any;
  department_id: number;
  email: string;
  laundry_id: number;
  laundry_branch_id?: number;
  password: string;
  role_admin_id: number;
  translations?: JlmTranslations[];
  name?: string;
  first_name?: string;
  last_name?: string;
  pin?: number;
  country_id?: number;
  assigned_country?: number[];
  voip_id?: string;
}
