import { Group, ReferralSetting } from '@shared/models';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../../../shared/service/global.service';
import { CustomerService } from '../../../../shared/service/customer.service';
import {
  CustomerDetails,
  UserAddress,
  OrderCustomerNew,
  GiftToUserBody,
  ChangeUserType,
  CustomerAddress,
} from '../../../../shared/models/customer/customer';
import {
  PageInfo,
  SelectOptions,
} from '../../../../shared/models/global/response';
import { ToastService } from 'ng-uikit-pro-standard';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { CarwashOrder } from '@app/modules/carwash/modules/carwash-order/components/shared/models/carwash-order.model';
import { CountryService } from '@app/shared/service/country.service';
import { CarwashOrderService } from '@app/modules/carwash/modules/carwash-order/components/shared/services/carwash-order.service';
import { GroupService } from '@app/shared/service/group';
import { ReferralSettingService } from '@app/shared/service/referral-settings';

interface CustomerOrderAnalytics {
  group: { name: { en: string } };
  orderCount: number;
  totalSpend: string;
  totalGiftSpend: string;
  giftSpendPercentage: string;
}

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss'],
})
export class CustomerDetailsComponent implements OnInit, OnDestroy {
  currentCustomer: CustomerDetails;
  orders: OrderCustomerNew[] | CarwashOrder[];
  isDataReady: boolean;
  modalTitle: string;
  isUpdate: boolean;
  openDialog: boolean;
  addressToBeUpdated: UserAddress;
  addressToBeDeleted: UserAddress;
  toggleDeleteModal = false;
  loader = false;
  currency: string;
  pagination: PageInfo;
  customerId: number;
  isConfirmationModalOpened = false;
  isGiftModalOpened = false;
  userTypesInForm: SelectOptions<any>[];
  userTypeId: number | null;
  gift: GiftToUserBody;
  customerGroups$: Observable<any>;
  customerBalance$: Observable<any>;
  /** Select Laundry group by default */
  selectedGroupId = 1;
  currentPage = 1;
  subscriber$: Subscription;
  cwOrders$: Observable<CarwashOrder[]>;
  cwPagination$: Observable<PageInfo>;
  isLoading: boolean;
  showAddressModal: boolean;

  menuItems: { icon: string; title: string }[];
  selectedMenuItemIndex: number = 0;
  getFullAddressBounded: Function;
  private readonly destroy$: Subject<void> = new Subject<void>();
  addresses: CustomerAddress[];
  referralSetting: ReferralSetting;
  isChangeReferralSetting: boolean;
  isSavingCustomerTypes: boolean = false;

  customerOrderAnalytics$: Observable<CustomerOrderAnalytics[]>;
  customerTransactions$: Observable<any[]>;
  totalOrderCount: number;
  totalRow: CustomerOrderAnalytics;

  constructor(
    private route: ActivatedRoute,
    protected globalService: GlobalService,
    protected customerService: CustomerService,
    protected referralSettingService: ReferralSettingService,
    protected countryService: CountryService,
    public groupService: GroupService,
    protected carwashOrderService: CarwashOrderService,
    protected toast: ToastService,
  ) {}

  ngOnInit() {
    this.setMenuItems();
    this.isDataReady = false;
    this.customerId = this.route.snapshot.params.id;
    this.groupService.selectedGroup = null;
    this.getCustomerDetails(this.customerId);
    this.getCustomerAddresses(this.customerId);
    this.loadGroups();
    this.loadCustomerOrderAnalytics();
    this.loadCustomerBalance();
    this.loadCustomerTransactions();
    this.currency = this.globalService.getCurrency();
    this.getUserTypes();
    this.getReferralSetting(this.customerId);
    this.getFullAddressBounded = this.getFullAddress.bind(this);
  }

  openCreate() {
    this.modalTitle = 'Create New Address';
    this.isUpdate = false;
    this.addressToBeUpdated = null;
    this.showAddressModal = true;
    setTimeout(() => (this.openDialog = true));
  }

  openUpdate(address: UserAddress) {
    this.modalTitle = 'Update Address:' + address.name;
    this.isUpdate = true;
    this.addressToBeUpdated = address;
    this.showAddressModal = true;
    setTimeout(() => (this.openDialog = true));
  }

  callDelete(address: UserAddress) {
    this.addressToBeDeleted = address;
    this.toggleDeleteModal = true;
  }

  deleteAddress(event) {
    if (event) {
      this.loader = true;
      this.customerService
        .deleteCustomerAddress(this.globalService.headers, {
          id: this.addressToBeDeleted.id,
          user_id: this.route.snapshot.params.id,
        })
        .subscribe((data) => {
          if (data.code === 200) {
            this.loader = false;
            this.toggleDeleteModal = false;
            this.getCustomerDetails(this.route.snapshot.params.id);
          }
        });
    }
  }

  closeModal(event) {
    this.showAddressModal = false;
    this.openDialog = false;
    if (event) {
      this.getCustomerDetails(this.route.snapshot.params.id);
      this.getCustomerAddresses(this.route.snapshot.params.id);
    }
  }

  getPage(pageNumber: number) {
    this.currentPage = pageNumber;
  }

  setActiveTab(group: Group): void {
    if (this.selectedGroupId !== group.id) {
      this.groupService.selectedGroup = group;
      this.selectedGroupId = group.id;
    }
  }

  getCurrency() {
    return this.globalService.currency;
  }

  getDate(date: string) {
    return this.globalService.getDateTimeFromString(date);
  }

  saveCompensation($event) {
    this.loader = true;
    this.customerService
      .giveCreditToUser(this.globalService.headers, this.gift)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.loader = false;
            this.closeConfirmationModal(true);
            this.closeGiftModal(true);
            this.getCustomerDetails(this.customerId);
            this.toast.success(response.message);
          } else {
            this.toast.error(response.message);
            this.closeConfirmationModal(true);
            this.loader = false;
          }
        },
        (error) => {
          this.closeConfirmationModal(true);
          this.loader = false;
        },
      );
  }

  closeConfirmationModal($event) {
    this.isConfirmationModalOpened = false;
  }

  openConfirmation($event) {}

  closeGiftModal($event) {
    this.isGiftModalOpened = false;
    this.gift.promo_code = null;
  }

  updateUserTypes(): void {
    const params = {
      userTypeId: this.userTypeId,
    } as ChangeUserType;

    this.isSavingCustomerTypes = true;
    this.customerService
      .updateUserType(this.globalService.headers, this.customerId, params)
      .subscribe((response) => {
        this.isSavingCustomerTypes = false;
        if (response.code === 200) {
          this.toast.success(response.message);
        } else {
          this.toast.error(response.message);
        }
      });
  }

  private getFullAddress(address: any): Observable<string> {
    return this.customerService.getFullAddress(address, this.destroy$);
  }

  private getCustomerDetails(id) {
    this.customerService
      .getCustomerDetails(this.globalService.headers, id)
      .subscribe((response) => {
        if (response.code === 200) {
          this.currentCustomer = response.data;
          this.isDataReady = true;
          this.gift = {
            user_id: this.currentCustomer.user_details.id,
            promo_code: null,
          };
          this.userTypeId = this.currentCustomer.user_details.user_type_id;
        }
      });
  }

  private getCustomerAddresses(userId: number): void {
    this.customerService.getCustomerAddresses(userId).subscribe((addresses) => {
      this.addresses = addresses;
    });
  }

  private getUserTypes(): void {
    this.customerService.getUserTypes(this.globalService.headers).subscribe(
      (response) => {
        if (response.code === 200) {
          this.userTypesInForm = response.data.map((type) => {
            return {
              value: type.id,
              label: type.name,
              group: false,
            };
          });
        } else {
          console.log('No user type fetched!');
        }
      },
      (error) => {
        console.log(error.error.message);
      },
    );
  }

  toggleReferralSetting(): void {
    this.isChangeReferralSetting = true;
    const params: ReferralSetting = {
      userId: +this.customerId,
      countryId: this.globalService.countryId,
      bookingCountToUnlockSender:
        this.referralSetting?.bookingCountToUnlockSender === 4 ? 0 : 4,
    };
    this.referralSettingService
      .updateReferralSetting(this.customerId, params)
      .pipe(finalize(() => (this.isChangeReferralSetting = false)))
      .subscribe((setting) => {
        this.referralSetting = setting;
      });
  }

  private getReferralSetting(userId: number): void {
    this.referralSettingService
      .getReferralSetting(userId)
      .subscribe((setting) => {
        this.referralSetting = setting;
      });
  }

  private loadGroups(): void {
    console.log('loadingGroups: ')
    this.customerGroups$ = this.customerService.getCustomerVerticalOrderCount(this.globalService.headers, this.customerId).pipe(
      map((customerGroups: any) => {
        console.log('getCustomerVerticalOrderCount: ', customerGroups)
        if (customerGroups && customerGroups[0]) {

          this.groupService.selectedGroup = customerGroups[0].group
        }
        return customerGroups;
      }),
    );
  }

  private loadCustomerOrderAnalytics(): void {
    let decimalPlaces = this.globalService.getCurrencyDecimal(); // Retrieve the number of decimal places for the currency
    this.customerOrderAnalytics$ = this.customerService.getCustomerOrderAnalytics(this.globalService.headers, this.customerId).pipe(
      map((customerAnalytics: CustomerOrderAnalytics[] | null) => {
        if (!customerAnalytics) {
          // Handle the case where customerAnalytics is null or undefined
          this.totalRow = { group: { name: { en: 'Total' } }, orderCount: 0, totalSpend: '0', totalGiftSpend: '0', giftSpendPercentage: '0' };
          this.totalOrderCount = 0;
          return [];
        }

        // Calculate totals
        let totalSpend = 0;
        let totalGiftSpend = 0;
        this.totalRow = customerAnalytics.reduce(
          (acc, group) => {
            acc.orderCount += group?.orderCount ?? 0;
            totalSpend += parseFloat(group?.totalSpend) || 0;
            totalGiftSpend += parseFloat(group?.totalGiftSpend) || 0;
            return acc;
          },
          { group: { name: { en: 'Total' } }, orderCount: 0, totalSpend: '0', totalGiftSpend: '0', giftSpendPercentage: '0' }
        );

        // Calculate gift spend percentage for the total row
        let giftSpendPercentage = totalSpend > 0
          ? (totalGiftSpend / totalSpend) * 100
          : 0;

        // Format the totalSpend and totalGiftSpend back to strings with fixed decimal places
        this.totalRow.totalSpend = totalSpend.toFixed(decimalPlaces);
        this.totalRow.totalGiftSpend = totalGiftSpend.toFixed(decimalPlaces);

        // Format the percentage as a string with 2 decimal places
        this.totalRow.giftSpendPercentage = giftSpendPercentage.toFixed(2);

        this.totalOrderCount = this.totalRow.orderCount;
        return customerAnalytics;
      })
    );
  }



  private loadCustomerBalance(): void {
    this.customerBalance$ = this.customerService.getCustomerBalance(this.globalService.headers, this.customerId).pipe(
      map((customerBalance: any) => {
        console.log('customerBalance: ', customerBalance);
        // Calculate total order count
        // this.totalOrderCount = customerAnalytics.reduce((sum, group) => sum + group.orderCount, 0);

        return customerBalance;
      }),
    );
  }

  private loadCustomerTransactions(): void {
    this.customerTransactions$ = this.customerService
      .getMappedTransactions(this.globalService.headers, this.customerId, 1)
      .pipe(
        map((mappedTransactions: any[]) => {
          console.log('mappedTransactions: ', mappedTransactions);
          return mappedTransactions; // Now mapped with payment methods
        })
      );
  }



  private setMenuItems(): void {
    this.menuItems = [
      {
        icon: 'orders',
        title: 'CUSTOMER_ORDERS',
      },

      {
        icon: 'address',
        title: 'CUSTOMER_ADDRESSES',
      },
      {
        icon: 'details',
        title: 'CUSTOMER_TYPE',
      },
      {
        icon: 'balance',
        title: 'CUSTOMER_ACCOUNT_BALANCE',
      },
      {
        icon: 'cashback',
        title: 'CUSTOMER_CASH_BACK',
      },
      {
        icon: 'cashback',
        title: 'CUSTOMER_TRANSACTION_HISTORY',
      },
    ];
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  goToMap(latitude: number, longitude: number) {
    const url = `https://maps.google.com/?q=${latitude},${longitude}`;
    window.open(url, '_blank');
  }

  openOrderDetails(transactionType: string, orderId: number, groupId: number, callType: string, designType: string) {
    console.log(`openOrderDetails orderId:${orderId}, groupId: ${groupId}, callType: ${callType}, designType: ${designType} `)

    if (transactionType === 'credit') {
      return;
    }
    // Navigate to the order details route
    // this.router.navigate([`/dashboard/orders/order-details/${orderId}`]);
    switch (designType) {
      case 'laundry':
        window.open(`/dashboard/orders/order-details/${orderId}`, '_blank');
        break;
      case 'homeCleaning':
        window.open( `dashboard/homeclean/orders/details/${orderId}?isMobileType=true`, '_blank');
        break;
      case 'carwashHome':
      case 'carwashGarage':
        window.open( `/dashboard/carwash/orders/details/${orderId}?isMobileType=true`, '_blank');
        break;
      default:
        break;
    }

  }
}
