import { NgModule } from '@angular/core';
import { SharedModule } from '@app/modules/carwash/shared/shared.module';
import {
  CarWashAddOnPricingListComponent
} from "@app/modules/carwash/modules/car-wash-addons-pricing/components/car-wash-add-on-pricing-list/car-wash-add-on-pricing-list.component";
import {
  CarWashAddOnPricingFormComponent
} from "@app/modules/carwash/modules/car-wash-addons-pricing/components/car-wash-add-on-pricing-edit-dialog/car-wash-add-on-pricing-form/car-wash-add-on-pricing-form.component";
import {
  CarWashAddOnPricingEditDialogComponent
} from "@app/modules/carwash/modules/car-wash-addons-pricing/components/car-wash-add-on-pricing-edit-dialog/car-wash-add-on-pricing-edit-dialog.component";
import { CarWashAddOnPricingRoutingModule } from "@app/modules/carwash/modules/car-wash-addons-pricing/car-wash-add-on-pricing-routing.module";
@NgModule({
  imports: [CarWashAddOnPricingRoutingModule, SharedModule],
  declarations: [
    CarWashAddOnPricingListComponent,
    CarWashAddOnPricingFormComponent,
    CarWashAddOnPricingEditDialogComponent
  ],
})
export class CarWashAddOnPricingModule {}
