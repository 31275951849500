import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable, of, take } from "rxjs";

import { Group } from '@shared/models';
import { Status } from '@shared/enums/status.enum';
import { PageInfo } from '@shared/models/global/apiResponse';
import { GroupService } from '@shared/service/group';
import { GlobalService } from "@shared/service/global.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { tap } from "rxjs/operators";

@Component({
  selector: 'app-group-list',
  templateUrl: './group-positioning.component.html',
})
export class GroupPositioningComponent implements OnInit {
  countryId: number;
  readonly Status = Status;
  pagination$: Observable<PageInfo>;
  groups$: Observable<Array<Group>>;
  isLoading: boolean = false;

  constructor(
    private readonly groupService: GroupService,
    private readonly globalService: GlobalService
  ) {
    this.countryId = this.globalService.countryId;
  }

  ngOnInit(): void {
    this.loadGroups();
    this.pagination$ = this.groupService.pagination$;
  }

  private loadGroups(forceLoad?: boolean): void {
    console.error('countryID: ', this.countryId)
    this.groups$ = this.groupService.getGroups({
      countryId: this.countryId.toString(),
      showWithoutProviders: 'false',
      sorting: 'recommended'
    }, forceLoad);
  }


  drop(event: CdkDragDrop<Group[]>): void {
    this.isLoading = true; // Start loading
    this.groups$.pipe(
      take(1),
      tap(groups => {

        // Clone the current array to a new one
        const updatedGroups = [...groups];

        moveItemInArray(updatedGroups, event.previousIndex, event.currentIndex);

        const updateOperations = updatedGroups.map((group, index) => {
          if (group?.groupCountries[0]?.sortOrder !== index + 1) {
            return this.updateGroupPosition(group.groupCountries[0].id, group.id, index + 1);
          }
          return of(null); // Return an observable that does nothing for unchanged items
        });

        // Wait for all updates to complete
        forkJoin(updateOperations).subscribe({
          next: () => {
            this.isLoading = false; // Stop loading
          },
          error: () => {
            this.isLoading = false; // Stop loading on error
          }
        });
      })
    ).subscribe();
  }

  updateGroupPosition(groupCountryId: number, groupId: number, newPosition: number): Observable<any> {
    console.error(`groupCountryId: ${groupCountryId} - newPosition: ${newPosition}`)
    // Send a request to the backend to update the group position
    return this.groupService.updateAssignedCountry({
      id: groupCountryId,
      groupId: groupId,
      countryId: this.countryId,
      sortOrder: newPosition
    });
  }
}
