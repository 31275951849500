import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ReferralCashoutRequestHttpService } from '@shared/service/referral-cashout-requests/referral-cashout-requests-http.service';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { ReferralCashoutRequest } from '@shared/models';
import { PaginationHelper } from '@app/shared/helpers/pagination-helper';
import { PageInfo } from '@app/shared/models/global/response';

@Injectable({ providedIn: 'root' })
export class ReferralCashoutRequestService {
  readonly pagination$: Subject<PageInfo> = new Subject<PageInfo>();

  constructor(
    private readonly referralCashoutRequestHttpService: ReferralCashoutRequestHttpService,
  ) {}

  getReferralCashoutRequests(
    filters?: Record<string, string>,
  ): Observable<ReferralCashoutRequest[]> {
    const toData = (res: ApiResponse<ReferralCashoutRequest[]>) => res.data;

    const toPagination = (res: ApiResponse<ReferralCashoutRequest[]>) =>
      res.pageInfo &&
      this.pagination$.next(PaginationHelper.camelToSnakeCase(res.pageInfo));

    return this.referralCashoutRequestHttpService
      .getReferralCashoutRequests(filters)
      .pipe(tap(toPagination), map(toData));
  }

  getReferralCashoutRequest(id: number): Observable<ReferralCashoutRequest> {
    const toData = (res: ApiResponse<ReferralCashoutRequest>) => res.data;

    return this.referralCashoutRequestHttpService
      .getReferralCashoutRequest(id)
      .pipe(map(toData));
  }

  updateReferralCashoutRequest(
    id: number,
    data: Partial<ReferralCashoutRequest>,
  ): Observable<ReferralCashoutRequest> {
    const toReferralCashoutRequest = (
      res: ApiResponse<ReferralCashoutRequest>,
    ) => res.data;

    return this.referralCashoutRequestHttpService
      .updateReferralCashoutRequest(id, data)
      .pipe(map(toReferralCashoutRequest));
  }
}
