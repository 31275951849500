import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '@shared/service/global.service';

import { environment } from '@env/environment';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { CarwashCompany } from '@shared/models';
import { expand, reduce } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CarwashCompanyHttpService {
  private readonly url = `${environment.jcDeliveryServer}cw-garages`;

  constructor(
    private readonly http: HttpClient,
    private readonly globalService: GlobalService,
  ) {}

  getCompanies(
    filters?: Record<string, string>,
  ): Observable<ApiResponse<CarwashCompany[]>> {
    const countryId: string = this.globalService.countryId.toString();
    const params = {
      ...(filters || {}),
      countryId,
    };

    return this.http
      .get<ApiResponse<CarwashCompany[]>>(this.url, { params })
      .pipe(
        expand((res) =>
          !res.pageInfo.nextPage
            ? EMPTY
            : this.http.get<ApiResponse<CarwashCompany[]>>(
                this.url,
                this.updateRequestFormat(params, res.pageInfo.nextPage),
              ),
        ),
        reduce(
          (records, res) => ({
            data: records.data.concat(res.data),
            pageInfo: records.pageInfo,
          }),
          { data: [], pageInfo: {} },
        ),
      );
  }

  getCompaniesWithPagination(
    filters?: Record<string, string>,
  ): Observable<ApiResponse<CarwashCompany[]>> {
    const countryId: string = this.globalService.countryId.toString();
    const params = {
      ...(filters || {}),
      countryId,
    };

    return this.http.get<ApiResponse<CarwashCompany[]>>(this.url, { params });
  }

  createCompany(
    data: Partial<CarwashCompany>,
  ): Observable<ApiResponse<CarwashCompany>> {
    return this.http.post<ApiResponse<CarwashCompany>>(this.url, data);
  }

  updateCompany(
    id: number,
    data: Partial<CarwashCompany>,
  ): Observable<ApiResponse<CarwashCompany>> {
    return this.http.patch<ApiResponse<CarwashCompany>>(
      `${this.url}/${id}`,
      data,
    );
  }

  removeCompany(id: number): Observable<void> {
    return this.http.delete<void>(`${this.url}/${id}`);
  }

  private updateRequestFormat(params: any, page: number) {
    params.page = page;
    return {
      params,
    };
  }
}
