import { Component, OnInit } from '@angular/core';
import { MarketingService } from '../../services/marketing.service';
import { GlobalService } from 'src/app/shared/service/global.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { IdQuestion } from '../../models/segments';

@Component({
  selector: 'app-users-segements-main',
  templateUrl: './users-segements-main.component.html',
  styleUrls: ['./users-segements-main.component.css']
})
export class UsersSegementsMainComponent implements OnInit {
  raisonsSrc: IdQuestion[];
  constructor(
    private marketingService: MarketingService,
    private globalService: GlobalService,
    private toast: ToastService
  ) { }

  ngOnInit() {
    this.getQuestions();
  }

  private getQuestions() {
    this.marketingService
      .getQuestions(
        this.globalService.headers,
      )
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.raisonsSrc = response.data as IdQuestion[];
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }

}
