import { Routes } from '@angular/router';
import { LoginComponent } from './shared/components/login/login.component';
import { PanelComponent } from './shared/components/panel/panel.component';
import { CanActivateViaAuthGuard } from './shared/service/can-activate-via-auth-guard.service';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { IsSuperAdminGuard, IsLiveOrderManagementGuard } from './shared/guards';
export const appRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Login To Justclean Admin' },
  },
  {
    path: 'dashboard',
    component: PanelComponent,
    children: [
      {
        path: 'users',
        loadChildren: () =>
          import('./modules/users/users.module').then((m) => m.UsersModule),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'partners',
        loadChildren: () =>
          import('./modules/partners/partners.module').then(
            (m) => m.PartnersModule,
          ),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'items',
        loadChildren: () =>
          import('./modules/items/items.module').then((m) => m.ItemsModule),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'offers',
        loadChildren: () =>
          import('./modules/offers/offers.module').then((m) => m.OffersModule),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'jc-credit-offers',
        loadChildren: () =>
          import('./modules/jc-credit-packages/jc-credit-packages.module').then(
            (m) => m.JcCreditPackagesModule,
          ),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'cars',
        loadChildren: () =>
          import('./modules/cars/cars.module').then((m) => m.CarsModule),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'countries',
        loadChildren: () =>
          import('./modules/countries/countries.module').then(
            (m) => m.CountriesModule,
          ),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'crud',
        loadChildren: () =>
          import('./modules/crud/crud.module').then((m) => m.CrudsModule),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'groups',
        loadChildren: () =>
          import('./modules/groups/groups.module').then((m) => m.GroupsModule),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'ads',
        loadChildren: () =>
          import('./modules/ads/ads.module').then((m) => m.AdsModule),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'groups-orders-map',
        loadChildren: () =>
          import('./modules/groups-orders-map/groups-orders-map.module').then(
            (m) => m.GroupsOrdersMapModule,
          ),
        canActivate: [CanActivateViaAuthGuard, IsLiveOrderManagementGuard],
      },
      {
        path: 'variants',
        loadChildren: () =>
          import('./modules/variant/variant.module').then(
            (m) => m.VariantsModule,
          ),
        canActivate: [CanActivateViaAuthGuard, IsSuperAdminGuard],
      },
      {
        path: 'customers',
        loadChildren: () =>
          import('./modules/customer/customer.module').then(
            (m) => m.CustomerModule,
          ),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'laundries',
        loadChildren: () =>
          import('./modules/laundries/laundries.module').then(
            (m) => m.LaundriesModule,
          ),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'drivers',
        loadChildren: () =>
          import('./modules/drivers/drivers.module').then(
            (m) => m.DriversModule,
          ),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'laundries',
        loadChildren: () =>
          import('./modules/laundries/laundries.module').then(
            (m) => m.LaundriesModule,
          ),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'branches',
        loadChildren: () =>
          import('./modules/branch/branch.module').then((m) => m.BranchModule),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('./modules/order/order.module').then((m) => m.OrderModule),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'reviews',
        loadChildren: () =>
          import('./modules/review/review.module').then((m) => m.ReviewModule),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'logistics',
        loadChildren: () =>
          import('./modules/logistics/logistics.module').then(
            (m) => m.LogisticsModule,
          ),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'corporate',
        loadChildren: () =>
          import('./modules/corporate/corporate.module').then(
            (m) => m.CorporateModule,
          ),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'campaigns',
        loadChildren: () =>
          import(
            './modules/marketing-campaigns/marketing-campaigns.module'
          ).then((m) => m.MarketingCampaignsModule),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'suppliers',
        loadChildren: () =>
          import('./modules/supplier/supplier.module').then(
            (m) => m.SupplierModule,
          ),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'jc-delivery',
        loadChildren: () =>
          import('./modules/jc-deliver/jc-deliver.module').then(
            (m) => m.JcDeliverModule,
          ),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'finance',
        loadChildren: () =>
          import('./modules/finance/finance.module').then(
            (m) => m.FinanceModule,
          ),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'sales',
        loadChildren: () =>
          import('./modules/sales/sales.module').then((m) => m.SalesModule),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'marketing',
        loadChildren: () =>
          import('./modules/marketing/marketing.module').then(
            (m) => m.MarketingModule,
          ),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'carwash',
        loadChildren: () =>
          import('./modules/carwash/carwash.module').then(
            (m) => m.CarwashModule,
          ),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'homeclean',
        loadChildren: () =>
          import('./modules/homeclean/homeclean.module').then(
            (m) => m.HomecleanModule,
          ),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'cashback-campaigns',
        loadChildren: () =>
          import('./modules/cashback-campaign/cashback-campaign.module').then(
            (m) => m.CashbackCampaignsModule,
          ),
        canActivate: [CanActivateViaAuthGuard, IsSuperAdminGuard],
      },
      {
        path: 'referral-campaigns',
        loadChildren: () =>
          import('./modules/referral-campaign/referral-campaign.module').then(
            (m) => m.ReferralCampaignsModule,
          ),
        canActivate: [CanActivateViaAuthGuard, IsSuperAdminGuard],
      },
      {
        path: 'referral-cashout-requests',
        loadChildren: () =>
          import(
            './modules/referral-cashout-requests/referral-cashout-requests.module'
          ).then((m) => m.ReferralCashoutRequestsModule),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'user-segments',
        loadChildren: () =>
          import('./modules/user-segment/user-segment.module').then(
            (m) => m.UserSegmentsModule,
          ),
        canActivate: [CanActivateViaAuthGuard, IsSuperAdminGuard],
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./modules/notifications/notifications.module').then(
            (m) => m.NotificationsModule,
          ),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'not-found',
        component: NotFoundComponent,
      },
      {
        path: '**', //fall back routing
        redirectTo: '/dashboard/carwash/companies/list',
      },
    ],
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: '**', //fall back routing
    redirectTo: '/dashboard/carwash/companies/list',
  },
];
