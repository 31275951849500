import { Component, OnInit } from '@angular/core';
import { PageInfo } from 'src/app/shared/models/global/response';
import { ReviewsService } from 'src/app/shared/service/reviews.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/shared/service/global.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { Reviews } from 'src/app/shared/models/customer/reviews';

@Component({
  selector: 'app-review-list',
  templateUrl: './review-list.component.html',
  styleUrls: ['./review-list.component.css']
})
export class ReviewListComponent implements OnInit {
  statuses = [
    {
      value: 'approved',
      label: 'Approved'
    },
    {
      value: 'rejected',
      label: 'Rejected'
    },
    {
      value: 'pending',
      label: 'Pending'
    }
  ];
  selectedStatus: string;
  reviews: Reviews.ReviewsFromList[];
  selectedReview: Reviews.ReviewsFromList;
  customer: string;
  laundry: string;
  dateCreation: string;
  dateUpdated: string;
  pagination: PageInfo;
  showData = false;
  loaderApprove: boolean;
  loaderReject: boolean;
  openDialog: boolean;

  multipleReviewIds: number[] = [];
  isAllSelected = false;
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected globalService: GlobalService,
    protected reviewsService: ReviewsService,
    protected toast: ToastService
  ) {}

  ngOnInit() {
    this.loaderApprove = this.loaderReject = false;
    this.getReviews();
  }

  filter() {
    this.getReviews(0);
  }

  initFilters() {
    this.customer = this.dateCreation = this.dateUpdated = this.laundry = null;
    this.selectedStatus = undefined;
    this.getReviews(1);
  }

  getPage(page: number) {
    this.getReviews(page);
    this.multipleReviewIds = [];
  }

  openUpdateDialog(review) {
    this.selectedReview = review;
    this.openDialog = true;
  }

  closeDialog() {
    this.openDialog = false;
  }

  updateReview(event: { review: Reviews.ReviewsFromList; isApproved }) {
    this.loaderApprove = event.isApproved ? true : false;
    this.loaderReject = event.isApproved ? false : true;

    this.reviewsService
      .updateReview(this.globalService.headers, event.review)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.getPage(this.pagination.current_page);
            this.loaderApprove = this.loaderReject = false;
            this.closeDialog();
          }
          if (response.code > 200) {
            this.closeDialog();
            // this.toast.error(response.message);
          }
        },
        error => {
          this.loaderApprove = this.loaderReject = false;
        }
      );
  }

  getColor(review) {
    if (review.status === 'pending') {
      return 'yellow-orange white-color text-center';
    }
    if (review.status === 'rejected') {
      return 'red-material white-color text-center';
    }
    if (review.status === 'approved') {
      return 'green-blue-2 white-color text-center';
    }
  }

  getDate(date: string) {
    return this.globalService.getDateTimeFromString(date);
  }

  closeModal() {
    this.openDialog = false;
  }

  toggleReviewIdInList(id: number) {
    const found = this.multipleReviewIds.findIndex(e => e === id);
    if (found >= 0) {
      this.multipleReviewIds.splice(found, 1);
    } else {
      this.multipleReviewIds.push(id);
    }
  }

  toggleSelectAll() {
    if (this.isAllSelected) {
      this.multipleReviewIds = [];
    } else {
      this.multipleReviewIds = this.reviews.map(e => e.id);
    }
    this.isAllSelected = !this.isAllSelected;
  }

  idExistInList(id: number) {
    return !!this.multipleReviewIds.find(e => e === id);
  }

  updateBulkReviews(isApproved: boolean) {
    this.loaderApprove = isApproved ? true : false;
    this.loaderReject = isApproved ? false : true;

    this.reviewsService
      .updateBulkReview(this.globalService.headers, {
        id: this.multipleReviewIds,
        status: isApproved ? 'approved' : 'rejected'
      })
      .subscribe(
        response => {
          if (response.code === 200) {
            this.getPage(this.pagination.current_page);
            this.loaderApprove = this.loaderReject = false;
            this.toast.success(response.message);
          }
          if (response.code > 200) {
            // this.toast.error(response.message);
          }
          this.multipleReviewIds = [];
          this.isAllSelected = false;
        },
        error => {
          this.loaderApprove = this.loaderReject = false;
        }
      );
  }

  private getReviews(page?: number) {
    this.reviewsService
      .getReviews(this.globalService.headers, {
        customer: this.customer,
        laundry: this.laundry,
        created_at: this.dateCreation,
        updated_at: this.dateUpdated,
        status: this.selectedStatus,
        page: page
      })
      .subscribe(
        response => {
          if (response.code === 200) {
            this.reviews = response.data;
            this.reviews = this.reviews.filter(
              review => review.country_id === this.globalService.countryId
            );
            this.pagination = response.page_info;
          }
        },
        error => {
          this.showData = false;
        }
      );
  }
}
