import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeCleaningOrderRoutingModule } from '@app/modules/homeclean/modules/homeclean-order/home-cleaning-order-routing.module';
import { HomecleanOrderListComponent } from './components/homeclean-order-list/homeclean-order-list.component';
import { HomeCleaningOrderDetailsModule } from './homeclean-order-details/home-cleaning-order-details.module';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [HomecleanOrderListComponent],
  imports: [
    CommonModule,
    HomeCleaningOrderDetailsModule,
    HomeCleaningOrderRoutingModule,
    SharedModule,
  ],
})
export class HomeCleaningOrderModule {}
