import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { PaymentMethod } from '@shared/models';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PaymentHelper {
  readonly url = environment.jcDeliveryServer + 'payment-methods';
  constructor(private readonly http: HttpClient) { }

  getPaymentMethods(payload: any, isPos?: boolean): Observable<any> {
    const unNeededIDs = [1, 2, 4, 5, 6, 7, 8, 10, 13, 14, 15, 17, 22, 23];

    const { countryId } = payload;
    return this.http.get<ApiResponse<PaymentMethod[]>>(this.url, {
      params: {
        countryId,
        isHidden: false,
        [isPos ? 'isPosPaymentMethod' : 'isUserPaymentMethod']: true
      } as any,
    }).
      pipe(map(res => ({ ...res, data: isPos ? res.data : res.data.filter(item => !unNeededIDs.includes(item.id)) })));
  }
}
