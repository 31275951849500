import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { map, tap } from 'rxjs/operators';
import { CarwashTicket } from '@app/modules/carwash/modules/carwash-packages/shared/models/carwash-ticket-model';
import { PageInfo } from '@app/shared/models/global/response';
import { ApiResponse } from '@app/shared/models/global/apiResponse';
import { PaginationHelper } from '@app/shared/helpers/pagination-helper';
import { DateSlot, TimeSlotTime } from '@app/shared/models';
import { Status } from '@app/shared/enums/status.enum';
import { GlobalService } from '@app/shared/service/global.service';
import { CarwashOrder } from '../../../carwash-order/components/shared/models/carwash-order.model';

@Injectable({ providedIn: 'root' })
export class CarwashTicketService {
  private readonly url =
    environment.jcDeliveryServer + 'cw-garage-branch-packages';
    private readonly getOpenHoursURL = environment.jcDeliveryServer + 'cw-garage-branch-schedules/opening-hours';
    private readonly cwRescheduleUrl = `${environment.jcDeliveryServer}cw-garage-branch-schedules`;
  readonly pagination$: Subject<PageInfo> = new Subject<PageInfo>();

  constructor(private readonly http: HttpClient, private readonly globalService: GlobalService) {}
  get(params: any): Observable<CarwashTicket[]> {
    const toPagination = (res: ApiResponse<CarwashTicket[]>) =>
      this.pagination$.next(PaginationHelper.camelToSnakeCase(res.pageInfo));

    return this.http
      .get<ApiResponse<CarwashTicket[]>>(this.url, {
        params,
      })
      .pipe(
        tap(toPagination),
        map((response) => response.data),
      );
  }
  create(data: any): Observable<any> {
    return this.http.post(this.url, data);
  }
  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`);
  }
  update(id: number, data: Partial<CarwashTicket>): Observable<any> {
    return this.http.patch(`${this.url}/${id}`, data);
  }
  assignVariant(packageId: number, variantId: number): Observable<any> {
    return this.http.patch(`${this.url}/${packageId}`, {
      subVariantId: variantId,
    });
  }


  getCwOpeningHoures(cwBranchId, groupId): Observable<DateSlot[]> {
    const params = { cwGarageBranchId: cwBranchId, globalGroupId: groupId };

    const toData = (res: ApiResponse<DateSlot[]>) => {
      return res.data.map((item) => this.getMappedItem(item));
    };
    return this.http
      .get<ApiResponse<DateSlot[]>>(this.getOpenHoursURL, {
        params: params as any,
      })
      .pipe(map(toData));
  }
  private getMappedItem(item: DateSlot): DateSlot {
    return {
      ...item,
      timeSlotTimes: item.timeSlotTimes
        .filter((time) => time.status === Status.Active)
        .map((time) => this.getMappedTime(time)),
    };
  }

  private getMappedTime(item: TimeSlotTime): TimeSlotTime {
    return {
      ...item,
      start: this.globalService.utcToLocalDate(item.start, 'H:mm'),
      end: this.globalService.utcToLocalDate(item.end, 'H:mm'),
    } as TimeSlotTime;
  }

  reScheduleTicket(body: Record<string, any>) {
   const  id=body.id;
    return this.http.patch<ApiResponse<CarwashOrder>>(
      `${this.cwRescheduleUrl}/${id}`,
      body,
    );
  }
}

