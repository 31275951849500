import { environment } from '@env/environment';
import { takeUntil } from 'rxjs/operators';
import { GroupService } from '@shared/service/group';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  HostListener,
  Inject, ViewChild
} from "@angular/core";
import { GlobalService } from 'src/app/shared/service/global.service';
import { Subject, Subscription } from 'rxjs';
import {} from 'googlemaps';
import {
  DropdownOption,
  GroupOrderMap,
  ZoneOrderMap,
} from '@app/shared/models';
import {
  GET_ORDER_MAP_QUERY,
  GET_ZONE_ORDER_COUNT_QUERY,
} from '@app/graphql/queries';
import * as _ from 'lodash';
import {
  ORDER_MAP_SUBSCRIPTION,
  SCHEDULED_ORDER_MAP_SUBSCRIPTION,
} from '@app/graphql/subscriptions';
import { GraphQLService } from '@app/shared/service/graphql/graphql.service';
import { UpdateType } from '@app/shared/enums';
import { GraphQLClient } from 'graphql-request';
import { DOCUMENT } from '@angular/common';
import { ClusterStats, MarkerClusterer, Renderer } from "@googlemaps/markerclusterer";

const graphQLClient = new GraphQLClient(environment.graphqlURL, {
  method: 'GET',
  jsonSerializer: {
    parse: JSON.parse,
    stringify: JSON.stringify,
  },
});
@Component({
  selector: 'app-groups-orders-map',
  templateUrl: './groups-orders-map.component.html',
  styleUrls: ['./groups-orders-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupsOrdersMapComponent implements OnInit, OnDestroy {
  @ViewChild('gm') gm: any;
  @ViewChild('markerCluster') markerCluster: any;

  allOrders: GroupOrderMap[];
  markers: GroupOrderMap[];
  zoom = 5;
  initMarker: {
    lat: number;
    lng: number;
  };
  zones: DropdownOption<number>[] = [];
  selectedZone: DropdownOption<number>;
  changedZones: { [zoneName: string]: string } = {};
  groups: DropdownOption<number>[] = [];
  selectedGroup: DropdownOption<number>;
  changedGroups: { [groupId: number]: string } = {};
  selectedMarker: GroupOrderMap;
  lastMarkerId: number;

  public clusterRenderer: Renderer = {
    render: ({ count, position }, clusterStatus: ClusterStats) => {
      return new google.maps.Marker({
        position,
        icon: {
          url: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48Zz48Zz48cGF0aCBmaWxsPSIjNjQxYmI0IiBkPSJNMjU2LDQ4QzE0MS4xLDQ4LDQ4LDE0MS4xLDQ4LDI1NnM5My4xLDIwOCwyMDgsMjA4YzExNC45LDAsMjA4LTkzLjEsMjA4LTIwOFMzNzAuOSw0OCwyNTYsNDh6IE0yNTYsNDQ2LjcgICAgYy0xMDUuMSwwLTE5MC43LTg1LjUtMTkwLjctMTkwLjdjMC0xMDUuMSw4NS41LTE5MC43LDE5MC43LTE5MC43YzEwNS4xLDAsMTkwLjcsODUuNSwxOTAuNywxOTAuNyAgICBDNDQ2LjcsMzYxLjEsMzYxLjEsNDQ2LjcsMjU2LDQ0Ni43eiIvPjwvZz48L2c+PGc+PGc+PHBhdGggZmlsbD0iIzY0MWJiNCIgZD0iTTI1Niw5NmMtODguNCwwLTE2MCw3MS42LTE2MCwxNjBjMCw4OC40LDcxLjYsMTYwLDE2MCwxNjBjODguNCwwLDE2MC03MS42LDE2MC0xNjBDNDE2LDE2Ny42LDM0NC40LDk2LDI1Niw5NnoiLz48L2c+PC9nPjwvc3ZnPg==',
          scaledSize: new google.maps.Size(50, 50),
        },
        label: {
          text: String(count),
          color: 'rgba(255,255,255,1)',
          fontSize: '18px',
          fontWeight: 'bold',
        },
        // adjust zIndex to be above other markers
        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
      });
    },
  };

  public clusterAlgorithmOptions = {
    maxZoom: 21
  }

  getVertivalBounded: Function = this.getVertival.bind(this);
  getFormattedAmountBounded: Function = this.getFormattedAmount.bind(this);

  isMobileResolution: boolean;
  private window: Window;
  private readonly destroy$: Subject<void> = new Subject<void>() ;
  isScheduledToday: boolean;
  isLoading: boolean;
  subscriber$: Subscription;
  clusterBounds: any;
  clusterManager: MarkerClusterer;

  constructor(
    private readonly globalService: GlobalService,
    private readonly groupService: GroupService,
    private readonly gqlService: GraphQLService,
    private readonly cd: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    this.initializeWindowObject();
    this.setDefaultMarkers();
    this.refresh(false);
  }

  refresh(isScheduledToday: boolean): void {
    if (isScheduledToday !== this.isScheduledToday) {
      this.isLoading = true;
      this.markers = [];
      this.allOrders = null;
      this.isScheduledToday = isScheduledToday;
      this.loadZones();
      this.loadOrders();
      this.subscribeData();
    }
  }

  changeZone(selectedItem: DropdownOption<number>): void {
    this.selectedZone = selectedItem.value ? selectedItem : null;
    if (this.selectedZone) {
      const countryId = selectedItem.extraKeys.countryId;
      this.setCountryMarker(countryId, 7);
    } else {
      this.setDefaultMarkers();
    }
    this.filterMarkers();
  }

  changeGroup(selectedItem: DropdownOption<number>): void {
    this.selectedGroup = selectedItem.value ? selectedItem : null;
    if (this.selectedGroup) {
    } else {
      this.setDefaultMarkers();
    }
    this.filterMarkers();
  }

  getFilteredItem(items: DropdownOption<number>[]): DropdownOption<number>[] {
    return items.filter(({ value }) => value);
  }

  goToDetails(order: GroupOrderMap): void {
    window.open(this.getOrderDetailsUrl(order), '_blank');
  }

  infoWindowClose(): void {
    this.selectedMarker = null;
  }

  onMouseOver(infoWindow, gm) {
    gm.lastOpen?.close();
    gm.lastOpen = infoWindow;
    infoWindow.open();
  }
  onClusterClick(event) {
    // Get all markers within the cluster
    const markers = event.cluster.markers;

    // Calculate the bounds of those markers
    const bounds = new google.maps.LatLngBounds();
    for (let marker of markers) {
      bounds.extend(marker.getPosition());
    }

    setTimeout(() => {
      // Adjust the map's view to fit those bounds
      event.map.fitBounds(bounds);
      event.map.setZoom(event.map.getZoom() - 2); // Zoom out by two levels
    }, 300);  // Delay of 500ms. Adjust as needed.
  }

  clickMarker(event, marker: GroupOrderMap, gm: any, infoWindow: any) {

    console.error(event)

    const lastMarker = this.markers?.find((m) => m.id === this.lastMarkerId);
    if (lastMarker || !this.lastMarkerId) {
      gm?.lastOpen?.close?.();
    }
    if (marker !== this.selectedMarker) {
      gm.lastOpen = infoWindow;
      this.lastMarkerId = marker.id;

      setTimeout(() => {
        this.selectedMarker = marker;
      });
    } else {
      gm.lastOpen = null;
      setTimeout(() => infoWindow.close());
    }
    this.cd.detectChanges();
  }

  private getOrderDetailsUrl(order: GroupOrderMap): string {
    const group = this.groups.find((group) => group.value === order.groupId);
    let url;
    if (this.groupService.checkLaundryDesign(group.extraKeys)) {
      url = `/dashboard/orders/order-details/${order.id}`;
    } else if (this.groupService.checkCarwashGarageDesign(group.extraKeys)) {
      url = `/dashboard/carwash/orders/details/${order.id}?isMobileType=true`;
    } else if (this.groupService.checkHomeCleaningDesign(group.extraKeys)) {
      url = `/dashboard/homeclean/orders/details/${order.id}?isMobileType=true`;
    }
    return url;
  }

  private getFormattedAmount(countryId: number, amount: number): string {
    return this.globalService.formatAmount(countryId, amount);
  }

  private getVertival(groupId: number): DropdownOption<number> {
    return this.groups?.find(({ value }) => value === groupId);
  }

  private filterMarkers(): void {
    this.markers = this.allOrders.filter(
      (order) =>
        (!this.selectedGroup || order.groupId === this.selectedGroup.value) &&
        (!this.selectedZone || order.zoneName === this.selectedZone.key),
    );
    this.refreshZones();
    this.refreshGroups();
    this.cd.detectChanges();
  }

  private setDefaultMarkers(): void {
    /** Centralize the map for our countries (Saudi Arabia) */
    this.setCountryMarker(4, 5);
  }

  private setCountryMarker(countryId: number, zoom: number): void {
    const countryLocation = this.globalService.countries.find(
      (e) => e.countryId === countryId,
    );
    this.initMarker = {
      lat: countryLocation.latitude,
      lng: countryLocation.longitude,
    };
    this.zoom = zoom;

  }

  private subscribeData(): void {
    if (this.subscriber$) {
      this.subscriber$.unsubscribe();
    }

    const query = this.isScheduledToday
      ? SCHEDULED_ORDER_MAP_SUBSCRIPTION
      : ORDER_MAP_SUBSCRIPTION;
    this.subscriber$ = this.gqlService.subscription({ query }).subscribe({
      next: ({ data }) => {
        const newOrderSubscription: GroupOrderMap =
          data.newOrderSubscription || data.scheduledOrderSubscription;

        if (newOrderSubscription.updateType === UpdateType.created) {
          this.allOrders.push(newOrderSubscription);
          this.handleDataChanged(newOrderSubscription);
        } else if (newOrderSubscription.updateType === UpdateType.deleted) {
          const order = this.allOrders.find(
            ({ id }) => id === newOrderSubscription.id,
          );
          if (order) {
            this.allOrders.splice(this.allOrders.indexOf(order), 1);
            this.handleDataChanged(newOrderSubscription, true);
          }
        }
        this.cd.detectChanges();
      },
    });
  }

  private handleDataChanged(
    newOrderSubscription: GroupOrderMap,
    isDescreased?: boolean,
  ): void {
    const zoneName = newOrderSubscription.zoneName;
    const groupId = newOrderSubscription.groupId;

    const changedZone = this.zones.find(({ key }) => key === zoneName);
    const changedGroup = this.groups.find(({ value }) => value === groupId);

    if (!isDescreased) {
      changedZone && changedZone.extraKeys.count++;
      changedGroup && changedGroup.extraKeys.count++;
    } else {
      changedZone && changedZone.extraKeys.count--;
      changedGroup && changedGroup.extraKeys.count--;
    }

    const cssClassName = !isDescreased ? 'increased' : 'decreased';
    this.changedZones[zoneName] = cssClassName;
    this.changedGroups[groupId] = cssClassName;

    this.filterMarkers();

    setTimeout(() => {
      this.changedZones[zoneName] = '';
      this.changedGroups[groupId] = '';
      this.cd.detectChanges();
    }, 3000);
  }

  private refreshZones(): void {
    const zonesCountMap = this.getZonesCountMap();
    this.zones.forEach((zone) => {
      if (zone.value) {
        zone.extraKeys.count = zonesCountMap[zone.key] || 0;
      }
    });
  }

  private refreshGroups(): void {
    const groupsCountMap = this.getGroupsCountMap();
    this.groups.forEach((group) => {
      if (group.value) {
        group.extraKeys.count = groupsCountMap[group.value] || 0;
      }
    });
  }

  private loadOrders(): void {
    const dates = this.getDates();
    const query = GET_ORDER_MAP_QUERY(
      dates.startDate,
      dates.endDate,
      this.isScheduledToday,
    );
    graphQLClient
      .request(query, null, { 'content-type': 'application/json' })
      .then((res: {getOrderMapData: GroupOrderMap[]}) => {
        this.allOrders = this.getMappedOrders(res.getOrderMapData);
        this.markers = this.globalService.getNewInstance(this.allOrders);
        this.loadGroups();
      });
  }

  private loadZones(): void {
    const dates = this.getDates();
    const query = GET_ZONE_ORDER_COUNT_QUERY(
      dates.startDate,
      dates.endDate,
      this.isScheduledToday,
    );
    graphQLClient
      .request(query, null, { 'content-type': 'application/json' })
      .then((res: {countryOrderCount: ZoneOrderMap[]}) => {
        const data: ZoneOrderMap[] = res.countryOrderCount;
        this.zones = [
          {
            value: 0,
            key: 'GROUPS_MAP.ZONES.ALL_OPTION',
            iconUrl: '/assets/img/icons/flags/0.svg',
          },
          ...data.map((item) => ({
            value: item.countryId,
            key: item.zoneName,
            iconUrl: `/assets/img/icons/flags/${item.countryId}.svg`,
            extraKeys: {
              count: item.totalCount || 0,
              countryId: item.countryId,
            },
          })),
        ];

        if (this.selectedZone) {
          this.selectedZone = this.zones.find(
            ({ value }) => value === this.selectedZone.value,
          );
        }
        this.isLoading = false;
        this.cd.detectChanges();
      });
  }

  private loadGroups(): void {
    this.groupService
      .getGroups()
      .pipe(takeUntil(this.destroy$))
      .subscribe((groups) => {
        const groupCountMap = this.getGroupsCountMap();
        this.groups = [
          {
            value: 0,
            key: 'GROUPS_MAP.VERTICALS.ALL_OPTION',
            iconUrl: '/assets/img/icons/vertical.svg',
            extraKeys: {
              count:  0,
              designType: null,
            },
          },
          ...groups.map((group) => {
            return {
              value: group.id,
              key: group.name.en,
              iconUrl: group.image.en,
              extraKeys: {
                count: groupCountMap[group.id] || 0,
                designType: group.designType,
              },
            };
          }),
        ];

        if (this.selectedGroup) {
          this.selectedGroup = this.groups.find(
            ({ value }) => value === this.selectedGroup.value,
          );
        }

        this.cd.detectChanges();
      });
  }

  private getMappedOrders(orders: GroupOrderMap[]): GroupOrderMap[] {
    return orders.map((order) => ({
      ...order,
      visible: true,
      branchName: this.getBranchName(order.branchName),
      firstName: order.firstName || '',
      lastName: order.lastName || '',
    }));
  }

  private getGroupsCountMap(): {
    [groupId: number]: number;
  } {
    const result = {};
    Object.entries(_.groupBy(this.markers, 'groupId')).forEach(
      ([groupId, list]: any) => {
        result[groupId] = list.length;
      },
    );
    return result;
  }

  private getZonesCountMap(): {
    [zoneName: string]: number;
  } {
    const result = {};
    Object.entries(_.groupBy(this.markers, 'zoneName')).forEach(
      ([zoneName, list]: any) => {
        result[zoneName] = list.length;
      },
    );
    return result;
  }

  private getBranchName(branchName: string): string {
    return branchName?.includes('{"en":')
      ? JSON.parse(branchName).en
      : branchName;
  }

  private getDates(): { startDate: string; endDate: string } {
    const todayUtc = this.globalService.dateNowUTC();
    const startDate = this.globalService.getNormalStartOfDay(todayUtc);
    const endDate = this.globalService.getNormalEndOfDay(todayUtc);
    return { startDate, endDate };
  }

  private initializeWindowObject() {
    this.window = this.document.defaultView;
    this.checkMobileResolution();
  }

  @HostListener('window:resize', ['$event'])
  checkMobileResolution(): void {
    this.isMobileResolution = this.window.innerWidth < 1200;
  }

  private unsubscribe(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
