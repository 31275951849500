export const environment = {
  production: true,
  serverUrl: 'https://admin-api.justclean.online/v3/',
  posServerUrl: 'https://dev-admin.justclean.online/v3/',
  jcDeliveryServer: 'https://api.justclean.online/v4/',
  apiKey:
    'uaSg9g8ajB9axrSxW0aeyQ==:A2TmB9d41kb+sD4fV3adSAqJOSspoSmq29uXYTGkzGv4gIk4zZX3uCsDNeWoobYYtCY3ZNUr1z+H6ABquqYedgK50uoloKTpQoLu38WOVnGELjDl5Tw/GLWWrpeYwolCFryiasF7FWQbjdFZwoaVz0/H1Sghs4Oy0vOqEyTHLIWrM2FhcVEskQTmj3RiuLOjPbr0fUX6jVWdMAIVZtnrhAdyeXGyuotkYr1w45z83+cEdL1gU6Wfjc81IFQ2G+wMKQD/p64O8dOPukgAcG/ek2joWQ1rWGNqkkLpEpjNFEuvhTNPksK/IHAbVLCRU1bnvYwgb9klHJM4njxYSzGLnMqd1fhPJmvINLOmVnet7+YVAh2xpyy6JscZlQGeaWOTTaxTNT9We0ZybRSgmxMcSI1Ub4YQqXoNtGiSVag0XfOnVFzGz8sibirun8hS5qxaVeDe5sw3FDYe/YEx9HV+16rpQzi2zaLu/BAUl+ZjBO+pgiueVZUEyV7U9TCdp9xI5BZg0HGx79QGCha2Xo3PCapGZwySahZ47Nyqrlg8AdR18tlkjCSNUpijqhZNbJ9uxVmn3H5Uw5Ctt7HFjfDPAA==',
  firebase: {
    apiKey: 'AIzaSyCcwxDMhV8IRDDSufjo6PK6DXXmywupUmo',
    authDomain: 'justclean-dev-e2e6c.firebaseapp.com',
    databaseURL: 'https://justclean-dev-e2e6c.firebaseio.com',
    projectId: 'justclean-dev-e2e6c',
    storageBucket: 'justclean-dev-e2e6c.appspot.com',
    messagingSenderId: '254049862790',
    appId: '1:254049862790:web:bb42ecf696cd8d7be4fd07',
  },
  graphqlURL: 'https://api.justclean.online/v4/graphql',
  graphqlWsUrl: 'wss://api.justclean.online/v4/graphql',
  companyName: 'فرع شركة جست كلين للتجارة',
  trn: '310451452400003',
  superAdminEmail: 'superadmin',
  liveOrdersManagementEmails: [],
  marketingTeamEmails: [],
  priorityBookingViewingManagementEmails: [],
  name: 'staging',
};
