import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homeclean-start-time',
  templateUrl: './homeclean-start-time.component.html',
  styleUrls: ['./homeclean-start-time.component.scss'],
})
export class HomecleanStartTimeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
