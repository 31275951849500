import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DialogRef } from '@app/shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@app/shared/components/dialog/services/dialog.token';
import { envVariables } from '@app/shared/enums';
import { Crud } from '@app/shared/models';
import { CrudService } from '@app/shared/service/crud';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-manage-permissions',
  templateUrl: './manage-permissions.component.html',
  styleUrls: ['./manage-permissions.component.scss'],
})
export class ManagePermissionsComponent implements OnInit {
  form: UntypedFormGroup;
  isSaving: boolean;
  private readonly destroy$: Subject<void> = new Subject<void>() ;
  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: { firstPage: Crud } | any,
    private readonly fb: UntypedFormBuilder,
    private readonly dialogRef: DialogRef<ManagePermissionsComponent>,
    private readonly crudService: CrudService,
  ) {}

  ngOnInit(): void {
    this.initForms();
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.data.firstPage.permissionsConfig = {};
    Object.keys(envVariables).forEach((key) =>
      this.setEmails(key as envVariables),
    );

    this.isSaving = true;
    this.crudService
      .updateCrud(this.data.firstPage.id, this.data.firstPage)
      .pipe(
        tap(() => {
          this.isSaving = false;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (crud: Crud) => {
          this.dialogRef.close(crud);
        },
        (error) => {
          this.isSaving = false;
        },
      );
  }

  private setEmails(variableName: envVariables): void {
    this.data.firstPage.permissionsConfig[variableName] =
      this.form.get(variableName).value;
  }

  private initForms(): void {
    const config = this.data.firstPage?.permissionsConfig;
    this.form = this.fb.group({
      liveOrdersManagementEmails: [config?.liveOrdersManagementEmails || ''],
      marketingTeamEmails: [config?.marketingTeamEmails || ''],
      priorityBookingViewingManagementEmails: [
        config?.priorityBookingViewingManagementEmails || '',
      ],
    });
  }
}
