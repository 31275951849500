import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { GlobalService } from 'src/app/shared/service/global.service';
import { DriverList } from '../../../../shared/models/cars/driver';
import { PageInfo } from '../../../../shared/models/global/response';
import { LogDriverService } from 'src/app/shared/service/log-driver.service';

@Component({
  selector: 'app-log-drivers-list',
  templateUrl: './log-drivers-list.component.html',
  styleUrls: ['./log-drivers-list.component.css']
})
export class LogDriversListComponent implements OnInit {
  drivers: DriverList[];
  pagination: PageInfo;
  branchId: number;
  loader = false;
  constructor(
    private globalService: GlobalService,
    private driversService: LogDriverService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.branchId = this.route.snapshot.params.branchId;
    this.getDrivers(this.branchId, { page: 1 });
  }

  getDrivers(branchId: number, params?: any) {
    this.drivers = [];
    this.driversService
      .getDriversPerLogBranch(this.globalService.headers, branchId, params)
      .subscribe(response => {
        if (response.code === 200) {
          this.drivers = response.data;
          this.pagination = response.page_info;
        }
      });
  }

  getPage(event) {
    this.getDrivers(this.branchId, { page: event });
  }

  refreshData(event) {
    this.getPage(this.pagination.current_page);
  }
}
