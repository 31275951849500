import { BranchesService } from '@shared/service/branches.service';
import { map, takeUntil } from 'rxjs/operators';
import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { DIALOG_DATA } from '@app/shared/components/dialog/services/dialog.token';
import { Observable, Subject } from 'rxjs';
import { DialogRef } from '@app/shared/components/dialog/dialog-ref/dialog-ref';
import { BrArea } from '@app/shared/models/laundries/branch';
import { CarwashBranchAreaRequest } from '@app/shared/models';
import { GlobalService } from '@app/shared/service/global.service';
import { CountryService } from '@app/shared/service/country.service';

@Component({
  selector: 'app-area-coverage-dialog',
  templateUrl: './area-coverage-dialog.component.html',
  styleUrls: ['./area-coverage-dialog.component.scss'],
})
export class LaundryBranchAreaCoverageDialogComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>() ;

  areas$: Observable<Array<BrArea>>;
  areas: Array<BrArea>;
  selectedAreas: Array<{ id: number; uniqueId?: number }> = [];
  selectedAreasToUpdate: Array<number> = [];
  isLoading: boolean;
  isSelectedFn: Function;
  governorates: any[];
  governorate: any;
  private requestBody: CarwashBranchAreaRequest;

  constructor(
    @Inject(DIALOG_DATA) public readonly branchId: number | any,
    private branchService: BranchesService,
    private globalService: GlobalService,
    private countryService: CountryService,
    private cd: ChangeDetectorRef,
    private readonly dialogRef: DialogRef<LaundryBranchAreaCoverageDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.loadGovernorates();
    this.loadAreasOfBranch();
    this.isSelectedFn = this.isSelected.bind(this);
    this.requestBody = {
      laundryBranchId: this.branchId,
      minimumOrderAmount: 0,
      deliveryCharge: 0,
      serviceCharge: 0,
      sortOrder: 0,
    };

    if (this.getCountryId() === '2') {
      this.requestBody.serviceCharge = 5
      this.requestBody.serviceChargeThreshold = 0
      this.requestBody.serviceChargeAfterThreshold = 5
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  toggleArea(area: BrArea): void {
    this.selectedAreasToUpdate.push(area.id);
    const selectedArea = this.selectedAreas.find((el) => el.id === area.id);
    if (selectedArea) {
      this.branchService
        .unAssignAreasFromBranch(selectedArea.uniqueId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.selectedAreas = this.selectedAreas.filter(
            (el) => el.id !== area.id,
          );
          this.refreshSelectedAreasToUpdate(area.id);
        });
    } else {
      this.requestBody.areaId = area.id;
      this.branchService
        .assignAreasToBranch(this.requestBody)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res['code'] === 200) {
            this.selectedAreas.push({ id: area.id, uniqueId: res['data']?.id });
            this.refreshSelectedAreasToUpdate(area.id);
          }
        });
    }
  }

  changeGov(govId: number): void {
    this.loadAreas(govId);
  }

  loadGovernorates() {
    this.countryService
      .getAllListCities(this.globalService.headers)
      .subscribe((data) => {
        this.governorates = [];
        data?.forEach((governorate) => {
          this.governorates.push({
            value: governorate.id,
            label: governorate.name.en,
          });
        });
      });
  }

  private refreshSelectedAreasToUpdate(areaId: number): void {
    this.selectedAreasToUpdate = this.selectedAreasToUpdate.filter(
      (id) => id !== areaId,
    );
    this.cd.detectChanges();
  }

  private loadAreas(governorateId: number): void {
    this.areas = [];
    this.areas$ = this.branchService
      .getBranchAllAreas({
        countryId: this.getCountryId(),
        cityId: governorateId,
      })
      .pipe(
        map((data) => {
          this.areas = data;
          return data;
        }),
      );
  }

  private getCountryId(): string {
    return localStorage.getItem('country');
  }

  private loadAreasOfBranch() {
    this.branchService
      .getBranchSelectedAreas({
        laundryBranchId: this.branchId,
        countryId: this.getCountryId(),
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.selectedAreas = data?.map((item) => ({
          id: item?.areaId,
          uniqueId: item?.id,
        }));
      });
  }

  private isSelected(areaId: number): boolean {
    return !!this.selectedAreas.find((area) => area.id === areaId);
  }

  private unsubscribe(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
