import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { finalize } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { IOption } from 'ng-uikit-pro-standard/lib/pro/material-select/option-interface';

import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { TatService } from '@shared/service/tat.service';
import { TatItem } from '@app/modules/items/shared/models/tat-item.model';
import { TatServices } from '@app/modules/items/shared/enums/tat-services.enum';

@Component({
  selector: 'app-items-tat-dialog',
  templateUrl: './items-tat-dialog.component.html',
  styleUrls: ['./items-tat-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemsTatDialogComponent implements OnInit {
  isLoading = false;
  serviceOptions: Pick<IOption, 'value' | 'label'>[] = [];
  normalServiceTime: string;
  fastServiceTime: string;
  normalServiceId: number;
  fastServiceId: number;

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: { id: number; isNoneOptions?: boolean } | any,
    private readonly dialogRef: DialogRef<ItemsTatDialogComponent>,
    private readonly tatService: TatService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initOptions();
    this.initTatItems();
  }

  close(): void {
    this.dialogRef.close();
  }

  initTatItems(): void {
    this.isLoading = true;
    this.tatService
      .getTatItems(this.data.id)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (tatItems: TatItem[]) => {
          tatItems.forEach((tatItem: TatItem) => {
            switch (tatItem.tatServiceId) {
              case TatServices.fastService: {
                this.fastServiceTime = String(tatItem.deliveryMin);
                this.fastServiceId = tatItem.id;
                break;
              }
              case TatServices.normalService: {
                this.normalServiceId = tatItem.id;
                this.normalServiceTime = String(tatItem.deliveryMin);
                break;
              }
            }
          });
          this.cdr.markForCheck();
        },
      );
  }

  updateTat(): void {
    this.isLoading = true;
    forkJoin([
      this.tatServiceUpdate(this.normalServiceId, this.normalServiceTime),
      this.tatServiceUpdate(this.fastServiceId, this.fastServiceTime),
    ]).subscribe(
      () => {
        this.close();
      },
    );
  }

  tatServiceUpdate(id: number, deliveryMin: string): Observable<TatItem> {
    return this.tatService
      .updateTat(id, deliveryMin)
      .pipe(finalize(() => (this.isLoading = false)));
  }

  private initOptions(): void {
    const noneOptions = this.data.isNoneOptions
      ? [{ value: '0', label: 'None' }]
      : [];

    this.serviceOptions = [
      ...noneOptions,
      { value: '1440', label: '24hrs' },
      { value: '2880', label: '48hrs' },
      { value: '4320', label: '3 days' },
      { value: '7200', label: '5 days' },
      { value: '8640', label: '6 days' },
    ];
  }
}
