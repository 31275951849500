import { AdFormComponent } from './ad-form/ad-form.component';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { FormStatus } from '@shared/enums/form-status.enum';
import { Ad, ImageQuality, ImageQualityOption } from '@shared/models';
import { ImageUploaderHttpService } from '@shared/service/image-uploader-http.service';
import { AdService } from '@shared/service/ads';
import { Translation } from 'libs/justclean-models/src/lib/justclean-models';
import { ImageChecker } from '@app/shared/helpers/image-checker';
import { GlobalConfig, ToastService } from 'ng-uikit-pro-standard';
import { TranslateService } from '@ngx-translate/core';
import { PetPreference } from '@app/shared/enums';
import { GlobalService } from '@app/shared/service/global.service';
import { Country } from '@app/shared/models/user/user';

@Component({
  templateUrl: './ad-edit-dialog.component.html',
  styleUrls: ['./ad-edit-dialog.component.scss'],
})
export class AdEditDialogComponent implements OnInit, OnDestroy {
  isEditMode: boolean;
  isDisableButton: boolean;
  formData: Partial<Ad>;
  adImageAr: string;
  adImageEn: string;
  isLoading: boolean;

  branchId: number;
  branchName: Translation;
  groupName: Translation;
  hcServiceId: number;

  @ViewChild('adForm') adForm: AdFormComponent;

  private readonly destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: {
      ad: Ad;
      isMarketing?: boolean;
      countries?: Array<Country>;
      selecteCountryId?: number;
    },
    private readonly dialogRef: DialogRef<AdEditDialogComponent>,
    private readonly adService: AdService,
    private readonly imageUploaderHttpService: ImageUploaderHttpService,
    private readonly toast: ToastService,
    private readonly translate: TranslateService,
    private readonly globalService: GlobalService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initVariables();
    this.isEditMode = !!this.data?.ad;
    if (this.isEditMode) {
      this.adImageEn = this.data.ad.image?.en;
      this.adImageAr = this.data.ad.image?.ar;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  formChanged(ad: Partial<Ad>): void {
    this.formData = {
      ...ad,
    };
    this.hcServiceId = this.formData.hcServiceId;
  }

  formStatusChanged(status: FormStatus): void {
    this.isDisableButton = status === FormStatus.Invalid;
  }

  createAd(): void {
    const data: Partial<Ad> = {
      ...this.formData,

      type: 'deals',
      countryId: this.data.selecteCountryId || this.countryId,
      genderId: this.formData.genderId || null,
      maxViews: +this.formData.maxViews,
      image: {
        en: this.adImageEn,
        ar: this.adImageAr,
      },
      ...(this.hcServiceId
        ? {
            link: {
              en: this.fullLink,
              ar: this.fullLink,
            },
          }
        : {}),
    };

    this.adForm.fillAreas(data);

    if (!data.petPreference) {
      data.petPreference = PetPreference.notSet;
    }

    if (!data.hcServiceId) {
      delete data.hcServiceId;
    }

    const action$ = this.isEditMode
      ? this.adService.updateAd(this.data.ad.id, data)
      : this.adService.createAd(data);

    this.isLoading = true;
    action$
      .pipe(
        tap(() => {
          this.isLoading = false;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (ad: Ad) => {
          this.dialogRef.close(ad);
        },
        (error) => {
          this.isLoading = false;
          this.cdr.markForCheck();
        },
      );
  }

  upload(file: File, isAr?: boolean): void {
    const options: ImageQualityOption = this.getImageQualityOptions(file);
    ImageChecker.checkImageQuality(options).then(
      ({ isAccepted, isRatioIssue, isSizeIssue }: ImageQuality) => {
        if (isAccepted) {
          this.uploadFile(file, isAr);
        } else {
          this.showErrorToastr(isRatioIssue, isSizeIssue);
        }
      },
    );
  }

  private get fullLink(): string {
    return `${this.formData.link.en}&companyBranchServiceId=${this.hcServiceId}`;
  }

  private uploadFile(file: File, isAr?: boolean): void {
    this.uploadImage(file)
      .pipe(takeUntil(this.destroy$))
      .subscribe((link: string) => {
        if (isAr) {
          this.adImageAr = link;
        } else {
          this.adImageEn = link;
        }
      });
  }

  private getImageQualityOptions(file: File): ImageQualityOption {
    return {
      imageFile: file,
      minSize: 500,
      maxSize: 1000,
    };
  }

  private showErrorToastr(isRatioIssue: boolean, isSizeIssue: boolean): void {
    const title = this.translate.instant('ADS.ERROR_TITLE');
    const key = `${(isRatioIssue && 'RATIO_') || ''}${
      (isSizeIssue && 'SIZE_') || ''
    }`;
    const message = this.translate.instant(`ADS.${key}ERROR_MESSAGE`);
    this.toast.error(message, title, this.getCommonSetting());
  }

  private getCommonSetting(): GlobalConfig {
    const options: GlobalConfig = this.toast.toastConfig;
    options.preventDuplicates = true;
    options.closeButton = true;
    return options;
  }

  private initVariables() {
    this.globalService.stopShowingErrors = false;
    this.branchId = this.adService.branchId;
    this.branchName = this.adService.branchName;
    this.groupName = this.adService.group?.name;
  }

  private uploadImage(file): Observable<string> {
    this.isLoading = true;

    return this.imageUploaderHttpService.imageUpload(file).pipe(
      tap(() => {
        this.cdr.markForCheck();
        this.isLoading = false;
      }),
    );
  }

  private get countryId(): number {
    return +localStorage.getItem('country');
  }

  ngOnDestroy(): void {
    this.globalService.stopShowingErrors = true;
    this.destroy$.next();
    this.destroy$.complete();
  }
}
