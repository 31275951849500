import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ItemsSpecialIconFromList,
  SpecialIconServicesFromList,
  ItemPricesToBeSaved,
  SpecialIconsListPrices,
  FinalItemObjectToBeSaved,
  SpecialIconsTCToBeSaved,
  SpecialIconsTCFromList
} from 'src/app/shared/models/laundries/special-icons';
import { IdName } from 'src/app/shared/models/cars/cars';
import { SelectOptions } from 'src/app/shared/models/global/response';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/shared/service/global.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { SpecialIconService } from 'src/app/shared/service/special-icon.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-special-icons',
  templateUrl: './special-icons.component.html',
  styleUrls: ['./special-icons.component.css']
})
export class SpecialIconsComponent implements OnInit {
  items: ItemsSpecialIconFromList[];
  services: SpecialIconServicesFromList[];
  tatService: IdName[];
  itemsToBeShownPrices: SelectOptions<number>[];
  servicesToBeShown: SelectOptions<number>[];
  tatToBeShown: SelectOptions<number>[];
  itemPricesToBeSaved: ItemPricesToBeSaved[] = [];
  currentItemToBeSaved: ItemPricesToBeSaved = new ItemPricesToBeSaved();
  itemPricesFromList: SpecialIconsListPrices;
  // Tc attributes
  itemsToBeShownTC: SelectOptions<number>[];
  tcToBeSaved: SpecialIconsTCToBeSaved = new SpecialIconsTCToBeSaved();
  tcFromList: SpecialIconsTCFromList[];
  // global
  branchId: number;
  laundryId: number;
  currency: string;
  decimal: number;
  loader = false;
  @ViewChild('form', { static: true }) myForm: NgForm;
  @ViewChild('tcFormEn', { static: true }) tcFormEn: NgForm;
  @ViewChild('tcFormAr', { static: true }) tcFormAr: NgForm;
  loaderTc = false;
  isSameCombination = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private specialIconService: SpecialIconService,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.branchId = this.route.snapshot.params.branchId;
    this.laundryId = this.route.parent.snapshot.params.id;
    this.getAllItems();
    this.getTatServices();
    this.getItemsWithPrices();
    this.currency = this.globalService.getCurrency();
    this.decimal = parseInt(this.globalService.fetchFromStorage('decimal'), 10);
    this.tcToBeSaved.translations = [
      {
        language_id: 1,
        content: ''
      },
      {
        language_id: 2,
        content: ''
      }
    ];
    this.getTc();
  }

  changeItem(event) {
    if (event) {
    this.getServices(event);
    }
    this.currentItemToBeSaved = new ItemPricesToBeSaved();

  }

  showItemName(itemid: number) {
    return this.items.find(e => e.id === itemid).name;
  }

  showServiceName(serviceId: number) {
    return this.services.find(e => e.service.id === serviceId).service.name;
  }

  showTatName(tatId: number) {
    return this.tatService.find(e => e.id === tatId).name;
  }

  showPrice(item: ItemPricesToBeSaved) {
    return parseFloat(item.price.toString()).toFixed(this.decimal);
  }

  enableAddItem() {
    this.isSameCombination = this.checkSameItemTOBeSaved() ? true : false;
    return (
      this.currentItemToBeSaved.item_id &&
      this.currentItemToBeSaved.price != null &&
      this.currentItemToBeSaved.price >= 0 &&
      this.currentItemToBeSaved.service_id &&
      this.currentItemToBeSaved.tat_service_id &&
      !this.isSameCombination
    );
  }

  enableAddTC() {
    return (
      this.tcToBeSaved.item_id &&
      this.tcToBeSaved.service_id &&
      this.tcFormAr &&
      this.tcFormAr.valid &&
      this.tcFormEn &&
      this.tcFormEn.valid
    );
  }

  addItem() {
    this.itemPricesToBeSaved.push(this.currentItemToBeSaved);
    this.currentItemToBeSaved = new ItemPricesToBeSaved();
    this.myForm.reset();
  }

  removeItem(itemId: number) {
    let index = -1;
    this.itemPricesToBeSaved.find((e, i) => {
      if (e.item_id === itemId) {
        index = i;
        return true;
      } else {
        return false;
      }
    });
    this.itemPricesToBeSaved.splice(index, 1);
  }

  saveItem() {
    this.loader = true;
    const finalItemsObject = new FinalItemObjectToBeSaved();
    finalItemsObject.laundry_branch_id = parseInt(this.branchId.toString(), 10);
    finalItemsObject.items = this.itemPricesToBeSaved;
    this.specialIconService
      .savePrices(this.globalService.headers, finalItemsObject)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.getItemsWithPrices();
            this.itemPricesToBeSaved = [];
            this.loader = false;
          } else {
            this.toast.error(response.message);
            this.loader = false;
          }
        },
        error => {
          this.loader = false;
        }
      );
  }

  saveTc() {
    this.loaderTc = true;
    this.tcToBeSaved.laundry_branch_id = parseInt(this.branchId.toString(), 10);
    this.specialIconService
      .saveTC(this.globalService.headers, this.tcToBeSaved)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.getTc();
            this.initTcForm();
            this.loaderTc = false;
          } else {
            this.toast.error(response.message);
            this.loaderTc = false;
          }
        },
        error => {
          this.loaderTc = false;
        }
      );
  }
  goBack() {
    this.router.navigate([
      '/dashboard/' +
        window.localStorage.getItem('module') +
        '/branch-list/' +
        this.laundryId
    ]);
  }

  initTcForm() {
    this.tcToBeSaved = new SpecialIconsTCToBeSaved();
    this.tcToBeSaved.translations = [
      {
        content: null,
        language_id: 1,
      },
      {
        content: null,
        language_id: 2,
      }
    ];
    this.tcFormAr.reset();
    this.tcFormEn.reset();
    this.tcToBeSaved.translations = [
      {
        language_id: 1,
        content: ''
      },
      {
        language_id: 2,
        content: ''
      }
    ];
  }

  private getItemsWithPrices() {
    this.specialIconService
      .getItemPrices(this.globalService.headers, this.branchId, {
        special: true
      })
      .subscribe(
        response => {
          if (response.code === 200) {
            this.itemPricesFromList = response.data;
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }

  private getAllItems() {
    this.specialIconService
      .getAllItems(this.globalService.headers, {
        special: true,
        laundry_branch_id: this.branchId
      })
      .subscribe(
        response => {
          if (response.code === 200) {
            this.items = response.data;
            this.itemsToBeShownPrices = [];
            this.itemsToBeShownTC = [];
            this.items.forEach(e => {
              this.itemsToBeShownPrices.push({
                value: e.id,
                label: e.name
              });
              this.itemsToBeShownTC.push({
                value: e.id,
                label: e.name
              });
            });
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }

  private getServices(itemId: number) {
    this.specialIconService
      .getServicesForItem(this.globalService.headers, itemId)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.services = response.data;
            this.servicesToBeShown = [];
            this.services.forEach(e => {
              this.servicesToBeShown.push({
                value: e.service.id,
                label: e.service.name
              });
            });
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }
  private getTatServices() {
    this.specialIconService
      .getTATServices(this.globalService.headers)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.tatService = response.data;
            this.tatToBeShown = [];
            this.tatService.forEach(e => {
              this.tatToBeShown.push({
                value: e.id,
                label: e.name
              });
            });
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }
  private getTc() {
    this.specialIconService
      .getTC(this.globalService.headers, this.branchId)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.tcFromList = response.data;
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }

  private checkSameItemTOBeSaved() {
    return this.itemPricesToBeSaved.find(
      e =>
        e.item_id === this.currentItemToBeSaved.item_id &&
        e.service_id === this.currentItemToBeSaved.service_id &&
        e.tat_service_id === this.currentItemToBeSaved.tat_service_id
    );
  }
}
