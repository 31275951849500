import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  LogisticsDriverToSave,
  DaysCapacity,
  CapacityToBeSaved
} from 'src/app/shared/models/cars/log-driver';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { NgForm } from '@angular/forms';
import { GlobalService } from 'src/app/shared/service/global.service';
import { LogDriverService } from 'src/app/shared/service/log-driver.service';

@Component({
  selector: 'app-driver-capacity',
  templateUrl: './driver-capacity.component.html',
  styleUrls: ['./driver-capacity.component.scss']
})
export class DriverCapacityComponent implements OnInit, OnChanges {
  @Input() isShown: boolean;
  @Input() driver: LogisticsDriverToSave;
  @Output() modalClosed = new EventEmitter();
  @ViewChild('driverCapacityModal', { static: true })
  driverCapacityModal: ModalDirective;
  @ViewChild('capacityForm', { static: false }) capacityForm: NgForm;
  days: DaysCapacity[];
  dataReady = false;
  loader = false;
  constructor(
    private globalService: GlobalService,
    private driverService: LogDriverService,
    private toaster: ToastService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if (this.isShown) {
      this.getDays();
    } else {
      this.driverCapacityModal.hide();
    }
  }

  getDays() {
    this.driverService
      .getDaysForCapacity(this.globalService.headers, this.driver.id)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.days = response.data;
            this.dataReady = true;
            this.driverCapacityModal.show();
          } else {
            this.toaster.error(response.message);
          }
        }
      );
  }

  save() {
    this.loader = true;
    const tasks = new CapacityToBeSaved(this.days);
    this.driverService
      .saveCapacity(this.globalService.headers, this.driver.id, tasks)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.loader = false;
            this.onHidden();
          } else {
            this.toaster.error(response.message);
          }
        }
      );
  }

  onHidden() {
    this.modalClosed.emit(true);
  }

  isAllInactive() {
    return this.days.find(e => e.is_active === true) ? false : true;
  }

  toggleActiveState(day: DaysCapacity, elementIndex: number) {
    day.is_active = !day.is_active;
    day.capacity = !day.is_active ? null : day.capacity;
    if (!day.is_active) {
      this.capacityForm.controls['capacity' + elementIndex].setErrors(null);
    }
  }
}
