import { CarwashOrderService } from '@app/modules/carwash/modules/carwash-order/components/shared/services/carwash-order.service';
import {
  Component,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { IOption } from 'ng-uikit-pro-standard';
import { getTicketStatuses } from '@app/modules/carwash/shared/helpers/ticket-statuses';
import { GlobalService } from '@shared/service/global.service';

import { OrderDetailsItemSize } from '@app/modules/carwash/modules/carwash-order/components/carwash-order-details/shared/enums/order-details-item-size.enum';
import { CwGarageBranchTicket } from '@app/modules/carwash/modules/carwash-order/components/shared/models/carwash-order.model';
import { Subscription } from 'rxjs';
import { GroupService } from '@app/shared/service/group';
import { DialogService } from '@app/shared/components/dialog/services/dialog.service';
import { CwCancelOrderDialogComponent } from '../../cw-cancel-order-dialog/cw-cancel-order-dialog.component';
import { CwRescheduleDialogComponent } from '../../cw-reschedule-dialog/cw-reschedule-dialog.component';

@Component({
  selector: 'app-carwash-order-details-card',
  templateUrl: './carwash-order-details-card.component.html',
  styleUrls: ['./carwash-order-details-card.component.scss'],
})
export class CarwashOrderDetailsCardComponent {
  @Input() isMobileType: boolean;
  @Input() ticket: CwGarageBranchTicket = null;
  @Input() groupId: number;
  @Input() orderDetails = null;
  @Output() onRefresh = new EventEmitter();
  readonly OrderDetailsItemSize = OrderDetailsItemSize;
  isCancellable: boolean = true;
  isAbleToReschedule: boolean;

  options: IOption[];
  activeTicketStatus: string;
  currency: string;
  language = localStorage.getItem('language')
    ? localStorage.getItem('language')
    : 'ar';
  dropDownDisabled: boolean = true;
  private subscribers$: Array<Subscription> = [];

  constructor(
    private readonly globalService: GlobalService,
    private readonly groupService: GroupService,
    private readonly dialog: DialogService,
    private readonly carwashOrderService: CarwashOrderService,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadGroup(this.groupId);
    this.isCancellable = false;
    this.isAbleToReschedule = false;
    this.currency = this.globalService.getCurrency();
  }

  openCancellationModal(): void {
    this.dialog.open(CwCancelOrderDialogComponent, {
      order: this.orderDetails,
      ticket: this.ticket,
      callbackFn: () => this.onRefresh.emit(),
    });
  }

  openRescheduleDialog() {
    this.dialog.open(CwRescheduleDialogComponent, {
      ticket: this.ticket,
      orderDetails: this.orderDetails,
      callbackFn: () => this.onRefresh.emit(),
    });
  }

  changeStatus(statusId: number): void {
    this.unsubscribe();
    const userAdminId = localStorage.getItem('userId');
    const subscriber$ = this.carwashOrderService
      .updateOrderStatus(this.ticket.id, statusId, +userAdminId)
      .subscribe();
    this.subscribers$.push(subscriber$);
  }

  private unsubscribe(): void {
    this.subscribers$.forEach((subscriber$) => subscriber$.unsubscribe());
  }

  private loadGroup(id: number) {
    if (!this.groupService?.selectedGroup) {
      if (this.groupService.allGroups) {
        this.groupService.setSelectedGroup(id);
        this.setTicketStatuses();
      } else {
        const subscriber$ = this.groupService.pagination$.subscribe(() => {
          setTimeout(() => {
            this.groupService.setSelectedGroup(id);
            this.setTicketStatuses();
          }, 0);
        });
        this.subscribers$.push(subscriber$);
      }
    } else {
      this.setTicketStatuses();
    }
  }

  private setTicketStatuses(): void {
    // TODO: Uncomment this later when we have statuses table for each group to load them dynamically
    // const groupId = this.groupService?.selectedGroup?.id || this.groupId;
    const groupId = this.groupService.checkOutCall() ? 6 : 4;

    if (groupId) {
      const subscriber$ = this.groupService
        .getGroupStatuses(groupId)
        .subscribe((data) => {
          this.options = data as any;
          this.setActiveTicketStatus();
        });
      this.subscribers$.push(subscriber$);
    } else {
      this.options = getTicketStatuses(this.isMobileType);
      this.setActiveTicketStatus();
    }
  }

  private setActiveTicketStatus(): void {
    this.activeTicketStatus = this.options.find(
      (option: IOption) =>
        Number(option.value) === this.ticket.statusCwGarageBranchTicketId,
    )?.value;
    /** 5=placed, 6 = on the way  , 7= started*/
    if (
      [5, 6, 7].includes(this.ticket.statusCwGarageBranchTicketId) &&
      this.isMobileType
    ) {
      this.isCancellable = true;
      this.isAbleToReschedule = true;
    }
    this.cd.detectChanges();
  }
}
