import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

import { Status } from '@shared/enums/status.enum';
import { CarwashStation } from '@app/modules/carwash/modules/carwash-station/shared/model/carwash-station.model';
import { CarwashStationService } from '@app/modules/carwash/modules/carwash-station/shared/services/carwash-station.service';
import { DialogService } from '@shared/components/dialog/services/dialog.service';
import { CarwashStationEditDialogComponent } from '@app/modules/carwash/modules/carwash-station/components/carwash-station-edit-dialog/carwash-station-edit-dialog.component';
import { AssignStaffDialogComponent } from '../assign-staff-dialog/assign-staff-dialog.component';

@Component({
  templateUrl: './carwash-station-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarwashStationListComponent implements OnInit, OnDestroy {
  Status = Status;
  stations$: Observable<CarwashStation[]>;
  branchId: number;
  isChangingStatus: boolean;
  selectedStation: CarwashStation;
  isMobileType: boolean;
  translationKey = 'STATION';

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private carwashStationService: CarwashStationService,
    private readonly dialog: DialogService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initVariables();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeStationsStatus(station: CarwashStation): void {
    this.isChangingStatus = true;
    this.selectedStation = station;
    const currentStatus =
      station.status === Status.Active ? Status.Inactive : Status.Active;
    this.carwashStationService
      .updateStations(station.id, { status: currentStatus })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        station.status = currentStatus;
        this.isChangingStatus = false;
        this.selectedStation = null;
        this.cdr.markForCheck();
      });
  }

  openStationEditDialog(station: CarwashStation = null): void {
    this.dialog
      .open(CarwashStationEditDialogComponent, {
        ...(station && { station }),
        branchId: this.branchId,
        isMobileType: this.isMobileType,
      })
      .afterClosed$.pipe(
        filter(Boolean),
        tap(() => {
          this.stations$ = this.getStations();
          this.cdr.markForCheck();
        }, takeUntil(this.destroy$)),
      )
      .subscribe();
  }

  removeStation(id: number): void {
    this.carwashStationService
      .removeStations(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.stations$ = this.getStations();
        this.cdr.markForCheck();
      });
  }

  openAssignStaffDialog(station: CarwashStation): void {
    this.dialog
      .open(AssignStaffDialogComponent, station)
      .afterClosed$.pipe(
        filter(Boolean),
        tap(() => {
          this.stations$ = this.getStations();
          this.cdr.markForCheck();
        }, takeUntil(this.destroy$)),
      )
      .subscribe();
  }

  private initVariables(): void {
    const snapshot = this.activatedRoute.snapshot;
    this.branchId = Number(snapshot.params.branchId);
    this.isMobileType = snapshot.queryParams.isMobileType === 'true';
    if (this.isMobileType) {
      this.translationKey = 'VEHICLE';
    }
    this.stations$ = this.getStations();
  }

  private getStations(): Observable<CarwashStation[]> {
    return this.carwashStationService.getStations(this.branchId).pipe(
      map((stations: CarwashStation[]) =>
        stations.filter(
          (station: CarwashStation) =>
            station.cwGarageBranchId === this.branchId,
        ),
      ),
      takeUntil(this.destroy$),
    );
  }
}
