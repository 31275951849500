import { map } from 'rxjs/operators';
import { ApiResponse } from './../../models/global/apiResponse';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HcPriorityChannel } from '@app/shared/models';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class HcPriorityChannelsService {
  private readonly url = `${environment.jcDeliveryServer}hc-priority-channels`;
  constructor(private readonly http: HttpClient) {}

  getChannels(): Observable<HcPriorityChannel[]> {
    return this.http
      .get<ApiResponse<HcPriorityChannel[]>>(this.url)
      .pipe(map(({ data }) => data));
  }
}
