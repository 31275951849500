import { Component, OnInit } from '@angular/core';
import { CampaignsService } from 'src/app/shared/service/campaigns.service';
import { GlobalService } from 'src/app/shared/service/global.service';
import { ToastService } from 'ng-uikit-pro-standard';
import {
  CashBackFromList,
  BOGOFromList
} from 'src/app/shared/models/offers/campaigns';
import { PageInfo } from 'src/app/shared/models/global/response';
import { ApiLibrary } from 'src/app/shared/helpers/http-params';

@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrls: ['./campaign-list.component.css']
})
export class CampaignListComponent implements OnInit {
  cashBackList: CashBackFromList[];
  search: string;
  pagination: PageInfo;
  showCreateCashBack: boolean;
  // BOGO
  bogoList: BOGOFromList[];
  bogoSearch: string;
  paginationBogo: PageInfo;
  showCreateBogo: boolean;
  constructor(
    private campaignService: CampaignsService,
    private globalService: GlobalService,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.getList({ page: 1 });
    this.getBoGOList({ page: 1 });
  }

  parseDate(date: Date) {
    return this.globalService.getDateFromString(date.toString());
  }

  filter() {
    this.getList({
      search: this.search
    });
  }

  filterBogo() {
    this.getBoGOList({
      search: this.bogoSearch
    });
  }

  clearBogo() {
    this.bogoSearch = null;
    this.getPageBogo(1);
  }

  clearCashBack() {
    this.search = null;
    this.getPage(1);
  }

  getPage(page) {
    this.getList(
      new ApiLibrary().cleanObjectFromComponent({
        search: this.search,
        page: page
      })
    );
  }

  getPageBogo(page) {
    this.getBoGOList(
      new ApiLibrary().cleanObjectFromComponent({
        search: this.bogoSearch,
        page: page
      })
    );
  }

  prettyPrice(price) {
    return this.globalService.showPriceWithCurrency(price);
  }

  toggleStatusCashBack(camapign: { id: number; status: string; type: string }) {
    const message = `campaign
      with ID:
      ${camapign.id}
      was successfully
      ${camapign.status === 'active' ? 'Inactivated' : 'Activated'}`;
    this.campaignService
      .activateCashBack(this.globalService.headers, {
        id: camapign.id,
        status: camapign.status === 'active' ? 'inactive' : 'active'
      })
      .subscribe(
        response => {
          if (response.code === 200) {
            this.toast.success(message);
            if (camapign.type === 'cashback') {
              this.getPage(this.pagination.current_page);
            } else {
              this.getPageBogo(this.paginationBogo.current_page);
            }
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }

  disableToggleStatus(date: Date) {
    return this.globalService.isAfter(new Date().toString(), date.toString());
  }

  closeCashBack(event: boolean) {
    this.showCreateCashBack = false;
    if (event) {
      this.getPage(this.pagination.current_page);
    }
  }
  closeBogo(event: boolean) {
    this.showCreateBogo = false;
    if (event) {
      this.getPageBogo(this.pagination.current_page);
    }
  }
  private getList(params?: any) {
    this.campaignService
      .getCashBackList(this.globalService.headers, params)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.cashBackList = response.data;
            this.pagination = response.page_info;
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }
  private getBoGOList(params?: any) {
    this.campaignService
      .getBOGOList(this.globalService.headers, params)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.bogoList = response.data;
            this.paginationBogo = response.page_info;
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }
}
