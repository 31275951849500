import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BackEndResponse } from '../models/global/response';
import { CategoryToBeSaved, CategoryPositionBody } from '../models/items/cats';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  readonly URL_GET_CAT = environment.serverUrl + 'items/get-category';
  readonly URL_SAVE = environment.serverUrl + 'items/create-category';
  readonly URL_DELETE_CAT = environment.serverUrl + 'items/del-category';
  readonly URL_UPLOAD = environment.serverUrl + 'users/upload-image';
  readonly URL_UPDATE_CAT = environment.serverUrl + 'items/edit-category';
  readonly URL_STATUS = environment.serverUrl + 'items/act-inact-category';
  readonly URL_CAT_CHANGE_POSITION =
    environment.serverUrl + 'items/change-position';
  catheaders: HttpHeaders;

  constructor(
    private readonly http: HttpClient,
    private readonly globalService: GlobalService,
  ) {}

  /**
   * Get Gategories from api
   * @param userToken The authentified token
   * @param page the number of requested page, its optional which means if you want the whole list dont pass it.
   */

  getCategories(headers, page?: number) {
    return this.http
      .get<any>(this.URL_GET_CAT, {
        headers: this.getHeaders(headers),
        params: { page: page ? page.toString() : '0' },
      })
      .pipe(map((data) => data as BackEndResponse));
  }

  saveCategory(headers, category: CategoryToBeSaved) {
    return this.http.post<BackEndResponse>(this.URL_SAVE, category, {
      headers: this.getHeaders(headers),
    });
  }

  updateCategory(headers, category: CategoryToBeSaved) {
    return this.http.post<BackEndResponse>(this.URL_UPDATE_CAT, category, {
      headers: this.getHeaders(headers),
    });
  }

  imageUpload(
    file: File,
    imageTypeName: string = 'cw-garages',
  ): Observable<any> {
    const formData = new FormData();
    const headers = this.globalService.headersImage;
    headers['image_type'] = imageTypeName;

    formData.append('image', file);
    return this.http.post<BackEndResponse>(this.URL_UPLOAD, formData, {
      headers: this.getHeaders(headers),
    });
  }

  deleteCategory(headers, id: number) {
    return this.http.post<BackEndResponse>(
      this.URL_DELETE_CAT,
      {
        id: id,
      },
      {
        headers: this.getHeaders(headers),
      },
    );
  }

  changeStatus(headers, id: number, status: string) {
    return this.http.post<BackEndResponse>(
      this.URL_STATUS,
      {
        id: id,
        status: status,
      },
      {
        headers: this.getHeaders(headers),
      },
    );
  }

  changePosition(headers, body: CategoryPositionBody) {
    return this.http.post<BackEndResponse>(this.URL_CAT_CHANGE_POSITION, body, {
      headers: this.getHeaders(headers),
    });
  }

  getHeaders(headers) {
    return new HttpHeaders(headers);
  }
}
