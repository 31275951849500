import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  EventEmitter,
  Output
} from '@angular/core';
import {
  OrderDetails,
  OrderToBeRescheduled,
  Reason,
  SelectedReason
} from '../../../../../shared/models/orders/order';
import { GlobalService } from '../../../../../shared/service/global.service';
import { OrderService } from '../../../../../shared/service/order.service';
import { BranchesService } from '../../../../../shared/service/branches.service';
import {
  ModalDirective,
  MDBDatePickerComponent,
  IMyOptions,
  ToastService
} from 'ng-uikit-pro-standard';
import { BranchTimeSlotNew } from '../../../../../shared/models/laundries/branch';
import { ApiLibrary } from 'src/app/shared/helpers/http-params';
import { ArrayFunctions } from 'src/app/shared/helpers/array-functions';

@Component({
  selector: 'app-change-timeslot',
  templateUrl: './change-timeslot.component.html',
  styleUrls: ['./change-timeslot.component.css']
})
export class ChangeTimeslotComponent implements OnInit, OnChanges {
  @Input()
  showDialog: boolean;
  @Input()
  order: OrderDetails;
  @Output()
  done: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('changeSlot', { static: true })
  modal: ModalDirective;
  @ViewChild('pickUp', { static: false })
  pickup: MDBDatePickerComponent;


  constructor(
    protected globalService: GlobalService,
    protected orderService: OrderService,
    protected branchService: BranchesService,
    protected toast: ToastService
  ) {}

  orderPickUpDate: string;
  orderDeliveryDate: string;
  pickupTimeSlots: { value: any; label: any }[];
  DeliveryTimeSlots: { value: any; label: any }[];
  selectedPickupTimeSlot: number;
  selectedDeliveryTimeSlot: number;
  pickupReason: string;
  deliveryReason: string;
  isFirstPickUp = true;
  isFirstDelivery = true;
  today = new Date().getDate();
  currentOrderStatus: number;
  serviceRadioTouched = false;

  reasons: Reason[];
  selectedPickupReason: SelectedReason;
  selectedDeliveryReason: SelectedReason;
  myDatePickerOptions: IMyOptions;
  myDatePickerDeliveryOptions: IMyOptions

  ngOnInit() {

    this.myDatePickerOptions = {
      disableUntil: {
        day: this.globalService
          .dateMinusDays(new Date().toDateString(), 7)
          .date(),
        month:
          this.globalService.dateMinusDays(new Date().toDateString(), 7).month() +
          1,
        year: this.globalService
          .dateMinusDays(new Date().toDateString(), 7)
          .year()
      },
      disableSince: {
        day: this.globalService.datePlusDays(new Date().toDateString(), 7).date(),
        month:
          this.globalService.datePlusDays(new Date().toDateString(), 7).month() +
          1,
        year: this.globalService.datePlusDays(new Date().toDateString(), 7).year()
      },
      closeAfterSelect: true
    };

    this.myDatePickerDeliveryOptions = {
      disableUntil: {
        day: this.globalService
          .dateMinusDays(new Date().toDateString(), 1)
          .date(),
        month:
          this.globalService.dateMinusDays(new Date().toDateString(), 1).month() +
          1,
        year: this.globalService
          .dateMinusDays(new Date().toDateString(), 1)
          .year()
      },
      disableSince: {
        day: this.globalService.datePlusDays(new Date().toDateString(), 7).date(),
        month:
          this.globalService.datePlusDays(new Date().toDateString(), 7).month() +
          1,
        year: this.globalService.datePlusDays(new Date().toDateString(), 7).year()
      },
      closeAfterSelect: true
    };

    this.getReasons({
      type: 'reschedule'
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.showDialog && changes.showDialog.currentValue) {
      this.showDialog = changes.showDialog.currentValue;
    }
    if (changes && changes.order && changes.order.currentValue) {
      this.order = changes.order.currentValue;
      this.updateDatePicker();
      this.currentOrderStatus = this.order.order_result.statuses_user.id;
    }
    console.log(changes);

    if (this.showDialog) {
      this.modal.show();
    }
  }

  onClose() {
    console.log('closed');
    this.orderPickUpDate = null;
    this.orderDeliveryDate = null;
    this.pickupTimeSlots = null;
    this.DeliveryTimeSlots = null;
    this.isFirstDelivery = true;
    this.isFirstPickUp = true;
    this.serviceRadioTouched = false;
    this.selectedDeliveryReason = null;
    this.selectedPickupReason = null;
    this.pickupReason = null;
    this.deliveryReason = null;
    this.done.emit(true);

  }

  changeService(value: boolean) {
    this.serviceRadioTouched = true;
    this.order.order_result.is_fast_service = value;
  }

  changeTimeSlot(type?: string) {
    let t = new OrderToBeRescheduled();
    t.order_id = this.order.order_result.id;
    if (type === 'pickup') {
      t.timeslot_id = this.selectedPickupTimeSlot.toString();
      const picktime = this.pickupTimeSlots.find(
        e => e.value === this.selectedPickupTimeSlot
      );
      t.time_at = this.globalService.getDateOrderFormat(
        this.orderPickUpDate + ' ' + picktime.label
      );
      t.reason = this.pickupReason;
      t.reason_id = this.selectedPickupReason.reason;
      t.sub_reason_id = this.selectedPickupReason.sub;
    }
    if (type === 'delivery') {
      t.timeslot_id = this.selectedDeliveryTimeSlot.toString();
      const deliverytime = this.DeliveryTimeSlots.find(
        e => e.value === this.selectedDeliveryTimeSlot
      );
      t.time_at = this.globalService.getDateOrderFormat(
        this.orderDeliveryDate + ' ' + deliverytime.label
      );
      t.reason = this.deliveryReason;
      t.reason_id = this.selectedDeliveryReason.reason;
      t.sub_reason_id = this.selectedDeliveryReason.sub;
    }
    if (type === undefined) {
      t.is_fast = this.order.order_result.is_fast_service;
    } else {
      t.type = type;
    }
    t = new ApiLibrary().cleanObjectFromComponentWithMinusValue(t);
    this.orderService.rescheduleOrder(this.globalService.headers, t).subscribe(
      response => {
        this.toast.success(response.message);
        this.updateDatePicker();
      }
    );
  }

  timeSlotChanged(event) {
    console.log(event);
  }

  pickupDateChanged(event) {
    console.log(event);
    this.getLaundryTimeSlots(event, true);
    /*  this.myDatePickerDeliveryOptions = {
      disableUntil: {
        day: new Date(event).getDate() - 1,
        month: new Date(event).getMonth() + 1,
        year: new Date(event).getFullYear()
      },
      disableSince: {
        day: new Date(event).getDate() + 7,
        month:
          new Date(event).getDate() < 25
            ? new Date(event).getMonth() + 1
            : new Date(event).getMonth() + 2,
        year: new Date(event).getFullYear()
      }
    }; */
  }

  DeliveryDateChanged(event) {
    console.log('anis', event);

    this.getLaundryTimeSlots(event, false);
  }

  getLaundryTimeSlots(day: string, isPickUp: boolean) {
    console.log(this.globalService.getDayName(day));
    this.branchService
      .getBranchTimeSlot(
        this.globalService.headers,
        this.order.order_result.laundry_branch.id,
        isPickUp
          ? {
            is_reschedule: true,
            pickup_date: day.split(' ')[0],
            order_id: this.order.order_result.id
          }
          : {
              is_reschedule: true,
              delivery_date: day.split(' ')[0],
              order_id: this.order.order_result.id
            }
      )
      .subscribe(response => {
        this.pickupTimeSlots = isPickUp ? [] : this.pickupTimeSlots;
        this.DeliveryTimeSlots = !isPickUp ? [] : this.DeliveryTimeSlots;
        let timeSlots = response.data as BranchTimeSlotNew[];
        timeSlots = isPickUp
          ? timeSlots.filter(
              elem =>
                elem.day.toLowerCase() ===
                this.globalService
                  .getDayName(this.orderPickUpDate)
                  .toLowerCase()
            )
          : timeSlots.filter(
              elem =>
                elem.day.toLowerCase() ===
                this.globalService
                  .getDayName(this.orderDeliveryDate)
                  .toLowerCase()
            );
        timeSlots.forEach(e => {
          e.times.forEach(time => {
            console.log(
              this.orderDeliveryDate +
                ' ' +
                this.globalService.getTIME(time.start.toString())
            );
            if (
              !time.is_busy &&
              // time.is_selected &&
              time.remaining_capacity > 0
            ) {
              console.log(
                this.orderPickUpDate +
                  ' ' +
                  this.globalService.getTIME(time.start.toString())
              );
              if (isPickUp) {
                this.pickupTimeSlots.push({
                  value: time.id,
                  label: this.getTimeWithTimeZone(
                    this.orderPickUpDate +
                      ' ' +
                      this.globalService.getTIME(time.start.toString())
                  )
                });
              } else {
                console.log('am in');

                this.DeliveryTimeSlots.push({
                  value: time.id,
                  label: this.getTimeWithTimeZone(
                    this.orderDeliveryDate +
                      ' ' +
                      this.globalService.getTIME(time.start.toString())
                  )
                });
              }
            }
          });
        });
      });
  }

  getTimeWithTimeZone(time: string) {
    return this.globalService.getTimeString(time);
  }

  validatePickUpForm() {
    return (
      this.orderPickUpDate &&
      this.selectedPickupTimeSlot &&
      this.validatePickReasons() &&
      this.pickupReason
    );
  }
  validateDeliveryForm() {
    return (
      this.orderDeliveryDate &&
      this.selectedDeliveryTimeSlot &&
      this.validateDelReasons() &&
      this.deliveryReason
    );
  }

  validatePickReasons() {
    if (this.selectedPickupReason) {
      return this.selectedPickupReason &&
        this.reasonHasSubs(this.selectedPickupReason.reason)
        ? this.selectedPickupReason.reason > 0 &&
            this.selectedPickupReason.sub > 0
        : this.selectedPickupReason.reason > 0;
    } else {
      return false;
    }
  }
  validateDelReasons() {
    if (this.selectedDeliveryReason) {
      return this.selectedDeliveryReason &&
        this.reasonHasSubs(this.selectedDeliveryReason.reason)
        ? this.selectedDeliveryReason.reason > 0 &&
            this.selectedDeliveryReason.sub > 0
        : this.selectedDeliveryReason.reason > 0;
    } else {
      return false;
    }
  }

  updateDatePicker() {
    this.myDatePickerDeliveryOptions = {
      disableUntil: {
        day: this.globalService
          .dateMinusDays(new Date().toDateString(), 1)
          .date(),
        month:
          this.globalService
            .dateMinusDays(new Date().toDateString(), 1)
            .month() + 1,
        year: this.globalService
          .dateMinusDays(new Date().toDateString(), 1)
          .year()
      },
      disableSince: {
        day: this.order.order_result.is_fast_service
          ? this.globalService
              .datePlusDays(
                new Date(this.order.order_result.pickup_at).toDateString(),
                2
              )
              .date()
          : this.globalService
              .datePlusDays(new Date().toDateString(), 7)
              .date(),
        month: this.order.order_result.is_fast_service
          ? this.globalService
              .datePlusDays(
                new Date(this.order.order_result.pickup_at).toDateString(),
                2
              )
              .month() + 1
          : this.globalService
              .datePlusDays(new Date().toDateString(), 7)
              .month() + 1,
        year: this.order.order_result.is_fast_service
          ? this.globalService
              .datePlusDays(
                new Date(this.order.order_result.pickup_at).toDateString(),
                2
              )
              .year()
          : this.globalService.datePlusDays(new Date().toDateString(), 7).year()
      },
      closeAfterSelect: true
    };
  }

  isPickupAfterDelivery() {
    return (
      this.order &&
      this.order.order_result &&
      this.globalService.isAfter(
        this.order.order_result.pickup_at.toString(),
        this.order.order_result.delivery_at.toString()
      )
    );
  }

  selectedReasonAndSubPickup(event: SelectedReason) {
    this.selectedPickupReason = event;
  }
  selectedReasonAndSubDelivery(event: SelectedReason) {
    this.selectedDeliveryReason = event;
  }

  private getReasons(params?: any) {
    this.orderService
      .getAllReasons(this.globalService.headers, params)
      .subscribe(response => {
        if (response.code === 200) {
          this.reasons = response.data;
        }
      });
  }

  private reasonHasSubs(id: number) {
    const reasonIndex = ArrayFunctions.findElementInArray<Reason>(
      this.reasons,
      id,
      true,
      'id'
    );
    const reason = this.reasons[reasonIndex];
    return reason.sub_reasons && reason.sub_reasons.length > 0;
  }
}
