import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BackEndResponse } from 'src/app/shared/models/global/response';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  readonly URL_USERS = environment.jcDeliveryServer + 'users';
  readonly URL_NOTIFICATIONS = environment.jcDeliveryServer + 'notifications/users';
  constructor(private http: HttpClient) {}

  getUserByEmail(headers, params?: any) {
    return this.http
      .get<BackEndResponse>(`${this.URL_USERS}`, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(
        tap((data) => console.log(data))
      );
  }

  sendNotificationToUsers(headers, body: SendNotificationsDto) {
    return this.http
      .post<BackEndResponse>(this.URL_NOTIFICATIONS, body, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

}
