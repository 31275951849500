import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BackEndResponse } from '../models/global/response';
import { tap } from 'rxjs/operators';
import {
  FinalItemObjectToBeSaved,
  SpecialIconsTCToBeSaved
} from '../models/laundries/special-icons';

@Injectable({
  providedIn: 'root'
})
export class SpecialIconService {
  readonly URL_SERVICES = environment.serverUrl + 'laundry/item-service';
  readonly URL_ITEMS = environment.serverUrl + 'laundry/items';
  readonly URL_TAT = environment.serverUrl + 'laundry/tat-services';
  readonly URL_SAVE = environment.serverUrl + 'laundry/item-price-list';
  readonly URL_TC = environment.serverUrl + 'laundry/item-tc';
  readonly URL_PRICES =
    environment.serverUrl + 'laundry/branch-item-price-list';
  constructor(private http: HttpClient) {}

  getAllItems(headers, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_ITEMS, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params })
      })
      .pipe(tap(data => console.log(data)));
  }

  getServicesForItem(headers, itemId: number) {
    return this.http
      .get<BackEndResponse>(this.URL_SERVICES + '/' + itemId, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  getTATServices(headers) {
    return this.http
      .get<BackEndResponse>(this.URL_TAT, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  getItemPrices(headers, branchId: number, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_PRICES + '/' + branchId, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params })
      })
      .pipe(tap(data => console.log(data)));
  }

  savePrices(headers, body: FinalItemObjectToBeSaved) {
    return this.http
      .post<BackEndResponse>(this.URL_SAVE, body, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  getTC(headers, branchId: number) {
    return this.http
      .get<BackEndResponse>(this.URL_TC + '/' + branchId, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  saveTC(headers, body: SpecialIconsTCToBeSaved) {
    return this.http
      .post<BackEndResponse>(this.URL_TC, body, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }
}
