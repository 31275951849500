import { ERROR_TYPE } from './error.type';

export const ERROR_HANDLERS: ERROR_TYPE = {
  401: {
    messageTranslation: {
      ar: 'انتهت الصلاحية، يرجى تسجيل الخروج وتسجيل الدخول مرة أخرى',
      en: 'Session expired, please logout and login again',
    },
    handlerName: 'logout', // should create a method with the same name in the Interceptor class
  },
  403: {
    messageTranslation: {
      ar: 'ممنوع ، آسف ليس لديك إذن للوصول إلى هذه الصفحة',
      en: 'Forbidden, sorry you do not have permission to access this page',
    },
    handlerName: 'goHome',
  },
};
