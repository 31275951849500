import { HomecleanCompany } from '@app/modules/homeclean/modules/homeclean-company/shared/models';
import { OpenHours } from '@shared/models';
import { Translation } from 'libs/justclean-models/src/lib/justclean-models';
import { HcArea } from '.';
export interface HcBranch {
  area: HcArea;
  areaId: number;
  branchAddress: string;
  closeTime: string;
  code: string;
  companyName: Translation;
  contactEmail: string;
  contactMobile: string;
  contactName: string;
  countryCode: string;
  countryId: number;
  createdAt: string;
  createdById: number;
  currency: string;
  deletedAt: null;
  discountBy: string;
  distance: number;
  gmapsLink: string;
  hcCompany: HomecleanCompany;
  hcCompanyId: number;
  highestPrice: number;
  id: number;
  latitude: number;
  longitude: number;
  lowestPrice: number;
  managerEmail: string;
  managerMobile: string;
  managerName: string;
  noOfBranches: number;
  openTime: string;
  openingHours: OpenHours[];
  operatingName: Translation;
  ratingCount: number;
  ratingValue: number;
  status: string;
  tax: number;
  trnName: string;
  trnNumber: string;
  type: string;
  updatedAt: string;
  hcPriorityChannelId?: number;
}
