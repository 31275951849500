import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { concat, Observable, Subject } from 'rxjs';
import { takeUntil, tap, toArray } from 'rxjs/operators';

import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { FormStatus } from '@shared/enums/form-status.enum';
import { Variant, SubVariant } from '@shared/models';
import { ImageUploaderHttpService } from '@shared/service/image-uploader-http.service';
import { VariantService } from '@shared/service/variant';
import { UntypedFormControl } from '@angular/forms';
import { GlobalConfig, ToastService } from 'ng-uikit-pro-standard';

@Component({
  templateUrl: './variant-edit-dialog.component.html',
})
export class VariantEditDialogComponent implements OnInit {
  isEditMode: boolean;
  isDisableButton: boolean;
  formData: Partial<Variant>;
  subVariantsFormsData: Array<Partial<SubVariant>>;
  imageVariantLogoLink: string;
  imageOperatingLogoLink: string;
  isLoading: boolean;
  isMainVariantFormValid: boolean;
  isAllSubVariantFormsValid: boolean = true;

  private loadedVariants: Array<Variant>;
  private readonly destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    @Inject(DIALOG_DATA) public readonly data: Variant | any,
    private readonly dialogRef: DialogRef<VariantEditDialogComponent>,
    private readonly variantService: VariantService,
    private readonly imageUploaderHttpService: ImageUploaderHttpService,
    private readonly cdr: ChangeDetectorRef,
    private readonly toast: ToastService,
  ) {}

  ngOnInit(): void {
    this.isEditMode = !!this.data;
    this.loadedVariants = this.variantService.loadedVariants;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(): void {
    this.dialogRef.close();
  }

  formChanged(variant: Partial<Variant>): void {
    if (!this.isEditMode) {
      const sameVariantExisting = this.loadedVariants.find(
        (el) =>
          (el.name?.en === variant.name?.en ||
            el.name?.ar === variant.name?.ar) &&
          el.groupId === variant.groupId,
      );
      if (sameVariantExisting) {
        this.showWarningToast(
          'Variant with the same name and group is already exist',
        );
        this.isDisableButton = true;
      } else {
        this.changeSaveButtonStatus();
      }
    }
    if (!variant.hasMultipleVariants && this.isEditMode) {
      if (variant.subVariants.length) {
        variant.subVariants[0].name = variant.name;
        variant.subVariants[0].image = variant.image;
      }
    }
    this.formData = {
      ...variant,
    };
  }

  subVariantFormChanged(subVariants: Array<Partial<SubVariant>>): void {
    this.subVariantsFormsData = subVariants;
  }

  formStatusChanged(status: FormStatus): void {
    this.isMainVariantFormValid = status === FormStatus.Valid;
    this.changeSaveButtonStatus();
  }

  subVariantFormsValidityChanged(isAllValid: boolean): void {
    this.isAllSubVariantFormsValid = isAllValid;
    this.changeSaveButtonStatus();
  }

  createVariant(): void {
    const data: Partial<Variant> = {
      ...this.formData,
      ...(this.imageVariantLogoLink && {
        image: this.imageVariantLogoLink,
      }),
      subVariants: undefined,
    };
    const action$ = this.isEditMode
      ? this.variantService.updateVariant(this.data.id, data)
      : this.variantService.createVariant(data);

    this.isLoading = true;
    action$
      .pipe(
        tap(() => {
          this.isLoading = false;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (variant: Variant) => {
          this.handleSubVariantsData(variant);
        },
        (error) => {
          this.isLoading = false;
          this.cdr.markForCheck();
        },
      );
  }

  private handleSubVariantsData(variant: Variant): void {
    const subscribers$: Array<Observable<any>> = [];

    if (
      !this.isEditMode &&
      !this.subVariantsFormsData?.length &&
      !this.formData.hasMultipleVariants
    ) {
      /** Create sub variant by default from main variant data */
      const newSubVariant: SubVariant = {
        name: variant.name,
        image: variant.image,
        variantId: variant.id,
      };
      subscribers$.push(this.variantService.createSubVariant(newSubVariant));
    } else if (this.formData.hasMultipleVariants) {
      this.subVariantsFormsData?.forEach((subVariant) => {
        const existingSubVariant = subVariant.id
          ? this.formData.subVariants
              ?.map((el) => ({
                id: el.id,
                name: el.name,
                image: el.image,
                variantId: el.variantId,
              }))
              ?.find((el) => el.id === subVariant.id)
          : null;
        if (existingSubVariant) {
          /** sub variants to update */
          if (
            JSON.stringify(subVariant) !== JSON.stringify(existingSubVariant)
          ) {
            subscribers$.push(
              this.variantService.updateSubVariant(subVariant.id, subVariant),
            );
          }
        } else {
          /** sub variants to create */
          subVariant.variantId = variant.id;
          subscribers$.push(this.variantService.createSubVariant(subVariant));
        }
      });

      /** sub variants to delete */
      this.formData?.subVariants
        ?.filter(
          (oldSubVariant) =>
            !this.subVariantsFormsData
              ?.filter((el) => el.id)
              ?.find((el) => el.id === oldSubVariant.id),
        );
        // .forEach(({ id }) => {
        //   subscribers$.push(this.variantService.deleteSubVariant(id));
        // });
    } else if (
      !this.formData.hasMultipleVariants &&
      this.formData.subVariants?.length
    ) {
      subscribers$.push(
        this.variantService.updateSubVariant(
          this.formData.subVariants[0].id,
          this.formData.subVariants[0],
        ),
      );
    }

    if (subscribers$.length) {
      /** calling observables sequentially */
      concat(...subscribers$).pipe(toArray()).subscribe(() => this.dialogRef.close(variant));
    } else {
      this.dialogRef.close(variant);
    }
  }

  uploadVariantLogoFile(file: File): void {
    this.uploadImage(file)
      .pipe(takeUntil(this.destroy$))
      .subscribe((link: string) => {
        this.imageVariantLogoLink = link;
      });
  }

  uploadSubVariantLogoFile(file: File, imageFormControl: UntypedFormControl): void {
    this.uploadImage(file)
      .pipe(takeUntil(this.destroy$))
      .subscribe((link: string) => {
        imageFormControl.setValue(link);
      });
  }

  private changeSaveButtonStatus(): void {
    this.isDisableButton =
      !this.isMainVariantFormValid || !this.isAllSubVariantFormsValid;
  }

  private uploadImage(file): Observable<string> {
    this.isLoading = true;

    return this.imageUploaderHttpService.imageUpload(file).pipe(
      tap(() => {
        this.cdr.markForCheck();
        this.isLoading = false;
      }),
    );
  }

  private showWarningToast(message: string): void {
    this.toast.warning(message, 'Sorry!', this.getCommonSetting());
  }

  private getCommonSetting(): GlobalConfig {
    const options: GlobalConfig = this.toast.toastConfig;
    options.preventDuplicates = true;
    options.closeButton = true;
    return options;
  }
}
