import {
  ServiceAreasComponent,
  WorkingHoursComponent,
  AssignServiceToCompanyComponent,
  PackageHoursComponent,
  ServicePaymentMethodsComponent,
} from '../action-components';

export const ACTION_COMPONENT_MAP: { [componentSelector: string]: any } = {
  'app-service-areas': ServiceAreasComponent,
  'app-working-hours': WorkingHoursComponent,
  'app-assign-service-to-company': AssignServiceToCompanyComponent,
  'app-package-hours': PackageHoursComponent,
  'app-service-payment-methods': ServicePaymentMethodsComponent,
};
