import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { UserSegment } from '@shared/models';
import { expand, reduce, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserSegmentHttpService {
  private readonly url = `${environment.jcDeliveryServer}user-segments`;

  constructor(private readonly http: HttpClient) {}

  getAllUserSegments(
    params: Record<string, string> = {},
  ): Observable<UserSegment[]> {
    return this.http
      .get<ApiResponse<UserSegment[]>>(`${this.url}`, {
        params,
      })
      .pipe(
        expand((res) =>
          !res.pageInfo.nextPage
            ? EMPTY
            : this.http.get<ApiResponse<UserSegment[]>>(
                this.url,
                this.updateRequestFormat(params, res.pageInfo.nextPage),
              ),
        ),
        reduce((records, res) => records.concat(res.data), []),
      );
  }

  getUserSegments(
    params?: Record<string, string>,
  ): Observable<ApiResponse<UserSegment[]>> {
    return this.http.get<ApiResponse<UserSegment[]>>(this.url, { params });
  }

  getSegmentById(id: number): Observable<UserSegment> {
    return this.http
      .get<ApiResponse<UserSegment>>(`${this.url}/${id}`)
      .pipe(map(({ data }) => data));
  }

  createUserSegment(
    data: Partial<UserSegment>,
  ): Observable<ApiResponse<UserSegment>> {
    return this.http.post<ApiResponse<UserSegment>>(this.url, data);
  }

  updateUserSegment(
    id: number,
    data: Partial<UserSegment>,
  ): Observable<ApiResponse<UserSegment>> {
    return this.http.patch<ApiResponse<UserSegment>>(`${this.url}/${id}`, data);
  }

  private updateRequestFormat(params: any, page: number) {
    params.page = page;
    return {
      params,
    };
  }
}
