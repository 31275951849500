import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { LoggedinUser } from '../models/user/user';

import  { DurationInputArg2 } from 'moment-timezone';
import { Observable, of, Subject } from "rxjs";
import { Currency, BackEndResponse } from '../models/global/response';
import { NgLog } from '../helpers/log.decorator';
import { ContractToBeSaved } from '../models/laundries/corporate';
import { AppHeaders } from '../models/user/generic-backend';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap } from "rxjs/operators";
import { IMyDate } from 'ng-uikit-pro-standard';
import { LocalToUtcTimePipe } from '@app/shared/pipes/local-to-utc/local-to-utc-time.pipe';
import { UtcToLocalTimePipe } from './../pipes/utc-to-local/utc-to-local-time.pipe';
import { UtcToLocalDatePipe } from '../pipes/utc-to-local/utc-to-local-date.pipe';
import { LocalToUtcDatePipe } from '../pipes/local-to-utc/local-to-utc-date.pipe';
import moment from "moment/moment";
import 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
@NgLog()
export class GlobalService {
  countryId = 1;
  groupId = 1;
  userToken: string;
  headers: AppHeaders = {};
  stopShowingErrors: boolean;
  headersImage: AppHeaders = {};
  loggedIn = false;
  currency: string;
  timeZone: string;
  loggedInUser: LoggedinUser;
  decimal: number;
  countries = [
    {
      //KW
      countryId: 1,
      longitude: 47.9774,
      latitude: 29.3759,
      currency_decimal: 3,
      country_code: 'KW',
      currency: 'KD',
    },
    {
      //AE
      countryId: 2,
      longitude: 55.2708,
      latitude: 25.2048,
      currency_decimal: 2,
      country_code: 'AE',
      currency: 'AE',
    },
    {
      //BH
      countryId: 3,
      longitude: 50.586,
      latitude: 26.2285,
      currency_decimal: 3,
      country_code: 'BH',
      currency: 'BD',
    },
    {
      //SA
      countryId: 4,
      longitude: 46.649572374999934,
      latitude: 24.594404455792954,
      currency_decimal: 2,
      country_code: 'SA',
      currency: 'SR',
    },
    {
      //QA
      countryId: 5,
      longitude: 50.6572972,
      latitude: 25.3430486,
      currency_decimal: 2,
      country_code: 'QA',
      currency: 'QR',
    },
  ];
  countryCodeSubject: Subject<string> = new Subject();
  readonly URL_VALIDATE_PROMO =
    environment.serverUrl + 'laundry/validate-gift-code';
  constructor(
    // private storage: StorageMap,
    private localStorage: LocalStorage,
    private http: HttpClient,
  ) {
    this.setHeaders();
  }

  saveUser(user: LoggedinUser): Observable<LoggedinUser> {
    this.loggedInUser = user;
    this.countryId = user.country_id;
    this.headers.Authorization = 'Bearer ' + user.token;
    this.headersImage.Authorization = 'Bearer ' + user.token;
    this.addExtraHeaders(this.headers);
    this.addExtraHeaders(this.headersImage);
    window.localStorage.setItem('loggedIn', 'true');
    window.localStorage.setItem('token', user.token);
    window.localStorage.setItem('expiry', moment().add(2, 'hours').format('x'));
    window.localStorage.setItem('email', user.email);
    window.localStorage.setItem('name', user.name);
    window.localStorage.setItem('userId', user.id.toString());
    window.localStorage.setItem('role', user.role_id.toString());
    window.localStorage.setItem('country', user.country_id ? user.country_id.toString() : '1');
    if (user.role_id !== 1) {
      this.countryId = user.country_id;
    }
    window.localStorage.setItem('appUser', JSON.stringify(user));

    return of(user);
  }

  getUser$(): Observable<LoggedinUser> {
    const user = JSON.parse(window.localStorage.getItem('appUser'));
    return of(user);
  }

  getUser(): LoggedinUser {
    return JSON.parse(window.localStorage.getItem('appUser'));
  }

  getUserSynchronous() {
    return window.indexedDB.open('ngStorage').result;
  }

  deleteUser() {
    return window.localStorage.removeItem('appUser');
  }

  getDateFromString(date: string) {
    return moment(date).tz(this.timeZone).format('DD-MM-YYYY');
  }
  getDateFromStringForDatePicker(date: string) {
    return moment(date).tz(this.timeZone).format('YYYY-MM-DD');
  }

  getDateTimeFromString(date: string) {
    return moment(date).tz(this.timeZone).format('DD-MM-YYYY HH:mm:ss');
  }

  getTimeFromString(date: string) {
    return moment(date).tz(this.timeZone).format('HH');
  }

  getTimeString(date: string) {
    return moment(date).format('HH:mm:ss');
  }

  getDayName(date: string) {
    return moment(date).format('dddd');
  }

  getDateOrderFormat(date: string) {
    return moment(date).utc().format('YYYY-MM-DD HH:mm:ss');
  }

  getHours(date: string | Date) {
    return moment(date).tz(this.timeZone).format('HH');
  }

  getTIME(date: string) {
    return moment(date).format('HH:mm:ss');
  }

  isAfter(date1: string, date2: string) {
    return moment(date1).isAfter(date2);
  }

  isBefore(date1: string, date2: string) {
    return moment(date1).isBefore(date2);
  }

  isSame(date1: string, date2: string) {
    return moment(date1).isSame(date2);
  }

  formatDate(date: string, format: string) {
    return moment(date).format(format);
  }

  dateNow(): string {
    return moment().format('YYYY-MM-DD[T]HH:mm:ss');
  }

  dateNowUTC(): string {
    return moment().utc().format('YYYY-MM-DD[T]HH:mm:ss');
  }

  logout(): Observable<boolean> {
    this.localStorage.clear();
    window.localStorage.setItem('loggedIn', 'false');
    window.localStorage.clear();
    return new Observable((observer) => observer.next(true));
  }

  getCurrency() {
    return window.localStorage.getItem('currency');
  }

  getCurrencyDecimal() {
    return parseInt(window.localStorage.getItem('decimal'));
  }

  getTax(): number {
    return +window.localStorage.getItem('tax') || 0;
  }

  setHeaders() {

    this.countryId = parseInt(window.localStorage.getItem('country'), 10);

    if (this.countryId === 2) {
      this.timeZone = 'Asia/Dubai';
    } else {
      this.timeZone = 'Asia/Kuwait';
    }
    const loggedIn = window.localStorage.getItem('loggedIn');
    if (loggedIn === 'true') {
      this.headers.Authorization =
        'Bearer ' + window.localStorage.getItem('token');
      this.headersImage.Authorization =
        'Bearer ' + window.localStorage.getItem('token');
      this.addExtraHeaders(this.headers);
      this.addExtraHeaders(this.headersImage);
      if (
        window.localStorage.getItem('role') === '1' ||
        window.localStorage.getItem('role') === '5' ||
        window.localStorage.getItem('role') === '7'
      ) {
        this.headers.country_id = window.localStorage.getItem('country');

        this.headersImage.country_id = window.localStorage.getItem('country');
      }
    }
    this.getCurrency();
  }

  getLongLat() {
    return this.countries.find((e) => e.countryId === this.countryId);
  }

  getCountry() {
    const country = Currency.find(
      (e) => e.id.toString() === window.localStorage.getItem('country'),
    );
    return country ? country.name : 'Kuwait';
  }

  fixNullValues(value: any) {
    return value === null ? '' : value;
  }

  saveContractToLocalStorage(contract: ContractToBeSaved) {
    return this.localStorage.setItem('contract', contract);
  }

  deleteContract() {
    window.localStorage.removeItem('contract');
  }

  dateMinusDays(date: string, days: number) {
    return moment(date).subtract(days, 'days');
  }

  datePlusDays(date: string, days: number) {
    return moment(date).add(days, 'days');
  }

  getLast2MonthDate() {
    return moment().subtract(60, 'days');
  }

  getNext7Days() {
    return moment().add(7, 'days');
  }

  getMomentDate(date: string) {
    return new Date(moment(date).toString());
  }

  getMoment(date: string) {
    return moment(date);
  }

  /**
   * @param date string
   * @param number
   * @param type  type here needs to be exactly days, months, years
   */

  dateAddition(date: string, number: number, type: string) {
    return moment(date).add(
      number,
      type as moment.unitOfTime.DurationConstructor,
    );
  }

  fetchFromStorage(key: string) {
    return window.localStorage.getItem(key);
  }

  showPriceWithCurrency(price: number) {
    return (
      parseFloat(price.toString()).toFixed(
        parseInt(this.fetchFromStorage('decimal'), 10),
      ) + this.currency
    );
  }

  formatAmount(countryId: number, amount: number): string {
    const country = this.countries.find(
      (country) => country.countryId === countryId,
    );
    return `${(+amount).toFixed(country.currency_decimal)} ${
      country.country_code
    }`;
  }

  formatTime(time: string, format: string = 'HH:mm:ss') {
    const dateOnly = moment().format('ddd MMM DD YYYY');
    return moment(`${dateOnly} ${time}`).format(format);
  }

  getTimePlus(
    time: string,
    number: number,
    type: DurationInputArg2,
    format: string = 'HH:mm:ss',
  ) {
    const dateOnly = moment().format('ddd MMM DD YYYY');
    return moment(`${dateOnly} ${time}`)
      .add(number, type as any)
      .format(format);
  }

  timeDiffString(date: string) {
    const now = moment();
    const later = moment(date);
    return moment(later.diff(now)).format('hh:mm:ss');
  }

  timeDiffSecond(date: string) {
    const now = moment();
    const later = moment(date);
    return later.diff(now, 'seconds');
  }

  /** Method to get difference time between start param and current time */
  checkFromNow(start: string): string {
    return moment(start).fromNow();
  }

  formatTimeFromSecond(s: number) {
    if (s > 0) {
      let hours = moment.duration(s, 'seconds').hours().toString();
      let minutes = moment.duration(s, 'seconds').minutes().toString();
      let seconds = moment.duration(s, 'seconds').seconds().toString();
      let days = moment.duration(s, 'seconds').days().toString();

      hours = hours < '10' ? '0' + hours : hours;
      minutes = minutes < '10' ? '0' + minutes : minutes;
      seconds = seconds < '10' ? '0' + seconds : seconds;
      days = days < '10' ? '0' + days : days;
      return days + ' days  ' + hours + ':' + minutes + ':' + seconds;
    } else {
      return '00:00:00';
    }
  }

  validatePromoCode(headers, body: { promo_code: string }) {
    return this.http
      .post<BackEndResponse>(this.URL_VALIDATE_PROMO, body, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  timeDiff(date1: string, date2: string, unit: string) {
    const now = moment(date1);
    const later = moment(date2);
    return later.diff(now, unit as moment.unitOfTime.DurationConstructor);
  }

  dateIsBetween(date: string, startRange: string, endRange: string) {
    return moment(date).isBetween(startRange, endRange);
  }

  getTimeWithTimeZone(time: string) {
    const tempTime = time.split(':');
    return moment()
      .utc()
      .set({
        hour: parseInt(tempTime[0], 10),
        minute: parseInt(tempTime[1], 10),
        second: parseInt(tempTime[2], 10),
      })
      .tz(this.timeZone)
      .format('HH:mm:ss');
  }

  getDateAvoidingTimeZone(date: string, format: string) {
    return moment(date).utc().format(format);
  }

  getEndOfDayDateTime(date: string) {
    return moment(date).utc().endOf('day').format();
  }

  getMonthList() {
    return moment.months();
  }

  getMonth(date: string) {
    return moment(date).month() + 1;
  }

  getYear(date: string) {
    return moment(date).year();
  }

  getDatePickerDate(date: string): IMyDate {
    return {
      day: moment(date).date(),
      month: moment(date).month() + 1,
      year: moment(date).year(),
    };
  }

  uniq(a: any[], param: string) {
    return a.filter((item, pos, array) => {
      return (
        array.map((mapItem) => mapItem[param]).indexOf(item[param]) === pos
      );
    });
  }

  getDay(date: string) {
    return moment(date).isoWeekday(moment(date).day());
  }

  utcToLocalDate(
    date: string | Date,
    format = 'YYYY-MM-DD[T]HH:mm:ss',
  ): string {
    return new UtcToLocalDatePipe().transform(date, format);
  }

  localToUtcDate(
    date: string | Date,
    format = 'YYYY-MM-DD[T]HH:mm:ss',
  ): string {
    return new LocalToUtcDatePipe().transform(date, format);
  }

  utcToLocalTime(time: string, format = 'HH:mm:ss'): string | Date {
    return new UtcToLocalTimePipe().transform(time, format);
  }

  localToUtcTime(time: string, format = 'HH:mm:ss'): string | Date {
    return new LocalToUtcTimePipe().transform(time, format);
  }

  getNewInstance(obj: any): any {
    if (!obj) {
      return obj;
    }
    return JSON.parse(JSON.stringify(obj));
  }

  getUtcStartOfDay(date: string): string {
    return moment(date).startOf('d').utc().format();
  }

  getUtcEndOfDay(date: string): string {
    return moment(date).endOf('d').utc().format();
  }

  getNormalStartOfDay(date: string): string {
    return moment(date.split('T')[0])
      .startOf('d')
      .format('YYYY-MM-DDT00:00:00');
  }

  getNormalEndOfDay(date: string): string {
    return moment(date.split('T')[0]).endOf('d').format('YYYY-MM-DDT23:59:59');
  }

  /**
   * Calculate and return the difference between UTC and selected country timezones in hours
   * @returns a number for difference hours
   */
  getTimezoneDifference(): number {
    const now = moment.utc();
    return Math.abs(moment.tz.zone(this.timeZone).utcOffset(+now) / 60);
  }

  private addExtraHeaders(headerObj: any): void {
    headerObj['device-type'] = 'web';
    // headerObj['language-id:'] = '1';
    headerObj['app-version'] = '3.9.0';
    headerObj['x-api-key'] = environment.apiKey;
  }
}
