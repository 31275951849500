import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JcDeliverRoutingModule } from './jc-deliver-routing.module';
import { DeliveryOrdersComponent } from './components/delivery-orders/delivery-orders.component';
import { SharedModule } from '../shared/shared.module';
import { JcDelUserListComponent } from './components/user-list/jc-del-user-list.component';
import { JcDelUserProfileComponent } from './components/jc-del-user-profile/jc-del-user-profile.component';


@NgModule({
  declarations: [DeliveryOrdersComponent, JcDelUserListComponent, JcDelUserProfileComponent],
  imports: [
    CommonModule,
    SharedModule.forRoot(),
    JcDeliverRoutingModule
  ]
})
export class JcDeliverModule { }
