import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BackEndResponse } from '../models/global/response';
import { tap } from 'rxjs/operators';
import { DriverToBeSaved, DriverToBeUpdated } from '../models/cars/driver';

@Injectable({
  providedIn: 'root'
})
export class DriversService {
  readonly URL_GET_DRIVER = environment.serverUrl + 'laundry/driver'; // post
  readonly URL_CHANGE_STATUS =
    environment.serverUrl + 'laundry/change-status-driver'; // post
  readonly URL_DELETE_DRIVER = environment.serverUrl + 'laundry/delete-driver'; // post
  constructor(private http: HttpClient) {}

  getDriver(headers, id: number, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_GET_DRIVER + '/' + id, {
        headers: new HttpHeaders(headers),
        params: params ? params : null
      })
      .pipe(tap(data => console.log(JSON.stringify(data))));
  }

  addDriver(headers, driver: DriverToBeSaved) {
    return this.http
      .post<BackEndResponse>(this.URL_GET_DRIVER, driver, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(JSON.stringify(data))));
  }

  updateDriver(headers, driver: DriverToBeUpdated, driverId: number) {
    return this.http
      .post<BackEndResponse>(this.URL_GET_DRIVER + '/' + driverId, driver, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(JSON.stringify(data))));
  }

  changeStatus(headers, id: number, status: string) {
    return this.http.post<BackEndResponse>(
      this.URL_CHANGE_STATUS,
      {
        driver_id: id,
        status: status
      },
      {
        headers: new HttpHeaders(headers)
      }
    );
  }

  deleteDriver(headers, driverId: number) {
    return this.http
      .post<BackEndResponse>(
        this.URL_DELETE_DRIVER,
        {
          driver_id: driverId
        },
        {
          headers: new HttpHeaders(headers)
        }
      )
      .pipe(tap(data => console.log(JSON.stringify(data))));
  }
}
