import { PetPreference } from '@app/shared/enums';
import { Translation } from 'libs/justclean-models/src/lib/justclean-models';
import { AdvertisementArea } from '.';
export interface Ad {
  id?: number;
  name: Translation;
  image: Translation;
  link: Translation;
  type: string;
  views: number;
  clicks: number;
  maxViews: number;
  couponCode: string;
  startDate: string;
  endDate: string;
  status: string;
  totalCpd: number;
  totalCpdAndroid: number;
  totalCpdIos: number;
  totalContractCost: number;
  billTo: string;
  comment?: string;
  countryId: number;
  laundryBranchId: number;
  cwGarageBranchId?: number;
  groupId: number;
  petPreference?: PetPreference;
  genderId: number;
  hcServiceId?: number;
  hcCompanyBranchId?: number;
  advertisementAreas: AdvertisementArea[];
}
