import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { GroupHttpService } from '@shared/service/group/group-http.service';
import { ApiResponse, PageInfo } from '@shared/models/global/apiResponse';
import { Group, GroupCountry } from '@shared/models';
import { SelectOptions } from '@app/shared/models/global/response';

@Injectable({ providedIn: 'root' })
export class GroupService {
  readonly pagination$: Subject<PageInfo> = new Subject<PageInfo>();
  selectedGroup: Group;
  allGroups: Array<Group>;

  constructor(private readonly groupHttpService: GroupHttpService) {}

  getGroups(
    filters?: Record<string, string>,
    forceLoad?: boolean,
  ): Observable<Group[]> {
    if (!this.allGroups || forceLoad) {
      const toData = (res: ApiResponse<Group[]>) => {
        /** Filterout old car wash group */
        this.allGroups = res.data.filter((group) => group.id !== 2);
        return this.allGroups;
      };
      const toPagination = (res: ApiResponse<Group[]>) =>
        this.pagination$.next(res.pageInfo);

      return this.groupHttpService
        .getGroups(filters)
        .pipe(tap(toPagination), map(toData));
    }

    return of(this.allGroups.slice());
  }

  getGroupById(id: number): Observable<Group> {
    if (!this.allGroups) {
      const toData = (res: ApiResponse<Group>) => res.data;
      return this.groupHttpService.getGroupById(id).pipe(map(toData));
    }

    return of(this.allGroups.slice().find((group) => group.id === id));
  }

  createGroup(data: Partial<Group>): Observable<Group> {
    const toGroup = (res: ApiResponse<Group>) => res.data;

    return this.groupHttpService.createGroup(data).pipe(map(toGroup));
  }

  updateGroup(id: number, data: Partial<Group>): Observable<Group> {
    const toGroup = (res: ApiResponse<Group>) => res.data;

    return this.groupHttpService.updateGroup(id, data).pipe(map(toGroup));
  }

  getGroupCountries(groupId: number): Observable<GroupCountry[]> {
    return this.groupHttpService
      .getGroupCountries(groupId)
      .pipe(map((res) => res.data));
  }

  assignBulkCountries(
    data: Partial<Array<GroupCountry>>,
  ): Observable<ApiResponse<Array<GroupCountry>>> {
    return this.groupHttpService.assignBulkCountries(data);
  }

  assignCountry(
    data: Partial<GroupCountry>,
  ): Observable<ApiResponse<GroupCountry>> {
    return this.groupHttpService.assignCountry(data);
  }

  updateAssignedCountry(
    data: Partial<GroupCountry>,
  ): Observable<ApiResponse<GroupCountry>> {
    return this.groupHttpService.updateAssignedCountry(data);
  }

  removeAssignedCountry(countryId: number): Observable<ApiResponse<void>> {
    return this.groupHttpService.removeAssignedCountry(countryId);
  }

  getGroupStatuses(groupId: number): Observable<Array<SelectOptions<number>>> {
    if (groupId && !this.selectedGroup) {
      this.setSelectedGroup(groupId);
    }

    return this.groupHttpService.getGroupStatuses(
      this.checkLaundryDesign() || this.checkHomeCleaningDesign(),
      groupId,
    );
  }

  setSelectedGroup(groupId: number): void {
    this.selectedGroup = this.allGroups?.find((group) => group.id === groupId);
  }

  getDesignTypes(): Observable<Array<SelectOptions<string>>> {
    // TODO: load types from API
    const designTypes: SelectOptions<string>[] = [
      { label: 'Laundry Design', value: 'laundry' },
      { label: 'out call design', value: 'carwash' },
      { label: 'in call design (old)', value: 'carwashGarage' },
      { label: 'in call design', value: 'carwashHome' },
      { label: 'home cleaning design', value: 'homeCleaning' },
    ];
    return of(designTypes);
  }

  getCallTypes(): Observable<Array<SelectOptions<string>>> {
    // TODO: load types from API
    const callTypes: SelectOptions<string>[] = [
      { label: 'in call', value: 'inCall' },
      { label: 'out call', value: 'outCall' },
    ];
    return of(callTypes);
  }

  getSpecialityServices(): Observable<Array<SelectOptions<string>>> {
    const specialityServices: SelectOptions<string>[] = [
      { label: 'none', value: 'none' },
      { label: 'laundry', value: 'laundry' },
      { label: 'laundryUnit', value: 'laundryUnit' },
      { label: 'cw', value: 'cw' },
    ];
    return of(specialityServices);
  }

  getKeywords(): Observable<Array<SelectOptions<string>>> {
    const keywords: SelectOptions<string>[] = [
      { label: 'laundry', value: 'laundry' },
      { label: 'carwash', value: 'carwash' },
      { label: 'others', value: 'others' },
      { label: 'carwashGarage', value: 'carwashGarage' },
      { label: 'homeCleaning', value: 'homeCleaning' },
      { label: 'carWashHome', value: 'carWashHome' },
      { label: 'salon', value: 'salon' },
      { label: 'grooming', value: 'grooming' },
      { label: 'speciality', value: 'speciality' },
    ];
    return of(keywords);
  }

  checkOutCall(selectedGroup?: Group): boolean {
    return (selectedGroup || this.selectedGroup)?.callType === 'outCall';
  }

  checkInCall(selectedGroup?: Group): boolean {
    return (selectedGroup || this.selectedGroup)?.callType === 'inCall';
  }

  checkLaundryDesign(selectedGroup?: Group): boolean {
    return (selectedGroup || this.selectedGroup)?.designType === 'laundry';
  }

  checkHomeCleaningDesign(selectedGroup?: Group): boolean {
    return (selectedGroup || this.selectedGroup)?.designType === 'homeCleaning';
  }

  checkCarwashGarageDesign(selectedGroup?: Group): boolean {
    return (
      (selectedGroup || this.selectedGroup)?.designType === 'carwashGarage' ||  (selectedGroup || this.selectedGroup)?.designType === 'carwashHome'
    );
  }

  getBranchIdKey(group: Group) {
    let isCarwash = this.checkCarwashGarageDesign(group);
    let isLaundry = this.checkLaundryDesign(group);
    return isCarwash
      ? 'cwGarageBranchId'
      : isLaundry
      ? 'laundryBranchId'
      : 'hcCompanyBranchId';
  }
}
