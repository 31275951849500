import { OrderType, UpdateType } from '@app/shared/enums';

export interface GroupOrderMap {
  currency: string;
  latitude: number;
  longitude: number;
  id: number;
  branchName: string;
  currencyDecimal: number;
  updateType?: UpdateType;
  type: OrderType;
  groupId: number;
  amount: number;
  countryId: number;
  firstName: string;
  lastName: string;
  zoneName?: string;
  visible?: boolean;
}
