import { HttpParams } from '@angular/common/http';

export class ApiLibrary {
  static generateFromObject(params) {
    return new HttpParams({ fromObject: params });
  }

  cleanObject(object) {
    for (const key of Object.keys(object)) {
      if (!object[key] && object[key] !== false) {
        delete object[key];
      }
    }
    return object;
  }

  cleanObjectFromComponent(object) {
    for (const key of Object.keys(object)) {
      if (
        object[key] === undefined ||
        object[key] === 'undefined' ||
        object[key] === null ||
        object[key] === ''
      ) {
        delete object[key];
      }
    }
    return object;
  }
  cleanObjectFromComponentWithMinusValue(object) {
    for (const key of Object.keys(object)) {
      if (
        object[key] === undefined ||
        object[key] === 'undefined' ||
        object[key] === null ||
        object[key] === '' ||
        object[key] === -1
      ) {
        delete object[key];
      }
    }
    return object;
  }
}
