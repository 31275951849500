import { GroupService } from '@app/shared/service/group';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormArray,
  UntypedFormControl,
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { FormStatus } from '@shared/enums/form-status.enum';
import { CashbackCampaign, UserSegment } from '@shared/models';
import { GlobalService } from '@app/shared/service/global.service';
import { Status } from '@app/shared/enums/status.enum';
import { SelectOptions } from '@app/shared/models/global/response';
import { CashbackCampaignService } from '@app/shared/service/cashback-campaign';
import { UserSegmentService } from '@app/shared/service/user-segment';
import { IMyOptions } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-cashback-campaign-form',
  templateUrl: './cashback-campaign-form.component.html',
  styleUrls: ['./cashback-campaign-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CashbackCampaignFormComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  groups$: Observable<SelectOptions<number>[]>;
  segments$: Observable<UserSegment[]>;
  currency: string;
  STATUS = Status;
  selectedRecievableGroups: SelectOptions<number>[];
  selectedDeductibleGroups: SelectOptions<number>[];
  canAddSegment: boolean;
  canRemoveLastSegment: boolean;
  totalSegments: number;
  isLoading: boolean;
  startDateOptions: IMyOptions;
  endDateOptions: IMyOptions;

  @Input() cashbackCampaign: CashbackCampaign;
  @Output() formChanged: EventEmitter<Partial<CashbackCampaign>> =
    new EventEmitter<Partial<CashbackCampaign>>();
  @Output() formStatusChanged: EventEmitter<FormStatus> =
    new EventEmitter<FormStatus>();

  private readonly destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly cashbackCampaignService: CashbackCampaignService,
    private readonly userSegmentService: UserSegmentService,
    private readonly globalService: GlobalService,
    private readonly groupService: GroupService,
  ) {}

  ngOnInit(): void {
    this.currency = this.globalService.currency;
    this.initForm();
    this.handleFormChanges();
    this.handleFormStatusChanges();
    this.loadGroups();
    this.loadAllSegments();
    this.setDateOptions();
  }

  changeStatus(isActive: boolean) {
    const status = isActive ? Status.Active : Status.Inactive;
    this.form.get('status').setValue(status);
  }

  selectGroup(selectedGroupIDs: number[], formControlName: string): void {
    this.form
      .get(formControlName)
      .setValue(selectedGroupIDs.map((groupId) => ({ groupId })));
    this.checkFormStatus();
  }

  getGroupName(groupId: number, groups: SelectOptions<number>[]): string {
    return groups.find((group) => group.value === groupId)?.label;
  }

  addSegment(segment?: Partial<UserSegment>): void {
    const addedSegments = this.form.get('cashbackSegments');
    const newSegment: UntypedFormGroup = this.fb.group({
      cashbackPercentage: [
        segment?.cashbackPercentage || 0,
        [Validators.required, Validators.min(1), Validators.max(100)],
      ],
      orderStartRange: segment
        ? segment.orderStartRange
        : addedSegments.value.length || 0,
      orderEndRange: segment
        ? segment.orderEndRange
        : addedSegments.value.length + 1,
      status: segment?.status || Status.Active,
    });

    (this.form.get('cashbackSegments') as UntypedFormArray).push(
      new UntypedFormControl(newSegment),
    );
    this.canAddSegment = false;
    if (!segment) {
      this.checkFormStatus();
    }
  }

  checkLastSegment(): void {
    const segments = this.form.get('cashbackSegments').value;
    this.canAddSegment =
      segments[segments.length - 1]?.get('cashbackPercentage')?.value > 0;

    this.checkFormStatus();
  }

  removeLastSegment(lastIndex: number): void {
    (this.form.get('cashbackSegments') as UntypedFormArray).removeAt(lastIndex);
    this.checkLastSegment();
  }

  private initForm(): void {
    this.form = this.fb.group({
      name: this.fb.group({
        en: ['', [Validators.required]],
        ar: ['', []],
      }),
      status: [Status.Active],
      cashbackPercentage: [1, Validators.required],
      maxCashbackAmount: [1, Validators.required],
      cashbackReceivableGroups: [[], Validators.required],
      cashbackDeductibleGroups: [[], Validators.required],
      cashbackSegments: new UntypedFormArray([]),
      validityInMinutes: ['', [Validators.required]],
      userSegmentId: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
    });
    this.patchForm();
  }

  private patchForm(): void {
    if (!this.cashbackCampaign) {
      this.addSegment();
      return;
    }

    this.isLoading = true;
    this.cashbackCampaignService
      .getCashbackCampaign(this.cashbackCampaign.id)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe((campaignDetails: CashbackCampaign) => {
        this.cashbackCampaign = campaignDetails;
        this.form.patchValue(this.cashbackCampaign);
        this.cashbackCampaign.cashbackSegments.forEach((segment) =>
          this.addSegment(segment),
        );
        this.totalSegments = this.getTotalSegments();
        this.formChanged.emit(this.form.value);
      });
  }

  private handleFormChanges(): void {
    const emitValue = (value: Partial<CashbackCampaign>) => {
      value.name.ar = value.name.en;
      this.formChanged.emit(value);
    };

    this.form.valueChanges
      .pipe(debounceTime(500), tap(emitValue), takeUntil(this.destroy$))
      .subscribe();
  }

  private handleFormStatusChanges(): void {
    const emitStatus = (value: FormStatus) => {
      this.checkFormStatus();
    };

    this.form.statusChanges
      .pipe(
        startWith(this.form.status),
        distinctUntilChanged(),
        tap(emitStatus),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private checkFormStatus(): void {
    const isAllGreeterThanZero = !(
      this.form.get('cashbackSegments') as UntypedFormArray
    ).value.find(
      (segmentForm) => segmentForm.get('cashbackPercentage').invalid,
    );

    this.totalSegments = this.getTotalSegments();
    if (this.totalSegments >= 100) {
      this.canAddSegment = false;
    }

    const isAllValid =
      isAllGreeterThanZero && this.totalSegments === 100 && this.form.valid;

    this.formStatusChanged.emit(
      isAllValid ? FormStatus.Valid : FormStatus.Invalid,
    );
  }

  private getTotalSegments(): number {
    return (this.form.get('cashbackSegments') as UntypedFormArray).value.reduce(
      (total: number, segment: UntypedFormGroup) =>
        total + +segment.get('cashbackPercentage').value,
      0,
    );
  }

  private setDateOptions(): void {
    this.setStartDateOptions();
    this.setEndDateOptions();
  }

  private setStartDateOptions(): void {
    const today = new Date();

    this.startDateOptions = {
      disableUntil: {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        day: today.getDate() - 1,
      },
      closeAfterSelect: true,
    };
  }

  setEndDateOptions(startDate?: Date): void {
    const today = startDate || new Date();
    const after30Days = this.globalService
      .datePlusDays(today.toString(), 28)
      .toDate();

    this.endDateOptions = {
      disableUntil: {
        year: after30Days.getFullYear(),
        month: after30Days.getMonth() + 1,
        day: after30Days.getDate(),
      },
      closeAfterSelect: true,
      showTodayBtn: false,
    };
  }

  private loadGroups(): void {
    this.groups$ = this.groupService.getGroups().pipe(
      map((groups) =>
        groups.map((group) => ({
          value: group.id,
          label: group.name?.en,
        })),
      ),
    );
  }

  private loadAllSegments(): void {
    this.segments$ = this.userSegmentService.getAllUserSegments();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
