// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time {
  margin: 5px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/carwash/modules/carwash-packages/shared/components/carwash-package-time-wash/carwash-package-time-wash.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF","sourcesContent":[".time {\n  margin: 5px 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
