import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { JustCleanLaundryPercentage } from '../../models/global/response';

@Component({
  selector: 'app-jc-laundry-slider',
  templateUrl: './jc-laundry-slider.component.html',
  styleUrls: ['./jc-laundry-slider.component.css'],
})
export class JcLaundrySliderComponent implements OnInit, OnChanges {
  laundryPercentage = 0;
  justcleanPercentage = 100;
  @Input() reset: boolean;
  @Input() groupName: string;
  @Output() sendValues: EventEmitter<JustCleanLaundryPercentage> =
    new EventEmitter();
  constructor() {}

  ngOnInit(): void {
    this.sendValues.emit({
      laundry: Math.abs(this.laundryPercentage),
      justclean: Math.abs(this.justcleanPercentage),
    });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (this.reset) {
      console.log(simpleChanges.reset.currentValue);

      this.laundryPercentage = 0;
      this.justcleanPercentage = 100;
    }
  }

  rangeChanges(event) {
    this.laundryPercentage = 100 - event.value;
    this.justcleanPercentage = event.value;
    this.sendValues.emit({
      laundry: Math.abs(this.laundryPercentage),
      justclean: Math.abs(this.justcleanPercentage),
    });
  }
}
