import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BackEndResponse, NewBackEndResponse } from '../models/global/response';
import { map, takeUntil, tap } from 'rxjs/operators';
import {
  CustomerAddress,
  GiftToUserBody,
  ChangePasswordBody,
  ChangeUserType,
} from '../models/customer/customer';
import { ApiLibrary } from '../helpers/http-params';
import { forkJoin, Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  readonly URL_CREATE_CUSTOMER =
    environment.serverUrl + 'customer/create-customer'; // post
  readonly URL_CUSTOMER_LIST = environment.serverUrl + 'customer/list'; // get
  readonly URL_CUSTOMER_DETAILS = environment.serverUrl + 'customer/customer/'; // get
  readonly URL_CUSTOMER_DETAILS_UPDATE_PROFILE =
    environment.serverUrl + 'customer/profile'; // get
  readonly URL_DELETE_CUSTOMER = environment.serverUrl + 'customer/customer/'; // DELETE
  readonly URL_CREATE_CUSTOMER_ADDR =
    environment.jcDeliveryServer + 'user-addresses'; // POST & PUT & DELETE
  readonly URL_ORDERS = environment.serverUrl + 'customer/orders/'; // POST & PUT & DELETE
  readonly URL_CREDIT_TO_USER = environment.serverUrl + 'customer/add-credit'; // POST & PUT & DELETE
  readonly URL_SCHEDULE = environment.jcDeliveryServer + 'user-schedules'; // POST & PUT & DELETE
  readonly URL_CUSTOMER = environment.jcDeliveryServer + 'users/'; // POST & PUT & DELETE
  readonly URL_USER_TYPES = environment.jcDeliveryServer + 'user-types'; // POST & PUT & DELETE
  readonly URL_CUSTOMER_TRANSACTION_HISTORY = environment.jcDeliveryServer +  '/user-transactions/history';
  readonly URL_PAYMENT_METHOD = `${environment.jcDeliveryServer}payment-methods`
  constructor(
    private readonly http: HttpClient,
    private readonly translateService: TranslateService,
  ) {}

  getCustomers(headers, params?: any) {
    const pms = new ApiLibrary().cleanObject(params);
    return this.http
      .get<BackEndResponse>(this.URL_CUSTOMER_LIST, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: pms }),
      })
      .pipe(tap((data) => console.log(data)));
  }

  getCustomerDetails(headers, id: number, isAddress?: boolean) {
    return this.http
      .get<BackEndResponse>(this.URL_CUSTOMER_DETAILS + id, {
        headers: new HttpHeaders(headers),
        params: isAddress ? { is_address: isAddress + '' } : null,
      })
      .pipe(tap((data) => console.log(JSON.stringify(data))));
  }

  getCustomerAddresses(userId: number): Observable<CustomerAddress[]> {
    return this.http
      .get<BackEndResponse>(this.URL_CREATE_CUSTOMER_ADDR, {
        params: {
          userId,
        },
      })
      .pipe(map((res) => res.data));
  }

  updateCustomerDetails(headers, profile: { user_id: any; email: string }) {
    return this.http
      .post<BackEndResponse>(
        this.URL_CUSTOMER_DETAILS + profile.user_id,
        profile,
        {
          headers: new HttpHeaders(headers),
        },
      )
      .pipe(tap((data) => console.log(data)));
  }

  createCustomerAddress(headers, address: CustomerAddress) {
    return this.http
      .post<BackEndResponse>(this.URL_CREATE_CUSTOMER_ADDR, address, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  updateCustomerAddress(headers, address: CustomerAddress, addressId: number) {
    return this.http
      .patch<BackEndResponse>(
        this.URL_CREATE_CUSTOMER_ADDR + '/' + addressId,
        address,
        {
          headers: new HttpHeaders(headers),
        },
      )
      .pipe(tap((data) => console.log(data)));
  }

  deleteCustomerAddress(
    headers,
    customer: {
      id: number;
      user_id: number;
    },
  ) {
    return this.http
      .request<BackEndResponse>('delete', this.URL_CREATE_CUSTOMER_ADDR, {
        body: customer,
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  getOrders(headers, customerId, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_ORDERS + customerId, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(tap((data) => console.log(data)));
  }

  getUserTypes(headers) {
    return this.http
      .get<BackEndResponse>(this.URL_USER_TYPES, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  updatePassword(headers, id: string, params: ChangePasswordBody) {
    return this.http
      .patch<BackEndResponse>(this.URL_CUSTOMER + id, params, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  updateUserType(headers, id: number, params: ChangeUserType) {
    return this.http
      .patch<BackEndResponse>(this.URL_CUSTOMER + id, params, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  giveCreditToUser(headers, body: GiftToUserBody) {
    return this.http
      .post<BackEndResponse>(this.URL_CREDIT_TO_USER, body, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  getCustomerSchedules(headers, params?: any) {
    const pms = new ApiLibrary().cleanObject(params);
    return this.http
      .get<NewBackEndResponse>(this.URL_SCHEDULE, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: pms }),
      })
      .pipe(tap((data) => console.log(data)));
  }

  deleteCustomerSchedules(headers, scheduleId: number) {
    return this.http
      .delete<NewBackEndResponse>(this.URL_SCHEDULE + '/' + scheduleId, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  updateCustomerSchedules(headers, scheduleId: number, body: any) {
    return this.http
      .patch<NewBackEndResponse>(this.URL_SCHEDULE + '/' + scheduleId, body, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  getFullAddress(userAddress: any, destroy$: Subject<any>): Observable<string> {
    if (!userAddress) {
      return of('');
    }

    const getValidValues = (key: string, value: string | number) =>
      value && value !== 'undefined' ? `${key} ${value}, ` : '';

    return this.translateService.get('CUSTOMER_PROFILE.ADDRESS_DATA').pipe(
      takeUntil(destroy$),
      map((keys) => {
        const {
          block,
          floor,
          apartment_no,
          apartmentNo,
          street,
          house_no,
          houseNo,
          map_address_details,
          mapAddressDetails,
          directions,
          userAddressesContacts,
        } = userAddress;
        const addressBlock = getValidValues(keys.BLOCK, block);
        const addressStreet = getValidValues(keys.STR, street);
        const addressBuilding = getValidValues(
          keys.BUILDING,
          house_no || houseNo,
        );
        const addressApartment = getValidValues(
          keys.APARTMENT,
          apartment_no || apartmentNo,
        );
        const addressFloor = getValidValues(keys.FLOOR, floor);
        const mapAddress = getValidValues(
          '',
          map_address_details || mapAddressDetails,
        );
        const addressDirection = getValidValues('', directions);

        let fullAddress = `${addressBlock}${addressStreet}${addressBuilding}${addressApartment}${addressFloor}${mapAddress}${addressDirection}`;
        if (userAddressesContacts?.length) {
          userAddressesContacts.forEach((contact, index) => {
            fullAddress = `${fullAddress}Phone${
              userAddressesContacts.length > 1 ? `_${index + 1}` : ''
            }: ${contact.phoneExt}-${contact.phone}, `;
          });
        }
        /** Remove last ', ' */
        return fullAddress.substring(0, fullAddress.length - 2);
      }),
    );
  }

  getCustomerOrderAnalytics(headers, id: number) {
    return this.http
      .get<BackEndResponse>(this.URL_CUSTOMER + id + '/order-analytics', {
        headers: new HttpHeaders(headers),
      })
      .pipe(
        map((response) => response.data),
        // tap((data) => console.log(JSON.stringify(data?.data)))
      );
  }

  getCustomerBalance(headers, id: number) {
    return this.http
      .get<BackEndResponse>(this.URL_CUSTOMER + id + '/credit-balance', {
        headers: new HttpHeaders(headers),
      })
      .pipe(
        map((response) => response.data),
        // tap((data) => console.log(JSON.stringify(data?.data)))
      );
  }

  getMappedTransactions(headers, userId: number, pageNo: number) {
    return forkJoin({
      transactions: this.getCustomerTransactions(headers, userId, pageNo),
      paymentMethods: this.getPaymentMethod(headers, userId)
    }).pipe(
      map(({ transactions, paymentMethods }) => {
        return transactions.map((transaction) => {
          const matchedPaymentMethod = paymentMethods.find(
            (paymentMethod) => paymentMethod.id === transaction.paymentMethodId
          );

          return {
            ...transaction,
            paymentMethod: matchedPaymentMethod ? matchedPaymentMethod : null // Include full object
          };
        });
      })
    );
  }


  getPaymentMethod(headers, id: number) {
    return this.http
      .get<BackEndResponse>(this.URL_PAYMENT_METHOD, {
        headers: new HttpHeaders(headers),
      })
      .pipe(
        map((response) => response.data),
      );
  }

  getCustomerTransactions(headers, userId: number, pageNo: number) {
    let params = new HttpParams()
      .set('countryId', headers.country_id.toString())
      .set('userId', userId.toString())
      .set('pageNo', pageNo.toString());

    return this.http
      .get<BackEndResponse>(this.URL_CUSTOMER_TRANSACTION_HISTORY, {
        headers: new HttpHeaders(headers),
        params: params
      })
      .pipe(
        map((response) => response.data),
        // tap((data) => console.log(JSON.stringify(data?.data)))
      );
  }

  getCustomerVerticalOrderCount(headers, id: number) {
    return this.http
      .get<BackEndResponse>(this.URL_CUSTOMER + id + '/vertical-order-count', {
        headers: new HttpHeaders(headers),
      })
      .pipe(
        map((response) => response.data),
        // tap((data) => console.log(JSON.stringify(data?.data)))
      );
  }

}
