// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge--size {
  width: 350px;
}
.badge-types-size {
  width: 350px;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/order/components/compensate-order/compensate-order.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd","sourcesContent":[".badge--size {\n  width: 350px;\n}\n.badge-types-size {\n  width: 350px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
