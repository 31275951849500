export enum CarwashTicketWashTime {
  quarter = 15,
  half = 30,
  halfAndQuarter = 45,
  one = 60,
  oneQuarter = 75,
  oneHalf = 90,
  oneHalfAndQuarter = 105,
  two = 120,
  twoQuarter = 135,
  twoHalf = 150,
  twoHalfAndQuarter = 165,
  three = 180,
  threeQuarter = 195,
  threeHalf = 210,
  threeHalfAndQuarter = 225,
  four = 240,
  fourQuarter = 255,
  fourHalf = 270,
  fourHalfAndQuarter = 285,
  five = 300,
  fiveQuarter = 315,
  fiveHalf = 330,
  fiveHalfAndQuarter = 345,
  six = 360,
}
