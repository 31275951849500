const mobileTicketStatuses = [
  {
    id: 1,
    name: 'Valid',
    status_cw_garage_branch_ticket_id: 1,
    language_id: 1,
  },
  {
    id: 2,
    name: 'expired',
    status_cw_garage_branch_ticket_id: 2,
    language_id: 1,
  },
  {
    id: 3,
    name: 'redeemed',
    status_cw_garage_branch_ticket_id: 3,
    language_id: 1,
  },
];

const ticketStatuses = [
  {
    id: 9,
    name: 'placed',
    status_cw_garage_branch_ticket_id: 9,
    language_id: 1,
  },
  {
    id: 10,
    name: 'on the way',
    status_cw_garage_branch_ticket_id: 10,
    language_id: 1,
  },
  {
    id: 11,
    name: 'job started',
    status_cw_garage_branch_ticket_id: 11,
    language_id: 1,
  },
  {
    id: 12,
    name: 'job finished',
    status_cw_garage_branch_ticket_id: 12,
    language_id: 1,
  },
  {
    id: 13,
    name: 'canceled',
    status_cw_garage_branch_ticket_id: 13,
    language_id: 1,
  },
];

const jobStatuses = [
  {
    id: 17,
    name: 'New job request',
    status_cw_garage_branch_ticket_id: 17,
    language_id: 1,
  },
  {
    id: 18,
    name: 'Acknowledged',
    status_cw_garage_branch_ticket_id: 18,
    language_id: 1,
  },
  {
    id: 19,
    name: 'On the way',
    status_cw_garage_branch_ticket_id: 19,
    language_id: 1,
  },
  {
    id: 20,
    name: 'Job Started',
    status_cw_garage_branch_ticket_id: 20,
    language_id: 1,
  },
  {
    id: 21,
    name: 'Job Finished',
    status_cw_garage_branch_ticket_id: 21,
    language_id: 1,
  },
  {
    id: 22,
    name: 'Job canceled',
    status_cw_garage_branch_ticket_id: 22,
    language_id: 1,
  },
];

export const getTicketStatuses = (
  isMobileType: boolean,
  isHomeCleaning?: boolean,
) => {
  return (
    isHomeCleaning
      ? jobStatuses
      : isMobileType
      ? mobileTicketStatuses
      : ticketStatuses
  ).map((status) => ({
    value: String(status.status_cw_garage_branch_ticket_id),
    label: status.name,
    disabled: false,
    icon: '',
  }));
};
