import { AnotherAction, APIConfig, BottomGroup, FieldConfig } from '.';

export interface Crud {
  id: number;
  pageName: string;
  menuNamePrefix?: string;
  parentMenuItemId?: number | string;
  isHideFromMenu: boolean;
  isActive: boolean;
  isSuperAdmin: boolean;
  canCreate: boolean;
  canCreateSA: boolean;
  canUpdate: boolean;
  canUpdateSA: boolean;
  canDelete: boolean;
  canDeleteSA: boolean;
  otherActions: Array<AnotherAction>;
  apiConfig: APIConfig;
  customAPIConfigs?: {
    POST: APIConfig | string;
    PATCH: APIConfig | string;
    DELETE: APIConfig | string;
  };
  dataModel: Array<FieldConfig>;
  bottomGroupedFields?: Array<BottomGroup>;
  /** This is just a workaround to control permission emails list through admin */
  permissionsConfig?: {[key: string]: string};
}
