import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomecleanCompanyRoutingModule } from '@app/modules/homeclean/modules/homeclean-company/homeclean-company-routing.module';
import { HomecleanCompanyListComponent } from './components/homeclean-company-list/homeclean-company-list.component';
import { SharedModule } from '@app/modules/homeclean/shared/shared.module';
import { HomecleanCompanyEditDialogComponent } from '@app/modules/homeclean/modules/homeclean-company/components/homeclean-company-edit-dialog/homeclean-company-edit-dialog.component';
import { HomecleanCompanyFormComponent } from '@app/modules/homeclean/modules/homeclean-company/components/homeclean-company-edit-dialog/homeclean-company-form/homeclean-company-form.component';

@NgModule({
  declarations: [
    HomecleanCompanyListComponent,
    HomecleanCompanyEditDialogComponent,
    HomecleanCompanyFormComponent,
  ],
  imports: [CommonModule, HomecleanCompanyRoutingModule, SharedModule],
})
export class HomecleanCompanyModule {}
