// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-row {
  font-weight: bold;
}

.file-field {
  margin-bottom: 25px;
}

:host::ng-deep .mdb-select-outline .mdb-select-value {
  padding: 0.375rem 0.75rem;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/carwash/modules/carwash-company/components/carwash-company-edit-dialog/carwash-company-form/carwash-company-form.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,yBAAA;AACF","sourcesContent":[".title-row {\n  font-weight: bold;\n}\n\n.file-field {\n  margin-bottom: 25px;\n}\n\n:host::ng-deep .mdb-select-outline .mdb-select-value {\n  padding: 0.375rem 0.75rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
