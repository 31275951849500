// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `thead, th {
  width: 100%;
}
tr, td {
  width: 80%;
}

`, "",{"version":3,"sources":["webpack://./src/app/modules/finance/components/finance-detailed-report/finance-detailed-report.component.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE,UAAU;AACZ","sourcesContent":["thead, th {\n  width: 100%;\n}\ntr, td {\n  width: 80%;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
