import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { CarwashStation } from '@app/modules/carwash/modules/carwash-station/shared/model/carwash-station.model';
import { environment } from '@env/environment';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { Staff } from '@app/shared/models';

@Injectable({ providedIn: 'root' })
export class CarwashStationHttpService {
  private readonly url =
    environment.jcDeliveryServer + 'cw-garage-branch-stations';
  private readonly urlGetStaffList = environment.jcDeliveryServer + 'users-jlm';

  constructor(private readonly http: HttpClient) {}

  getStations(
    cwGarageBranchId: number,
  ): Observable<ApiResponse<CarwashStation[]>> {
    return this.http.get<ApiResponse<CarwashStation[]>>(this.url, {
      params: { cwGarageBranchId } as any,
    });
  }

  createStations(
    branchId: number,
    data: Partial<CarwashStation>,
  ): Observable<CarwashStation> {
    return this.http.post<CarwashStation>(this.url, {
      ...data,
      cwGarageBranchId: branchId,
    });
  }

  updateStations(
    id: number,
    data: Partial<CarwashStation>,
  ): Observable<ApiResponse<CarwashStation>> {
    return this.http.patch<ApiResponse<CarwashStation>>(
      `${this.url}/${id}`,
      data,
    );
  }

  removeStations(id: number): Observable<ApiResponse<any>> {
    return this.http.delete<ApiResponse<any>>(`${this.url}/${id}`);
  }

  getStaffList(cwGarageBranchId: number): Observable<ApiResponse<Staff[]>> {
    return this.http.get<ApiResponse<Staff[]>>(this.urlGetStaffList, {
      params: { cwGarageBranchId } as any,
    });
  }
}
