import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BackEndResponse } from '../models/global/response';
import { tap } from 'rxjs/operators';
import {
  LogisticsDriverToSave,
  CapacityToBeSaved,
  DriverAreaToBeSaved
} from '../models/cars/log-driver';
import { OrderAssignmentBody } from '../models/cars/partners';

@Injectable({
  providedIn: 'root'
})
export class LogDriverService {
  readonly URL_FETCH_DRIVERS_FOR_ASSIGN =
    environment.serverUrl + 'logistics/get-driver/';
  readonly URL_FETCH_DRIVERS_BY_BRANCH =
    environment.serverUrl + 'logistics/driver/';
  readonly URL_FETCH_ALL_LOGISTICS_DRIVERS =
    environment.serverUrl + 'logistics/driver-list';
  readonly URL_SAVE_UPDATE_DRIVER = environment.serverUrl + 'logistics/driver';
  readonly URL_CAR = environment.serverUrl + 'logistics/car';
  readonly URL_DAYS = environment.serverUrl + 'logistics/driver/days/';
  readonly URL_CAPACITY = environment.serverUrl + 'logistics/capacity/';
  readonly URL_AREA = environment.serverUrl + 'logistics/drivers/areas';
  readonly URL_AVAILABLE_DRIVERS_FOR_ASSIGN =
    environment.serverUrl + 'partners';
  readonly URL_LOG_DRIVERS = environment.serverUrl + 'partners/driver';
  readonly URL_QUESTIONS = environment.serverUrl + 'partners/answers';
  readonly URL_ASSIGN_ORDER = environment.serverUrl + 'partners/change';

  constructor(private http: HttpClient) {}

  getDriversForAssignement(headers, branchId, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_FETCH_DRIVERS_FOR_ASSIGN + branchId, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params })
      })
      .pipe(tap(data => console.log(data)));
  }

  getDriversPerLogBranch(headers, branchId, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_FETCH_DRIVERS_BY_BRANCH + branchId, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params })
      })
      .pipe(tap(data => console.log(data)));
  }

  assignDrivers(headers, branchId: number, body: any) {
    return this.http
      .post<BackEndResponse>(
        this.URL_FETCH_DRIVERS_BY_BRANCH + branchId,
        body,
        {
          headers: new HttpHeaders(headers)
        }
      )
      .pipe(tap(data => console.log(data)));
  }

  makeDriverLogistics(headers, driverId: number) {
    return this.http
      .put<BackEndResponse>(
        this.URL_FETCH_DRIVERS_BY_BRANCH + driverId,
        {},
        {
          headers: new HttpHeaders(headers)
        }
      )
      .pipe(tap(data => console.log(data)));
  }

  getAllDriverForLogistics(headers, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_FETCH_ALL_LOGISTICS_DRIVERS, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params })
      })
      .pipe(tap(data => console.log(data)));
  }

  saveLogisticsDriver(headers, driver: LogisticsDriverToSave) {
    return this.http
      .post<BackEndResponse>(this.URL_SAVE_UPDATE_DRIVER, driver, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  getCarsForLogistics(headers, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_CAR, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params })
      })
      .pipe(tap(data => console.log(data)));
  }

  getDaysForCapacity(headers, idDriver: number) {
    return this.http
      .get<BackEndResponse>(this.URL_DAYS + idDriver, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  saveCapacity(headers, idDriver: number, capacity: CapacityToBeSaved) {
    return this.http
      .post<BackEndResponse>(this.URL_CAPACITY + idDriver, capacity, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  changeStatusLogisticDriver(headers, driverId: number, body: any) {
    return this.http
      .patch<BackEndResponse>(
        this.URL_SAVE_UPDATE_DRIVER + '/' + driverId,
        body,
        {
          headers: new HttpHeaders(headers)
        }
      )
      .pipe(tap(data => console.log(data)));
  }

  getDriversArea(headers, idDriver: number, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_AREA + '/' + idDriver, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params })
      })
      .pipe(tap(data => console.log(data)));
  }

  saveDriverAreas(headers, areas: DriverAreaToBeSaved) {
    return this.http
      .post<BackEndResponse>(this.URL_AREA, areas, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  getAvailablePartnerForAssignment(headers, orderId: number) {
    return this.http
      .get<BackEndResponse>(
        this.URL_AVAILABLE_DRIVERS_FOR_ASSIGN + '/' + orderId,
        {
          headers: new HttpHeaders(headers)
        }
      )
      .pipe(tap(data => console.log(data)));
  }

  getAvailableDriverForAssignment(headers, orderId: number) {
    return this.http
      .get<BackEndResponse>(
        this.URL_LOG_DRIVERS + '/' + orderId,
        {
          headers: new HttpHeaders(headers)
        }
      )
      .pipe(tap(data => console.log(data)));
  }

  getQuestionRelatedToAssignment(headers, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_QUESTIONS, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params })
      })
      .pipe(tap(data => console.log(data)));
  }

  assignReassignOrder(headers, body: OrderAssignmentBody) {
    return this.http
      .post<BackEndResponse>(this.URL_ASSIGN_ORDER, body, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }
}
