export class ItemTranslation {
  language_id: number;
  name: string;
  description: string;
  image: string;
}

export class CategoryArray {
  cat_id: number;
  is_deleted: boolean;
}

export class ItemToBeSaved {
  id?: number;
  alt_tag: string;
  image_seo: string;
  image: string;
  is_taxable: boolean;
  item_translations: ItemTranslation[];
  category_array: CategoryArray[];
  constructor() {
    this.item_translations = [];
    this.category_array = [];
  }
}

export class ItemCatRel {
  category_master_id: number;
  item_master_id: number;
}

export class CategoryMaster {
  id: number;
  cat_name: string;
  item_cat_rel: ItemCatRel;
}

export class NameDesc {
  name: string;
  description: string;
  image: string;
}

export class Name {
  en: NameDesc;
  ar: NameDesc;
}

export class Item {
  id: number;
  item_name: string;
  alt_tag: string;
  item_seo_image: string;
  item_image: string;
  is_taxable: boolean;
  group_id: number;
  status: string;
  category_masters: CategoryMaster[];
  name: Name;
}

export class ItemCategories {
  category_id: number;
  item_id: number;
}

export class CategoryOfItem {
  id: number;
  name: string;
  item_categories: ItemCategories;
}

export class ItemToBeUpdated {
  id: number;
  alt_tag: string;
  item_seo_image: string;
  item_image: string;
  is_taxable: boolean;
  item_translations: ItemTranslation[];
  category_array: CategoryArray[];
}

export class NameList {
  ar: NameDesc;
  en: NameDesc;
}

export class CategoryInItem {
  id: number;
  name: string;
  item_categories: ItemCategories;
}

export class ItemFromListItem {
  id: number;
  name: Name;
  alt_tag: string;
  image_seo: string;
  image: string;
  is_taxable: boolean;
  group_id: number;
  status: string;
  sort_order: number;
  is_corporate: boolean;
  categories: CategoryInItem[];
  icon?: string;
  is_market_place: boolean;
  is_pos: boolean;
}

export interface ItemChangePositionBody {
  next_position: number;
  current_position: number;
  item_id: number;
}
