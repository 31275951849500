// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ad-modal-container {
  max-width: inherit;
  min-width: 500px;
  width: 80vw;
}
.ad-modal-container.is-marketing {
  width: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/ads/components/ad-edit-dialog/ad-edit-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,WAAA;AACF;AAAE;EACE,WAAA;AAEJ","sourcesContent":[".ad-modal-container {\n  max-width: inherit;\n  min-width: 500px;\n  width: 80vw;\n  &.is-marketing {\n    width: auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
