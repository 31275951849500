import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SupplierRoutingModule } from './supplier-routing.module';
import { CreateSupplierComponent } from './components/create-supplier/create-supplier.component';
import { ListSupplierComponent } from './components/list-supplier/list-supplier.component';
import { SharedModule } from '../shared/shared.module';
import { AssignAreaSupplierComponent } from './components/assign-area-supplier/assign-area-supplier.component';

@NgModule({
  declarations: [CreateSupplierComponent, ListSupplierComponent, AssignAreaSupplierComponent],
  imports: [CommonModule, SharedModule.forRoot(), SupplierRoutingModule]
})
export class SupplierModule {}
