import { NgModule } from '@angular/core';
import { UsersComponent } from './users.component';
import { CreateUsersComponent } from './components/create-users/create-users.component';
import { SharedModule } from '../shared/shared.module';
import { Routes, RouterModule } from '@angular/router';
import { ListUserComponent } from './components/list-user/list-user.component';
import { UserCarwashFormComponent } from './components/user-carwash-form/user-carwash-form.component';
import { UserHomeCleaningFormComponent } from './components/user-home-cleaning-form/user-home-cleaning-form.component';
import { UserAssignBranchComponent } from '@app/modules/users/components/user-assign-branch/user-assign-branch.component';

export const appRoutes: Routes = [
  {
    path: 'create-users/:id',
    component: CreateUsersComponent,
    data: {
      title: 'Create User',
    },
  },
  {
    path: 'list-users',
    component: ListUserComponent,
    data: {
      title: 'List of User',
    },
  },
];
@NgModule({
  imports: [SharedModule.forRoot(), RouterModule.forChild(appRoutes)],
  declarations: [
    UsersComponent,
    CreateUsersComponent,
    ListUserComponent,
    UserCarwashFormComponent,
    UserHomeCleaningFormComponent,
    UserAssignBranchComponent,
  ],
})
export class UsersModule {}
