import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FinanceRoutingModule } from './finance-routing.module';
import { OnlinePaymentChargesComponent } from './components/online-payment-charges/online-payment-charges.component';
import { SharedModule } from '../shared/shared.module';
import { CloseMonthlyOrdersComponent } from './components/close-monthly-orders/close-monthly-orders.component';
import { LaundryBillingComponent } from './components/laundry-billing/laundry-billing.component';
import { FinanceDetailedReportComponent } from './components/finance-detailed-report/finance-detailed-report.component';
import { ExportInvoicesComponent } from './components/export-invoices/export-invoices.component';
import { InvoiceTemplateComponent } from './components/invoice-template/invoice-template.component';
import { ProviderCommissionComponent } from './components/provider-commission/provider-commission.component';

@NgModule({
  declarations: [
    OnlinePaymentChargesComponent,
    CloseMonthlyOrdersComponent,
    LaundryBillingComponent,
    FinanceDetailedReportComponent,
    ExportInvoicesComponent,
    InvoiceTemplateComponent,
    ProviderCommissionComponent,
  ],
  imports: [CommonModule, FinanceRoutingModule, SharedModule.forRoot()],
})
export class FinanceModule {}
