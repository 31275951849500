// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.schedule-box {
  border-radius: 5px;
  height: 80px;
}

.order-card {
  height: 350px;
}

.closed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  opacity: 0.5;
  background-color: grey;
  font-size: 8rem;
  text-align: center;
}

.tag-badge {
  padding: 2px 10px;
  border-radius: 16px;
  display: inline-block;
}
.tag-badge img {
  width: 14px;
  height: auto;
}

.btn-action {
  border-radius: 8px;
  padding: 14px 16px;
  border: solid 1px #1c283b !important;
  background-color: #ffffff;
  font-weight: 600;
  color: #1c283b;
}
.btn-action img {
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/order/components/order-details/order-details.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,eAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;EACA,sBAAA;EACA,eAAA;EACA,kBAAA;AACF;;AAEA;EACE,iBAAA;EACA,mBAAA;EACA,qBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ;;AAGA;EACE,kBAAA;EACA,kBAAA;EACA,oCAAA;EACA,yBAAA;EACA,gBAAA;EACA,cAAA;AAAF;AAEE;EACE,qBAAA;AAAJ","sourcesContent":[".schedule-box {\n  border-radius: 5px;\n  height: 80px;\n}\n\n.order-card {\n  height: 350px;\n}\n\n.closed {\n  position: fixed;\n  left: 0;\n  top: 0;\n  width: 100%;\n  min-height: 100%;\n  opacity: 0.5;\n  background-color: grey;\n  font-size: 8rem;\n  text-align: center;\n}\n\n.tag-badge {\n  padding: 2px 10px;\n  border-radius: 16px;\n  display: inline-block;\n\n  img {\n    width: 14px;\n    height: auto;\n  }\n}\n\n.btn-action {\n  border-radius: 8px;\n  padding: 14px 16px;\n  border: solid 1px #1c283b !important;\n  background-color: #ffffff;\n  font-weight: 600;\n  color: #1c283b;\n\n  img {\n    display: inline-block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
