import { IdName } from './cars';

export class Laundry {
  name: string;
}

export class LaundryBranch {
  name: string;
  laundry: Laundry;
}

export class Area {
  name: string;
}

export class CarDriverRelation {
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  car_detail_id: number;
  driver_id: number;
}

export class CarDetail {
  name: string;
  car_driver_relation: CarDriverRelation;
}

export class Language {
  iso_code: string;
}

export class DriverTranslation {
  id: number;
  first_name: string;
  last_name: string;
  language_id: number;
  language: Language;
}

export class LogDriverFromList {
  id: number;
  first_name: string;
  last_name: string;
  mobile_no: string;
  score: number;
  capacity: number;
  pin_no: number;
  role?: any;
  car_for: string;
  token: string;
  status: string;
  is_logistics: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  laundry_branch_id: number;
  area_id: number;
  laundry_branch: LaundryBranch;
  area: Area;
  car_details: CarDetail[];
  driver_translations: DriverTranslation[];
}

export class DriversForAssign {
  id: number;
  first_name: string;
  last_name: string;
  is_selected: boolean;
}

export class DriverTranslationForAll {
  language_id: number;
  first_name: string;
  last_name: string;
}

export class CarDetails {
  id: number;
  name: string;
}

export class DriverFromAllList {
  id: number;
  first_name: string;
  last_name: string;
  mobile_no: string;
  car_for: string;
  pin_no: number;
  score: number;
  status: string;
  car_id: number;
  driver_translations: DriverTranslationForAll[];
  car_details: CarDetails;
  laundries: string[];
  branch_count: number;
  is_active: boolean;
  active_task: number;
  capacity: number;
  completed_task: number;
  areas: IdName[];
  is_shift_started:  boolean;
}

export class LogisticsDriverToSave {
  id: number;
  first_name: string;
  last_name: string;
  mobile_no: string;
  car_id: number;
  car_for: string;
  driver_translations: DriverTranslationForAll[];
}

export class DaysCapacity {
  timeslot_day_id: number;
  day_name: string;
  is_active: boolean;
  capacity: number;
}

export class CapacityToBeSaved {
  tasks: DaysCapacity[];
  constructor(days: DaysCapacity[]) {
    this.tasks = days;
  }
}

export class LogDriverAreas {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  status: string;
  country_id: number;
  governorate_id: number;
  governorateId: number;
  is_selected: boolean;
}

export class LogDriverArea {
  area_id: number;
  is_selected: boolean;
}

export class DriverAreaToBeSaved {
  driver_id: number;
  driverAreas: LogDriverArea[];
}
