import { Name } from '../items/cats';

export class BranchToBeSaved {
  laundry_id: number;
  name: string;
  manager: string;
  governorate_id: number;
  area_id: number;
  mobile: string;
  alternate_mobile?: string;
  email: string;
  no_of_drivers: number;
  latitude: number;
  longitude: number;
  address: string;
}

export class BranchInfo {
  id: number;
  code: string;
  name: string;
  manager: string;
  mobile: string;
  alternate_mobile?: string;
  email: string;
  no_of_drivers: number;
  latitude: number;
  longitude: number;
  address: string;
}
export class BranchList {
  id: number;
  code: string;
  name: string;
  manager: string;
  mobile: string;
  email: string;
  no_of_drivers: number;
  latitude: number;
  longitude: number;
  address: string;
  rating_value: number;
  rating_count: number;
  status?: string;
  has_pos_status: boolean;
  has_tracking: boolean;
  is_logistics: boolean;
  has_discount: boolean;
  is_corporate: boolean;
  is_partner: boolean;
  has_corporate_contract: boolean;
  has_active_contract: boolean;
  laundry_id: number;
  country_id: number;
}

export class BranchWise {
  id: number;
  code: string;
  name: string;
}

export class StatusesUser {
  id: number;
  name: string;
}

export class BranchUser {
  id: number;
  first_name: string;
  last_name: string;
}

export class OrderOfBranch {
  id: number;
  unique_id: string;
  item_count: number;
  bill_amount: number;
  is_paid: boolean;
  placed_at: Date;
  statuses_user: StatusesUser;
  user: BranchUser;
}

export class BranchOrderData {
  id: number;
  unique_id: string;
  item_count: number;
  item_merchandise_count: number;
  bill_amount_merchandise: number;
  bill_amount_total: number;
  is_paid: boolean;
  placed_at: Date;
  type: string;
  updated_at: Date;
  is_fast_service: boolean;
  is_tracking_enabled: boolean;
  pickup_at: Date;
  delivery_at: Date;
  delivery_charge: number;
  service_charge: number;
  extra_charges: number;
  extra_discounts: number;
  special_instruction: string;
}

export class Item {
  id: number;
  name: string;
}

export class OrderBasketService {
  id: number;
  name: string;
}

export class OrderBasket {
  id: number;
  unit_price: number;
  quantity: number;
  total_price: number;
  item: Item;
  service: OrderBasketService;
}

export class BranchOrderDetails {
  order_data: BranchOrderData;
  order_basket: OrderBasket[];
}

export class AreaArray {
  area_id: number;
  is_active: boolean;
}

export class BranchArea {
  branch_id: number;
  areaArray: AreaArray[];
}

export class NewBranchArea {
  laundry_branch_id: number;
  cw_garage_branch_id: number;
  laundry_branch_areas: BrArea[];
  delivery_charge?: number;
  delivery_charge_threshold?: number;
  delivery_charge_after_threshold?: number;
  service_charge?: number;
  service_charge_threshold?: number;
  service_charge_after_threshold?: number;
}

export class BrArea {
  id: number;
  name: string | {en: string, ar: string};
  latitude: number;
  longitude: number;
  status: string;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  country_id: number;
  governorate_id: number;
  amount_minimum_order: number;
  amount_delivery_charge: number;
  amount_service_charge: number;
  amount_delivery_charge_threshold: number;
  amount_service_charge_threshold: number;
  amount_delivery_charge_after_threshold: number;
  amount_service_charge_after_threshold: number;
  is_selected: boolean;
}

export class BranchSettings {
  laundry_branch_id: number;
  special_service_leather: boolean;
  special_service_alteration: boolean;
  special_service_shoes: boolean;
  just_premium: number;
  delivery_charge: number;
  delivery_service: number;
  min_order_amount: number;
  fast_turn_around: number;
  normal_turn_around: number;
  premium_turn_around: number;
  payment_method: number[];
  has_tracking: boolean;
  is_logistics: boolean;
  has_pos_status: boolean;
  has_fast_service: boolean;
  translations?: Translation[];
}

export class Translation {
  language_id: number;
  content: string;
}

export class Timeslot {
  timeslot_id: number;
  is_deleted: boolean;
}

export class LaundryTimeSLotsToBeSaved {
  laundry_branch_id: number;
  timeslot: Timeslot[];
}

export class LaundryTimeslot {
  is_busy: boolean;
  capacity: number;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  laundry_branch_id: number;
  timeslot_time_id: number;
}

export class TimeslotTime {
  id: number;
  time: string;
  status: string;
  created_at: Date;
  updated_at: Date;
  laundry_timeslots: LaundryTimeslot[];
}

export class BranchTimeSlot {
  id: number;
  day_name: string;
  status: string;
  created_at: Date;
  updated_at: Date;
  timeslot_times: TimeslotTime[];
}

export interface TimeslotTime {
  id: number;
  time: string;
  status: string;
}

export class DayTimeSLot {
  id: number;
  day_name: string;
  status: string;
  timeslot_times?: TimeslotTime[];
}

export class UniqTimeSlot {
  laundry_branch_id: number;
  timeslot_time_id: number;
  is_busy: boolean;
  remaining_capacity: number;
  default_capacity: number;
  previous_capacity: number;
  is_selected: boolean;
}

export class ItemPrice {
  item_id: number;
  normal_dryclean: number;
  normal_dryandwash: number;
  normal_iron: number;
  fast_dryclean: number;
  fast_dryandwash: number;
  fast_iron: number;
  itemMap?: NamePicture;
}

export class ItemPriceToBeAdd {
  laundry_branch_id: number;
  item_price: ItemPrice[];
}

export class IdNameObject {
  id: number;
  name: string;
}
export class NamePicture {
  name: string;
  picture: string;
}

export class ItemList {
  id: number;
  normal_dryclean: number;
  normal_washandiron: number;
  normal_iron: number;
  fast_dryclean: number;
  fast_washandiron: number;
  fast_iron: number;
  item: IdNameObject;
}

export class Times {
  id: number;
  start: string | Date;
  end: string | Date;
  is_busy: boolean;
  default_capacity: number;
  previous_capacity: number;
  remaining_capacity: number;
  is_selected: boolean;
}

export class BranchTimeSlotNew {
  id: number;
  day: string;
  date: Date;
  times: Times[];
}

export class BranchTimeSlotToBeSavedNew {
  laundry_branch_id: number;
  timeslots: BranchTimeSlotNew[];
}

export class LaundryBranchArea {
  id: number;
  sort_order: number;
  laundry_branch_id: number;
  area_id: number;
}

export class BranchPerArea {
  id: number;
  code: string;
  name: string;
  laundry_id: number;
  area_id: number;
  laundry_branch_areas: LaundryBranchArea[];
}

export class BranchPosition {
  areaId?: number;
  laundryBranchId?: number;
  cwGarageBranchId?: number;
  fromPosition: number;
  toPosition: number;
}

export class Translations {
  en: string;
  ar: string;
  fr: string;
}

export class BranchToBeUpdated {
  id: number;
  code: string;
  name: string;
  manager: string;
  mobile: string;
  alternate_mobile?: string;
  email: string;
  no_of_drivers: number;
  latitude: number;
  longitude: number;
  address: string;
  has_tracking: boolean;
  has_pos_status: boolean;
  is_logistics: boolean;
  laundry_bag: boolean;
  area_id: number;
  governorate_id: number;
  spcl_service_leather: boolean;
  spcl_service_alteration: boolean;
  spcl_service_shoes: boolean;
  just_premium: boolean;
  has_discount: boolean;
  has_fast_service: boolean;
  delivery_charge: number;
  delivery_service: number;
  min_order_amount: number;
  fast_turn_around: number;
  normal_turn_around: number;
  premium_turn_around: number;
  payment_methods: number[];
  translations: Translations;
  special_branch_icons: number[];
  is_online_transaction_charge: boolean;
  is_free_jccredit_charge: boolean;
  type?: string;
}

export class DiscountForBranch {
  id: number;
  has_discount: boolean;
  discount_percentage: number;
  discount_amount: number;
  discount_type: string;

  constructor(discount_percentage: number = 0, discount_amount: number = 0) {
    this.discount_amount = discount_amount;
    this.discount_percentage = discount_amount;
  }
}

export class DiscountOnCreate {
  discount_type: string;
  discount_percentage?: number;
  discount_amount?: number;
  laundry_branch_id: number;
  discount_by_justclean?: number;
  discount_by_laundry?: number;
}

export class BranchTaTService {
  id: number;
  value: number;
}

export class BranchTATServiceToBeSaved {
  laundry_branch_id: number;
  services: BranchTaTService[];
}

export class BranchTATFromList {
  id: number;
  name: Name;
  description: string;
  icon: string;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  country_id: number;
  group_id?: any;
}

export class BranchTaTServices {
  has_fast: boolean;
  services: BranchTaTService[];
}

export class SpecialIcons {
  id: number;
  name: string;
  icon: string;
  icon_height: number;
  icon_width: number;
  status: string;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  country_id: number;
  group_id: number;
}

export interface BranchContractInfo {
  id: number;
  contract_start_date: Date;
  contract_end_date: Date;
  commission_percentage: number;
  subscription_fee_monthly: number;
  laundry_branch_id: number;
  jc_credit_free_commission: number;
  jc_credit_paid_commission: number;
}

export interface BranchContractInfoToBeSaved {
  id?: number;
  laundry_branch_id: number;
  contract_start_date: string;
  contract_end_date: string;
  commission_percentage: number;
  subscription_fee_monthly: number;
  jc_credit_free_commission: number;
  jc_credit_paid_commission: number;
}

export interface UserTypeApiRequstBody {
  userTypeIds: Array<number>;
}
