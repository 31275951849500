// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host::ng-deep .mdb-select-outline .mdb-select-value {
  padding: 0.375rem 0.75rem;
}

.modal-body {
  min-width: 798px;
  min-height: 400px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/carwash/modules/carwash-branch/components/area-coverage-dialog/area-coverage-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;;AAEA;EACE,gBAAA;EACA,iBAAA;AACF","sourcesContent":[":host::ng-deep .mdb-select-outline .mdb-select-value {\n  padding: 0.375rem 0.75rem;\n}\n\n.modal-body {\n  min-width: 798px;\n  min-height: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
