import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomecleanOrderListComponent } from '@app/modules/homeclean/modules/homeclean-order/components/homeclean-order-list/homeclean-order-list.component';
import { HomeCleaningOrderDetailsComponent } from './homeclean-order-details/home-cleaning-order-details.component';

const routes: Routes = [
  {
    path: 'orders/details/:id',
    component: HomeCleaningOrderDetailsComponent,
    data: { title: 'Home cleaning order details' },
  },
  {
    path: 'orders/list',
    component: HomecleanOrderListComponent,
    data: { title: 'Home cleaning orders' },
  },

  {
    path: 'orders',
    redirectTo: 'orders/list',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeCleaningOrderRoutingModule {}
