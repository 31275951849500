import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { NewBackEndResponse } from '@shared/models/global/response';
import { GlobalService } from 'src/app/shared/service/global.service';
import { BranchesService } from 'src/app/shared/service/branches.service';
import { BranchPosition } from "src/app/shared/models/laundries/branch";
import { CountryService } from 'src/app/shared/service/country.service';
import { ToastService, ModalDirective } from 'ng-uikit-pro-standard';
import { GroupService } from '@shared/service/group';
import { BranchArea, Group } from "@shared/models";
import { AppHeaders } from "@shared/models/user/generic-backend";
import { Governorate } from "@shared/models/countries/country";
import { LaundryBranch } from "@shared/models/laundries/laundry";
import { Laundry } from "@shared/service/laundry.service";

@Component({
  selector: 'app-laundry-position',
  templateUrl: './area-position.component.html',
  styleUrls: ['./area-position.component.css'],
})
export class AreaPositionComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  // Observable Subjects
  governoratesLoading$ = new BehaviorSubject<boolean>(false);
  areasLoading$ = new BehaviorSubject<boolean>(false);
  groupsLoading$ = new BehaviorSubject<boolean>(false);
  laundriesLoading$ = new BehaviorSubject<boolean>(false);
  laundryBranchesLoading$ = new BehaviorSubject<boolean>(false);
  cwGaragesLoading$ = new BehaviorSubject<boolean>(false);
  cwGarageBranchesLoading$ = new BehaviorSubject<boolean>(false);
  laundryBranchAreasLoading$ = new BehaviorSubject<boolean>(false);

  // Other variables
  firstInit = true;
  selectedBranch: any;
  designType: string;
  selectedBranchPosition: any;
  isLoading: boolean;
  loader = false;
  query = '';
  key = 'name';
  areaId: number;
  from: string;
  currentTab = 1;
  @ViewChild('updatePosition', { static: true })
  modal: ModalDirective;
  groups$: Observable<Group[]>;
  selectedGroup: Group;
  governorates$: Observable<Governorate[]>;
  selectedGovernorate: Group;
  laundries$: Observable<Laundry[]>;
  selectedLaundry: LaundryBranch;
  laundryBranches$: Observable<LaundryBranch[]>;
  selectedLaundryBranch: LaundryBranch;
  cwGarages$: Observable<any[]>;
  selectedCwGarage: any;
  cwgBranches$: Observable<any[]>;
  selectedCwgBranch: any;
  laundryBranchAreas$: Observable<BranchArea[]>;
  selectedBranchArea: BranchArea
  groupId: number;
  private headers: AppHeaders;

  constructor(
    private readonly global: GlobalService,
    private readonly branchService: BranchesService,
    private readonly countryService: CountryService,
    private readonly toast: ToastService,
    private readonly groupService: GroupService,
  ) {}

  ngOnInit(): void {
    this.headers = this.global.headers;
    this.loadGroups();
  }

  ngOnDestroy(): void {
    this.global.headers.group_id = '1';
    this.destroy$.next();
    this.destroy$.complete();

    // Complete the observables
    this.governoratesLoading$.complete();
    this.areasLoading$.complete();
    this.groupsLoading$.complete();
    this.laundryBranchesLoading$.complete();
    this.laundriesLoading$.complete();
    this.cwGaragesLoading$.complete();
    this.cwGarageBranchesLoading$.complete();
    this.laundryBranchAreasLoading$.complete();
  }

  areaChanges(areaId: number): void {
    this.areaId = areaId;
  }


  changePosition(): void {
    this.loader = true;
    const branchPosition = new BranchPosition();
    branchPosition.toPosition = Number(this.selectedBranchPosition);

    branchPosition.fromPosition = this.selectedBranchArea.sortOrder
    if (this.designType === 'laundry') {
      branchPosition.laundryBranchId = this.selectedBranchArea.laundryBranchId;
    } else {
      branchPosition.cwGarageBranchId = this.selectedBranchArea.cwGarageBranchId;
    }

    branchPosition.areaId = this.selectedBranchArea.areaId;
    this.branchService.changePosition(this.global.headers, branchPosition).subscribe(
      (response: NewBackEndResponse) => {
        if (response.code === 200) {
          this.loader = false;
          this.modal.hide();
          this.loadLaundryBranchAreas()
        }
        if (response.code > 200) {
          this.loader = false;
          this.toast.error(response.message);
        }
      },
      (error) => {
        this.loader = false;
      },
    );
  }

  openDialog(branchArea: BranchArea, designType: string): void {
    this.designType = designType;
    this.selectedBranchArea = branchArea
    this.selectedBranchPosition = branchArea.sortOrder
    this.modal.show();
  }


  private loadLaundries(): void {
    this.laundriesLoading$.next(true);
    this.laundries$ = this.branchService
      .getLaundryList(this.headers, {
        countryId: this.global.countryId,
        groupId: this.selectedGroup.id,
        status:'active',
        page: 'all'
      }).pipe(
        takeUntil(this.destroy$),
        map((laundries) => {
          this.laundriesLoading$.next(false);
          return laundries;
        }),
      )
  }

  setLaundry(): void {
    this.getLaundryBranches()
  }


  private getLaundryBranches(): void {
    this.laundryBranchesLoading$.next(true);
    this.laundryBranches$ = this.branchService
      .getLaundryBranchList(this.headers, {
        status:'active',
        laundryId: this.selectedLaundry.id
      }).pipe(
        takeUntil(this.destroy$),
        map((laundryBranches) => {
          this.laundryBranchesLoading$.next(false);
          return laundryBranches;
        }),
      )
  }

  setBranch(): void {
    this.loadGovernorates()
  }



  private loadCwgGarages(): void {
    this.cwGaragesLoading$.next(true);
    this.cwGarages$ = this.branchService
      .getCwGarageList(this.headers, {
        countryId: this.global.countryId,
        groupId: this.selectedGroup.id,
        status:'active',
        page: 'all'
      }).pipe(
        takeUntil(this.destroy$),
        map((laundries) => {
          this.cwGaragesLoading$.next(false);
          return laundries;
        }),
      )
  }

  setCwGarage(): void {
    this.getCwgBranches()
  }


  private getCwgBranches(): void {
    this.cwGarageBranchesLoading$.next(true);
    this.cwgBranches$ = this.branchService
      .getCwgBranchList(this.headers, {
        status:'active',
        cwGarageId: this.selectedCwGarage.id,
        groupId: this.selectedGroup.id
      }).pipe(
        takeUntil(this.destroy$),
        map((cwgarageBranches) => {
          this.cwGarageBranchesLoading$.next(false);
          return cwgarageBranches;
        }),
      )
  }



  loadGroups(): void {
    this.groupsLoading$.next(true);
    this.groups$ = this.groupService.getGroups().pipe(
      map((groups) => {
        if (this.groupId || this.selectedGroup) {
          /** This to bind with groups dropdown */
          this.selectedGroup = groups.find(
            ({ id }) => id === this.groupId || id === this.selectedGroup?.id,
          );
        } else {
          // assign the first group by default
          this.selectedGroup = groups.find(() => true);
          this.setGroup();
        }
        this.groupsLoading$.next(false);
        return groups;
      }),
    );
  }

  setGroup(): void {
    this.headers.group_id = this.selectedGroup?.id ? this.selectedGroup.id.toString() : `${this.groupId || '1'}`;
    this.designType = this.selectedGroup?.designType ? this.selectedGroup.designType : 'laundry';
    console.log('selectedGroup = ', this.selectedGroup)

    if (this.selectedGroup.designType === 'laundry') {
      this.loadLaundries();
    } else if (this.selectedGroup.designType === 'carwashGarage') {
      this.loadCwgGarages();
    }
  }

  private loadGovernorates(): void {
    this.governoratesLoading$.next(true);
    this.governorates$ = this.countryService
      .getAllListCities(this.global.headers)
      .pipe(
        takeUntil(this.destroy$),
    map((governorates) => {
      this.governoratesLoading$.next(false);
      return governorates;
    }),
      );
  }

  setGovernorate(): void {
    this.loadLaundryBranchAreas()
  }

  loadLaundryBranchAreas(): void {
    let laundryBranchParams: {
      countryId: number,
        cityId: number,
        sortName: 'areaId',
      laundryBranchId?: number,
      cwGarageBranchId?: number
    } = {
      countryId: this.global.countryId,
      cityId: this.selectedGovernorate.id,
      sortName: 'areaId'
    }
    if (this.selectedLaundryBranch?.id) {
      laundryBranchParams.laundryBranchId = this.selectedLaundryBranch.id
    }
    if (this.selectedCwgBranch?.id) {
      laundryBranchParams.cwGarageBranchId = this.selectedCwgBranch.id
    }

    this.laundryBranchAreasLoading$.next(true);
    this.laundryBranchAreas$ = this.branchService
      .getBranchSelectedAreas(laundryBranchParams)
      .pipe(takeUntil(this.destroy$),
        map((areas) => {
          this.laundryBranchAreasLoading$.next(false);
          return areas;
        }),)
  }
}
