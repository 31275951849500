import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { AdsRoutingModule } from './ads-routing.module';
import { AdEditDialogComponent } from './components/ad-edit-dialog/ad-edit-dialog.component';
import { AdFormComponent } from './components/ad-edit-dialog/ad-form/ad-form.component';
import { AdListComponent } from './components/ad-list/ad-list.component';
import { MarketingAdListComponent } from './components/marketing-ad-list/marketing-ad-list.component';
import { SalesAdListComponent } from "@app/modules/ads/components/sales-ad-list/sales-ad-list.component";
import { SalesAdEditDialogComponent } from "@app/modules/ads/components/sales-ad-edit-dialog/sales-ad-edit-dialog.component";
import { SalesAdFormComponent } from "@app/modules/ads/components/sales-ad-edit-dialog/sales-ad-form/sales-ad-form.component";

@NgModule({
  declarations: [
    AdListComponent,
    AdEditDialogComponent,
    AdFormComponent,
    MarketingAdListComponent,
    SalesAdListComponent,
    SalesAdEditDialogComponent,
    SalesAdFormComponent,
  ],
  imports: [SharedModule, AdsRoutingModule],
})
export class AdsModule {}
