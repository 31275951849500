import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchInList'
})
export class SearchPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (args[0] === '') {
      return value;
    }
    return value.filter(current =>
      current[args[1]].toLowerCase().includes(args[0].toLowerCase())
    );
  }
}
