import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PartnerManagementComponent } from './components/partners-management/partner-management.component';
import { PartnersListComponent } from './components/partners-management/partners-list/partners-list.component';
import { AssignAreaPartnerComponent } from './components/partners-management/assign-area-partner/assign-area-partner.component';

const routes: Routes = [
  {
    path: 'partners-management',
    component: PartnerManagementComponent,
    children: [
      {
        path: 'partners-list',
        component: PartnersListComponent,
        data: {
          title: 'List of Logistics Drivers'
        }
      },
      {
        path: 'areas',
        component: AssignAreaPartnerComponent,
        data: {
          title: 'List of Logistics Drivers'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PartnersRoutingModule {}
