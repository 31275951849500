export interface AnotherAction {
    actionName: string; // ex. Assign staff
    /** This component should be developed and set its selector into this property */
    component?: string;
    /** if this action will open another configured page */
    linkedPageId?: number;
    /** to be used when load the child data ex. load company branches (companyId) */
    foreignKeyName?: string;
    /** if true, so add a separate button like (Create button) above the list table */
    isSeparateAction?: string;
    /** if true, so only super admin can see this action */
    isSuperAdmin?: boolean;
    /** comma separated, if not empty, so only one of these emails can see this action [NOTE: if isSuperAdmin is true, then will not look at this list of emails]*/
    emailsList?: string;
    /** navigate to that URL and set foreignKeyName as query param for name */
    navigationUrl?: string;
  }