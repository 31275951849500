import { BrArea } from './../../../../../shared/models/laundries/branch';
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { GlobalService } from '@app/shared/service/global.service';
import { CountryService } from '@app/shared/service/country.service';

import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { BranchesService } from '@app/shared/service/branches.service';
import { HomeCleaningServicesService } from '@app/shared/service/home-cleaning/home-cleaning-services.service';
import { HcService, HcServiceArea } from '@app/shared/models';

@Component({
  selector: 'app-service-areas',
  templateUrl: './service-areas.component.html',
  styleUrls: ['./service-areas.component.scss'],
})
export class ServiceAreasComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  isLoadingData: boolean;
  form: UntypedFormArray;
  governorates: any[];
  governorate: any;

  private requestBody: HcServiceArea;
  private hcServiceAreas: Array<HcServiceArea>;
  private readonly destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: {
      dataRow: HcService;
      PageNamePrefix: string;
      foreignKeyParam: Object;
    } | any,
    private readonly dialogRef: DialogRef<ServiceAreasComponent>,
    private readonly cd: ChangeDetectorRef,
    private readonly fb: UntypedFormBuilder,
    private readonly homeCleaningServicesService: HomeCleaningServicesService,
    private readonly branchService: BranchesService,
    private readonly globalService: GlobalService,
    private readonly countryService: CountryService,
  ) {}

  ngOnInit(): void {
    this.requestBody = {
      hcServiceId: this.data.dataRow?.id,
      deliveryCharge: 0,
      serviceCharge: 0,
    };
    if (this.getCountryId() === '2') {
      this.requestBody.serviceCharge = 5
      this.requestBody.serviceChargeThreshold = 0
      this.requestBody.serviceChargeAfterThreshold = 5
    }
    this.loadServiceAreas();
  }

  close(): void {
    this.dialogRef.close();
  }

  changeGov(govId: number): void {
    this.loadCityAreas(govId);
  }

  loadGovernorates() {
    this.countryService
      .getAllListCities(this.globalService.headers)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.governorates = [];
        data?.forEach((governorate) => {
          this.governorates.push({
            value: governorate.id,
            label: governorate.name.en,
          });
        });

        if (data?.length) {
          this.governorate = data[0]?.id;
          this.loadCityAreas(this.governorate);
        } else {
          this.isLoadingData = false;
        }
      });
  }

  private loadServiceAreas(): void {
    this.isLoadingData = true;
    this.homeCleaningServicesService
      .getAllHcServicesAreas({
        hcServiceId: this.data.dataRow?.id,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (areas) => {
          this.hcServiceAreas = areas.map((hcServiceArea: HcServiceArea) => ({
            areaId: hcServiceArea.areaId,
            id: hcServiceArea.id
          }));
          this.loadGovernorates();
        },
        (error) => {
          this.dialogRef.close();
        },
      );
  }

  private loadCityAreas(governorateId: number): void {
    this.isLoadingData = true;
    this.branchService
      .getBranchAllAreas({
        countryId: this.getCountryId(),
        cityId: governorateId,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (areas) => {
          this.form = this.fb.array(
            areas.map((area) => {
              const formGroup = this.fb.group(this.mapActiveStatus(area));

              formGroup.valueChanges
                .pipe(takeUntil(this.destroy$))
                .subscribe((changes: { id: number; areaId: number; hcServiceId: number; status: boolean }) => {
                  this.modifyHcAreasArray(changes.id, changes.areaId, changes.status);
                });

              return formGroup;
            }),
          );

          this.isLoadingData = false;
          this.cd.detectChanges();
        },
        (error) => {
          this.dialogRef.close();
        },
      );
  }

  private modifyHcAreasArray(hcServiceAreaId: number, areaId: number, status: boolean): void {
    if (!status) {
      if (!hcServiceAreaId) {
        hcServiceAreaId = this.getServiceAreaId(areaId);
      }
      this.homeCleaningServicesService
        .unAssignAreasFromService(hcServiceAreaId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res['code'] === 200) {
            this.hcServiceAreas = this.hcServiceAreas.filter(
              (area) => area.areaId !== areaId,
            );
            // this.refreshSelectedAreasToUpdate(areaId);
          }
        });
    } else {
      this.requestBody.areaId = areaId;
      this.homeCleaningServicesService
        .assignAreasToService(this.requestBody)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
          if (res['code'] === 200) {
            this.hcServiceAreas?.push({ areaId, id: res.data.id, hcServiceId: res.data.hcServiceId });
            // this.refreshSelectedAreasToUpdate(areaId);
          }
        });
    }
  }

  private getCountryId(): string {
    return localStorage.getItem('country');
  }

  private mapActiveStatus(config: BrArea) {
    return {
      id: this.getServiceAreaId(config.id),
      areaId: config.id,
      areaName: config.name['en'] || config.name,
      status: this.checkAreaSelected(config.id),
    };
  }

  checkAreaSelected(areaId: number): boolean {
    return !!this.hcServiceAreas?.find((area) => area.areaId === areaId);
  }

  getServiceAreaId(areaId: number): number {
    return this.hcServiceAreas?.find((area) => area.areaId === areaId)?.id;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
