import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OnlinePaymentChargesComponent } from './components/online-payment-charges/online-payment-charges.component';
import { CloseMonthlyOrdersComponent } from './components/close-monthly-orders/close-monthly-orders.component';
import { LaundryBillingComponent } from './components/laundry-billing/laundry-billing.component';
import { FinanceDetailedReportComponent } from './components/finance-detailed-report/finance-detailed-report.component';
import { ExportInvoicesComponent } from './components/export-invoices/export-invoices.component';
import { ProviderCommissionComponent } from '@app/modules/finance/components/provider-commission/provider-commission.component';

const routes: Routes = [
  {
    path: 'payment-methods',
    component: OnlinePaymentChargesComponent,
    data: {
      title: 'Payment Methods',
    },
  },
  {
    path: 'close-month',
    component: CloseMonthlyOrdersComponent,
    data: {
      title: 'Close monthly Orders',
    },
  },
  {
    path: 'laundry-billing',
    component: LaundryBillingComponent,
    data: {
      title: 'Laundry Billing',
    },
  },
  {
    path: 'laundry-detailed-billing',
    component: FinanceDetailedReportComponent,
    data: {
      title: 'Laundry Billing',
    },
  },
  {
    path: 'export-invoices',
    component: ExportInvoicesComponent,
    data: {
      title: 'Export Justclean Invoices',
    },
  },
  {
    path: 'provider-commission',
    component: ProviderCommissionComponent,
    data: {
      title: 'Provider Commission',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FinanceRoutingModule {}
