// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.capacity .md-form {
  margin-top: 0.5rem;
  margin-bottom: 0% !important;
}
.capacity .md-form .form-control {
  margin: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/logistics/components/driver-management/driver-capacity/driver-capacity.component.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,4BAAA;AAAJ;AAEI;EACE,oBAAA;AAAN","sourcesContent":[".capacity {\n  .md-form {\n    margin-top: 0.5rem;\n    margin-bottom: 0% !important;\n\n    .form-control {\n      margin: 0 !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
