export namespace Reviews {
  export class OrderRatingAnswer {
    id: number;
    created_at: Date;
    updated_at: Date;
    deleted_at?: any;
    rating_answer_id: number;
    order_rating_id: number;
  }

  export class User {
    id: number;
    first_name: string;
    last_name: string;
  }

  export class LaundryBranch {
    id: number;
    code: string;
    name: string;
  }

  export class ReviewsFromList {
    id: number;
    rating: number;
    comment: string;
    status: string;
    created_at: Date;
    updated_at: Date;
    deleted_at?: any;
    laundry_id: number;
    user_id: number;
    order_id: number;
    laundry_branch_id: number;
    country_id: number;
    group_id: number;
    rating_question_id: number;
    order_rating_answers: OrderRatingAnswer[];
    user: User;
    laundry_branch: LaundryBranch;
  }

  export class ReviewsFilters {
    customer: string;
    laundry: string;
    created_at: string;
    updated_at: string;
    status: string;
  }
}
