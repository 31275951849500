// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  height: 24px;
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/danger-switch/danger-switch.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[":host {\n  display: block;\n  height: 24px;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
