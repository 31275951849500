import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@env/environment';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { Group, GroupCountry } from '@shared/models';
import { SelectOptions } from '@app/shared/models/global/response';

@Injectable({ providedIn: 'root' })
export class GroupHttpService {
  private readonly url = `${environment.jcDeliveryServer}groups`;
  private readonly urlGroupCountries = `${environment.jcDeliveryServer}groups/countries`;
  private readonly urlCWStatuses = `${environment.jcDeliveryServer}statuses-cw-garage-branch-ticket`;
  private readonly urlLaundryStatuses = `${environment.jcDeliveryServer}statuses-user`;

  constructor(private readonly http: HttpClient) {}

  getGroups(
    filters?: Record<string, string>,
  ): Observable<ApiResponse<Group[]>> {
    let showWithoutProviders: string = 'true';
    if (filters?.showWithoutProviders) {
      showWithoutProviders = filters.showWithoutProviders;
    }
    const params = new HttpParams().appendAll({
      ...(filters || {}),
      showWithoutProviders: showWithoutProviders as any,
    });

    return this.http.get<ApiResponse<Group[]>>(this.url, { params });
  }

  getGroupById(id: number): Observable<ApiResponse<Group>> {
    return this.http.get<ApiResponse<Group>>(`${this.url}/${id}`);
  }

  createGroup(data: Partial<Group>): Observable<ApiResponse<Group>> {
    return this.http.post<ApiResponse<Group>>(this.url, data);
  }

  updateGroup(
    id: number,
    data: Partial<Group>,
  ): Observable<ApiResponse<Group>> {
    return this.http.patch<ApiResponse<Group>>(`${this.url}/${id}`, data);
  }

  getGroupStatuses(
    isLaundry: boolean,
    groupId: number,
  ): Observable<Array<SelectOptions<number>>> {
    const apiURL = !isLaundry ? this.urlCWStatuses : this.urlLaundryStatuses;
    const params = { groupId: groupId.toString() };
    return this.http.get<ApiResponse<any>>(apiURL, { params }).pipe(
      map((res) => {
        if (res.code === 200) {
          return res.data
            ?.filter((el) => el?.name?.en)
            ?.map((el) => {
              const statuse: SelectOptions<number> = {
                value: +el.id,
                label: el.name.en,
              };
              return statuse;
            });
        }
      }),
    );
  }

  getGroupCountries(groupId: number): Observable<ApiResponse<GroupCountry[]>> {
    const params = new HttpParams().appendAll({
      groupId: groupId.toString(),
    });

    return this.http.get<ApiResponse<GroupCountry[]>>(this.urlGroupCountries, {
      params,
    });
  }

  assignBulkCountries(
    data: Partial<Array<GroupCountry>>,
  ): Observable<ApiResponse<Array<GroupCountry>>> {
    return this.http.post<ApiResponse<Array<GroupCountry>>>(
      `${this.urlGroupCountries}/bulk`,
      data,
    );
  }

  assignCountry(
    data: Partial<GroupCountry>,
  ): Observable<ApiResponse<GroupCountry>> {
    return this.http.post<ApiResponse<GroupCountry>>(
      this.urlGroupCountries,
      data,
    );
  }

  updateAssignedCountry(
    data: Partial<GroupCountry>,
  ): Observable<ApiResponse<GroupCountry>> {
    return this.http.patch<ApiResponse<GroupCountry>>(
      `${this.urlGroupCountries}/${data.id}`,
      data,
    );
  }

  removeAssignedCountry(countryId: number): Observable<ApiResponse<void>> {
    return this.http.delete<ApiResponse<void>>(
      `${this.urlGroupCountries}/${countryId}`,
    );
  }
}
