import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BackEndResponse } from '../models/global/response';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {
  readonly URL_GET_DEVICES = environment.serverUrl + 'device-authorizations-sp'; // get
  constructor(private http: HttpClient) {}

  getDevices(headers, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_GET_DEVICES, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params })
      })
      .pipe(tap(data => console.log(JSON.stringify(data))));
  }

  getDeviceDetails(headers, id: any) {
    return this.http
      .get<BackEndResponse>(this.URL_GET_DEVICES + '/' + id, {
        headers: new HttpHeaders(headers)
      })
      .pipe(tap(data => console.log(JSON.stringify(data))));
  }

  toggleStatus(headers, id: number, is_authorized: boolean) {
    return this.http
      .patch<BackEndResponse>(
        this.URL_GET_DEVICES + '/' + id,
        {
          is_authorized: !is_authorized
        },
        {
          headers: new HttpHeaders(headers)
        }
      )
      .pipe(tap(data => console.log(JSON.stringify(data))));
  }
}
