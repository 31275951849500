import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MarketingRoutingModule } from './marketing-routing.module';
import { UsersSegementsMainComponent } from './components/users-segements-main/users-segements-main.component';
import { SegmentComponent } from './components/users-segements-main/segment/segment.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [UsersSegementsMainComponent, SegmentComponent],
  imports: [
    CommonModule,
    MarketingRoutingModule,
    SharedModule.forRoot()
  ]
})
export class MarketingModule { }
