import { NumbersHelper } from '@shared/helpers/numbers.helper';

export class UniqueIdHelper {
  static generate(parameters: {isoCode: string, groupId: number, count?: number}) {
    return `${parameters.isoCode}${this.getPrefix(
      parameters.groupId,
    )}${NumbersHelper.padDigits((parameters.count || 0) + 1, 10)}`;
  }
  static getPrefix(groupId: number) {
    switch (groupId) {
      case 1:
        return 'JCL';
      case 2:
        return 'CWL';
      case 3:
        return 'JCC';
      case 4:
        return 'CWG';
      case 5:
        return 'JCHC';
      case 6:
        return 'CWM';
      case 7:
        return 'WSH';
      case 8:
        return 'MSH';
      case 9:
        return 'WSS';
      case 10:
        return 'MSS';
      case 11:
        return 'WSPH';
      case 12:
        return 'MSPH';
      case 13:
        return 'WSPS';
      case 14:
        return 'MSPS';
      case 15:
        return 'PGH';
      case 16:
        return 'ACG';
      case 17:
        return 'JSC';
      case 18:
        return 'JPC';
      case 19:
        return 'JCCC';
      default:
        return 'JCG';
    }
  }
}
