import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from 'src/app/shared/service/global.service';
import { SelectOptions, PageInfo } from 'src/app/shared/models/global/response';
import { UsersService } from 'src/app/shared/service/users.service';
import { User } from 'src/app/shared/models/user/user';
import { KPIFromList, KPIParams, KPIToBeSaved } from '../../models/kpi';
import { SalesService } from '../../services/sales.service';
import { ApiLibrary } from 'src/app/shared/helpers/http-params';
import { ToastService } from 'ng-uikit-pro-standard';
import { IdName } from 'src/app/shared/models/offers/offers';
import { NgForm, NgModel } from '@angular/forms';

@Component({
  selector: 'app-kpi-setup',
  templateUrl: './kpi-setup.component.html',
  styleUrls: ['./kpi-setup.component.css'],
})
export class KpiSetupComponent implements OnInit {
  selectedYear: string;
  selectedMonth: string;
  selectedAgent: number;
  selectedKpiType: number;
  selectedKPI: KPIFromList;
  years: SelectOptions<string>[] = [];
  months: SelectOptions<string>[] = [];
  salesAgents: SelectOptions<number>[] = [];
  kpiTypes: SelectOptions<number>[] = [];
  kpis: KPIFromList[];
  pagination: PageInfo;
  params: KPIParams;
  kpiToBeSaved: KPIToBeSaved;
  toggleDelete = false;
  loader = false;
  @ViewChild('myForm', {static : true}) myForm: NgForm;
  @ViewChild('description', {static : true}) comment: NgModel;
  // modal


  isCreateModalOpened = false;
  constructor(
    private globalService: GlobalService,
    private userService: UsersService,
    private toast: ToastService,
    private salesService: SalesService
  ) {}

  ngOnInit() {
    this.initParams();
    this.initData();
    this.getUsers();
    this.getPage(1);
    this.getKPISTypes();
    this.initSaveKpiBody(false);
  }

  getPage(event) {
    if (event) {
      this.params.page = event;
      this.getKPIS(this.params);
    }
  }

  resetFilters() {
    this.initParams();
    this.getKPIS(this.params);
  }

  filter() {
    this.params = {
      user_admin_id: this.selectedAgent,
      month: this.selectedMonth,
      year: this.selectedYear,
      page: 1,
    };
    this.getKPIS(this.params);
  }

  triggerDelete(kpi: KPIFromList) {
    this.selectedKPI = kpi;
    this.toggleDelete = true;
  }

  deleteKPI(event) {
    this.loader = true;
    this.salesService
      .deleteKPI(this.globalService.headers, this.selectedKPI.id)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.getKPIS(this.params);
            this.toast.success(response.message);
            this.toggleDelete = false;
          } else {
            this.toast.error(response.message);
          }
          this.loader = false;
        },
        (error) => {
          this.loader = false;
        }
      );
  }

  saveKpi() {
    this.loader = true;
    this.salesService
      .saveKpis(this.globalService.headers, this.kpiToBeSaved)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            this.getKPIS(this.params);
            this.toast.success(response.message);
            this.closeCreateModal(true);
          } else {
            this.toast.error(response.message);
          }
          this.loader = false;
        },
        (error) => {
          this.loader = false;
        }
      );
  }

  initSaveKpiBody(isUpdate: boolean) {
    if (!isUpdate) {
      this.kpiToBeSaved = {
        amount: null,
        count: null,
        comment: null,
        kpi_type_id: null,
        month: null,
        year: null,
        percent: null,
        user_admin_id: null,
        weight: null,
      };
    } else {
      this.kpiToBeSaved = {
        amount: this.selectedKPI.amount,
        count: this.selectedKPI.count,
        comment: this.selectedKPI.comment,
        kpi_type_id: this.selectedKPI.kpi_type_id,
        month: this.globalService.getMonth(
          this.selectedKPI.kpi_date.toString()
        ) + '',
        year: this.globalService.getYear(this.selectedKPI.kpi_date.toString()) + '',
        percent: this.selectedKPI.percent,
        user_admin_id: this.selectedKPI.user_admin_id,
        weight: this.selectedKPI.weight,
        id: this.selectedKPI.id
      };
    }

  }

  // create modal public functions
  closeCreateModal(event) {
    this.isCreateModalOpened = false;
    this.selectedKPI = null;
    this.myForm.reset();
    this.comment.reset();

  }

  validate() {
    return (
      this.kpiToBeSaved &&
      this.kpiToBeSaved.kpi_type_id &&
      this.kpiToBeSaved.month &&
      this.kpiToBeSaved.year &&
      this.kpiToBeSaved.user_admin_id
    );
  }

  openCreate() {
    this.initSaveKpiBody(false);
    this.isCreateModalOpened = true;
    this.myForm.reset();
  }

  openUpdate(kpi: KPIFromList) {
    this.selectedKPI = kpi;
    this.initSaveKpiBody(true);
    this.isCreateModalOpened = true;
  }

  private initParams() {
    this.selectedAgent = null;
    this.selectedMonth = null;
    this.selectedYear = null;
    this.params = {
      user_admin_id: this.selectedAgent,
      month: this.selectedMonth,
      year: this.selectedYear,
      page: 1,
    };
  }

  private getUsers() {
    this.userService
      .getUsers(this.globalService.headers, {
        role_id: 7,
        page: 'all',
      })
      .subscribe((response) => {
        if (response.code === 200) {
          const users = response.data as User[];
          console.log(users);

          this.salesAgents = users.filter(e => e.country && e.country.id === this.globalService.countryId).map((e) => {
            return {
              label: e.name,
              value: e.id,
            } as SelectOptions<number>;
          });
          console.log(this.salesAgents);

        }
      });
  }

  private initData() {
    const currentYear = new Date().getFullYear();
    const startYear = 2018;
    for (let i = startYear; i <= currentYear; i++) {
      this.years.push({
        label: i + '',
        value: i + '',
      });
    }
    const months = this.globalService.getMonthList();
    const keys = Object.keys(months);
    for (const key of keys) {
      this.months.push({
        label: months[key],
        value: parseInt(key, 10) + 1 + '',
      });
    }
  }

  private getKPIS(params?: any) {
    this.salesService
      .getKpis(
        this.globalService.headers,
        new ApiLibrary().cleanObjectFromComponent(params)
      )
      .subscribe((response) => {
        if (response.code === 200) {
          this.kpis = response.data as KPIFromList[];
          this.pagination = response.page_info;
        }
      });
  }

  private getKPISTypes(params?: any) {
    this.salesService
      .getKpiTypes(
        this.globalService.headers
      )
      .subscribe((response) => {
        if (response.code === 200) {
          const types = response.data as IdName[];
          this.kpiTypes = types.map((e) => {
            return {
              label: e.name,
              value: e.id,
            } as SelectOptions<number>;
          });
        }
      });
  }

  // create modal private functions
}
