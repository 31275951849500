import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountriesListComponent } from './components/countries-list/countries-list.component';
import { SharedModule } from '../shared/shared.module';
import { CountriesRoutingModule } from './countries-routing.module';
import { CreateCountryComponent } from './components/create-country/create-country.component';
import { CitiesListComponent } from './components/countries-list/cities-list/cities-list.component';
import { AreasListComponent } from './components/countries-list/areas-list/areas-list.component';

@NgModule({
  imports: [CommonModule, SharedModule.forRoot(), CountriesRoutingModule],
  declarations: [
    CountriesListComponent,
    CreateCountryComponent,
    CitiesListComponent,
    AreasListComponent
  ]
})
export class CountriesModule {}
