import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBDatePickerComponent, IMyOptions } from 'ng-uikit-pro-standard';
import { PageInfo, SelectOptions } from 'src/app/shared/models/global/response';
import {
  ActiveOrders,
  OrderFilter,
  STATUSES
} from 'src/app/shared/models/orders/order';
import { GlobalService } from 'src/app/shared/service/global.service';
import { OrderService } from 'src/app/shared/service/order.service';

@Component({
  selector: 'app-log-all-orders',
  templateUrl: './logistic-orders.component.html',
  styleUrls: ['./logistic-orders.component.css']
})
export class LogisticOrdersComponent implements OnInit {
  @ViewChild('placedAt', { static: false }) placedAt: MDBDatePickerComponent;
  @ViewChild('pickupAt', { static: false }) pickupAt: MDBDatePickerComponent;
  @ViewChild('deliveryAt', { static: false })
  deliveryAt: MDBDatePickerComponent;
  pagination: PageInfo;
  statuses: SelectOptions<number>[];
  types: SelectOptions<string>[];
  assignment: SelectOptions<string>[];
  selectedStatus: number;
  selectedAssignment: string;
  orders: ActiveOrders[];
  orderFilter: OrderFilter = new OrderFilter();
  pickUpDeliveryDatesOptions: IMyOptions;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected globalService: GlobalService,
    protected orderService: OrderService
  ) {
    this.pickUpDeliveryDatesOptions = {
      disableSince: {
        day: new Date(this.globalService.getNext7Days().toString()).getDate(),
        month: new Date(this.globalService.getNext7Days().toString()).getMonth() + 1,
        year: new Date(this.globalService.getNext7Days().toString()).getFullYear()
      },
      disableUntil: {
        day: new Date(this.globalService.getLast2MonthDate().toString()).getDate(),
        month: new Date(this.globalService.getLast2MonthDate().toString()).getMonth() + 1,
        year: new Date(this.globalService.getLast2MonthDate().toString()).getFullYear()
      },
      closeAfterSelect: true
    };

  }


  ngOnInit() {
    this.getAllOrders(1);
    this.filtersSelectData();
  }

  getAllOrders(page: number) {
    const params = {
      page: page,
      order_status: this.selectedStatus,
      customer_name: this.orderFilter.customerName,
      customer_mobile: this.orderFilter.customerMobile,
      is_assigned: this.selectedAssignment === 'assigned',
      driver_name: this.orderFilter.driverName,
      driver_mobile: this.orderFilter.driverMobile,
      order_id: this.orderFilter.orderNumber,
      placed_at: this.orderFilter.placed_at,
      updated_at: this.orderFilter.updatedAt,
      searchstr: this.orderFilter.searchstr,
      type: this.orderFilter.type,
      is_logistics: this.orderFilter.is_logistics,
      pickup_at: this.orderFilter.pickup_at,
      delivery_at: this.orderFilter.delivery_at
    };

    this.orderService
      .getAllLogOrders(this.globalService.headers, params)
      .subscribe(response => {
        this.orders = response.data as ActiveOrders[];
        this.pagination = response.page_info;
      });
  }

  private filtersSelectData() {
    this.statuses = [];
    STATUSES.forEach((val, i) => {
      this.statuses.push({
        value: i + 1,
        label: val
      });
    });
    this.types = [
      {
        value: 'marketplace',
        label: 'Marketplace'
      },
      {
        value: 'corporate',
        label: 'Corporate'
      }
    ];
    this.assignment = [
      {
        value: 'assigned',
        label: 'Assigned'
      },
      {
        value: 'unassigned',
        label: 'Unassigned'
      }
    ];
  }

  getDateTime(date: string) {
    return this.globalService.getDateTimeFromString(date);
  }

  getPage(event) {
    this.getAllOrders(event);
  }

  filter() {
    this.getAllOrders(0);
  }

  initFilters() {
    this.selectedStatus = null;
    this.selectedAssignment = null;
    this.orderFilter = new OrderFilter();
    this.placedAt.clearDate();
    this.pickupAt.clearDate();
    this.deliveryAt.clearDate();
    this.getAllOrders(1);
  }

  toggleIsLogistics() {
    this.orderFilter.is_logistics = !this.orderFilter.is_logistics;
    console.log(this.orderFilter.is_logistics);
  }
}
