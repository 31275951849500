import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partner-management',
  templateUrl: './partner-management.component.html'
})
export class PartnerManagementComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
