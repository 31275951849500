import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { ModalDirective } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-jc-modal',
  templateUrl: './jc-modal.component.html',
  styleUrls: ['./jc-modal.component.scss']
})
export class JcModalComponent implements OnInit, OnChanges {
  @Input() isOpened: boolean;
  @Input() headerText: string;
  @Input() actionText: string;
  @Input() cancelText: string;
  @Input() disabled: boolean;
  @Input() loader: boolean;
  @Input() isBigBody: boolean;
  @Input() isSide: boolean;
  @Input() isFluid: boolean;
  @ViewChild('basicModal', { static: true }) basicModal: ModalDirective;
  @Output() actionEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() cancelEventEmitter: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.isOpened === true) {
      this.basicModal.show();
    } else {
      this.basicModal.hide();
    }
  }

  actionTrigger() {
    this.actionEventEmitter.emit();
  }

  cancelTrigger() {
    this.cancelEventEmitter.emit();
  }
}
