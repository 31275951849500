import { expand, map, reduce } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { APIConfig, Crud, CrudResponse, Parameter } from '@shared/models';
import { SelectOptions } from '@app/shared/models/global/response';

@Injectable({ providedIn: 'root' })
export class CrudHttpService {
  private readonly url = `${environment.jcDeliveryServer}admin-pages`;
  constructor(private readonly http: HttpClient) {}

  //#region Page Generator Logic

  getCruds(): Observable<Array<Crud>> {
    return this.http.get<ApiResponse<Array<CrudResponse>>>(this.url).pipe(
      expand((res) =>
        !res.pageInfo.nextPage
          ? EMPTY
          : this.http.get<ApiResponse<Array<CrudResponse>>>(
              this.url,
              this.updateRequestFormat({}, res.pageInfo.nextPage),
            ),
      ),
      reduce(
        (records, res) => records.concat(this.getMappedPages(res.data)),
        [],
      ),
    );
  }

  getActivePages(): Observable<Array<Crud>> {
    return this.http.get<ApiResponse<Array<CrudResponse>>>(this.url).pipe(
      expand((res) =>
        !res.pageInfo.nextPage
          ? EMPTY
          : this.http.get<ApiResponse<Array<CrudResponse>>>(
              this.url,
              this.updateRequestFormat({}, res.pageInfo.nextPage),
            ),
      ),
      reduce(
        (records, res) => records.concat(this.getMappedPages(res.data)),
        [],
      ),
    );
  }

  createCrud(data: Partial<Crud>): Observable<ApiResponse<CrudResponse>> {
    return this.http.post<ApiResponse<CrudResponse>>(this.url, {
      configuration: data,
    });
  }

  updateCrud(
    id: number,
    data: Partial<Crud>,
  ): Observable<ApiResponse<CrudResponse>> {
    return this.http.patch<ApiResponse<CrudResponse>>(`${this.url}/${id}`, {
      configuration: data,
    });
  }

  deleteCrud(id: number): Observable<ApiResponse<Crud>> {
    return this.http.delete<ApiResponse<Crud>>(`${this.url}/${id}`);
  }

  private updateRequestFormat(params: any, page: number) {
    params.page = page;
    return {
      params,
    };
  }

  private getMappedPages(pages: Array<CrudResponse>): Array<Crud> {
    return pages.map((page) => {
      page.configuration.id = page.id;
      return page.configuration;
    });
  }

  //#endregion

  //#region Generated Page Logic

  getDropdownData(
    apiConfig: APIConfig,
  ): Observable<Array<SelectOptions<number>>> {
    const apiUrl = `${environment[apiConfig.apiVersion]}${apiConfig.path}`;
    const params = apiConfig.extraParameters || {};
    return this.http.get<ApiResponse<any>>(apiUrl, { params }).pipe(
      map((res) => {
        if (res.code === 200) {
          return res.data
            ?.filter((el) => el?.name?.en || el?.name)
            ?.map((el) => {
              const statuse: SelectOptions<number> = {
                value: +el.id,
                label: el.name.en || el.name,
              };
              return statuse;
            });
        }
      }),
    );
  }

  /** Method for generic loading data of the page */
  getPageData(
    apiConfig: APIConfig,
    FKParam: any = {},
    page: number = 1,
  ): Observable<any> {
    const apiUrl = `${this.getApiUrl(apiConfig)}`;
    const params = { ...(apiConfig.extraParameters || {}), ...FKParam, page };
    return this.http.get<ApiResponse<any>>(apiUrl, { params });
  }

  /** Method for generic loading data of the page */
  getSingleRow(id: number, apiConfig: APIConfig): Observable<any> {
    const apiUrl = `${this.getApiUrl(apiConfig)}/${id}`;
    return this.http.get<ApiResponse<any>>(apiUrl);
  }

  /** Method for generic createing data */
  create(apiConfig: APIConfig, body: any): Observable<any> {
    const apiUrl = `${this.getApiUrl(apiConfig)}`;
    this.adjustNumericParameters(apiConfig.extraParameters);
    return this.http.post<ApiResponse<any>>(apiUrl, {
      ...body,
      ...(apiConfig.extraParameters || {}),
    });
  }

  /** Method for generic updating data */
  update(apiConfig: APIConfig, id: number, body: any): Observable<any> {
    if (JSON.stringify(body) === '{}') {
      return of(null);
    }
    const apiUrl = `${this.getApiUrl(apiConfig)}/${id}`;
    this.adjustNumericParameters(apiConfig.extraParameters);
    return this.http.patch<ApiResponse<any>>(apiUrl, {
      ...body,
      ...(apiConfig.extraParameters || {}),
    });
  }

  /** Method for generic deleting data */
  delete(apiConfig: APIConfig, id: number): Observable<any> {
    const apiUrl = `${environment[apiConfig.apiVersion]}${
      apiConfig.path
    }/${id}`;
    return this.http.delete<ApiResponse<any>>(apiUrl);
  }

  private getApiUrl(apiConfig: APIConfig): string {
    this.setDynamicParamValues(apiConfig);
    const apiUrl = `${environment[apiConfig.apiVersion]}${apiConfig.path}`;
    return apiUrl;
  }

  private setDynamicParamValues(apiConfig: APIConfig): void {
    Object.keys(apiConfig.extraParameters || {})?.forEach((key) => {
      const lsKeyName = apiConfig.extraParameters[key]
        ?.toString()
        ?.replace('{', '')
        ?.replace('}', '');
      if (
        apiConfig.extraParameters[key]?.toString()?.includes('{') &&
        localStorage[lsKeyName]
      ) {
        apiConfig.extraParameters[key] = localStorage[lsKeyName];
      }
    });
  }

  adjustNumericParameters(extraParameters: Parameter): void {
    Object.keys(extraParameters || {})?.forEach((key) => {
      if (+extraParameters[key]) {
        extraParameters[key] = +extraParameters[key];
      }
    });
  }

  //#endregion
}
