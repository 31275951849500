import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {NewBackEndResponse} from '../models/global/response';
import {JcCreditPackage} from '../models/jc-credit-package.model';
import {ApiResponse} from '../models/global/apiResponse';

@Injectable({
  providedIn: 'root'
})
export class JcCreditPackagesService {
  readonly URL = environment.jcDeliveryServer + 'jc-credit-packages';

  constructor(private http: HttpClient) {}

  list(headers, countryId, pageNo?: number, search?: string): Observable<ApiResponse<JcCreditPackage[]>> {
    const params: any = { page: pageNo ? pageNo.toString() : '1' , countryId };

    if (search) {
      params.search = search;
    }
    return this.http.get<ApiResponse<JcCreditPackage[]>>(this.URL, {
      headers: this.setHeaders(headers),
      params: params
    });
  }

  get(headers, id: string): Observable<NewBackEndResponse> {
    return this.http.get<NewBackEndResponse>(`${this.URL}/${id}`,
      {
      headers: this.setHeaders(headers)
    });
  }

  create(headers, jcCreditPackage: JcCreditPackage): Observable<NewBackEndResponse> {
    return this.http.post<NewBackEndResponse>(this.URL, jcCreditPackage, {
      headers: this.setHeaders(headers)
    });
  }

  update(headers, id: number, status?: string): Observable<ApiResponse<JcCreditPackage>> {
    return this.http.patch<NewBackEndResponse>(`${this.URL}/${id}`, { status }, {
      headers: this.setHeaders(headers)
    });
  }

  delete(headers, id: number) {
    return this.http.delete<NewBackEndResponse>(
      `${this.URL}/${id}`,
      {
        headers: this.setHeaders(headers)
      }
    );
  }

  setHeaders(headers) {
    return new HttpHeaders(headers);
  }
}
