import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CarwashStation } from '@app/modules/carwash/modules/carwash-station/shared/model/carwash-station.model';
import { CarwashStationHttpService } from '@app/modules/carwash/modules/carwash-station/shared/services/carwash-station-http.service';
import { ApiResponse } from '@shared/models/global/apiResponse';

@Injectable({ providedIn: 'root' })
export class CarwashStationService {
  constructor(
    private readonly carwashStationHttpService: CarwashStationHttpService,
  ) {}

  getStations(cwGarageBranchId: number): Observable<CarwashStation[]> {
    const toData = (res: ApiResponse<CarwashStation[]>) => res.data;

    return this.carwashStationHttpService
      .getStations(cwGarageBranchId)
      .pipe(map(toData));
  }

  createStations(
    branchId: number,
    data: Partial<CarwashStation>,
  ): Observable<CarwashStation> {
    return this.carwashStationHttpService.createStations(branchId, data);
  }

  updateStations(
    id: number,
    data: Partial<CarwashStation>,
  ): Observable<CarwashStation> {
    const toData = (res: ApiResponse<CarwashStation>) => res.data;

    return this.carwashStationHttpService
      .updateStations(id, data)
      .pipe(map(toData));
  }

  removeStations(id: number): Observable<ApiResponse<any>> {
    return this.carwashStationHttpService.removeStations(id);
  }

  assignStaff(stationId: number, selectedStaffId: number): Observable<any> {
    return this.carwashStationHttpService.updateStations(stationId, {
      userJlmId: selectedStaffId,
    });
  }
}
