// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host::ng-deep .mdb-select-outline .mdb-select-value {
  padding: 0.375rem 0.75rem;
  width: 10rem;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/ads/components/sales-ad-list/sales-ad-list.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,YAAA;AACJ","sourcesContent":[":host::ng-deep .mdb-select-outline .mdb-select-value {\n    padding: 0.375rem 0.75rem;\n    width: 10rem;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
