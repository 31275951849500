import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { FormStatus } from '@shared/enums/form-status.enum';
import { Group } from '@shared/models';
import { ImageUploaderHttpService } from '@shared/service/image-uploader-http.service';
import { GroupService } from '@shared/service/group';

@Component({
  templateUrl: './group-edit-dialog.component.html',
})
export class GroupEditDialogComponent implements OnInit {
  isEditMode: boolean;
  isDisableButton: boolean;
  formData: Partial<Group>;
  imageGroupIconImageLink: string;
  imageGroupBackgroundImageLink: string;
  imageGroupCardImageLink: string;
  imageOperatingLogoLink: string;
  isLoading: boolean;

  private readonly destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    @Inject(DIALOG_DATA) public readonly data: Group | any,
    private readonly dialogRef: DialogRef<GroupEditDialogComponent>,
    private readonly groupService: GroupService,
    private readonly imageUploaderHttpService: ImageUploaderHttpService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.isEditMode = !!this.data;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(): void {
    this.dialogRef.close();
  }

  formChanged(group: Partial<Group>): void {
    this.formData = {
      ...group,
    };
  }

  formStatusChanged(status: FormStatus): void {
    this.isDisableButton = status === FormStatus.Invalid;
  }

  createGroup(): void {
    const data: Partial<Group> = {
      ...this.formData,
      keyword: this.formData.designType,
      ...(this.imageGroupIconImageLink && {
        image: {
          en: this.imageGroupIconImageLink,
          ar: this.imageGroupIconImageLink,
        },
      }),
      ...(this.imageGroupCardImageLink && {
        cardImage: {
          en: this.imageGroupCardImageLink,
          ar: this.imageGroupCardImageLink,
        },
      }),
      ...(this.imageGroupBackgroundImageLink && {
        backgroundImage: {
          en: this.imageGroupBackgroundImageLink,
          ar: this.imageGroupBackgroundImageLink,
        },
      }),
    };
    const action$ = this.isEditMode
      ? this.groupService.updateGroup(this.data.id, data)
      : this.groupService.createGroup(data);

    this.isLoading = true;
    action$
      .pipe(
        tap(() => {
          this.isLoading = false;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (company: Group) => {
          this.dialogRef.close(company);
        },
        (error) => {
          this.isLoading = false;
          this.cdr.markForCheck();
        },
      );
  }

  uploadGroupLogoFile(file: File): void {
    this.uploadImage(file)
      .pipe(takeUntil(this.destroy$))
      .subscribe((link: string) => {
        this.imageGroupIconImageLink = link;
      });
  }

  uploadGroupBackgroundImageFile(file: File): void {
    this.uploadImage(file)
      .pipe(takeUntil(this.destroy$))
      .subscribe((link: string) => {
        this.imageGroupBackgroundImageLink = link;
      });
  }

  uploadGroupCardImageFile(file: File): void {
    this.uploadImage(file)
      .pipe(takeUntil(this.destroy$))
      .subscribe((link: string) => {
        this.imageGroupCardImageLink = link;
      });
  }

  changeOperatingFile(file: File): void {
    this.uploadImage(file)
      .pipe(takeUntil(this.destroy$))
      .subscribe((link: string) => {
        this.imageOperatingLogoLink = link;
      });
  }

  private uploadImage(file): Observable<string> {
    this.isLoading = true;

    return this.imageUploaderHttpService.imageUpload(file).pipe(
      tap(() => {
        this.cdr.markForCheck();
        this.isLoading = false;
      }),
    );
  }
}
