import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../../../shared/service/global.service';
import { OrderService } from '../../../../shared/service/order.service';
import {
  PageInfo,
  SelectOptions,
} from '../../../../shared/models/global/response';
import { MDBDatePickerComponent, IMyOptions, ToastService } from 'ng-uikit-pro-standard';
import {
  CancellationRequestFilter,
  OrderCancellationRequest,
} from 'src/app/shared/models/orders/order-cancellation';
import { ApprovalSubmission } from 'src/app/shared/models/orders/order';

@Component({
  selector: 'app-order-cancel-requests',
  templateUrl: './order-cancel-requests.component.html',
  styleUrls: ['./order-cancel-requests.component.css'],
})
export class OrderCancelRequestsComponent implements OnInit {
  pagination: PageInfo;
  statuses: SelectOptions<string>[];
  selectedStatus: string;
  cancellationRequest: OrderCancellationRequest[];
  filters: CancellationRequestFilter;
  loader = {
    approve: false,
    reject: false
  };
  @ViewChild('requestedAt', { static: true })
  requestedAt: MDBDatePickerComponent;
  requestedAtDatesOptions: IMyOptions = {
    closeAfterSelect: true,
  };
  currentClickedButtonIndex = -1;
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected globalService: GlobalService,
    protected orderService: OrderService,
    protected toast: ToastService
  ) {}

  ngOnInit() {
    this.initFilters();
    this.getAllOrders(1);
    this.filtersSelectData();
  }

  getAllOrders(page: number) {
    this.filters.page = page;
    this.orderService
      .getCancelOrderRequests(this.globalService.headers, this.filters)
      .subscribe((response) => {
        this.cancellationRequest = response.data as OrderCancellationRequest[];
        this.pagination = response.page_info;
      });
  }

  private filtersSelectData() {
    this.statuses = [
      {
        value: 'pending',
        label: 'Pending',
      },
      {
        value: 'approved',
        label: 'Approved',
      },
      {
        value: 'rejected',
        label: 'Rejected',
      },
    ];
  }

  getDateTime(date: string) {
    return this.globalService.getDateTimeFromString(date);
  }

  getPage(event) {

    this.getAllOrders(event);
  }

  filter() {
    this.filters.request_date = this.filters.request_date
      ? this.globalService.formatDate(this.filters.request_date, 'YYYY-MM-DD').toString()
      : null;
    this.getAllOrders(1);
  }

  initFilters() {
    this.selectedStatus = null;
    this.filters = {
      order_id: null,
      request_date: null,
      status: null,
      page: 1
    };
    if (!!this.requestedAt) {
      this.requestedAt.clearDate();
    }
    this.getAllOrders(1);
  }

  confirmApproveOrReject(request: OrderCancellationRequest, type: boolean, index: number) {
    this.currentClickedButtonIndex = index;
    const approval: ApprovalSubmission = {
      approve: type,
      from_email: false,
      order_id: request.order_id,
      request_id: request.id
    };
    this.loader = {
      approve: type,
      reject: !type
    };
    this.orderService
        .answerApprovalRequest(this.globalService.headers, approval)
        .subscribe(
          response => {
            if (response.code === 200) {
              this.filter();
              this.toast.success(response.message);
            } else {
              this.toast.error(response.message);
            }
            this.loader = {
              approve: false,
              reject: false
            };
            this.currentClickedButtonIndex = -1;
          },
          error => {
            this.loader = {
              approve: false,
              reject: false
            };
            this.currentClickedButtonIndex = -1;
          }
        );
  }
}
