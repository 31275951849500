import { NgModule } from '@angular/core';
import { LogLaundryListComponent } from './components/laundry-list/log-laundry-list.component';
import { LogisiticsRoutingModule } from './logistics-routing.module';
import { SharedModule } from '../shared/shared.module';
import { LogBranchListComponent } from './components/branch-list/log-branch-list.component';
import { LogDriversListComponent } from './components/drivers-list/log-drivers-list.component';
import { DriverManagementComponent } from './components/driver-management/driver-management.component';
import { LogAllDriversListComponent } from './components/driver-management/log-all-drivers-list/log-all-drivers-list.component';
import { CreateLogisticsDriverComponent } from './components/driver-management/create-logistics-driver/create-logistics-driver.component';
import { CarManagementComponent } from './components/car-management/car-management.component';
import { LogCarsListComponent } from './components/car-management/log-cars-list/log-car-list.component';
import { LogCarCreateComponent } from './components/car-management/log-car-create/log-car-create.component';
import { DriverCapacityComponent } from './components/driver-management/driver-capacity/driver-capacity.component';
import { LogisticOrdersComponent } from './components/orders/logistic-orders/logistic-orders.component';
import { LogisticOrderDetailsComponent } from './components/orders/logistic-order-details/logistic-order-details.component';
import { AssignAreaDriverComponent } from './components/driver-management/assign-area-driver/assign-area-driver.component';
import { QuestionsModalComponent } from './components/orders/questions-modal/questions-modal.component';
import { OrdersMapComponent } from './components/orders-map/orders-map.component';
import { SharedLogisticsModule } from '@app/modules/logistics/shared/shared-logistics.module';

@NgModule({
  imports: [SharedModule.forRoot(), LogisiticsRoutingModule, SharedLogisticsModule],
  declarations: [
    LogLaundryListComponent,
    LogBranchListComponent,
    LogDriversListComponent,
    DriverManagementComponent,
    LogAllDriversListComponent,
    CreateLogisticsDriverComponent,
    CarManagementComponent,
    LogCarsListComponent,
    LogCarCreateComponent,
    DriverCapacityComponent,
    LogisticOrdersComponent,
    LogisticOrderDetailsComponent,
    AssignAreaDriverComponent,
    QuestionsModalComponent,
    OrdersMapComponent
  ]
})
export class LogisticsModule {}
