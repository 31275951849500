// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body {
  min-height: 20rem;
  max-height: 40rem;
  min-width: 800px;
}

.online-profile-wrapper {
  min-height: 314px;
}

.online-profile__images-container {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
  row-gap: 3rem;
  flex-wrap: wrap;
}
.online-profile__images-container--image-link {
  cursor: zoom-in;
}
.online-profile__images-container--image-loader {
  width: 140px;
  height: 140px;
}
.online-profile__status-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  min-width: 90px;
}
.online-profile__status-container--img-status {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
.online-profile__status-container--img-status.approved {
  background-color: #00c851;
}
.online-profile__status-container--img-status.rejected {
  background-color: #ff3547;
}
.online-profile__status-container--img-status.in-review {
  background-color: orange;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/carwash/modules/carwash-branch/components/online-profile/online-profile.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,iBAAA;EACA,gBAAA;AACJ;;AACE;EACE,iBAAA;AAEJ;;AAEI;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,eAAA;AACN;AACM;EACE,eAAA;AACR;AACM;EACE,YAAA;EACA,aAAA;AACR;AAGI;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,eAAA;AADN;AAGM;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AADR;AAGQ;EACE,yBAAA;AADV;AAGQ;EACE,yBAAA;AADV;AAGQ;EACE,wBAAA;AADV","sourcesContent":[".modal-body {\n    min-height: 20rem;\n    max-height: 40rem;\n    min-width: 800px;\n  }\n  .online-profile-wrapper {\n    min-height: 314px;\n  }\n  \n  .online-profile {\n    &__images-container {\n      display: flex;\n      align-items: center;\n      column-gap: 1.5rem;\n      row-gap: 3rem;\n      flex-wrap: wrap;  \n\n      &--image-link {\n        cursor: zoom-in;\n      }\n      &--image-loader {\n        width: 140px;\n        height: 140px;\n      }\n    }\n\n    &__status-container {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      gap: 0.5rem;\n      min-width: 90px;\n\n      &--img-status {\n        width: 10px;\n        height: 10px;\n        border-radius: 100%;\n\n        &.approved {\n          background-color: #00c851;\n        }\n        &.rejected {\n          background-color: #ff3547;\n        }\n        &.in-review {\n          background-color: orange;\n        }\n      }\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
