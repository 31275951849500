import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TabsetComponent } from 'ng-uikit-pro-standard';
import { forkJoin } from 'rxjs';

import { Days } from '@shared/enums';
import { CarwashBranchTimeServiceService } from '@app/modules/carwash/modules/carwash-branch/shared/services/carwash-branch-time-service/carwash-branch-time-service.service';
import { TimeSlotsConfig } from '@app/modules/carwash/modules/carwash-branch/shared/models/time-slots-config.model';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
} from '@angular/forms';
import { Status } from '@shared/enums/status.enum';
import { ActivatedRoute } from '@angular/router';
import { CarwashBranchService } from '@shared/service/carwash-branch';
import { CarwashBranch, CarwashBranchTimeSlot } from '@shared/models';

interface Tabs {
  title: string;
  times: TimeSlotsConfig[];
}

@Component({
  selector: 'app-carwash-branch-time-service',
  templateUrl: './carwash-branch-time-service.component.html',
  styleUrls: ['./carwash-branch-time-service.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CarwashBranchTimeServiceComponent implements OnInit {
  @ViewChild('staticTabs', { static: true }) staticTabs: TabsetComponent;

  isLoading: boolean;
  tabs: Tabs[] = [];
  form: UntypedFormArray;
  private originalTimeSlot: { [timeSlotTimeId: number]: { status: Status; subscriptionStatus: Status } } = {};
  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly carwashBranchTimeServiceService: CarwashBranchTimeServiceService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly carwashBranchService: CarwashBranchService,
  ) {}

  private get branchId(): number {
    return this.activatedRoute.snapshot.params.id;
  }

  ngOnInit(): void {
    this.initTabs();
  }

  saveTimes(): void {
    const toTime = ({ id, status, subscriptionStatus }): {
      timeSlotTimeId: any;
      status: Status;
      subscriptionStatus: Status
    } => ({
      timeSlotTimeId: id,
      status: status ? Status.Active : Status.Inactive,
      subscriptionStatus: subscriptionStatus ? Status.Active : Status.Inactive,
    });

    const toTimes = (acc, cur: Tabs): TimeSlotsConfig[] => [
      ...acc,
      ...cur.times,
    ];

    /** Filter only changed times */
    const checkChangedOnly = ({ id, status, subscriptionStatus }): boolean =>
      this.originalTimeSlot[id]?.status !== (status ? Status.Active : Status.Inactive) ||
      this.originalTimeSlot[id]?.subscriptionStatus !== (subscriptionStatus ? Status.Active : Status.Inactive);

    const times: CarwashBranchTimeSlot[] = this.form.value
      .reduce(toTimes, [])
      .filter(checkChangedOnly)
      .map(toTime);

    if (times.length) {
      this.isLoading = true;
      this.carwashBranchService
        .updateBranch(this.branchId, { cwGarageBranchSSTD: times })
        .subscribe(() => {
          times.forEach(
            (time) => {
              this.originalTimeSlot[time.timeSlotTimeId] = {
                status: time.status,
                subscriptionStatus: time.subscriptionStatus,
              };
            },
          );
          this.isLoading = false;
        });
    }
  }

  private initForm(timeSlotsConfig: TimeSlotsConfig[]): void {
    timeSlotsConfig.forEach(
      ({ id: timeSlotTimeId, status, subscriptionStatus }) =>
        (this.originalTimeSlot[timeSlotTimeId] = { status, subscriptionStatus }),
    );

    const toSortedTimeSlotsConfig = (acc, timeSlotConfig: TimeSlotsConfig) => {
      const slots = acc[timeSlotConfig.timeSlotDayId]
        ? acc[timeSlotConfig.timeSlotDayId]
        : [];

      acc[timeSlotConfig.timeSlotDayId] = [...slots, timeSlotConfig];

      return acc;
    };

    const sortedTimeSlotsConfig: Record<number, TimeSlotsConfig[]> =
      timeSlotsConfig.reduce(toSortedTimeSlotsConfig, {});
    this.form = this.fb.array([
      this.fb.group({
        title: Days[Days.Monday],
        times: this.fb.array(
          sortedTimeSlotsConfig[Days.Monday].map((config: TimeSlotsConfig) =>
            this.fb.group(this.mapActiveStatus(config)),
          ),
        ),
      }),
      this.fb.group({
        title: Days[Days.Tuesday],
        times: this.fb.array(
          sortedTimeSlotsConfig[Days.Tuesday].map((config: TimeSlotsConfig) =>
            this.fb.group(this.mapActiveStatus(config)),
          ),
        ),
      }),
      this.fb.group({
        title: Days[Days.Wednesday],
        times: this.fb.array(
          sortedTimeSlotsConfig[Days.Wednesday].map((config: TimeSlotsConfig) =>
            this.fb.group(this.mapActiveStatus(config)),
          ),
        ),
      }),
      this.fb.group({
        title: Days[Days.Thursday],
        times: this.fb.array(
          sortedTimeSlotsConfig[Days.Thursday].map((config: TimeSlotsConfig) =>
            this.fb.group(this.mapActiveStatus(config)),
          ),
        ),
      }),
      this.fb.group({
        title: Days[Days.Friday],
        times: this.fb.array(
          sortedTimeSlotsConfig[Days.Friday].map((config: TimeSlotsConfig) =>
            this.fb.group(this.mapActiveStatus(config)),
          ),
        ),
      }),
      this.fb.group({
        title: Days[Days.Saturday],
        times: this.fb.array(
          sortedTimeSlotsConfig[Days.Saturday].map((config: TimeSlotsConfig) =>
            this.fb.group(this.mapActiveStatus(config)),
          ),
        ),
      }),
      this.fb.group({
        title: Days[Days.Sunday],
        times: this.fb.array(
          sortedTimeSlotsConfig[Days.Sunday].map((config: TimeSlotsConfig) =>
            this.fb.group(this.mapActiveStatus(config)),
          ),
        ),
      }),
    ]);
  }

  private mapActiveStatus(config: TimeSlotsConfig) {
    return {
      ...config,
      status: config.status === Status.Active,
      subscriptionStatus: config.subscriptionStatus === Status.Active,
    };
  }

  private initTabs(): void {
    this.isLoading = true;
    forkJoin([
      this.carwashBranchService.getBranch(this.branchId),
      this.carwashBranchTimeServiceService.getTimesConfig(),
    ]).subscribe(
      ([branch, timeSlotsConfig]: [CarwashBranch, TimeSlotsConfig[]]) => {
        const { cwGarageBranchSSTD: branchTimeSlots } = branch;

        this.initForm(this.mergeSlots(timeSlotsConfig, branchTimeSlots));
        this.isLoading = false;
      },
    );
  }

  private mergeSlots(
    timeSlotsConfig: TimeSlotsConfig[],
    branchTimeSlots: CarwashBranchTimeSlot[],
  ): TimeSlotsConfig[] {
    return timeSlotsConfig.map((timeSlotConfig: TimeSlotsConfig) => {
      const branchTimeSlot = branchTimeSlots.find(
        (branchTimeSlot: CarwashBranchTimeSlot) =>
          branchTimeSlot.timeSlotTimeId === timeSlotConfig.id,
      );
      return {
        ...timeSlotConfig,
        status: branchTimeSlot ? branchTimeSlot.status : timeSlotConfig.status,
        subscriptionStatus: branchTimeSlot ? branchTimeSlot.subscriptionStatus : timeSlotConfig.subscriptionStatus,
      };
    });
  }

  getTimes(controls) {
    return controls.filter((timeConfigControl) =>
      timeConfigControl.value.time.endsWith(':00:00'),
    );
  }

  toggle(
    timeConfigControl: FormGroup,
    status: boolean,
    dayForm: FormGroup,
  ): void {
    if (timeConfigControl.value.time.endsWith(':00:00')) {
      const index = (dayForm.controls as any as FormGroup[]).indexOf(
        timeConfigControl,
      );
      /** Set same status for the whole hour (x:15:00, x:30:00 and x:45:00) */
      dayForm.controls[index + 1].get('status').setValue(status);
      dayForm.controls[index + 2].get('status').setValue(status);
      dayForm.controls[index + 3].get('status').setValue(status);
    }
  }

  toggleSubscriptionStatus(
    timeConfigControl: FormGroup,
    status: boolean,
    dayForm: FormGroup,
  ): void {
    if (timeConfigControl.value.time.endsWith(':00:00')) {
      const index = (dayForm.controls as any as FormGroup[]).indexOf(
        timeConfigControl,
      );
      /** Set same status for the whole hour (x:15:00, x:30:00 and x:45:00) */
      dayForm.controls[index + 1].get('subscriptionStatus').setValue(status);
      dayForm.controls[index + 2].get('subscriptionStatus').setValue(status);
      dayForm.controls[index + 3].get('subscriptionStatus').setValue(status);
    }
  }

  getExactHoursOnly(timeConfigControls: FormGroup[]): FormGroup[] {
    return timeConfigControls.filter((control) =>
      control.value.time.endsWith(':00:00'),
    );
  }
}
