import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { timesWash } from '@app/modules/carwash/modules/carwash-packages/shared/components/carwash-package-time-wash/carwash-package-time-wash-data';
import { Nullable } from '@shared/models';

@Component({
  selector: 'app-carwash-ticket-time-wash',
  templateUrl: './carwash-package-time-wash.component.html',
  styleUrls: ['./carwash-package-time-wash.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarwashPackageTimeWashComponent {
  @Input() set time(time: number) {
    this.activeTime = time;
  }
  @Output() changedTime: EventEmitter<Nullable<number>> = new EventEmitter<
    Nullable<number>
  >();

  activeTime: number;
  readonly times = timesWash;

  changeTime(event: boolean, time: number): void {
    this.changedTime.emit(event ? time : null);
  }
}
