import { NgModule } from '@angular/core';
import { ItemsRoutingModule } from './items-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ItemsListComponent } from './components/items-list/items-list.component';
import { ItemCreateComponent } from './components/item-create/item-create.component';
import { CategoryListComponent } from './components/category-list/category-list.component';
import { CategoryCreateComponent } from './components/category-create/category-create.component';

@NgModule({
  imports: [ItemsRoutingModule, SharedModule.forRoot()],
  declarations: [
    ItemsListComponent,
    ItemCreateComponent,
    CategoryListComponent,
    CategoryCreateComponent,
  ],
})
export class ItemsModule {}
