import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CarwashCompanyHttpService } from '@shared/service/carwash-company/carwash-company-http.service';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { CarwashCompany } from '@shared/models';
import { PageInfo } from '@shared/models/global/response';
import { PaginationHelper } from '@app/shared/helpers/pagination-helper';

@Injectable({ providedIn: 'root' })
export class CarwashCompanyService {
  readonly pagination$: Subject<PageInfo> = new Subject<PageInfo>();

  constructor(
    private readonly carwashCompanyHttpService: CarwashCompanyHttpService,
  ) {}

  getCompanies(filters?: Record<string, string>): Observable<CarwashCompany[]> {
    const toData = (res: ApiResponse<CarwashCompany[]>) => res.data;
    const toPagination = (res: ApiResponse<CarwashCompany[]>) =>
      this.pagination$.next(PaginationHelper.camelToSnakeCase(res.pageInfo));

    return this.carwashCompanyHttpService
      .getCompanies(filters)
      .pipe(tap(toPagination), map(toData));
  }

  getCompaniesWithPagination(
    filters?: Record<string, string>,
  ): Observable<any> {
    return this.carwashCompanyHttpService.getCompaniesWithPagination(filters);
  }

  createCompany(data: Partial<CarwashCompany>): Observable<CarwashCompany> {
    const toCompany = (res: ApiResponse<CarwashCompany>) => res.data;

    return this.carwashCompanyHttpService
      .createCompany(data)
      .pipe(map(toCompany));
  }

  updateCompany(
    id: number,
    data: Partial<CarwashCompany>,
  ): Observable<CarwashCompany> {
    const toCompany = (res: ApiResponse<CarwashCompany>) => res.data;

    return this.carwashCompanyHttpService
      .updateCompany(id, data)
      .pipe(map(toCompany));
  }

  removeCompany(id: number): Observable<void> {
    return this.carwashCompanyHttpService.removeCompany(id);
  }
}
