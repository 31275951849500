import { Component, OnInit } from '@angular/core';
import { BranchTimeslotsComponent } from './branch-timeslots/branch-timeslots.component';
import { BranchItemPriceComponent } from './branch-item-price/branch-item-price.component';
import { CreateMinOrderPerAreaComponent } from './create-min-order-per-area/create-min-order-per-area.component';
import { SpecialIconsComponent } from './special-icons/special-icons.component';
import { SplashBannerComponent } from './splash-banner/splash-banner.component';

@Component({
  selector: 'app-create-branch',
  templateUrl: './create-branch.component.html',
  styleUrls: ['./create-branch.component.scss']
})
export class CreateBranchComponent implements OnInit {
  isBigContainer = false;
  hasCard = true;
  constructor() {}

  ngOnInit() {}

  onRouterOutletActivate(event) {
    this.isBigContainer =
      event instanceof BranchTimeslotsComponent ||
      event instanceof BranchItemPriceComponent ||
      event instanceof CreateMinOrderPerAreaComponent ||
      event instanceof SpecialIconsComponent ||
      event instanceof SplashBannerComponent ;
    this.hasCard = !(event instanceof SpecialIconsComponent);
  }
}
