import { PaginationHelper } from '@app/shared/helpers/pagination-helper';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { GlobalService } from '@shared/service/global.service';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { CarwashBranch } from '@shared/models';
import { PageInfo } from '@app/shared/models/global/response';

@Injectable({ providedIn: 'root' })
export class CarwashBranchService {
  readonly url = environment.jcDeliveryServer + 'cw-garage-branches';
  readonly pagination$: Subject<PageInfo> = new Subject<PageInfo>();

  constructor(
    private readonly http: HttpClient,
    private readonly globalService: GlobalService,
  ) {}

  createBranch(data: Partial<CarwashBranch>): Observable<CarwashBranch> {
    const countryId = this.globalService.countryId;
    return this.http.post<CarwashBranch>(this.url, {
      ...this.getMappedItem(data, false),
      countryId,
    });
  }

  updateBranch(id: number, data: Partial<CarwashBranch>) {
    return this.http.patch<CarwashBranch>(
      `${this.url}/${id}`,
      this.getMappedItem(data, false),
    );
  }

  getBranch(id: number): Observable<CarwashBranch> {
    return this.http
      .get<ApiResponse<CarwashBranch>>(`${this.url}/${id}`)
      .pipe(
        map((res: ApiResponse<CarwashBranch>) => this.getMappedItem(res.data)),
      );
  }

  getBranches(
    filters?: Record<string, string | string[]>,
  ): Observable<CarwashBranch[]> {
    const countryId: string = this.globalService.countryId.toString();
    const params = new HttpParams().appendAll({
      ...(filters || {}),
      countryId,
    });

    const toPagination = (res: ApiResponse<CarwashBranch[]>) =>
      this.pagination$.next(PaginationHelper.camelToSnakeCase(res.pageInfo));

    return this.http
      .get<ApiResponse<CarwashBranch[]>>(this.url, { params })
      .pipe(
        tap(toPagination),
        map((res: ApiResponse<CarwashBranch[]>) =>
          res.data.map((item) => this.getMappedItem(item)),
        ),
      );
  }

  private getMappedItem(
    item: CarwashBranch | Partial<CarwashBranch>,
    isUtcToLoacal = true,
  ): CarwashBranch {
    if (!item.openTime) {
      return item as CarwashBranch;
    }
    const convertionFn = isUtcToLoacal
      ? this.globalService.utcToLocalTime
      : this.globalService.localToUtcTime;
    return {
      ...item,
      openTime: convertionFn(item.openTime),
      closeTime: convertionFn(item.closeTime),
      openingHours:
        item.openingHours?.map((time) => {
          return {
            ...time,
            openTime: convertionFn(time.openTime, 'HH:mm'),
            closeTime: convertionFn(time.closeTime, 'HH:mm'),
          };
        }) || [],
    } as CarwashBranch;
  }
}
