import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { FormStatus } from '@shared/enums/form-status.enum';
import { CarwashCompany, Nullable } from "@shared/models";
import { GlobalService } from '@shared/service/global.service';
import { CarWashPackage } from "@shared/models/carwash/car-wash-package.model";
import { SelectOptions } from '@shared/models/global/response';
import { CarWashPackageService } from '@shared/service/car-wash-packages';
@Component({
  selector: 'app-car-wash-package-form',
  templateUrl: './car-wash-package-form.component.html',
  styleUrls: ['./car-wash-package-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarWashPackageFormComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;

  @Input() carWashPackage: CarWashPackage;
  @Input() carWashPackageImageEn: string;
  @Input() carWashPackageImageAr: string;
  @Output() formChanged: EventEmitter<Partial<CarwashCompany>> =
    new EventEmitter<Partial<CarwashCompany>>();
  @Output() formStatusChanged: EventEmitter<FormStatus> =
    new EventEmitter<FormStatus>();
  @Output() changeCompanyLogoFile: EventEmitter<File> =
    new EventEmitter<File>();
  @Output() changeImageEnFile: EventEmitter<File> = new EventEmitter<File>();
  @Output() changeImageArFile: EventEmitter<File> = new EventEmitter<File>();

  private readonly destroy$: Subject<void> = new Subject<void>() ;
  carWashPackageTypes$: Observable<Array<SelectOptions<string>>>;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly globalService: GlobalService,
    private carWashPackageService: CarWashPackageService
  ) {}

  get carWashPackageImageEnUrl(): string {
    return this.form.get('image').get('en').value || this.carWashPackageImageEn;
  }

  get carWashPackageImageArUrl(): string {
    return (
      this.form.get('image').get('en').value || this.carWashPackageImageAr
    );
  }

  get includeDetails(): UntypedFormArray {
    return this.form.get('included') as UntypedFormArray;
  }

  get notIncludeDetails(): UntypedFormArray {
    return this.form.get('notIncluded') as UntypedFormArray;
  }

  get includeNewDetails(): UntypedFormArray {
    return this.form.get('includedNew') as UntypedFormArray;
  }

  get notIncludeNewDetails(): UntypedFormArray {
    return this.form.get('notIncludedNew') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.initForm();
    this.handleFormChanges();
    this.handleFormStatusChanges();
    this.loadCarWashPackageTypes();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateImageEnFile(file: File): void {
    this.changeImageEnFile.next(file);
  }
  updateImageArFile(file: File): void {
    this.changeImageArFile.next(file);
  }
  private initForm(): void {
    this.form = this.fb.group({
      name: this.fb.group({
        en: ['', [Validators.maxLength(25)]],
        ar: ['', [Validators.maxLength(25)]],
      }),
      description: this.fb.group({
        en: ['', [Validators.maxLength(250)]],
        ar: ['', [Validators.maxLength(250)]],
      }),
      image: this.fb.group({
        en: [''],
        ar: [''],
      }),
      included: this.fb.array(this.setIncludeDetailsInitData(this.carWashPackage)),
      notIncluded: this.fb.array(
        this.setNotIncludeDetailsInitData(this.carWashPackage),
      ),
      includedNew: this.fb.array(this.setIncludeDetailsNewInitData(this.carWashPackage)),
      notIncludedNew: this.fb.array(
        this.setNotIncludeDetailsNewInitData(this.carWashPackage),
      ),
      timeToComplete: 1,
      type: 'oneTime',
      countryId: this.globalService.countryId,
    });
    this.patchForm();
  }

  private patchForm(): void {
    if (!this.carWashPackage) {
      return;
    }

    this.form.patchValue(this.carWashPackage);
    this.formChanged.emit(this.form.value);
  }

  private handleFormChanges(): void {
    const emitValue = (value: Partial<CarwashCompany>) =>
      this.formChanged.emit(value);

    this.form.valueChanges
      .pipe(debounceTime(500), tap(emitValue), takeUntil(this.destroy$))
      .subscribe();
  }

  private handleFormStatusChanges(): void {
    const emitStatus = (value: FormStatus) =>
      this.formStatusChanged.emit(value);

    this.form.statusChanges
      .pipe(
        startWith(this.form.status),
        distinctUntilChanged(),
        tap(emitStatus),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  setIncludeDetailsInitData(cwPackage: Nullable<CarWashPackage>) {
    if (cwPackage && cwPackage.included.length) {
      return cwPackage.included
        .map(() => this.createFormDetail(true));
    } else {
      return [this.createFormDetail(true)];
    }
  }
  setNotIncludeDetailsInitData(ticket: Nullable<CarWashPackage>) {
    if (ticket && ticket.notIncluded.length) {
      return ticket.notIncluded
        .map(() => this.createFormDetail());
    } else {
      return [this.createFormDetail()];
    }
  }

  setIncludeDetailsNewInitData(cwPackage: Nullable<CarWashPackage>) {
    if (cwPackage && cwPackage.includedNew.length) {
      return cwPackage.includedNew
        .map(() => this.createFormDetail(true));
    } else {
      return [this.createFormDetail(true)];
    }
  }
  setNotIncludeDetailsNewInitData(ticket: Nullable<CarWashPackage>) {
    if (ticket && ticket.notIncludedNew.length) {
      return ticket.notIncludedNew
        .map(() => this.createFormDetail());
    } else {
      return [this.createFormDetail()];
    }
  }

  private createFormDetail(isInclude: boolean = false): UntypedFormGroup {
    return this.fb.group({
      name: this.fb.group({
        en: ['', [Validators.maxLength(25)]],
        ar: ['', [Validators.maxLength(25)]],
      }),
      image: isInclude ? 'https://d2326kvh047ee7.cloudfront.net/V3/appicons/tick%403x.png' : 'https://d2326kvh047ee7.cloudfront.net/V3/appicons/checkmark%403x.png' ,
    })
  }

  addDetail(isIncludedDetails: boolean = false): void {
    const details: UntypedFormArray = isIncludedDetails
      ? this.includeDetails
      : this.notIncludeDetails;

    details.push(this.createFormDetail(isIncludedDetails));
  }

  removeDetail(index: number, isIncludedDetails: boolean = false): void {
    const details: UntypedFormArray = isIncludedDetails
      ? this.includeDetails
      : this.notIncludeDetails;

    details.removeAt(index);
  }

  addNewDetail(isIncludedDetails: boolean = false): void {
    const details: UntypedFormArray = isIncludedDetails
      ? this.includeNewDetails
      : this.notIncludeNewDetails;

    details.push(this.createFormDetail(isIncludedDetails));
  }

  removeNewDetail(index: number, isIncludedDetails: boolean = false): void {
    const details: UntypedFormArray = isIncludedDetails
      ? this.includeNewDetails
      : this.notIncludeNewDetails;

    details.removeAt(index);
  }

  private loadCarWashPackageTypes(): void {
    this.carWashPackageTypes$ = this.carWashPackageService.getCarWashPackageTypes();
  }
}
