import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OffersListComponent } from './components/offers-list/offers-list.component';

import { OfferCreateComponent } from './components/offer-create/offer-create.component';
import { CouponListComponent } from './components/coupon-list/coupon-list.component';
import { CouponDetailsComponent } from './components/coupon-details/coupon-details.component';

const routes: Routes = [
  {
    path: 'offer-list',
    component: OffersListComponent,
    data: {
      title: 'List of Offers'
    }
  },
  {
    path: 'offer-create',
    component: OfferCreateComponent,
    data: {
      title: 'Create Offer'
    }
  },
  {
    path: 'coupon-list',
    component: CouponListComponent,
    data: {
      title: 'List of Coupons'
    }
  },
  {
    path: 'coupon-details',
    component: CouponDetailsComponent,
    data: {
      title: 'Coupon Details'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OffersRoutingModule {}
