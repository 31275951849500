import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
})
export class SkeletonLoaderComponent {
  /** Default background color is light grey ($grey-light), this input is to make it darker ($neutral-n200) */
  @Input() isGrey: boolean;
  @Input() isPurple: boolean;
  @Input() extraCssClasses: string;
}
