import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import {
  SupplierFormList,
  SupplierToBeSaved
} from 'src/app/shared/models/laundries/suppliers';
import {
  PageInfo,
  BackEndResponse
} from 'src/app/shared/models/global/response';
import { Subject } from 'rxjs';
import { DataExchangeWithBackend } from 'src/app/shared/models/user/generic-backend';
import { GlobalService } from 'src/app/shared/service/global.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { SupplierService } from 'src/app/shared/service/supplier.service';
import { DataManager } from 'src/app/shared/helpers/data-manager';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-list-supplier',
  templateUrl: './list-supplier.component.html',
  styleUrls: ['./list-supplier.component.css']
})
export class ListSupplierComponent implements OnInit {
  suppliers: SupplierFormList[];
  pagination: PageInfo;
  isShown: boolean;
  isCapacityModalShown: boolean;
  toggleDeleteModal = false;
  loader = false;
  supplier: SupplierToBeSaved;
  isUpdate: boolean;
  refreshingCar = false;
  dataSubject: Subject<DataExchangeWithBackend> = new Subject();
  currency: string;
  decimal: number;
  isPayModalOpened = false;
  isConfirmModalOpened = false;
  amountToBePaid = 0.000;
  selectedSupplier: SupplierFormList;
  @ViewChild('paySupplierAmount', {static: true}) paySupplierModel: NgModel;
  constructor(
    private globalService: GlobalService,
    private supplierService: SupplierService,
    private toast: ToastService,
    private router: Router
  ) {}

  ngOnInit() {
    const showPage =
      window.localStorage.getItem('showPartner') === 'true' ? true : false;
    console.log(showPage);
    this.currency = this.globalService.currency;
    this.decimal = parseInt(localStorage.getItem('decimal'), 10);
    console.log(this.decimal);

    if (showPage) {
      this.getPartners({ page: 1 });
      setInterval(() => {
        this.getPartners({
          page: this.pagination.current_page ? this.pagination.current_page : 1
        });
      }, 30000);
    } else {
      this.router.navigate(['dashboard/not-found']);
    }
  }

  getPartners(params?: any) {
    /* this.drivers = [];
    this.logisticsDriver
      .getAllDriverForLogistics(this.globalService.headers, params)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.drivers = response.data;
            this.pagination = response.page_info;
          }
        },
        error => {
          this.toast.error(error.error.message);
        }
      ); */
    DataManager.subscribeToAnObservable<BackEndResponse, any>(
      this.supplierService.getSuppliers(this.globalService.headers, params),
      this.suppliers,
      this.toast,
      false,
      this.dataSubject
    );
    this.dataSubject.subscribe(data => {
      this.suppliers = data.list;
      this.pagination = data.pagination;
    });
  }

  getPage(event) {
    this.getPartners({ page: event });
  }

  refreshData(isCreate: boolean) {
    this.getPage(this.pagination.current_page);
    this.refreshingCar = true;
  }

  showAreas(supplier: SupplierFormList) {
    let html = '';
    let overFifteen = false;
    supplier.suppliers_area.forEach((e, i) => {
      if (i < 15) {
        html +=
          '<span class="green-blue-color">' +
          (i + 1) +
          '- ' +
          e.name +
          '</span><br>';
      } else {
        overFifteen = true;
      }
    });
    html = overFifteen
      ? html + '<span class="green-blue-color"> More ...</span><br>'
      : html;
    return supplier.suppliers_area.length > 0
      ? html
      : '<span class="error-text"> Not Assigned to Any Area yet</span>';
  }

  triggerDelete(supplier: SupplierToBeSaved) {
    this.supplier = supplier;
    this.toggleDeleteModal = true;
  }

  deletePartner(event) {
    this.loader = true;
    this.supplierService
      .deleteSupplier(this.globalService.headers, this.supplier.id)
      .subscribe(
        response => {
          this.loader = false;
          this.toggleDeleteModal = false;
          this.refreshingCar = true;
          this.getPage(this.pagination.current_page);
        }
      );
  }

  isClosed(event) {
    this.isShown = false;
    this.isUpdate = false;
  }

  openDialog(isUpdate: boolean, supplier?: SupplierToBeSaved) {
    this.isShown = true;
    this.isUpdate = isUpdate;
    this.supplier = isUpdate ? supplier : null;
  }

  openCapacityModal(supplier: SupplierToBeSaved) {
    this.isCapacityModalShown = true;
    this.supplier = supplier;
  }

  toggleStatus(supplier: SupplierFormList) {
    this.refreshingCar = false;
    this.supplierService
      .changeStatus(this.globalService.headers, {
        id: supplier.id,
        status: supplier.status === 'active' ? 'inactive' : 'active'
      })
      .subscribe(
        response => {
          if (response.code === 200) {
            this.getPage(this.pagination.current_page);
            this.refreshingCar = true;
          } else {
            this.toast.error(response.message);
            this.getPage(this.pagination.current_page);
          }
        },
        error => {
          this.getPage(this.pagination.current_page);
        }
      );
  }

  isDeleteClosed(event) {
    this.toggleDeleteModal = false;
  }

  getBalanceMinusEnteredValue() {
    return this.selectedSupplier ? this.selectedSupplier.balance - this.amountToBePaid : 0.000;
  }

  openPayDialog(supplier: SupplierFormList) {
    this.selectedSupplier = supplier;
    this.isPayModalOpened = true;
  }

  closePaymentModal(event) {
    this.isPayModalOpened  = false;
    this.paySupplierModel.reset();
    this.amountToBePaid = 0;
  }

  closeConfirmationModal(event) {
    this.isConfirmModalOpened = false;
  }

  paySuppler() {
    this.loader = true;
    this.supplierService.paySupplier(this.globalService.headers, {
      supplier_id: this.selectedSupplier.id,
      amount: this.amountToBePaid
    }).subscribe(
      response => {
        if (response.code === 200) {
          this.toast.success(response.message);
          this.isConfirmModalOpened = false;
          this.isPayModalOpened = false;
          this.getPage(this.pagination.current_page);
        } else {
          this.toast.error(response.message);
        }
        this.loader = false;
      },
      error => {
        this.loader = false;
      }
    );
  }

  showBalance(supplier: SupplierFormList) {
    return (supplier.balance) ? supplier.balance.toFixed(this.decimal) : 0;
  }

  haveAccess() {
    return localStorage.getItem('email') === 'aelmorsy@justclean.com' ||
      localStorage.getItem('email') === 'mellewaa@Justclean.com' ||
      localStorage.getItem('email') === 'mhamdy@justclean.com' ||
    localStorage.getItem('email') === 'superadminkw@justclean.com';
  }

}
