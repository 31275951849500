export class ArrayFunctions {
  public static checkElementExistNoneNested<T>(
    list: T[],
    elementToFind: T
  ): boolean {
    return list.find(element => element === elementToFind) ? true : false;
  }

  public static checkElementExistNested<T>(
    list: T[],
    elementToFind: any,
    attribute: string
  ): boolean {
    return list.find(element => element[attribute] === elementToFind)
      ? true
      : false;
  }

  public static findElementInArray<T>(
    list: T[],
    elementToFind: any,
    isNested: boolean,
    attribute?: string
  ): number {
    let index = -1;
    list.forEach((element, i) => {
      if (isNested) {
        index = element[attribute] === elementToFind ? i : index;
      } else {
        index = element === elementToFind ? i : index;
      }
    });
    return index;
  }

  public static addElementToArray<T>(
    list: T[],
    element: any,
    isNested: boolean,
    attribute?: string
  ) {
    const doesExist = isNested
      ? this.checkElementExistNested(list, element, attribute)
      : this.checkElementExistNoneNested(list, element);
    if (!doesExist) {
      list.push(element);
    }

    return list;
  }

  public static removeElementFromArray<T>(
    list: T[],
    element: any,
    isNested: boolean,
    attribute?: string
  ) {
    const index = this.findElementInArray(list, element, isNested, attribute);
    if (index >= 0) {
      list.splice(index, 1);
    }
    return list;
  }

  public static toggleElement<T>(
    list: T[],
    element: any,
    isNested: boolean,
    attribute?: string
  ) {
    if (this.findElementInArray(list, element, isNested, attribute) < 0) {
      this.addElementToArray(list, element, isNested, attribute);
    } else {
      this.removeElementFromArray(list, element, isNested, attribute);
    }
  }

  public static toggleElementWithMax<T>(
    list: T[],
    element: any,
    isNested: boolean,
    capacity: number,
    attribute?: string
  ) {
    let isProcessed = false;
    if (
      this.findElementInArray(
        list,
        isNested ? element[attribute] : element,
        isNested,
        attribute
      ) < 0
    ) {
      if (capacity > list.length) {
        this.addElementToArray(list, element, isNested, attribute);
        isProcessed = true;
      }
    } else {
      this.removeElementFromArray(
        list,
        isNested ? element[attribute] : element,
        isNested,
        attribute
      );
      isProcessed = true;
    }

    return isProcessed;
  }
}
