export interface PosCondition {
  id: number;
  condition: string;
  status: string;
}

export class PosContractData {
  id: number;
  startDate: string;
  expiryDate: string;
  status: string;
  amount: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: any;
  laundryPosConditionId: number;
  laundryBranchId?: number;
  hcCompanyBranchId?:number;
  cwGarageBranchId?: number;
  userAdminId: number;
  countryId: number;
  groupId: number;
  subscriptionPackageId?: number;
  subscriptionCustomPackageId?: number;
  comment: string;
  type: string;
}

export class PosCustomContractData {
  id?: number;
  startDate?: string;
  expiryDate?: string;
  amount?: number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  laundryBranchId?: number;
  cwGarageBranchId? :number;
  hcCompanyBranchId?:number;
  subscriptionPackageId?: number;
}

export interface PosContractCreationBody {
  startDate: string;
  expiryDate: string;
  status: string;
  amount: number;
  countryId?: number;
  groupId?: number;
  userAdminId?: number;
  laundryBranchId?: number;
  cwGarageBranchId? :number;
  hcCompanyBranchId?:number;
  laundryPosConditionId: number;
  comment: string;
  subscriptionPackageId?: number;
  subscriptionCustomPackageId?: number;
  type: string;
  id?: number;
}
