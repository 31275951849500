import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone'

@Pipe({
  name: 'jcLocalToUtcDate'
})
export class LocalToUtcDatePipe implements PipeTransform {

  transform(date: string | Date, format?: string): string {
    if (!date) {
      return null;
    }
    const timeZone = localStorage.getItem('timeZone');
    const dateOnly = moment.parseZone(date).format('MMM DD YYYY');
    const time = moment.parseZone(date).format('HH:mm:ss');
    const result = moment.tz(`${dateOnly} ${time}`, 'MMM DD YYYY HH:mm:ss', timeZone).utc().format(format);
    return result;
  }
}
