import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { FormStatus } from '@shared/enums/form-status.enum';
import { CrudService } from '@app/shared/service/crud';
import { GlobalService } from '@app/shared/service/global.service';

@Component({
  templateUrl: './generated-page-edit-dialog.component.html',
})
export class GeneratedPageEditDialogComponent implements OnInit {
  isEditMode: boolean;
  isDisableButton: boolean;
  formData: any;
  isLoading: boolean;
  pageName: string;

  private timeFields: Array<string> = [];
  private dateFields: Array<string> = [];
  private dateTimeFields: Array<string> = [];
  private readonly destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data:
      | {
          dataRow: any;
          PageNamePrefix: string;
          foreignKeyParam: Object;
        }
      | any,
    private readonly dialogRef: DialogRef<GeneratedPageEditDialogComponent>,
    private readonly crudService: CrudService,
    private readonly cdr: ChangeDetectorRef,
    private readonly globalService: GlobalService,
  ) {}

  ngOnInit(): void {
    this.isEditMode =
      this.data.dataRow && !this.data.dataRow?.hasOwnProperty('length');
    this.pageName = `${this.crudService.selectedCrud.pageName[0].toUpperCase()}${this.crudService.selectedCrud.pageName
      .slice(1)
      .toLowerCase()}`;
    this.handleForignKeyParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(): void {
    this.dialogRef.close();
  }

  formChanged(event: {
    generatedPage: any;
    numericTranslationFields: Array<string>;
    dateFields: Array<string>;
    timeFields: Array<string>;
    dateTimeFields: Array<string>;
  }): void {
    this.formData = {
      ...event.generatedPage,
    };

    this.dateFields = event.dateFields;
    this.timeFields = event.timeFields;
    this.dateTimeFields = event.dateTimeFields;

    this.handleNumericTranslationFields(event.numericTranslationFields);
  }

  formStatusChanged(status: FormStatus): void {
    this.isDisableButton = status === FormStatus.Invalid;
  }

  createGeneratedPage(): void {
    this.handleDateFields(this.dateFields);
    this.handleTimeFields(this.timeFields);
    this.handleDateTimeFields(this.dateTimeFields);

    const data: any = {
      ...this.formData,
      ...(this.data?.foreignKeyParam || {}),
    };
    const action$ = this.isEditMode
      ? this.crudService.update(this.data.dataRow.id, data)
      : this.crudService.create(data);

    this.isLoading = true;
    action$
      .pipe(
        tap(() => {
          this.isLoading = false;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (el: any) => {
          this.dialogRef.close(el);
        },
        (error) => {
          this.isLoading = false;
          this.cdr.markForCheck();
        },
      );
  }

  changeLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
    this.cdr.markForCheck();
  }

  private handleDateFields(dateFields: Array<string>): void {
    dateFields?.forEach(
      (dateFieldName) =>
        (this.formData[dateFieldName] = this.globalService.localToUtcDate(
          this.formData[dateFieldName],
        )),
    );
  }

  private handleTimeFields(timeFields: Array<string>): void {
    timeFields?.forEach(
      (timeFieldName) =>
        (this.formData[timeFieldName] = this.globalService.localToUtcTime(
          this.formData[timeFieldName],
          'HH:mm',
        )),
    );
  }

  private handleDateTimeFields(dateTimeFields: Array<string>): void {
    dateTimeFields?.forEach(
      (dateTimeFieldName) =>
        (this.formData[dateTimeFieldName] = this.globalService.localToUtcDate(
          this.formData[dateTimeFieldName],
        )),
    );
  }

  private handleNumericTranslationFields(
    numericTranslationFields: Array<string>,
  ): void {
    numericTranslationFields?.forEach((numericFieldName) => {
      this.formData[numericFieldName].ar =
        this.formData[numericFieldName]?.ar?.toString();
      this.formData[numericFieldName].en =
        this.formData[numericFieldName]?.en?.toString();
    });
  }

  private handleForignKeyParams() {
    if (this.data?.foreignKeyParam) {
      this.crudService.adjustNumericParameters(this.data.foreignKeyParam);
    }
  }
}
