export class PageInfo {
  page_count?: any;
  current_page?: any;
  next_page?: any;
  item_count?: number;
}

export class NewPageInfo {
  pageCount?: any;
  currentPage?: any;
  nextPage?: any;
  itemCount?: number;
}

export class BackEndResponse {
  status: boolean;
  code: number;
  message: string;
  data: any | any[];
  page_info: PageInfo;
  pageInfo: NewPageInfo;
}

export class NewBackEndResponse {
  status: boolean;
  code: number;
  message: string;
  data: any | any[];
  pageInfo: NewPageInfo;
}

export class PosBackendResponse {
  code: number;
  isError: boolean;
  status: string;
  message: string;
  data: any;
}

export const Currency = [
  {
    id: 1,
    currency: 'KWD',
    name: 'Kuwaiti Dinar',
    decimalPlaces: 3
  },
  {
    id: 2,
    currency: 'AED',
    name: 'Dirham',
    decimalPlaces: 2
  },
  {
    id: 3,
    currency: 'BHD',
    name: 'Bahraini Dinar',
    decimalPlaces: 3
  },
  {
    id: 4,
    currency: 'SAR',
    name: 'Saudi Riyal',
    decimalPlaces: 2
  },
  {
    id: 5,
    currency: 'QAR',
    name: 'Qatari Riyal',
    decimalPlaces: 2
  }
];

export class SelectOptions<T> {
  value: T;
  label: string;
  group?: boolean;
  selected?: boolean;
  disabled?: boolean;
}

export interface MarkerForMap {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

export interface IdType {
  id: number;
  type: string;
}

export interface CompensationType extends IdType {
  has_promo_code: boolean;
}


export interface JustCleanLaundryPercentage {
  laundry: number;
  justclean: number;
}
