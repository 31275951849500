import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'jcUtcToLocalTime'
})
export class UtcToLocalTimePipe implements PipeTransform {

  transform(time: string, format?: string): string | Date {
    if (!time) {
      return null;
    }

    const timeZone = localStorage.getItem('timeZone');
    time = time.replace('.', ':');
    const dateOnly = moment().format('ddd MMM DD YYYY');
    return moment.utc(`${dateOnly} ${time}`).tz(timeZone).format(format);
  }
}
