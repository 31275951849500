import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersSegementsMainComponent } from './components/users-segements-main/users-segements-main.component';


const routes: Routes = [
  {
    path: 'users-segments',
    component: UsersSegementsMainComponent,
    data: {
      title: 'Marketing Segments'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MarketingRoutingModule { }
