export class CustomerFromList {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  is_email_verified: number;
  is_firebase_user: number;
  phone: string;
  orders_total_spend: string;
}

export class UserProfile {
  ishtirakat_balance: number;
  gift_balance: number;
  cashback: number;
  item_offer: number;
  referrals: number;
}

export class Governorate {
  name: string;
}

export class Area {
  name: string;
  governorate: Governorate;
}

export class UserAddress {
  id: number;
  name: string;
  block: string;
  street: string;
  avenue: string;
  house_no: string;
  apartment_no: string;
  floor: string;
  latitude: number;
  longitude: number;
  directions: string;
  area_id: number;
  country_id: number;
  area: Area;
  description?: string;
  phone: string;
  phone_ext: string;
}

export class UserDetails {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  joining_date: Date;
  order_last_date?: any;
  order_first_date?: any;
  user_profiles: UserProfile[];
  user_addresses: UserAddress[];
  app_version: string;
  device_type: string;
  user_type_id?: number;
}

export class CustomerDetails {
  user_details: UserDetails;
  total_orders: number;
}

export class UpdatedCustomer {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  phone_ext: string;
  photo_url: string;
  password_hash: string;
  password_salt: string;
  uid: string;
  firebase_uid: string;
  is_firebase_user: boolean;
  is_email_verified: boolean;
  status: string;
  last_login: Date;
  google_id?: any;
  google_display_name?: any;
  google_photo_url?: any;
  facebook_id?: any;
  facebook_display_name?: any;
  facebook_photo_url?: any;
  github_id?: any;
  github_display_name?: any;
  github_photo_url?: any;
  order_first_date?: any;
  order_last_date?: any;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  preferred_language_id?: any;
  preferred_country_id?: any;
}

export class CustomerAddress {
  name: string;
  block: string;
  street: string;
  avenue: string;
  houseNo: string;
  apartmentNo: string;
  floor: string;
  directions: string;
  latitude: number;
  longitude: number;
  areaId: number;
  userId: number;
}

export interface ChangePasswordBody {
  password: string;
}

export interface ChangeUserType {
  userTypeId: number;
}

export class LaundryBranch {
  code: string;
  name: string;
}

export class CustomerOrder {
  id: number;
  unique_id: string;
  item_count: number;
  bill_amount: number;
  is_paid: boolean;
  placed_at: Date;
  laundry_branch: LaundryBranch;
}
export class LaundryBranchCustomerOrder {
  id: number;
  code: string;
  name: string;
}

export class StatusesUserCustomerOrder {
  id: number;
  name: string;
}

export class OrderCustomerNew {
  id: number;
  unique_id: string;
  type: string;
  item_count: number;
  item_merchandise_count: number;
  bill_amount_total: number;
  bill_amount_remaining: number;
  bill_amount_items: number;
  bill_amount_merchandise: number;
  commission: number;
  extra_charges: number;
  extra_discounts: number;
  service_charge: number;
  delivery_charge: number;
  is_fast_service: boolean;
  is_paid: boolean;
  is_rated: boolean;
  is_assigned: boolean;
  is_tracking_enabled: boolean;
  pickup_at: Date;
  delivery_at: Date;
  special_instruction: string;
  placed_at: Date;
  updated_at: Date;
  deleted_at?: any;
  basket_type_id: number;
  laundry_branch_id: number;
  country_id: number;
  group_id: number;
  user_id: number;
  pickup_address_id: number;
  delivery_address_id: number;
  pickup_timeslot_id: number;
  delivery_timeslot_id: number;
  statuses_user_id: number;
  payment_status_id: number;
  laundry_branch: LaundryBranchCustomerOrder;
  statuses_user: StatusesUserCustomerOrder;
}

export interface GiftToUserBody {
  user_id: number;
  promo_code: string;
}

export namespace CustomerNameSpace {
  export interface LaundryBranchForSchedule {
    id: number;
    code: string;
    name: string;
    manager: string;
    mobile: string;
    hasTracking: boolean;
    laundryBag: boolean;
    hasDiscount: boolean;
    hasFastService: boolean;
    showRepeatedOrder: boolean;
    ratingValue: number;
    ratingCount: number;
    status: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt?: any;
    laundryId: number;
    countryId: number;
  }

  export interface TimeSlot {
    start: Date;
    end: Date;
  }

  export interface Transaltion {
    en: string;
    ar: string;
  }

  export interface Laundry {
    id: number;
    name: Transaltion;
    imageLogo: Transaltion;
    imageBackdrop: Transaltion;
    description: Transaltion;
    ratingValue: number;
    ratingCount: number;
    trnName: string;
    trnNumber?: any;
    amountMinimumOrder: number;
    amountDeliveryCharge: number;
    status: string;
    type: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt?: any;
    countryId: number;
    groupId: number;
  }

  export interface ScheduledOrderConfig {
    id: number;
    name: Transaltion;
    keyword: Transaltion;
    description: Transaltion;
    placeholderText: Transaltion;
    intervalBetweenOrders: number;
    status: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt?: any;
  }

  export interface CustomerSchedule {
    id: number;
    status: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt?: any;
    userId: number;
    laundryBranchId: number;
    countryId: number;
    groupId: number;
    tatServiceId: number;
    scheduledOrderConfigId: number;
    pickupTimeSlotId: number;
    deliveryTimeSlotId: number;
    pickupAddressId: number;
    deliveryAddressId: number;
    laundryBranch: LaundryBranchForSchedule;
    pickup: TimeSlot;
    delivery: TimeSlot;
    laundry: Laundry;
    scheduledOrderConfig: ScheduledOrderConfig;
  }

  export interface ScheduleParams {
    status?: boolean;
    userId: number;
    countryId?: number;
    sortBy?: string;
    sortValue?: string;
    page?: number;
  }
}

