import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { TranslateModule } from '@ngx-translate/core';

import { HomecleanOrderDetailsCardComponent } from './homeclean-order-details-card/homeclean-order-details-card.component';
import { SharedModule } from '@app/modules/homeclean/shared/shared.module';
import { HomeCleaningOrderDetailsComponent } from './home-cleaning-order-details.component';
import { HomecleanOrderDetailsHeaderComponent } from './homeclean-order-details-header/homeclean-order-details-header.component';
import { HomecleanOrderDetailsItemComponent } from './homeclean-order-details-item/homeclean-order-details-item.component';
import { ChangeBranchDialogComponent } from '../components/change-branch-dialog/change-branch-dialog.component';
import { CancelOrderDialogComponent } from '../components/cancel-order-dialog/cancel-order-dialog.component';
import { RescheduleDialogComponent } from '../components/reschedule-dialog/reschedule-dialog.component';
import { ClipboardModule } from 'ngx-clipboard';
import { MatIcon } from '@angular/material/icon';

@NgModule({
  declarations: [
    HomeCleaningOrderDetailsComponent,
    HomecleanOrderDetailsCardComponent,
    HomecleanOrderDetailsHeaderComponent,
    HomecleanOrderDetailsItemComponent,
    ChangeBranchDialogComponent,
    CancelOrderDialogComponent,
    RescheduleDialogComponent,
  ],
  imports: [
    CommonModule,
    MDBBootstrapModulesPro,
    TranslateModule,
    SharedModule,
    ClipboardModule,
    MatIcon,
  ],
})
export class HomeCleaningOrderDetailsModule {}
