import { UserBankAccount } from '.';
import { UserV4 } from '../user';

export interface ReferralCashoutRequest {
  id: number;
  requestedAmount: number;
  availableBalance: number;
  remainingBalance: number;
  feeCollected: number;
  amountCollected: number;
  status: 'approved' | 'rejected';
  countryId: number;
  userId: number;
  userBankAccountId: number;
  user: UserV4;
  userBankAccount: UserBankAccount;
}
