import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { FormStatus } from '@shared/enums/form-status.enum';
import { CarwashBranch, Nullable, PaymentMethod } from '@shared/models';
import { CarwashBranchService } from '@shared/service/carwash-branch';

@Component({
  templateUrl: './carwash-branch-edit-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarwashBranchEditDialogComponent implements OnInit, OnDestroy {
  isEditMode: boolean;
  isDisableButton: boolean;
  isLoading: boolean;
  formData: Partial<CarwashBranch>;

  private readonly destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: {
      branch: Nullable<CarwashBranch>;
      companyId: number;
    } | any,
    private readonly dialogRef: DialogRef<CarwashBranchEditDialogComponent>,
    private readonly carwashBranchService: CarwashBranchService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.isEditMode = !!this.data.branch;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(): void {
    this.dialogRef.close();
  }

  formChanged(branch: Partial<CarwashBranch>): void {
    this.formData = {
      ...branch,
      latitude: +branch.latitude,
      longitude: +branch.longitude,
      areaId: +branch.areaId,
      lowestPrice: +branch.lowestPrice,
      highestPrice: +branch.highestPrice,
      openTime: branch.openTime ? branch.openTime : null,
      closeTime: branch.closeTime ? branch.closeTime : null,
      tax: +branch.tax,
      cwGarageId: Number(this.data.companyId),
      paymentMethods: branch.paymentMethods ? branch.paymentMethods.filter(
        (paymentMethod: PaymentMethod) => paymentMethod.checked,
      ) : [],
    };
  }

  formStatusChanged(status: FormStatus): void {
    this.isDisableButton = status === FormStatus.Invalid;
  }

  updateBranch(): void {
    this.formData.trnNumber = this.formData.trnNumber?.trim() || null;
    const action$ = this.isEditMode
      ? this.carwashBranchService.updateBranch(
          this.data.branch.id,
          this.formData,
        )
      : this.carwashBranchService.createBranch(this.formData);

    this.isLoading = true;
    action$
      .pipe(
        tap(() => {
          this.isLoading = false;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (company: CarwashBranch) => {
          this.dialogRef.close(company);
        },
        (error) => {
          this.isLoading = false;
          this.cdr.markForCheck();
        },
      );
  }
}
