import { IdName } from '../offers/offers';

export class PartnerTranslation {
  id?: number;
  first_name: string;
  last_name: string;
  partner_id?: number;
  language_id?: number;
}

export class PartnersArea {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  status: string;
  country_id: number;
  governorate_id: number;
  governorateId: number;
  is_selected: boolean;
}

export class PartnersFromList {
  id: number;
  first_name: string;
  last_name: string;
  mobile_no: string;
  car_plate_no: string;
  address: string;
  pin_no: number;
  is_tracking_enabled: boolean;
  is_available: boolean;
  status?: any;
  governorate_id: number;
  area_id: number;
  country_id: number;
  group_id: number;
  assigned_task_count: number;
  completed_task_count: number;
  partner_translations: PartnerTranslation[];
  partners_area: PartnersArea[];
  assigned_area_count: number;
  supplier: IdName;
  has_supplier: boolean;
}

export class PartnerToBeSaved {
  id: number;
  first_name: string;
  last_name: string;
  mobile_no: string;
  area_id: number;
  governorate_id: number;
  car_plate_no: string;
  address: string;
  partner_translations: PartnerTranslation[];
}

export interface PartnerArea {
  area_id: number;
  is_selected: boolean;
}

export class PartnerAreaToBeSaved {
  partner_id: number;
  partnerAreas: PartnerArea[];
}

export class QuestionsForAssignDriver {
  id: number;
  answer: string;
  has_note: boolean;
}

export class DriverToBeAssigned {
  id: number;
  first_name: string;
  last_name: string;
  mobile: string;
}

export class ListAvailableDrivers {
  partners: DriverToBeAssigned[];
  drivers: DriverToBeAssigned[];
}

export class OrderAssignmentBody {
  id?: number;
  order_id: number;
  answer_id?: number;
  notes?: string;
  is_partner: boolean;
}
