import { Component, OnInit } from '@angular/core';
import {
  BranchTaTServices,
  BranchTATServiceToBeSaved,
  BranchTATFromList
} from 'src/app/shared/models/laundries/branch';
import { GlobalService } from 'src/app/shared/service/global.service';
import { BranchesService } from 'src/app/shared/service/branches.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { zip } from 'rxjs';

@Component({
  selector: 'app-branch-tat',
  templateUrl: './branch-tat.component.html',
  styleUrls: ['./branch-tat.component.css']
})
export class BranchTatComponent implements OnInit {
  branchId: number;
  branchTATServices: BranchTaTServices;
  branchServicesToBeSaved: BranchTATServiceToBeSaved;
  allServices: BranchTATFromList[];
  dataReady = false;
  loader = false;
  laundryId: number;
  isFastGreat: boolean;
  constructor(
    protected globalService: GlobalService,
    protected branchService: BranchesService,
    protected route: ActivatedRoute,
    protected router: Router,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.branchId = this.route.snapshot.params.branchId as number;
    this.laundryId = this.route.parent.snapshot.params.id;
    this.branchServicesToBeSaved = new BranchTATServiceToBeSaved();
    this.getData();
  }

  getData() {
    const zippedResponse = zip(
      this.branchService.getAllTats(this.globalService.headers),
      this.branchService.getBranchTats(
        this.globalService.headers,
        this.branchId
      )
    );
    zippedResponse.subscribe(
      response => {
        const all = response[0];
        const branchTat = response[1];
        if (all.code === 200) {
          this.allServices = all.data as BranchTATFromList[];
        } else {
          this.toast.error(all.message);
        }
        if (branchTat.code === 200) {
          this.branchTATServices = branchTat.data as BranchTaTServices;
          this.dataReady = true;
        } else {
          this.toast.error(branchTat.message);
        }
      }
    );
  }

  changeFastServiceStatus(status: boolean) {
    this.branchService
      .changeFastServiceStatus(
        this.globalService.headers,
        this.branchId,
        status
      )
      .subscribe(
        response => {
          if (response.code === 200) {
            this.getData();
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }

  updateServices() {
    this.loader = true;
    this.branchServicesToBeSaved.laundry_branch_id = this.branchId;
    this.branchServicesToBeSaved.services = this.branchTATServices.services;
    this.branchService
      .updateTATService(
        this.globalService.headers,
        this.branchServicesToBeSaved
      )
      .subscribe(
        response => {
          if (response.code === 200) {
            this.getData();
            this.toast.success(response.message);
          } else {
            this.toast.error(response.message);
          }
          this.loader = false;
        },
        error => {
          this.loader = false;
        }
      );
  }

  getServiceById(id: number) {
    return this.allServices.find(element => element.id === id);
  }

  goBack() {
    this.router.navigate([
      '/dashboard/' +
        window.localStorage.getItem('module') +
        '/branch-list/' +
        this.laundryId
    ]);
  }

  pattern(id: number) {
    return this.getServiceById(id)
      .name.en.toLocaleLowerCase()
      .includes('fast')
      ? '^(4|6|8|12|20|24){1}$'
      : '^(24|30|36|48|72|96){1}$';
  }

  patternMessage(id: number) {
    return this.getServiceById(id)
      .name.en.toLocaleLowerCase()
      .includes('fast')
      ? '4, 6, 8, 12, 20 or 24'
      : '24, 30, 36, 48, 72, or 96';
  }

  isGreaterThan(id: number) {
    if (
      this.getServiceById(id)
        .name.en.toLocaleLowerCase()
        .includes('fast')
    ) {
      const fast = this.branchTATServices.services.find(e => e.id === id);
      const normal = this.branchTATServices.services.find(e => e.id !== id);
      this.isFastGreat = fast.value >= normal.value;
      return this.isFastGreat;
    }
  }
  showErrors(val) {
    console.log(val);
  }
}
