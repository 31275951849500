import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/shared/service/global.service';
import { OrderService } from 'src/app/shared/service/order.service';
import { ToastService } from 'ng-uikit-pro-standard';
import {
  OrderDetails,
  OrderToBeCompensated,
  SelectedReason,
  Reason
} from 'src/app/shared/models/orders/order';
import { CompensationType, JustCleanLaundryPercentage } from 'src/app/shared/models/global/response';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ArrayFunctions } from 'src/app/shared/helpers/array-functions';
import { ApiLibrary } from 'src/app/shared/helpers/http-params';

@Component({
  selector: 'app-compensate-order',
  templateUrl: './compensate-order.component.html',
  styleUrls: ['./compensate-order.component.css']
})
export class CompensateOrderComponent implements OnInit {
  orderId: string;
  orderDetails: OrderDetails;
  currency: string;
  orderToBeCompensated: OrderToBeCompensated;
  compensationTypes: CompensationType[];
  selectedCompensationType: CompensationType;
  loader = false;
  selectedReason: SelectedReason;
  isReasonModalOpened = false;
  isConfirmationModalOpened = false;
  reasons: Reason[];
  commentControl: UntypedFormControl;
  justCleanOrLaundry: JustCleanLaundryPercentage;
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected globalService: GlobalService,
    protected orderService: OrderService,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.commentControl = new UntypedFormControl(null, [Validators.required]);
    this.orderId = this.route.snapshot.params.orderId;
    this.currency = this.globalService.currency;
    this.justCleanOrLaundry = {
      justclean: 50,
      laundry: 50
    };
    this.getOrderDetails();
    this.getCompensatedTypes();
  }

  selectType(type: CompensationType) {
    this.orderToBeCompensated.compensation_type_id = type.id;
    this.selectedCompensationType = type;
    if (!this.selectedCompensationType.has_promo_code) {
      this.orderToBeCompensated.promo_code = null;
    }
  }

  validate() {
    const promoIsValid =
      this.selectedCompensationType &&
      this.selectedCompensationType.has_promo_code
        ? this.orderToBeCompensated.promo_code
        : true;
    return (
      this.orderToBeCompensated.compensation_type_id &&
      this.orderToBeCompensated.compensate_amount &&
      promoIsValid
    );
  }

  openReasonModal(event) {
    if (this.selectedCompensationType.has_promo_code) {
      this.loader = true;
      this.globalService
        .validatePromoCode(this.globalService.headers, {
          promo_code: this.orderToBeCompensated.promo_code
        })
        .subscribe(
          response => {
            if (response.code === 200) {
              this.isReasonModalOpened = true;
              this.getReasons({ type: 'compensate' });
              this.loader = false;
            }
          },
          error => {
            this.loader = false;
          },
          () => {
            this.loader = false;
          }
        );
    } else {
      this.isReasonModalOpened = true;
      this.getReasons({ type: 'compensate' });
    }
  }

  openConfirmationModal(event) {
    this.isConfirmationModalOpened = true;
  }

  closeReasonModal(event) {
    this.isReasonModalOpened = false;
    this.selectedReason = null;
    this.commentControl.setValue(null);
  }

  closeConfirmationModal(event) {
    this.isConfirmationModalOpened = false;
  }

  saveCompensation(event) {
    this.loader = true;
    this.orderToBeCompensated.order_id = parseInt(this.orderId, 10);
    this.orderToBeCompensated.reason_id = this.selectedReason.reason;
    this.orderToBeCompensated.comment = this.commentControl.value;
    this.orderToBeCompensated = new ApiLibrary().cleanObjectFromComponent(
      this.orderToBeCompensated
    );
    this.orderToBeCompensated.discount_by_justclean = this.justCleanOrLaundry.justclean;
    this.orderToBeCompensated.discount_by_laundry = this.justCleanOrLaundry.laundry;

    this.orderService
      .saveCompensation(this.globalService.headers, this.orderToBeCompensated)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.toast.success(response.message);
            this.isConfirmationModalOpened = false;
            this.isReasonModalOpened = false;
            this.loader = false;
          } else {
            this.toast.error(response.message);
            this.loader = false;
          }
        },
        error => {
          this.loader = false;
        }
      );
  }

  isReasonButtonDisabled() {
    if (this.selectedReason) {
      return (
        (this.selectedReason && this.reasonHasSubs(this.selectedReason.reason)
          ? this.selectedReason.reason > 0 && this.selectedReason.sub > 0
          : this.selectedReason.reason > 0) && this.commentControl.valid
      );
    } else {
      return false;
    }
  }

  selectedReasonAndSub(event) {
    this.selectedReason = event;
  }

  getSliderValues(event: JustCleanLaundryPercentage) {
    this.justCleanOrLaundry = event;
    console.log(event);
  }

  private getOrderDetails(): void {
    this.orderService
      .getOrderDetails(this.globalService.headers, this.orderId)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.orderDetails = response.data;
            this.orderToBeCompensated = new OrderToBeCompensated();
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }

  private getCompensatedTypes() {
    this.orderService
      .getCompensationTypes(this.globalService.headers)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.compensationTypes = response.data;
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }

  private getReasons(params?: any) {
    this.orderService
      .getAllReasons(this.globalService.headers, params)
      .subscribe(response => {
        if (response.code === 200) {
          this.reasons = response.data;
        }
      });
  }

  private reasonHasSubs(id: number) {
    const reasonIndex = ArrayFunctions.findElementInArray<Reason>(
      this.reasons,
      id,
      true,
      'id'
    );
    const reason = this.reasons[reasonIndex];
    return reason.sub_reasons && reason.sub_reasons.length > 0;
  }
}
