import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BranchList } from '../../../../shared/models/laundries/branch';
import { GlobalService } from '../../../../shared/service/global.service';
import { BranchesService } from '../../../../shared/service/branches.service';
import { ToastService, ModalDirective } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-log-branch-list',
  templateUrl: './log-branch-list.component.html',
  styleUrls: ['./log-branch-list.component.scss']
})
export class LogBranchListComponent implements OnInit {
  branches: BranchList;
  laundryid: number;
  isLogistics: boolean;
  openAssign = false;
  selectedBranch: number;
  @ViewChild('success', { static: true }) succesModal: ModalDirective;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected globalService: GlobalService,
    protected branchService: BranchesService,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.laundryid = this.route.snapshot.params.id;
    this.isLogistics =
      window.localStorage.getItem('isLog') === 'true' ? true : false;
    this.getBranches(this.laundryid);
  }

  getBranches(laundryId: number) {
    this.branchService
      .getBranchList(this.globalService.headers, laundryId)
      .subscribe(data => {
        if (data.code === 200) {
          this.branches = data.data;
        }
      });
  }

  changeStatus(branch: BranchList, isLogistics?: boolean) {
    const status = {
      branch_id: branch.id,
      status: branch.status === 'active' ? 'inactive' : 'active',
      is_logistics: branch.is_logistics ? false : true
    };
    if (isLogistics) {
      delete status.status;
    } else {
      delete status.is_logistics;
    }
    this.branchService
      .changeBranchStatus(this.globalService.headers, status)
      .subscribe(
        response => {
          this.getBranches(this.laundryid);
        }
      );
  }
  changeStatusPartner(branch: BranchList, isPartner?: boolean) {
    const status = {
      branch_id: branch.id,
      status: branch.status === 'active' ? 'inactive' : 'active',
      is_partner: branch.is_partner ? false : true
    };
    if (isPartner) {
      delete status.status;
    } else {
      delete status.is_partner;
    }
    this.branchService
      .changeBranchStatus(this.globalService.headers, status)
      .subscribe(
        response => {
          this.getBranches(this.laundryid);
          this.toast.success(response.message);
        }
      );
  }

  openDialog(event) {
    this.selectedBranch = event;
    this.openAssign = true;
  }

  closeAssign(event) {
    console.log(event);

    this.openAssign = false;
    if (event.showSuccess) {
      this.succesModal.show();
    }
  }

  changePosStatus(branch: BranchList) {
    const status = {
      laundry_branch_id: branch.id,
      status: branch.has_pos_status ? false : true
    };
    this.branchService
      .changeBranchPosStatus(this.globalService.headers, status)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.getBranches(this.laundryid);
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }

  showDriversList() {
    this.router.navigate([
      'dashboard/logistics/drivers-list/' + this.selectedBranch
    ]);
  }

  showPartners() {
    return  window.localStorage.getItem('showPartner') === 'true';
  }
}
