// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-item .order-item__name {
  font-weight: bold;
}
.order-item .order-item__value {
  margin-left: 3px;
}
.order-item.order-item--small {
  font-size: 12px;
}
.order-item.order-item--medium {
  font-size: 14px;
}
.order-item.order-item--large {
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/carwash/modules/carwash-order/components/carwash-order-details/carwash-order-details-item/carwash-order-details-item.component.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;AAIE;EACE,eAAA;AAFJ;AAKE;EACE,eAAA;AAHJ;AAME;EACE,eAAA;AAJJ","sourcesContent":[".order-item {\n  .order-item__name {\n    font-weight: bold;\n  }\n\n  .order-item__value {\n    margin-left: 3px;\n  }\n\n  &.order-item--small {\n    font-size: 12px;\n  }\n\n  &.order-item--medium {\n    font-size: 14px;\n  }\n\n  &.order-item--large {\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
