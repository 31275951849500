import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LaundryListComponent } from './components/laundry-list/laundry-list.component';
import { CreateLaundryComponent } from './components/create-laundry/create-laundry.component';
import { StepCreationComponent } from './components/create-laundry/step-creation/step-creation.component';
import { StepContractComponent } from './components/create-laundry/step-contract/step-contract.component';
import { StepMarketingComponent } from './components/create-laundry/step-marketing/step-marketing.component';
import { LaundryPositionComponent } from './components/laundry-position/laundry-position.component';
import { AreaPositionComponent } from "@app/modules/laundries/components/area-position/area-position.component";
import { LaundryPosComponent } from "@app/modules/laundries/components/laundry-pos/laundry-pos.component";

const routes: Routes = [
  {
    path: 'laundry-list',
    component: LaundryListComponent,
    data: {
      title: 'List of Laundries'
    }
  },
  {
    path: 'laundry-pos',
    component: LaundryPosComponent,
    data: {
      title: 'Laundries Positions'
    }
  },
  {
    path: 'laundry-positioning',
    component: LaundryPositionComponent,
    data: {
      title: 'Laundry Positioning'
    }
  },
  {
    path: 'area-positioning',
    component: AreaPositionComponent,
    data: {
      title: 'Area Positions'
    }
  },
  {
    path: 'laundry-create',
    component: CreateLaundryComponent,
    children: [
      {
        path: 'step1',
        component: StepCreationComponent,
        data: {
          title: 'Step1 | Info'
        }
      },
      {
        path: 'step2/:id',
        component: StepContractComponent,
        data: {
          title: 'step2 | Contract'
        }
      },
      {
        path: 'step3/:id',
        component: StepMarketingComponent,
        data: {
          title: 'step3 | Marketing Info'
        }
      },
      {
        path: '',
        redirectTo: 'step1',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LaundryRoutingModule {}
