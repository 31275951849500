export interface OnlineProfileImage {
  id: number;
  imageUrl: string;
  isCoverImage: boolean;
  sortOrder: number;
  cwGarageBranchId: number;
  profileStatusId: number;
  groupId: number;
  userAdminId: number;
}
