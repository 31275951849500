import { PageInfo } from '@shared/models/global/response';

export class PaginationHelper {
  static camelToSnakeCase(pageInfo: any): PageInfo {
    return {
      page_count: pageInfo.pageCount,
      current_page: pageInfo.currentPage,
      next_page: pageInfo.nextPage,
      item_count: pageInfo.itemCount,
      total_count: pageInfo.totalCount || pageInfo.totalItemsCount,
      ...pageInfo,
    };
  }
}
