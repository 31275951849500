import { Component, OnInit } from '@angular/core';
import {
  BranchContractInfo,
  BranchContractInfoToBeSaved,
} from 'src/app/shared/models/laundries/branch';
import { BranchesService } from 'src/app/shared/service/branches.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IMyOptions, ToastService } from 'ng-uikit-pro-standard';
import { GlobalService } from 'src/app/shared/service/global.service';
@Component({
  selector: 'app-branch-contract',
  templateUrl: './branch-contract.component.html',
  styleUrls: ['./branch-contract.component.css'],
})
export class BranchContractComponent implements OnInit {
  startDateText: any;
  endDateText: any;
  loader = false;
  percentageText = '0';
  amountText = 0;
  jcCreditFreeText = 0;
  jcCreditPaidText = 0;
  laundryId: number;
  branchContractInfo: BranchContractInfo;
  isUpdate: any;
  endDateOptions: IMyOptions = {};
  noContractMessage: boolean;
  page: number;
  currency: string;
  branchId: any;
  backUrl: string;
  groupId: string;
  laundryDesign: boolean;
  cwgDesign: boolean;
  hcDesign: boolean;
  constructor(
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private branchService: BranchesService,
    private router: Router,
    private toast: ToastService,
  ) {
    this.cwgDesign = this.route.snapshot.queryParams.cwgDesign === 'true';
    this.laundryDesign =
      this.route.snapshot.queryParams.laundryDesign === 'true';
    this.hcDesign = this.route.snapshot.queryParams.hcDesign === 'true';
  }

  ngOnInit() {
    this.laundryId = this.route.parent.snapshot.params.id;
    this.branchId = this.route.snapshot.params.branchId;
    this.backUrl = this.route.snapshot.queryParams.backUrl;
    this.groupId = this.route.snapshot.queryParams.groupId;
    this.page = this.route.snapshot.queryParams.page;
    this.currency = this.globalService.currency;
    this.getLaundryContract();
  }

  saveLaundry() {
    this.loader = true;
    const contract: BranchContractInfoToBeSaved = {
      commission_percentage: parseInt(this.percentageText, 10),
      contract_end_date: this.endDateText,
      contract_start_date: this.startDateText,
      ...(this.cwgDesign && { cw_garage_branch_id: this.branchId }),
      ...(this.laundryDesign && { laundry_branch_id: this.branchId }),
      ...(this.hcDesign && { hc_company_branch_id: this.branchId }),
      subscription_fee_monthly: this.amountText,
      jc_credit_free_commission: this.jcCreditFreeText,
      jc_credit_paid_commission: this.jcCreditPaidText,
    };
    if (!this.isUpdate) {
      this.branchService
        .saveBranchContract(this.globalService.headers, contract)
        .subscribe(
          (response) => {
            if (response.code === 200) {
              this.loader = false;
              this.toast.success(response.message);
              this.getLaundryContract();
            } else {
              this.loader = false;
              this.toast.error(response.message);
            }
          },
          (error) => {
            this.loader = false;
          },
        );
    } else {
      contract.id = this.branchContractInfo.id;
      this.branchService
        .updateBranchContract(this.globalService.headers, contract.id, contract)
        .subscribe(
          (response) => {
            if (response.code === 200) {
              this.loader = false;
              this.getLaundryContract();
              this.toast.success(response.message);
            } else {
              this.loader = false;
              this.toast.error(response.message);
            }
          },
          (error) => {
            this.loader = false;
          },
        );
    }
  }

  selectStartDate(event) {
    const date = this.globalService.formatDate(
      this.globalService.datePlusDays(event.actualDateFormatted, 0).toString(),
      'YYYY-MM-DD',
    );
    this.endDateOptions = {
      disableUntil: {
        year: parseInt(date.split('-')[0], 10),
        month: parseInt(date.split('-')[1], 10),
        day: parseInt(date.split('-')[2], 10),
      },
    };
  }

  goBack() {
    if (!this.backUrl) {
      if (this.hcDesign) {
        this.router.navigate(['/dashboard/crud/page/branch'], {
          queryParams: { hcCompanyId: this.laundryId, rowDataName: '' },
        });
      } else if (this.cwgDesign) {
        this.router.navigate(
          [`/dashboard/carwash/branches/${this.laundryId}/list`],
          {
            queryParams: { isMobileType: true, groupId: this.groupId },
          },
        );
      } else {
        this.router.navigate([
          '/dashboard/' +
            window.localStorage.getItem('module') +
            '/branch-list/' +
            this.laundryId,
        ]);
      }
    } else {
      this.router.navigate([this.backUrl], {
        queryParams: { groupId: this.groupId },
      });
    }
  }

  private getLaundryContract() {
    this.branchService
      .getBranchContractInfo(this.globalService.headers, {
        ...(this.cwgDesign && { cw_garage_branch_id: this.branchId }),
        ...(this.laundryDesign && { laundry_branch_id: this.branchId }),
        ...(this.hcDesign && { hc_company_branch_id: this.branchId }),
      })
      .subscribe(
        (response) => {
          if (response.code === 200 && response.data) {
            this.isUpdate = true;
            this.branchContractInfo = response.data as BranchContractInfo;
            this.percentageText = this.branchContractInfo.commission_percentage
              ? this.branchContractInfo.commission_percentage * 100 + ''
              : '0';
            this.startDateText = this.branchContractInfo.contract_start_date
              ? this.globalService.getDateFromStringForDatePicker(
                  this.branchContractInfo.contract_start_date.toString(),
                )
              : null;
            const date = this.globalService.formatDate(
              this.globalService
                .datePlusDays(
                  this.branchContractInfo.contract_start_date.toString(),
                  1,
                )
                .toString(),
              'YYYY-MM-DD',
            );
            this.endDateOptions = {
              disableUntil: {
                year: parseInt(date.split('-')[0], 10),
                month: parseInt(date.split('-')[1], 10),
                day: parseInt(date.split('-')[2], 10),
              },
            };
            this.endDateText = this.branchContractInfo.contract_end_date
              ? this.globalService.getDateFromStringForDatePicker(
                  this.branchContractInfo.contract_end_date.toString(),
                )
              : null;
            this.amountText = this.branchContractInfo.subscription_fee_monthly
              ? this.branchContractInfo.subscription_fee_monthly
              : 0;
            this.jcCreditFreeText = this.branchContractInfo
              .jc_credit_free_commission
              ? this.branchContractInfo.jc_credit_free_commission
              : 0;
            this.jcCreditPaidText = this.branchContractInfo
              .jc_credit_paid_commission
              ? this.branchContractInfo.jc_credit_paid_commission
              : 0;
          } else {
            this.isUpdate = false;
            this.noContractMessage = true;
          }
        },
        (error) => {
          this.noContractMessage = true;
        },
      );
  }
}

export function configurable() {
  return function (
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor,
  ) {
    console.log(
      'target \n',
      target,
      'property \n',
      propertyKey,
      ' \n desc \n',
      descriptor,
    );
  };
}
