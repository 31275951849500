import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { GlobalService } from '@shared/service/global.service';
import { LogDriverService } from '@shared/service/log-driver.service';
import { DriversForAssign } from '@shared/models/cars/log-driver';

@Component({
  selector: 'app-assign-dirvers',
  templateUrl: './assign-dirvers.component.html',
  styleUrls: ['./assign-dirvers.component.scss']
})
export class AssignDirversComponent implements OnChanges {
  @Input() isOpen: boolean;
  @Input() branchId: number;
  @Input() isLogistics: boolean;
  @Input() laundryId?: number;
  @Output() close: EventEmitter<{
    branchId: number;
    showSuccess: boolean;
  }> = new EventEmitter();
  @ViewChild('assignDrivers', { static: true }) modal: ModalDirective;
  drivers: DriversForAssign[];
  loader = false;
  constructor(
    private globalService: GlobalService,
    private logDriverService: LogDriverService,
    private toast: ToastService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.isOpen) {
      this.modal.show();
      this.getDrivers();
    } else {
      this.modal.hide();
    }
  }

  closeModal(showSuccess = false) {
    this.close.emit({
      branchId: this.branchId,
      showSuccess: showSuccess
    });
  }

  private getDrivers() {
    const params: {
      isLogistics: boolean,
      laundryId?: number
    } = {
      isLogistics: this.isLogistics
    };

    console.log('laundryId:: ', this.laundryId)
    if (!this.isLogistics && this.laundryId) {
      params.laundryId = this.laundryId
    }
    this.logDriverService
      .getDriversForAssignement(this.globalService.headers, this.branchId, params)
      .subscribe(response => {
        if (response.code === 200) {
          this.drivers = response.data;
        } else {
          this.toast.error(response.message);
        }
      });
  }

  toggleDriver(driver: DriversForAssign) {
    driver.is_selected = !driver.is_selected;
  }

  assign() {
    this.loader = true;
    const drivers = {
      drivers: this.drivers
    };
    this.logDriverService
      .assignDrivers(this.globalService.headers, this.branchId, drivers)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.closeModal(true);
            this.loader = false;
          } else {
            this.toast.error(response.message);
            this.loader = false;
          }
        },
        error => {
          this.loader = false;
        }
      );
  }
}
