import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { FormStatus } from '@shared/enums/form-status.enum';
import { CarwashCompany, Variant } from "@shared/models";
import { CarWashAddOn } from "@shared/models/carwash/car-wash-add-on.model";
import { CarWashAddonService } from "@shared/service/car-wash-add-ons";

@Component({
  templateUrl: './car-wash-add-on-pricing-edit-dialog.component.html',
})
export class CarWashAddOnPricingEditDialogComponent implements OnInit {
  isEditMode: boolean;
  isDisableButton: boolean;
  formData: Partial<CarWashAddOn>;
  isLoading: boolean;

  private readonly destroy$: Subject<void> = new Subject<void>() ;

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: { carWashAddOn: CarWashAddOn | null; groupId: number; cwGarageBranchId: number; variants$: Observable<Array<Variant>>; } | any,
    private readonly dialogRef: DialogRef<CarWashAddOnPricingEditDialogComponent>,
    private readonly carWashAddonService: CarWashAddonService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.isEditMode = !!this.data.carWashAddOn;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(): void {
    this.dialogRef.close();
  }

  formChanged(carWashAddOn: Partial<CarWashAddOn>): void {
    this.formData = {
      ...carWashAddOn,
    };
  }

  formStatusChanged(status: FormStatus): void {
    this.isDisableButton = status === FormStatus.Invalid;
  }

  createPackage(): void {
    const data: Partial<CarwashCompany> = {
      ...this.formData,
      ...(this.data.groupId ? { groupId: this.data.groupId } : {}),
    };
    const action$ = this.isEditMode
      ? this.carWashAddonService.updateAddon(this.data.carWashAddOn.id, data)
      : this.carWashAddonService.createAddon(data);

    this.isLoading = true;
    action$
      .pipe(
        tap(() => {
          this.isLoading = false;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (carWashAddOn: CarWashAddOn) => {
          this.dialogRef.close(carWashAddOn);
        },
        (error) => {
          this.isLoading = false;
          this.cdr.markForCheck();
        },
      );
  }
}
