import { HomeCleaningBranchesService } from '@app/shared/service/home-cleaning/home-cleaning-branches.service';
import { Country, LoggedinUser } from '@app/shared/models/user/user';
import { GlobalService } from 'src/app/shared/service/global.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';

import { DialogService } from '@shared/components/dialog/services/dialog.service';
import { Ad, Group } from '@shared/models';
import { Status } from '@shared/enums/status.enum';
import { catchError, filter, map, takeUntil } from 'rxjs/operators';
import { AdService } from '@shared/service/ads';
import { AdEditDialogComponent } from '../ad-edit-dialog/ad-edit-dialog.component';
import { PageInfo } from '@app/shared/models/global/response';
import { CountryService } from '@app/shared/service/country.service';
import { BranchesService } from '@app/shared/service/branches.service';
import { CarwashBranchService } from '@app/shared/service/carwash-branch';
import { GroupService } from '@app/shared/service/group';
import { CouponsService } from '@app/shared/service/coupons.service';
import moment from "moment";
import { SalesAdEditDialogComponent } from "@app/modules/ads/components/sales-ad-edit-dialog/sales-ad-edit-dialog.component";

@Component({
  selector: 'app-marketing-ad-list',
  templateUrl: './sales-ad-list.component.html',
  styleUrls: ['./sales-ad-list.component.scss'],
})
export class SalesAdListComponent implements OnInit, OnDestroy {
  readonly Status = Status;
  pagination$: Observable<PageInfo>;
  ads$: Observable<Array<Ad>>;
  countries: Array<Country>;
  countryId = +localStorage.getItem('country');
  types: Array<{ name: string, value: string }> = [{ name: 'Scrollable Ads', value: 'home' }, { name: 'Banner 1', value: 'homeSet2' }, { name: 'Banner 2', value: 'homeSet3' }];
  adType: string = this.types[0].value;

  checkIsActiveBounded: Function;
  loadedBranches: { [branchKey_branchId: string]: string } = {};
  getBranchNameBounded: Function = this.getBranchName.bind(this);
  groups: Array<Group>;
  getGroupNameBounded: Function = this.getGroupName.bind(this);
  getDiscountBounded: Function = this.getDiscount.bind(this);

  private destroy$: Subject<void> = new Subject<void>();
  private user: LoggedinUser;

  constructor(
    private readonly countryService: CountryService,
    private readonly adService: AdService,
    private readonly hcBranchesService: HomeCleaningBranchesService,
    private readonly cwBranchesService: CarwashBranchService,
    private readonly branchesService: BranchesService,
    private readonly groupService: GroupService,
    private readonly couponsService: CouponsService,
    private readonly globalService: GlobalService,
    private readonly dialog: DialogService,
  ) {}

  ngOnInit(): void {
    this.initVariables();
    this.loadGroups();
    this.loadUserAndCountries();
  }

  openDialog(ad: Ad = null): void {
    this.groupService.getGroupById(ad.groupId).subscribe((group) => {
      this.adService.branchId =
        ad.hcCompanyBranchId || ad.cwGarageBranchId || ad.laundryBranchId;
      this.adService.branchKey = this.groupService.getBranchIdKey(group);
      this.adService.group = group;
      this.dialog
        .open(AdEditDialogComponent, {
          ad,
          isMarketing: true,
          countries: this.countries,
          selecteCountryId: this.countryId,
        })
        .afterClosed$.pipe(filter(Boolean))
        .subscribe((updatedAd: Ad) => {
          this.globalService.stopShowingErrors = true;
          Object.keys(ad).forEach((key) => (ad[key] = updatedAd[key]));
        });
    });
  }

  openCreateSalesAdDialog(ad: Ad = null): void {
    this.dialog
      .open(SalesAdEditDialogComponent, { ad })
      .afterClosed$.pipe(filter(Boolean))
      .subscribe(() => {
        this.ads$ = null;
        this.loadAds();
      });
  }

  getPage(pageNumber: number) {
    this.loadAds(pageNumber);
  }

  loadCountryAds(): void {
    this.globalService.headers.country_id = this.countryId.toString();
    this.adService.pagination$.next({
      current_page: 1,
      item_count: 1,
      next_page: null,
      page_count: 1,
    });
    this.loadAds();
  }

  getBranchKey(ad: Ad): string {
    return (
      (ad.hcCompanyBranchId && 'hc') ||
      (ad.cwGarageBranchId && 'cw') ||
      (ad.laundryBranchId && 'lu')
    );
  }

  getBranchId(ad: Ad): number {
    return ad.hcCompanyBranchId || ad.cwGarageBranchId || ad.laundryBranchId;
  }

  private checkIsActive(endDate: string): boolean {
    const diff = this.globalService.checkFromNow(endDate);
    return !diff.includes('ago');
  }

  private initVariables(): void {
    this.globalService.stopShowingErrors = true;
    this.checkIsActiveBounded = this.checkIsActive.bind(this);
    this.pagination$ = this.adService.pagination$;
  }

  private loadUserAndCountries(): void {
    const apis$ = [
      this.countryService.getCountries(this.globalService.headers),
      this.globalService.getUser$(),
    ];

    forkJoin(apis$)
      .pipe(takeUntil(this.destroy$))
      .subscribe(([{ data: countries }, userData]) => {
        this.user = userData ? (userData as LoggedinUser) : null;
        this.filterCountries(countries);
        this.loadAds();
      });
  }

  private filterCountries(countries: Country[]): void {
    const emailCountryMap = {
      'mlotfy@justclean.com': [4],
      'melhusseiny@justclean.com': [3, 5],
      'edesouky@justclean.com': [1],
      'ghussain@justclean.com': [2],
    };

    const userCountries: number[] = emailCountryMap[this.user?.email];
    if (userCountries) {
      this.countries = countries.filter(({ id }) => userCountries.includes(id));
      this.countryId = this.countries[0]?.id;
    } else {
      this.countries = countries;
    }
  }

  private getCountry(countryId: number): Country {
    return this.countries.find((e) => e.id === countryId);
  }

  private loadAds(page: number = 1): void {
    const params = {
      countryId: this.countryId,
      includeExpired: true,
      page,
      sortName: 'endDate',
      sortOrder: 'DESC',
      hasBlankCodes: false,
      type: this.adType,
    };
    this.ads$ = this.adService.getAds(params);
  }

  private getBranchName(
    branchId: number,
    branchKey: string,
  ): Observable<string> {
    if (!branchId) {
      return of('-');
    }

    const branchKey_branchId = `${branchKey}_${branchId}`;

    if (this.loadedBranches[branchKey_branchId]) {
      return of(this.loadedBranches[branchKey_branchId]);
    }

    this.loadedBranches[branchKey_branchId] = '-';

    return this.getMethod(branchId, branchKey).pipe(
      catchError((err) => {
        this.loadedBranches[branchKey_branchId] = '-';
        return this.loadedBranches[branchKey_branchId];
      }),
      map((branchName: string) => {
        this.loadedBranches[branchKey_branchId] = branchName || '-';
        return this.loadedBranches[branchKey_branchId];
      }),
    );
  }

  private getMethod(branchId: number, branchKey: string): Observable<any> {
    return (
      (branchKey === 'hc' &&
        this.hcBranchesService
          .getHcBranchById(branchId)
          .pipe(map((branch) => branch.hcCompany?.name?.en))) ||
      (branchKey === 'cw' &&
        this.cwBranchesService
          .getBranch(branchId)
          .pipe(map((branch) => branch.companyName?.en))) ||
      (branchKey === 'lu' &&
        this.branchesService
          .getBranchInfo(this.globalService.headers, branchId)
          .pipe(map(({ data }) => data.name?.en || data.name)))
    );
  }

  private getDiscount(
    couponCode: string,
    countryId: number,
  ): Observable<string> {
    if (!couponCode) {
      return of('-');
    }
    const body = { countryId, code: couponCode };

    return this.couponsService.verifyCoupon(body).pipe(
      takeUntil(this.destroy$),
      map((res) => {
        const coupon = res.data;
        if (coupon?.discountType === 'Percentage') {
          return `${coupon.discountPercentage * 100}%`;
        } else {
          const country = this.getCountry(countryId);
          return `${(coupon.discountAmount || 0)?.toFixed(
            country.currency_decimal,
          )} ${country.currency}`;
        }
      }),
    );
  }

  private loadGroups(): void {
    this.groupService
      .getGroups()
      .pipe(takeUntil(this.destroy$))
      .subscribe((groups) => (this.groups = groups));
  }

  private getGroupName(groupId: number): string {
    return this.groups.find((group) => group.id === groupId)?.name?.en;
  }
  ngOnDestroy(): void {
    this.globalService.stopShowingErrors = false;
    this.globalService.headers.country_id = localStorage.getItem('country');
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeStatus(ad: Ad) {
    let status = 'active';
    if (ad.status === 'active') {
      status = 'inactive';
    }
    this.adService.updateAd(ad.id, { status }).subscribe(() => {
      this.loadAds();
    });
  }

  getSelectedAdType($event: any) {
    this.loadAds()
    return this.adType
  }

  isFutureDate(startDate: string): boolean {
    return moment(startDate).isAfter(moment());
  }
  isPastDate(endDate: string): boolean {
    return moment(endDate).isBefore(moment());
  }
}
