import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {
  FinanceLaundryBill,
  SummaryReport,
} from 'src/app/modules/finance/models/finance';
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root',
})
export class ExcellService {
  public generateLaundryBill(
    json: any[],
    excelFileName: string,
    header: FinanceLaundryBill
  ): void {
    let worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([
      [`Laundry: ${header.laundry}`],
      [`branch(s): ${header.laundryBranch.toString()}`],
      [`Country: ${header.country}`],
      [`Month: ${header.invoice_month}`],
      [`Currency: ${localStorage.getItem('currency')}`],
    ]);
    const ranges: XLSX.Range[] = [];
    for (let i = 0; i < 6; i++) {
      ranges.push({
        s: {
          c: 0,
          r: i,
        },
        e: {
          c: Object.keys(json[0]).length,
          r: i,
        },
      });
    }
    worksheet['!merges'] = ranges;
    const cells = [];
    Object.keys(json[0]).forEach((e) =>
      cells.push({
        wch: 20,
      })
    );
    const rows = [];
    json.forEach((e) =>
      cells.push({
        hpx: 30,
      })
    );
    worksheet['!cols'] = cells as XLSX.ColInfo[];
    worksheet['!rows'] = rows as XLSX.RowInfo[];
    worksheet = XLSX.utils.sheet_add_json(worksheet, json, {
      origin: { r: 6, c: 0 },
      header: Object.keys(json[0]),
    });
    const workbook: XLSX.WorkBook = {
      Sheets: { Bill: worksheet },
      SheetNames: ['Bill'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  generateSummaryBill(table: any, fileName: string, header: SummaryReport) {
    let worksheet = XLSX.utils.table_to_sheet(table);

    const ranges: XLSX.Range[] = [];
    ranges.push({
      s: {
        r: 0,
        c: 0
      },
      e: {
        r: 0,
        c: 2
      }
    }, {
      s: {
        r: 4,
        c: 0
      },
      e: {
        r: 4,
        c: 2
      }
    }, {
      s: {
        r: 13,
        c: 0
      },
      e: {
        r: 13,
        c: 2
      }
    } , {
      s: {
        r: 21,
        c: 0
      },
      e: {
        r: 21,
        c: 2
      }
    } , {
      s: {
        r: 29,
        c: 0
      },
      e: {
        r: 29,
        c: 2
      }
    } , {
      s: {
        r: 35,
        c: 0
      },
      e: {
        r: 35,
        c: 2
      }
    } , {
      s: {
        r: 40,
        c: 0
      },
      e: {
        r: 40,
        c: 2
      }
    } , {
      s: {
        r: 41,
        c: 0
      },
      e: {
        r: 41,
        c: 2
      }
    } , {
      s: {
        r: 44,
        c: 0
      },
      e: {
        r: 44,
        c: 2
      }
    } , {
      s: {
        r: 45,
        c: 0
      },
      e: {
        r: 45,
        c: 2
      }
    } , {
      s: {
        r: 47,
        c: 0
      },
      e: {
        r: 47,
        c: 2
      }
    } , {
      s: {
        r: 48,
        c: 0
      },
      e: {
        r: 48,
        c: 2
      }
    });
    for (let i = 50; i < 61; i++) {
      ranges.push({
        s: {
          c: 0,
          r: i,
        },
        e: {
          c: 2,
          r: i,
        }
      } );
    }
    worksheet['!merges'] = ranges;
    const cells = [];
    cells.push(
      {
        wch: 100,
      }
    );
    const rows = [];
    for (let i = 0; i < 61; i++) {
      rows.push({
        hpx: 20,
      });
    }
    worksheet['!cols'] = cells as XLSX.ColInfo[];
    worksheet['!rows'] = rows as XLSX.RowInfo[];
    worksheet = XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [`Laundry: ${header.laundry}`],
        [`branch(s): ${header.laundryBranch}`],
        [`Country: ${header.country}`],
        [`Period: ${header.period}`],
        [`Currency: ${localStorage.getItem('currency')}`],
      ],
      {
        origin: { r: 55, c: 0 },
      }
    );
    const workbook: XLSX.WorkBook = {
      Sheets: { Summary: worksheet },
      SheetNames: ['Summary'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, fileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
}
