import { map } from 'rxjs/operators';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PackageHour } from '@app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class HomeCleaningPackagesService {
  private readonly packageHoursUrl = `${environment.jcDeliveryServer}hc-service-package-hours`;
  constructor(private readonly http: HttpClient) {}

  getPackageHours(packageId: number): Observable<Array<PackageHour>> {
    const toData = (res) => res.data;
    return this.http
      .get<ApiResponse<Array<PackageHour>>>(this.packageHoursUrl, {
        params: { hcServicePackageId: packageId.toString() },
      })
      .pipe(map(toData));
  }

  updatePackageHour(
    id: number,
    body: Partial<PackageHour>,
  ): Observable<PackageHour> {
    const toData = (res) => res.data;
    return this.http
      .patch<ApiResponse<PackageHour>>(`${this.packageHoursUrl}/${id}`, body)
      .pipe(map(toData));
  }
}
