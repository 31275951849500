import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-danger-switch',
  templateUrl: './danger-switch.component.html',
  styleUrls: ['./danger-switch.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DangerSwitchComponent {
  @Input() set enabled(isEnabled: boolean) {
    this.isChecked = isEnabled;
  }
  @Input() label: string;
  @Input() disabled: boolean;
  @Output() switched: EventEmitter<boolean> = new EventEmitter<boolean>();

  isChecked: boolean;

  toggle(): void {
    this.switched.emit(this.isChecked);
  }
}
