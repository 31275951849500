import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { Car, CarList, CarDetails } from '../../../../shared/models/cars/cars';
import { CarsService } from '../../../../shared/service/cars.service';
import { GlobalService } from '../../../../shared/service/global.service';
import { PageInfo } from '../../../../shared/models/global/response';

@Component({
  selector: 'app-cars-list',
  templateUrl: './cars-list.component.html',
  styleUrls: ['./cars-list.component.css']
})
export class CarsListComponent implements OnInit, OnDestroy {
  cars: CarList[];
  search: string = '';
  isShown: boolean;
  carsData: any[];
  pagination: PageInfo;
  toggleDeleteModal: boolean;
  loader: boolean;
  carLoaded: boolean;
  carToBeUpdated: CarDetails;
  isUpdate = false;
  carId: number;
  private searchSubject = new Subject<string>();
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private globalService: GlobalService,
    private carsService: CarsService
  ) {}

  ngOnInit() {
    this.isShown = false;
    this.carLoaded = false;
    this.setupSearch();
    this.getCars(1);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setupSearch() {
    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(() => {
      this.getCars(1);
    });
  }

  onSearchChange() {
    this.searchSubject.next(this.search);
  }

  getCars(page: number) {
    this.carLoaded = false;
    this.carsService
      .getCars(this.globalService.headers, { page: page, search: this.search })
      .subscribe(response => {
        if (response.code === 200) {
          this.cars = response.data;
          this.pagination = response.page_info;
          this.carLoaded = true;
        }
      });
  }

  getPage(page: number) {
    this.getCars(page);
  }

  isClosed($event: any) {
    this.isShown = false;
    this.isUpdate = false;
  }

  refreshData($event: any) {
    this.getPage(this.pagination.current_page);
  }

  openUpdate(car: Car) {
    this.carsService
      .getCarDetails(this.globalService.headers, car.id)
      .subscribe(response => {
        this.carToBeUpdated = response.data;
        this.isShown = true;
        this.isUpdate = true;
      });
  }
}
