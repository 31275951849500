import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  CarWashAddOnsListComponent
} from "@app/modules/carwash/modules/car-wash-add-ons/components/car-wash-add-ons-list/car-wash-add-ons-list.component";

const routes: Routes = [
  {
    path: 'add-ons/list',
    component: CarWashAddOnsListComponent,
    data: {title: 'Car wash company listing'}
  },
  {
    path: 'add-ons',
    redirectTo: 'add-ons/list',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CarWashAddOnsRoutingModule {}
