import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ContractToBeSaved,
  ContractCategory,
  PriceItem
} from 'src/app/shared/models/laundries/corporate';
import { NgForm } from '@angular/forms';
import { GlobalService } from 'src/app/shared/service/global.service';
import { LaundryService } from 'src/app/shared/service/laundry.service';
import { ItemsService } from 'src/app/shared/service/items.service';
import { LaundryFromList } from 'src/app/shared/models/laundries/laundry';
import {
  ItemFromListItem,
  CategoryOfItem
} from 'src/app/shared/models/items/items';
import { ArrayFunctions } from 'src/app/shared/helpers/array-functions';
import { CategoryList } from 'src/app/shared/models/items/cats';
import { CategoriesService } from 'src/app/shared/service/categories.service';
import { CorporateService } from 'src/app/shared/service/corporate.service';
import { BranchesService } from 'src/app/shared/service/branches.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { Router, ActivatedRoute } from '@angular/router';
import { IdName } from 'src/app/shared/models/cars/cars';

@Component({
  selector: 'app-contract-settings',
  templateUrl: './contract-settings.component.html',
  styleUrls: ['./contract-settings.component.scss']
})
export class ContractSettingsComponent implements OnInit {
  laundries: any[];
  branches: any[];
  contract: ContractToBeSaved;
  flat: string = ContractCategory.FLAT;
  priceKg: string = ContractCategory.PRICE_PER_KG;
  pricePerItem: string = ContractCategory.PRICE_PER_ITEM;
  selectedCategory: string;
  items: ItemFromListItem[];
  itemCategories: CategoryList[];
  loader = false;
  dataReady = false;
  corpId: number;
  @ViewChild('contractForm', { static: false }) myForm: NgForm;
  @ViewChild('flatRateForm', { static: false }) flatRateForm: NgForm;
  @ViewChild('priceKgForm', { static: false }) priceKgForm: NgForm;
  @ViewChild('priceItemForm', { static: false }) priceItemForm: NgForm;
  viewInit = false;
  constructor(
    protected globalService: GlobalService,
    private laundryService: LaundryService,
    private branchesService: BranchesService,
    private itemService: ItemsService,
    private catService: CategoriesService,
    private corporateService: CorporateService,
    private toast: ToastService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.corpId = this.route.snapshot.queryParams.id;
    this.contract = new ContractToBeSaved();
    this.contract.user_corporate_id = this.corpId;
    this.contract.items = this.contract.items ? this.contract.items : [];
    this.getLaundries();
    this.getCategories();
    this.getItems();
    this.dataReady = true;
  }

  getLaundries() {
    this.laundryService
      .getLaundries(this.globalService.headers, { page: 0 })
      .subscribe(response => {
        const laundries = response.data as LaundryFromList[];
        this.laundries = [];
        laundries.forEach(element => {
          if (element.is_corporate) {
            this.laundries.push({ value: element.id, label: element.name });
          }
        });
      });
  }

  getBranches(laundryId: number, corpId: number) {
    if (this.contract.laundry_id) {
      this.branchesService
        .getBranchesWithNocontractCorporate(
          this.globalService.headers,
          laundryId,
          corpId
        )
        .subscribe(
          response => {
            if (response.code === 200) {
              const branches = response.data as IdName[];
              this.branches = [];
              branches.forEach(val => {
                if (!val.status) {
                  this.branches.push({
                    value: val.id,
                    label: val.name
                  });
                }
              });
            } else {
              this.toast.error(response.message);
            }
          }
        );
    }
  }

  changeLaundry(event) {
    this.contract.laundry_id = event;
    this.getBranches(event, this.corpId);
  }

  getCategories() {
    this.catService
      .getCategories(this.globalService.headers, 0)
      .subscribe(response => {
        this.itemCategories = response.data as CategoryList[];
      });
  }

  getItems() {
    this.itemService
      .getItems(this.globalService.headers, 0)
      .subscribe(response => {
        this.items = response.data as ItemFromListItem[];
        this.items = this.items.filter(item => item.is_corporate);
      });
  }

  filterItemsPerCat(idCat: number) {
    if (this.items && this.items.length > 0) {
      const items: ItemFromListItem[] = this.items;
      return items.filter(element =>
        this.isItemBelongToCat(element.categories, idCat)
      );
    }
  }

  toggleElement(item: ItemFromListItem, idCat: number) {
    const actionDone = ArrayFunctions.toggleElementWithMax<number | PriceItem>(
      this.contract.items,
      { id: item.id, price: 0.1 },
      true,
      this.contract.contract_type === this.flat
        ? this.contract.capacity
        : this.items.length,
      'id'
    );
    if (!actionDone) {
      this.toast.error('you already selected the max capacity of items');
    }
    this.getSelectedByCat(idCat);
  }

  elementExist(id: number) {
    return ArrayFunctions.findElementInArray<number | PriceItem>(
      this.contract.items,
      id,
      true,
      'id'
    ) >= 0
      ? true
      : false;
  }

  getElement(id: number) {
    const index = ArrayFunctions.findElementInArray<number | PriceItem>(
      this.contract.items,
      id,
      true,
      'id'
    );
    return this.contract.items[index];
  }

  validateForm() {
    if (this.contract) {
      if (this.contract.contract_type === this.flat) {
        return this.myForm
          ? this.myForm.valid &&
              this.flatRateForm &&
              this.flatRateForm.valid &&
              this.contract.items.length > 0
          : false;
      }
      if (this.contract.contract_type === this.priceKg) {
        return this.myForm
          ? this.myForm &&
              this.myForm.valid &&
              this.priceKgForm &&
              this.priceKgForm.valid
          : false;
      }
      if (this.contract.contract_type === this.pricePerItem) {
        console.log(this.myForm);
        return this.myForm
          ? this.myForm &&
              this.myForm.valid &&
              this.priceItemForm &&
              this.priceItemForm.valid &&
              this.contract.items.length > 0
          : false;
      }
    } else {
      return false;
    }
  }

  isItemBelongToCat(cat: CategoryOfItem[], catId: number): boolean {
    const found = cat.find(c => c.id === catId);
    return found ? true : false;
  }

  getSelectedByCat(catId: number) {
    let count = 0;
    if (this.items && this.items.length > 0) {
      this.contract.items.forEach(item => {
        const itemFound = this.items.find(e => e.id === item.id);
        count = this.isItemBelongToCat(itemFound.categories, catId)
          ? count + 1
          : count;
      });
    }
    return count;
  }

  saveContract() {
    console.log(this.contract);

    this.loader = true;
    this.corporateService
      .createUpdateContract(this.globalService.headers, this.contract)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.loader = false;
            this.globalService.deleteContract();
            this.router.navigate(['dashboard/corporate/contract-list'], {
              queryParams: { id: this.corpId }
            });
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }

  back() {
    this.router.navigate(['dashboard/corporate/corp-customer-list']);
  }
}
