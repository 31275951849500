import { AbstractControl, Validators } from '@angular/forms';

export const spaceValidator = (control: AbstractControl): null | Validators => {
  if (
    (control.value as string)?.startsWith(' ') ||
    (control.value as string)?.endsWith(' ') ||
    control.value === ' '
  ) {
    return { space: '' };
  }
  return null;
};
