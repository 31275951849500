import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BackEndResponse } from '../models/global/response';
import { OfferToBeSaved } from '../models/offers/offers';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OffersService {
  readonly GET_OFFERS = environment.serverUrl + 'offer/get-offer';
  readonly ADD_OFFER = environment.serverUrl + 'offer/create-offer';
  readonly GET_OFFER_TYPE = environment.serverUrl + 'offer/get-offer-types';
  readonly DELETE_OFFER = environment.serverUrl + 'offer/delete-offer';
  readonly UPDATE_OFFER =
    environment.serverUrl + 'offer/update-offer/:offer_id';
  readonly CHANGE_STATUS = environment.serverUrl + 'offer/change-offer-status';

  constructor(private http: HttpClient) {}

  getOffers(headers, page?: number): Observable<BackEndResponse> {
    return this.http
      .get<BackEndResponse>(this.GET_OFFERS, {
        headers: this.setHeaders(headers),
        params: { page: page ? page.toString() : '0' }
      })
      .pipe(tap(data => console.log(data)));
  }

  getOfferType(headers, page?: number): Observable<BackEndResponse> {
    return this.http
      .get<BackEndResponse>(this.GET_OFFER_TYPE, {
        headers: this.setHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  saveOffer(headers, offer: OfferToBeSaved): Observable<BackEndResponse> {
    return this.http
      .post<BackEndResponse>(this.ADD_OFFER, offer, {
        headers: this.setHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  updateOffer(headers, item: OfferToBeSaved): Observable<BackEndResponse> {
    return this.http
      .post<BackEndResponse>(this.UPDATE_OFFER, item, {
        headers: this.setHeaders(headers)
      })
      .pipe(tap(data => console.log(data)));
  }

  deleteOffer(headers, id: number) {
    return this.http
      .post<BackEndResponse>(
        this.DELETE_OFFER,
        {
          id: id
        },
        {
          headers: this.setHeaders(headers)
        }
      )
      .pipe(tap(data => console.log(data)));
  }

  changeStatus(headers, id: number, status: string) {
    return this.http
      .post<BackEndResponse>(
        this.CHANGE_STATUS,
        {
          id: id,
          status: status
        },
        {
          headers: this.setHeaders(headers)
        }
      )
      .pipe(tap(data => console.log(data)));
  }

  setHeaders(headers) {
    return new HttpHeaders(headers);
  }
}
