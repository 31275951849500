import { Status } from '@shared/enums/status.enum';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { DialogService } from '@shared/components/dialog/services/dialog.service';
import { UserSegment } from '@shared/models';
import { PageInfo } from '@shared/models/global/response';
import { filter, takeUntil, finalize } from 'rxjs/operators';
import { UserSegmentService } from '@app/shared/service/user-segment';
import { UserSegmentEditDialogComponent } from '../user-segment-edit-dialog/user-segment-edit-dialog.component';

@Component({
  selector: 'app-user-segment-list',
  templateUrl: './user-segment-list.component.html',
})
export class UserSegmentListComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>() ;
  pagination$: Observable<PageInfo>;
  userSegments$: Observable<Array<UserSegment>>;
  STATUS = Status;
  currentPage: number = 1;

  private get countryId(): number {
    return +localStorage.getItem('country');
  }

  constructor(
    private readonly userSegmentService: UserSegmentService,
    private readonly dialog: DialogService,
  ) {}

  ngOnInit(): void {
    this.loadUserSegments();
    this.pagination$ = this.userSegmentService.pagination$;
  }

  openDialog(userSegment?: UserSegment): void {
    this.dialog
      .open(UserSegmentEditDialogComponent, userSegment)
      .afterClosed$.pipe(takeUntil(this.destroy$), filter(Boolean))
      .subscribe(() => this.loadUserSegments());
  }

  loadUserSegments(page?: number): void {
    this.currentPage = page || this.currentPage;
    this.userSegments$ = this.userSegmentService.getUserSegments(
      {
        page: this.currentPage.toString(),
        countryId: this.countryId.toString(),
      },
    );
  }

  changeStatus(userSegment: UserSegment) {
    userSegment['isUpdaing'] = true;
    const status =
      userSegment.status === Status.Active
        ? Status.Inactive
        : Status.Active;
    this.userSegmentService
      .updateUserSegment(userSegment.id, {
        status,
      })
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          userSegment['isUpdaing'] = false;
        }),
      )
      .subscribe(
        () => (userSegment.status = status),
        () => this.loadUserSegments(),
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
