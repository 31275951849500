import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import {
  ModalDirective,
  IMyOptions,
  ToastService
} from 'ng-uikit-pro-standard';
import { NgForm } from '@angular/forms';
import { BOGOToBeSaved } from 'src/app/shared/models/offers/campaigns';
import { SelectOptions, JustCleanLaundryPercentage } from 'src/app/shared/models/global/response';
import { LaundryFromList } from 'src/app/shared/models/laundries/laundry';
import { CampaignsService } from 'src/app/shared/service/campaigns.service';
import { GlobalService } from 'src/app/shared/service/global.service';
import { LaundryService } from 'src/app/shared/service/laundry.service';
import { SpecialIconServicesFromList } from 'src/app/shared/models/laundries/special-icons';
import { ItemsService } from 'src/app/shared/service/items.service';
import { SpecialIconService } from 'src/app/shared/service/special-icon.service';
import { ItemFromListItem } from 'src/app/shared/models/items/items';

@Component({
  selector: 'app-create-bogo',
  templateUrl: './create-bogo.component.html',
  styleUrls: ['./create-bogo.component.css']
})
export class CreateBogoComponent implements OnInit, OnChanges {
  @ViewChild('createBogoModal', { static: true }) modal: ModalDirective;
  @ViewChild('bogoForm', { static: false }) form: NgForm;
  @Input() isShown: boolean;
  @Output() isClosed: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();
  bogo: BOGOToBeSaved;
  type: SelectOptions<boolean>[];
  laundriesInForm: SelectOptions<any>[];
  laundries: LaundryFromList[];
  branchId: number[];
  selectAll = true;
  loader: boolean;
  validity: SelectOptions<number>[];
  selectedValidity = 1;
  items: ItemFromListItem[];
  services: SpecialIconServicesFromList[];
  itemsToBeShownPrices: SelectOptions<number>[];
  servicesToBeShown: SelectOptions<number>[];
  selectedItem: number;
  selectedService: number;
  allServices: SpecialIconServicesFromList[] = [];
  justCleanOrLaundry: JustCleanLaundryPercentage;
  startDateOptions: IMyOptions;
  endDateOption: IMyOptions;
  constructor(
    private campaignService: CampaignsService,
    private globalService: GlobalService,
    private toast: ToastService,
    private laundryService: LaundryService,
    private itemService: ItemsService,
    private specialIconService: SpecialIconService
  ) {}


  ngOnInit() {

    this.startDateOptions = {
      disableUntil: {
        day: this.globalService
          .dateMinusDays(new Date().toDateString(), 1)
          .date(),
        month:
          this.globalService.dateMinusDays(new Date().toDateString(), 1).month() +
          1,
        year: this.globalService
          .dateMinusDays(new Date().toDateString(), 1)
          .year()
      },
      closeAfterSelect: true
    };
    this.endDateOption = {
      disableUntil: {
        day: this.globalService
          .dateMinusDays(new Date().toDateString(), 1)
          .date(),
        month:
          this.globalService.dateMinusDays(new Date().toDateString(), 1).month() +
          1,
        year: this.globalService
          .dateMinusDays(new Date().toDateString(), 1)
          .year()
      },
      closeAfterSelect: true
    };
    this.initForm();
    this.generateArrayForList();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isShown) {
      this.initForm();
      this.modal.show();
    } else {
      this.modal.hide();
    }
  }

  onHidden(event) {
    this.isClosed.emit(event);
  }

  initForm() {
    if (this.form) {
      this.form.reset();
    }
    this.justCleanOrLaundry = {
      laundry: 50,
      justclean: 50
    };
    this.bogo = new BOGOToBeSaved();
    this.bogo.translations = [];
    this.bogo.translations[0] = {
      language_id: 1,
      message: '',
      name: 'bogo'
    };
    this.bogo.translations[1] = {
      language_id: 2,
      message: '',
      name: 'bogo'
    };
    this.bogo.offers = [];
    this.selectedItem = null;
    this.selectedService = null;
    this.getLaundries();
    this.getAllItems();
  }

  startDateChanged(event) {
    if (event) {
      this.endDateOption = {
        disableUntil: {
          day: this.globalService
            .dateMinusDays(new Date(event).toDateString(), 1)
            .date(),
          month:
            this.globalService
              .dateMinusDays(new Date(event).toDateString(), 1)
              .month() + 1,
          year: this.globalService
            .dateMinusDays(new Date(event).toDateString(), 1)
            .year()
        },
        closeAfterSelect: true
      };
      this.validateForm();
    }
  }

  endDateChanged(event) {
    this.validateForm();
  }

  validateForm() {
    return (
      this.form &&
      this.form.valid &&
      this.bogo.laundry_branch_ids &&
      this.bogo.laundry_branch_ids.length > 0 &&
      this.bogo.offers &&
      this.bogo.offers.length > 0 &&
      this.bogo.expiry_date &&
      this.bogo.start_date
    );
  }

  saveBogo() {
    this.loader = true;
    this.bogo.validity = this.bogo.validity * this.selectedValidity;
    this.bogo.discount_by_justclean = this.justCleanOrLaundry.justclean;
    this.bogo.discount_by_laundry = this.justCleanOrLaundry.laundry;
    this.campaignService
      .saveBogo(this.globalService.headers, this.bogo)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.loader = false;
            this.onHidden(true);
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }

  changeItem(event) {
    if (event) {
      this.getServices(event);
    }
  }

  addItem() {
    this.bogo.offers.push({
      service_id: this.selectedService,
      item_id: this.selectedItem
    });
  }

  combinationAlreadyExist() {
    const offer = this.bogo.offers.filter(e => e.item_id === this.selectedItem);
    const service = offer.find(e => e.service_id === this.selectedService);
    return offer && this.selectedService && service;
  }

  deleteRow(index: number) {
    this.bogo.offers.splice(index, 1);
    if (this.bogo.offers.length <= 0) {
      this.selectedService = null;
      this.selectedItem = null;
    }
  }

  showItemName(itemId: number) {
    return this.items.find(e => e.id === itemId).name.en.name;
  }

  showServiceName(serviceId: number) {
    const service = this.allServices.find(e => e.service_id === serviceId);
    return service ? service.service.name : 'lol';
  }

  getSliderValues(event: JustCleanLaundryPercentage) {
    this.justCleanOrLaundry = event;
    console.log(event);
  }

  private generateArrayForList() {
    this.type = [
      {
        value: true,
        label: 'Next Order'
      },
      {
        value: false,
        label: 'Same Order'
      }
    ];

    this.validity = [
      {
        value: 1,
        label: 'Minutes'
      },
      {
        value: 60,
        label: 'Hours'
      },
      {
        value: 1440,
        label: 'Days'
      }
    ];
  }

  private getLaundries() {
    this.laundryService
      .getLaundriesWithParams(this.globalService.headers, {
        page: 0,
        status: 'active',
        campaign: true
      })
      .subscribe(
        response => {
          if (response.code === 200) {
            this.laundries = response.data;
            this.laundriesInForm = [];
            this.laundries.forEach((laundry, i) => {
              this.laundriesInForm.push({
                value: '',
                label: laundry.name,
                group: true
              });
              laundry.laundry_branches.forEach(branch => {
                if (!branch.campaign_offeritem) {
                  this.laundriesInForm.push({
                    value: branch.id,
                    label: branch.name.toString()
                  });
                }
              });
            });
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }
  private getAllItems() {
    this.itemService.getItems(this.globalService.headers).subscribe(
      response => {
        if (response.code === 200) {
          this.items = response.data;
          this.itemsToBeShownPrices = [];
          this.items.forEach(e => {
            if (e.status === 'active') {
              this.itemsToBeShownPrices.push({
                value: e.id,
                label: e.name.en ? e.name.en.name : '-'
              });
            }
          });
        } else {
          this.toast.error(response.message);
        }
      }
    );
  }

  private getServices(itemId: number) {
    this.specialIconService
      .getServicesForItem(this.globalService.headers, itemId)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.services = response.data;
            this.allServices = this.allServices.concat(response.data);
            this.allServices = Array.from(new Set(this.allServices));
            this.servicesToBeShown = [];
            this.services.forEach(e => {
              this.servicesToBeShown.push({
                value: e.service.id,
                label: e.service.name
              });
            });
          } else {
            this.toast.error(response.message);
          }
        }
      );
  }
}
