import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../../shared/service/global.service';
import { OffersService } from '../../../../shared/service/offers.service';
import { Offer, OfferList } from '../../../../shared/models/offers/offers';
import { PageInfo } from '../../../../shared/models/global/response';

@Component({
  selector: 'app-offers-list',
  templateUrl: './offers-list.component.html',
  styleUrls: ['./offers-list.component.scss']
})
export class OffersListComponent implements OnInit {
  offers: OfferList[];
  pagination: PageInfo;
  search: string;
  isShown: boolean;

  offerLoaded: boolean;
  offerToBeUpdated: Offer;
  toggleDeleteModal: boolean;
  loader: boolean;
  offerToBeDeleted: Offer;

  constructor(
    private offersService: OffersService,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.offerLoaded = false;
    this.getOffers(1);
  }

  getOffers(page) {
    this.offersService
      .getOffers(this.globalService.headers, page)
      .subscribe(response => {
        if (response.code === 200) {
          console.log(response);
          this.offers = response.data;
          this.pagination = response.page_info;
          this.offerLoaded = true;
        }
      });
  }

  changeStatus(id: number, status: string) {
    const offStatus = status === 'active' ? 'inactive' : 'active';

    this.offersService
      .changeStatus(this.globalService.headersImage, id, offStatus)
      .subscribe(response => {
        this.getOffers(this.pagination.current_page);
      });
  }

  refreshData(event) {
    if (event) {
      this.getOffers(this.pagination.current_page);
    }
  }

  resetFilter() {}

  isClosed(event) {
    this.isShown = false;
  }

  updateOffer(offer: Offer) {
    this.offerToBeUpdated = offer;
    this.isShown = true;
  }
  delete(offer) {
    this.offerToBeDeleted = offer;
    this.toggleDeleteModal = true;
  }
  deleteOffer(offer) {
    this.loader = true;
    this.offersService
      .deleteOffer(this.globalService.headers, this.offerToBeDeleted.id)
      .subscribe(response => {
        if (response.code === 200) {
          this.loader = false;
          this.toggleDeleteModal = false;
          this.getOffers(this.pagination.current_page);
        }
      });
  }

  filter() {}
}
