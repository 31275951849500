export interface Staff {
  id: number;
  email: string;
  first_name: string;
  gender: string;
  pin: string;
  preferred_language_id: number;
  roles_jlm_id: number;
  status: string;
  uid: string;
  username: string;
}
