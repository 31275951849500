import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA } from '@shared/components/dialog/services/dialog.token';
import { Observable } from 'rxjs';
import { Driver, DriversBookingLogs } from '@app/modules/drivers/shared/models';
import { DriverQuestions } from '@app/modules/drivers/shared/models/driver-questions.model';
import { DialogRef } from '@shared/components/dialog/dialog-ref/dialog-ref';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-drivers-booking-logs-advice',
  templateUrl: './drivers-booking-logs-advice.component.html',
  styleUrls: ['./drivers-booking-logs-advice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TranslatePipe],
})
export class DriversBookingLogsAdviceComponent implements OnInit {
  selectedTab = 0;
  tabs: Array<{ title: string; text: string }>;

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: DriversBookingLogs & {
      question$: Observable<DriverQuestions>;
    } & { driver: Driver },
    private readonly dialogRef: DialogRef<DriversBookingLogsAdviceComponent>,
    private readonly translatePipe: TranslatePipe,
  ) {}

  ngOnInit(): void {
    this.tabs = [
      {
        title: this.translatePipe.transform('DRIVERS_REASON.CUSTOMER_SUPPORT'),
        text: this.translatePipe.transform(
          'DRIVERS_REASON.CUSTOMER_SUPPORT_TEXT',
        ),
      },
      {
        title: this.translatePipe.transform('DRIVERS_REASON.PARTNER_SUCCESS'),
        text: this.translatePipe.transform('DRIVERS_REASON.PARTNER_SUCCESS_TEXT'),
      },
    ];
  }

  changeTab(tab: number): void {
    this.selectedTab = tab;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
