import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeCleaningCompanyBranchListComponent } from './components/home-cleaning-company-branch-list/home-cleaning-company-branch-list.component';

const routes: Routes = [
  {
    path: 'home-cleaning-company-branch/list',
    component: HomeCleaningCompanyBranchListComponent,
  },
  // {
  //   path: 'home-cleaning-company-branch/create',
  //   component: CarwashBranchCreateComponent,
  // },
  {
    path: 'home-cleaning-company-branch',
    redirectTo: 'home-cleaning-company-branch/list',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeCleaningCompanyBranchRoutingModule {}
