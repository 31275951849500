import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import moment from 'moment/moment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  readonly URL = environment.serverUrl + 'users/login';

  token?: BehaviorSubject<string>;
  expiry?: BehaviorSubject<string>;
  // public currentUser: Observable<String>;

  constructor(private http: HttpClient) {
    this.token = new BehaviorSubject<string>(localStorage.getItem('token'));
    this.expiry = new BehaviorSubject<string>(localStorage.getItem('expiry'));
    // this.currentUser = this.currentUserSubject.asObservable();
  }

  authenticate(userName: string, password) {
    return this.http
      .post<any>(this.URL, {
        email: userName,
        password: password
      })
      .pipe(map(data => data));
  }

  public get isUserLoggedIn(): Boolean {

    console.log(this.expiry.getValue());
    console.log(this.token.getValue());
    return (this.expiry.getValue() && moment(this.expiry.getValue().toString(), 'x').isAfter(moment()) && !!this.token.getValue());
  }
}
