import { GroupService } from '@app/shared/service/group';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { FormStatus } from '@shared/enums/form-status.enum';
import { Variant, Group, SubVariant } from '@shared/models';
import { SelectOptions } from '@app/shared/models/global/response';

@Component({
  selector: 'app-variant-form',
  templateUrl: './variant-form.component.html',
  styleUrls: ['./variant-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VariantFormComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  subVariantsForms: Array<UntypedFormGroup> = [];
  groups$: Observable<Array<Group>>;
  callTypes$: Observable<Array<SelectOptions<string>>>;

  @Input() variant: Variant;
  @Input() imageVariantLogoLink: string;
  @Output() formChanged: EventEmitter<Partial<Variant>> = new EventEmitter<
    Partial<Variant>
  >();
  @Output() formStatusChanged: EventEmitter<FormStatus> =
    new EventEmitter<FormStatus>();
  @Output() subVariantFormChanged: EventEmitter<Array<Partial<Variant>>> =
    new EventEmitter<Array<Partial<Variant>>>();
  @Output() subVariantFormsValidityChanged: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() changeVariantLogoFile: EventEmitter<File> =
    new EventEmitter<File>();
  @Output() changeSubVariantLogoFile: EventEmitter<{
    file: File;
    imageFormControl: UntypedFormControl;
  }> = new EventEmitter<{ file: File; imageFormControl: UntypedFormControl }>();

  private readonly destroy$: Subject<void> = new Subject<void>() ;
  subVariantsSubscribers$: Array<Subscription> = [];

  constructor(
    private readonly fb: UntypedFormBuilder,
    private groupService: GroupService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.handleFormChanges();
    this.handleFormStatusChanges();
    this.loadGroups();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateVariantLogoFile(file: File): void {
    this.changeVariantLogoFile.next(file);
  }

  uploadSubVariantLogoFile(file: File, imageFormControl: UntypedFormControl): void {
    this.changeSubVariantLogoFile.next({ file, imageFormControl });
  }

  addNewSubVariantForm(subVariant?: SubVariant): void {
    const subVariantForm: UntypedFormGroup = this.fb.group({
      id: [subVariant?.id || undefined],
      name: this.fb.group({
        en: [subVariant?.name?.en || '', Validators.required],
        ar: [subVariant?.name?.ar || '', Validators.required],
      }),
      image: [subVariant?.image || '', Validators.required],
      variantId: [this.variant?.id || ''],
    });
    this.subVariantsForms.push(subVariantForm);
    this.handlesubVariantFormChanges(subVariantForm);
    this.handlesubVariantFormStatusChanges(subVariantForm);
  }

  removeSubVariantForm(index: number): void {
    this.subVariantsForms.splice(index, 1);
    if (!this.subVariantsForms.length) {
      this.form.get('hasMultipleVariants').setValue(false);
    }
    this.setSubVariantsFormsValidity();
    this.sendSubVariantsFormsValues();
  }

  toggleHasSubVariants(isOn?: boolean): void {
    if (isOn) {
      if (!this.subVariantsForms.length) {
        this.addNewSubVariantForm();
      } else {
        this.setSubVariantsFormsValidity();
      }
    } else {
      this.subVariantFormsValidityChanged.emit(true);
    }
  }
  private initForm(): void {
    this.form = this.fb.group({
      name: this.fb.group({
        en: ['', [Validators.maxLength(25), Validators.required]],
        ar: ['', [Validators.maxLength(25)]],
      }),
      description: this.fb.group({
        en: [''],
        ar: [''],
      }),
      groupId: ['', Validators.required],
      image: [''],
      hasMultipleVariants: [false, Validators.required],
      subVariants: [[]],
    });
    this.patchForm();
  }

  private patchForm(): void {
    if (!this.variant) {
      return;
    }

    this.form.patchValue(this.variant);
    if (this.variant?.hasMultipleVariants) {
      this.variant.subVariants?.forEach((subVariant) => {
        this.addNewSubVariantForm(subVariant);
      });
    }
    this.formChanged.emit(this.form.value);
  }

  private handleFormChanges(): void {
    const emitValue = (value: Partial<Variant>) => this.formChanged.emit(value);

    this.form.valueChanges
      .pipe(debounceTime(500), tap(emitValue), takeUntil(this.destroy$))
      .subscribe();
  }

  private handleFormStatusChanges(): void {
    const emitStatus = (value: FormStatus) => {
      this.formStatusChanged.emit(value);
    };

    this.form.statusChanges
      .pipe(
        startWith(this.form.status),
        distinctUntilChanged(),
        tap(emitStatus),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private setSubVariantsFormsValidity(): void {
    const isSubVariantsFormsValid = !this.subVariantsForms.find(
      (subVariantsForm) => subVariantsForm.invalid,
    );
    this.subVariantFormsValidityChanged.emit(isSubVariantsFormsValid);
  }

  private sendSubVariantsFormsValues(): void {
    this.subVariantFormChanged.emit(
      this.subVariantsForms.map((subVariantForm) => subVariantForm.value),
    );
  }

  private handlesubVariantFormChanges(subVariantForm: UntypedFormGroup): void {
    const emitValue = () => this.sendSubVariantsFormsValues();

    subVariantForm.valueChanges
      .pipe(debounceTime(500), tap(emitValue), takeUntil(this.destroy$))
      .subscribe();
  }

  private handlesubVariantFormStatusChanges(subVariantForm: UntypedFormGroup): void {
    const emitStatus = () => this.setSubVariantsFormsValidity();

    const subscriber$ = subVariantForm.statusChanges
      .pipe(
        startWith(subVariantForm.status),
        distinctUntilChanged(),
        tap(emitStatus),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.subVariantsSubscribers$.push(subscriber$);
  }

  private loadGroups(): void {
    this.groups$ = this.groupService.getGroups();
  }
}
