import { IdName } from '../cars/cars';

export class SpecialIconServicesFromList {
  id: number;
  service_id: number;
  item_id: number;
  service: IdName;
}
// items
export class SpecialIcon {
  id: number;
  name: string;
  status: string;
}

export class ItemSpecialIcon {
  item_id: number;
  special_icon_id: number;
  special_icon: SpecialIcon;
}

export class ItemsSpecialIconFromList {
  id: number;
  name: string;
  item_special_icon: ItemSpecialIcon;
}

// tPrice list

export class SpecialIconsListPrices {
  id: number;
  item: IdName;
  service: IdName;
  tat_service: IdName;
  price: number;
}

export class ItemPricesToBeSaved {
  item_id: number;
  service_id: number;
  tat_service_id: number;
  price: number;
}

export class FinalItemObjectToBeSaved {
  laundry_branch_id: number;
  items: ItemPricesToBeSaved[];
}

export class Translation {
  content: string;
  language_id: number;
  id?: number;
}

export class SpecialIconsTCToBeSaved {
  laundry_branch_id: number;
  item_id: number;
  service_id: number;
  translations: Translation[];
}

export class SpecialIconsTCFromList {
  item: IdName;
  service: IdName;
  content: Translation[];
}
