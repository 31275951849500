import { NgModule } from '@angular/core';
import { OrderRoutingModule } from './order-routing.module';
import { SharedModule } from '../shared/shared.module';
import { OrderListComponent } from './components/order-list/order-list.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { ChangeTimeslotComponent } from './components/order-details/change-timeslot/change-timeslot.component';
import { ChangeLaundryComponent } from './components/order-details/change-laundry/change-laundry.component';
import { AllOrdersComponent } from './components/all-orders/all-orders.component';
import { ApprovalComponent } from './components/approval/approval.component';
import { WaivedOrderComponent } from './components/order-details/waived-order/waived-order.component';
import { CompensateOrderComponent } from './components/compensate-order/compensate-order.component';
import { PayCashComponent } from './components/order-details/pay-cash/pay-cash.component';
import { OrderCancelRequestsComponent } from './components/order-cancel-requests/order-cancel-requests.component';

@NgModule({
  imports: [SharedModule.forRoot(), OrderRoutingModule],
  declarations: [
    OrderListComponent,
    OrderDetailsComponent,
    ChangeTimeslotComponent,
    ChangeLaundryComponent,
    AllOrdersComponent,
    ApprovalComponent,
    WaivedOrderComponent,
    CompensateOrderComponent,
    PayCashComponent,
    OrderCancelRequestsComponent
  ]
})
export class OrderModule {}
