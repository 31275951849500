import { AbstractControl, Validators } from '@angular/forms';

const getPhonePattern = () => {
  return '^\\+(?:[0-9] ?){6,14}[0-9]$';
};

export const phoneValidator = (control: AbstractControl): null | Validators => {
  const reg = new RegExp(getPhonePattern()).test(control.value);
  if (!reg && control.value?.length) {
    return { phone: '' };
  }
  return null;
};
