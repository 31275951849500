import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BackEndResponse } from '@shared/models/global/response';
import { environment } from '@env/environment';
import { GlobalService } from '@shared/service/global.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ImageUploaderHttpService {
  private readonly URL_UPLOAD = environment.serverUrl + 'users/upload-image';

  constructor(
    private readonly http: HttpClient,
    private readonly globalService: GlobalService,
  ) {}

  imageUpload(
    file: File,
    imageTypeName: string = 'cw-garages',
  ): Observable<string> {
    const formData = new FormData();
    this.globalService.headersImage['image_type'] = imageTypeName;

    formData.append('image', file);
    return this.http
      .post<BackEndResponse>(this.URL_UPLOAD, formData, {
        headers: new HttpHeaders(this.globalService.headersImage as any),
      })
      .pipe(map((res) => res.data));
  }
  imageUploadWithFullResponse(file: File): Observable<string> {
    const formData = new FormData();

    formData.append('image', file);
    return this.http
      .post<BackEndResponse>(this.URL_UPLOAD, formData, {
        headers: new HttpHeaders(this.globalService.headersImage as any),
      })
      .pipe(map((res: any) => res));
  }
}
