// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left-side {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;
  background-color: #f5f5f5;
  min-height: 100%;
}

.rounded {
  border-radius: 25px;
}

.table-left {
  margin-left: 30%;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/jc-deliver/components/jc-del-user-profile/jc-del-user-profile.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,UAAU;EACV,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".left-side {\n  position: absolute;\n  left: 0;\n  right: 0;\n  z-index: 0;\n  background-color: #f5f5f5;\n  min-height: 100%;\n}\n\n.rounded {\n  border-radius: 25px;\n}\n\n.table-left {\n  margin-left: 30%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
