// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input.counter-success ~ span.text-success {
  visibility: hidden;
}

img {
  height: 45px;
  width: 45px;
}

.text-price {
  background-color: white !important;
  color: black !important;
  border-radius: 0.125rem;
}
.text-price .md-form {
  margin-top: 0.5rem;
  margin-bottom: 0% !important;
}
.text-price .md-form .form-control {
  margin: 0 !important;
}

.error {
  background-color: white !important;
  color: red !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/corporate/components/contract-create/contract-settings/contract-settings.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,YAAA;EACA,WAAA;AACJ;;AAEA;EACI,kCAAA;EACA,uBAAA;EACA,uBAAA;AACJ;AACI;EACI,kBAAA;EACA,4BAAA;AACR;AACQ;EACI,oBAAA;AACZ;;AAIA;EACI,kCAAA;EACA,qBAAA;AADJ","sourcesContent":["input.counter-success~span.text-success {\r\n    visibility: hidden;\r\n}\r\n\r\nimg {\r\n    height: 45px;\r\n    width: 45px;\r\n}\r\n\r\n.text-price {\r\n    background-color: white !important;\r\n    color: black !important;\r\n    border-radius: 0.125rem;\r\n\r\n    .md-form {\r\n        margin-top: 0.5rem;\r\n        margin-bottom: 0% !important;\r\n\r\n        .form-control {\r\n            margin: 0 !important;\r\n        }\r\n    }\r\n}\r\n\r\n.error {\r\n    background-color: white !important;\r\n    color: red !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
