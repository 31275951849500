import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../../shared/service/global.service';
import { PageInfo } from '../../../../shared/models/global/response';
import { TranslateService } from '@ngx-translate/core';
import { CustomerService } from '../../../../shared/service/customer.service';
import { CustomerFromList } from '../../../../shared/models/customer/customer';
@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {
  search: string;
  email: string;
  mobile: string;
  lang: string;
  pagination: PageInfo;
  toggleDeleteModal: boolean;
  loader: boolean;
  isPasswordModalOpened: boolean;
  customerId: string;
  customers: CustomerFromList[];
  allCustomers: CustomerFromList[];
  filteredCustomers: CustomerFromList[];
  customerToBeDeleted: CustomerFromList = new CustomerFromList();
  constructor(
    public translate: TranslateService,
    public customerService: CustomerService,
    protected globalService: GlobalService
  ) {}

  ngOnInit() {
    this.toggleDeleteModal = false;
    this.translate.use(this.translate.defaultLang);
    this.lang = this.translate.getDefaultLang();
    this.loader = false;
    this.isPasswordModalOpened = false;
    this.getCustomers(1);
  }

  getCustomers(page) {
    this.customerService
      .getCustomers(this.globalService.headers, {
        page: page,
        searchstr: this.search,
        email: this.email,
        mobile: this.mobile
      })
      .subscribe(response => {
        this.allCustomers = response.data;
        this.pagination = response.page_info;
      });
  }

  filter() {
    /*
    this.getAll();
    this.filteredCustomers = JSON.parse(JSON.stringify(this.allCustomers));
    if (this.search !== "")
      this.filteredCustomers = this.filteredCustomers.filter(
        e =>
          e.first_name.includes(this.search) ||
          e.last_name.includes(this.search)
      );
    if (this.mobile !== "")
      this.filteredCustomers = this.filteredCustomers.filter(e =>
        e.phone.includes(this.mobile)
      );
    if (this.email !== "")
      this.filteredCustomers = this.filteredCustomers.filter(e =>
        e.email.includes(this.email)
      );
      */
    this.getCustomers(1);
  }

  delete(customer: CustomerFromList) {
    this.customerToBeDeleted = customer;
    this.toggleDeleteModal = true;
  }

  deleteCustomer(event) {}

  resetFilter() {
    this.filteredCustomers = this.customers;
    this.initFilters();
  }

  getPage(page) {
    this.getCustomers(page);
  }

  getAll() {
    this.getCustomers('all');
  }

  pageArray() {
    return Array(this.pagination.page_count);
  }

  openChagePasswordModal(id: string): void {
    this.customerId = id;
    this.isPasswordModalOpened = true;
  }

  closeChangePasswordModal(status: boolean): void {
    this.isPasswordModalOpened = status;
  }

  private initFilters() {
    this.search = this.mobile = this.email = undefined;
    this.getCustomers(1);
  }
}
