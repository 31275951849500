import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  CountryToBeSaved,
  CountrySLA,
  AreaToBeSaved,
  IconName,
  GovernorateToBeSaved,
  AreaList,
} from '../models/countries/country';
import { environment } from '../../../environments/environment';
import { BackEndResponse } from '../models/global/response';

import { expand, map, reduce, tap } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';
import { ApiResponse } from '@shared/models/global/apiResponse';
import { CountryV4 } from '@shared/models/countries/country-v4';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  readonly URL_GET_COUNTRY = environment.serverUrl + 'global/countries';
  readonly URL_SAVE = environment.serverUrl + 'global/countries';
  readonly URL_GET_BY_ID =
    environment.serverUrl + 'setting/get-country-setting/';
  readonly URL_DELETE_COUNTRY = environment.serverUrl + 'items/del-category';
  readonly SLA = environment.serverUrl + 'setting/create-country-setting';
  readonly URL_CITY = environment.serverUrl + 'global/governorates';
  readonly URL_AREA = environment.serverUrl + 'global/areas';
  readonly URL_CREATE_PAYMENT =
    environment.serverUrl + 'setting/add-payment-method';
  readonly URL_GET_PAYMENT =
    environment.serverUrl + 'setting/get-payment-method';
  readonly URL_PAYMENT_STATUS =
    environment.serverUrl + 'setting/change-payment-status/';
  readonly URL_LIST_CITIES = environment.serverUrl + 'global/governorates';
  readonly URL_LIST_CITIES_AREAS =
    environment.serverUrl + 'global/governorates-with-areas/';
  readonly URL_LIST_AREAS = environment.serverUrl + 'global/areas';
  readonly URL_LIST_VARIANTS = environment.jcDeliveryServer + 'variants';

  private readonly urlCountryV4 = `${environment.jcDeliveryServer}countries`;
  private countriesResponse;

  constructor(private http: HttpClient) {}

  getCountries(headers) {
    if (!this.countriesResponse) {
      return this.http
        .get<BackEndResponse>(this.URL_SAVE, {
          headers: new HttpHeaders(headers),
        })
        .pipe(
          map((res) => {
            if (res.code === 200) {
              this.countriesResponse = res;
              return this.countriesResponse;
            }
          }),
        );
    }
    return of(this.countriesResponse);
  }

  getCountry(id: number): Observable<CountryV4> {
    return this.http
      .get<ApiResponse<CountryV4>>(`${this.urlCountryV4}/${id}`)
      .pipe(map((res) => res.data));
  }

  updateCountry(id: number, data: Partial<CountryV4>): Observable<CountryV4> {
    return this.http
      .patch<ApiResponse<CountryV4>>(`${this.urlCountryV4}/${id}`, data)
      .pipe(map((res) => res.data));
  }

  saveCountry(headers, country: CountryToBeSaved) {
    return this.http
      .post<BackEndResponse>(this.URL_SAVE, country, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  getCountryById(headers, id: number) {
    return this.http
      .get<BackEndResponse>(this.URL_GET_BY_ID + id, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  saveSLA(headers, sla: CountrySLA) {
    return this.http
      .post<BackEndResponse>(this.SLA, sla, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  saveGovernorate(headers, gov: GovernorateToBeSaved) {
    return this.http
      .post<BackEndResponse>(this.URL_CITY, gov, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  saveArea(headers, area: AreaToBeSaved) {
    return this.http
      .post<BackEndResponse>(this.URL_AREA, area, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  savePaymentMethod(headers, payment: IconName) {
    return this.http
      .post<BackEndResponse>(this.URL_CREATE_PAYMENT, payment, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  getPaymentMethod(headers) {
    return this.http
      .get<BackEndResponse>(this.URL_GET_PAYMENT, {
        headers: new HttpHeaders(headers),
      })
      .pipe(tap((data) => console.log(data)));
  }

  changeStatusPaymentMethod(headers, id, status) {
    return this.http
      .post<BackEndResponse>(
        this.URL_PAYMENT_STATUS + id + '/' + status,
        {},
        {
          headers: new HttpHeaders(headers),
        },
      )
      .pipe(tap((data) => console.log(data)));
  }

  getListCities(headers, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_LIST_CITIES, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(tap((data) => console.log(data)));
  }

  getAllListCities(headers, params: any = {}): Observable<Array<any>> {
    return this.http
      .get<BackEndResponse>(this.URL_LIST_CITIES, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(
        expand((res) =>
          !res.page_info.next_page
            ? EMPTY
            : this.http.get<BackEndResponse>(
                this.URL_LIST_CITIES,
                this.updateRequestFormat(params, res.page_info.next_page),
              ),
        ),
        reduce((records, res) => records.concat(res.data), []),
      );
  }

  private updateRequestFormat(params: any, page: number) {
    params.page = page;
    return {
      params,
    };
  }

  getListAreas(headers, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_LIST_AREAS, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(tap((data) => console.log(data)));
  }

  getCountryVariants(params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_LIST_VARIANTS, {
        params,
      })
      .pipe(tap((data) => console.log(data)));
  }

  getListCitiesWithAreas(headers, govId: number, params?: any) {
    return this.http
      .get<BackEndResponse>(this.URL_LIST_CITIES_AREAS + govId, {
        headers: new HttpHeaders(headers),
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(tap((data) => console.log(data)));
  }

  generateListOfAreasForUi(headers, list: any[]) {
    this.getListAreas(headers, { page: 0 }).subscribe((response) => {
      const areas: AreaList[] = response.data;
      list = [];
      areas.forEach((element) => {
        if (element.country_id === parseInt(headers.country_id, 10)) {
          list.push({ value: element.id, label: element.name });
        }
      });
      list = [...list, {}];
    });
  }
}
